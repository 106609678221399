// @flow

import React, { Fragment } from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Tile from '../../components/Tile';
import ModalEprm from '../../components/modal/ModalEprm';
import {
  CaseStatus as CaseStatusEnum,
  CommunicationChannel,
  CommunicationStatus,
  RecordingReason,
  teleConsultCode,
  UserRole,
} from '../../enum';
import { createSearchFromParams } from '../../services/queryParamsService';
import CaseStatus from './CaseStatus';
import {
  CASE_PROCESS_PAGE_ROUTE,
  CONSENTS_PAGE_ROUTE,
  QUALIFICATION_PAGE_ROUTE,
  TELECONSULTATION_PAGE_ROUTE,
} from '../../routes';
import caseApi from '../../network/api/caseApi';
import userApi from '../../network/api/userApi';
import { currentSessionService } from '../../services/sessionServiceInstance';
import Loader from '../../components/Loader';
import { store } from '../../network/reduce';
import { USER_IS_AVAILABLE } from '../../state/process/processReducer';
import { notifyError } from '../../network/notification';

type Props = {
  dispatch: Function,
  channel: string,
  patientName: string,
  caseDateCreation: string,
  sessionStartDate: string,
  brand: string,
  reasonForTheCall: string,
  processStatus: string,
  userName: string,
  appointment: boolean,
  caseId: string,
  patientId: string,
  history: Function,
  processCase: boolean,
  chatStatus: string,
  videoStatus: string,
  caseQualification: string,
  isRedirectToConsent: boolean,
  partnerName: string,
  userTakenInChargeId: string
};

type State = {
  modal: boolean,
  highestStep: number,
};

export class DashboardTile extends React.Component<Props, State> {
  state = {
    modal: false,
  };

  componentDidMount = () => {
    caseApi
      .getHighestStep(this.props.caseId)
      .then(response => response.text())
      .then((highestStep: number) => {
        this.setState({ highestStep });
      })
      .catch(e => console.log('Erreur apppel API', e));
  };

  shouldBlink(liveStatus: string, caseCreationDate: string, appointment: boolean) {
    // Pour toute les BU :
    // 1. Dossier sans RDV :
    //    - Doit clgnoter si un patient se trouve dans la session vidéo
    // 2. Dossier avec  RDV :
    //    - Doit clignoter si la date de rdv est dépassée
    // Pour l'espagne :
    // 1. Dossier sans RDV :
    //    - Channel : Téléphone, Doit cligonter tant qu'il n'a pas été pris en charge (car l'utilisateur doit appeler le patient)
    // 2. Dossier avec RDV :
    //    - Doit clignoter si la date du rendez-vous est arrivé
    //    - Doit continuer de clignoter lorsqu'il a été assigné a qqun
    //    - Doit arreter de clignoter lorqu'il a été pris en charge
    return (liveStatus === CommunicationStatus.WAITING ||
          ((appointment) && moment(caseCreationDate).isBefore(moment()) && this.props.processStatus !== CaseStatusEnum.TAKEN_IN_CHARGE)
      || (currentSessionService().currentUser().doctorShouldCallback && this.props.channel === CommunicationChannel.PHONE && !(appointment) && this.props.processStatus !== CaseStatusEnum.TAKEN_IN_CHARGE));
  }

  getBarColor = (liveStatus: string, caseCreationDate: string, appointment: boolean) => {
    if (liveStatus === CommunicationStatus.IN_PROGRESS) {
      return ' in-progress';
    }

    if (liveStatus === CommunicationStatus.DROPPED) {
      return ' dropped';
    }

    if (this.shouldBlink(liveStatus, caseCreationDate, appointment)) {
      return ' blink';
    }

    return '';
  };

  getChannelIcon = (isAppointment) => {
    let icon;
    if (!this.props.channel) {
      return <div><Loader small grey /></div>;
    }

    switch (this.props.channel) {
      case CommunicationChannel.CHAT:
        icon = 'fa-comments';
        break;
      case CommunicationChannel.PHONE:
        icon = 'fa-phone-volume';
        break;
      case CommunicationChannel.VIDEO:
        icon = 'fa-video';
        break;
      default:
        icon = '';
        break;
    }

    if (isAppointment) {
      return <i className="channel-icon--tl far fa-calendar-day"><i className={`channel-icon--br fa ${icon}`} /></i>;
    }
    return <i className={`channel-icon--mm fa ${icon}`} />;
  };

  getShortText = (text: string) => {
    if (text.length > 20) {
      return `${text.substring(0, 19)}...`;
    }
    return text;
  };

  redirectToPage = () => {
    const queryParams = {
      caseId: this.props.caseId,
      patientId: this.props.patientId,
      redirectToConsent: this.props.isRedirectToConsent,
    };

    const isTeleconsultation = (this.props.caseQualification === teleConsultCode);
    if (this.props.isRedirectToConsent) {
      this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + CONSENTS_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
    } else if (!currentSessionService().currentUser().showQualification || isTeleconsultation) {
      this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
    } else {
      this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + QUALIFICATION_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
    }
  }

  takeCase = (consultOnly) => {

    this.changeAvailability();
    
    // redirect to case (consult only mode)
    const hadCurrentUserTakenInCharge = (this.props.userTakenInChargeId == currentSessionService().currentUser().userId);
    if (consultOnly && !hadCurrentUserTakenInCharge) {
      this.redirectToPage();
    } else {
      // redirect to case (take in charge mode)
      caseApi
        .takeInCharge(this.props.caseId)
        .then(() => {
          this.props.dispatch(store(
            USER_IS_AVAILABLE,
            currentSessionService().currentUser().isAvailable,
          ));
          this.redirectToPage();
        })
        .catch(this.props.dispatch(notifyError));
    }
  };

  changeAvailability = () => {
    userApi
      .updateAvailability(false);
  };

  blinkClassName = (liveStatus: string, caseDateCreation: string, appointment: boolean) => {
    if (liveStatus === CommunicationStatus.IN_PROGRESS) {
      return ' in-progress';
    }

    if (liveStatus === CommunicationStatus.DROPPED) {
      return ' dropped';
    }
    if (this.shouldBlink(liveStatus, caseDateCreation, appointment)) {
      return ' blink';
    }

    return '';
  };

  render() {
    const liveStatus = this.props.videoStatus || this.props.chatStatus;
    return (
      <Tile
        className={`tile-dashboard bar ${this.getBarColor(liveStatus, this.props.caseDateCreation, this.props.appointment)} ${this.props.processCase ? 'process-tile' : ''}`}
      >
        <div
          className={`container-channel ${this.blinkClassName(liveStatus, this.props.caseDateCreation, this.props.appointment)}`}
        >
          {this.getChannelIcon(this.props.appointment)}
        </div>
        <div className="patient-id">
          <div className="name" title={this.props.patientName}>{this.props.patientName}</div>
          <div className="date-creation">
            {moment(this.props.caseDateCreation).format(I18n.t('date.DATE_LONG'))}
          </div>
        </div>
        <div className="b-partner">
          <div className="name">{this.props.brand}</div>
          <div className="name">{this.props.partnerName}</div>
        </div>

        <div className="call-reason">
          {this.props.reasonForTheCall &&
          <>
            <i className="info-icon fa fa-info-circle">
              <div className="bubble">
                <div className="bubble-arrow" />
                <div className="bubble-text">
                  {this.props.reasonForTheCall}
                </div>
              </div>
            </i>
            <div className="text ">{this.getShortText(this.props.reasonForTheCall)}</div>
          </>
          }
        </div>
        <CaseStatus
          userName={this.props.userName}
          userRole={this.props.caseQualification === teleConsultCode
            ? UserRole.DOCTOR_LEVEL : UserRole.NURSE_LEVEL}
          caseDateCreation={this.props.caseDateCreation}
          sessionStartDate={this.props.sessionStartDate}
          status={this.props.processStatus}
          channel={this.props.channel}
          liveStatus={liveStatus}
          appointment={this.props.appointment}
          blinkClassName={this.blinkClassName(liveStatus, this.props.caseDateCreation, this.props.appointment) === ' blink'}
        />
        <div className="take-in-charge">
          <Button
            color="primary"
            onClick={() => this.setState({ modal: !this.state.modal })}
          >
            <i className="button-icon fa fa-folder-open" />{I18n.t('button.OPEN_CASE')}
          </Button>
          <ModalEprm
            title={I18n.t('button.TITLE_POPIN')}
            modal={this.state.modal}
            toggle={() => this.setState({ modal: !this.state.modal })}
          >
            <div className="take-in-charge-message">
              {I18n.t('dashboard.modal.TAKE_IN_CHARGE')}
            </div>
            <div className="take-in-charge-buttons">
              <Button
                color="secondary"
                onClick={() => this.setState({ modal: !this.state.modal })}
              >
                <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({ modal: !this.state.modal });
                  this.takeCase(true);
                }}
              >
                <i className="button-icon fa fa-eye" />{I18n.t('button.CONSULT_ONLY')}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.setState({ modal: !this.state.modal });
                  this.takeCase(false);
                }}
              >
                <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.TAKE_IN_CHARGE')}
              </Button>
            </div>
          </ModalEprm>
        </div>
      </Tile>);
  }
}

export default withRouter(connect()(DashboardTile));
