// @flow

import { RestClientAuthenticated } from '../network';
import type { User } from '../../types/UsersTypes';

const appointmentApi = {
  fetchAvailabilityProfiles: () => new RestClientAuthenticated('/appointment/availability-profile/list', 'GET').execute(),
  searchAppointments: (availabilityProfileId: string) => new RestClientAuthenticated(`/appointment/availability-profile/${availabilityProfileId}/list`, 'GET').execute(),
  fetchPreviewAvailabilities: (data) => new RestClientAuthenticated('/appointment/availability-profile/availabilities', 'POST').jsonBody(data).execute(),
  createAvailabilities: (listAvailabilitiesToCreate) => new RestClientAuthenticated('/appointment/availability', 'POST').jsonBody(listAvailabilitiesToCreate).execute(),
  createRelatedCase: (appointmentId: string, caseParentId: string) => new RestClientAuthenticated(`/appointment/${appointmentId}/case/${caseParentId}/related-case`, 'POST').execute(),
  deleteAvailability: (appointmentId: string) => new RestClientAuthenticated(`/appointment/availability-profile/${appointmentId}`, 'DELETE').execute(),
};
export default appointmentApi;
