// @flow
import { RestClientAuthenticated } from '../network';
import type { PatientSearchType } from '../../types/PatientTypes';
import { createSearchFromParams } from '../../services/queryParamsService';
import {AddNewPolicyType} from "./policiesApi";

export type ShortPatient = {
  lastName: string,
  firstName: string,
  birthDate: string,
  policyNumber: string,
  protocolNumber: string,
  expiryDate: string,
  partnerName: string,
  beneficiaryBirthday: string,
  beneficiaryFirstName: string,
  beneficiaryLastName: string,
  policyHolderFirstName: string,
  policyHolderLastName: string,
  pdaAccount: string,
  mobilePhone?: string,
  emailAddress?: string,
  noAddressMail?: string,
  isSearchBeneficiary: boolean
};

const patientApi = {
  fetchById: (patientId: string, policyNumber?: string) =>
    new RestClientAuthenticated(`/patients/${patientId}${policyNumber ? `?policyNumber=${policyNumber}` : ''}`, 'GET').execute(),

  update: (patient: ShortPatient, id: string) =>
    new RestClientAuthenticated(`/patients/${id}/update`, 'PATCH').jsonBody(patient).execute(),

  create: (patient: ShortPatient) =>
    new RestClientAuthenticated('/patients', 'POST').jsonBody(patient).execute(),

  saveField: (caseId: ?string, patientId: string, field: string, values: Object) => new RestClientAuthenticated(`/patients/${patientId}/profile?field=${field}${caseId ? `&caseId=${caseId}` : ''}`, 'PATCH').jsonBody(values).execute(),
  getPatientMedicalProfile: (patientId: string) => new RestClientAuthenticated(`/patients/${patientId}/medical-profile`, 'GET').execute(),
  fetchProcessDetails: (patientId: string, caseId: string) => new RestClientAuthenticated(`/patients/process-details${caseId ? `?caseId=${caseId}&` : '?'}${patientId ? `patientId=${patientId}` : ''}`, 'GET').execute(),
  getLastDocuments: (id: string, protocolNumber: string) => new RestClientAuthenticated(`/patients/${id}/lastDocuments`, 'POST').jsonBody(protocolNumber).execute(),
  getDocument: (id: string) => new RestClientAuthenticated(`/document/${id}`, 'GET').execute(),
  getPatientHistory: (prmId: string) => new RestClientAuthenticated(`/patients/${prmId}/history`, 'GET').execute(),
  getContactMeans: (id: string) => new RestClientAuthenticated(`/patients/${id}/contact`, 'GET').execute(),
  search: (search: PatientSearchType) => new RestClientAuthenticated(`/patients/search${createSearchFromParams(search)}`, 'GET').execute(),
  searchPatientsProfiles: (search: PatientSearchType) => new RestClientAuthenticated(`/patients/profiles${createSearchFromParams(search)}`, 'GET').execute(),
  fetchLastPatientsByRole: () => new RestClientAuthenticated(`/patients/last`, 'GET').execute(),
  savePolicyByPatient: (id: string, values: Object) =>
      new RestClientAuthenticated(`/policy/patient/${id}/visa-contract`, 'POST').jsonBody(values).execute(),
};

export default patientApi;
