// @flow

import React from 'react';
import { Field, Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CoreozInputBase as CoreozInput } from '../../../lib/coreoz-form-base/index';

import ModalEprm from '../../../components/modal/ModalEprm';
import {validateEmail, validateEmailFormat, validatePhone} from '../../../validator';
import type { ShortPatient } from '../../../network/api/patientApi';
import businessUnitApi from '../../../network/api/businessUnitApi';
import { notifyError } from '../../../network/notification';
import CoreozPhoneInput from '../../../components/CoreozPhoneInput';
import Loader from '../../../components/Loader';

type Props = {
  dispatch: Function,
  modal: boolean,
  toggle: Function,
  submitFunction: Function,
  patientInfosToSave: ShortPatient,
};

type State = {
  preferredCountries: Array,
  loading: boolean,
};

class CreatePatientModal extends React.Component<Props, State> {
  state = {
    preferredCountries: [],
    phoneValid: false,
    loading: false,

  };

  componentDidMount(): void {
    businessUnitApi
      .fetchListofCountries()
      .then(resultat => resultat.json())
      .then(preferredCountries => this.setState({ preferredCountries }))
      .catch(this.props.dispatch(notifyError));
  }

  handleSubmit = (data) => {
    this.setState({ loading: true });
    this.props.submitFunction({
      ...this.props.patientInfosToSave,
      mobilePhone: data.mobilePhone,
      email: data.email,
      noEmailAddress: data.noEmailAddress
    },);
  };

  disabledSubmit = (noEmailAddress, email) => {
    if (noEmailAddress) {
      return !(!(validateEmail(email)) && this.state.phoneValid);
    } else {
      return !(email && !(validateEmail(email)) && this.state.phoneValid);
    }
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('popinCreatePatient.title')}
        modal={this.props.modal}
        toggle={this.props.toggle}>

        <div className="popin-subtitle">{I18n.t('popinCreatePatient.subtitle')}</div>
        <Form
          onSubmit={this.handleSubmit}
          initialValues={{ noEmailAddress: false }}
          render={({ handleSubmit, values, form, invalid }) => (
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <div className="popin-create-modal">
                <Field
                  label={I18n.t('administration.identity.EMAIL')}
                  component={CoreozInput}
                  name="email"
                  type="email"
                  required={!values.noEmailAddress}
                  validate={validateEmailFormat}
                />
                <div className="checkbox-form-container container-input-coreoz">
                  <div className="checkbox-container">
                    <Field
                      component="input"
                      name="noEmailAddress"
                      type="checkbox"
                    />
                  </div>
                  <div className="checkbox-label-container">
                    {I18n.t('administration.identity.EMAIL_BOOLEAN')}
                  </div>
                </div>
                {
                  this.state.preferredCountries.length &&
                  <CoreozPhoneInput
                    preferredCountries={this.state.preferredCountries}
                    value={values.mobilePhone}
                    label={I18n.t('administration.identity.PHONE_NUMBER')}
                    onChange={newValue => form.change('mobilePhone', newValue)}
                    setPhoneIsValid={(phoneValid) => this.setState({ phoneValid })}
                    required
                  />
                }
                <div className="popin-footer">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={this.props.toggle}
                  >
                    {I18n.t('button.CANCEL')}
                  </button>
                  <button className="btn btn-primary "
                          disabled={this.disabledSubmit(values.noEmailAddress, values.email) || this.state.loading}
                          type="submit"
                  >
                    {
                      this.state.loading ?
                        <Loader small/>
                        :
                        <div>
                          {I18n.t('button.CONTINUE')}
                        </div>
                    }
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </ModalEprm>
    );
  }
}

export default withRouter(connect()(CreatePatientModal));
