// @flow

import { RestClientAuthenticated } from '../../network/network';
import type { BusinessUnit } from '../../types/BusinessUnitTypes';
import { BPartnerDetail, SearchCriteriaType, SearchResultType } from '../../types/B-PartnerManagement';
import { notifyError } from '../notification';

const businessUnitApi = {
  fetch: () => new RestClientAuthenticated('/business-units', 'GET').execute(),
  search: (searchCriteria: SearchCriteriaType): SearchResultType => (dispatch: Function) => {
    const queryString = Object.keys(searchCriteria).filter(v => searchCriteria[v] !== null).map(key => `${key }=${ searchCriteria[key]}`).join('&');

    return new RestClientAuthenticated(`/business-units/search?${queryString}`, 'GET').execute().then(response => response.text()).then(text => (text.length ? JSON.parse(text) : null))
      .catch(e => dispatch(notifyError)(e));
  },
  fetchById: id => (dispatch: Function) => new RestClientAuthenticated(`/business-units/detail/${id}`, 'GET').execute().then(response => (response.status === 200) && response.json()).catch(e => dispatch(notifyError)(e)),
  fetchListofCountries: () => new RestClientAuthenticated('/business-units/countries', 'GET').execute(),
  save: (data) =>
    new RestClientAuthenticated('/business-units', 'POST').jsonBody(data).execute(),
  update: (id, data) =>
    new RestClientAuthenticated(`/business-units/${id}`, 'PUT').jsonBody(data).execute(),
  delete: (dataId: $PropertyType<BusinessUnit, 'id'>) =>
    new RestClientAuthenticated(`/business-units/${dataId}`, 'DELETE').execute(),
};

export default businessUnitApi;
