// @flow

import React from 'react';
import { Button } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ResizableTextarea from '../ResizableTextarea';
import ModalEprm from './ModalEprm';
import caseApi from '../../network/api/caseApi';
import { notifyError, notifySuccess } from '../../network/notification'

type Props = {
  modal: boolean,
  toggle: Function,
  case: Object,
  fetchCase: Function,
  dispatch: Function,
}

class ModalTechnicalProblem extends React.Component<Props> {
  updateTechnicalProblem = (values: Object) => {
    caseApi
      .updateTechnicalProblem(this.props.case.id, values.technicalIssueText, this.props.case.patientId)
      .then(() => {
        this.props.dispatch(notifySuccess(this.props.dispatch, null, I18n.t('notify.success.MODIFICATION_SAVED')));
        this.props.fetchCase();
        this.props.toggle();
      })
      .catch(notifyError(this.props.dispatch));
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('techProblem.TITLE')}
        modal={this.props.modal}
        toggle={this.props.toggle}
        className="modal-EPRM"
        size="lg"
      >
        <Form
          onSubmit={this.updateTechnicalProblem}
          initialValues={this.props.case}
          render={({ handleSubmit, form, values }) => (
            <form
              className="technical-problem-form"
              onSubmit={handleSubmit}
            >
              <div className="modal-technical-problem-container">
                <div className="label-container">
                  {I18n.t('techProblem.LABEL')}
                </div>
                <Field
                  component={ResizableTextarea}
                  minrows={4}
                  maxrows={15}
                  name="technicalIssueText"
                  required
                  disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                />
              </div>
              <div className="modal-technical-problem-action">
                <Button
                  color="secondary"
                  onClick={this.props.toggle}
                >
                  <i className="button-icon fa fa-undo" />
                  {I18n.t('button.CANCEL')}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={!values.technicalIssueText}
                >
                  <i className="button-icon fa fa-arrow-circle-right" />
                  {I18n.t('techProblem.CREATE')}
                </Button>
              </div>
            </form>
          )}
        />
      </ModalEprm>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(ModalTechnicalProblem));
