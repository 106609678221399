// @flow

export const LOGIN_PAGE_ROUTE = '/login';
export const FORGOT_PASSWORD_ROUTE = '/forgotPassword/:token';
export const DASHBOARD_PAGE_ROUTE = '/dashboard';
export const APPOINTMENTS_PAGE_ROUTE = '/appointments';
export const PATIENT_PAGE_ROUTE = '/patient';
export const PATIENT_DETAILS_ROUTE = `${PATIENT_PAGE_ROUTE}/:id`;
export const CASE_PAGE_ROUTE = '/case';
export const CASE_DETAILS_ROUTE = `${CASE_PAGE_ROUTE}/:id`;
export const AVAILABILITIES_PAGE_ROUTE = '/availabilities';
export const EXPORTS_PAGE_ROUTE = '/exports';
export const OPERATIONS_PAGE_ROUTE = '/operations';
export const USER_MANAGEMENT_PAGE_ROUTE = '/userManagement';
export const USER_MANAGEMENT_NEW_PAGE_ROUTE = `${USER_MANAGEMENT_PAGE_ROUTE}/create`;
export const USER_MANAGEMENT_DETAILS_PAGE_ROUTE = `${USER_MANAGEMENT_PAGE_ROUTE}/edit/:id`;
export const ADVICE_CODING_PAGE_ROUTE = '/adviceCoding';
export const PATHOLOGY_CODING_PAGE_ROUTE = '/pathologyCoding';
export const PHARMACIES_PAGE_ROUTE = '/pharmacies';
export const MEDICAL_RESOURCES_PAGE_ROUTE = '/medicalResources';

export const CASE_PROCESS_PAGE_ROUTE = '/process';
export const CASE_PROCESS_DETAILS_PAGE_ROUTE = `${CASE_PROCESS_PAGE_ROUTE}/:name`;

export const BUSINESS_UNIT_PAGE_ROUTE = '/businessUnit';
export const BUSINESS_UNIT_CREATE_PAGE_ROUTE = `${BUSINESS_UNIT_PAGE_ROUTE}/create`;
export const BUSINESS_UNIT_DETAILS_PAGE_ROUTE = `${BUSINESS_UNIT_PAGE_ROUTE}/edit/:id`;


export const IDENTIFICATION_PAGE_ROUTE = '/identification';
export const ELIGIBILITY_PAGE_ROUTE = '/eligibility';
export const PROCESS_DETAILS_PAGE_ROUTE = '/processDetails';
export const CONSENTS_PAGE_ROUTE = '/consents';
export const QUALIFICATION_PAGE_ROUTE = '/qualification';
export const TELECONSULTATION_PAGE_ROUTE = '/teleconsultation';
export const SENDING_DOCUMENTS_PAGE_ROUTE = '/sendingDocuments';
export const PRESCRIPTION_PAGE_ROUTE = '/prescription';
export const MED_REPORT_PAGE_ROUTE = '/medicalReport';
export const ADVICE_REPORT_PAGE_ROUTE = '/adviceReport';

export const HISTORY_PAGE_ROUTE = '/history';
export const ROLES_PAGE_ROUTE = '/roles';
export const SCHEDULED_PAGE_ROUTE = '/scheduledJobs';
export const LOGS_PAGE_ROUTE = '/logs';
export const LOG_API_PAGE_ROUTE = '/logApi';
export const MAIL_PAGE_ROUTE = '/mails';
export const MAIL_DETAILS_PAGE_ROUTE = `${MAIL_PAGE_ROUTE}/:id`;
export const SMS_PAGE_ROUTE = '/sms';
export const SMS_DETAILS_PAGE_ROUTE = `${SMS_PAGE_ROUTE}/:id`;
export const FAX_PAGE_ROUTE = '/fax';
export const FAX_CONFIRM_ROUTE = `${FAX_PAGE_ROUTE}/confirm/:token`;
export const FAX_CANCEL_ROUTE = `${FAX_PAGE_ROUTE}/cancel/:token`;

export const INTERNAL_BENEFICIARY_MANAGEMENT_PAGE_ROUTE = '/internalBeneficiary';
export const INTERNAL_BENEFICIARY_DETAIL_PAGE_ROUTE = '/internalBeneficiary/:id';

export const LOGO_MANAGEMENT_PAGE_ROUTE = '/logo';
export const LOGO_CREATE_PAGE_ROUTE = `${LOGO_MANAGEMENT_PAGE_ROUTE}/create`;
export const LOGO_EDIT_PAGE_ROUTE = `${LOGO_MANAGEMENT_PAGE_ROUTE}/edit/:id`;

export const B_PARTNER_MANAGEMENT_PAGE_ROUTE = '/b-partner';
export const B_PARTNER_CREATE_PAGE_ROUTE = `${B_PARTNER_MANAGEMENT_PAGE_ROUTE}/create`;
export const B_PARTNER_EDIT_PAGE_ROUTE = `${B_PARTNER_MANAGEMENT_PAGE_ROUTE}/edit/:id`;

export const PROTOCOL_MANAGEMENT_PAGE_ROUTE = '/protocol';
export const PROTOCOL_CREATE_PAGE_ROUTE = `${PROTOCOL_MANAGEMENT_PAGE_ROUTE}/create`;
export const PROTOCOL_EDIT_PAGE_ROUTE = `${PROTOCOL_MANAGEMENT_PAGE_ROUTE}/edit/:id`;

export const LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE = '/legalEntity';
export const LEGAL_ENTITY_CREATE_PAGE_ROUTE = `${LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE}/create`;
export const LEGAL_ENTITY_EDIT_PAGE_ROUTE = `${LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE}/edit/:id`;

export const BRAND_MANAGEMENT_PAGE_ROUTE = '/brand';
export const BRAND_CREATE_PAGE_ROUTE = `${BRAND_MANAGEMENT_PAGE_ROUTE}/create`;
export const BRAND_EDIT_PAGE_ROUTE = `${BRAND_MANAGEMENT_PAGE_ROUTE}/edit/:id`;
