import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Loader from '../../components/Loader';
import { CoreozInputBase, CoreozSelectBase } from '../../lib/coreoz-form-base/index';
import { LegalEntityStatus, ProtocolStatus } from '../../enum';
import Pagination from 'react-js-pagination';
import { SearchCriteriaType, SearchResultType } from '../../types/LegalEntityManagement';
import legalEntityApi from '../../network/api/legalEntityApi';
import userApi from '../../network/api/userApi';
import ProtocolTable from '../../components/protocol/protocolTable';
import LegalEntityContext from '../../stores/legal-entity-management-context';
import LegalEntityTable from '../../components/legalEntity/legalEntityTable';
import exportApi from '../../network/api/exportApi';
import { download } from '../../utils';
import { notifyError } from '../../network/notification';
import ButtonExport from '../../components/ButtonExport';
import { B_PARTNER_EDIT_PAGE_ROUTE, LEGAL_ENTITY_CREATE_PAGE_ROUTE, LEGAL_ENTITY_EDIT_PAGE_ROUTE } from '../../routes';
import hasPermission from '../../services/permissionService';
import { currentSessionService } from '../../services/sessionServiceInstance';

const LegalEntityManagement = (props) => {
  const { searchCriteriaContext, updateSearchCriteriaContext } = useContext(LegalEntityContext);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSearchResult, setTotalSearchResult] = useState(0);
  const [businessUnits, setBusinessUnits] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [value, setValue] = useState();
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    fetchBusinessUnits();
    if (searchCriteriaContext) {
      fetchLegalEntity(searchCriteriaContext);
    } else {
      fetchLegalEntity({
        page,
        size: pageSize,
      });
    }
  }, []);


  async function fetchLegalEntity(criteria: SearchCriteriaType) {
    setLoading(true);

    const searchCriteria = {
      ...criteria,
      page: criteria.page || page,
      size: criteria.size || pageSize,
    };


    const searchResultResponse = await props.dispatch(legalEntityApi.search(searchCriteria));

    updateSearchCriteriaContext({ ...searchCriteria });

    setLoading(false);

    setSearchResults((searchResultResponse) ? searchResultResponse.content : []);

    setTotalSearchResult((searchResultResponse) ? searchResultResponse.total : 0);
    setPerPage((searchResultResponse) ? searchResultResponse.perPage : 0);
    setTotalPages((searchResultResponse) ? searchResultResponse.totalPages : 0);
  }

  async function fetchBusinessUnits() {
    const businessUnitsResponse = await userApi.fetchBusinessUnits().then(response => response.json());
    console.log(currentSessionService().currentUser().businessUnitId);

      const buList = businessUnitsResponse.filter(v => hasPermission('EDITABLE_BUSINESS_UNIT_LEGAL_ENTITY') || v.id === currentSessionService().currentUser().businessUnitId.toString());

      setBusinessUnits(buList);

  }

  const handlePageChange = (pageNumber) => {
    fetchLegalEntity({
      ...searchCriteriaContext,
      page: pageNumber,
    });
    setPage(pageNumber);
  };

  const resetSearchResults = () => {
    setSearchResults([]);
    setTotalSearchResult(0);
    setPage(1);
    setTotalPages(0);

    fetchLegalEntity({
      page,
      size: pageSize,
    });
  };

  const goToView = (itemId: number) => {
    props.history.push(LEGAL_ENTITY_EDIT_PAGE_ROUTE.replace(':id', itemId));
  };


  const downloadReport = (searchParameters: Object) => {
    setExportLoading(true);

    const filters = {
      ...searchParameters,
      columnsTranslation: I18n.t('legalEntity.export'),
    };

    exportApi
      .exportLegalEntityKpi(filters)
      .then(response => response.blob())
      .then(blob =>
        download(blob, 'export_legal_entity.csv', () => {
          setExportLoading(false);
        })).catch((e) => {
        setExportLoading(false);
        notifyError(props.dispatch)(e);
      });
  };

  return (
    <div className="legal-entity-management-container" id="legalEntityManagement">
      <div className="title-container">
        <div className="title">{I18n.t('legalEntity.TITLE_LIST')}</div>
      </div>
      <div className="search-bar">
        <Form
          onSubmit={fetchLegalEntity}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>

              <Field
                name="legalEntityCode"
                id="legalEntityCodeSearch"
                label={I18n.t('legalEntity.label.LEGAL_ENTITY_CODE')}
                component={CoreozInputBase}
                className="m-0"
                defaultValue={searchCriteriaContext && searchCriteriaContext.legalEntityCode}
                maxLength="9"
                format={stringValue => stringValue && stringValue.replace(/[^\d]/g, '') || ''}
              />
              <Field
                name="legalEntityName"
                id="legalEntityNameSearch"
                label={I18n.t('legalEntity.label.LEGAL_ENTITY_NAME')}
                component={CoreozInputBase}
                className="m-0"
                defaultValue={searchCriteriaContext && searchCriteriaContext.legalEntityName}
              />


              <div className="layout-third-row search-box pt-4">
                <Button type="submit" color="primary" className="validation-button m-0" id="searchBtnSearch">
                  <i className="fa fa-search" />{I18n.t('button.SEARCH')}
                </Button>
                <Button type="button" color="primary" className="validation-button m-0" onClick={() => { form.reset(); resetSearchResults(); }} id="resetBtnSearch">
                  <i className="fa fa-redo-alt" />{I18n.t('button.RESET')}
                </Button>
              </div>

              <Field
                name="businessUnitId"
                id="businessUnitId"
                autoComplete="off"
                type="text"
                className="m-0"
                component={CoreozSelectBase}
                label={I18n.t('legalEntity.label.BUSINESS_UNIT')}
                list={businessUnits && businessUnits.map(bu => ({
                    id: bu.id,
                    label: bu.name,
                  }))}
                required={false}
                placeholder="All"
                disabledPlaceholder={false}
                defaultValue={searchCriteriaContext && searchCriteriaContext.businessUnit}
              />

              <Field
                name="status"
                id="status"
                type="text"
                className="m-0"
                component={CoreozSelectBase}
                label={I18n.t('legalEntity.label.STATUS')}
                placeholder="All"
                disabledPlaceholder={false}
                list={Object.keys(LegalEntityStatus).map(v => ({ id: v, label: I18n.t(`legalEntity.STATUS.${v}`) }))}
                required={false}
                defaultValue={searchCriteriaContext && searchCriteriaContext.status}
              />
              <div className="layout-third-row search-box pt-4">

                <ButtonExport downloadReport={downloadReport} loading={exportLoading} values={values} />
                <Button type="button" color="primary" className="validation-button m-0" onClick={() => { props.history.push(LEGAL_ENTITY_CREATE_PAGE_ROUTE); }} id="createButton">
                  <i className="fa fa-plus" />{I18n.t('legalEntity.button.ADD')}
                </Button>
              </div>
            </form>
                    )}
        />
      </div>

      <div className="search-result">
        {
                    !loading && !!searchResults.length &&
                    <>
                      <div className="box-result">
                        <LegalEntityTable currentItems={searchResults} goToView={goToView} />
                      </div>
                      <div className="box-paginate">
                        <div className="show-paginate-result">
                          {I18n.t('legalEntity.TABLE.PAGINATION_INFO', { start: ((page - 1) * pageSize) + 1, end: ((page - 1) * pageSize) + pageSize, total: totalSearchResult })}
                        </div>
                        <div className="paginate-list">
                          <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="&laquo;"
                            lastPageText="&raquo;"
                            prevPageText="&lsaquo;"
                            nextPageText="&rsaquo;"
                            activePage={page}
                            itemsCountPerPage={totalPages}
                            totalItemsCount={totalSearchResult}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </>
                }
        {!loading && !searchResults.length &&
          <div className="no-result">{I18n.t('internalBeneficiary.SEARCH.NO_RESULT')}</div>
          }
        {
              loading && <div className="center-child search-loader"><Loader /></div>
          }
      </div>
    </div>
  );
};

export default withRouter(connect()(LegalEntityManagement));
