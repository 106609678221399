// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'reactstrap';
import { Field } from 'react-final-form';
import 'react-phone-input-2/lib/style.css';
import Button from 'reactstrap/es/Button';
import {
  CoreozDatePickerBase as CoreozDatePicker,
  CoreozInputBase as CoreozInput,
  CoreozSelectBase as CoreozSelect,
} from '../../../lib/coreoz-form-base/index';
import moment from 'moment';
import { connect } from 'react-redux';
import { dateValidator, required, validateEmail, validateEmailFormat, validateForEmail } from '../../../validator';
import PlmPanel from '../../../components/panel/PlmPanel';
import businessUnitApi from '../../../network/api/businessUnitApi';
import { notifyError } from '../../../network/notification';
import CoreozPhoneInput from '../../../components/CoreozPhoneInput';

type Props = {
  dispatch: Function,
  countries: Array<Object>,
  change: Function,
  values: {
    mobilePhone: String,
  },
  caseInfos: Object,
  resendPdaLinkFunction: ?Function,
  phoneValid?: Function,
  preferredCountries: Array<String>,
};

type State = {
  phoneNumberIsNotValid: String,
};

class Identity extends React.Component<Props, State> {
  static defaultProps = {
    phoneValid: () => {
    },
  };

  state = {
    phoneNumberIsNotValid: null,
  }

  componentDidMount(): void { }

  render() {
    return (
      <Row>
        <Col md="12" lg="6">
          <PlmPanel className="identity" title="">
            <span className="title-panel">{I18n.t('administration.identity.TITLE')}</span>
            <div className="radio-button-form-container container-input-coreoz">
              <div className="radio-button-label-container civility-status">
                {I18n.t('administration.identity.CIVILITY')}
              </div>
              <div className="radio-button-container">
                <Field
                  component="input"
                  name="genderCode"
                  type="radio"
                  value="1"
                  validate={required}
                  required
                  autoComplete="none"
                  disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                />
                <span>{I18n.t('administration.identity.M')}</span>
                <div>
                  <Field
                    className="base-input-coreoz"
                    component="input"
                    name="genderCode"
                    type="radio"
                    value="2"
                    validate={required}
                    required
                    autoComplete="none"
                    disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                  />
                </div>
                <span>{I18n.t('administration.identity.MME')}</span>

              </div>
            </div>

            <Field
              label={I18n.t('administration.identity.FIRSTNAME')}
              component={CoreozInput}
              name="firstName"
              validate={required}
              required
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.identity.LASTNAME')}
              component={CoreozInput}
              name="lastName"
              validate={required}
              required
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.identity.BIRTHNAME')}
              component={CoreozInput}
              name="birthName"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.identity.EMAIL')}
              component={CoreozInput}
              name="email"
              type="email"
              required={this.props.values.noEmailAddress === 'false'}
              validate={e => validateEmail(e, this.props.values.noEmailAddress === 'false')}
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            {
              this.props.resendPdaLinkFunction &&
              <Button
                className="float-right"
                color="btn secondary"
                type="button"
                onClick={this.props.resendPdaLinkFunction}
              >
                <i className="button-icon fas fa-undo" />{I18n.t('administration.identity.RESEND_PDA_LINK')}
              </Button>
            }
            <div className="checkbox-form-container container-input-coreoz">
              <div className="checkbox-container">
                <Field
                  component="input"
                  name="noEmailAddress"
                  type="checkbox"
                  format={value => (value === 'true')}
                  parse={value => String(value)}
                  autoComplete="none"
                  disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                />
              </div>
              <div className="checkbox-label-container">
                {I18n.t('administration.identity.EMAIL_BOOLEAN')}
              </div>
            </div>
            {
              this.props.preferredCountries.length &&
              <CoreozPhoneInput
                required
                preferredCountries={this.props.preferredCountries}
                value={this.props.values.mobilePhone}
                label={I18n.t('administration.identity.PHONE_NUMBER')}
                onChange={newValue => this.props.change('mobilePhone', newValue)}
                setPhoneIsValid={this.props.phoneValid}
                disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
              />
            }
          </PlmPanel>
        </Col>
        <Col md="12" lg="6">
          <PlmPanel title="">
            <span className="title-panel">{I18n.t('administration.identity.birth.TITLE')}</span>
            <Field
              label={I18n.t('administration.identity.birth.BIRTHDATE')}
              component={CoreozDatePicker}
              onlyDate
              validate={e => dateValidator(e, I18n.t('date.DATE_FORMAT_ADMINISTRATION_FILE'), true)}
              dateFormat={I18n.t('date.DATE_FORMAT')}
              name="birthDate"
              format={(stringValue) => {
                if (stringValue) {
                  if (moment(stringValue, 'DD/MM/YYYY', true).isValid()) {
                    return moment(stringValue, 'DD/MM/YYYY');
                  }
                  if (moment(stringValue, 'YYYY-MM-DD', true).isValid()) {
                    return moment(stringValue, 'YYYY-MM-DD');
                  }
                }
                return stringValue;
              }}
              parse={momentValue => (momentValue && moment.isMoment(momentValue) ? momentValue.format('YYYY-MM-DD') : momentValue)}
              required
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.identity.birth.COUNTRY')}
              component={CoreozSelect}
              name="birthCountry"
              validate={required}
              list={this.props.countries}
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.identity.birth.CITY')}
              component={CoreozInput}
              name="birthCity"
              validate={required}
              required
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
          </PlmPanel>

          <PlmPanel title="">
            <span className="title-panel">{I18n.t('administration.identity.address.TITLE')}</span>

            <Field
              label={I18n.t('administration.identity.address.STREET')}
              component={CoreozInput}
              name="addressStreet"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.identity.address.POSTCODE')}
              component={CoreozInput}
              name="addressPostalCode"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.identity.address.CITY')}
              component={CoreozInput}
              name="addressCity"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.identity.address.COUNTRY')}
              component={CoreozInput}
              name="addressCountry"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
          </PlmPanel>
        </Col>
      </Row>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(Identity));

