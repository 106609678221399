// @ flow

import React from 'react';

import { SMS_DETAILS_PAGE_ROUTE, SMS_PAGE_ROUTE } from '../../routes';
import Sms from './Sms';
import SmsDetails from './SmsDetails';
import PrivateRoute from '../../components/PrivateRoute';

function SmsRoutes() {
  return (
    <div>
      <PrivateRoute exact path={SMS_PAGE_ROUTE} component={Sms} />
      <PrivateRoute path={SMS_DETAILS_PAGE_ROUTE} component={SmsDetails} />
    </div>
  );
}

export default SmsRoutes;
