// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import PlmPanel from '../panel/PlmPanel';
import { CoreozInputBase as CoreozInput } from '../../lib/coreoz-form-base/index';

type Props = {
  search: Function,

}

class BusinessUnitSearch extends React.PureComponent<Props> {
  render() {
    return (
      <PlmPanel title={I18n.t('businessUnit.SEARCH_TITLE')}>
        <Form
          onSubmit={this.props.search}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="panel-body">
                <Row className="search-form">
                  <Col>
                    <Field
                      type="text"
                      component={CoreozInput}
                      name="name"
                      label={I18n.t('businessUnit.label.name')}
                    />
                  </Col>
                </Row>
              </div>
              <div className="panel-footer">
                <button
                  className="btn btn-primary"
                  type="submit"
                >
                  <Translate value="actions.SEARCH" />
                </button>
              </div>
            </form>
          )}
        />
      </PlmPanel>
    );
  }
}

export default withRouter(connect()(BusinessUnitSearch));
