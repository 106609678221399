// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

type Props = {
  openDetails?: Function,
  events: Object[],
  defaultDate: Date,
  clickableEvent?: boolean,
};

type State = {};
const localizer = momentLocalizer(moment);

function CalendarContainer(props: Props) {
  return (
    <div className={`calendar${props.clickableEvent ? '' : ' no-clickable'}`}>
      <Calendar
        localizer={localizer}
        events={props.events}
        views={[Views.WEEK, Views.MONTH]}
        defaultView={Views.WEEK}
        onSelectEvent={event => props && props.openDetails && props.openDetails(event)}
        style={{ height: 780 }}
        messages={
          {
            next: I18n.t('calendar.next'),
            previous: I18n.t('calendar.previous'),
            today: I18n.t('calendar.today'),
            month: I18n.t('calendar.month'),
            week: I18n.t('calendar.week'),
          }}
        defaultDate={props.defaultDate}
        eventPropGetter={
          (event, start, end, isSelected) => ({
            style: {
              backgroundColor: event.new ? '#24e60e' : (event.isEmpty ? '#0e86e6' : '#f0ad4e'), // todo mettre les couleurs dans une constante
            },
          })
        }
      />
    </div>
  );
}

export default withRouter(CalendarContainer);
