// @ flow

import React from 'react';
import { INTERNAL_BENEFICIARY_MANAGEMENT_PAGE_ROUTE, INTERNAL_BENEFICIARY_DETAIL_PAGE_ROUTE } from '../../routes';
import InternalBeneficiaryManagement from './InternalBeneficiaryManagement';
import InternalBeneficiaryDetail from './InternalBeneficiaryDetail';
import PrivateRoute from '../../components/PrivateRoute';
import { InternalBeneficiaryContextProvider } from '../../stores/internal-beneficiary-management-context';

export default function InternalBeneficiaryManagementRoutes() {
  return (
    <InternalBeneficiaryContextProvider>
      <PrivateRoute exact path={INTERNAL_BENEFICIARY_MANAGEMENT_PAGE_ROUTE} component={InternalBeneficiaryManagement} />
      <PrivateRoute exact path={INTERNAL_BENEFICIARY_DETAIL_PAGE_ROUTE} component={InternalBeneficiaryDetail} />
    </InternalBeneficiaryContextProvider>
  );
}
