// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import DashboardTile from '../../dashboard/DashboardTile';
import Tile from '../../../components/Tile';
import type { Case } from '../../../types/CasesTypes';

type Information = {
  firstName: string,
  lastName: string,
  birthdate: string,
  policy: string,
};

type Props = {
  hideOpenCase: Function,
  openCases: Case[],
  firstInfo: Information,
};

type State = {};

class EligibilityOpenCase extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <Tile className="tile-eligibility eligibility-found">
          <i className="fa fa-user-check" />
          <div className="eligibility-result">
            {I18n.t('processCase.eligibility.result.EXIST_AND_OPEN_CASES', { patient: ` ${this.props.firstInfo.firstName} ${this.props.firstInfo.lastName}` })}
          </div>
        </Tile>
        <div className="open-cases-container">
          {this.props.openCases.map(openCase => (
            <DashboardTile
              key={openCase.id}
              channel={openCase.channel}
              patientName={openCase.patientName}
              caseDateCreation={openCase.startDateAppointment || openCase.dateCreation}
              brand={openCase.brandName}
              partnerName={openCase.bpartnerName}
              reasonForTheCall={openCase.reasonForTheCall}
              userName={openCase.userName}
              appointment={!!openCase.startDateAppointment}
              protocolNumber={openCase.protocolNumber}
              speciality={openCase.speciality}
              caseId={openCase.id}
              patientId={openCase.patientId}
              processStatus={openCase.processStatus}
              processCase
              videoStatus={openCase.videoStatus}
              chatStatus={openCase.chatStatus}
              caseQualification={openCase.caseQualification}
              sessionStartDate={openCase.sessionStartDate}
            />
          ))
          }
          <Button
            className="create-btn"
            color="primary"
            onClick={() => this.props.hideOpenCase()}
          >
            <i className="button-icon fa fa-plus-circle" />{I18n.t('button.CREATE')}
          </Button>
        </div>
      </div>
    );
  }
}

export default EligibilityOpenCase;
