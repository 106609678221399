// @flow

import React from 'react';
import { Button } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import ModalEprm from './ModalEprm';

type Props = {
  modal: boolean,
  toggle: Function,
  newUsername: string,
}

class ModalAssigned extends React.PureComponent<Props> {
  render() {
    return (
      <ModalEprm
        title={I18n.t('changeTaken.title')}
        modal={this.props.modal}
        toggle={this.props.toggle}
        className="modal-EPRM"
      >
          <span
            className="modal-transfer-title">{I18n.t('changeTaken.text', { username: this.props.newUsername })}</span>
        <div className="modal-transfer-action">
          <Button
            color="secondary"
            onClick={this.props.toggle}
          >
            <i className="button-icon fa fa-undo" />
            {I18n.t('button.CONTINUE')}
          </Button>
        </div>
      </ModalEprm>
    );
  }
}

export default ModalAssigned;
