// @flow

import React, { Fragment } from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Tile from '../Tile';
import type { CaseSearchResultType } from '../../types/CasesTypes';
import { CaseLifeStatus, CaseStatus as CaseStatusEnum, CommunicationChannel, UserRole, UserTypeRequired } from '../../enum';
import { CASE_PAGE_ROUTE } from '../../routes';

type Props = {
  caseFound: CaseSearchResultType,
  history: {
    push: Function,
  },
  lastSearchObject: Object,
  showPolicy: boolean,
}

type TextProps = {
  i18nAccessor: string,
  text: string,
}

const TextLabel = ({ i18nAccessor, text }: TextProps) => (
    <div className="tile-text">
      <div className="tile-label">{I18n.t(i18nAccessor)}</div>
      <div className="tile-value">{text}</div>
    </div>);


class CaseSearchResultTile extends React.Component<Props> {
  channelIcon = () => {
    let icon = '';
    if (this.props.caseFound.channel === CommunicationChannel.PHONE) {
      icon = 'fa-phone-volume';
    } else if (this.props.caseFound.channel === CommunicationChannel.VIDEO) {
      icon = 'fa-video';
    } else {
      icon = 'fa-comments';
    }

    if (this.props.caseFound.appointmentDate) {
      return (<i className="channel-icon--tl far fa-calendar-day">
        <i className={`channel-icon--br fa ${icon}`} />
      </i>);
    }
    return <i className={`channel-icon--mm fa ${icon}`} />;
  };

  getIcon = () => (this.props.caseFound.userTypeRequired === UserTypeRequired.NURSE_LEVEL ? 'fa-syringe' : 'fa-stethoscope');

  getMessage = () => {
    if (this.props.caseFound.processStatus === CaseStatusEnum.REQUIRED) {
      if (this.props.caseFound.userTypeRequired === UserTypeRequired.NURSE_LEVEL) {
        return I18n.t('dashboard.CASE_STATUS_NURSE_REQUIRED');
      }
      if (this.props.caseFound.userTypeRequired === UserTypeRequired.DOCTOR_LEVEL) {
        return I18n.t('dashboard.CASE_STATUS_DOCTOR_REQUIRED');
      }
    }
    if (this.props.caseFound.processStatus === CaseStatusEnum.ASSIGNED) {
      return I18n.t('dashboard.ASSIGNED_TO');
    }
    if (this.props.caseFound.processStatus === CaseStatusEnum.TAKEN_IN_CHARGE) {
      return I18n.t('dashboard.TAKEN_IN_CHARGE_BY');
    }
    return '';
  };
  displayName = () => {
    if (this.props.caseFound.processStatus === CaseStatusEnum.TAKEN_IN_CHARGE
      || this.props.caseFound.processStatus === CaseStatusEnum.ASSIGNED) {
      return this.props.caseFound.updatedName;
    }
    return '';
  };

  render() {
    return (
      <Tile
        className="tile-case"
        key={this.props.caseFound.id}
      >
        <div className="container-channel">
          {this.channelIcon()}
        </div>
        <div className="patient-id">
          <div>
            <div className="case-name">
              {this.props.caseFound.caseName}
            </div>
            <div
              className="name"
              title={this.props.caseFound.patientName}
            >{this.props.caseFound.patientName}
            </div>
            <div className="date-creation">
              {this.props.caseFound.appointmentDate ? moment(this.props.caseFound.appointmentDate).format(I18n.t('date.DATE_LONG')) : moment(this.props.caseFound.createdOn).format(I18n.t('date.DATE_LONG'))}
            </div>
          </div>
        </div>
        { this.props.showPolicy &&
          <div className="policy-info">
            <div>
              <TextLabel
                  i18nAccessor="administration.policy.policy.POLICY_NUMBER"
                  text={this.props.caseFound.policyNumber ? this.props.caseFound.policyNumber : '--'}
              />
            </div>
          </div>
        }

        <div className="case-status">
          {
            this.props.caseFound.status === CaseLifeStatus.INACTIVE ?
              I18n.t('caseSearch.label.ARCHIVED') :
              <>
                <i className={`status-icon fa ${this.getIcon()}`} />
                <div>
                  <div className="required"> {this.getMessage()}</div>
                  <div className="waiting">
                    {this.displayName()}
                  </div>
                </div>
              </>
          }
        </div>
        <div className="last-edit-info">
          {this.props.caseFound.lastEdit.userFirstName &&
            <Fragment>
              {this.props.caseFound.createdUserDetail && Object.keys(this.props.caseFound.createdUserDetail).length !== 0 && this.props.caseFound.createdUserDetail.firstName !== '' &&
                <div className="user-name">
                  {I18n.t('caseSearch.CREATED_BY', { firstname: this.props.caseFound.createdUserDetail.firstName, lastname: this.props.caseFound.createdUserDetail.lastName })}
                </div>
              }
              <div className="user-name">
                {I18n.t('caseSearch.LAST_EDIT', { firstname: this.props.caseFound.lastEdit.userFirstName, lastname: this.props.caseFound.lastEdit.userLastName })}
              </div>
              <div className="edit-date">{moment(this.props.caseFound.updatedOn).format(I18n.t('date.DATE_LONG'))}</div>
            </Fragment>
          }
        </div>
        <div className="open-case">
          <Button
            color="primary"
            onClick={() => this.props.history.push(`${CASE_PAGE_ROUTE}/${this.props.caseFound.id}`, this.props.lastSearchObject)}
          >
            <i className="button-icon fa fa-folder-open" />{I18n.t('caseSearch.OPEN_CASE')}
          </Button>
        </div>
      </Tile>
    );
  }
}

export default withRouter(CaseSearchResultTile);
