// @flow

import es from 'react-phone-input-2/lang/es.json';
import fr from 'react-phone-input-2/lang/fr.json';
import de from 'react-phone-input-2/lang/de.json';
import it from 'react-phone-input-2/lang/it.json';

import {
	CONSENTS_PAGE_ROUTE,
	ELIGIBILITY_PAGE_ROUTE,
	IDENTIFICATION_PAGE_ROUTE,
	PROCESS_DETAILS_PAGE_ROUTE,
	QUALIFICATION_PAGE_ROUTE,
	SENDING_DOCUMENTS_PAGE_ROUTE,
	TELECONSULTATION_PAGE_ROUTE,
} from './routes';
import pathologyCodingApi from './network/api/pathologyCoding';

export const CaseStatus = {
	REQUIRED: 'REQUIRED',
	TAKEN_IN_CHARGE: 'TAKEN_IN_CHARGE',
	ASSIGNED: 'ASSIGNED',
};

export const Availability = {
	AVAILABLE: 'available',
	BUSY: 'busy',
};

export const UserRole = {
	NURSE_LEVEL: 'NURSE_LEVEL',
	DOCTOR_LEVEL: 'DOCTOR_LEVEL',
	NURSE_DOCTOR_LEVEL: null,
	COMPLETED: 'COMPLETED',
};

export const MedicalRessourcesLinks = {
	MEDICATION: 'MEDICATION',
	MEDICAL_SEARCH: 'MEDICAL_SEARCH',
	VARIOUS: 'VARIOUS',
	TRAVELERS: 'TRAVELERS',
};

export const CommunicationChannel = {
	PHONE: 'PHONE',
	VIDEO: 'VIDEO',
	CHAT: 'CHAT',
};

export const CommunicationStatus = {
	NOT_AVAILABLE: 'NOT_AVAILABLE',
	NOT_STARTED: 'NOT_STARTED',
	WAITING: 'WAITING',
	IN_PROGRESS: 'IN_PROGRESS',
	COMPLETED: 'COMPLETED',
	DROPPED: 'DROPPED',
	PATIENT_OUT: 'PATIENT_OUT',
};

export const Language = {
	ENGLISH: 1,
	FRENCH: 2,
};

export const LanguageIsoCode = {
	ENGLISH: 'en',
	FRENCH: 'fr',
	GERMAN: 'de',
	SPANISH: 'es',
	ITALIAN: 'it',
};

export const phoneInputLanguage = {
	[LanguageIsoCode.ENGLISH]: undefined,
	[LanguageIsoCode.FRENCH]: fr,
	[LanguageIsoCode.GERMAN]: de,
	[LanguageIsoCode.SPANISH]: es,
	[LanguageIsoCode.ITALIAN]: it,
};

export const Appointment = {
	TODAY: 'TODAY',
	LATER: 'LATER',
};

export const ProcessSteps = [
	IDENTIFICATION_PAGE_ROUTE,
	ELIGIBILITY_PAGE_ROUTE,
	PROCESS_DETAILS_PAGE_ROUTE,
	CONSENTS_PAGE_ROUTE,
	QUALIFICATION_PAGE_ROUTE,
	TELECONSULTATION_PAGE_ROUTE,
	SENDING_DOCUMENTS_PAGE_ROUTE,
];

export const StatusOfCase = {
	ACTIVE: '0',
	ARCHIVED: '1',
};

export const CaseLifeStatus = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
	CLOSED: 'CLOSED',
};

export const teleAdviceCode = '283030000';
export const teleConsultCode = '283030001';
export const otherCode = 'null';

export const VideoCode = '283030000';
export const TelephoneCode = '283030001';
export const ChatCode = '283030002';

export const ModalTransferDoctor = {
	DOCTOR_REQUIRED: 'DOCTOR_REQUIRED',
	DOCTOR: 'Doctor',
};

export const Roles = {
	NURSE: 'NURSE',
	DOCTOR: 'DOCTOR',
};

export const PrequalificationCode = {
	TELECONSULTATION: teleConsultCode,
	TELEADVICE: teleAdviceCode,
	OTHER: otherCode,
};

export const DocumentType = {
	PRESCRIPTION: 'Prescription',
	MEDICAL_REPORT: 'MEDICAL_REPORT',
	ADVICE_REPORT: 'ADVICE_REPORT',
};

export const typeModification = {
	CREATE: 'CREATE',
	SEND: 'SEND',
	ARCHIVE: 'ARCHIVE',
	REOPEN: 'REOPEN',
	DELETE: 'DELETE',
};

export const PolicySearchOrigin = {
	BENIFICIARY: 'benificiary',
	POLICY_HOLDER: 'policyHolder',
};

export const reasonToCallNonMed = 'reasonToCallNonMed';
export const awareEmergencyServices = 'awareEmergencyServices';
export const orientationToEmergencyServices = 'orientationToEmergencyServices';
export const awareToContact = 'awareToContact';
export const emergencyDetected = 'emergencyDetected';
export const technicalIssue = 'technicalIssue';
export const medicalExam = 'medicalExam';
export const caseQualification = 'caseQualification';
export const secureLink = 'SECURE_LINK';
export const sharingConsents = 'sharingConsents';
export const treatmentConsent = 'treatmentConsent';
export const familyDocConsent = 'familyDocConsent';
export const statusCase = 'statusCase';
export const processStatus = 'processStatus';
export const pharmacySend = 'PHARMACY';
export const MEDICINE = 'MEDICINE';
export const genderCode = 'genderCode';
export const ADDITIONAL_NOTES = 'ADDITIONAL_NOTES';
export const beneficiaryBirthday = 'beneficiaryBirthday';
export const beneficiaryFirstName = 'beneficiaryFirstName';
export const beneficiaryLastName = 'beneficiaryLastName';
export const policyHolderLastName = 'policyHolderLastName';
export const policyHolderFirstName = 'policyHolderFirstName';
export const partnerName = 'partnerName';
export const protocolNumber = 'protocolNumber';

export const ghostPolicy = {
	POLICY_NUMBER: '123456789',
	PROTOCOL_NUMBER: '987654321',
	PARTNER_NAME: 'Allianz Travel',
	LEGAL_ENTITY_NAME: 'AWP P&C',
};

export const SendBy = {
	PHARMACY: 'PHARMACY',
	SECURE_LINK: 'SECURE_LINK',
};
export const PharmacyCriteria = {
	NAME: 'name',
	ADDRESS: 'address',
	CITY: 'city',
	COUNTRY: 'countryCode',
	ZIP_CODE: 'postalCode',
};

export const CodingCriteria = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};

export const SendType = {
	MAIL: 'mail',
	SMS: 'sms',
};

export const RecordingReason = {
	NEW_CASE_DASHBOARD_BUTTON: 'NEW_CASE_DASHBOARD_BUTTON',
	WRONG_SERVICE: 'WRONG_SERVICE',
	BACK_TO_DASHBOARD: 'BACK_TO_DASHBOARD',
};

export const DummyPolicyCmcCic = 'POLICE FICTIVE CM/CIC';
export const DummyPolicyItaly = 'DUMMY ITALIAN POLICY';

export const UserType = {
	NURSE: 'NURSE',
	DOCTOR: 'DOCTOR',
};

export const PageMode = {
	CREATE: 'CREATE',
	EDIT: 'EDIT',
	VIEW: 'VIEW',
};

export const PathologyCodingCriteria = {
	ACTIVE: 'true',
	INACTIVE: 'false',
};

export const BpartnerStatus = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};

export const ProtocolStatus = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};

export const UserTypeRequired = {
	NURSE_LEVEL: 'NURSE_LEVEL',
	DOCTOR_LEVEL: 'DOCTOR_LEVEL',
	COMPLETED: 'COMPLETED',
};

export const LegalEntityStatus = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};

export const logoStatus = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};

export const BrandStatus = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};

export const AdviceCodingStatus = {
	ACTIVE: 'true',
	INACTIVE: 'false',
};
export const typeOfBPartner = {
	OTHER: '1',
	CM_CIC: '2',
	VISA: '3',
};
