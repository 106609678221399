// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import Button from 'reactstrap/es/Button';
import ModalEprm from '../../modal/ModalEprm';
import notificationApi from '../../../network/api/notificationApi';
import { notifyError, notifySuccess } from '../../../network/notification';
import { connect } from 'react-redux';
import { currentSessionService } from '../../../services/sessionServiceInstance';

type Props = {
  caseId: string,
  closedSelf: Function,
  dispatch: Function,
  openVideoWindow: Function,
  sessionReady: boolean,
}

type States = {
  message: string,
}

class VideoConfirmationMail extends React.Component<Props, States> {

  SendVideoMailButton = (color) => <Button
    color={color}
    onClick={() => {
      notificationApi
        .sendEmailInitiateVideo(this.props.caseId)
        .then(() => this.props.dispatch(notifySuccess(this.props.dispatch, null, I18n.t('notify.success.SEND_EMAIL')))).then(() => this.props.closedSelf())
        .catch(this.props.dispatch(notifyError));
    }}
  >
    <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.SEND_VIDEO_LINK_EMAIL')}
  </Button>

  OpenVideoWindowButton = () => <Button
    color="primary"
    onClick={this.props.openVideoWindow}
  >
    <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.START_VIDEO')}
  </Button>

  render() {
    const canResendMail = currentSessionService().currentUser().canResendMailVideo;
    return (
      <ModalEprm
        title={canResendMail ? I18n.t('contact.video.TITLE_2') : I18n.t('contact.video.TITLE')}
        modal
        toggle={this.props.closedSelf}
        className="contact-popin"
        size="lg"
      >
        {
          canResendMail && this.props.sessionReady ?
            <div className="take-in-charge-message">
              {I18n.t('contact.video.SEND_VIDEO_LINK_CONFIRMATION_OR_OPEN_VIDEO')}
            </div>
            :
            <div className="take-in-charge-message">
              {I18n.t('contact.video.SEND_VIDEO_LINK_CONFIRMATION')}
            </div>
        }

        <div className="take-in-charge-buttons">
          <Button
            color="secondary"
            onClick={this.props.closedSelf}
          >
            <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
          </Button>

          {
            canResendMail ?
              <div>
                {this.SendVideoMailButton(this.props.sessionReady ? 'secondary' : 'primary')}
                {' '}
                {this.props.sessionReady && this.OpenVideoWindowButton()}
              </div>
              :
              <div>
                {this.SendVideoMailButton()}
              </div>
          }
        </div>
      </ModalEprm>
    );
  }
}

export default withRouter(connect()(VideoConfirmationMail));
