// @flow

import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { I18n, Translate } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase as CoreozInput } from '../../lib/coreoz-form-base/index';
import {
  ADDITIONAL_NOTES,
  awareEmergencyServices,
  awareToContact,
  caseQualification,
  emergencyDetected,
  familyDocConsent,
  genderCode,
  medicalExam,
  MEDICINE,
  orientationToEmergencyServices,
  partnerName,
  pharmacySend, processStatus,
  protocolNumber,
  reasonToCallNonMed,
  secureLink,
  sharingConsents,
  statusCase,
  technicalIssue,
  treatmentConsent,
  typeModification,
} from '../../enum';

type Props = {
  caseId: String,
  goBack: Function,
  caseHistory: Object,
  searchKeyWordHistory: Function,
  callCategory: Object,
  countries: Object,
  pathologyCoding?: Object,
  icd10?: Object,
}

type State = {
  // caseHistory: []
}

class HistoryModification extends React.Component<Props, State> {
  componentDidMount = () => {
  };

  onSubmit = (values) => {
    this.props.searchKeyWordHistory(values['search-field']);
  };

  fetchValue = (field: string, value: string) => {
    if ([reasonToCallNonMed,
      awareEmergencyServices,
      awareToContact,
      emergencyDetected,
      technicalIssue,
      medicalExam,
      caseQualification,
      secureLink,
      sharingConsents,
      treatmentConsent,
      familyDocConsent,
      statusCase,
      processStatus,
      MEDICINE,
      ADDITIONAL_NOTES,
      awareEmergencyServices,
      orientationToEmergencyServices,
      partnerName,
      protocolNumber,
    ].includes(field)) {
      return I18n.t(`historyModifications.${value}`);
    } else if ([genderCode].includes(field)) {
      return I18n.t(`historyModifications.gender.${value}`);
    } else {
      return <div dangerouslySetInnerHTML={{ __html: value }} />;
    }
  };

  getDocumentName = (documentName: string) => {
    if (documentName === null) {
      return '';
    } else {
      return documentName;
    }
  };

  render() {
    return (
      <div className="history-modification">
        <header>
          <div className="drawer-backTo" onClick={this.props.goBack}>
            <i className="fa fa-arrow-left" />
            <span>{I18n.t('medicalProfile.lastDocumentsDrawer.backTo')}</span>
          </div>
          <div className="drawer-title">
            <h1>{I18n.t('historyModifications.title')}</h1>
          </div>
        </header>
        <div>
          <Form
            className="form-horizontal"
            onSubmit={this.onSubmit}
            render={({
              handleSubmit, submitting, invalid, values,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="history-search">
                  <Field
                    component={CoreozInput}
                    name="search-field"
                    placeholder={I18n.t('historyModifications.placeholder')}
                    type="text"
                    className="search-input"
                  />
                  <div className="search-button">
                    <button className="btn btn-primary" onClick={this.searchInHistory}>
                      <Translate value="actions.SEARCH" />
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
          <div>
            {this.props.caseHistory &&
              Object.keys(this.props.caseHistory)
                .map(date =>
                  <div className="modification-content" key={date}>
                    <div className="modification-date">
                      <div> {date} </div>
                    </div>
                    <div>
                      {this.props.caseHistory[date] && Object.keys(this.props.caseHistory[date]).map(userId =>
                        <Fragment key={`${this.props.caseHistory[date][userId][0].userId}`}>
                          <div >{(this.props.caseHistory[date][userId][0].userId ? I18n.t('historyModifications.by') : '') + (this.props.caseHistory[date][userId][0].userFirstName || '') + ' ' + (this.props.caseHistory[date][userId][0].userLastName || '')}</div>
                          {this.props.caseHistory[date][userId] && this.props.caseHistory[date][userId].map(modification =>
                            <div className="modification-table" key={`${modification.id}_${modification.field}`}>
                              <div className="modification-title">
                                <span>{!!modification.field && I18n.t(`historyModifications.${modification.field}`)}</span>
                                <span>{!!modification.field && ' - '}</span>
                                <span>{`${I18n.t(`historyModifications.${modification.modificationType}`)} ${this.getDocumentName(modification.documentName)}`}</span>
                              </div>
                              {(modification.oldValue !== null || modification.newValue !== null) &&
                                <table>
                                  <tbody>
                                    <tr className="modification-table-row">
                                      <th>{I18n.t('historyModifications.newValue')}</th>
                                      <th>{I18n.t('historyModifications.oldValue')}</th>
                                    </tr>
                                    <tr className="modification-table-row">
                                      <td>{this.fetchValue(modification.field, modification.newValue)}</td>
                                      <td>{this.fetchValue(modification.field, modification.oldValue)}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              }
                              {typeModification.SEND === modification.modificationType &&
                                <table>
                                  <tbody>
                                    {modification.documentName === null ?
                                      <Fragment>
                                        <tr className="modification-table-row">
                                          <th>{I18n.t('historyModifications.addressRecipient')}</th>
                                          <th>{I18n.t('historyModifications.content')}</th>
                                        </tr>
                                        <tr className="modification-table-row">
                                          <td>{this.fetchValue(modification.field, modification.addressRecipient)}</td>
                                          <td>{this.fetchValue(modification.field, modification.content)}</td>
                                        </tr>
                                      </Fragment>
                                      :
                                      <Fragment>
                                        <tr className="modification-table-row">
                                          <th>{modification.field === pharmacySend ? I18n.t('historyModifications.pharmacyName') : I18n.t('historyModifications.mainToSend')}</th>
                                          <th>{modification.field === pharmacySend ? I18n.t('historyModifications.pharmacyNumber') : I18n.t('historyModifications.addressRecipient')}</th>
                                        </tr>
                                        <tr className="modification-table-row">
                                          <td>{this.fetchValue(modification.field, modification.content)}</td>
                                          <td>{modification.addressRecipient}</td>
                                        </tr>
                                      </Fragment>
                                    }
                                  </tbody>
                                </table>
                              }
                            </div>)
                          }
                        </Fragment>
                      )}
                    </div>
                  </div>)
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect()(HistoryModification));
