// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase as CoreozInput, CoreozInputBase, CoreozSelectBase as CoreozSelect } from '../../lib/coreoz-form-base/index';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { required, validatePhone } from '../../validator';
import { PharmacyCriteria } from '../../enum';
import Tile from '../../components/Tile';
import referentialApi from '../../network/api/referentialApi';
import Loader from '../../components/Loader';
import ModalEprm from '../../components/modal/ModalEprm';
import { notifyError } from '../../network/notification';
import pharmacyApi from "../../network/api/pharmaciesApi";

type Props = {
  dispatch: Function,
}

type State = {
  pharmacies: Array<Object>,
  loading: boolean,
  addPharmacyModal: boolean,
  countries: Array<Object>,
}

class PharmacySearch extends React.Component<Props, State> {
  state = {
    pharmacies: [],
    loading: false,
    addPharmacyModal: false,
    countries: [],
  };

  componentDidMount = () => {
    referentialApi
      .getAllCountries()
      .then(response => response.json())
      .then((countries) => {
        this.setState({ countries });
      })
      .catch(this.props.dispatch(notifyError));
  };

  onSubmit = (values: Object) => {
    this.setState({
      loading: true,
      pharmacies: [],
    });
    this.searchPharmacy(values);
  };

  searchPharmacy = (values: Object) => {
    pharmacyApi.searchPharmacy({
      name: values.name,
      address: values.address,
      city: values.city,
      country: values.country,
      zipCode: values.zipCode,
    })
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(pharmacies => this.setState({ pharmacies, loading: false }))
      .catch(e => console.log('Erreur apppel API', e));
  };

  searchOnGoogle = (values) => {
    const url = `https://www.google.com/search?q=${I18n.t('documentsToSend.sendToPharmacy.pharmacyResearch')}`
      .concat(
        ` ${values.namePharmacy ? values.namePharmacy : ''}`,
        ` ${values.faxPharmacy ? values.faxPharmacy : ''}`,
        ` ${values.cityPharmacy ? values.cityPharmacy : ''}`,
        ` ${this.getCountryNameById(values.countryPharmacy) ? this.getCountryNameById(values.countryPharmacy) : ''}`,
      );
    window.open(url);
  };

  getCountryNameById = (countryId: string) => {
    if (this.state.countries
      && !!this.state.countries.filter(country => country.id === countryId)[0]) {
      return this.state.countries.filter(country => country.id === countryId)[0].label;
    }
    return '';
  };

  savePharmacy = (values) => {
    pharmacyApi
      .addPharmacy({
        name: values.namePharmacy,
        address: values.addressPharmacy,
        postalCode: values.zipcodePharmacy,
        city: values.cityPharmacy,
        country: values.countryPharmacy,
        faxNumber: values.faxPharmacy,
        phoneNumber: values.phoneNumber,
      })
      .then(() => this.setState({ addPharmacyModal: !this.state.addPharmacyModal }))
      .catch(this.props.dispatch(notifyError));
  };

  deletePharmacy = (values) => {
    values.forEach(pharmacyId => pharmacyApi
      .deletePharmacy(pharmacyId)
      .then(() => this.setState({
        pharmacies: this.state.pharmacies.filter(pharmacy => pharmacy.id !== pharmacyId),
      }))
      .catch(this.props.dispatch(notifyError)));
  };

  render() {
    return (
      <div className="pharmacy-search-container">
        <div className="title-container">
          <div className="title">{I18n.t('pharmacySearch.TITLE')}</div>
        </div>
        <div className="search-bar">
          <Form
            onSubmit={this.onSubmit}
            initialValues={{}}
            render={({ handleSubmit, submitting, invalid }) => (
              <form onSubmit={handleSubmit}>
                <div className="criteria">
                  <div className="criteria-column">
                    <Field
                      label={I18n.t('pharmacySearch.NAME')}
                      name="name"
                      component={CoreozInputBase}
                    />
                    <Field
                      label={I18n.t('pharmacySearch.ADDRESS')}
                      name="address"
                      component={CoreozInputBase}
                    />
                  </div>
                  <div className="criteria-column">
                    <Field
                      label={I18n.t('pharmacySearch.CITY')}
                      name="city"
                      component={CoreozInputBase}
                      required
                      validate={required}
                    />
                    <Field
                      label={I18n.t('pharmacySearch.COUNTRY')}
                      name="country"
                      component={CoreozSelect}
                      list={this.state.countries}
                      required
                      validate={required}
                    />
                  </div>
                  <div className="criteria-column">
                    <Field
                      label={I18n.t('pharmacySearch.ZIP_CODE')}
                      name="zipCode"
                      component={CoreozInputBase}
                    />
                    <Button
                      type="submit"
                      color="primary"
                      className="validation-button"
                      disabled={invalid}
                    >
                      <i className="button-icon fa fa-search" />
                      {I18n.t('button.SEARCH')}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
        <div className="search-result">
          {this.state.pharmacies.length > 0 &&
          <Form
            onSubmit={this.onSubmit}
            initialValues={{ selectAll: false }}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>
                <div className="result-action">
                  <div className="sort-action">
                    <Field
                      component="input"
                      name="selectAll"
                      type="checkbox"
                      onClick={() => {
                        if (!values.selectAll) {
                          form.change('pharmacy', this.state.pharmacies.map(p => p.id));
                        } else {
                          form.change('pharmacy', []);
                        }
                      }}
                    />
                    {values.pharmacy && values.pharmacy.length > 0 ?
                      <Fragment>
                        <Button
                          type="button"
                          color="danger"
                          className="delete-button"
                          onClick={() => {
                            this.deletePharmacy(values.pharmacy);
                            form.change('pharmacy', []);
                          }}
                        >
                          <i className="button-icon fa fa-trash" />
                          {I18n.t('button.DELETE')}
                        </Button>
                        <div
                          className="rows-to-delete">{I18n.t('pharmacySearch.TO_DELETE', { rows: values.pharmacy.length })}</div>
                      </Fragment>
                      :
                      <Field
                        name="sortValue"
                        component={CoreozSelect}
                        placeholder={I18n.t('pharmacySearch.SORT_BY')}
                        list={Object.keys(PharmacyCriteria).map(criteria => ({
                          id: PharmacyCriteria[criteria],
                          label: I18n.t(`pharmacySearch.${criteria}`),
                        }))}
                        disabledPlaceholder={false}
                      />
                    }
                  </div>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => this.setState({ addPharmacyModal: !this.state.addPharmacyModal })}
                  >
                    <i className="button-icon fa fa-plus" />
                    {I18n.t('actions.ADD')}
                  </Button>
                </div>
                <div className="result-list">
                  {this.state.pharmacies
                    .sort((p1, p2) => {
                      if (p1[values.sortValue] && p2[values.sortValue]) {
                        if (values.sortValue === PharmacyCriteria.ZIP_CODE) {
                          return p1[values.sortValue] - p2[values.sortValue];
                        }
                        const nameA = p1[values.sortValue].toLowerCase().trim();
                        const nameB = p2[values.sortValue].toLowerCase().trim();

                        return ((nameA < nameB) ? -1 : ((nameA > nameB) ? 1 : 0));
                      }
                      return 0;
                    })
                    .map(pharmacy => (
                      <Tile className="tile-search-pharmacy" key={pharmacy.id}>
                        <div>
                          <Field
                            component="input"
                            name="pharmacy"
                            type="checkbox"
                            value={pharmacy.id}
                          />
                        </div>
                        <div className="pharmacy-name">{pharmacy.name}</div>
                        <div className="pharmacy-address">
                          <div>
                          </div>
                          <div>
                            <div>{pharmacy.address}</div>
                            <div>{pharmacy.postalCode} {pharmacy.city}</div>
                          </div>
                        </div>
                        <div className="pharmacy-contact">
                          {pharmacy.faxNumber ?
                            <Fragment>
                              <i className="button-icon fa fa-print" />
                              {pharmacy.faxNumber}
                            </Fragment>
                            :
                            <Fragment>
                              <i className="button-icon fa fa-phone" />
                              {pharmacy.phoneNumber}
                            </Fragment>
                          }
                        </div>
                      </Tile>
                    ))}
                </div>
              </form>
            )}
          />
          }
          {!this.state.loading && this.state.pharmacies.length === 0 &&
          <div className="no-result">
            {I18n.t('caseSearch.NO_RESULT')}
            <Button
              type="button"
              color="primary"
              onClick={() => {
                this.setState({
                  addPharmacyModal: !this.state.addPharmacyModal,
                });
              }}
            >
              <i className="button-icon fa fa-plus" />
              {I18n.t('actions.ADD')}
            </Button>
          </div>}
          {this.state.loading && <div className="loader"><Loader /></div>}
        </div>
        <ModalEprm
          title={I18n.t('pharmacySearch.ADD_PHARMACY')}
          modal={this.state.addPharmacyModal}
          toggle={() => this.setState({ addPharmacyModal: !this.state.addPharmacyModal })}
          size="lg"
        >
          <Form
            initialValues={this.props.formInitial}
            onSubmit={this.onSubmit}
            render={({ handleSubmit, values, form }) => (
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="add-pharmacy-body">
                  <div className="add-pharmacy-form">
                    <div className="form-column">
                      <Field
                        name="namePharmacy"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.name')}
                        type="text"
                        required
                      />
                      <Field
                        name="addressPharmacy"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.adress')}
                        type="text"
                      />
                      <Field
                        name="zipcodePharmacy"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.zipcode')}
                        type="text"
                      />
                      <Field
                        name="cityPharmacy"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.city')}
                        type="text"
                        required
                      />
                      <Field
                        label={I18n.t('documentsToSend.sendToPharmacy.country')}
                        className="select-country"
                        component={CoreozSelect}
                        name="countryPharmacy"
                        list={this.state.countries}
                        required
                      />
                    </div>
                    <div className="form-column">
                      <div>
                        <Button
                          type="button"
                          className="btn btn-secondary internet-search"
                          onClick={() => this.searchOnGoogle(values)}
                        >
                          <i className="button-icon fa fa-undo" />
                          {I18n.t('documentsToSend.sendToPharmacy.internet')}
                        </Button>
                      </div>
                      <div>
                        <Field
                          name="faxPharmacy"
                          component={CoreozInput}
                          label={I18n.t('documentsToSend.sendToPharmacy.fax')}
                          type="text"
                          validate={validatePhone}
                          required
                        />
                        <Field
                          name="phoneNumber"
                          component={CoreozInput}
                          label={I18n.t('documentsToSend.sendToPharmacy.phoneNumber')}
                          type="text"
                          validate={validatePhone}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="add-pharmacy-action">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => this.setState({ addPharmacyModal: !this.state.addPharmacyModal })}
                    >
                      <i className="button-icon fa fa-undo" />
                      {I18n.t('button.CANCEL')}
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        this.savePharmacy(values);
                      }}
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable || (this.state.displayAdd ? (!this.state.pharmacyToSave) : (!values.namePharmacy || !values.faxPharmacy || !values.cityPharmacy || !values.countryPharmacy))}
                    >
                      <i className="button-icon fas fa-paper-plane" />
                      {I18n.t('button.SAVE')}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </ModalEprm>
      </div>
    );
  }
}

export default connect()(PharmacySearch);
