// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozDatePickerBase } from '../../lib/coreoz-form-base/index';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { dateValidator } from '../../validator';
import { notifyError } from '../../network/notification';
import ExportsTileCaseDetails from './ExportsTileCaseDetails';
import exportApi from '../../network/api/exportApi';
import { download } from '../../utils';
import businessUnitApi from '../../network/api/businessUnitApi';

import type { ExportCaseKpiBean } from '../../types/ExportTypes';
import type { BusinessUnit } from '../../types/BusinessUnitTypes';
import { currentSessionService } from '../../services/sessionServiceInstance';
import Loader from '../../components/Loader';
import ExportsTilePatientDetails from './ExportsTilePatientDetails';

type Props = {
  dispatch: Function
}

type State = {
  bPartners: Array<any>,
  businessUnits: Array<BusinessUnit>,
  loadingCaseKpiExport: boolean,
  loadingPatientKpiExport: boolean,
  dateFrom: String,
  dateTo: String,
}

const CASE_KPI_DOCUMENT_NAME = 'caseKpi.csv';
const PATIENT_KPI_DOCUMENT_NAME = 'patientKpi.csv';

class Exports extends React.Component<Props, State> {
  state = {
    bPartners: [],
    businessUnits: [],
    loadingCaseKpiExport: false,
    loadingPatientKpiExport: false,
    dateFrom: moment().subtract(7, 'days'),
    dateTo: moment(),
  };

  downloadCaseKpiReport = (searchParameters: ExportCaseKpiBean) => {
    this.setState({ loadingCaseKpiExport: true });
    exportApi
      .searchCaseKpi({ ...searchParameters, columnsTranslation: I18n.t('export.columns'), valuesTranslation : I18n.t('export.values') })
      .then(response => response.blob())
      .then(blob =>
        download(blob, CASE_KPI_DOCUMENT_NAME, () => {
          this.setState({ loadingCaseKpiExport: false });
        })).catch((e) => {
      this.setState({ loadingCaseKpiExport: false });
      notifyError(this.props.dispatch)(e);
    });
  };

  downloadPatientKpiReport = (searchParameters: ExportPatientKpiBean) => {
    this.setState({ loadingPatientKpiExport: true });
    exportApi
      .searchPatientKpi({ ...searchParameters, columnsTranslation: I18n.t('export.columns'), valuesTranslation : I18n.t('export.values') })
      .then(response => response.blob())
      .then(blob =>
        download(blob, PATIENT_KPI_DOCUMENT_NAME, () => {
          this.setState({ loadingPatientKpiExport: false });
        })).catch((e) => {
      this.setState({ loadingPatientKpiExport: false });
      notifyError(this.props.dispatch)(e);
    });
  };

  componentDidMount() {
    exportApi
      .fetchAllBpartners()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(bPartners =>
        this.setState({bPartners}))
      .catch(e => console.log('Erreur apppel API', e));

    businessUnitApi
      .fetch()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(businessUnits =>
        this.setState({
          businessUnits: businessUnits.map(businessUnit =>
            ({ ...businessUnit, label: businessUnit.name })),
        }))
      .catch(e => console.log('Erreur apppel API', e));
  }

  validateDate = (e) => {
    if (!moment(e).isValid()) {
      return dateValidator(e, I18n.t('date.DATE_FORMAT'));
    }
    return undefined;
  }

  render() {
    return (
      <div className="export-container">
        <div className="title-container">
          <div className="title">{I18n.t('export.TITLE')}</div>
        </div>
        <div className="search-bar">
          <Form
            onSubmit={() => {
            }}
            initialValues={{ periodFrom: this.state.dateFrom, periodTo: this.state.dateTo }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="criteria-line period">
                  <div className="period-field">
                    <Field
                      className="periodFrom"
                      name="periodFrom"
                      component={CoreozDatePickerBase}
                      placeholder={I18n.t('export.periodFromPlaceHolder')}
                      validate={e => dateValidator(e, I18n.t('date.DATE_FORMAT'))}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      readOnly
                      format={(stringValue) => {
                        if (stringValue) {
                          if (moment(stringValue, I18n.t('date.DATE_FORMAT'), true).isValid()) {
                            return moment(stringValue, I18n.t('date.DATE_FORMAT'));
                          }
                          if (moment(stringValue, moment.ISO_8601).isValid()) {
                            return moment(stringValue);
                          }
                        }
                        return stringValue;
                      }}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                      onBlur={(stringValue) => {
                        this.setState({ dateFrom: stringValue });
                      }}
                    />
                    <i className="fa fa-arrow-right"/>
                    <Field
                      className="periodTo"
                      name="periodTo"
                      component={CoreozDatePickerBase}
                      placeholder={I18n.t('export.periodToPlaceHolder')}
                      validate={e => dateValidator(e, I18n.t('date.DATE_FORMAT'))}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      readOnly
                      format={(stringValue) => {
                        if (stringValue) {
                          if (moment(stringValue, I18n.t('date.DATE_FORMAT'), true).isValid()) {
                            return moment(stringValue, I18n.t('date.DATE_FORMAT'));
                          }
                          if (moment(stringValue, moment.ISO_8601).isValid()) {
                            return moment(stringValue);
                          }
                        }
                        return stringValue;
                      }}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                      onBlur={(stringValue) => {
                        this.setState({ dateTo: stringValue });
                      }}
                    />
                  </div>
                </div>
              </form>
            )}
          />
        </div>
        <div className="export-list">
          <ExportsTilePatientDetails
            title={I18n.t('export.patientPolicy.PATIENT_KPI_REPORT')}
            icone="fa-folder-open"
            loading={this.state.loadingPatientKpiExport}
            businessUnits={this.state.businessUnits}
            bPartners={this.state.bPartners}
            downloadSearch={this.downloadPatientKpiReport}
            defaultValues={{
              createdAfter: this.state.dateFrom,
              createdBefore: this.state.dateTo,
              businessUnitId: currentSessionService().currentUser().businessUnitId,
            }}
          />
          <ExportsTileCaseDetails
            title={I18n.t('export.patientPolicy.CASE_KPI_REPORT')}
            icone="fa-folder-open"
            loading={this.state.loadingCaseKpiExport}
            businessUnits={this.state.businessUnits}
            bPartners={this.state.bPartners}
            downloadSearch={this.downloadCaseKpiReport}
            defaultValues={{
              periodFrom: this.state.dateFrom,
              periodTo: this.state.dateTo,
              businessUnitId: currentSessionService().currentUser().businessUnitId,
            }}
          />

          {this.state.loading &&
          <div className="center-child search-loader"><Loader/></div>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(Exports));
