// @flow
import React, { Fragment } from 'react';
import { I18n, Localize } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase, CoreozSelectBase as CoreozSelect, CoreozSelectBase } from '../../lib/coreoz-form-base/index';
import InputFile from '../../lib/coreoz-input-file/index';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import userApi from '../../network/api/userApi';
import authorizeApi from '../../network/api/authorizeApi';
import { notifyError, notifySuccess } from '../../network/notification';
import { USER_MANAGEMENT_PAGE_ROUTE } from '../../routes';
import { currentSessionService } from '../../services/sessionServiceInstance';
import { UserType, PageMode } from '../../enum';
import SelectMultiAutoComplete from '../../components/SelectMultiAutoComplete';
import CreatableMultiSelect from '../../components/CreatableMultiSelect';
import referentialApi from '../../network/api/referentialApi';
import languageApi from '../../network/api/languageApi';
import specialityApi from '../../network/api/specialityApi';
import { required, validateEmail, validatePasswordConfirm } from '../../validator';
import hasPermission from '../../services/permissionService';
import PlmFormItem from '../../components/panel/PlmFormItem';
import { USER_MANAGEMENT_DETAILS_PAGE_ROUTE } from '../../routes';
import { CreateUserRequest } from '../../types/UsersTypes';


type Props = {
  dispatch: Function,
  location: {
    state: Object,
  },
  match: {
    params: {
      id: String,
    }
  },
}

type State = {
  userInfos: Object,
  userSpecialities: Array<Object>,
  userLanguages: Array<Object>,
  userKnowledgeSpecialities: Array<Object>,
  allLanguages: Array<Object>,
  allSpecialities: Array<Object>,
  countries: Array<Object>,
}

class UserManagementDetails extends React.Component<Props, State> {
  state = {
    userInfos: null,
    userSpecialities: [],
    userLanguages: [],
    userKnowledgeSpecialities: [],
    allLanguages: [],
    allSpecialities: [],
    countries: [],
    roles: [],
    businessUnits: [],

  };

  isPageEdit: Boolean;

  componentDidMount() {
    this.isPageEdit = this.props.match.params.id && true;

    if (this.isPageEdit) {
      userApi.fetchUserDetailsById(this.props.match.params.id)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : {}))
        .then((user) => {
          this.setState({
            userInfos: user,
            userSpecialities: user.specialities.map(speciality => ({ value: speciality.id, label: speciality.name })),
            userLanguages: user.languages.map(language => ({ value: language.id, label: language.name })),
            userKnowledgeSpecialities: user.knowledgeSpecialities.map(detail => ({
              value: detail.id,
              label: detail.detail,
            })),
          });
        })
        .catch(notifyError(this.props.dispatch));
    }

    languageApi.fetchAllLanguages()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(languages => this.setState({
        allLanguages: languages.map(language => ({ value: language.id, label: language.name })),
      }))
      .catch(notifyError(this.props.dispatch));

    specialityApi.fetchSpecialties(currentSessionService().currentUser().businessUnitCountryCode)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(specialities => this.setState({
        allSpecialities: specialities.map(speciality => ({ value: speciality.id, label: speciality.name })),
      }))
      .catch(notifyError(this.props.dispatch));

    referentialApi
      .getAllCountries()
      .then(response => response.json())
      .then(countries => this.setState({ countries }))
      .catch(this.props.dispatch(notifyError));

    authorizeApi
      .fetchRoles()
      .then(response => response.json())
      .then(roles => this.setState({ roles }))
      .catch(e => console.log('Erreur apppel API', e));

    userApi
      .fetchBusinessUnits()
      .then(response => response.json())
      .then(res =>
        this.setState({
          businessUnits: res.filter(v => hasPermission('EDITABLE_BUSINESS_UNIT_CREATE_USER') || v.id === currentSessionService().currentUser().businessUnitId),
        }))
      .catch(e => console.log('Erreur apppel API', e));
  }

  formatUserForFront(userDetail) {
    if (!userDetail) {
      return {
        details: {
          eprmId: null,
          roleId: null,
          creationDate: null,
          firstName: null,
          lastName: null,
          email: null,
          type: null,
          userName: null,
          password: null,
          passwordConfirmation: null,
          isTwoFactorEnabled: null,
          mobilePhoneNumber: null,
          gender: null,
          street: null,
          zipCode: null,
          city: null,
          countryId: null,
          businessUnitId: null,
          professionalId: null,
          isThirdPart: null,
        },
        signature: null,
        specialities: [],
        languages: [],
        knowledgeSpecialities: [],
      }
    }
    return {
      details: userDetail.details,
      signature: userDetail.signature && (userDetail.signature.base64 || userDetail.signature.url) ? userDetail.signature : null,
      specialities: userDetail.specialities !== null ? userDetail.specialities.map(s => ({
        value: s.id,
        label: s.name,
      })) : [],
      languages: userDetail.languages !== null ? userDetail.languages.map(l => ({ value: l.id, label: l.name })) : [],
      knowledgeSpecialities: userDetail.knowledgeSpecialities !== null ? userDetail.knowledgeSpecialities
        .map(k => ({
          value: k.id,
          label: k.detail,
        })) : [],
    }
  }

  formatUserForBack(values) {
    return {
      details: {
        id: values.details.id,
        eprmId: values.details.eprmId,
        firstName: values.details.firstName,
        lastName: values.details.lastName,
        email: values.details.email,
        userName: values.details.userName,
        password: values.details.password,
        passwordConfirmation: values.details.passwordConfirmation,
        mobilePhoneNumber: values.details.mobilePhoneNumber,
        gender: values.details.gender,
        street: values.details.street,
        zipCode: values.details.zipCode,
        city: values.details.city,
        countryId: values.details.countryId,
        prmId: values.details.prmId,
        professionalId: values.details.professionalId,
        isThirdPart: values.details.isThirdPart,
        ...(hasPermission('EDITABLE_BUSINESS_UNIT_CREATE_USER') &&
        {
          type: values.details.type,
          businessUnitId: values.details.businessUnitId,
          roleId: values.details.roleId,
          isTwoFactorEnabled: values.details.isTwoFactorEnabled,
        }),
      },
      specialities: values.specialities ? values.specialities.map(s => ({
        id: s.value,
        name: s.label,
      })) : [],
      languages: values.languages ? values.languages.map(l => ({ id: l.value, name: l.label })) : [],
      knowledgeSpecialities: values.knowledgeSpecialities ? values.knowledgeSpecialities
        .map(k => ({
          id: k.value === k.label ? '' : k.value,
          idUser: this.props.match.params.id,
          detail: k.label,
        })) : [],
      signature: values.signature,
    };
  }

  formatCreateUserForBack(data): CreateUserRequest {
    if (!data) {
      return null;
    }

    const createRequest: CreateUserRequest = {
      roleId: data.details.roleId,
      firstName: data.details.firstName,
      lastName: data.details.lastName,
      email: data.details.email,
      type: data.details.type,
      userName: data.details.userName,
      password: data.details.password,
      passwordConfirmation: data.details.passwordConfirmation,
      isTwoFactorEnabled: data.details.isTwoFactorEnabled,
      mobilePhoneNumber: data.details.mobilePhoneNumber,
      gender: data.details.gender,
      street: data.details.street,
      zipCode: data.details.zipCode,
      city: data.details.city,
      countryId: data.details.countryId,
      businessUnitId: data.details.businessUnitId,
      professionalId: data.details.professionalId,
      isThirdPart: data.details.isThirdPart,
      specialityIds: data.specialities ? data.specialities.map(s => s.value) : [],
      languageIds: data.languages ? data.languages.map(l => l.value) : [],
      userDetails: data.knowledgeSpecialities ? data.knowledgeSpecialities.map(k => k.value) : [],
    };

    if (data.signature) {
      createRequest.signature = { name: data.signature.filename, data: data.signature.base64 };
    }

    return createRequest;
  }

  saveUser = (values) => {
    // update user
    if (this.isPageEdit) {
      userApi.update(this.formatUserForBack(values))
        .then(this.props.dispatch(notifySuccess))
        .catch(this.props.dispatch(notifyError));
    } else {
      userApi.create(this.formatCreateUserForBack(values))
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : {}))
        .then(this.props.dispatch(notifySuccess))
        .then(res => {
          setTimeout(() =>
            this.props.history.push(USER_MANAGEMENT_DETAILS_PAGE_ROUTE.replace(':id', res.id), this.props.location.state)
            , 3000);
        })
        .catch(this.props.dispatch(notifyError));
    }
  };

  getHeaderTitile: String = () => (this.props.match.params.id ? I18n.t('userDetails.TITLE') : I18n.t('userDetails.CREATE'))

  render() {
    return (
      <div>
        <div className="user-details">
          <div className="user-details-header">
            <div
              className="back-to-list"
              onClick={() => this.props.history.push(`${USER_MANAGEMENT_PAGE_ROUTE}`, this.props.location.state)}
            >
              <i className="fa fa-arrow-left" />
              {I18n.t('userDetails.BACK_TO_LIST')}
            </div>
            <div className="title">{this.getHeaderTitile()}</div>
            {this.state.userInfos && this.state.userInfos.details &&
              <div className="user-summary">
                <i className="user-icon fa fa-user" />
                <div>
                  <div className="user-name">
                    {this.state.userInfos.details.firstName} {this.state.userInfos.details.lastName}
                  </div>
                  <div className="user-info">
                    <div className="gender">
                      {this.state.userInfos.details.gender ?
                        <Fragment>
                          <i className="fa fa-venus" />
                          {I18n.t('userDetails.WOMAN')}
                        </Fragment>
                        :
                        <Fragment>
                          <i className="fa fa-mars" />
                          {I18n.t('userDetails.MAN')}
                        </Fragment>
                      }
                    </div>
                  </div>
                  <div className="address">
                    {this.state.userInfos.details.street && `${this.state.userInfos.details.street}, `}
                    {this.state.userInfos.details.city}
                  </div>
                </div>
              </div>
            }
          </div>
          <Form
            onSubmit={() => {
            }}
            initialValues={
              this.formatUserForFront(this.state.userInfos)
            }
            render={({ handleSubmit, values, invalid }) => (
              <form onSubmit={handleSubmit}>
                <div className="user-action">
                  <Button
                    color="primary"
                    type="button"
                    disabled={invalid}
                    onClick={() => this.saveUser(values)}
                  >
                    <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => {
                      this.props.history.push(`${USER_MANAGEMENT_PAGE_ROUTE}`, this.props.location.state);
                    }}
                  >
                    <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
                  </Button>
                </div>
                <div className="content">
                  {hasPermission('ACCESS_USERS_ADMIN') &&
                    <div className="user-category">

                      <div className="title">{I18n.t('userDetails.ADMIN')}</div>
                      <br />
                      {values.details.creationDate &&
                        <PlmFormItem label={I18n.t('users.CREATION_DATE')}>
                          <Localize value={values.details.creationDate || ''} dateFormat="date.long" />
                        </PlmFormItem>
                      }

                      <Field
                        autoComplete="off"
                        type="text"
                        disabled={!hasPermission('MANAGE_USERS_ADMIN') && this.isPageEdit}
                        component={CoreozSelect}
                        name="details.businessUnitId"
                        label={I18n.t('userDetails.BUSINESS_UNIT')}
                        list={this.state.businessUnits && this.state.businessUnits.map(bu => ({
                          id: bu.id,
                          label: bu.name,
                        }))}
                        validate={required}
                        required
                      />

                      <Field
                        label={I18n.t('userDetails.TYPE')}
                        component={CoreozSelectBase}
                        disabled={!hasPermission('MANAGE_USERS_ADMIN') && this.isPageEdit}
                        list={Object.keys(UserType).map(role => ({ id: role, label: I18n.t(`role.${role}`) }))}
                        name="details.type"
                        validate={required}
                        required
                      />

                      <Field
                        autoComplete="off"
                        type="text"
                        component={CoreozSelectBase}
                        disabled={!hasPermission('MANAGE_USERS_ADMIN') && this.isPageEdit}
                        name="details.roleId"
                        label={I18n.t('users.ROLE')}
                        validate={required}
                        required
                        list={this.state.roles}
                      />

                      <div className="base-container-input-coreoz">
                        <div
                          className="base-label-input-coreoz"
                        >
                          {I18n.t('users.TWO_FACTOR')}
                        </div>
                        <Field
                          autoComplete="off"
                          component="input"
                          disabled={!hasPermission('MANAGE_USERS_ADMIN') && this.isPageEdit}
                          name="details.isTwoFactorEnabled"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  }

                  <div className="user-category">
                    <div className="title">{I18n.t('userDetails.IDENTITY')}</div>
                    <div className="radio-button-container">
                      <div className="radio-button-label">{I18n.t('userDetails.GENDER')}</div>
                      <div className="choices">
                        <Field
                          component="input"
                          name="details.gender"
                          type="radio"
                          value="false"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                        />
                        <span>{I18n.t('userDetails.MAN')}</span>
                        <Field
                          component="input"
                          name="details.gender"
                          type="radio"
                          value="true"
                          format={value => String(value)}
                          parse={value => value === 'true'}

                        />
                        <span>{I18n.t('userDetails.WOMAN')}</span>
                      </div>
                    </div>
                    <Field
                      label={I18n.t('userDetails.FIRST_NAME')}
                      component={CoreozInputBase}
                      name="details.firstName"
                      validate={required}
                      required
                    />
                    <Field
                      label={I18n.t('userDetails.LAST_NAME')}
                      component={CoreozInputBase}
                      name="details.lastName"
                      validate={required}
                      required
                    />
                    <Field
                      label={I18n.t('userDetails.USERNAME')}
                      component={CoreozInputBase}
                      name="details.userName"
                      validate={required}
                      required
                    />
                    <Field
                      label={I18n.t('userDetails.EMAIL')}
                      component={CoreozInputBase}
                      name="details.email"
                      validate={e => validateEmail(e, true)}
                      required
                    />
                    <Field
                      label={I18n.t('userDetails.PHONE')}
                      component={CoreozInputBase}
                      name="details.mobilePhoneNumber"
                      validate={required}
                      required
                    />
                    <Field
                      autoComplete="new-password"
                      label={I18n.t('users.PASSWORD')}
                      type="password"
                      component={CoreozInputBase}
                      name="details.password"
                      validate={!this.isPageEdit && required}
                      required={!this.isPageEdit && true}
                    />
                    <Field
                      autoComplete="off"
                      label={I18n.t('users.PASSWORD_CONFIRM')}
                      type="password"
                      component={CoreozInputBase}
                      name="details.passwordConfirmation"
                      required={!this.isPageEdit && true}
                      validate={(e, allValues) => validatePasswordConfirm(allValues.details.password, allValues.details.passwordConfirmation)}
                    />
                    <div className="radio-button-container">
                      <div className="radio-button-label">{I18n.t('userDetails.THIRD_PART')}</div>
                      <div className="choices">
                        <Field
                          component="input"
                          name="details.isThirdPart"
                          type="radio"
                          value="true"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                        />
                        <span>{I18n.t('userDetails.YES')}</span>
                        <Field
                          className="base-input-coreoz"
                          component="input"
                          name="details.isThirdPart"
                          type="radio"
                          value="false"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                        />
                        <span>{I18n.t('userDetails.NO')}</span>
                      </div>
                    </div>
                  </div>


                  {values.details.type === UserType.DOCTOR &&
                    <div className="user-category">
                      <div className="title">{I18n.t('userDetails.DOCTOR_INFORMATION')}</div>

                      <Fragment>
                        <Field
                          label={I18n.t('userDetails.DOCTOR_ID')}
                          component={CoreozInputBase}
                          name="details.professionalId"
                        />
                      </Fragment>
                    </div>
                  }


                  <div className="user-category">
                    <div className="title">{I18n.t('userDetails.KNOWLEDGE')}</div>
                    <div className="languages">
                      <div className="select-label">{I18n.t('userDetails.LANGUAGES')}</div>
                      <div className="select-values">
                        <Field
                          component={SelectMultiAutoComplete}
                          name="languages"
                          defaultValue={this.state.userLanguages}
                          options={this.state.allLanguages}
                        />

                      </div>
                    </div>
                    <div className="specialities">
                      <div className="select-label">{I18n.t('userDetails.SPECIALITIES')}</div>
                      <div className="select-values">
                        <Field
                          component={SelectMultiAutoComplete}
                          name="specialities"
                          defaultValue={this.state.userSpecialities}
                          options={this.state.allSpecialities}
                          onInputChange={() => {
                          }}
                        />
                      </div>
                    </div>
                    <div className="details">
                      <div className="select-label">{I18n.t('userDetails.KNOWLEDGE_DETAILS')}</div>
                      <div className="select-values">
                        <Field
                          component={CreatableMultiSelect}
                          name="knowledgeSpecialities"
                          defaultValue={this.state.userKnowledgeSpecialities}
                          options={this.state.userKnowledgeSpecialities}
                          noOptionsMessage={() => I18n.t('userDetails.START_TYPING')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="user-category">
                    <div className="title">{I18n.t('userDetails.ADDRESS')}</div>
                    <Field
                      label={I18n.t('userDetails.STREET')}
                      component={CoreozInputBase}
                      name="details.street"
                    />
                    <Field
                      label={I18n.t('userDetails.ZIPCODE')}
                      component={CoreozInputBase}
                      name="details.zipCode"
                    />
                    <Field
                      label={I18n.t('userDetails.CITY')}
                      component={CoreozInputBase}
                      name="details.city"
                    />
                    <Field
                      label={I18n.t('userDetails.COUNTRY')}
                      component={CoreozSelectBase}
                      list={this.state.countries}
                      name="details.countryId"
                      required={false}
                    />
                  </div>
                  {/* <div className="user-category"> */}
                  {/* <div className="title">{I18n.t('userDetails.INFORMATION')}</div> */}
                  {/* <Field */}
                  {/* label={I18n.t('userDetails.SITE')} */}
                  {/* component={CoreozInputBase} */}
                  {/* name="site" */}
                  {/* /> */}
                  {/* <Field */}
                  {/* label={I18n.t('userDetails.BUSINESS_UNIT')} */}
                  {/* component={CoreozSelectBase} */}
                  {/* list={Object.keys(Roles).map(role => ({ id: role, label: I18n.t(`role.${role}`) }))} */}
                  {/* name="businessUnit" */}
                  {/* /> */}
                  {/* <Field */}
                  {/* label={I18n.t('userDetails.TEAM')} */}
                  {/* component={CoreozSelectBase} */}
                  {/* list={Object.keys(Roles).map(role => ({ id: role, label: I18n.t(`role.${role}`) }))} */}
                  {/* name="team" */}
                  {/* /> */}
                  {/* </div> */}
                  <div className="user-category">
                    <div className="title">{I18n.t('userDetails.SIGNATURE')}</div>
                    <div className="import-image">
                      <Field
                        label={I18n.t('userDetails.COUNTRY')}
                        component={InputFile}
                        name="signature"
                        wordings={{
                          dragFile: I18n.t('userDetails.wordings.dragFile'),
                          selectFile: I18n.t('userDetails.wordings.selectFile'),
                        }}
                      />
                    </div>
                  </div>
                  <div className="user-action">
                    <Button
                      color="primary"
                      type="button"
                      disabled={invalid}
                      onClick={() => this.saveUser(values)}
                    >
                      <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
                    </Button>
                    <Button
                      color="secondary"
                      type="button"
                      onClick={() => {
                        this.props.history.push(`${USER_MANAGEMENT_PAGE_ROUTE}`, this.props.location.state);
                      }}
                    >
                      <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(UserManagementDetails));
