// @flow

import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import Tile from '../Tile';
import type { PatientSearchResultType, PatientSearchType } from '../../types/PatientTypes';
import { PATIENT_PAGE_ROUTE } from '../../routes';

type Props = {
  patient: PatientSearchResultType,
  history: {
    push: Function,
  },
  lastSearchObject: ?PatientSearchType
}

function PatientTile({ patient, history, lastSearchObject }: Props) {
  return (
    <Tile
      className="tile-patient"
    >
      <div className="patient-id">
        <i className="fa fa-user-injured" />
        <div>
          <div
            className="name"
            title={`${patient.firstName} ${patient.lastName}`}
          >{patient.firstName} {patient.lastName}
          </div>
          <div className="date-of-birth">
            {moment(patient.birthDate).format(I18n.t('date.DATE_FORMAT'))}
          </div>
        </div>
      </div>
      <div className="last-edit-info">
        {patient.lastEdit && patient.lastEdit.userFirstName &&
          <Fragment>
            <div className="user-name">
              {I18n.t('caseSearch.LAST_EDIT', { firstname: patient.lastEdit.userFirstName, lastname: patient.lastEdit.userLastName })}
            </div>
            <div className="edit-date">{patient.lastEdit && moment(patient.lastEdit.eventDate).format(I18n.t('date.DATE_LONG'))}</div>
          </Fragment>
        }
      </div>
      <div className="open-patient">
        <Button
          color="primary"
          onClick={() => history.push(`${PATIENT_PAGE_ROUTE}/${patient.id}`, lastSearchObject)}
        >
          <i className="button-icon fa fa-folder-open" />{I18n.t('patientSearch.OPEN_PATIENT')}
        </Button>
      </div>
    </Tile>
  );
}

export default withRouter(PatientTile);
