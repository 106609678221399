// @flow

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { notifyError, notifySuccess } from '../../network/notification';
import { PATIENT_PAGE_ROUTE } from '../../routes';
import HistoryModification from '../../components/historyModification/HistoryModification';
import type { PatientSearchType, PatientType } from '../../types/PatientTypes';
import patientApi from '../../network/api/patientApi';
import { Form } from 'react-final-form';
import { cleanObject, groupHistoryByDateAndIdUser, searchHistory } from '../../utils';
import Identity from '../administration/component/Identity';
import { Col, Row } from 'reactstrap';
import Doctor from '../administration/component/Doctor';
import Insurance from '../administration/component/Insurance';
import PolicyInformation from '../administration/component/PolicyInformation';
import LastEdit from '../cases/components/LastEdit';
import type { DoctorType } from '../../types/DoctorType';
import caseApi from '../../network/api/caseApi';
import caseReportApi from '../../network/api/caseReportApi';
import CoreozDrawer from '../../components/CoreozDrawer';
import referentialApi from '../../network/api/referentialApi';
import type { CaseSearchResultType } from '../../types/CasesTypes';
import CaseSearchResultTile from '../../components/cases/CaseSearchResultTile';
import PlmPanel from '../../components/panel/PlmPanel';
import AutoSave from '../../components/AutoSave';
import setFieldData from 'final-form-set-field-data';
import { hideAutoSaveBadge, showAutoSaveBadge } from '../../state/process/processService';
import PaginatedList from '../../components/PaginatedList';
import policiesApi from '../../network/api/policiesApi';
import MedicalProfileFields from '../../components/patients/MedicalProfileFields';
import type { PolicyType } from '../../types/PolicyType';
import PolicyTile from '../../components/patients/PolicyTile';
import ResendMailModal from '../../components/patients/ResendMailModal';
import Button from 'reactstrap/es/Button';
import AddPolicyModal from '../../components/patients/AddPolicyModal';
import Loader from '../../components/Loader';
import notificationApi from '../../network/api/notificationApi';
import type { HistoryType } from '../../types/HistoryTypes';
import businessUnitApi from '../../network/api/businessUnitApi';
import familyDoctorApi from '../../network/api/familyDoctor';

type Props = {
  dispatch: Function,
  history: Function,
  location: {
    state: PatientSearchType
  },
  match: {
    params: {
      id: string,
    }
  },
}

type State = {
  doctor: ?DoctorType,
  patientDetail: ?PatientType;
  sideDrawerOpenHistory: boolean,
  patientHistory: Array<HistoryType>,
  patientDefaultHistory: Array<HistoryType>,
  policyAssurance: ?Object,
  countries: Object[],
  policies: PolicyType[],
  policy: PolicyType,
  policyChecked: boolean,
  cases: ?CaseSearchResultType[],
  resendModalShown: boolean,
  addPolicyModalShown: boolean,
  preferredCountries: Array<String>,
}

class PatientDetails extends React.Component<Props, State> {
  state = {
    doctor: null,
    patientDetail: null,
    sideDrawerOpenHistory: false,
    patientHistory: [],
    patientDefaultHistory: [],
    policyAssurance: null,
    countries: [],
    policy: null,
    policies: [],
    policyChecked: false,
    cases: [],
    resendModalShown: false,
    addPolicyModalShown: false,
    loading: false,
    preferredCountries: [],
  };

  componentDidMount() {
    this.props.dispatch(showAutoSaveBadge);

    const patientId = this.props.match.params.id;

    patientApi
      .fetchById(patientId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((patientDetail) => {
        if (patientDetail) {
          Promise.all([
            this.fetchCases(patientId),
            this.fetchPolicies(patientId)])
            .then((values) => {
              const cases = values[0]; // resultat de fetchCases
              const policies = values[1]; // resultat de fetchPolicies
              if (cases && policies) {
                const lastCase = cases.reduce(
                  (total, currentValue) => {
                    if (total.createdOn < currentValue.createdOn) {
                      return currentValue;
                    }
                    return total;
                  },
                  cases[0],
                );
                this.setState({ policy: policies.filter(policy => !!policy && !!lastCase && policy.policyNumber === lastCase.policyNumber)[0] });
              }
            });

          this.setState({ patientDetail });
          this.getPatientHistory(patientId);
        }
      })
      .catch(this.props.dispatch(notifyError));
    this.fetchFamilyDoctor();
    this.fetchPolicyAssurance();
    this.fetchCountries();
    this.fetchPreferredCountries();
  }

  componentWillUnmount() {
    this.props.dispatch(hideAutoSaveBadge);
  }

  searchKeyWordHistory = value => this.setState({
    patientHistory: searchHistory(value, this.state.patientDefaultHistory),
  });

  fetchFamilyDoctor = () => {
    caseApi.getFamilyDoctor(this.props.match.params.id)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(doctor => this.setState({ doctor }))
      .catch(this.props.dispatch(notifyError));
  };
  fetchCountries = () => {
    referentialApi
      .getAllCountries()
      .then(response => response.json())
      .then(countries => this.setState({ countries }))
      .catch(this.props.dispatch(notifyError));
  };
  fetchPolicyAssurance = () => {
    caseReportApi.getPolicyAssuranceInfosByPatient(this.props.match.params.id)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(policyAssurance => this.setState({ policyAssurance }))
      .catch(this.props.dispatch(notifyError));
  };

  fetchCases = (userIdPrm: string) => caseApi.fetchPatientCase(userIdPrm)
    .then(response => response.text())
    .then(text => (text.length ? JSON.parse(text) : []))
    .then((cases) => {
      this.setState({ cases });
      return cases;
    })
    .catch(e => console.log('Erreur apppel API', e));
  fetchPolicies = (idPatient: string) => policiesApi.fetchPolicyPatientContract(idPatient)
    .then(response => response.text())
    .then(text => (text.length ? JSON.parse(text) : []))
    .then((policies) => {
      this.setState({
        policies,
        policyChecked: true,
      });
      return policies;
    })
    .catch((e) => {
      this.setState({
        policyChecked: true,
      });
    });

  getPatientHistory = (idPatient: string) => {
    patientApi.getPatientHistory(idPatient)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(patientHistory => this.setState({
        patientHistory: patientHistory.map(value => ({
          ...value,
          eventDate: moment(value.eventDate).format('DD/MM/YYYY HH:mm'),
        })),
        patientDefaultHistory: patientHistory.map(value => ({
          ...value,
          eventDate: moment(value.eventDate).format('DD/MM/YYYY HH:mm'),
        })),
      }))
      .catch(this.props.dispatch(notifyError));
  };

  fetchPreferredCountries = () => {
    businessUnitApi
      .fetchListofCountries()
      .then(result => result.json())
      .then(preferredCountries => this.setState({ preferredCountries }))
      .catch(this.props.dispatch(notifyError));
  }

  drawerToggleHistory = () => {
    this.setState({
      sideDrawerOpenHistory: !this.state.sideDrawerOpenHistory,
    });
  };
  updateField = async (field, oldValue, newValue) => {
    if (this.familyDoctorFields.includes(field)) {
      familyDoctorApi.saveField(null, this.state.doctor.id, field, {
        oldValue,
        newValue,
      })
        .catch(this.props.dispatch(notifyError));
    } else {
      patientApi.saveField(null, this.props.match.params.id, field, {
        oldValue,
        newValue,
      }).catch(this.props.dispatch(notifyError));
    }
  };
  resendPdaLink = (idPrmPolicy: string) => {
    this.setState({ loading: true });
    notificationApi.resendPDALink(idPrmPolicy, this.state.patientDetail.id)
      .then(() => {
        if (this.state.resendModalShown) {
          this.toggleResendMailModal();
        }
        this.setState({ loading: false });
      })
      .then(this.props.dispatch(notifySuccess))
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(notifyError)(e);
      });
  };
  toggleResendMailModal = () => {
    this.setState({ resendModalShown: !this.state.resendModalShown });
  };
  toggleAddPolicyModal = () => {
    this.setState({ addPolicyModalShown: !this.state.addPolicyModalShown });
  };

  policiesWithoutPDA = () => this.state.policies.filter(policy => !policy.isPdaAccount);

  familyDoctorFields = [
    'DOCTOR_FIRSTNAME',
    'DOCTOR_LASTNAME',
    'DOCTOR_COUNTRY',
    'DOCTOR_CITY',
    'DOCTOR_POSTALCODE',
    'DOCTOR_ADDRESS',
    'DOCTOR_EMAIL',
    'DOCTOR_PHONE'];

  contractInformationFields = [
    'policyHolderFirstName',
    'policyHolderLastName',
    'policyHolderCity',
    'policyHolderPhonenumber',
    'beneficiaryFirstName',
    'beneficiaryLastName',
    'beneficiaryBirthday',
    'policyNumber',
    'protocolNumber',
    'bPartnerName',
    'legalEntityName',
    'pdaAccount'];

  render() {
    return (
      <div>
        {this.state.patientDetail &&
          <div className="patient-details">
            <div className="case-details-header">
              <div
                className="back-to-list"
                onClick={() => this.props.history.push(`${PATIENT_PAGE_ROUTE}`, this.props.location.state)}
              >
                <i className="fa fa-arrow-left" />
                {I18n.t('caseDetails.BACK_TO_LIST')}
              </div>
              <div className="case-summary">
                <div className="case-identity">
                  <i className="fa fa-user-injured" />
                  <div>
                    <div
                      className="case-number"
                    >{I18n.t('caseDetails.PATIENT')} {this.state.patientDetail && this.state.patientDetail.firstName} {this.state.patientDetail && this.state.patientDetail.lastName}
                    </div>
                    <div
                      className="patient"
                    >{this.state.patientDetail.birthDate ? -moment(this.state.patientDetail.birthDate, 'YYYY-MM-DD').diff(Date.now(), 'years') : 0} {I18n.t('editDocument.YEARS')}
                    </div>
                  </div>
                </div>
                {this.state.patientDefaultHistory.length > 0 &&
                  <LastEdit drawerToggleHistory={this.drawerToggleHistory} history={this.state.patientDefaultHistory} />
                }
              </div>
            </div>
            {
              this.state.patientHistory &&
              <CoreozDrawer show={this.state.sideDrawerOpenHistory} side={false}>
                <HistoryModification
                  idCase={this.props.match.params.id}
                  goBack={this.drawerToggleHistory}
                  caseHistory={groupHistoryByDateAndIdUser(this.state.patientHistory)}
                  searchKeyWordHistory={this.searchKeyWordHistory}
                  callCategory={[]}
                  countries={[]}
                />
              </CoreozDrawer>
            }
            <ResendMailModal
              resendFunction={this.resendPdaLink}
              toggle={this.toggleResendMailModal}
              modal={this.state.resendModalShown}
              policies={this.state.policies}
              loading={this.state.loading}
            />
            <AddPolicyModal
              toggle={this.toggleAddPolicyModal}
              modal={this.state.addPolicyModalShown}
              patientDetail={{
                ...this.state.patientDetail,
                birthdate: this.state.patientDetail.birthdate ?
                  moment(this.state.patientDetail.birthdate, 'YYYY-MM-DD') : null,
              }}
              reloadPolicies={() => this.fetchPolicies(this.props.match.params.id)}
              existingPolicies={this.state.policies}
            />
            {this.state.doctor && this.state.policyAssurance &&
              <div className="patient-administration">
                <Form
                  onSubmit={() => {
                  }}
                  initialValues={{
                    ...cleanObject(this.state.patientDetail),
                    DOCTOR_FIRSTNAME: this.state.doctor.firstName,
                    DOCTOR_LASTNAME: this.state.doctor.lastName,
                    DOCTOR_COUNTRY: this.state.doctor.country,
                    DOCTOR_CITY: this.state.doctor.city,
                    DOCTOR_POSTALCODE: this.state.doctor.postcode,
                    DOCTOR_ADDRESS: this.state.doctor.address,
                    DOCTOR_EMAIL: this.state.doctor.email,
                    DOCTOR_PHONE: this.state.doctor.phone,
                    legalEntityName: this.state.policyAssurance && this.state.policyAssurance.legalEntity || '',
                    brand: this.state.policyAssurance && this.state.policyAssurance.brand || '',
                    noEmailAddress: this.state.patientDetail.noEmailAddress ? 'true' : 'false',
                    email: this.state.patientDetail && this.state.patientDetail.email || null,
                    isPdaAccount: this.state.patientDetail ? this.state.patientDetail.isPdaAccount ? 'true' : 'false' : '',
                  }}
                  mutators={{ setFieldData }}
                  render={({ handleSubmit, values, form }) => (
                    <form onSubmit={handleSubmit}>
                      <AutoSave
                        setFieldData={form.mutators.setFieldData}
                        save={this.updateField}
                        ignoreFields={['bPartnerName', 'id'].concat(this.contractInformationFields)}
                      />
                      <header className="step-title">
                        <div className="content-title ">
                          <i className="fal fa-id-card" /> {I18n.t('administration.TITLE')}
                        </div>
                        <div className="header-action">
                          <p className="ml-4">
                            <span>* </span>
                            <span>{I18n.t('administration.MANDATORY')}</span>
                          </p>
                        </div>
                      </header>
                      <div className="content">
                        <Identity
                          values={values}
                          countries={this.state.countries}
                          preferredCountries={this.state.preferredCountries}
                          change={form.change}
                          resendPdaLinkFunction={this.policiesWithoutPDA().length > 0 && this.toggleResendMailModal}
                        />
                        <Row className="row">
                          <Col md="12" lg="6">
                            <Doctor
                              formValues={values}
                              onFormChange={form.change}
                              preferredCountries={this.state.preferredCountries}
                            />
                            <PlmPanel className="medical-profile-panel">
                              <span className="title-panel">{I18n.t('medicalProfile.title')}</span>
                              <MedicalProfileFields
                                values={values}
                                patient={this.state.patientDetail}
                                caseInfos={null}
                              />
                            </PlmPanel>
                          </Col>
                          <Col md="12" lg="6">
                            <Insurance />
                            <PolicyInformation />
                          </Col>
                        </Row>
                      </div>
                    </form>)}
                />
                <div className="content">
                  <Row>
                    <Col sm="12">
                      <PlmPanel className="identity policy" title="">
                        <span
                          className="title-panel"
                        >{I18n.t('medicalProfile.lastConsultations.history')} ({this.state.cases ? this.state.cases.length : 0})
                        </span>
                        {this.state.cases &&
                          <PaginatedList
                            pageSize={5}
                            dataArray={this.state.cases}
                            render={caseFound =>
                            (<CaseSearchResultTile
                              key={caseFound.id}
                              caseFound={caseFound}
                              lastSearchObject={null}
                              showPolicy
                            />)}
                          />
                        }
                      </PlmPanel>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <PlmPanel>
                        <span className="title-panel mb-2">{I18n.t('administration.policy.policy.TITLE')}</span>
                        {
                          Boolean(this.state.patientDetail) &&
                          <Button
                            className="float-right"
                            color="btn secondary"
                            type="button"
                            onClick={this.toggleAddPolicyModal}
                          >
                            <i className="button-icon fas fa-plus" />{I18n.t('patientDetails.ADD_POLICY')}
                          </Button>
                        }
                        <div className="mt-3">
                          {this.state.policyChecked ?
                            <Fragment>
                              {(this.state.policies.length === 0
                                || (this.state.policies.length === 1
                                  && !this.state.policies[0].isActive))
                                && <span className="skip-eligibility">{I18n.t('patientDetails.SKIP_ELIGIBILITY')}</span>}
                              {
                                this.state.policies.map(policy => (
                                  <Fragment key={`PolicyTile_${policy.policyId}`}>
                                    {!!policy.isActive &&
                                      <PolicyTile policy={policy} key={policy.policyId} />
                                    }
                                  </Fragment>
                                ))
                              }
                            </Fragment>
                            :
                            <div className="policy-loader"><Loader /></div>
                          }
                        </div>
                      </PlmPanel>
                    </Col>
                  </Row>
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

export default withRouter(connect()(PatientDetails));
