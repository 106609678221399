// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Col, Row } from 'reactstrap';
import Button from 'reactstrap/es/Button';
import { Form, FormSpy } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';

import type { HistoryLog } from '../../types/HistoryTypes';
import { notifyError, notifySuccess } from '../../network/notification';
import Identity from './component/Identity';
import Doctor from './component/Doctor';
import PolicyInformation from './component/PolicyInformation';
import Insurance from './component/Insurance';
import { CASE_PROCESS_PAGE_ROUTE, CONSENTS_PAGE_ROUTE } from '../../routes';
import patientApi from '../../network/api/patientApi';
import referentialApi from '../../network/api/referentialApi';
import { createSearchFromParams } from '../../services/queryParamsService';
import ListLastDocuments from '../../components/medicalProfile/components/ListLastDocuments';
import CoreozDrawer from '../../components/CoreozDrawer';
import PlmPanel from '../../components/panel/PlmPanel';
import ModalAssigned from '../../components/modal/ModalAssigned';
import AutoSave from '../../components/AutoSave';
import { validateEmail, validateForEmail } from '../../validator';
import { cleanObject } from '../../utils';
import caseApi from '../../network/api/caseApi';
import caseReportApi from '../../network/api/caseReportApi';
import Loader from '../../components/Loader';
import policiesApi from '../../network/api/policiesApi';
import { DummyPolicyCmcCic, DummyPolicyItaly } from '../../enum';
import { hideAutoSaveBadge, showAutoSaveBadge, updateIsSalvageable } from '../../state/process/processService';
import { store } from '../../network/reduce';
import { CASE_INFOS } from '../../state/process/processReducer';
import businessUnitApi from '../../network/api/businessUnitApi';
import familyDoctorApi from '../../network/api/familyDoctor';
import protocolApi from '../../network/api/protocolApi';

type Props = {
    historyLogs: HistoryLog[],
    history: Function,
    searchHistoryLogs: ?HistoryLog[],
    dispatch: Function,
    queryParams: Object,
    caseInfos: Object,
    location: {
        state: Object,
    },
    autoSave: boolean,
    preferredCountries: Array<String>,
};

type State = {
    doctor: any,
    countries: Array<Object>,
    patient: Object,
    modal: boolean,
    newUsername: string,
    lastDocuments: {
        nbTeleadvice?: number,
        nbTeleconsult?: number,
        nbTeleconsultOn12Month?: number,
        nbOfMaxTeleconsult?: number,
        lastDocumentsBean?: Array<Object>,
    },
    beneficiary: Object,
    sideDrawerOpen: boolean,
    policy: Object,
    seeEligibility: boolean,
    loading: boolean,
    policyAssurance: Object
};

class Administration extends React.Component<Props, State> {
    state = {
      doctor: {},
      countries: [],
      patient: {},
      lastDocuments: {},
      modal: false,
      newUsername: '',
      sideDrawerOpen: false,
      policyAssurance: {},
      policy: null,
      phoneValid: true,
      seeEligibility: true,
      loading: false,
    };

    fetchCountries = () => {
      referentialApi
        .getAllCountries()
        .then(response => response.json())
        .then(countries => this.setState({ countries }))
        .catch(this.props.dispatch(notifyError));
    };

    shouldShowContinueButton = () => !this.props.queryParams.caseId;

    formIsDisabled = (invalid, values) => (invalid || !this.props.autoSave || !this.state.phoneValid || !(!(values.noEmailAddress === 'false') || !validateEmail(values.email)) || (this.props.caseInfos && !this.props.caseInfos.isEditable) || !values.genderCode);

    fetchDocuments = (patient) => {
      patientApi
        .getLastDocuments(
          this.props.queryParams.patientId,
          patient.protocolNumber || this.props.queryParams.protocolNumber,
        )
        .then(response => response.json())
        .then(lastDocuments => this.setState({ lastDocuments }))
        .catch(this.props.dispatch(notifyError));
    };

    fetchFamilyDoctor = () => {
      caseApi.getFamilyDoctor(this.props.queryParams.patientId)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : null))
        .then((doctor) => {
          if (doctor) {
            this.setState({ doctor });
          }
        })
        .catch(this.props.dispatch(notifyError));
    };

    fetchPolicy = (policyNumber: string) => {
      policiesApi.fetchPolicy(this.props.queryParams.patientId, policyNumber)
        .then(response => response.json())
        .then(policy => this.setState({ policy }));
    };

    fetchPolicyAssurance = () => {
      this.setState({ policyAssurance: {} });
      caseReportApi.getPolicyAssuranceInfosByPatient(this.props.queryParams.patientId)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : null))
        .then(policyAssurance => this.setState({ policyAssurance }))
        .catch(this.props.dispatch(notifyError));
    };

    setSeeEligibilityState = (fetchPolicy) => {
      let seeEligibility;
      if (this.props.queryParams.isCmCicProtocol) {
        seeEligibility = this.props.queryParams.isCmCicProtocol !== 'true';
        fetchPolicy(seeEligibility);
      } else if (this.props.queryParams.caseId) {
        protocolApi.fetchCheckProtocolsCmCic(this.props.queryParams.caseId)
          .then(response => response.text())
          .then((isCmCic) => {
            seeEligibility = isCmCic !== 'true';
            fetchPolicy(seeEligibility);
          })
          .catch(this.props.dispatch(notifyError));
      } else {
        seeEligibility = true;
        fetchPolicy(seeEligibility);
      }
      this.setState({ seeEligibility });
    };

    handleFetchPolicy = (seeEligibility) => {
      if (!!this.props.queryParams.policy && seeEligibility && !(this.props.queryParams.policy === DummyPolicyCmcCic || this.props.queryParams.policy === DummyPolicyItaly)) {
        if (this.props.caseInfos && this.props.caseInfos.caseInfos && this.props.caseInfos.caseInfos.policyNumber) {
          this.fetchPolicy(this.props.caseInfos.caseInfos.policyNumber);
        } else if (this.props.queryParams.policyNumber) {
          this.fetchPolicy(this.props.queryParams.policyNumber);
        }
      }
    };

    componentDidMount() {
      this.setSeeEligibilityState(this.handleFetchPolicy);
      let policyNumber = null;
      if (this.props.caseInfos && this.props.caseInfos.caseInfos && this.props.caseInfos.caseInfos.policyNumber) {
        policyNumber = this.props.caseInfos.caseInfos.policyNumber;
      } else if (this.props.queryParams.policyNumber) {
        policyNumber = this.props.queryParams.policyNumber;
      }

      patientApi.fetchById(this.props.queryParams.patientId, policyNumber)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : null))
        .then((patient) => {
          if (patient) {
            this.setState({ patient });
            this.fetchDocuments(patient);
          }
        })
        .catch(this.props.dispatch(notifyError));
      this.fetchFamilyDoctor();
      this.fetchPolicyAssurance();

      // TODO deplacer ces appels WS dans processCaseApi.fetchProcessDetails
      this.fetchCountries();
      this.fetchPreferredCountries();
    }


    drawerToggleClickHandle = () => {
      this.setState({
        sideDrawerOpen: !this.state.sideDrawerOpen,
      });
    };

    goToNextStep = () => {
      if (this.props.queryParams.isCmCicProtocol) {
        this.setState({ loading: true });
        caseApi.createCase({
          patientId: this.props.queryParams.patientId,
          patientMobileNumber: this.props.caseInfos.patient.mobilePhone,
          patientEmail: this.props.caseInfos.patient.email,
          protocolNumber: this.props.queryParams.protocolNumber,
          policyNumber: this.props.queryParams.policyNumber,
          isSharingConsent: false,
          isTreatmentConsent: false,
        })
          .then(response => response.text())
          .then((caseId) => {
            this.setState({ loading: false });
            /* TODO voir le cycle de vie pour eviter de vider les cases infos ici */
            this.props.dispatch(store(CASE_INFOS, null));
            this.props.dispatch(notifySuccess(this.props.dispatch, null, I18n.t('notify.success.CREATED')));

            this.props.history.push(CASE_PROCESS_PAGE_ROUTE + CONSENTS_PAGE_ROUTE
                        + createSearchFromParams({
                          ...this.props.queryParams,
                          caseId,
                          redirectToConsent: true,
                        }), this.props.location.state);
          })
          .catch(this.props.dispatch(notifyError));
      } else {
        this.props.history.push(CASE_PROCESS_PAGE_ROUTE + CONSENTS_PAGE_ROUTE
                + createSearchFromParams(this.props.queryParams), this.props.location.state);
      }
    };

    updateField = async (field, oldValue, newValue) => {
      if (this.familyDoctorFields.includes(field)) {
        if (this.state.doctor && this.state.doctor.id) {
          familyDoctorApi.saveField(null, this.state.doctor.id, field, {
            oldValue,
            newValue,
          }).catch(this.props.dispatch(notifyError));
        } else {
          familyDoctorApi.saveFieldByPatientId(this.props.queryParams.patientId, field, {
            oldValue,
            newValue,
          }).catch(this.props.dispatch(notifyError));
        }
      } else if (this.props.queryParams.caseId) {
        await caseApi.checkTakenInCharge(this.props.queryParams.caseId)
          .then(response => response.text())
          .then(text => (text.length ? JSON.parse(text) : {}))
          .then((changeInfos) => {
            if (changeInfos.change) {
              this.setState({ modal: true, newUsername: changeInfos.newUserName });
            } else {
              if (field === 'email' && newValue && !validateForEmail(newValue)) {
                return;
              }
              patientApi.saveField(this.props.queryParams.caseId, this.state.patient.id, field, {
                oldValue,
                newValue,
              });
            }
          })
          .catch(e => console.log('Erreur apppel API', e));
      } else {
        if (field === 'email' && newValue && !validateForEmail(newValue)) {
          return;
        }
        patientApi.saveField(this.props.queryParams.caseId, this.state.patient.id, field, {
          oldValue,
          newValue,
        });
      }
    };

    fetchPreferredCountries = () => {
      businessUnitApi
        .fetchListofCountries()
        .then(result => result.json())
        .then(preferredCountries => this.setState({ preferredCountries }))
        .catch(this.props.dispatch(notifyError));
    }

    isSalvageable = (props, values) => {
      const emailValid = values.noEmailAddress === 'false' ? validateForEmail(values.email) : true;
      const consultOnly = props.caseInfos && !props.caseInfos.isEditable;
      const isSalvageable = consultOnly || (!props.invalid && !props.autoSave && this.state.phoneValid && emailValid);

      updateIsSalvageable(isSalvageable)(this.props.dispatch);
    };

    initialData = () => {
      let brand = '';
      if (this.state.policyAssurance && this.state.policyAssurance.brand) {
        brand = this.state.policyAssurance.brand;
      } else if (this.props.caseInfos && this.props.caseInfos.caseInfos.brand) {
        brand = this.props.caseInfos.brand;
      }

      return {
        ...cleanObject(this.props.queryParams),
        ...cleanObject(this.state.patient),
        DOCTOR_FIRSTNAME: this.state.doctor.firstName || '',
        DOCTOR_LASTNAME: this.state.doctor.lastName || '',
        DOCTOR_COUNTRY: this.state.doctor.country || '',
        DOCTOR_CITY: this.state.doctor.city || '',
        DOCTOR_POSTALCODE: this.state.doctor.postcode || '',
        DOCTOR_ADDRESS: this.state.doctor.address || '',
        DOCTOR_EMAIL: this.state.doctor.email || '',
        DOCTOR_PHONE: this.state.doctor.phone || '',
        legalEntityName: this.state.policyAssurance && this.state.policyAssurance.legalEntity || '',
        policyNumber: this.state.patient && this.state.patient.policyNumber,
        protocolNumber: this.state.patient && this.state.patient.protocolNumber,
        email: this.state.patient && this.state.patient.email,
        noEmailAddress: this.state.patient && this.state.patient.noEmailAddress ? 'true' : 'false',
        policyHolderLastName: this.state.patient && this.state.patient.policyHolderLastName,
        policyHolderFirstName: this.state.patient && this.state.patient.policyHolderFirstName,
        beneficiaryFirstName: this.state.patient && this.state.patient.beneficiaryFirstName,
        beneficiaryLastName: this.state.patient && this.state.patient.beneficiaryLastName,
        beneficiaryBirthday: this.state.patient && this.state.patient.beneficiaryBirthday,
        brand,
        isPdaAccount: this.state.patient && this.state.patient.isPdaAccount ? 'true' : 'false',

      };
    };

    familyDoctorFields = [
      'DOCTOR_FIRSTNAME',
      'DOCTOR_LASTNAME',
      'DOCTOR_COUNTRY',
      'DOCTOR_CITY',
      'DOCTOR_POSTALCODE',
      'DOCTOR_ADDRESS',
      'DOCTOR_EMAIL',
      'DOCTOR_PHONE'];

    contractInformationFields = [
      'policyHolderFirstName',
      'policyHolderLastName',
      'policyHolderCity',
      'policyHolderPhonenumber',
      'beneficiaryFirstName',
      'beneficiaryLastName',
      'beneficiaryBirthday',
      'policyNumber',
      'protocolNumber',
      'partnerName',
      'legalEntityName',
      'isPdaAccount'];

    render() {
      return (
        <div className="administration">
          <ModalAssigned
            modal={this.props.caseInfos && this.props.caseInfos.isEditable ? this.state.modal : false}
            toggle={() => this.setState({ modal: !this.state.modal })}
            newUsername={this.state.newUsername}
          />
          {
              (Object.keys(this.state.patient).length
                  && Object.keys(this.state.policyAssurance).length) ?
                    <Form
                      onSubmit={this.goToNextStep}
                      initialValues={this.initialData()}
                      mutators={{ setFieldData }}
                      render={({
                                         handleSubmit, values, form, invalid,
                                     }) => (
                                       <form onSubmit={handleSubmit}>
                                         <AutoSave
                                           setFieldData={form.mutators.setFieldData}
                                           save={this.updateField}
                                           ignoreFields={['partnerName', 'id'].concat(this.contractInformationFields)}
                                         />
                                         <FormSpy
                                           subscription={{ invalid: true }}
                                           onChange={(props) => {
                                            this.isSalvageable(props, values);
                                        }}
                                         />
                                         <header className="step-title">
                                           <div className="content-title ">
                                             <i className="fal fa-id-card" /> {I18n.t('administration.TITLE')}
                                           </div>
                                           <div className="header-action">
                                             <span>* </span>
                                             <span>{I18n.t('administration.MANDATORY')}</span>
                                             {
                                                this.shouldShowContinueButton() &&
                                                <Button
                                                  className="validation-button"
                                                  color="secondary"
                                                  type="submit"
                                                  disabled={this.formIsDisabled(invalid, values) || this.state.loading}
                                                >
                                                    {
                                                        this.state.loading ?
                                                          <Loader small grey />
                                                            :
                                                          <div>
                                                            <i className="button-icon fa fa-arrow-circle-right" />
                                                            <span>{I18n.t('button.CONTINUE')}</span>
                                                          </div>
                                                    }
                                                </Button>
                                            }
                                           </div>
                                         </header>
                                         <Translate className="please" value="administration.PLEASE" />
                                         <div className="content">
                                           <Identity
                                             values={values}
                                             countries={this.state.countries}
                                             change={form.change}
                                             preferredCountries={this.state.preferredCountries || []}
                                             phoneValid={newValue => this.setState({ phoneValid: newValue })}
                                           />
                                           <Row className="row">
                                             <Col md="12" lg="6">
                                               <Doctor
                                                 formValues={values}
                                                 onFormChange={form.change}
                                                 preferredCountries={this.state.preferredCountries || []}
                                               />
                                               <div className="last-documents">
                                                 <span
                                                   className="last-documents-title"
                                                 >{I18n.t('administration.lastDocuments.history')}
                                                 </span>
                                                 <div>
                                                   <div className="last-consultations">
                                                     <PlmPanel>
                                                       <div
                                                         className="info-lign"
                                                         onClick={parseInt(this.state.lastDocuments.nbTeleadvice)
                                                                        ? this.drawerToggleClickHandle : null}
                                                       >
                                                         <span>{I18n.t('administration.lastDocuments.total')}</span>
                                                         <p className="font-weight-lighter title">
                                                           {I18n.t('administration.lastDocuments.advice')}
                                                         </p>
                                                         <p
                                                           className="number"
                                                         >{this.state.lastDocuments.nbTeleadvice || 0}
                                                         </p>
                                                         <a> {parseInt(this.state.lastDocuments.nbTeleadvice) ? I18n.t('administration.lastDocuments.showAll') : ''}</a>
                                                       </div>
                                                     </PlmPanel>
                                                     <PlmPanel>
                                                       {
                                                                    this.state.lastDocuments &&
                                                                    <div
                                                                      className="info-lign"
                                                                      onClick={parseInt(this.state.lastDocuments.nbTeleconsult)
                                                                            ? this.drawerToggleClickHandle : null}
                                                                    >
                                                                      <span>{I18n.t('administration.lastDocuments.total')}</span>
                                                                      <p
                                                                        className="font-weight-lighter title"
                                                                      >{I18n.t('administration.lastDocuments.consult')}
                                                                      </p>
                                                                      <p
                                                                        className="number"
                                                                      >{this.state.lastDocuments.nbTeleconsult || 0}
                                                                      </p>
                                                                      <a> {parseInt(this.state.lastDocuments.nbTeleconsult) ? I18n.t('administration.lastDocuments.showAll') : ''}</a>
                                                                    </div>
                                                                }

                                                     </PlmPanel>
                                                   </div>
                                                 </div>
                                               </div>
                                               <div className="last-documents">
                                                 <span
                                                   className="last-documents-title"
                                                 >{I18n.t('administration.lastDocuments.nbOfTeleconsultRollingMonthsForProtocol')}
                                                 </span>
                                                 <span
                                                   className="protocol-title"
                                                 >{this.props.queryParams.partnerName || this.state.patient.bpartnerName}
                                                   {' - '} {this.props.queryParams.protocolNumber || this.state.patient.protocolNumber}
                                                 </span>
                                                 <div>
                                                   <div className="last-consultations">
                                                     <PlmPanel>
                                                       {
                                                                    this.state.lastDocuments &&
                                                                    <div
                                                                      className="info-lign"
                                                                    >
                                                                      <span>{I18n.t('administration.lastDocuments.total')}</span>
                                                                      <p
                                                                        className="font-weight-lighter title"
                                                                      >{I18n.t('administration.lastDocuments.onRollingMonths')}
                                                                      </p>
                                                                      <p
                                                                        className="number"
                                                                      >{(this.state.lastDocuments.nbTeleconsultOn12Months) + (this.state.lastDocuments.nbOfMaxTeleconsult && (` / ${this.state.lastDocuments.nbOfMaxTeleconsult}`))
                                                                            || 0}
                                                                      </p>
                                                                    </div>
                                                                }
                                                     </PlmPanel>
                                                   </div>
                                                 </div>
                                               </div>
                                             </Col>
                                             <Col md="12" lg="6">
                                               <Insurance />
                                               {this.state.seeEligibility &&
                                               <PolicyInformation />
                                                }
                                             </Col>
                                           </Row>
                                           <Row>
                                             <Col md="12" lg="12" className="button-col-container">
                                               {
                                                    this.shouldShowContinueButton() &&
                                                    <Button
                                                      className="validation-button"
                                                      color="secondary"
                                                      type="submit"
                                                      disabled={this.formIsDisabled(invalid, values) || this.state.loading}
                                                    >
                                                        {
                                                            this.state.loading ?
                                                              <Loader small grey />
                                                                :
                                                              <div>
                                                                <i className="button-icon fa fa-arrow-circle-right" />
                                                                <span>{I18n.t('button.CONTINUE')}</span>
                                                              </div>
                                                        }
                                                    </Button>
                                                }
                                             </Col>
                                           </Row>
                                         </div>
                                       </form>)}
                    />
                        :
                    <div className="loader-administration"><Loader /></div>
                }
          <CoreozDrawer show={this.state.sideDrawerOpen}>
            <ListLastDocuments
              patientId={this.props.caseInfos && this.props.caseInfos.patient && this.props.caseInfos.patient.id}
              goBack={this.drawerToggleClickHandle}
              lastDocuments={this.state.lastDocuments.lastDocumentsBean}
              nbTeleconsult={this.state.lastDocuments.nbTeleconsult}
              nbTeleadvice={this.state.lastDocuments.nbTeleadvice}
              nbMaxOfTeleconsult={this.state.lastDocuments.nbOfMaxTeleconsult}
            />
          </CoreozDrawer>
        </div>
      );
    }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
  historyLogs: state.history.historyLogs,
  searchHistoryLogs: state.history.searchHistoryLogs,
  autoSave: state.process.autoSave,
}))(Administration));
