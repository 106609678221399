// @flow

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';

import PlmPanel from '../../components/panel/PlmPanel';
import PlmFormItem from '../../components/panel/PlmFormItem';
import { MAIL_PAGE_ROUTE } from '../../routes';
import { required } from '../../validator';
import type { Mail } from '../../types/MailTypes';
import { toggleConfirmationWithModal } from '../../state/notifications/notificationService';

import mailApi from '../../network/api/mailApi';
import { MAILS } from '../../state/mail/mailReducer';
import { store } from '../../network/reduce';
import { fetchMails } from '../../state/mail/mailService';
import { notifyError, notifySuccess } from '../../network/notification';
import ResizableTextarea from '../../components/ResizableTextarea';

type Props = {
  mails: Mail[],
  match: {
    params: {
      id: string,
    }
  },
  history: Object,
  dispatch: Function,
}

type State = {
  labelTitle: string,
  mailSelected: Mail,
}

class MailDetails extends React.Component<Props, State> {
  state = {
    labelTitle: I18n.t('mail.NEW_TITLE'),
    mailSelected: {},
  };

  componentDidMount() {
    if (this.props.match.params.id !== null) {
      const mailSelectedId = this.props.match.params.id;
      mailApi
        .fetch()
        .then(response => response.json())
        .then((mails) => {
          this.props.dispatch(store(MAILS, mails));
          const currentMail = mails.filter(mail => mail.id === mailSelectedId)[0];
          this.setState({
            mailSelected: currentMail,
            labelTitle: I18n.t('mail.EDIT_TITLE'),
          });
        });
    }
  }

  saveMail = (mail: Mail) => {
    mailApi
      .save(mail)
      .then(this.props.dispatch(notifySuccess))
      .then(() => {
        this.props.dispatch(fetchMails());
        this.props.history.push(MAIL_PAGE_ROUTE);
      })
      .catch(this.props.dispatch(notifyError));
  };

  componentWillUnmount() {
    this.props.dispatch(toggleConfirmationWithModal(false));
  }

  getMailTypeOptionList = (mails: Array<Object>) => {
    const mailTypeOptionList = [];
    mails.forEach((option: Object) => {
      if (!mailTypeOptionList.includes(option.mailType)) {
        mailTypeOptionList.push(option.mailType);
      }
    });
    return mailTypeOptionList;
  };

  render() {
    if (this.props.mails === undefined) {
      return false;
    }
    return (
      <div>
        <h1 className="content-title"><Translate value="mail.TITLE" /></h1>
        <div className="content">
          <PlmPanel title={this.state.labelTitle}>
            <Form
              className="form-horizontal"
              onSubmit={this.saveMail}
              initialValues={this.state.mailSelected}
              render={({ handleSubmit, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="panel-body">
                    <PlmFormItem label={I18n.t('mail.label.idBusinessUnit')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="input"
                        name="idBusinessUnit"
                      />
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('mail.label.mailType')}>
                      <Field
                        className="form-control"
                        component="select"
                        name="mailType"
                        type="text"
                      >
                        <option value="any">{I18n.t('mail.label.all')}</option>
                        {this.props.mails.length > 0 &&
                        this.getMailTypeOptionList(this.props.mails)
                          .map(option => (
                            <option value={option} key={option}>{option}</option>
                          ))
                        }
                      </Field>
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('mail.label.subject')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="input"
                        name="subject"
                      />
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('mail.label.template')}>
                      <Field
                        component={ResizableTextarea}
                        minrows={5}
                        maxrows={30}
                        name="template"
                        validate={required}
                      />
                    </PlmFormItem>
                  </div>
                  <div className="panel-footer">
                    <Link
                      className="btn btn-secondary"
                      style={{ marginRight: '5px' }}
                      to={MAIL_PAGE_ROUTE}
                    >
                      <Translate value="actions.BACK" />
                    </Link>
                    <button type="submit" className="btn btn-success" disabled={invalid || submitting}>
                      <Translate value="actions.SAVE" />
                    </button>
                  </div>
                </form>
              )}
            />
          </PlmPanel>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  mails: state.mail.mails,
}))(MailDetails));

