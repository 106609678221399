// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Button from 'reactstrap/es/Button';
import { CoreozInputBase as CoreozInput } from '../../lib/coreoz-form-base/index';
import ModalEprm from '../../components/modal/ModalEprm';
import { connect } from 'react-redux';

type Props = {
  dispatch: Function,
  sendForgotPasswordEmail: Function,
  close: Function,
  modal: boolean,
}

type States = {
  contactMeans: Object,
}

class ForgotPasswordModal extends React.Component<Props, States> {
  handleSubmit = (data) => {
    const { login } = data;
    this.props.sendForgotPasswordEmail(login);
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('forgot_password.modal.TITLE')}
        modal={this.props.modal}
        toggle={this.props.closedSelf}
        className="forgot-password-modal"
        size="lg"
      >
        <Form
          onSubmit={this.handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="body">
              {I18n.t('forgot_password.modal.INSTRUCTIONS')}
              <Field
                placeholder={I18n.t('forgot_password.modal.LOGIN')}
                component={CoreozInput}
                type="text"
                name="login"
              />
              <div className="footer">
                <Button type="submit"
                        color="primary"
                        className="validation-button">
                  <i className="fal fa-arrow-circle-right" />
                  {I18n.t('forgot_password.modal.SUBMIT')}
                </Button>
              </div>
            </form>)}
        />
      </ModalEprm>
    );
  }
}

export default withRouter(connect()(ForgotPasswordModal));
