// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { Field } from 'react-final-form';

import { currentSessionService } from '../../services/sessionServiceInstance';
import { Roles } from '../../enum';
import ResizableTextarea from '../ResizableTextarea';

type Props = {
  logo: string,
  brand: string,
  legalEntity: string,
  legalEntityAddress: string,
  actor: string,
  organizationMail: string,
  place: string,
  patient: Object,
  adviceNote: string,
  technicalIssue: boolean,
  technicalIssueText: string,
  userName: string,
  nurse: boolean,
  familyDoctorConsent: boolean,
  rpps: string,
  speciality: string,
  reasonForTheCall: string,
  showLogoInDocument: boolean,
  getPatientPresentation: string,
  previewMode: boolean,
  signature: String,
  hospitals: Array<Object>,
}

type State = {
  user: Object,
}

class TeleAdviceReportPreview extends React.PureComponent<Props, State> {
  state = {
    user: currentSessionService().currentUser(),
  };

  render() {
    return (
      <div className="tele-advice-report-preview">
        {this.props.previewMode && <div className="document-footer footer">
          <span className="important-text">{I18n.t('editDocument.EMAIL')} : </span>
          <a href={'mailto:' + this.props.organizationMail}
             className="text text-with-link">{this.props.organizationMail}</a>
          <div className="important-text">{I18n.t('editDocument.OPEN_HOURS')}</div>
        </div>}
        <div className="pdf-header">
          <div className="document-header">
            {this.props.showLogoInDocument &&
            <div className="logo-container">
              <img src={this.props.logo} alt={this.props.brand} />
            </div>
            }
            <div className="document-doctor-info">
              <div
                className="important-text"> {this.state.user.userRole === Roles.DOCTOR && I18n.t('editDocument.DOCTOR')}
                <span
                  className="important-text doctor">{!!this.props.userName && this.props.userName.toUpperCase()}</span>
              </div>
              {this.state.user.userRole === Roles.NURSE && <div
                className="important-text">{I18n.t('editDocument.NURSE')}</div>}
              {this.state.user.userRole === Roles.DOCTOR &&
              <div>
                {/*<div className="important-text">{this.props.speciality.toUpperCase()}</div>*/}
                <div>
                  <span className="important-text">{I18n.t('editDocument.ID_NUMBER')}</span>
                  <span className="text">{this.props.rpps}</span>
                </div>
              </div>}
            </div>
            <div className="document-address">
              <div className="important-text">{I18n.t('editDocument.ADDRESS')} :</div>
              <div className="text-small">{I18n.t('editDocument.ORGANIZATION')}
                <span className="important-text-small">{this.props.legalEntity}</span>
              </div>
              <div className="text-small">{I18n.t('editDocument.UNDER_NAME')}
                <span className="important-text-small"> "{this.props.brand}"</span>
              </div>
              <div className="text-small">{this.props.legalEntityAddress}</div>
              <div className="bloc-text text-small">{I18n.t('editDocument.IMPLEMENTED_BY')} :
                <span className="important-text-small"> {this.props.actor}</span>
              </div>
              <div>
                <span className="important-text">{I18n.t('editDocument.EMAIL')} : </span>
                <a href={'mailto:' + this.props.organizationMail}
                   className="text text-with-link">{this.props.organizationMail}</a>
              </div>
              <div className="important-text">{I18n.t('editDocument.OPEN_HOURS')}</div>
            </div>
            <div
              className="document-date text">{this.props.place}, {I18n.t('editDocument.THE')} {moment().format(I18n.t('date.DATE_FORMAT'))}</div>
            <div>
              <div className="important-text">{I18n.t('editDocument.ATTENTION_OF')} </div>
              <div className="text">
                {`${I18n.t('editDocument.PATIENT')} : ${this.props.patient.genderCode === '1' ? I18n.t('editDocument.SIR') : I18n.t('editDocument.MADAM')} `}
                <span className="text">
                  {this.props.patient.firstName.toUpperCase()} {this.props.patient.lastName.toUpperCase()}
                </span>
                {` ${I18n.t('editDocument.LEGAL_REPRESENTATIVE')}`}
              </div>
              {this.props.familyDoctorConsent && (this.props.case.familyDoctorFirstname || this.props.case.familyDoctorLastname) &&
              <div className="text">
                {I18n.t('editDocument.DOCTOR')} :
                <span className="text">
                  {this.props.case.familyDoctorFirstname && this.props.case.familyDoctorFirstname.toUpperCase()} {this.props.case.familyDoctorLastname && this.props.case.familyDoctorLastname.toUpperCase()}
                </span>, {I18n.t('editDocument.FAMILY_DOCTOR')}
              </div>}
            </div>
            {this.props.previewMode &&
            <div className="numbering">
            <span className="important-text">{I18n.t('editDocument.TELEADVICE_REPORT_NUMBERING')} (<span
              className="pagenumber"></span> / <span className="pagecount"></span>)</span>
            </div>}
          </div>
        </div>
        <div className="numbering-pdf-header">
        {this.props.previewMode &&
        <div className="numbering">
            <span className="important-text">{I18n.t('editDocument.TELEADVICE_REPORT_NUMBERING')} (<span
                className="pagenumber"></span> / <span className="pagecount"></span>)</span>
        </div>}
        </div>
        <div className="report-content">
          <div className="document-patient-info">
            <div
              className="bloc-text text">{this.state.user.userRole === Roles.DOCTOR ? I18n.t('editDocument.DEAR_COLLEAGUE') : I18n.t('editDocument.DEAR_DOCTOR')},
            </div>
            <div className="text">
              {this.props.getPatientPresentation}
            </div>
            <div className="text">{I18n.t('editDocument.ADVICE.TO_BE_FOLLOWED')}</div>
          </div>
          <div className="profil-medical">
            <div
              className="important-text bloc-text">{I18n.t('editDocument.ADVICE.TITLE_1')}
            </div>
            <div className="bloc-text important-text">
              {
                this.props.previewMode ?
                  <div className="adviceNotes text">
                    {this.props.reasonForTheCall}
                  </div>
                  :
                  <Field
                    component={ResizableTextarea}
                    name="reasonForTheCall"
                    minrows={10}
                    maxrows={25}
                  />
              }
            </div>
            <div className="resume important-text bloc-text">{I18n.t('editDocument.ADVICE.TITLE_2')}
            </div>
            <div className="bloc-text important-text">
              {
                this.props.previewMode ?
                  <div className="adviceNotes text">
                    {this.props.adviceNote}
                  </div>
                  :
                  <Field
                    component={ResizableTextarea}
                    name="adviceNote"
                    minrows={10}
                    maxrows={25}
                  />
              }
            </div>
          </div>

          <div className="teleconsultation-hospitals">
            {
              !!this.props.hospitals.length &&
              <div>
                <div>{I18n.t('editDocument.HOSPITALS_GIVEN')}</div>
                <ul>
                  {
                    this.props.hospitals.map(hospital => <li>{hospital}</li>)
                  }
                </ul>
              </div>
            }
          </div>
          <div className="teleconsultation-notes">
            <div
              className="technical-problem bloc-text text">{`${I18n.t('editDocument.TECHNICAL_ISSUE')}: `} {this.props.technicalIssue ?
              <Fragment>
                <span className="text">{I18n.t('form.YES').toUpperCase()}</span>
                <div className="detail text">
                  {`${I18n.t('editDocument.DETAILS')}: ${this.props.technicalIssueText}`}
                </div>
              </Fragment>
              :
              <span className="text">{I18n.t('form.NO').toUpperCase()}</span>
            }
            </div>
          </div>

        </div>

        <div className="document-signature">
          <div className="teleconsultation-notes">
            <div
              className="bloc-text text">{this.state.user.userRole === Roles.DOCTOR ? I18n.t('editDocument.ADVICE.END_DOCTOR') : I18n.t('editDocument.ADVICE.END')}</div>
          </div>
          <div className="doctor">
            <div
              className="important-text">{this.state.user.userRole === Roles.DOCTOR && I18n.t('editDocument.DOCTOR')}
              <span className="important-text">{this.props.userName && this.props.userName.toUpperCase()}</span>
            </div>
            {this.state.user.userRole === Roles.DOCTOR &&
              <img
                src={this.props.signature}
                className="doctor-signature"
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default TeleAdviceReportPreview;
