// @flow

import { RestClient } from '../network';

const confApi = {
  fetchEnv: () => new RestClient('/conf', 'GET').execute(),
  fetchConsentLink: () => new RestClient('/conf/consentslink', 'GET').execute(),
  fetchGoogleApiKey: () => new RestClient('/conf/google', 'GET').execute(),
  fetchPdaUrl: (buCode : string) => new RestClient(`/conf/pdaurl/${buCode}`, 'GET').execute()
};

export default confApi;
