// @flow

import { RestClientAuthenticated } from '../network';
import { notifyError } from '../../network/notification';
import {
  SearchResultType,
  SearchCriteriaType,
  SearchResultContentItem,
  LegalEntityDetail, LegalEntityRequest,
} from '../../types/LegalEntityManagement';
import data from './mockData/legalEntity.json';

const apiEndpoint = {
  searchList: '/legal-entity/search',
  getById: '/legal-entity',
  save: '/legal-entity',
  fetchList: '/legal-entity/list',
};

const legalEntityApi = {
  search: (searchCriteria: SearchCriteriaType): SearchResultType => (dispatch: Function) => {
    const queryString = Object.keys(searchCriteria).filter(v => searchCriteria[v] !== null).map(key => `${key }=${ searchCriteria[key]}`).join('&');

    return new RestClientAuthenticated(`${apiEndpoint.searchList}?${queryString}`, 'GET').execute()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : null))
      .catch(e => dispatch(notifyError)(e));
  },
  fetchById: legalEntityId => (dispatch: Function) => new RestClientAuthenticated(`${apiEndpoint.getById}/${legalEntityId}`, 'GET').execute().then(response => (response.status === 200) && response.json()).catch(e => dispatch(notifyError)(e)),
  save: (Request: LegalEntityRequest) => new RestClientAuthenticated(`${apiEndpoint.save}`, 'POST').jsonBody(Request).execute(),
  update: (legalEntityId, Request: LegalEntityRequest) => new RestClientAuthenticated(`${apiEndpoint.save}/${legalEntityId}`, 'PUT').jsonBody(Request).execute(),
  fetchList: (): FetchLegalEntityResponse[] => new RestClientAuthenticated(`${apiEndpoint.fetchList}`, 'GET').execute().then(response => response.json()),
};

export interface FetchLegalEntityResponse {
  id: string;
  code: number;
  name: string;
  isActive: boolean;
}

const mockResponse = (searchCriteria: SearchCriteriaType): SearchResultType => {
  const firstPageIndex = (searchCriteria.page - 1) * searchCriteria.size;
  const lastPageIndex = firstPageIndex + searchCriteria.size;

  const resultContent = data.slice(firstPageIndex, lastPageIndex);

  const total = data.length;
  const perPage = Math.ceil(total / searchCriteria.size);
  return {
    content: resultContent,
    page: searchCriteria.page,
    perPage,
    total,
    total_pages: searchCriteria.size,
  };
};

const mockResponseById = (id: number): LegalEntityDetail => data.find(v => v.id == id);
export default legalEntityApi;
