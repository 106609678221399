import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Table } from 'reactstrap';
import { SearchResultContentItem } from '../../../types/logoManagement';

type Props = {
    currentItems: SearchResultContentItem[],
    goToView: Function,
};

function LogoTable({ currentItems, goToView }: Props) {
  return (
    <Table bordered>
      <thead>
        <tr>
          <th>
            {I18n.t('logoManagement.TABLE.LOGO_NAME')}
          </th>
          <th>
            {I18n.t('logoManagement.TABLE.BUSINESS_UNIT')}
          </th>
          <th>
            {I18n.t('logoManagement.TABLE.STATUS')}
          </th>
          <th>
            {I18n.t('logoManagement.TABLE.CREATED')}
          </th>
        </tr>
      </thead>
      <tbody>
        {currentItems && currentItems.map(item => (
          <tr
            key={item.id}
            onClick={() => {
                        goToView(item.id);
                    }}
          >
            <td>{item.logoName}</td>
            <td>{item.businessUnitName}</td>
            <td>{item.isActive ? I18n.t('logoManagement.STATUS.ACTIVE') : I18n.t('logoManagement.STATUS.INACTIVE')}</td>
            <td>{ item.createdDate && moment(item.createdDate).format(I18n.t('date.DATE_FORMAT'))}</td>
          </tr>
                ))}
      </tbody>
    </Table>
  );
}

export default LogoTable;
