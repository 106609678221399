// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';

type Props = {
  icone: string,
  label: string,
  links: Array,
}

class MedicalResourceShort extends React.Component<Props, State> {

  render() {
    return (
      <div>
        <header>
          <h2><i className={`fas ${this.props.icone}`} />
            {this.props.label}</h2>
        </header>
        <div className="block">
          {
            this.props.links.map(linkObj =>
              <a target="_blank" href={linkObj.link} className="display-lign" key={linkObj.label}>
                <i className="fa fa-external-link" />
                <p>{linkObj.label}</p>
              </a>
            )
          }
        </div>
      </div>
    );
  }
}

export default withRouter(MedicalResourceShort);
