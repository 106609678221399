// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MAIL_PAGE_ROUTE } from '../../routes';
import PlmPanel from '../panel/PlmPanel';
import PlmReactTable from '../PlmReactTable';
import type { Mail } from '../../types/MailTypes';

type Props = {
  mails: Mail[],
  history: Object,
}

type State = {
  columns: Array<{
    Header: string,
    accessor: string,
  }>,
};

class MailList extends React.Component<Props, State> {
  state = {
    columns: [
      {
        Header: I18n.t('mail.label.idBusinessUnit'),
        accessor: 'idBusinessUnit',
      },
      {
        Header: I18n.t('mail.label.mailType'),
        accessor: 'mailType',
      },
      {
        Header: I18n.t('mail.label.subject'),
        accessor: 'subject',
      },
    ],
  };

  render() {
    if (this.props.mails === undefined || !Array.isArray(this.props.mails)) {
      return false;
    }
    return (
      <PlmPanel title={I18n.t('mail.SUBTITLE')}>
        <div className="panel-body">
          <PlmReactTable
            style={{ cursor: 'pointer', height: '450px' }}
            className="table table-striped"
            data={this.props.mails}
            columns={this.state.columns}
            getTdProps={(state, rowInfo) => ({
              onClick: () => {
                if (rowInfo) {
                  this.props.history.push(`${MAIL_PAGE_ROUTE}/${rowInfo.original.id}`);
                }
              },
            })}
          />
        </div>
      </PlmPanel>
    );
  }
}

export default withRouter(connect()(MailList));
