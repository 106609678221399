// @flow
import { currentUser } from './sessionService';

function hasPermission(permission: string) {
  const user = currentUser();
  let hasPermission;

  if (user) {

    hasPermission = user.permissions.includes(permission);

    if(hasPermission && 'MANAGE_INTERNAL_BENEFICIARY' == permission) {
      hasPermission = user.canManageInternalBeneficiary;
    }
    
  }

  return hasPermission;
}

export default hasPermission;
