// @flow

import { I18n } from 'react-redux-i18n';
import Button from 'reactstrap/es/Button';
import React from 'react';
import type { HistoryType } from '../../../types/HistoryTypes';

type Props = {
  drawerToggleHistory: Function,
  history: HistoryType[]
}

export default function LastEdit({ history, drawerToggleHistory }: Props) {
  return (
    <div className="case-last-edit">
      <div className="last-edit">{I18n.t('caseDetails.LAST_EDIT')}</div>
      <div
        className="last-editor"
      >{history[0].userFirstName} {history[0].userLastName}
      </div>
      <div className="edit-date">{history[0].eventDate}</div>
      <Button
        color="secondary"
        onClick={drawerToggleHistory}
      >
        <i className="fa fa-history" />
        {I18n.t('caseDetails.HISTORY')}
      </Button>
    </div>
  );
}
