// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'reactstrap';
import { Field } from 'react-final-form';
import { CoreozInputBase as CoreozInput } from '../../../lib/coreoz-form-base/index';
import { connect } from 'react-redux';
import PlmPanel from '../../../components/panel/PlmPanel';
import { notifyError } from '../../../network/notification';
import businessUnitApi from '../../../network/api/businessUnitApi';
import CoreozPhoneInput from '../../../components/CoreozPhoneInput';

type Props = {
  dispatch: Function,
  onFormChange: Function,
  formValues: {
    DOCTOR_PHONE: string,
  },
  caseInfos: Object,
  preferredCountries: Array<String>,
};

type State = {};

class Doctor extends React.PureComponent<Props, State> {

  componentDidMount(): void {
  }

  render() {
    return (
      <Row>
        <Col xs="12">
          <PlmPanel title="">
            <span className="title-panel">{I18n.t('administration.doctor.TITLE')}</span>
            <Field
              label={I18n.t('administration.doctor.FIRSTNAME')}
              component={CoreozInput}
              name="DOCTOR_FIRSTNAME"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.doctor.LASTNAME')}
              component={CoreozInput}
              name="DOCTOR_LASTNAME"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.doctor.STREET')}
              component={CoreozInput}
              name="DOCTOR_ADDRESS"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.doctor.POSTCODE')}
              component={CoreozInput}
              name="DOCTOR_POSTALCODE"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.doctor.CITY')}
              component={CoreozInput}
              name="DOCTOR_CITY"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.doctor.COUNTRY')}
              component={CoreozInput}
              name="DOCTOR_COUNTRY"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            <Field
              label={I18n.t('administration.doctor.EMAIL')}
              component={CoreozInput}
              name="DOCTOR_EMAIL"
              type="email"
              autoComplete="none"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
            {
              this.props.preferredCountries.length &&
              <CoreozPhoneInput
                preferredCountries={this.props.preferredCountries}
                value={this.props.formValues.DOCTOR_PHONE ? this.props.formValues.DOCTOR_PHONE : ''}
                label={I18n.t('administration.doctor.PHONE')}
                onChange={value => this.props.onFormChange('DOCTOR_PHONE', value)}
                disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
              />
            }
          </PlmPanel>
        </Col>
      </Row>

    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(Doctor));
