// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { downloadBase64 } from '../../../utils';
import patientApi from '../../../network/api/patientApi';

type Props = {
  reasonForTheCall?:string,
  symptomCheckFlag?:string,
  teleAdviceNotes?:string,
  teleConsultationNotes?:string,
  documents?:Array<Object>,
};

class SummaryTile extends React.Component<Props, State> {
  download2 = (fileInfos) => {
    downloadBase64(fileInfos.base64, fileInfos.name)
  };

  download = (fileInfos) => {
    patientApi
      .getDocument(fileInfos.id)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((document) => {
        downloadBase64(document.base64, document.name);
      })
      .catch(e => console.log('Erreur apppel API', e));
  };
  render() {
    return (
      <div className="summary-container">
        {this.props.reasonForTheCall &&
        <div>
          <div className="category-subtitle">{I18n.t('teleconsultation.REASON_FOR_CALL')}</div>
          <div className="summary-content">{this.props.reasonForTheCall}</div>
        </div>
        }
        {this.props.symptomCheckFlag &&
        <div>
          <div className="category-subtitle">{I18n.t('teleconsultation.SYMPTOM_FLAG')}</div>
          <div className="summary-content">{this.props.symptomCheckFlag}</div>
        </div>
        }
        {this.props.teleAdviceNotes &&
        <div>
          <div className="category-subtitle">{I18n.t('teleconsultation.TELEADVICE_NOTES')}</div>
          <div className="summary-content">{this.props.teleAdviceNotes}</div>
        </div>
        }
        {this.props.teleConsultationNotes &&
        <div>
          <div className="category-subtitle">{I18n.t('teleconsultation.TELECONSULTATION_NOTES')}</div>
          <div className="summary-content">{this.props.teleConsultationNotes}</div>
        </div>
        }
        {this.props.documents &&
        <div>
          <div className="category-subtitle">{I18n.t('teleconsultation.DOCUMENTS')}</div>
          {
            this.props.documents && this.props.documents.map(document =>
              (
                <div className="tile-document">
                  <div className="document-name">{document.name}</div>
                  <div className="document-save-date"><i className="fa fa-save"/>{moment(document.creationDate).format(I18n.t('date.DATE_LONG'))}</div>
                  <div className="document-action">
                    <a onClick={() => this.download(document)}>{I18n.t('lastDocuments.DOWNLOAD')}</a>
                  </div>
                </div>
              ))
          }
        </div>
        }
      </div>

    )
  }

}

export default SummaryTile;
