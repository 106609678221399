// @flow

import { RestClientAuthenticated } from '../network';

export type Pharmacy = {
  name: string;
  address: string;
  city: string;
  country: string;
  faxNumber: string;
  phoneNumber: string;
  postalCode: string;
}
const pharmacyApi = {
  fetchByPharmacyPrmId: (pharmacyId: string) => new RestClientAuthenticated(`/pharmacy/${pharmacyId}`, 'GET').execute(),
  searchPharmacy: (filters: Object) => new RestClientAuthenticated(`/pharmacy/search?name=${filters.name || ''}&address=${filters.address || ''}&city=${filters.city || ''}&country=${filters.country || ''}&zipCode=${filters.zipCode || ''}`, 'GET').execute(),
  searchPharmacyWithFaxNumber: (filters: Object) => new RestClientAuthenticated(`/pharmacy/search?name=${filters.name || ''}&address=${filters.address || ''}&city=${filters.city || ''}&country=${filters.country || ''}&zipCode=${filters.zipCode || ''}&needFaxNumber=${filters.needFaxNumber || ''}`, 'GET').execute(),
  addPharmacy: (pharmacyToSave: Pharmacy) => new RestClientAuthenticated('/pharmacy', 'POST').jsonBody(pharmacyToSave).execute(),
  deletePharmacy: (pharmacyId: string) => new RestClientAuthenticated(`/pharmacy/${pharmacyId}`, 'DELETE').execute(),
};
export default pharmacyApi;
