// @flow

import recordingApi from '../network/api/recordingApi';
import { notifyErrorRecording } from '../network/notification';

export function closeRecording(dispatch: Function, caseId: ?string, reason: string) {
  recordingApi
    .closeRecording(caseId, reason)
    .catch(dispatch(notifyErrorRecording));
}

export function startedNewCaseRecording(dispatch: Function, reason: string) {
  recordingApi
    .startedNewCaseRecording(reason)
    .catch(dispatch(notifyErrorRecording));
}
