
import React, { createContext, useState }  from 'react';
import {SearchCriteriaType} from '../types/InternalBeneficiaryTypes';

const InternalBeneficiaryContext = createContext({
  searchCriteriaContext: null,
  updateCriteriaSearchContext: (searchCriteria: SearchCriteriaTyp) => {}
});

export function InternalBeneficiaryContextProvider(props) {

    const [useSearchCriteria, setUseSearchCriteria] = useState(null);
   
    function updateSearchCriteriaHandler (searchCriteria: SearchCriteriaType){
      setUseSearchCriteria(searchCriteria);
    }

    const context = {
      searchCriteriaContext: useSearchCriteria,
      updateSearchCriteriaContext: updateSearchCriteriaHandler
    };
  
    return (
      <InternalBeneficiaryContext.Provider value={context}>
        {props.children}
      </InternalBeneficiaryContext.Provider>
    );
}

export default InternalBeneficiaryContext;