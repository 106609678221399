// @flow

import React from 'react';
import { Field, Form } from 'react-final-form';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { CoreozInputBase as CoreozInput, CoreozSelectBase as CoreozSelect } from '../../../lib/coreoz-form-base/index';
import PlmPanel from '../../../components/panel/PlmPanel';
import { ModificationType } from '../../../types/HistoryTypes';
import type { HistoryLog } from '../../../types/HistoryTypes';

type Props = {
  historyLogs: HistoryLog[],
  search: Function,
}

class HistorySearch extends React.PureComponent<Props> {
  render() {
    if (this.props.historyLogs === undefined) {
      return false;
    }
    return (
      <PlmPanel title={I18n.t('history.SEARCH_TITLE')}>
        <Form
          onSubmit={this.props.search}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="panel-body">
                <Row className="search-form">
                  <Col xs="5">
                    <Field
                      component={CoreozInput}
                      name="username"
                      label={I18n.t('history.USERNAME')}
                    />

                    <Field
                      component={CoreozInput}
                      name="idItem"
                      label={I18n.t('history.ID_ITEM')}
                    />

                    <Field
                      component={CoreozInput}
                      name="tableName"
                      label={I18n.t('history.TABLE')}
                    />

                  </Col>
                  <Col xs="5">

                    <Field
                      component={CoreozInput}
                      name="message"
                      label={I18n.t('history.MESSAGE')}
                    />

                    <Field
                      component={CoreozSelect}
                      name="modificationType"
                      label={I18n.t('history.MODIFICATION_TYPE.TITLE')}
                      list={[
                        { id: 'any', label: 'Tous' },
                        {
                          id: ModificationType.CREATE,
                          label: I18n.t('history.MODIFICATION_TYPE.CREATE'),
                        },
                        { id: ModificationType.UPDATE, label: I18n.t('history.MODIFICATION_TYPE.UPDATE') },
                        { id: ModificationType.DELETE, label: I18n.t('history.MODIFICATION_TYPE.DELETE') }]}
                    />

                  </Col>
                </Row>
              </div>
              <div className="panel-footer">
                <button
                  className="btn btn-info"
                  type="submit"
                >
                  <Translate value="actions.SEARCH" />
                </button>
              </div>
            </form>
          )}
        />
      </PlmPanel>
    );
  }
}

export default connect()(HistorySearch);
