
import { RestClientAuthenticated } from '../../network/network';
import { SearchResultType, SearchCriteriaType, SearchResultContentItem, BrandDetail } from '../../types/BrandManagement';
import { notifyError, notifySuccess } from '../../network/notification';
import data from './mockData/brand.json';

const apiEndpoint = {
  searchList: '/brand/search',
  getById: '/brand/detail/:id',
  update: '/brand/:id',
  save: '/brand',
  fetchList: '/brand/list'
};

const brandApi = {
  search: (searchCriteria: SearchCriteriaType): SearchResultType => (dispatch: Function) => {
    const queryString = Object.keys(searchCriteria).filter(v => searchCriteria[v] !== null).map(key => `${key }=${ searchCriteria[key]}`).join('&');

    return mockResponse(searchCriteria);

    // return new RestClientAuthenticated(`${apiEndpoint.searchList}?${queryString}`, 'GET').execute().then(response => response.text()).then(text => (text.length ? JSON.parse(text) : null))
    //   .catch(e => dispatch(notifyError)(e));
  },
  fetchById: (id: string): BrandDetail => (dispatch: Function) =>

    mockResponseById(id),
  // new RestClientAuthenticated(`${apiEndpoint.getById.replace(':id', id)}`, 'GET').execute().then(response => (response.status == 200) && response.json()).catch(e => dispatch(notifyError)(e))

  update: (data: any, id: string) => new RestClientAuthenticated(`${apiEndpoint.update.replace(':id', id)}`, 'PUT').jsonBody(data).execute(),
  save: (data: any) => new RestClientAuthenticated(`${apiEndpoint.save}`, 'POST').jsonBody(data).execute(),
  fetchList: (): FetchBrandResponse[] => new RestClientAuthenticated(`${apiEndpoint.fetchList}`, 'GET').execute().then(response => response.json()), 
};

const mockResponse = (searchCriteria: SearchCriteriaType): SearchResultType => {

  const firstPageIndex = (searchCriteria.page - 1) * searchCriteria.size;
  const lastPageIndex = firstPageIndex + searchCriteria.size;

  const resultContent: SearchResultContentItem = data.slice(firstPageIndex, lastPageIndex);

  const total = data.length;
  const perPage = Math.ceil(total / searchCriteria.size);
  return {
    content: resultContent,
    page: searchCriteria.page,
    perPage,
    total,
    total_pages: searchCriteria.size,
  };
};

const mockResponseById = (id: number): LegalEntityDetail => data.find(v => v.id === id);

export interface FetchBrandResponse {
  id: string;
  code: string;
  name: string;
  isActive: boolean;
}

export default brandApi;
