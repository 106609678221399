// @flow

import { RestClientAuthenticated } from '../network';

const medicinesApi = {
  getMedicine: (medicineName: string) => new RestClientAuthenticated(`/medicines/search?name=${medicineName}`, 'GET').execute(),
  getMedicineInnName: (medicineId: string) => new RestClientAuthenticated(`/medicines/searchInn?id=${medicineId}`, 'GET').execute(),
};

export default medicinesApi;
