// @flow
import moment from 'moment';
import Button from 'reactstrap/es/Button';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {Col, Row} from 'reactstrap';
import ModalEprm from '../../../components/modal/ModalEprm';
import PlmPanel from "../../../components/panel/PlmPanel";

type Props = {
  modal: boolean,
  toggle: Function,
  submitFunction: Function,
  visaPatientInfo: {},
};

type State = {
  preferredCountries: Array,
  loading: boolean,
};

class CreatePolicyModal extends React.Component<Props, State> {
  state = {
    loading: false,
  };

  componentDidMount(): void {
  }

  handleSubmit = () => {
    this.props.submitFunction({
      ...this.props.visaPatientInfo,
    });
  };


  render() {
    return (
      <ModalEprm
        title={I18n.t('processCase.identification.NEW_POLICY')}
        modal={this.props.modal}
        toggle={this.props.toggle}
      >
        <div className="select-patient-message mb-2">
        </div>
        <div className="title-container bg-white p-2 px-3">

          { this.props.visaPatientInfo && this.props.visaPatientInfo.patient &&
              <Row className="operation-main-row">
                <Col lg="12" md="12">
                  <PlmPanel >
                    <div className="team-availability">
                      <p><strong>{I18n.t('form.PATIENT_NAME')}</strong> : { this.props.visaPatientInfo.patient.firstName} {this.props.visaPatientInfo.patient.lastName}</p>
                    </div>
                    <div className="team-availability">
                      <p><strong>{I18n.t('form.DATE_OF_BIRTH')}</strong> : { moment(this.props.visaPatientInfo.patient.birthDate).format('DD/MM/YYYY')}</p>
                    </div>
                    <div className="team-availability">
                      <p><strong>{I18n.t('form.BIN_NUMBER')}</strong> : { this.props.visaPatientInfo.binNumber}</p>
                    </div>
                  </PlmPanel>
                </Col>
              </Row>
          }

        </div>

        <div className="select-patient-message mb-2 p-2">
          {I18n.t('processCase.identification.CONFIRM_ADDING_NEW_POLICY_MESSAGE')}<br/>
          {I18n.t('processCase.identification.CONFIRM_ADDING_NEW_POLICY_MESSAGE2', { binNumber: this.props.visaPatientInfo.binNumber })}
        </div>


        <div className="new-patient">

          <Button
            color="primary"
            className="btn-lg"
            disabled={this.state.loading}
            onClick={() => this.props.submitFunction(this.props.visaPatientInfo)}
          >
            {I18n.t('button.VALIDATE')}
          </Button>
        </div>
        <div className="select-patient-buttons justify-content-center">
          <Button
            color="secondary"
            className="btn-sm"
            onClick={this.props.toggle}
          >
            <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
          </Button>

        </div>
      </ModalEprm>
    );
  }
}

export default withRouter(connect()(CreatePolicyModal));
