// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import ModalEprm from '../../../modal/ModalEprm';
import MapContainer from './MapContainer';
import SideContent from './SideContent';
import SendButtons from './SendButtons';
import ConfirmationModal from './ConfirmationModal';
import hospitalApi from '../../../../network/api/hospitalApi';
import { notifyError, notifySuccess } from '../../../../network/notification';
import { connect } from 'react-redux';
import { SendType } from '../../../../enum';

type Props = {
  closedSelf: Function,
  apiKey: string,
  caseId: string,
  patientId: string,
}

type States = {
  selectedHospitalId: string,
  isMailModalOpen: boolean,
  isSmsModalOpen: boolean,
  input: string,
  places: TypePlaces[],
  selection: TypePlaces[],
  loading: boolean,
  center: Object,
}

class HospitalSearchPopin extends React.Component<Props, States> {
  state = {
    isMailModalOpen: false,
    isSmsModalOpen: false,
    selectedHospitalId: null,
    input: '',
    places: [],
    selection: [],
    loading: false,
    center: {
      lat: 48.8534100,
      lng: 2.3488000,
    },
    defaultReportValue: false,
  }

  componentDidMount() {
    hospitalApi
      .getCaseReport(this.props.caseId)
      .then(res => res.text())
      .then((boolString) => this.setState({ defaultReportValue: boolString === 'true' }))
      .catch(e => console.log('Erreur apppel API', e));
  }

  searchPlaces = (e) => {
    e.preventDefault();
    this.setState({ loading: true })
    hospitalApi.getPlaces(this.state.input, this.props.caseId)
      .then(response => response.json())
      .then(data => {
        this.setState({
          places: data,
          loading: false,
          center: data.length ? { lat: data[0].latitude, lng: data[0].longitude } : this.state.center
        })
      })
      .catch((e) => {
        notifyError(this.props.dispatch)(e);
        this.setState({ loading: false });
      });
  }

  selectHospital = (place: TypePlace) => {
    if (place) {
      this.setState(prevState => ({
        selectedHospitalId: place.id,
        center: { lat: place.latitude, lng: place.longitude },
      }));
    } else {
      this.setState(prevState => ({
        selectedHospitalId: null,
      }));
    }
  }

  multiSelect = (place: TypePlace) => {
    const { selection } = this.state;
    const existing = selection.find(s => s.id === place.id);
    if (existing) {
      this.setState({
        selection: selection.filter(s => s.id !== place.id),
      });
    } else {
      this.setState(prevState => ({
        selection: [...prevState.selection, place],
      }));
    }
  }

  toggleMailModal = (isMailModalOpen: boolean) => {
    this.setState({ isMailModalOpen, isSmsModalOpen: false });
  }
  toggleSmslModal = (isSmsModalOpen: boolean) => {
    this.setState({ isSmsModalOpen, isMailModalOpen: false });
  }

  updateCaseReport = (boolean) => {
    hospitalApi
      .updateCaseReport(this.props.caseId, boolean)
      .then(notifySuccess(this.props.dispatch))
      .catch(e => console.log('Erreur apppel API', e));
  }

  toggleAll = () => {
    this.setState({ isSmsModalOpen: false, isMailModalOpen: false });
  }

  sendData = (type: string) => {
    const { selection } = this.state;
    const body = {
      caseId: this.props.caseId,
      patientId: this.props.patientId,
      places: selection,
    };

    if (type === SendType.MAIL) {
      hospitalApi.sendMail(body)
        .then(r => this.toggleAll())
        .then(() => this.props.dispatch(notifySuccess(this.props.dispatch, null, I18n.t('notify.success.SEND_EMAIL'))))
        .catch(e => console.log('Erreur apppel API', e));
    } else {
      hospitalApi.sendSms(body)
        .then(r => this.toggleAll())
        .then(() => this.props.dispatch(notifySuccess(this.props.dispatch, null, I18n.t('notify.success.SEND_SMS'))))
        .catch(e => console.log('Erreur apppel API', e));
    }
  }

  render() {
    const { apiKey } = this.props;
    const { places, selectedHospitalId, selection } = this.state;
    const selectedHospital = places.find(place => place.id === selectedHospitalId);
    return (
      <ModalEprm
        title={I18n.t('contact.hospitalSearch.TITLE')}
        modal
        toggle={this.props.closedSelf}
        className="hospital-search-popin"
        size="lg"
      >
        {this.state.isMailModalOpen &&
        <ConfirmationModal
          toggle={this.toggleMailModal}
          title={I18n.t('contact.hospitalSearch.mailConfirmationTitle')}
          content={I18n.t('contact.hospitalSearch.mailConfirmationContent')}
          action={() => this.sendData(SendType.MAIL)}
        />
        }
        {this.state.isSmsModalOpen &&
        <ConfirmationModal
          toggle={this.toggleSmslModal}
          title={I18n.t('contact.hospitalSearch.smsConfirmationTitle')}
          content={I18n.t('contact.hospitalSearch.smsConfirmationContent')}
          action={() => this.sendData(SendType.SMS)}
        />
        }

        <div className="search-hospital">
          <form onSubmit={this.searchPlaces}>
            <input name="input" onChange={event => this.setState({ input: event.target.value })} />
            <Button
              type="submit"
              color="primary"
              className="search-button"
            >
              {I18n.t('contact.hospitalSearch.SEARCH')}
            </Button>
          </form>
        </div>
        <div>
          <MapContainer
            places={places}
            center={this.state.center}
            apiKey={apiKey}
            selectHospital={this.selectHospital}
            selectedHospital={selectedHospital}
          />
          <SideContent
            selectHospital={this.selectHospital}
            multiSelect={this.multiSelect}
            selectedHospital={selectedHospital}
            places={places}
            loading={this.state.loading}
          />
          <SendButtons
            disabled={!selectedHospitalId && selection.length === 0}
            onMailPress={() => this.toggleMailModal(true)}
            onSmsPress={() => this.toggleSmslModal(true)}
            onCaseReport={this.updateCaseReport}
            defaultReportValue={this.state.defaultReportValue}
          />
        </div>

      </ModalEprm>
    );
  }
}

export default withRouter(connect()(HospitalSearchPopin));
