// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Button from 'reactstrap/es/Button';
import { CoreozInputBase as CoreozInput } from '../../../lib/coreoz-form-base/index';
import RichText from './RichText';
import ModalEprm from '../../modal/ModalEprm';
import type { Email } from '../../../types/NotificationTypes';
import { notifyError } from '../../../network/notification';
import patientApi from '../../../network/api/patientApi';
import { connect } from 'react-redux';
import {validateEmail, validateEmailFormat} from '../../../validator';

type Props = {
  dispatch: Function,
  sendEmail: Function,
  closedSelf: Function,
  modal: boolean,
  patientId: string,
}

type States = {
  contactMeans: Object,
}

class EmailPopin extends React.Component<Props, States> {
  state = {
    contactMeans: {},
  };

  componentDidMount(): void {
    patientApi.getContactMeans(this.props.patientId)
      .then(response => response.json())
      .then((contactMeans) => {
        this.setState({ contactMeans });
      })
      .catch(this.props.dispatch(notifyError));
  }

  handleSubmit = (data) => {
    const message: Email = {
      emailReceiver: data.email,
      emailCc: data.emailCc,
      message: data.message,
      subject: data.subject,
    };
    this.props.sendEmail(message);

    this.props.closedSelf();
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('contact.email.TITLE')}
        modal={this.props.modal}
        toggle={this.props.closedSelf}
        className="contact-popin"
        size="lg"
      >
        <Form
          onSubmit={this.handleSubmit}
          initialValues={{ email: !!this.state.contactMeans ? this.state.contactMeans.email : '' }}
          render={({ handleSubmit, form, values, invalid }) => (
            <form onSubmit={handleSubmit} className="body">
              <div className="code-and-phone-number">
              </div>
              <Field
                label={I18n.t('contact.email.EMAIL')}
                component={CoreozInput}
                type="email"
                name="email"
                disabled
              />
              <Field
                label={I18n.t('contact.email.EMAIL_CC')}
                component={CoreozInput}
                type="emailCc"
                name="emailCc"
                validate={validateEmailFormat}
              />
              <Field
                label={I18n.t('contact.email.OBJECT')}
                component={CoreozInput}
                type="subject"
                name="subject"
              />
              <Field
                component={RichText}
                minrows={5}
                maxrows={30}
                name="message"
              />
              <div className="footer">
                <Button
                  type="submit"
                  color="primary"
                  className="validation-button"
                  disabled={invalid}
                >
                  <i className="fal fa-arrow-circle-right" />
                  {I18n.t('contact.email.SEND')}
                </Button>
              </div>
            </form>)}
        />
      </ModalEprm>
    );
  }
}

export default withRouter(connect()(EmailPopin));
