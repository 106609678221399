'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

require('./index.css');

var _CoreozTooltip = require('../tooltip/CoreozTooltip');

var _CoreozTooltip2 = _interopRequireDefault(_CoreozTooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CoreozInput = function (_React$Component) {
  _inherits(CoreozInput, _React$Component);

  function CoreozInput() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CoreozInput);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CoreozInput.__proto__ || Object.getPrototypeOf(CoreozInput)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      hidePassword: _this.props.input.type === 'password',
      isFocused: false
    }, _this.toggleInputType = function () {
      _this.setState(function (prevState) {
        return { hidePassword: !prevState.hidePassword };
      });
    }, _this.getType = function () {
      if (_this.props.input.type === 'password') {
        return _this.state.hidePassword ? 'password' : 'text';
      }
      return _this.props.input.type;
    }, _this.manageFocus = function (isFocused) {
      return _this.setState({ isFocused: isFocused });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CoreozInput, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          label = _props.label,
          input = _props.input,
          placeholder = _props.placeholder,
          disabled = _props.disabled,
          required = _props.required,
          tip = _props.tip,
          theme = _props.theme,
          onChange = _props.onChange,
          onBlur = _props.onBlur,
          onFocus = _props.onFocus,
          className = _props.className,
          rest = _objectWithoutProperties(_props, ['label', 'input', 'placeholder', 'disabled', 'required', 'tip', 'theme', 'onChange', 'onBlur', 'onFocus', 'className']);

      var _state = this.state,
          hidePassword = _state.hidePassword,
          isFocused = _state.isFocused;

      var hasError = this.props.meta.touched && this.props.meta.error;
      var isEmpty = !input.value && !isFocused;
      var inputClassName = theme + '-input-coreoz ' + (hasError ? theme + '-input-coreoz--error' : '');
      return _react2.default.createElement(
        'div',
        { className: theme + '-container-input-coreoz ' + (className || '') },
        label && _react2.default.createElement(
          'div',
          {
            className: theme + '-label-input-coreoz ' + (isEmpty ? theme + '-label-input-coreoz--empty' : '') + ' ' + (required ? theme + '-label-input-coreoz--required' : '') + ' ' + (hasError ? theme + '-label-input-coreoz--error' : '') },
          label,
          !!tip && _react2.default.createElement(_CoreozTooltip2.default, { message: tip, disabled: disabled, theme: theme })
        ),
        input.type === 'textarea' && _react2.default.createElement('textarea', _extends({}, input, rest, {
          disabled: disabled,
          onFocus: function (_onFocus) {
            function onFocus(_x) {
              return _onFocus.apply(this, arguments);
            }

            onFocus.toString = function () {
              return _onFocus.toString();
            };

            return onFocus;
          }(function (e) {
            _this2.manageFocus(true);
            input.onFocus(e);
            if (onFocus) onFocus(e);
          }),
          onBlur: function (_onBlur) {
            function onBlur(_x2) {
              return _onBlur.apply(this, arguments);
            }

            onBlur.toString = function () {
              return _onBlur.toString();
            };

            return onBlur;
          }(function (e) {
            _this2.manageFocus(false);
            input.onBlur(e);
            if (onBlur) onBlur(e);
          }),
          placeholder: placeholder,
          className: inputClassName
        })),
        input.type !== 'textarea' && _react2.default.createElement(
          _react2.default.Fragment,
          null,
          _react2.default.createElement('input', _extends({}, input, rest, {
            type: this.getType(),
            disabled: disabled,
            onFocus: function (_onFocus2) {
              function onFocus(_x3) {
                return _onFocus2.apply(this, arguments);
              }

              onFocus.toString = function () {
                return _onFocus2.toString();
              };

              return onFocus;
            }(function (e) {
              _this2.manageFocus(true);
              input.onFocus(e);
              if (onFocus) onFocus(e);
            }),
            onBlur: function (_onBlur2) {
              function onBlur(_x4) {
                return _onBlur2.apply(this, arguments);
              }

              onBlur.toString = function () {
                return _onBlur2.toString();
              };

              return onBlur;
            }(function (e) {
              _this2.manageFocus(false);
              input.onBlur(e);
              if (onBlur) onBlur(e);
            }),
            placeholder: placeholder,
            className: inputClassName,
            onChange: function (_onChange) {
              function onChange(_x5) {
                return _onChange.apply(this, arguments);
              }

              onChange.toString = function () {
                return _onChange.toString();
              };

              return onChange;
            }(function (e) {
              input.onChange(e.target.value);
              if (onChange) onChange(e.target.value);
            })
          })),
          input.type === 'password' && _react2.default.createElement('i', {
            onClick: this.toggleInputType,
            className: theme + '-icon-input ' + (hidePassword ? 'fa fa-eye-slash' : 'fa fa-eye')
          })
        ),
        hasError && _react2.default.createElement(
          'div',
          { className: theme + '-error-form error-form--field' },
          this.props.meta.error
        )
      );
    }
  }]);

  return CoreozInput;
}(_react2.default.Component);

CoreozInput.defaultProps = {
  label: '',
  placeholder: '',
  disabled: false,
  required: false,
  tip: '',
  className: '',
  theme: 'base'
};
exports.default = CoreozInput;