// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ContactMean from './components/ContactMean';
import { notifyError, notifyLot4, notifySuccess } from '../../network/notification';
import resourcesApi from '../../network/api/resourcesApi';
import notificationApi from '../../network/api/notificationApi';
import SmsPopin from './components/SmsPopin';
import caseApi from '../../network/api/caseApi';
import { CommunicationStatus } from '../../enum';
import MedicalResourcesButton from './components/MedicalResourcesButton';
import EmailPopin from './components/EmailPopin';
import type { Email } from '../../types/NotificationTypes';
import { currentSessionService } from '../../services/sessionServiceInstance';
import VideoConfirmationMail from './components/VideoConfirmationMail';
import HospitalSearchPopin from './components/hospitalSearch/HospitalSearchPopin';
import confApi from '../../network/api/confApi';
import CallbackPopin from './components/CallbackPopin';

type Props = {
  caseInfos: Object,
  patientId: string,
  dispatch: Function,
  caseId: string,
  drawerToggleClickHandle: Function,
}

type State = {
  showPhoneCallback: boolean,
  medicalResources: boolean,
  showSearchHospitalPopin: boolean,
  showSmsPopin: boolean,
  showEmailPopin: boolean,
  resources: any,
  liveStatus: Object,
  sendEmailConfirmation: boolean,
  apiKey: string
}

class ContactSideBar extends React.Component<Props, State> {
  state = {
    showSearchHospitalPopin: false,
    showSmsPopin: false,
    showPhoneCallback: false,
    showEmailPopin: false,
    medicalResources: false,
    sendEmailConfirmation: false,
    apiKey: false,
    resources: {},
    liveStatus: {},
  };

  interval: IntervalID;

  fetchLiveStatus = () => {
    if (this.props.caseId) {
      caseApi.getLiveStatus(this.props.caseId)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : {}))
        .then(liveStatus => this.setState({ liveStatus }))
        .catch(this.props.dispatch(notifyError));
    }
  };

  componentDidMount() {
    this.fetchLiveStatus();
    this.interval = setInterval(this.fetchLiveStatus, 10000);

    confApi.fetchGoogleApiKey()
      .then(response => response.text())
      .then((apiKey) => {
        this.setState({ apiKey });
      })
      .catch(this.props.dispatch(notifyError));
    resourcesApi.getResources()
      .then(response => response.json())
      .then(resources => this.setState({ resources }))
      .catch(this.props.dispatch(notifyError));
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
  }

  startChat = () => {
    if (this.state.liveStatus.chatSessionUrl) {
      window.open(this.state.liveStatus.chatSessionUrl, 'PDA Chat', 'menubar=no, status=no, scrollbars=no, menubar=no, width=600, height=600');
    }
  };

  openVideoWindow = () => {
    window.open(`${this.state.liveStatus.videoSessionUrl}?showCloseCall=true&lang=${currentSessionService().currentUser().businessUnitCountryCode}&userName=${currentSessionService().currentUser().userRole.toLowerCase()}`, 'Allianz Video', 'menubar=no, status=no, scrollbars=no, menubar=no, width=600, height=600');
    window.onmessage = (e) => {
      const message = e.data;
      if (message.name === 'PEER_WEBSOCKET_DISCONNECTED') {
        caseApi
          .setLiveStatusToPatientOut(this.props.caseId)
          .catch(this.props.dispatch(notifyError));
      }
    };
  }

  startVideo = () => {
    if (currentSessionService().currentUser().canResendMailVideo) {
      this.setState({
        sendEmailConfirmation: true,
      });
    } else {
      if (this.state.liveStatus.videoSessionUrl) {
        if (CommunicationStatus.WAITING === this.state.liveStatus.videoStatus || CommunicationStatus.IN_PROGRESS === this.state.liveStatus.videoStatus) {
          this.openVideoWindow();
        }
      } else {
        this.setState({
          sendEmailConfirmation: true,
        });
      }
    }

  };

  getColor = (liveStatus) => {
    if (CommunicationStatus.IN_PROGRESS === liveStatus) {
      return 'in-progress';
    }

    if (CommunicationStatus.WAITING === liveStatus || CommunicationStatus.DROPPED === liveStatus) {
      return 'waiting';
    }

    return '';
  };

  sendSms = (message) => {
    notificationApi.sendSms(this.props.patientId, this.props.caseId, { message })
      .then(() => this.props.dispatch(notifySuccess(this.props.dispatch, null, I18n.t('notify.success.SEND_SMS'))))
      .catch(this.props.dispatch(notifyError));
  };

  sendEmail = (message: Email) => {
    notificationApi.sendEmail(this.props.patientId, this.props.caseId, message)
      .then(() => this.props.dispatch(notifySuccess(this.props.dispatch, null, I18n.t('notify.success.SEND_EMAIL'))))
      .catch(this.props.dispatch(notifyError));
  };

  closeAllModal = () => {
    this.setState({
      showSearchHospitalPopin: false,
      showPhoneCallback: false,
      medicalResources: false,
      showEmailPopin: false,
      showSmsPopin: false,
    });
  };

  toggleMedicalResources = () => {
    this.setState({ medicalResources: !this.state.medicalResources });
  };
  toggleHospitalSearch = () => {
    this.setState({ showSearchHospitalPopin: !this.state.showSearchHospitalPopin });
  };

  render() {
    return (
      <div className="contact">
        <aside>
          <div className="contact-block start">
            {this.state.showPhoneCallback &&
            <SmsPopin
              phoneNumbre={this.props.caseInfos.patient.mobilePhone}
              closedSelf={() => {
                this.setState({ showPhoneCallback: !this.state.showPhoneCallback });
              }}
              displaySendSmsForm={true}
            />
            }
            <div className="contact-mean-title">
              <h2>{I18n.t('contact.start.TITLE')}</h2>
            </div>
            <ContactMean
              icon="fa-video"
              label={I18n.t('contact.start.VIDEO')}
              action={this.startVideo}
              blink={CommunicationStatus.WAITING === this.state.liveStatus.videoStatus}
              color={this.getColor(this.state.liveStatus.videoStatus)}
            />
            {currentSessionService().currentUser().showChat &&
            <ContactMean
              icon="fa-comments"
              label={I18n.t('contact.start.CHAT')}
              action={this.startChat}
              blink={CommunicationStatus.WAITING === this.state.liveStatus.chatStatus}
              color={this.getColor(this.state.liveStatus.chatStatus)}
            />
            }
            <ContactMean
              icon="fa-phone"
              highlight={this.state.showPhoneCallback}
              label={I18n.t('contact.start.PHONE')}
              action={() => {
                this.closeAllModal();
                this.setState({ showPhoneCallback: !this.state.showPhoneCallback });
              }}
            />
          </div>

          <div className="contact-block send">
            <div className="contact-mean-title">
              <h2>{I18n.t('contact.send.TITLE')}</h2>
            </div>
            <ContactMean
              icon="fa-at"
              label={I18n.t('contact.send.EMAIL')}
              action={() => {
                this.closeAllModal();
                this.setState({ showEmailPopin: !this.state.showEmailPopin });
              }}
            />
            <ContactMean
              icon="fa-comment-alt-lines"
              label={I18n.t('contact.send.SMS')}
              highlight={this.state.showSmsPopin}
              action={() => {
                this.closeAllModal();
                this.setState({ showSmsPopin: !this.state.showSmsPopin });
              }}
            />
          </div>
          <MedicalResourcesButton
            closeAllModal={this.closeAllModal}
            medicalResources={this.state.medicalResources}
            toggleMedicalResources={this.toggleMedicalResources}
            toggleHospitalSearch={this.toggleHospitalSearch}
          />

          <div className="contact-block">
            <div
              className="medical-profile-button resources right-bar-block contact-mean ressources"
              onClick={this.props.drawerToggleClickHandle}
            >
              <i className="fa fa-heartbeat" />
              <span className="block-title">Medical Profile</span>
            </div>
          </div>
        </aside>
        {this.state.sendEmailConfirmation &&
        <VideoConfirmationMail
          caseId={this.props.caseId}
          openVideoWindow={this.openVideoWindow}
          sessionReady={!!this.state.liveStatus.videoSessionUrl}
          closedSelf={() => this.setState({ sendEmailConfirmation: !this.state.sendEmailConfirmation })}
        />}
        {this.state.showSearchHospitalPopin &&
        <HospitalSearchPopin
          apiKey={this.state.apiKey}
          patientId={this.props.patientId}
          caseId={this.props.caseId}
          closedSelf={() => {
            this.setState({ showSearchHospitalPopin: !this.state.showSearchHospitalPopin });
          }}
        />
        }
        {this.state.showSmsPopin &&
        <SmsPopin
          phoneNumbre={this.props.caseInfos.patient.mobilePhone}
          sendSms={this.sendSms}
          closedSelf={() => {
            this.setState({ showSmsPopin: !this.state.showSmsPopin });
          }}
        />
        }
        {this.state.showEmailPopin &&
        <EmailPopin
          sendEmail={this.sendEmail}
          closedSelf={() => {
            this.setState({ showEmailPopin: !this.state.showEmailPopin });
          }}
          modal={this.state.showEmailPopin}
          patientId={this.props.patientId}
        />
        }
        {this.state.showPhoneCallback &&
        <CallbackPopin
          callbackPhoneNumber={this.props.caseInfos.caseInfos.callbackPhoneNumber}
          mobilePhone={this.props.caseInfos.patient.mobilePhone}
          closedSelf={() => {
            this.setState({ showPhoneCallback: !this.state.showPhoneCallback });
          }}
        />
        }
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(ContactSideBar));
