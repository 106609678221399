import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import GreenMarker from '../../../../img/marker_green.png';
import RedMarker from '../../../../img/marker_red.png';

const mapStyles = {
  width: '65%',
  height: '690px',
};

function MapContainer(props) {
  return (
    <Map
      google={props.google}
      zoom={14}
      initialCenter={props.center}
      center={props.center}
      style={mapStyles}
    >
      {props.places.map(place => (
        <Marker
          key={place.id}
          text={place.name}
          position={{ lat: place.latitude, lng: place.longitude }}
          onClick={() => {
            props.selectHospital(place);
          }}
          icon={{
            url: place.color === 'red' ? RedMarker : GreenMarker,
          }}
        />
      ))}
    </Map>
  );
}

export default GoogleApiWrapper(props => (
  { apiKey: props.apiKey }
))(MapContainer);
