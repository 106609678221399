// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { Field } from 'react-final-form';
import moment from 'moment';
import ResizableTextarea from '../../components/ResizableTextarea';
import { currentSessionService } from '../../services/sessionServiceInstance';

type Props = {
  medReport: Object,
  showLogoInDocument: boolean,
  showAddressInDocument: boolean,
  showHospitals: boolean,
  addressedToPatient: boolean,
  showNonMedicationNotesFirst: boolean,
  actualCase: Object,
  patient: Object,
  patientPresentation: string,
  previewMode: boolean,
  teleconsultNote: string,
  showEmailServiceMedicalReport: boolean,
};

class MedicalReportPreview extends React.PureComponent<Props> {
  render() {
    return (
      <>
        <div className="medical-report-preview">
          {this.props.previewMode && this.props.showEmailServiceMedicalReport &&
          <div className="footer document-footer">
            <div>
              <span className="important-text">{I18n.t('editDocument.EMAIL')} : {' '}</span>
              <a
                href={`mailto:${ this.props.medReport.businessUnitEmail}`}
                className="text text-with-link"
              >
                {this.props.medReport.businessUnitEmail}
              </a>
            </div>
            <div className="important-text">{I18n.t('editDocument.OPEN_HOURS')}</div>
          </div>
          }
          <div className="pdf-header">
            <div className="document-header">
              {this.props.showLogoInDocument &&
              <img src={this.props.medReport.logo} alt={this.props.medReport.brandName} />
              }
              <div className="document-doctor-info">
                <div className="important-text">
                  {I18n.t('editDocument.DOCTOR')}
                  <span className="text">
                    {this.props.medReport.doctorName.toUpperCase()}
                  </span>
                </div>
                {/* <div className="important-text">{this.props.medReport.doctorSpeciality.toUpperCase()}</div> */}
                <div className="important-text">
                  <span className="important-text">
                    {I18n.t('editDocument.ID_NUMBER')}
                  </span>
                  {this.props.medReport.doctorRpps}
                </div>
              </div>
            </div>
            <div className="document-address">
              {this.props.showAddressInDocument &&
              <Fragment>
                <div className="important-text">{I18n.t('editDocument.ADDRESS')} :</div>
                <div className="text-small">{I18n.t('editDocument.ORGANIZATION')}
                  <span className="important-text-small">{this.props.medReport.legalEntityName}</span>
                </div>
                <div className="text-small">{I18n.t('editDocument.UNDER_NAME')}
                  <span className="important-text-small">
                    {` "${this.props.medReport.brandName}"`}
                  </span>
                </div>
                <div className="text-small">{this.props.medReport.businessUnitAddress}</div>
                <div className="bloc-text text-small">{I18n.t('editDocument.IMPLEMENTED_BY')} :
                  <span className="important-text-small"> {this.props.medReport.businessUnitName}</span>
                </div>
              </Fragment>
              }
              {this.props.showEmailServiceMedicalReport &&
              <div>
                <span className="important-text">{I18n.t('editDocument.EMAIL')} : </span>
                <a href={`mailto:${this.props.medReport.businessUnitEmail}`} className="text-small text-with-link">
                  {this.props.medReport.businessUnitEmail}
                </a>
              </div>
              }
              {this.props.showEmailServiceMedicalReport &&
              <div className="important-text">{I18n.t('editDocument.OPEN_HOURS')}</div>
              }
            </div>
            {this.props.showPlaceInDocument ?
              <div className="document-date text">
                {`${this.props.medReport.place}, ${I18n.t('editDocument.THE')} ${moment().format(I18n.t('date.DATE_FORMAT'))}`}
              </div>
              :
              <div className="document-date text">
                {`${I18n.t('editDocument.DATE')}: ${moment().format(I18n.t('date.DATE_FORMAT'))}`}
              </div>
            }
            {!this.props.addressedToPatient ?
              <Fragment>
                <div>
                  <div className="important-text">{I18n.t('editDocument.ATTENTION_OF')} </div>
                  <div className="text">
                    {I18n.t('editDocument.PATIENT')} :
                    {this.props.patient.sex === 1 ? I18n.t('editDocument.SIR') : I18n.t('editDocument.MADAM')}
                    <span className="text">
                      {this.props.actualCase.patientName.toUpperCase()}
                    </span> {I18n.t('editDocument.LEGAL_REPRESENTATIVE')}
                  </div>
                  {this.props.actualCase.isFamilyDocConsent && (this.props.actualCase.familyDoctorFirstname|| this.props.actualCase.familyDoctorLastname) &&
                      <Fragment>
                        <div className="text">{I18n.t('editDocument.DOCTOR')} :
                          <span
                            className="text"
                          >
                            {this.props.actualCase.familyDoctorFirstname && this.props.actualCase.familyDoctorFirstname.toUpperCase()} {this.props.actualCase.familyDoctorLastname && this.props.actualCase.familyDoctorLastname.toUpperCase()}
                          </span>, {I18n.t('editDocument.FAMILY_DOCTOR')}
                        </div>
                        <div className="text">
                          {`${I18n.t('editDocument.FAMILY_DOCTOR_ADDRESS')}
                      ${this.props.actualCase.familyDoctorAddressLine || ''},
                      ${this.props.actualCase.familyDoctorAddressPostalcode || ''} ${this.props.actualCase.familyDoctorAddressCity || ''},
                      ${this.props.actualCase.familyDoctorAddressCountry || ''}.`}
                        </div>
                      </Fragment>
                  }
                </div>
                <br />
                {this.props.previewMode &&
                <div className="numbering">
                  <span className="important-text">
                    {I18n.t('editDocument.TELECONSULTATION_REPORT_NUMBERING')} (<span className="pagenumber" /> / <span
                      className="pagecount"
                    />)
                  </span>
                </div>}
              </Fragment>
              :
              <Fragment>
                {this.props.previewMode &&
                <div className="numbering">
                  <span className="important-text">
                    {I18n.t('editDocument.TELECONSULTATION_REPORT_NUMBERING')} (<span
                      className="pagenumber"
                    /> / <span className="pagecount" />)
                  </span>
                </div>
                }
                <br />
                <div>
                  <div className="important-text">
                    {I18n.t('editDocument.PATIENT')} : <span
                      className="text"
                    > {this.props.actualCase.patientName.toUpperCase()}
                                                       </span>
                  </div>
                  <div className="important-text">
                    {I18n.t('editDocument.BIRTH_DATE')}
                    <span className="text">
                      {moment(this.props.patient.birthday).format(I18n.t('date.DATE_FORMAT'))}
                    </span>
                  </div>
                </div>
              </Fragment>
            }
          </div>
          <div className="numbering-pdf-header">
            { this.props.previewMode &&
            <div className="numbering">
              <span className="important-text">
                {I18n.t('editDocument.TELECONSULTATION_REPORT_NUMBERING')} (<span
                  className="pagenumber"
                /> / <span className="pagecount" />)
              </span>
            </div>
            }
          </div>
          <div className="report-content">
            {this.props.addressedToPatient ?
              <div className="document-patient-info">
                <div className="bloc-text text">{I18n.t('editDocument.DEAR_PATIENT')},</div>
                <div className="bloc-text text">{I18n.t('editDocument.THANKS_FOR_THE_USE', {
                  callDay: moment(this.props.actualCase.dateCreation, 'YYYY-MM-DD').format(I18n.t('date.DATE_FORMAT')),
                  callHour: moment(this.props.actualCase.dateCreation).format(I18n.t('date.DATE_HOUR_SHORT')),
                })}
                </div>
                <div className="text">{I18n.t('editDocument.SUMMARY_CALL')}</div>
              </div>
              :
              <div className="document-patient-info">
                <div className="bloc-text text">{I18n.t('editDocument.DEAR_COLLEAGUE')},</div>
                <span className="text">{this.props.patientPresentation} </span>
                <div className="text">{I18n.t('editDocument.SUMMARY_CALL')}</div>
                <div className="text">{I18n.t('editDocument.TO_BE_FOLLOWED')}</div>
              </div>
            }
            <div className="profil-medical">
              <br />
              <div className="important-text bloc-text">
                {`1/ ${I18n.t('editDocument.medicalProfile.TITLE')}`}
              </div>
              <div
                className="profil-medical-info bloc-text text"
              >{I18n.t('editDocument.medicalProfile.MEDICAL_HISTORY')} :
                <br />
                {this.props.patient.medicalHistory}
              </div>
              <div
                className="profil-medical-info bloc-text text"
              >{I18n.t('editDocument.medicalProfile.CURRENT_TREATMENT')} :
                <br />
                {this.props.patient.treatment}
              </div>
              <div className="profil-medical-info bloc-text text">{I18n.t('editDocument.medicalProfile.ALLERGIES')} :
                <br />
                {this.props.patient.allergies}
              </div>
              <div
                className="profil-medical-info bloc-text text"
              >{I18n.t('editDocument.medicalProfile.VACCINATION_STATUS')} :
                <br />
                {this.props.patient.vaccination}
              </div>
              <div className="profil-medical-info bloc-text text">{I18n.t('editDocument.medicalProfile.LIFESTYLE')} :
                <br />
                {this.props.patient.lifeStyle}
              </div>
            </div>
            <div className="teleconsultation-notes">
              <br />
              <div className="bloc-text important-text">
                {`2/ ${I18n.t('editDocument.DIALOG_SUMMARY')}`}
              </div>
              {
                this.props.previewMode ?
                  <div className="adviceNotes text">
                    {this.props.teleconsultNote}
                  </div>
                  :
                  <Field
                    component={ResizableTextarea}
                    name="teleconsultNote"
                    minrows={10}
                    maxrows={25}
                  />
              }
            </div>
            {this.props.medReport.prescriptions && this.props.medReport.prescriptions.length > 0 &&
            <div className="prescriptions bloc-text">
              <br />
              {this.props.medReport.prescriptions && this.props.medReport.prescriptions
                .map((prescription, indexPrescription) => (
                  <div className="prescription" key={`${prescription}.${indexPrescription}`}>
                    <div
                      className="bloc-text text"
                    >{I18n.t('editDocument.PRESCRIPTION_GIVEN', { title: prescription.title })}:
                    </div>
                    {this.props.showNonMedicationNotesFirst ?
                      <Fragment>
                        <div className="bloc-text additional-notes text">{prescription.additionalNote}</div>
                        { prescription.medicines && prescription.medicines.map((medicine, indexMedicine) => (
                          <div className="medicine-info" key={medicine.name}>
                            <div
                              className="medicine-name important-text"
                            >{`${indexMedicine + 1}/ ${medicine.name}`}
                            </div>
                            {medicine.innName &&
                            <div className="medicine-INN-name important-text">{`(${medicine.innName} )`}</div>}
                            <div className="medicine-notes text">{medicine.instructions}</div>
                          </div>
                        ))}
                      </Fragment>
                      :
                      <Fragment>
                        {prescription.medicines && prescription.medicines.map((medicine, indexMedicine) => (
                          <div className="medicine-info" key={medicine.name}>
                            <div
                              className="medicine-name important-text"
                            >{`${indexMedicine + 1}/ ${medicine.name}`}
                            </div>
                            {medicine.innName &&
                            <div className="medicine-INN-name important-text">{`(${medicine.innName} )`}</div>}
                            <br />
                            <div className="medicine-notes text">{medicine.instructions}</div>
                          </div>
                        ))}
                        <div className="bloc-text additional-notes text">{prescription.additionalNote}</div>
                      </Fragment>
                    }
                  </div>
                ))}
            </div>
            }
            <div className="teleconsultation-hospitals">
              {
                !!this.props.hospitals.length &&
                <div>
                  <div>{I18n.t('editDocument.HOSPITALS_GIVEN')}</div>
                  <ul>
                    {
                      this.props.hospitals.map(hospital => <li>{hospital}</li>)
                    }
                  </ul>
                </div>
              }
            </div>
            {this.props.actualCase.medicalExam === 'true' && !this.props.medReport.providerNetworkDetails &&
            <div className="orientation bloc-text text">
              {I18n.t('editDocument.ORIENTATION_TO_DOCTOR')}
            </div>
            }
            {this.props.actualCase.medicalExam === 'true' && this.props.medReport.providerNetworkDetails &&
            <div className="protocol-info bloc-text text">
              {this.props.medReport.providerNetworkDetails}
            </div>
            }
            {this.props.actualCase.medicalExam === 'true' && this.props.actualCase.medicalExamInstructions &&
            <div className="medical-instruction bloc-text text">
              {this.props.actualCase.medicalExamInstructions}
            </div>
            }
            {this.props.actualCase.emergencyDetected &&
            <div className="emergency bloc-text text">
              {I18n.t('editDocument.EMERGENCY_DETECTED')}
              {(this.props.actualCase.awareEmergencyServices === '1' && currentSessionService().currentUser().businessUnitCountryCode === 'fr')
              || (this.props.actualCase.awareEmergencyServices === '3' && currentSessionService().currentUser().businessUnitCountryCode === 'es') ?
                // 1 = france, 2 = abroad, 3 = spain
                <div className="emergency-direction text">
                  {I18n.t('editDocument.EMERGENCY_CONTACTED')}
                </div>
                :
                <div className="emergency-direction text">
                  {I18n.t('editDocument.PATIENT_REDIRECTED')}
                </div>
              }
            </div>
            }
            <div className="technical-problem bloc-text text">
              {I18n.t('editDocument.TECHNICAL_ISSUE')}: {this.props.actualCase.isTechnicalIssue ?
                <Fragment>
                  <span className="text">{I18n.t('form.YES').toUpperCase()}</span>
                  <div
                    className="detail text"
                  >{I18n.t('editDocument.DETAILS')}: {this.props.actualCase.technicalIssueText}
                  </div>
                </Fragment>
              :
                <span className="text">{I18n.t('form.NO').toUpperCase()}</span>
            }
            </div>
          </div>
          {!this.props.previewMode && <div className="footer">
            <div className="bloc-text text">{I18n.t('editDocument.CONTACT')}
            </div>
            <div className="document-signature">
              <div
                className="bloc-text important-text"
              >{I18n.t('editDocument.DOCTOR')} <span
                className="text"
              >{this.props.medReport.doctorName.toUpperCase()}
              </span>
              </div>
              <img src={this.props.medReport.doctorSignature} className="doctor-signature" />
            </div>
            {this.props.showEmailServiceMedicalReport &&
            <div>
              <div>
                <span className="important-text">{I18n.t('editDocument.EMAIL')} : {' '}</span>
                <a
                  href={`mailto:${ this.props.medReport.businessUnitEmail}`}
                  className="text text-with-link"
                >{this.props.medReport.businessUnitEmail}
                </a>
              </div>
              <div className="important-text">{I18n.t('editDocument.OPEN_HOURS')}</div>
            </div>
            }
                                      </div>
          }
          {this.props.previewMode && <div className="document-bottom">
            <div className="bloc-text text">{I18n.t('editDocument.CONTACT')}
            </div>
            <div className="document-signature">
              <div
                className="bloc-text important-text"
              >{I18n.t('editDocument.DOCTOR')} <span
                className="text"
              >{this.props.medReport.doctorName.toUpperCase()}
              </span>
              </div>
              <img src={this.props.medReport.doctorSignature} className="doctor-signature" />
            </div>
                                     </div>}
        </div>
      </>
    );
  }
}

export default MedicalReportPreview;
