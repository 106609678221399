// @flow
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from 'react-router-dom';
import AsideNav from './layout/AsideNav';
import ClassicHeader from './layout/header/ClassicHeader';
import BaseRoutes from '../pages/BaseRoutes';

type Props = {}

class BaseContainer extends React.Component<Props> {
  render() {
    return (
      <div className="admin-container">
        <ClassicHeader />
        <div className="admin-content-container">
          <AsideNav />
          <main id="main-content">
            <BaseRoutes />
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(BaseContainer);
