// @flow

import { RestClientAuthenticated } from '../network';

const languageApi = {
  fetchAllLanguages: () => new RestClientAuthenticated('/languages/all', 'GET').execute(),
  fetchLanguages: () => new RestClientAuthenticated('/languages', 'GET').execute(),
};

export default languageApi;
