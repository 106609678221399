// @flow
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationsSystem from 'reapop';
import { CASE_PROCESS_DETAILS_PAGE_ROUTE } from './routes';
import ConfirmationModal from './components/modal/modalNotification/ConfirmationModal';
import { notificationTheme } from './components/modal/modalNotification/theme';
import PrivateRoute from './components/PrivateRoute';
import ProcessCaseContainer from './components/ProcessCaseContainer';
import BaseContainer from './components/BaseContainer';
import { USER_IS_AVAILABLE } from './state/process/processReducer';
import { store } from './network/reduce';
import userApi from './network/api/userApi';

type Props = {
  dispatch: Function
}

class App extends React.PureComponent<Props> {
  interval: IntervalID;

  componentDidMount(): void {
    this.interval = setInterval(
      () => userApi.getAvailability()
        .then(res => res.text())
        .then((available) => {
          this.props.dispatch(store(USER_IS_AVAILABLE, available === 'true'));
        })
        .catch(e => console.log('Erreur apppel API', e))
      , 2000,
    );
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
    this.interval = null;
  }

  render() {
    window.history.forward();
    return (
      /* eslint-disable react/jsx-filename-extension */
      <div id="app">
        <NotificationsSystem theme={notificationTheme} />
        <ConfirmationModal />
        <Switch>
          <PrivateRoute path={CASE_PROCESS_DETAILS_PAGE_ROUTE} component={ProcessCaseContainer} />
          <PrivateRoute path="/" component={BaseContainer} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(connect()(App));
