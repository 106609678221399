// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import setFieldData from 'final-form-set-field-data';
import Category from './components/Category';
import AutoSave from '../AutoSave';
import patientApi from '../../network/api/patientApi';
import ModalAssigned from '../modal/ModalAssigned';
import { downloadBase64 } from '../../utils';
import caseApi from '../../network/api/caseApi';
import { store } from '../../network/reduce';
import { MODAL_ASSIGNED } from '../../state/process/processReducer';
import Loader from '../Loader';
import { currentSessionService } from '../../services/sessionServiceInstance';

type
Props = {
  patient: Object,
  queryParams: Object,
  caseInfos: Object,
  documents: Object,
  drawerToggleClickHandle: Function,
  modalAssigned: boolean,
};

type
State = {
  newUsername: string,
  toggleLastConsult: boolean,
  toggleLastCreated: boolean,
  toggleLastDocuments: boolean,
};

class MedicalProfile extends React.Component<Props, State> {
  state = {
    toggleLastConsult: true,
    toggleLastCreated: true,
    toggleLastDocuments: false,
    newUsername: '',
  };

  updateField = (field, oldValue, newValue) =>
    caseApi.checkTakenInCharge(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((changeInfos) => {
        if (changeInfos.change) {
          !this.props.modalAssigned && this.props.dispatch(store(MODAL_ASSIGNED, true));
          this.setState({ newUsername: changeInfos.newUserName });
        } else {
          patientApi
            .saveField(this.props.queryParams.caseId, this.props.caseInfos.patient.id, field, {
              oldValue,
              newValue
            });
        }
      })
      .catch(e => console.log('Erreur apppel API', e));

  download = (fileInfos) => {
    patientApi
      .getDocument(fileInfos.id)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then(docInfo => downloadBase64(docInfo.base64, docInfo.name))
      .catch(e => console.log('Erreur apppel API', e));
  };

  getAge = (birthday:string) => {
    const birthdate = new Date(birthday);
    const cur = new Date();
    const diff = cur - birthdate; // This is the difference in milliseconds
    return Math.floor(diff / 31557600000);
  };

  save = async (field, oldValue, newValue) => {
    await this.updateField(field, oldValue, newValue);
  };

  render() {
    return (
      <div className="medical-profile">
        <ModalAssigned
          modal={this.props.caseInfos && this.props.caseInfos.isEditable ? this.props.modalAssigned : false}
          toggle={() => {
            this.props.dispatch(store(MODAL_ASSIGNED, false));
          }}
          newUsername={this.state.newUsername}
        />
        <div className="medical-title">
          <h1 className="font-weight-bolder">
            <i className="fas fa-heartbeat"/>
            <span>{I18n.t('medicalProfile.title')}</span>
          </h1>
          <div className="patient-general-infos">
            <div>
              {this.props.patient.birthday &&
              <span>{I18n.t('medicalProfile.AGE', { age: this.getAge(this.props.patient.birthday) })}</span>}
              {this.props.patient.sex && <span> - </span>}
              {this.props.patient.sex && <span>{I18n.t(`medicalProfile.SEX.${this.props.patient.sex}`)}</span>}
            </div>
          </div>
        </div>
        {
          Object.keys(this.props.patient).length ?
            <Form
              onSubmit={() => {
              }}
              initialValues={{
                medicalHistory: this.props.patient.medicalHistory,
                treatment: this.props.patient.treatment || '',
                allergies: this.props.patient.allergies || '',
                chronicalDisease: this.props.patient.chronicalDisease || '',
                vaccination: this.props.patient.vaccination || '',
                lifeStyle: this.props.patient.lifeStyle || '',
                weight: this.props.patient.weight === undefined ? '' : this.props.patient.weight,
                height: this.props.patient.height === undefined ? '' : this.props.patient.height,
              }}
              mutators={{ setFieldData }}
              render={({ handleSubmit, values, form }) => (
                <form onSubmit={handleSubmit}>
                  <AutoSave
                    setFieldData={form.mutators.setFieldData}
                    save={this.save}
                  />
                  {
                    this.props.patient && !!Object.keys(this.props.patient).length &&
                    <Fragment>
                      <Category
                        name="medicalHistory"
                        initialToggle={!!this.props.patient.medicalHistory}
                      />
                      <Category
                        name="treatment"
                        initialToggle={!!this.props.patient.treatment}
                      />
                      <Category
                        name="allergies"
                        initialToggle={!!this.props.patient.allergies}
                      />
                      <Category
                        name="chronicalDisease"
                        initialToggle={!!this.props.patient.chronicalDisease}
                      />
                      <Category
                        name="vaccination"
                        initialToggle={!!this.props.patient.vaccination}
                      />
                      <Category
                        name="lifeStyle"
                        initialToggle={!!this.props.patient.lifeStyle}
                      />
                    </Fragment>
                  }
                  <div className="patient-body-informations">
                    <div className="info-lign">
                      <h3>{I18n.t('medicalProfile.weight')}</h3>
                      <div
                        className="field-patient-body"
                      >
                        <Field
                          className="form-control weight-input"
                          component="input"
                          name="weight"
                          type="number"
                          disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                        />
                        <span>{I18n.t('medicalProfile.weight_mesure')}</span>
                      </div>
                    </div>
                    <div className="info-lign">
                      <h3>{I18n.t('medicalProfile.height')}</h3>
                      <div className="field-patient-body">
                        <Field
                          className="form-control weight-input"
                          component="input"
                          name="height"
                          type="number"
                          disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                        />
                        <span>{I18n.t('medicalProfile.height_mesure')}</span>
                      </div>
                    </div>
                    <div className="info-lign">
                      <h3>{I18n.t('medicalProfile.BMI')}</h3>
                      <div className="form-control disabled">
                        {values.weight && values.height && Math.round(values.weight / ((values.height / 100) ** 2) * 100) / 100}
                      </div>
                    </div>
                  </div>
                  <div className="patient-medical-infos">
                    <div
                      onClick={() => this.setState({ toggleLastConsult: !this.state.toggleLastConsult })}
                    >
                      <h2>
                        <i
                          className={` fas ${this.state.toggleLastConsult ? 'fa-chevron-down' : 'fa-chevron-right'}`}
                        />
                        {I18n.t('medicalProfile.lastConsultations.title')}
                      </h2>
                    </div>
                    <div>
                      {this.state.toggleLastConsult &&
                      <div className="last-consultations">
                        {currentSessionService().currentUser().showQualification &&
                        <div
                          className="info-lign"
                          onClick={this.props.documents.nbTeleadvice === 0 ? null :
                            () => {
                              this.setState({ toggleLastDocuments: !this.state.toggleLastDocuments });
                              this.props.drawerToggleClickHandle();
                            }}
                        >
                          <p
                            className="font-weight-lighter title"
                          >{I18n.t('medicalProfile.lastConsultations.advice')}
                          </p>
                          <p>
                            {this.props.documents.nbTeleadvice || '--'}
                            <i
                              className={`fas fa-eye${!this.props.documents.nbTeleadvice || this.props.documents.nbTeleadvice === 0 ? '-slash' : ''}`}/>
                          </p>

                        </div>
                        }
                        <div
                          className="info-lign"
                          onClick={this.props.documents.nbTeleconsult === 0 ? null :
                            () => {
                              this.setState({ toggleLastDocuments: !this.state.toggleLastDocuments });
                              this.props.drawerToggleClickHandle();
                            }
                          }
                        >
                          <p
                            className="font-weight-lighter title"
                          >{I18n.t('medicalProfile.lastConsultations.consult')}
                          </p>
                          <p>
                            {this.props.documents.nbTeleconsult || '--'}
                            <i
                              className={`fas fa-eye${!this.props.documents.nbTeleconsult || this.props.documents.nbTeleconsult === 0 ? '-slash' : ''}`}/>
                          </p>

                        </div>
                        <div className="info-lign">
                          <p className="font-weight-lighter title"
                          >{I18n.t('medicalProfile.lastConsultations.onRollingMonths')}
                          </p>
                          <p>
                            {(this.props.documents.nbTeleconsultOn12Months) + (this.props.documents.nbOfMaxTeleconsult && (` / ${this.props.documents.nbOfMaxTeleconsult}`))
                            || '--'}
                          </p>
                        </div>

                        {this.state.toggleLastDocuments ?
                          <div className="last-documents-cases">
                            {
                              this.props.documents
                              && this.props.documents.lastDocumentsBean.map(values =>
                                (<div className="documents">
                              <span className="documents-title">
                                {moment(values.creationDate).format(I18n.t('lastDocuments.formatDate'))} - {values.type} ({values.subject})
                              </span>
                                  {
                                    <div className="documents-tile">
                                      <div className="document-name">{values.name}</div>
                                      <a onClick={() => this.download(values)}><i
                                        className="fa fa-arrow-to-bottom"
                                      />
                                      </a>
                                    </div>
                                  }
                                </div>))
                            }
                          </div>
                          : ''
                        }
                      </div>
                      }
                    </div>
                  </div>
                  <div className="patient-medical-infos last-documents">
                    <div
                      onClick={() => this.setState({ toggleLastCreated: !this.state.toggleLastCreated })}
                    >
                      <h2>
                        <i
                          className={` fas ${this.state.toggleLastCreated ? 'fa-chevron-down' : 'fa-chevron-right'}`}
                        />
                        {I18n.t('medicalProfile.datesOfLastDocCreated.title')}
                      </h2>
                    </div>
                    <div>
                      {this.state.toggleLastCreated &&
                      <div>
                        {currentSessionService().currentUser().showQualification &&
                        <div className="info-lign">
                          <p className="font-weight-lighter">{I18n.t('medicalProfile.datesOfLastDocCreated.advice')}</p>
                          <p>{this.props.documents.lastAdviceDate ? moment(this.props.documents.lastAdviceDate).format(I18n.t('lastDocuments.formatDate')) : '--'}</p>
                        </div>
                        }
                        <div className="info-lign">
                          <p
                            className="font-weight-lighter"
                          >{I18n.t('medicalProfile.datesOfLastDocCreated.consult')}
                          </p>
                          <p>{this.props.documents.lastConsultDate ? moment(this.props.documents.lastConsultDate).format(I18n.t('lastDocuments.formatDate')) : '--'}</p>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                </form>
              )}
            />
            :
            <div className="loader-medical-profile">
              <Loader/>
            </div>
        }

      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
  modalAssigned: state.process.modalAssigned,
}))(MedicalProfile));
