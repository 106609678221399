

import React, { createContext, useState } from 'react';
import { SearchCriteriaType } from '../types/B-PartnerManagement';

const BPartnerContext = createContext({
    searchCriteriaContext: null,
    updateCriteriaSearchContext: (searchCriteria: SearchCriteriaType) => { }
});

export function BPartnerContextProvider(props) {

    const [useSearchCriteria, setUseSearchCriteria] = useState(null);

    function updateSearchCriteriaHandler(searchCriteria: SearchCriteriaType) {
        setUseSearchCriteria(searchCriteria);
    }

    const context = {
        searchCriteriaContext: useSearchCriteria,
        updateSearchCriteriaContext: updateSearchCriteriaHandler
    };

    return (
        <BPartnerContext.Provider value={context}>
            {props.children}
        </BPartnerContext.Provider>
    );
}

export default BPartnerContext;