// @flow

import './lib/font-awesome-5/lib/css/all.css';
import 'react-dates/initialize';
import 'react-datetime/css/react-datetime.css';
import 'react-table/react-table.css';
import './lib/coreoz-form-base/components/input/style/base.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import { initializeSessionService } from './services/sessionService';
import App from './App';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/login/Login';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import { FAX_PAGE_ROUTE, FORGOT_PASSWORD_ROUTE, LOGIN_PAGE_ROUTE } from './routes';
import './sass/app.scss';
import fr from './i18n/locales/fr';
import en from './i18n/locales/en';
import it from './i18n/locales/it';
import reducers from './state/reducers';
import es from './i18n/locales/es';
import { currentLanguage } from './services/languages';
import { initSentry } from './sentry';
import FaxRoutes from "./pages/fax/FaxRoutes";

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk)),
);
initializeSessionService(store);
syncTranslationWithStore(store);
store.dispatch(loadTranslations({
  fr,
  en,
  es,
  it,
}));
store.dispatch(setLocale(currentLanguage().code));

/* eslint-disable react/jsx-filename-extension */
function wrapApp(RootComponent, reduxStore) {
  return (
    <Provider store={reduxStore}>
      <BrowserRouter>
        <Switch>
          <Route path={FAX_PAGE_ROUTE} component={FaxRoutes} />
          <Route exact path={LOGIN_PAGE_ROUTE} component={Login} />
          <Route exact path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
          <PrivateRoute path="/" component={RootComponent} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

initSentry.then(() => {
  // $FlowFixMe
  ReactDOM.render(wrapApp(App, store), document.getElementById('root'));
});



