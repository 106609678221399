// @flow

import React from 'react';

type Props = {
  show: boolean,
  children: any,
  side?: boolean,
}

class CoreozDrawer extends React.Component<Props> {
  render() {
    return (
      <div className={'side-drawer' + (this.props.side ? '-left' : '') + (this.props.show ? ' open' : '')}>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

CoreozDrawer.defaultProps = {
  side: false,
};

export default CoreozDrawer;
