import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Loader from '../../components/Loader';
import BPartnerContext from '../../stores/b-partner-management-context';
import {
	CoreozDatePickerBase,
	CoreozInputBase,
	CoreozSelectBase,
} from '../../lib/coreoz-form-base/index';
import { BpartnerStatus } from '../../enum';
import userApi from '../../network/api/userApi';
import bPartnerApi from '../../network/api/bPartnerApi';
import {
	SearchCriteriaType,
	SearchResultType,
} from '../../types/B-PartnerManagement';
import BPartnerTable from '../../components/bPartner/BPartnerTable';
import Pagination from 'react-js-pagination';
import {
	B_PARTNER_CREATE_PAGE_ROUTE,
	B_PARTNER_EDIT_PAGE_ROUTE,
} from '../../routes';
import { currentSessionService } from '../../services/sessionServiceInstance';
import hasPermission from '../../services/permissionService';
import exportApi from '../../network/api/exportApi';
import { download } from '../../utils';
import { notifyError } from '../../network/notification';
import ButtonExport from '../../components/ButtonExport';

const initialPage = {
	currentPage: 1,
	pageSize: 10,
	perPage: 0,
	totalPages: 0,
};

const BPartnerManagement = (props) => {
	const { searchCriteriaContext, updateSearchCriteriaContext } =
		useContext(BPartnerContext);
	const [searchResults, setSearchResults] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalSearchResult, setTotalSearchResult] = useState(0);
	const [businessUnits, setBusinessUnits] = useState([]);

	const [currentPage, setCurrentPage] = useState(initialPage.currentPage);
	const [pageSize, setPageSize] = useState(initialPage.pageSize);
	const [perPage, setPerPage] = useState(initialPage.perPage);
	const [totalPages, setTotalPages] = useState(initialPage.totalPages);
	const [exportLoading, setExportLoading] = useState(false);

	useEffect(() => {
		fetchBusinessUnits();
		if (searchCriteriaContext) {
			fetchBpartner(searchCriteriaContext);
		} else {
			fetchBpartner({
				page: currentPage,
				size: pageSize,
			});
		}
	}, []);

	async function fetchBpartner(searchCriteria: SearchCriteriaType = {}) {
		setLoading(true);

		if (!searchCriteria?.page) {
			searchCriteria.page = initialPage.currentPage;
		}
		if (!searchCriteria?.size) {
			searchCriteria.size = initialPage.pageSize;
		}

		const searchResultResponse: SearchResultType = await props.dispatch(
			bPartnerApi.search(searchCriteria)
		);

		updateSearchCriteriaContext({ ...searchCriteria });

		const {
			content = [],
			page = initialPage.currentPage,
			perPage = initialPage.perPage,
			total = 0,
			totalPages = initialPage.totalPages,
		} = searchResultResponse ?? {};

		setSearchResults(content);
		setCurrentPage(page);
		setPerPage(perPage);
		setTotalSearchResult(total);
		setTotalPages(totalPages);

		setLoading(false);
	}

	async function fetchBusinessUnits() {
		const businessUnitsResponse = await userApi
			.fetchBusinessUnits()
			.then((response) => response.json());

		setBusinessUnits(
			businessUnitsResponse?.filter(
				(v) =>
					hasPermission('EDITABLE_BUSINESS_UNIT_B_PARTNER') ||
					v.id ===
						currentSessionService().currentUser().businessUnitId
			) ?? []
		);
	}

	const resetSearchResults = () => {
		fetchBpartner();
	};

	const handlePageChange = (pageNumber) => {
		fetchBpartner({
			...searchCriteriaContext,
			page: pageNumber,
		});
		setCurrentPage(pageNumber);
	};

	const goToCreate = () => {
		props.history.push(B_PARTNER_CREATE_PAGE_ROUTE);
	};

	const goToView = (itemId: number) => {
		props.history.push(B_PARTNER_EDIT_PAGE_ROUTE.replace(':id', itemId));
	};

	const downloadReport = (searchParameters: SearchCriteriaType) => {
		setExportLoading(true);

		const filters = {
			...searchParameters,
			columnsTranslation: I18n.t('bPartner.export'),
		};

		exportApi
			.exportBPartnerKpi(filters)
			.then((response) => response.blob())
			.then((blob) =>
				download(blob, 'export_b_partner.csv')
			)
			.catch((e) => {
				notifyError(props.dispatch)(e);
			})
			.finally(() => {
				setExportLoading(false);
			});
	};

	const onSearch = (values: SearchCriteriaType) => {
		fetchBpartner({ ...values, page: 1 });
	};

	return (
		<div className="b-partner-management-container" id="bPartnerManagement">
			<div className="title-container">
				<div className="title">{I18n.t('bPartner.TITLE_LIST')}</div>
			</div>
			<div className="search-bar">
				<Form
					onSubmit={onSearch}
					render={({ handleSubmit, form, values }) => (
						<form onSubmit={handleSubmit}>
							<Field
								name="bPartnerCode"
								id="bPartnerCodeSearch"
								label={I18n.t('bPartner.label.B_PARTNER_CODE')}
								component={CoreozInputBase}
								className="m-0"
								type="text"
								maxLength="9"
								format={(stringValue) =>
									(stringValue &&
										stringValue.replace(/[^\d]/g, '')) ||
									''
								}
								defaultValue={
									searchCriteriaContext?.bPartnerCode
								}
							/>
							<Field
								name="bPartnerName"
								id="bPartnerNameSearch"
								label={I18n.t('bPartner.label.B_PARTNER_NAME')}
								component={CoreozInputBase}
								className="m-0"
								maxLength="50"
								defaultValue={
									searchCriteriaContext?.bPartnerName
								}
							/>

							<div className="layout-third-row search-box pt-4">
								<Button
									type="submit"
									color="primary"
									className="validation-button m-0"
									id="searchBtnSearch"
								>
									<i className="fa fa-search" />
									{I18n.t('button.SEARCH')}
								</Button>
								<Button
									type="button"
									color="primary"
									className="validation-button m-0"
									onClick={() => {
										form.reset();
										resetSearchResults();
									}}
									id="resetBtnSearch"
								>
									<i className="fa fa-redo-alt" />
									{I18n.t('button.RESET')}
								</Button>
							</div>

							<Field
								name="businessUnitId"
								id="businessUnitSearch"
								autoComplete="off"
								type="text"
								className="m-0"
								component={CoreozSelectBase}
								label={I18n.t('bPartner.label.BUSINESS_UNIT')}
								list={businessUnits?.map((bu) => ({
									id: bu.id,
									label: bu.name,
								}))}
								required={false}
								placeholder="All"
								disabledPlaceholder={false}
								defaultValue={
									searchCriteriaContext?.businessUnitId
								}
							/>

							<Field
								name="status"
								id="statusSearch"
								type="text"
								className="m-0"
								component={CoreozSelectBase}
								label={I18n.t('bPartner.label.STATUS')}
								disabledPlaceholder={false}
								placeholder="All"
								list={Object.keys(BpartnerStatus).map((v) => ({
									id: BpartnerStatus[v],
									label: I18n.t(`bPartner.STATUS.${v}`),
								}))}
								defaultValue={searchCriteriaContext?.status}
								required={false}
							/>
							<div className="layout-third-row search-box pt-4">
								<ButtonExport
									downloadReport={downloadReport}
									loading={exportLoading}
									values={values}
								></ButtonExport>

								<Button
									type="button"
									color="primary"
									className="validation-button m-0"
									onClick={goToCreate}
									id="createButton"
								>
									<i className="fa fa-plus" />
									{I18n.t('bPartner.button.ADD')}
								</Button>
							</div>
						</form>
					)}
				/>
			</div>

			<div className="search-result">
				{!loading && searchResults?.length > 0 && (
					<>
						<div className="box-result">
							<BPartnerTable
								currentItems={searchResults}
								goToView={goToView}
							/>
						</div>
						<div className="box-paginate">
							<div className="show-paginate-result">
								{I18n.t('bPartner.TABLE.PAGINATION_INFO', {
									start: (currentPage - 1) * pageSize + 1,
									end:
										(currentPage - 1) * pageSize + pageSize,
									total: totalSearchResult,
								})}
							</div>
							<div className="paginate-list">
								<Pagination
									itemClass="page-item"
									linkClass="page-link"
									firstPageText="&laquo;"
									lastPageText="&raquo;"
									prevPageText="&lsaquo;"
									nextPageText="&rsaquo;"
									activePage={currentPage}
									itemsCountPerPage={totalPages}
									totalItemsCount={totalSearchResult}
									pageRangeDisplayed={5}
									onChange={handlePageChange}
								/>
							</div>
						</div>
					</>
				)}
				{!loading && !searchResults?.length && (
					<div className="no-result">
						{I18n.t('internalBeneficiary.SEARCH.NO_RESULT')}
					</div>
				)}
				{loading && (
					<div className="center-child search-loader">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

export default withRouter(connect()(BPartnerManagement));
