// @flow
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozDatePickerBase, CoreozInputBase, CoreozSelectBase as CoreozSelect } from '../../lib/coreoz-form-base/index';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { dateValidator } from '../../validator';
import userApi from '../../network/api/userApi';
import type { User } from '../../types/UsersTypes';
import { CaseLifeStatus, CommunicationChannel, PrequalificationCode, Roles } from '../../enum';
import caseApi from '../../network/api/caseApi';
import bPartnerApi from '../../network/api/bPartnerApi';
import CaseSearchResultTile from '../../components/cases/CaseSearchResultTile';
import type { CaseSearchResultType, CaseSearchType } from '../../types/CasesTypes';
import { currentSessionService } from '../../services/sessionServiceInstance';
import Loader from '../../components/Loader';
import { notifyError } from '../../network/notification';
import PaginatedList from '../../components/PaginatedList';

type Props = {
  dispatch: Function,
  location: Object,
}
type State = {
  cases: CaseSearchResultType[],
  showCriteria: boolean,
  lastSearchObject: ?CaseSearchType,
  users: User[],
  bPartners: [],
  loading: boolean,
  isLastCasesDisplayed: boolean,
}

class CaseSearch extends React.Component<Props, State> {
  state = {
    cases: [],
    showCriteria: false,
    lastSearchObject: {},
    loading: true,
    isLastCasesDisplayed: true,
    users: [],
    bPartners: [],
  };

  fetchCases = (values: any) => {
    this.setState({
      loading: true,
      cases: [],
      lastSearchObject: values || {},
      isLastCasesDisplayed: false,
    });

    caseApi.search(values)
      .then(response => response.text())
      .then(text => text.length ? JSON.parse(text) : [])
      .then(cases => this.setState({ cases, loading: false }))
      .catch((e) => {
        this.setState({ loading: false });
        notifyError(this.props.dispatch)(e);
      });
  };

  fetchLastCases = () => {
    caseApi.fetchLastCasesByRole()
      .then(response => response.text())
      .then(text => text.length ? JSON.parse(text) : [])
      .then(cases => this.setState({ cases, loading: false }))
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(notifyError)(e);
      });
  }

  displayCriteria = () => {
    let criteria = '';
    if (!this.state.lastSearchObject) {
      return criteria;
    }
    if (this.state.lastSearchObject.periodFrom) {
      criteria += `${I18n.t('caseSearch.periodFrom')} : ${moment(this.state.lastSearchObject.periodFrom).format(I18n.t('date.DATE_FORMAT'))}, `;
    }
    if (this.state.lastSearchObject.periodTo) {
      criteria += `${I18n.t('caseSearch.periodTo')} : ${moment(this.state.lastSearchObject.periodTo).format(I18n.t('date.DATE_FORMAT'))}, `;
    }
    if (this.state.lastSearchObject.inCharge) {
      criteria += `${I18n.t('caseSearch.inCharge')} : ${this.getLabel(this.state.lastSearchObject.inCharge, 'users')}, `;
    }
    if (this.state.lastSearchObject.assigned) {
      criteria += `${I18n.t('caseSearch.assigned')} : ${this.getLabel(this.state.lastSearchObject.assigned, 'users')}, `;
    }
    if (this.state.lastSearchObject.bpartner) {
      criteria += `${I18n.t('caseSearch.bPartner')} : ${this.getLabel(this.state.lastSearchObject.bpartner, 'bPartners')}, `;
    }
    if (this.state.lastSearchObject.teamInCharge) {
      criteria += `${I18n.t('caseSearch.teamInCharge')} : ${I18n.t(`role.${this.state.lastSearchObject.teamInCharge}`)}, `;
    }
    if (this.state.lastSearchObject.teamAssigned) {
      criteria += `${I18n.t('caseSearch.teamAssigned')} : ${I18n.t(`role.${this.state.lastSearchObject.teamAssigned}`)}, `;
    }
    if (this.state.lastSearchObject.channel) {
      criteria += `${I18n.t('caseSearch.channel')} : ${I18n.t(`channel.${this.state.lastSearchObject.channel}`)}, `;
    }
    if (this.state.lastSearchObject.qualificationCode) {
      criteria += `${I18n.t('caseSearch.qualificationCode')} : ${I18n.t(`caseSearch.label.${Object.entries(PrequalificationCode).filter(kv => kv[1] === this.state.lastSearchObject.qualificationCode)[0][0]}`)}, `;
    }
    if (this.state.lastSearchObject.status) {
      criteria += `${I18n.t('caseSearch.status')} : ${I18n.t(`caseSearch.label.${this.state.lastSearchObject.status}`)}, `;
    }
    if (this.state.lastSearchObject.caseNumber) {
      criteria += `${I18n.t('caseSearch.caseNumber')} : ${this.state.lastSearchObject.caseNumber}, `;
    }

    if (criteria.length > 2 && criteria[criteria.length - 1] === ' ' && criteria[criteria.length - 2] === ',') {
      return criteria.slice(0, -2);
    }

    return criteria;
  };

  getLabel(id, listName) {
    if (listName === 'users') {
      return this.state.users.filter(user => user.id === id)[0].label;
    } else if (listName === 'bPartners') {
      return this.state.bPartners.filter(bPartner => bPartner.id === id)[0].bpartnerName;
    }
    return '';
  }

  componentDidMount() {
    userApi
      .fetchByBusinessUnit()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(users => this.setState({
        users: users.map(user => ({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
        })),
      }))
      .catch(e => console.log('Erreur apppel API', e));

    bPartnerApi
      .fetchBpartners()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(bPartners =>
        this.setState({bPartners}))
      .catch(e => console.log('Erreur apppel API', e));

    if (this.props.location.state && Object.keys(this.props.location.state).length) {
      // s'il y a des filtres autres que les dates (filtres du menu deroulant)
      if (Object.keys(this.props.location.state).filter(key => key !== 'periodFrom' && key !== 'periodTo').length) {
        this.setState({ showCriteria: true });
      }
      this.fetchCases(this.props.location.state);
    } else {
      this.fetchLastCases();
    }
  }

  render() {
    return (
      <div className="case-search-container">
        <div className="title-container">
          <div className="title">{I18n.t('caseSearch.TITLE')}</div>
        </div>
        <div className="search-bar">
          <Form
            onSubmit={this.fetchCases}
            initialValues={this.props.location.state || {}}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="criteria-line period">
                  <div className="period-field">
                    <Field
                      className="periodFrom"
                      name="periodFrom"
                      component={CoreozDatePickerBase}
                      placeholder={I18n.t('caseSearch.periodFromPlaceHolder')}
                      validate={e => dateValidator(e, moment.ISO_8601)}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      format={(stringValue) => {
                        if (stringValue) {
                          if (moment(stringValue, 'DD/MM/YYYY', true).isValid()) {
                            return moment(stringValue, 'DD/MM/YYYY');
                          }
                          if (moment(stringValue, moment.ISO_8601).isValid()) {
                            return moment(stringValue);
                          }
                        }
                        return stringValue;
                      }}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                    />
                    <i className="fa fa-arrow-right" />
                    <Field
                      className="periodTo"
                      name="periodTo"
                      component={CoreozDatePickerBase}
                      placeholder={I18n.t('caseSearch.periodToPlaceHolder')}
                      validate={e => dateValidator(e, moment.ISO_8601)}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      format={(stringValue) => {
                        if (stringValue) {
                          if (moment(stringValue, 'DD/MM/YYYY', true).isValid()) {
                            return moment(stringValue, 'DD/MM/YYYY');
                          }
                          if (moment(stringValue, moment.ISO_8601).isValid()) {
                            return moment(stringValue);
                          }
                        }
                        return stringValue;
                      }}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                    />
                  </div>
                  <Button
                    type="submit"
                    color="primary"
                    className="validation-button"
                  >
                    <i className="button-icon fa fa-search" />
                    {I18n.t('button.SEARCH')}
                  </Button>
                </div>
                <div className={`${this.state.showCriteria ? 'show-criteria' : 'hide-criteria'} `}>
                  <div className="criteria-line">
                    <Field
                      name="inCharge"
                      component={CoreozSelect}
                      placeholder={I18n.t('caseSearch.inCharge')}
                      list={this.state.users}
                      disabledPlaceholder={false}
                    />
                    <Field
                      name="assigned"
                      component={CoreozSelect}
                      placeholder={I18n.t('caseSearch.assigned')}
                      list={this.state.users}
                      disabledPlaceholder={false}
                    />
                    <Field
                      name="bpartner"
                      component={CoreozSelect}
                      placeholder={I18n.t('caseSearch.bPartner')}
                      list={this.state.bPartners.map(bPartner => ({id: bPartner.id, label: bPartner.bpartnerName}) )}
                      disabledPlaceholder={false}
                    />
                  </div>
                  <div className="criteria-line">
                    <Field
                      name="teamInCharge"
                      component={CoreozSelect}
                      placeholder={I18n.t('caseSearch.teamInCharge')}
                      list={Object.keys(Roles).map(role => ({ id: role, label: I18n.t(`role.${role}`) }))}
                      disabledPlaceholder={false}
                    />
                    <Field
                      name="teamAssigned"
                      component={CoreozSelect}
                      placeholder={I18n.t('caseSearch.teamAssigned')}
                      list={Object.keys(Roles).map(role => ({ id: `${role}`, label: I18n.t(`role.${role}`) }))}
                      disabledPlaceholder={false}
                    />
                    <Field
                      name="channel"
                      component={CoreozSelect}
                      placeholder={I18n.t('caseSearch.channel')}
                      list={Object.keys(CommunicationChannel)
                        .filter(channel => currentSessionService().currentUser().showChat ||
                          CommunicationChannel[channel] !== CommunicationChannel.CHAT)
                        .map(status => ({ id: status, label: I18n.t(`channel.${status}`) }))}
                      disabledPlaceholder={false}
                    />
                  </div>
                  <div className="criteria-line ">
                    <Field
                      name="qualificationCode"
                      component={CoreozSelect}
                      placeholder={I18n.t('caseSearch.qualificationCode')}
                      list={Object.keys(PrequalificationCode).map(code =>
                        ({ id: PrequalificationCode[code], label: I18n.t(`caseSearch.label.${code}`) }))}
                      disabledPlaceholder={false}
                    />
                    <Field
                      name="status"
                      component={CoreozSelect}
                      placeholder={I18n.t('caseSearch.status')}
                      list={Object.keys(CaseLifeStatus).map(status =>
                        ({ id: CaseLifeStatus[status], label: I18n.t(`caseSearch.label.${status}`) }))}
                      disabledPlaceholder={false}
                    />
                    <Field
                      name="caseNumber"
                      component={CoreozInputBase}
                      placeholder={I18n.t('caseSearch.caseNumber')}
                    />
                  </div>
                </div>
                <a onClick={() => this.setState({ showCriteria: !this.state.showCriteria })}>
                  {this.state.showCriteria ? I18n.t('caseSearch.SHOW_LESS') : I18n.t('caseSearch.SHOW_MORE')}
                </a>
              </form>
            )}
          />
        </div>
        <div className="search-result">
          <div className="result-title">{I18n.t('caseSearch.SEARCH_RESULT')}</div>
          <div className="result-list">
            <div className="criteria">
              {!this.state.loading &&
              <div>
                {
                  this.state.isLastCasesDisplayed ?
                    <div className="no-result">
                      {I18n.t('caseSearch.FIRST_CASES', { count: this.state.cases.length })}
                    </div>
                    :
                    <div>
                      <div>{I18n.t('caseSearch.CASES_FOUND', { count: this.state.cases.length })}</div>
                      <div> {this.displayCriteria()}</div>
                    </div>
                }
              </div>}

              {
                <PaginatedList
                  pageSize={10}
                  dataArray={this.state.cases}
                  render={caseFound => (
                    <CaseSearchResultTile
                      key={caseFound.id}
                      caseFound={caseFound}
                      lastSearchObject={this.state.lastSearchObject}
                    />
                  )}
                />
              }
            </div>
          </div>

          {this.state.loading && <div>
            {
              this.state.isLastCasesDisplayed &&
              <div className="no-result">{I18n.t('caseSearch.WAIT_10_LAST_CASES')}</div>
            }
            <div className="center-child search-loader"><Loader /></div>
                                 </div>}

        </div>
      </div>
    );
  }
}

export default withRouter(connect()(CaseSearch));
