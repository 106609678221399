// @flow

import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { connect } from 'react-redux';
import { Availability } from '../../../enum';
import AllianzLogo from '../../../img/allianz-logo.png';
import { currentSessionService } from '../../../services/sessionServiceInstance';
import { changeLanguage } from '../../../state/businessUnit/businessUnitService';
import { toggleDashboardMenu } from '../../../state/dashboard/dashboardService';
import userApi from '../../../network/api/userApi';
import { languages } from '../../../services/languages';
import { withRouter } from 'react-router-dom';
import { store } from '../../../network/reduce';
import { USER_IS_AVAILABLE } from '../../../state/process/processReducer';

type Props = {
  dispatch: Function,
  store: Function,
  toggleAsideNav: Function,
  language: {
    code: string,
    imgPath: string,
  },
  changeLanguage: Function,
  dashboardMenu: Boolean,
  autoSave: Boolean,
  autoSaveEnabled: Boolean,
  userIsAvailable: boolean,
}

type State = {
  userName: string,
  available: boolean,
  dropdownFlagOpen: boolean,
  valueOfFlag: string,
  open: boolean,
}

class ClassicHeader extends React.Component<Props, State> {
  state = {
    userName: currentSessionService().currentUser().fullName,
    open: false,
    available: this.props.userIsAvailable,
  };

  handleChangeLanguage = (value) => {
    this.props.changeLanguage(value);
    this.setState({
      open: false,
    });
  };

  handleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  componentWillUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): void {
    if (nextProps.userIsAvailable !== this.props.userIsAvailable) {
      this.setState({ available: nextProps.userIsAvailable });
    }
  }

  updateAvailability = () => {
    userApi
      .updateAvailability(this.state.available)
      .then(() => this.props.store(USER_IS_AVAILABLE, this.state.available))
      .catch(e => console.log('Erreur apppel API', e));
  };

  render() {
    return (
      <div className="header">
        <div className="classicHeader">
          <div
            className="responsive-menu"
            onClick={() => this.props.toggleDashboardMenu(!this.props.dashboardMenu)
            }
          >
            <i className="fas fa-bars" />
          </div>
          <div id="site-logo">
            <img src={AllianzLogo} alt={I18n.t('brand')} />
          </div>
          <div id="header-nav">
            {
              this.props.autoSaveEnabled &&
              <div className={`save-message ${this.props.autoSave ? 'saved' : 'not-saved'}`}>
                <i className="far fa-save" />
                <div className="message">
                  {this.props.autoSave ? I18n.t('processCaseHeader.AUTO_SAVED') : I18n.t('processCaseHeader.AUTO_SAVING')}
                </div>
              </div>
            }
            <div
              id="availability"
              onClick={() => this.setState({ available: !this.state.available }, () => this.updateAvailability())}
            >
              <div
                id="status"
                className={`${this.state.available ? Availability.AVAILABLE : Availability.BUSY} pointer`}
              >
                <i
                  className="fa fa-circle"
                />{this.state.available ? I18n.t('header.availability.AVAILABLE') : I18n.t('header.availability.BUSY')}
              </div>
            </div>
            {
              this.props.language &&
              <div className="language-flags">
                <img
                  data-testid="header-button-language"
                  className="button-language"
                  onClick={this.handleOpen}
                  src={this.props.language.imgPath}
                  alt=""
                />
                {this.state.open &&
                <div className="form-group">
                  <div className="form-control" data-testid="header-list-flag">
                    {
                      languages().map(language => (
                        <div className="liste-flag" key={language.code}>
                          <img
                            className="button-language"
                            onClick={() => this.handleChangeLanguage(language)}
                            src={language.imgPath}
                            alt={language.code}
                          />
                        </div>
                      ))
                    }
                  </div>
                </div>
                }
              </div>
            }

            <ul className="nav nav-pills">
              <UncontrolledDropdown className="nav-item dropdown" inNavbar>
                <DropdownToggle
                  nav
                  caret
                >
                  <i className="fas fa-user-circle" />
                  <span data-testid="user-name">{this.state.userName}</span>
                  <b className="caret" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                  <DropdownItem
                    className="dropdown-item"
                    data-testid="logout-button"
                    onClick={() => {
                      currentSessionService().logout();
                    }}
                  >
                    <Translate value="header.LOGOUT" />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  state => ({
    language: state.businessUnit.language,
    dashboardMenu: state.dashboard.dashboardMenu,
    autoSave: state.process.autoSave,
    autoSaveEnabled: state.process.autoSaveEnabled,
    userIsAvailable: state.process.userIsAvailable,
  }),
  {
    changeLanguage,
    toggleDashboardMenu,
    store,
  },
)(ClassicHeader));
