// @flow
import { setLocale } from 'react-redux-i18n';
import { store } from '../../network/reduce';
import businessUnitApi from '../../network/api/businessUnitApi';
import { notifyError } from '../../network/notification';
import { BUSINESSUNITS_, LANGUAGE } from './businessUnitReducer';
import type { Language } from '../../services/languages';
import { storeLang } from '../../services/languages';

export const fetchBusinessUnits = () => (dispatch: Function) =>
  businessUnitApi
    .fetch()
    .then(response => response.json())
    .then((responseJson) => {
      dispatch(store(BUSINESSUNITS_, responseJson));
    })
    .catch(dispatch(notifyError));

export function changeLanguage(language: Language) {
  return (dispatch: Function) => {
    dispatch(store(LANGUAGE, language));
    dispatch(setLocale(language.code));
    storeLang(language.code);
  };
}
