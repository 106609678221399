// @flow

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

type Props = {
  disabled: boolean,
  onSmsPress: Function,
  onMailPress: Function,
  onCaseReport: Function,
  defaultReportValue: boolean,
}

type States = { checked: boolean }

class SendButtons extends Component<Props, States> {

  state = {
    checked: this.props.defaultReportValue,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.defaultReportValue !== nextProps.defaultReportValue) {
      this.setState({ checked: nextProps.defaultReportValue });
    }
  }

  render() {
    const {
      onMailPress, onSmsPress, disabled, onCaseReport,
    } = this.props;

    return (
      <div className="row search-buttons">
        <Button className="buttons" onClick={onMailPress}
                disabled={disabled}> {I18n.t('contact.hospitalSearch.sendMail')}</Button>
        <Button className="buttons" onClick={onSmsPress}
                disabled={disabled}> {I18n.t('contact.hospitalSearch.sendSms')}</Button>
        <div>
          <input type="checkbox" name="caseReport" id="caseReport" checked={this.state.checked}
                 onChange={(e) => {
                   onCaseReport(e.target.checked)
                   this.setState({ checked: e.target.checked })
                 }}
          />
          <label for="caseReport">{I18n.t('contact.hospitalSearch.caseReport')}</label>
        </div>
      </div>
    );
  }
}

export default withRouter(SendButtons);
