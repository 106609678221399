// @flow

import { RestClientAuthenticated } from '../network';
import type { User, CreateUserRequest } from '../../types/UsersTypes';

const userApi = {
  fetch: () => new RestClientAuthenticated('/admin/users', 'GET').execute(),
  fetchByBusinessUnit: () => new RestClientAuthenticated('/eprm-users', 'GET').execute(),
  fetchById: (id: String) => new RestClientAuthenticated(`/eprm-users/${id}`, 'GET').execute(),
  fetchUserDetailsById: (id: String) => new RestClientAuthenticated(`/eprm-users/details/${id}`, 'GET').execute(),
  fetchTypes: () => new RestClientAuthenticated('/eprm-users/types', 'GET').execute(),
  fetchBusinessUnits: () => new RestClientAuthenticated('/eprm-users/businessUnits', 'GET').execute(),
  create: (data: CreateUserRequest) => new RestClientAuthenticated('/eprm-users', 'POST').jsonBody(data).execute(),
  update: (user: Object) => new RestClientAuthenticated('/eprm-users', 'PUT').jsonBody(user).execute(),
  delete: (dataId: $PropertyType<User, 'id'>) => new RestClientAuthenticated(`/eprm-users/${dataId}`, 'DELETE').execute(),
  getWaitTime: () => new RestClientAuthenticated('/eprm-users/waittime', 'GET').execute(),
  updateTimeWait: (wait: Object) => new RestClientAuthenticated('/eprm-users/waittime', 'PUT').jsonBody(wait).execute(),
  updateAvailability: (value: boolean) => new RestClientAuthenticated('/eprm-users/availability', 'POST').jsonBody(value).execute(),
  getAvailability: () => new RestClientAuthenticated('/eprm-users/availability', 'GET').execute(),
  search: (searchObject: Object) => new RestClientAuthenticated('/eprm-users/search', 'POST').jsonBody(searchObject).execute(),
};

export default userApi;
