import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { Table } from 'reactstrap';
import { BusinessUnit } from '../../types/BusinessUnitTypes';

type Props = {
    currentItems: BusinessUnit[],
    goToView: Function,
};

function BusinessTable({ currentItems, goToView }: Props) {
  return (
    <Table bordered>
      <thead>
        <tr>
          <th>
            {I18n.t('businessUnit.TABLE.BUSINESS_UNIT_NAME')}
          </th>
          <th>
            {I18n.t('businessUnit.TABLE.COUNTRY')}
          </th>
          <th>
            {I18n.t('businessUnit.TABLE.COUNTRY_CODE')}
          </th>
          <th>
            {I18n.t('businessUnit.TABLE.ZONE')}
          </th>
        </tr>
      </thead>
      <tbody>
        {currentItems && currentItems.map(item => (
          <tr
            key={item.id}
            onClick={() => {
                            goToView(item.id);
                        }}
          >
            <td>{item.name}</td>
            <td>{item.countryName}</td>
            <td>{item.countryCode}</td>
            <td>{item.timezone}</td>
          </tr>
                    ))}
      </tbody>
    </Table>
  );
}

export default BusinessTable;
