import React, { createContext, useState } from 'react';
import { SearchCriteriaType } from '../types/LegalEntityManagement';

const LegalEntityContext = createContext({
  searchCriteriaContext: null,
  updateCriteriaSearchContext: (searchCriteria: SearchCriteriaType) => { },
});

export function LegalEntityContextProvider(props) {
  const [useSearchCriteria, setUseSearchCriteria] = useState(null);

  function updateSearchCriteriaHandler(searchCriteria: SearchCriteriaType) {
    setUseSearchCriteria(searchCriteria);
  }

  const context = {
    searchCriteriaContext: useSearchCriteria,
    updateSearchCriteriaContext: updateSearchCriteriaHandler,
  };

  return (
    <LegalEntityContext.Provider value={context}>
      {props.children}
    </LegalEntityContext.Provider>
  );
}

export default LegalEntityContext;
