// @flow

import { AUTO_SAVE_ENABLED, IS_SALVAGEABLE } from './processReducer';
import { store } from '../../network/reduce';

export const showAutoSaveBadge = (dispatch: Function) => dispatch(store(AUTO_SAVE_ENABLED, true));
export const hideAutoSaveBadge = (dispatch: Function) => dispatch(store(AUTO_SAVE_ENABLED, false));

export function updateIsSalvageable(value: boolean) {
  return (dispatch: Function) => {
    dispatch(store(IS_SALVAGEABLE, value));
  };
}
