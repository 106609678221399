// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import SmsSearch from '../../components/sms/SmsSearch';
import SmsList from '../../components/sms/SmsList';
import type { Criteria, Sms } from '../../types/SmsTypes';
import { store } from '../../network/reduce';
import { SEARCH_RESULTS_SMS } from '../../state/sms/smsReducer';
import search from '../../state/search';
import { fetchSmsList } from '../../state/sms/smsService';

type Props = {
  smsList: Sms[],
  searchResultsSms: [],
  dispatch: Function,
}

class SmsMain extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(fetchSmsList());
  }

  searchSms = (criteria: Criteria) => {
    this.props.dispatch(store(
      SEARCH_RESULTS_SMS,
      search(criteria, this.props.smsList),
    ));
  };

  render() {
    if (this.props.smsList === undefined || !Array.isArray(this.props.smsList)) {
      return false;
    }

    return (
      <div>
        <h1 className="content-title"><Translate value="sms.TITLE" /></h1>
        <div className="content">
          <Row className="row">
            <Col xs="12">
              <SmsSearch
                search={this.searchSms}
                smsList={this.props.smsList}
              />
            </Col>
            <Col xs="12">
              <SmsList
                searchResultSmsList={this.props.searchResultsSms
                  ? this.props.searchResultsSms
                  : this.props.smsList}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  smsList: state.sms.smsList,
  searchResultsSms: state.sms.searchResultsSms,
}))(SmsMain));
