import { RestClientAuthenticated } from '../network';
import {SearchCriteriaType, SearchResultType, ProfileType} from '../../types/InternalBeneficiaryTypes';
import { notifyError, notifySuccess } from '../../network/notification';

const apiEndpoint = {
    searchList: "/internal-beneficiary",
    profile: "/internal-beneficiary/:id"
}

const internalBeneficiaryApi = {
    search: (searchCriteria: SearchCriteriaType) : SearchResultType => (dispatch: Function) => {

        let requestParam = '?pageSize='+searchCriteria.pageSize;

        if(searchCriteria.beneficiaryFirstName) {
            requestParam += '&beneficiaryFirstName='+searchCriteria.beneficiaryFirstName;
        }

        if(searchCriteria.beneficiaryLastName) {
            requestParam += '&beneficiaryLastName='+searchCriteria.beneficiaryLastName;
        }
        
        if(searchCriteria.beneficiaryBirthDate) {
            requestParam += '&beneficiaryBirthDate='+searchCriteria.beneficiaryBirthDate;
        }

        if(searchCriteria.policyHolderFirstName) {
            requestParam += '&policyHolderFirstName='+searchCriteria.policyHolderFirstName;
        }

        if(searchCriteria.policyHolderLastName) {
            requestParam += '&policyHolderLastName='+searchCriteria.policyHolderLastName;
        }

        if(searchCriteria.policyNumber) {
            requestParam += '&policyNumber='+searchCriteria.policyNumber;
        }

        if(searchCriteria.protocolNumber) {
            requestParam += '&protocolNumber='+searchCriteria.protocolNumber;
        }

        return new RestClientAuthenticated(`${apiEndpoint.searchList+requestParam}`, 'GET').execute().then(response => (response.status == 200) && response.json() ).catch((e) => dispatch(notifyError)(e));
    },
    fetchProfile: (profileId: number) : ProfileType => (dispatch: Function) => {
        return new RestClientAuthenticated(`${apiEndpoint.profile.replace(':id', profileId)}`, 'GET').execute().then(response => (response.status == 200) && response.json() ).catch((e) => dispatch(notifyError)(e));
    },
    updateProfile: (profileId: number, profileRequest :ProfileType) : ProfileType => (dispatch: Function) => {
        return new RestClientAuthenticated(`${apiEndpoint.profile.replace(':id', profileId)}`, 'PUT').jsonBody(profileRequest).execute().then(response => { 
            if(response.status == 200) {
                dispatch(notifySuccess)();
                return true;
            }
        }).catch((e) => dispatch(notifyError)(e));
    },
    deleteProfile: (profileId: number) : ProfileType => (dispatch: Function) => {
        return new RestClientAuthenticated(`${apiEndpoint.profile.replace(':id', profileId)}`, 'DELETE').execute().then(response => (response.status == 200) ).catch((e) => dispatch(notifyError)(e));
    }
};

export default internalBeneficiaryApi;