// @flow

import React from 'react';
import Dropzone from 'react-dropzone';
import '../font-awesome-5/lib/css/all.css';
import './input-file.css';

type
  Props = {
  input: {
    value: ?{
      filename: string,
      base64: string,
      url: string,
    },
    onChange: ?Function,
  },
  className: ?string,
  wordings: ?{
    selectFile: string,
  },
  actions: ?[React.Component],
  buttonClassName: ?string,
  accept: ?string | ?string[],
  icon: ?string,
  onDropAccepted: ?Function,
  onDropRejected: ?Function,
}
type
  State = {
  onDrag: Boolean,
}

class InputFile extends React.Component<Props, State> {
  state = {
    onDrag: false,
  };

  simulateClick = () => {
    this.dropzoneRef.open();
  };

  clearFile = () => {
    this.props.input.onChange(null);
  };

  handleFileInput = (file: Object) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsBinaryString(file);
    } else {
      return;
    }
    const filename = file.name;
    reader.onload = () => {
      this.props.input.onChange({ filename, base64: btoa(reader.result) });
    };
  };

  onDrop = (files) => {
    this.setState({ onDrag: false });
    this.handleFileInput(files[0]);
  };

  areImageAwaited = () => this.props.accept && this.props.accept.some(acceptType => acceptType.match('image'));

  showImageOrIcon = (filename: string, data: string) => {
    if (this.areImageAwaited()) {
      return (
        <img
          className="image"
          src={data}
          alt={filename}
        />
      );
    }

    return (
      <React.Fragment>
        <i className={this.props.icon} />
        <span className="text">{filename}</span>
      </React.Fragment>
    );
  };

  findPreviewTest = () => {
    if (this.props.input.value && this.props.input.value.base64) {
      return this.showImageOrIcon(this.props.input.value.filename, `data:image;base64,${this.props.input.value.base64}`);
    }
    if (this.props.input.value && this.props.input.value.url) {
      return this.showImageOrIcon(this.props.input.value.filename, this.props.input.value.url);
    }
    if (this.props.input.value) {
      return this.showImageOrIcon('', this.props.input.value);
    }
    return (
      <div className="previewText">
        <div>
          {this.props.wordings.dragFile}
        </div>
        <div className="wlf-button">
          {this.props.wordings.selectFile}
        </div>
      </div>
    );
  };
  findDownloadLink = () => {
    if (this.props.input.value.base64) {
      return `data:image;base64,${this.props.input.value.base64}`;
    }
    return this.props.input.value && this.props.input.value.url ?
      this.props.input.value.url : this.props.input.value;
  };

  render() {
    return (
      <div className={this.props.className}>
        <div className={`input-file ${this.props.input.value ? '' : 'no-image'}`}>
          {
            this.props.input.value &&
            (this.props.actions ?
                <div className="container-actions">
                  {
                    this.props.actions.map(action => action)
                  }
                </div> :
                <div className="container-actions">
                  <a
                    href={this.findDownloadLink()}
                    download={this.props.input.value.filename ?
                      this.props.input.value.filename : true}
                    className={this.props.buttonClassName}
                  >
                    <i className="fa fa-download" />
                  </a>
                  <button type="button" onClick={() => this.simulateClick()} className={this.props.buttonClassName}>
                    {this.props.wordings.edit}
                    <i className="fa fa-edit icon" />
                  </button>
                  <button type="button" onClick={() => this.clearFile()} className={this.props.buttonClassName}>
                    {this.props.wordings.delete}
                    <i className="fa fa-trash icon" />
                  </button>
                </div>
            )
          }
          <Dropzone
            accept={this.props.accept}
            onDragLeave={() => this.setState({ onDrag: false })}
            onDragEnter={() => this.setState({ onDrag: true })}
            ref={(div) => {
              this.dropzoneRef = div;
            }}
            style={{}}
            className="drop-input"
            onDrop={this.onDrop}
            onDropAccepted={this.props.onDropAccepted}
            onDropRejected={this.props.onDropRejected}
          >
            {
              this.state.onDrag &&
              <div className="on-drag">
                {this.props.wordings.dropHere}
              </div>
            }
            <div className="preview">
              {
                this.findPreviewTest()
              }
            </div>
          </Dropzone>
        </div>
      </div>
    );
  }
}

InputFile.defaultProps = {
  className: 'input-file-component',
  accept: ['image/*'],
  buttonClassName: 'input-button',
  icon: 'far fa-file',
  wordings: {
    edit: 'edit',
    delete: 'delete',
    dropHere: 'Drop your file here',
    dragFile: 'Drag and drop file to upload',
    selectFile: 'Or select a file',
  },
};

export default InputFile;
