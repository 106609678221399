// @flow
import React from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { CaseStatus as CaseStatusEnum, CommunicationChannel, CommunicationStatus, UserRole } from '../../enum';
import { currentSessionService } from '../../services/sessionServiceInstance';

type Props = {
  caseDateCreation: string,
  sessionStartDate: string,
  status: string,
  userName: string,
  userRole: string,
  channel: string,
  liveStatus: string,
  appointment: boolean,
  blinkClassName: boolean,
};

type State = {
  waitingTime: string,
  displayWaitTime: boolean,
}

class CaseStatus extends React.Component<Props, State> {
  state = {
    waitingTime: '',
    displayWaitTime: false,
  };

  interval: IntervalID;

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setWaitingTime();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  displayIcones = () => this.props.status === CaseStatusEnum.REQUIRED
    && !(this.props.channel === CommunicationChannel.PHONE
      && this.props.userRole === UserRole.NURSE_LEVEL)
    && this.props.liveStatus !== CommunicationStatus.DROPPED

  getIcon = () => (this.props.userRole === UserRole.NURSE_LEVEL ? 'fa-syringe' : 'fa-stethoscope');

  getColor = () => {
    if ((this.props.status === CaseStatusEnum.REQUIRED
      || this.props.status === CaseStatusEnum.ASSIGNED)) {
      return 'required-color';
    }

    return '';
  };

  getMessage = () => {
    if (!this.props.appointment) {
      if (this.props.status === CaseStatusEnum.REQUIRED &&
        this.props.liveStatus !== CommunicationStatus.DROPPED) {
        if (this.props.userRole === UserRole.NURSE_LEVEL
          && this.props.channel !== CommunicationChannel.PHONE) {
          return I18n.t('dashboard.CASE_STATUS_NURSE_REQUIRED');
        }
        if (this.props.userRole === UserRole.DOCTOR_LEVEL) {
          return I18n.t('dashboard.CASE_STATUS_DOCTOR_REQUIRED');
        }
      }
    }

    if (this.props.status === CaseStatusEnum.ASSIGNED) {
      return I18n.t('dashboard.ASSIGNED_TO');
    }

    if (this.props.status === CaseStatusEnum.TAKEN_IN_CHARGE) {
      return I18n.t('dashboard.TAKEN_IN_CHARGE_BY');
    }
    return '';
  };

  setWaitingTime = () => {
    /*
    1. Pour les cas classiques
        - Channel Video /Chat : le calcul est fait avec a valeur sessionStartDate => Car foncitionnellement, il faut démarrer une session video et chat avec le patient
        - Channel phone : le calcul est fait avec Creation date => Foncitonnellement : le patient est présent au téléphone avec le soignant dès la création du cas sur le dashboard
    2. Pour les rendez-vous :
        - Tous channels confondue on prends la date de StartDateAppointment   (=> en front on prends creationDtae qui est remplis en back par la valeur du startDateAppointment récupéré du cas créé par le PDA)
     */
    if(!this.props.appointment) {
      if (this.props.channel === CommunicationChannel.VIDEO
        || this.props.channel === CommunicationChannel.CHAT) {
        this.setState({
          waitingTime: moment(moment().diff(moment(this.props.sessionStartDate).utc())).utc().format(I18n.t('date.DATE_HOUR')),
        });
      } else {
        this.setState({
          waitingTime: moment(moment().diff(moment(this.props.caseDateCreation).utc())).utc().format(I18n.t('date.DATE_HOUR')),
        });
      }
    } else {
      this.setState({
        waitingTime: moment(moment().diff(moment(this.props.caseDateCreation).utc())).utc().format(I18n.t('date.DATE_HOUR')),
      });
    }

    if (moment().isSameOrAfter(moment(this.props.caseDateCreation))
      && (this.props.liveStatus || this.props.appointment)) {
      this.setState({ displayWaitTime: true });
    } else {
      this.setState({ displayWaitTime: false });
    }
  };

  displayWaitingTime = () => {
    if (this.props.status !== CaseStatusEnum.TAKEN_IN_CHARGE
      && this.props.liveStatus !== CommunicationStatus.IN_PROGRESS
      && this.props.liveStatus !== CommunicationStatus.DROPPED) {

      // Si c'est au docteur d'apppeler alors le timer est set à la création du cas
      if (currentSessionService().currentUser().doctorShouldCallback
        && this.props.channel === CommunicationChannel.PHONE) {
        return this.state.waitingTime;
      }

      // Si c'est au patient d'apppeler ou qu'il a abandonné la session
      // alors il n' a pas de timer, l'action est au patient
      if ((this.props.channel === CommunicationChannel.PHONE && !this.props.appointment)
        || this.props.liveStatus === CommunicationStatus.DROPPED) {
        return '';
      }

      // Une action est en attente du docteur
      // apres une action du patient (session video, chat ou rendez-vous)
      if (this.state.displayWaitTime) {
        return (
          <div>
            <span className="waiting-label">{I18n.t('dashboard.WAITING_SINCE')} </span> {this.state.waitingTime}
          </div>
        );
      }
      // Si c'est un rendez-vous telephonique et pas de this.state.displayWaitTime
      // L'heure du rendez vous n'est pas encore atteinte
      if (this.props.channel === CommunicationChannel.PHONE && this.props.appointment
        && moment(this.props.caseDateCreation).isAfter(moment().format(I18n.t('date.DATE_LONG')))) {
        return (
          I18n.t('dashboard.NOT_APPOINTMENT_TIME')
        );
      }

      // Si pas de displayWaitTime et pas un appel telephonique,
      // alors en attente de session (video ou chat)
      return (
        I18n.t('dashboard.NO_SESSION')
      );
    } // si le cas est déjà pris en charge, pas de timer
    return '';
  };

  displayNameOfReference = () => {
    if (this.props.status === CaseStatusEnum.TAKEN_IN_CHARGE
      || this.props.status === CaseStatusEnum.ASSIGNED) {
      return this.props.userName;
    }
    return '';
  };

  render() {
    return (
      <div className={`case-status ${this.getColor()}`}>
        {((this.props.appointment && this.state.displayWaitTime)
          || !this.props.appointment) && this.displayIcones() &&
        <i className={`status-icon ${this.props.blinkClassName ? ' blink' : ''} fa ${this.getIcon()}`}/>
        }
        <div>
          <div className={`required ${this.props.blinkClassName ? ' blink' : ''}`}> {this.getMessage()}</div>
          <div className={`waiting ${this.props.blinkClassName ? ' blink' : ''}`}>
            {this.displayNameOfReference()}
            {!this.props.appointment && !this.displayNameOfReference() && this.displayWaitingTime()}
          </div>
          {(this.props.appointment && this.state.displayWaitTime) &&
          <div className={`waiting ${this.props.blinkClassName ? ' blink' : ''}`}>{this.displayWaitingTime()}</div>
          }
        </div>
      </div>
    );
  }
}

export default (CaseStatus);
