import { combineReducers } from 'redux';
import { reducer as notificationsReducer } from 'reapop';
import { i18nReducer } from 'react-redux-i18n';
import loginReducer from './login/loginReducer';
import roleReducer from './roles/roleReducer';
import userReducer from './users/userReducer';
import historyReducer from './history/historyReducer';
import customNotificationReducer from './notifications/notificationReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import processReducer from './process/processReducer';
import businessUnitReducer from './businessUnit/businessUnitReducer';
import mailReducer from './mail/mailReducer';
import smsReducer from './sms/smsReducer';

export default combineReducers({
  dashboard: dashboardReducer,
  process: processReducer,
  login: loginReducer,
  role: roleReducer,
  user: userReducer,
  history: historyReducer,
  i18n: i18nReducer,
  notifications: notificationsReducer(),
  customNotification: customNotificationReducer,
  businessUnit: businessUnitReducer,
  mail: mailReducer,
  sms: smsReducer,
});
