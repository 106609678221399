const fr = {
  brand: 'ePRM',
  AUTHENTIFICATION_EXPIRED: 'Votre session a expiré. Merci de vous reconnecter',
  aside: {
    nav: {
      WORKPLACE: 'ACCUEIL',
      ADMIN: ' ADMINISTRATIF',
      SHORTCUT: 'LIENS UTILES',
      DATABASE: 'BASE DE DONNEES',
      HOME: 'Accueil',
      USERS: 'Utilisateurs',
      ROLES: 'Rôles',
      LOG: 'Log API',
      HISTORY: 'Historique',
      SYSTEM: 'Système',
      SCHEDULED: 'Tâches planifiées',
      LOGS: 'Niveaux de traces',
      DASHBOARD: 'Tableau de bord',
      APPOINTMENTS: 'Rendez-vous',
      PATIENTS: 'Patients',
      CASES: 'Dossiers',
      AVAILABILITIES: 'Disponibilités',
      EXPORTS: 'Exports',
      OPERATIONS: 'Operations',
      USER_MANAGEMENTS: 'Management des utilisateurs',
      ADVICE_CODING: 'Codage téléconseil',
      PATHOLOGY_CODING: 'Codage pathologique',
      PHARMACIES: 'Pharmacies',
      BUSINESS_UNIT: 'Business unit',
      INTERNAL_BENFICIARY: 'Internal Beneficiary',
      B_PARTNER: 'B-partenaire',
      LOGO: 'Logo',
      PROTOCOL: 'Protocole',
      LEGAL_ENTITY: 'Entité légale',
      BRAND: 'Marque',
    },
  },
  lastDocuments: {
    CONSULT: 'Téléconsultation',
    ADVICE: 'Téléconseil',
    formatDate: 'DD/MM/YYYY',
    DOWNLOAD: 'Télécharger',
    caseNumber: 'Dossier n°',
    Prescription: 'Prescription',
    Report: 'Compte Rendu',
    PatientDocument: 'Document du patient',
    SEND: 'Lien sécurisé',
  },
  channel: {
    VIDEO: 'Vidéo',
    PHONE: 'Téléphone',
    CHAT: 'Chat',
  },
  role: {
    NURSE: 'Infirmier',
    DOCTOR: 'Médecin',
    NURSE_LEVEL: 'Infirmier', // TODO : à virer !!
    DOCTOR_LEVEL: 'Médecin', // TODO : à virer !!
    ADMIN: 'Administrateur',
  },
  button: {
    TAKE_IN_CHARGE: 'Prendre en charge',
    TITLE_POPIN: 'Prendre en charge',
    OPEN_CASE: 'Ouvrir le dossier',
    CONSULT_ONLY: 'Consulter',
    CANCEL: 'Annuler',
    ADD: 'Créer les disponibilités',
    CONTINUE_TRANSFER: 'Continuer',
    END_CALL: 'Retour tableau de bord',
    SEARCH: 'Rechercher',
    ARCHIVE: 'Archiver',
    CONTINUE: 'Continuer',
    PREVIOUS: 'Retour',
    SELECT: 'Sélectionner',
    SKIP_ELIGIBILITY: 'Contourner l\'éligibilité',
    CREATE: 'Créér un nouveau dossier',
    BACK_TO_DASHBOARD: 'Retour au tableau de bord',
    ABORT: 'Terminer l\'appel',
    DOWNLOAD: 'Télécharger',
    EDIT: ' Modifier',
    SEND: 'Envoyer',
    NEXT: 'Suivant',
    SAVE: 'Sauvegarder',
    NEW_PATIENT: 'Créer un nouveau patient',
    VALIDATE: 'VALIDER',
    SEND_VIDEO_LINK_EMAIL: 'Envoyer le lien vidéo',
    START_VIDEO: 'Démarrer la vidéo',
    DELETE: 'SUPPRIMER',
    UPDATE: 'Mise à jour',
    RESET: "Réinitialiser",
    DETAILS: "DETAILS",
    IMPORT: "IMPORT",
    TEMPLATE: "TEMPLATE"
  },
  communicationType: {
    video: 'Vidéo',
    phone: 'Téléphone',
    chat: 'Chat',
  },
  activeStatus: {
    ACTIVE: 'Ouvert',
    INACTIVE: 'Archivé',
    CLOSED: 'Fermé',
  },
  loader: {
    text: 'Veuillez patienter durant le chargement de la liste des dossiers...',
  },
  dashboard: {
    TITLE: 'Tableau de bord',
    AVERAGE_WAIT: 'Temps d\'attente PDA',
    MINUTES: 'minute(s)',
    OPENED_CASES: '%{count} dossier ouvert',
    OPENED_CASES_0: '%{count} dossier ouvert',
    OPENED_CASES_plural: '%{count} dossiers ouverts',
    NEW_CASE: 'Nouveau dossier',
    filters: {
      TITLE: 'Filtres',
      VIEW: 'Profil',
      ASSIGNED: 'Assigné à',
      CALL_TYPE: 'Type d\'appel',
      SPECIALITY: 'Spécialité',
      APPOINTMENT: 'Rendez-vous',
    },
    NOT_APPOINTMENT_TIME: 'Ce n\'est pas encore l\'heure',
    NO_SESSION: 'Session non démarrée.',
    WAITING_SINCE: 'Attend depuis',
    CASE_STATUS_NURSE_REQUIRED: 'Infirmier requis',
    CASE_STATUS_DOCTOR_REQUIRED: 'Médecin requis',
    CASE_STATUS_ASSIGNED: 'assigné',
    ASSIGNED_TO: 'Assigné à',
    CASE_STATUS_TAKEN: 'Pris en charge',
    TAKEN_IN_CHARGE_BY: 'Pris en charge par',
    modal: {
      TAKE_IN_CHARGE: 'Souhaitez-vous consulter le dossier ou prendre en charge le patient ?',
    },
  },
  header: {
    PROFILE: 'Profil',
    LOGOUT: 'Se déconnecter',
    availability: {
      AVAILABLE: 'Disponible',
      BUSY: 'Occupé(e)',
    },
  },
  caseStatus: {
    NOT_AVAILABLE: 'Non disponible',
    WAITING: 'En Attente',
    IN_PROGRESS: 'En cours',
    COMPLETED: 'Terminé',
    DROPPED: 'Abandonné',
    NOT_STARTED: 'Session non démarrée',
  },
  filterBar: {
    CHAT: 'Chat',
    PHONE: 'Téléphone',
    VIDEO: 'Vidéo',
    ALL: 'Tous',
    LATER: 'Plus tard',
    TODAY: 'Aujourd\'hui',
    DOCTOR_LEVEL: 'Médecin',
    NURSE_LEVEL: 'Infirmier(e)',
  },
  processCaseHeader: {
    CLOSE: 'Fermer',
    TITLE: 'Dossier médical créé le:',
    AUTO_SAVED: 'Enregistré !',
    AUTO_SAVING: 'Enregistrement...',
    YEARS: 'ans ',
    ASSIGNED: 'Assigné à',
    TAKEN_IN_CHARGE: 'Pris en charge par',
    options: {
      ASSIGN: 'Assigner',
      TAKE: 'Prendre en charge',
    },
    modal: {
      ASSIGNED_TITLE: 'Assigner un soignant pour ce dossier',
      ASSIGNED_TEXT: 'Vous pouvez sélectionner un soignant pour l\'assigner à ce dossier',
      USERS: 'Soignant',
      BUTTON: 'Assigner',
    },
    backToDashboard: {
      BACK_TITLE: 'Retour au tableau de bord',
      BACK_TEXT: 'Voulez-vous vraiment revenir au tableau de bord ?',
    },
    steps: {
      IDENTIFICATION: 'Identification',
      ELIGIBILITY: 'éligibilité',
      PATIENT_DETAILS: 'Dossier Administratif',
      CONSENTS: 'Consentements',
      QUALIFICATION: 'Qualification de l\'appel',
      TELECONSULTATION: 'Télé-consultation',
      DOCUMENTS: 'Envoi des documents',
    },
  },
  processCase: {
    identification: {
      SELECT_BPARTNER: '1. Sélectionnez le type de B-Partner : ',
      SELECT_PROTOCOL: 'Sélectionnez le protocole à utiliser pour le traitement du cas :',
      CHOOSE_PATIENT: 'Choix du patient',
      SELECT_PATIENT: 'Veuillez choisir un patient pour le traitement du cas : ',
      NEW_POLICY: 'Ajout d\'une nouvelle référence de carte bancaire (police) ',
      NEW_POLICY_QUESTION: 'La référence de la carte n\'existe pas encore pour ce patient',
      NEW_PATIENT: 'Les informations ci-dessus ne correspondent pas au patient que vous recherchez ? Vous pouvez créer un nouveau patient : ',
      PLACEHOLDER_BP: 'Sélectionnez le protocole',
      EXPLANATION: 'Vérification de l\'identité du patient',
      TELECONSULT_QUESTION: '1. Appelez-vous pour une téléconsultation ?',
      BENEFICIARY_QUESTION: '2. Cette consultation est-elle pour vous-même ?',
      CALLER_INFORMATION_QUESTION: '3. Pouvez-vous me donner votre identité ?',
      BANK_CARD_HOLDER_QUESTION: '3. Etes-vous le titulaire de la carte bancaire ?',
      VISA_CALLER_INFORMATION_QUESTION: '2. Pouvez-vous me donner votre identité ?',
      RELATIVE_INFORMATION_QUESTION: '3. Pouvez-vous me donner l\'identité du patient ?',
      ERROR_TITLE: 'Information patient',
      ERROR_MESSAGE: 'Vous êtes au service de téléconsultation. Nous ne pouvons pas vous aider pour ce type de demande.',
      CONFIRM_ADDING_NEW_POLICY_MESSAGE: 'Ce patient existe déjà avec un autre numéro BIN.',
      CONFIRM_ADDING_NEW_POLICY_MESSAGE2: 'Dès votre validation, le BIN %{binNumber} sera enregistré.',
      BANK_CARD_IS_INVALID: 'Votre carte bancaire n\'inclut pas la téléconsultation médicale',
    },
    eligibility: {
      result: {
        NOT_EXIST_AND_FOUND: 'Le patient est éligible mais n\'a pas encore de dossier dans le EPRM. Pour le créer, merci de sélectionner une police.',
        EXIST_AND_ELIGIBLE: 'Un dossier médical existe pour ce patient. Merci de sélectionner une police éligible pour débuter une nouvelle téléconsultation.',
        EXIST_AND_NOT_ELIGIBLE: 'Désolé, la téléconsultation n’est pas inclue dans le(s) contrat(s) ci-dessous.',
        EXIST_AND_EXPIRED: 'Le patient a été trouvé mais le(s) contrat(s) au(x)quel(s) il est rattaché ne sont plus valide(s)',
        EXIST_AND_OPEN_CASES: 'Le patient est éligible et une (ou des) téléconsultation(s) sont encore ouverte(s). Merci de sélectionner une consultation en cours ou d\'en créer une nouvelle.',
        NOT_EXIST_AND_NOT_ELIGIBLE: 'Patient non éligible avec les informations suivantes :',
        WARNING_TOO_LARGE: 'Attention, votre recherche n\'est pas assez précise, seuls les premiers resultats sont remontés',
        WARNING_TOO_MANY_PATIENTS: 'Attention, votre recherche n\'est pas assez précise, seuls les resultats des dix premiers patients de l\'ePRM sont remontés.',
        WARNING_ONLY_PRM: 'Attention, votre recherche ne concerne que les patients présents dans l\'ePRM. Pour tester également l\'éligibilité du patient, veuillez saisir son prénom et son nom, ainsi que sa date de naissance ou son numéro de police.',
        WARNING_GATEWAY_DOWN: ' Suite à un problème technique, l’éligibilté du patient n’a pas pu être vérifiée. Il est possible de passer l’éligibilité du patient pour continuer la consultation.',
      },
      EXPLANATION_COLLECT_INFO: 'Merci de vérifier les informations ci-dessous :',
      question: {
        IS_POLICY_HOLDER: '2. Le contrat est-il au nom du patient ?',
        CHECK_SPELLING: '1. Le nom est-il bien orthographié comme dans le contrat ?',
        CHANGE_INFORMATION: '2. Merci de corriger les informations erronées',
      },
      SKIP_ELIGIBILITY: 'Êtes-vous sûr de vouloir contourner le processus d\'éligibilité ?',
      ELIGIBLE: 'Eligible',
      NOT_ELIGIBLE: 'Non éligible',
      START_DATE: 'Commence le ',
      EXPIRATION_DATE: 'Expire le ',
      ABORT_TEXT: 'Voulez-vous vraiment terminer l\'appel et revenir au tableau de bord ?',
    },
    UNDER_18: 'Si l’appelant représente un patient mineur, demander le livret de famille',
    FOREIGN_CALL: 'Attention: le patient appelle de l\'étranger',
  },
  form: {
    CM_CIC: 'Crédit mutuel/CIC',
    OTHER: 'Autre',
    VISA: 'Visa',
    NAME: 'Nom',
    FIRST_NAME: 'Prénom',
    DATE_OF_BIRTH: 'Date de naissance',
    POLICY_NUMBER: 'Numéro du contrat',
    BIN_NUMBER: 'Les neuf premiers chiffres du numéro de la carte bancaire',
    PATIENT_NAME: 'Nom du patient',
    BANK_CARD_HOLDER_FIRST_NAME: 'Prénom du titulaire de la carte bancaire',
    BANK_CARD_HOLDER_LAST_NAME: 'Nom du titulaire de la carte bancaire',
    YES: 'Oui',
    NO: 'Non',
    OR: 'Ou',
    STAR_USE: 'Vous pouvez utiliser * pour chercher un nom composé',
    DATE_IN_FUTURE: 'Date invalide',
    validator: {
      DATE_IN_FUTURE: 'Date invalide',
      DATE_FORMAT: 'La date n\'est pas au bon format',
      DATE_COMPARE: 'La date de début est antérieure à la date de fin',
      VALIDATE_EMAIL: 'Email invalide',
      VALIDATE_LIST: 'Liste vide',
      VALIDATE_PHONE: 'Numéro de téléphone incorrect',
      VALIDATE_PHONE_NO_0: 'Le numéro ne doit pas avoir de 0 juste après le code pays',
      REQUIRED: 'Requis',
      TOO_SHORT: 'La recherche doit comporter au moins trois caractères',
      VALIDATE_IDENTICAL_VALUES: 'Merci de saisir différents destinataires pour le lien sécurisé et le mot de passe unique.',
      VALIDATE_JUST_ONE_VALUES: 'Ne pas utiliser deux méthodes de transfert pour la même information.',
      VALIDATE_SLOT_DURATION: 'Un slot doit durer au minimum : %{duration} min',
      START_DATE_BEFORE_NOW: 'La date de début de doit pas être antérieur à la date d\'aujourd\'hui',
      START_DATE_TO_EARLY: 'Les rendez-vous ne peuvent être pris avant : %{startMaxDate}h00',
      END_DATE_TO_LATE: 'Les rendez-vous ne peuvent être pris après : %{endMaxDate}h00',
      CONFIRM_PASSWORD: 'Les mots de passe \'Mot de passe\' et \'Confirmation du mot de passe\' doivent être identiques',
      REQUIRED_DIGITS: 'Les neuf premiers chiffres sont obligatoires',
    },
    placeholder: 'JJ/MM/AAAA',
  },
  login: {
    ePRM: 'ePRM',
    IDENTIFIER: 'Identifiant',
    PASSWORD: 'Mot de passe',
    TITLE: 'Veuillez vous identifier',
    SIGNIN: 'Se connecter',
    FORGOT_PASSWORD: 'Mot de passe oublié ?',
    sms: {
      CODE: 'Merci de renseigner le code reçu par SMS',
      OTP: 'Code',
      NEXT: 'Suivant',
    },
    email: {
      CODE: 'Merci de renseigner le code reçu par email',
      OTP: 'Code',
      NEXT: 'Suivant',
    },
    alternatives: {
      EMAIL: 'Recevoir le code par email ?',
      SMS: 'Recevoir le code par SMS ?',
    },
    enroll: {
      TITLE: 'Faire confiance à votre navigateur',
      INSTRUCTIONS: 'Faites-vous confiance à votre navigateur ?',
      YES: 'Oui',
      NO: 'Non',
    },
  },
  users: {
    TITLE: 'Utilisateurs',
    SEARCH_TITLE: 'Recherche d\'utilisateurs',
    LIST_TITLE: 'Liste des utilisateurs',
    USERNAME: 'Identifiant',
    EMAIL: 'E-mail',
    FIRSTNAME: 'Prénom',
    LASTNAME: 'Nom',
    PASSWORD: 'Mot de passe',
    PASSWORD_CONFIRM: 'Confirmation du mot de passe',
    BUSINESS_UNIT: 'Business Unit',
    ROLE: 'Rôle',
    TYPE: 'Type',
    TWO_FACTOR: 'Authentification double facteur',
    PHONE: 'Téléphone',
    type: {
      NURSE: 'Infirmier(e)',
      DOCTOR: 'Docteur',
    },
    CREATION_DATE: 'Date de création',
    NEW_TITLE: 'Création d\'un utilisateur',
    EDIT_TITLE: 'Edition de l\'utilisateur %{username}',
    DELETE_CONFIRM: 'Supprimer l\'utilisateur ?',
  },
  roles: {
    TITLE: 'Rôles',
    LABEL: 'Nom',
    PERMISSIONS: 'Autorisations',
    ENABLED: 'Autorisations actives',
    AVAILABLE: 'Autorisations disponibles',
    LIST_TITLE: 'Rôles',
    DETAIL_TITLE: 'Edition du rôle %{label}',
    NEW_TITLE: 'Creation d\'un rôle',
    DELETE_CONFIRM: 'Supprimer le rôle',
  },
  history: {
    TITLE: 'Historique des actions',
    SEARCH_TITLE: 'Recherche de logs',
    LIST_TITLE: 'Liste des logs',
    USERNAME: 'Identifiant utilisateur',
    ID_ITEM: 'Identifiant de l\'objet',
    TABLE: 'Table',
    MESSAGE: 'Message',
    MODIFICATION_TYPE: {
      TITLE: 'Type de modification',
      CREATE: 'Création',
      UPDATE: 'Modification',
      DELETE: 'Suppression',
    },
    DATE: 'Date',
  },
  scheduled: {
    TITLE: 'Tâches planifiées',
    TASKNAME: 'Nom de la tâche',
    FREQUENCY: 'Fréquence',
    EXECUTIONS: 'Nombre d\'exécutions',
    PREVIOUS_START: 'Début dernière exécution',
    PREVIOUS_END: 'Fin dernière exécution',
    NEVER_EXECUTED: 'Encore jamais exécuté',
    DURATION: 'Temps d\'exécution : %{duration} ms',
    NEXT: 'Prochaine exécution',
    STATUS: 'Statut',
    START: 'Lancer la tâche',
    threads: {
      TITLE: 'Statistiques threads',
      ACTIVE: 'Nombre de threads actives : ',
      INACTIVE: 'Nombre de threads inactives : ',
      MIN: 'Nombre minimum de threads : ',
      MAX: 'Nombre maximum de threads : ',
      LARGEST_POOL: 'Plus grand nombre de threads dans le pool : ',
    },
    DATE_FORMAT: 'DD-MM-YYYY kk:mm:ss',
  },
  logs: {
    TITLE: 'Gestion des niveaux de traces',
    ADD_TITLE: 'Ajouter un traceur',
    SEARCH_TITLE: 'Rechercher un traceur',
    DEFAULT_TITLE: 'Traceurs par défaut',
    CHANGED_TITLE: 'Traceurs modifiés',
    NAME: 'Nom du traceur',
    LEVEL: 'Niveau',
    EMPTY: 'Aucun traceur',
    SELECT_TRACE: 'Trace',
    SELECT_INACTIVE: 'Inactive',
    SELECT_OFF: 'Off',
    SELECT_INFO: 'Info',
    SELECT_DEBUG: 'Debug',
    SELECT_ERROR: 'Error',
    SELECT_WARN: 'Warn',
  },
  actions: {
    BACK: 'Retour',
    CANCEL: 'Annuler',
    SAVE: 'Enregistrer',
    DELETE: 'Supprimer',
    NEW: 'Nouveau',
    CONFIRM: 'Confirmer',
    SEARCH: 'Rechercher',
    ADD: 'Ajouter',
    CREATE_APPOINTEMENT: 'Créer le rendez-vous',
    REDIRECT_TO_RELATED_CASE: 'Etre redirigé vers le cas lié',
    CONTINUE_TELECONSULT: 'Continuer la téléconsultation en cours',

  },
  message: {
    SUCCESS: 'Les modifications ont bien été prises en compte',
    ERROR: 'Attention : les modifications n\'ont pas été enregistrées',
  },
  wsError: {
    INTERNAL_ERROR: 'Erreur de connexion',
    SERVER_CONNECTION: 'Impossible de se connecter au serveur HTTPS',
    AUTHENTICATION_EXPIRED: 'Session d\'administration expirée',
    FIELD_REQUIRED: 'Le champ \'%{field0}\' est requis',
    COLOR_INVALID: 'Le champ \'%{field0}\' doit être une couleur au format hexadecimale : #ff00ff',
    EMAIL_INVALID: 'L\'adresse e-mail \'%{field0}\' n\'est pas valide',
    USERNAME_ALREADY_EXISTS: 'Cet identifiant est déjà utilisé sur un autre compte, veuillez en choisir un autre',
    EMAIL_ALREADY_EXISTS: 'Cette adresse e-mail est déjà utilisée sur un autre compte, veuillez en choisir une autre',
    PASSWORDS_DIFFERENT: 'Les mots de passe \'%{field0}\' et \'%{field1}\' doivent être identiques',
    WRONG_LOGIN_OR_PASSWORD: 'Nom d\'utilisateur ou mot de passe incorrect',
    TOO_MANY_WRONG_ATTEMPS: 'Suite à des erreurs dans la saisie de vos identifiants, votre compte est verrouillé pendant %{field0} minutes, veuillez-vous reconnecter ultérieurement',
    ROLE_LABEL_EXISTS: 'Il existe déjà un rôle avec ce libellé',
    PASSWORD_TOO_SHORT: 'Le mot de passe est trop court, il doit faire au moins %{field0} caractères',
    MESSAGE: '%{field0}',
    OTP_INVALID: 'Code invalide',
    RESET_PASSWORD_UNAVAILABLE: 'Contactez l\'administrateur pour réinitialiser votre mot de passe.',
    RATE_LIMITED: 'Veuillez patienter avant de rééssayer.',
    API_PRM_ERROR: 'Le PRM n\'est pas accessible actuellement',
    RELOAD_PAGE: 'Veuillez recharger la page et recommencer.',
    BPARTNER_NOT_FOUND_FOR_BU: 'Le BPartner de ce dossier n\'a pas été retrouvé pour votre BU',
    BPARTNER_CODE_HAS_EXIST: 'B-Partner code has exist',
    RECORDING_ERROR: 'Une erreur lié au verrouillage du cas a eu lieu',
    CONTRACT_HAS_EXIST: 'La police existe déjà',
    INVALID_BENEFICIARY: 'This beneficiary is invalid',
    DATA_NOT_FOUND: 'Not found patient or policy',
    EMAIL_TEMPLATE_NOT_FOUND: 'Found Patient and policy data but not found email template',
    SMS_TEMPLATE_NOT_FOUND: 'Found Patient and policy data but not found sms template',
    EMAIL_MISSING: 'Found Patient and policy data but thers is not patient\'s email',
    PHONE_NUMBER_MISSING: 'Found Patient and policy data but thers is not patient\'s phone number',
    DUPLICATED_PROTOCOL: 'Protocol number has exist',
    DUPLICATED_BUSINESS_UNIT: 'Business unit has exist',
    BUSINESS_UNIT_NOT_FOUND: 'Business unit not found',
    BRAND_NOT_FOUND: 'Brand unit not found',
    LOGO_NOT_FOUND: 'Logo unit not found',
    LEGAL_ENTITY_HAS_EXIST: 'Legal entity has exist',
    PROTOCOL_NOT_FOUND: 'Protocal not found!',
  },
  apiLog: {
    TITLE: 'Logs',
    request: 'requête',
    response: 'réponse',
    header: 'Header',
    body: 'Body',
    apiFilter: 'Filtrer par nom d\'API :',
    statusCodeFilter: 'Filtrer par statut :',
    download: 'Télécharger',
    noContent: 'Pas de résultats',
    empty: 'Cette API ne contient ni headers ni bodies pour la requête et la réponse. ',
    emptyBody: 'Body vide',
    emptyHeader: 'Header vide',
  },
  date: {
    long: 'DD MMMM YYYY HH:mm',
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    DATE_FORMAT: 'DD/MM/YYYY',
    DATE_FORMAT_ADMINISTRATION_FILE: 'YYYY-MM-DD',
    DATE_HOUR: 'HH:mm:ss',
    DATE_HOUR_SHORT: 'HH:mm',
    DATE_LONG: 'DD/MM/YYYY HH:mm:ss',
  },
  reactTable: {
    EMPTY: 'Vide',
    PREVIOUS: 'Précédent',
    NEXT: 'Suivant',
    OF: 'sur',
    ROWS: 'lignes',
  },
  businessUnit: {
    TITLE: 'Unité opérationnelle',
    TITLE_LIST: 'Liste unités opérationnelles ',
    SEARCH_TITLE: 'Recherche de Business Units',
    SUBTITLE: 'Liste des Business Units',
    NEW_TITLE: 'Nouvelle Business Unit',
    EDIT_TITLE: 'Editer BusinessUnit',
    TITLE_CONFIGURATION: 'Configuration',
    TITLE_ADMIN: 'Administrateur',
    TITLE_CREATE: 'Date de création ',
    languages: {
      ENGLISH: 'EN',
      FRENCH: 'FR',
      SPANISH: 'ES',
      DEUTSH: 'DE',
      ITALIAN: 'IT',
    },
    modal: {
      title: 'Supprimer Business Unit',
      message: 'Voulez-vous vraiment supprimer ?',
    },
    label: {
      // TODO : mettre les bons labels
      name: 'Nom',
      businessUnitName: 'Nom unité opérationnelle ',
      prmId: 'Prm id',
      canSendByFax: 'Dans la page "Envoi des documents", possibilité d\'envoyer les documents par fax à une pharmacie',
      canAccessCmCic: 'Depuis la page d’identification, accès au choix du B-Partner Crédit Mutuel/CIC',
      canCreatePatientInIdentificationPage: 'Depuis la page d’identification, choix entre création ou récupération d’un patient existant',
      canResendMailVideo: 'Possibilité de renvoyer le mail qui contient le lien vidéo au patient (Menu de droite dans le processus de creation de dossier)',
      showChat: 'Afficher le chat dans la barre d’action des pages "Qualification de l\'appel" et "Téléconsultation"',
      showIdentification: 'Afficher l’étape "Identification"',
      showLogoInDocument: 'Afficher le logo du bPartner dans les documents : téléconsultation, téléadvice, prescription',
      showNewCaseButtonInDashboard: 'Afficher le bouton "Nouvel appel" dans le dashboard',
      showQualification: 'Afficher l’étape "Qualification de l\'appel"',
      showTeleAdviceReport: 'Afficher le résumé de l’étape de qualification dans la page téléconsultation',
      showViewFilterInDashboard: 'Afficher les filtres dans le dashboard',
      timezone: 'Fuseau horaire',
      prmIdCountry: 'Prm id country',
      doctorShouldCallback: 'Sur le dashboard, affichage spécifique : 1. temps d’attente et clignotement pour les dossier sans rdv et téléphone pour inciter le médecin à rappeler le patient // 2. Pour un dossier sans rdv, il doit clignoter jusqu\'à ce qu\'il soit pris en charge par un médecin // 3. Pour un dossier avec rdv, il doit clignoter et tenir compte de la nouvelle affectation ',
      recordingsSentToAida: 'Envoi à Aid@ du temps de verrouillage',
      reportAddressedToPatient: 'Dans le rapport de téléconsultation, on s’adresse au patient et non pas à un collègue médecin',
      sendNotificationWhenCaseCreated: "Envoi d’un SMS et d’un e-mail à tous les médecins connectés lors de la création (depuis le PDA) dans le tableau de bord  d'un nouveau dossier par rendez-vous.",
      sendSmsWhenCaseCreated: 'Envoi d’un SMS à tous les utilisateurs connectés lors de la création d’un nouveau dossier dans le dashboard',
      sendNotificationPatientWaitingInitial: "Envoi un SMS et email pour chaque cas à tous les médecins connectés lorsque le patient commence à attendre via chat ou via appel vidéo et pour un rendez-vous (l'heure du rendez-vous doit être dépassée et le patient attend dans le tableau de bord).",
      sendNotificationPatientWaitingGlobal: "Envoi d’un SMS et email à tous les médecins connectés, toutes les 5 minutes, s'il y a au moins 1 cas où le patient attend dans le chat ou l'appel vidéo et pour un rendez-vous (l'heure du rendez-vous doit être dépassée et le patient est en attente dans le tableau de bord",
      showAddressInDocument: 'Affiche l’adresse de l’organisation dans le rapport de téléconsultation',
      showEmailServiceMedicalReport: 'Affiche l’adresse mail de l’organisation dans le rapport de téléconsultation',
      showHospitals: 'Affiche les résultats de la recherche hôpitaux sélectionnés dans le rapport de téléconsultation',
      showNonMedicationNotesFirst: 'Dans le rapport de téléconsultation au moment d’afficher le contenu des prescriptions, afficher d’abord les notes additionnelles puis la liste des médicaments',
      useParentingForCodings: 'Afficher le code parent du code pathologique dans la page d’administration des codes pathologiques en plus du code pathologique (comportement récupéré du PRM)',
      useOneProtocolAndPolicyForTreatingCases: 'Utiliser un protocol et une police pour le traitement de tous les cas',
      showOneConsentInConsentPage: 'Afficher un seul consentement dans la page Consentement',
      makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories: 'Rendre non-obligatoire les champs : Diagnostic coding et ICD10 coding',
      emailFooterTakeInLegalEntityObject: 'Afficher le footer présent dans l\'objet "legal entity" qui est relié au protocole',
      showAlertWhenNoMedicalReport: 'Afficher une alerte dans la page Téléconsultation quand le medical report n\'est pas créé',
      checkEligibilityByVisa: 'Autoriser le choix VISA en création de nouveau cas',
      countryCode: 'Code pays',
      countryName: 'Nom du pays',
    },
      TABLE: {
        BUSINESS_UNIT_NAME: 'Nom unité opérationnelle ',
        COUNTRY: 'Nom du pays',
        COUNTRY_CODE: 'Code du pays',
        ZONE: 'Fuseau horaire',
        STATUS: 'Status',
        PAGINATION_INFO: 'De %{start} à %{end}/%{total}',
      },
    STATUS: {
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif'
    },
    button: {
      ADD: 'Ajout',
      EXPORT: 'Exporter'
    },
    export: {
      businessUnit: 'Nom unité opérationnelle',
      country: 'Nom du pays',
      countryCode: 'Code pays',
      createdOn: 'Date de création',
      timeZone: 'Fuseau horaire',
      canSendByFax: 'Dans la page "Envoi des documents", possibilité d\'envoyer les documents par fax à une pharmacie',
      canAccessCmCic: 'Depuis la page d’identification, accès au choix du B-Partner Crédit Mutuel/CIC',
      canCreatePatientInIdentificationPage: 'Depuis la page d’identification, choix entre création ou récupération d’un patient existant',
      canResendMailVideo: 'Possibilité de renvoyer le mail qui contient le lien vidéo au patient (Menu de droite dans le processus de creation de dossier)',
      showChat: 'Afficher le chat dans la barre d’action des pages "Qualification de l\'appel" et "Téléconsultation"',
      showIdentification: 'Afficher l’étape "Identification"',
      showLogoInDocument: 'Afficher le logo du bPartner dans les documents : téléconsultation, téléadvice, prescription',
      showNewCaseButtonInDashboard: 'Afficher le bouton "Nouvel appel" dans le dashboard',
      showQualification: 'Afficher l’étape "Qualification de l\'appel"',
      showTeleAdviceReport: 'Afficher le résumé de l’étape de qualification dans la page téléconsultation',
      showViewFilterInDashboard: 'Afficher les filtres dans le dashboard',
      doctorShouldCallback: 'Sur le dashboard, affichage spécifique : 1. temps d’attente et clignotement pour les dossier sans rdv et téléphone pour inciter le médecin à rappeler le patient // 2. Pour un dossier sans rdv, il doit clignoter jusqu\'à ce qu\'il soit pris en charge par un médecin // 3. Pour un dossier avec rdv, il doit clignoter et tenir compte de la nouvelle affectation ',
      recordingsSentToAida: 'Envoi à Aid@ du temps de verrouillage',
      reportAddressedToPatient: 'Dans le rapport de téléconsultation, on s’adresse au patient et non pas à un collègue médecin',
      sendNotificationWhenCaseCreated: "Envoi d’un SMS et d’un e-mail à tous les médecins connectés lors de la création (depuis le PDA) dans le tableau de bord  d'un nouveau dossier par rendez-vous.",
      sendSmsWhenCaseCreated: 'Envoi d’un SMS à tous les utilisateurs connectés lors de la création d’un nouveau dossier dans le dashboard',
      showAddressInDocument: 'Affiche l’adresse de l’organisation dans le rapport de téléconsultation',
      showEmailServiceMedicalReport: 'Affiche l’adresse mail de l’organisation dans le rapport de téléconsultation',
      showHospitals: 'Affiche les résultats de la recherche hôpitaux sélectionnés dans le rapport de téléconsultation',
      showNonMedicationNotesFirst: 'Dans le rapport de téléconsultation au moment d’afficher le contenu des prescriptions, afficher d’abord les notes additionnelles puis la liste des médicaments',
      useParentingForCodings: 'Afficher le code parent du code pathologique dans la page d’administration des codes pathologiques en plus du code pathologique (comportement récupéré du PRM)',
      useOneProtocolAndPolicyForTreatingCases: 'Utiliser un protocol et une police pour le traitement de tous les cas',
      showOneConsentInConsentPage: 'Afficher un seul consentement dans la page Consentement',
      makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories: 'Rendre non-obligatoire les champs : Diagnostic coding et ICD10 coding',
      emailFooterTakeInLegalEntityObject: 'Afficher le footer présent dans l\'objet "legal entity" qui est relié au protocole',
      showAlertWhenNoMedicalReport: 'Afficher une alerte dans la page Téléconsultation quand le medical report n\'est pas créé',
    },
  },
  changeTaken: {
    title: 'Changement de prise en charge',
    text: 'Attention, %{username} est la nouvelle personne en charge de ce dossier.',
  },
  administration: {
    TITLE: 'Fiche administrative',
    MANDATORY: ': champs obligatoires',
    PLEASE: 'Veuillez remplir les informations patient',
    identity: {
      TITLE: 'Identité',
      CIVILITY: 'Civilité',
      M: 'M', // Mister
      MME: 'Mme', // Missus
      FIRSTNAME: 'Prénom',
      LASTNAME: 'Nom',
      BIRTHNAME: 'Nom de naissance',
      EMAIL: 'Email',
      EMAIL_BOOLEAN: 'Le patient n\'a pas d\'adresse email',
      RESEND_PDA_LINK: 'Renvoyer le lien PDA',
      PHONE_NUMBER: 'Téléphone',
      birth: {
        TITLE: 'Naissance',
        BIRTHDATE: 'Date de naissance',
        COUNTRY: 'Pays de naissance',
        CITY: 'Ville de naissance',
      },
      address: {
        TITLE: 'Adresse',
        STREET: 'Rue',
        POSTCODE: 'Code postal',
        CITY: 'Ville',
        COUNTRY: 'Pays',
        DEPARTMENT: 'Département',
      },
    },
    lastDocuments: {
      history: 'Historique des anciennes consultations',
      total: 'Nombre total de',
      advice: 'TELECONSEILS',
      consult: 'TELECONSULTATIONS',
      showAll: 'TOUT VOIR',
      onRollingMonths: 'Teleconsutations sur ce protocole (sur 12 mois glissants)',
      nbOfTeleconsultRollingMonthsForProtocol: 'Nombre de téléconsultations sur 12 mois glissants pour le protocole : ',
    },
    doctor: {
      TITLE: 'Médecin traitant',
      FIRSTNAME: 'Prénom',
      LASTNAME: 'Nom',
      STREET: 'Rue',
      POSTCODE: 'Code postal',
      CITY: 'Ville',
      EMAIL: 'Email',
      PHONE: 'Téléphone',
      COUNTRY: 'Pays',
    },
    insurance: {
      TITLE: 'Assurance',
      SOCIAL_SECURITY_NUMBER: 'Numéro de sécurité sociale',
    },
    policy: {
      TITLE: 'Contrat',
      policyHolder: {
        TITLE: 'Titulaire',
        FIRSTNAME: 'Prénom',
        LASTNAME: 'Nom',
        CITY: 'Ville',
        PHONE_NUMBER: 'Numéro de téléphone',
      },
      beneficiary: {
        TITLE: 'Bénéficiaire',
        FIRSTNAME: 'Prénom',
        LASTNAME: 'Nom',
        BIRTHDATE: 'Date de naissance',
      },
      policy: {
        TITLE: 'Détails',
        POLICY_NUMBER: 'Numéro du contrat',
        PROTOCOL_NUMBER: 'Numéro de protocole',
        POLICY_HOLDER: 'Titulaire',
        BENEFICIARY: 'Benéficiaire',
        BRAND: 'Marque',
        LEGAL_ENTITY: 'Entité juridique',
        PDA_ACCOUNT: 'Compte PDA existant :',
        YES: 'Oui',
        NO: 'Non',
      },
    },
  },
  medicalProfile: {
    title: 'PROFIL MÉDICAL',
    weight: 'Poids',
    height: 'Taille',
    BMI: 'IMC',
    weight_mesure: 'kg',
    height_mesure: 'cm',
    SEX: {
      1: 'Homme',
      2: 'Femme',
    },
    AGE: '%{age} ans',
    total: 'Nombre total de',
    teleAdvice: 'Teleconseil',
    teleConsultation: 'Tele consultation',
    show: 'Montrer tout',
    lastModified: 'Dates de dernier document modifié',
    informationsMedicalProfile: {
      medicalHistory: 'Antécédents médicaux',
      treatment: 'Traitement',
      allergies: 'Allergies',
      chronicalDisease: 'Maladies chroniques',
      vaccination: 'Statut vaccinal',
      lifeStyle: 'Mode de vie',
    },
    lastConsultations: {
      history: 'Historique des anciennes consultations',
      total: 'Total number of',
      title: 'Historique des dossiers',
      advice: 'Nombre de TELECONSEILS',
      consult: 'Nombre de TELECONSULTATIONS',
      onRollingMonths: 'Nombre de Teleconsutations sur ce protocole (sur 12 mois glissants)',
      showAll: 'TOUT VOIR',
    },
    datesOfLastDocCreated: {
      title: 'Dates des derniers documents créés',
      advice: 'Dernier CR de téléconseil :',
      consult: 'Dernier CR médical :',
    },
    lastDocumentsDrawer: {
      title: 'Historique',
      backTo: 'Retour',
    },
  },
  historyModifications: {
    title: 'Historique des modifications',
    newValue: 'Nouvelle valeur',
    oldValue: 'Ancienne valeur',
    addressRecipient: 'Destinataire(s)',
    content: 'Contenu',
    mainToSend: 'Moyen d\'envoi',
    reasonForTheCall: 'Raison d\'appel',
    countryLocalisation: 'Pays d\'appel',
    reasonToCallNonMed: 'Catégorie d\'appel',
    caseQualification: 'Qualification de l\'appel',
    beneficiaryBirthday: 'Date de naissance du bénéficiaire',
    beneficiaryFirstName: 'Prénom du bénéficiaire',
    beneficiaryLastName: 'Nom du bénéficiaire',
    policyHolderLastName: 'Nom du souscripteur',
    partnerName: 'Nom du B-Partner',
    protocolNumber: 'Numéro de protocol',
    gender: {
      1: 'Homme',
      2: 'Femme',
    },
    policyHolderFirstName: 'Prénom du souscripteur',
    adviceNote: 'Note de téléconseil',
    localAdviceCoding: 'Codage local du téléconseil',
    teleconsultNotes: 'Notes de téléconsultation',
    doctorNotes: 'Notes du médecin',
    icd10: 'Code ICD10',
    pathologyCoding: 'Codage pathologique',
    medicalExam: 'Examen médical',
    medicalExamInstructions: 'Instructions de l\'examen médical',
    by: ' par ',
    countryCalling: 'Pays d\'appel',
    placeholder: 'Mots-clés',
    videoSessionId: 'Session vidéo id',
    prmIdAssignedTo: 'Assigné à ',
    assignedTo: 'Assigné à ',
    takenInCharge: 'Pris en charge par ',
    prmIdTakenInCharge: 'Pris en charge par ',
    technicalIssue: 'Problème technique ',
    awareEmergencyServices: 'Situation du patient',
    orientationToEmergencyServices: 'Service d \'urgence contactés',
    awareToContact: 'Le patient est informé qu\'il doit contacter les urgences',
    emergencyDetected: 'Urgence détectée',
    emergencyAddressInfo: 'Urgence - Plus d\'informations',
    emergencyAddressZipCode: 'Urgence - Code postal',
    emergencyAddressRegion: 'Urgence - Département',
    emergencyAddressCountry: 'Urgence - Pays',
    emergencyAddressCity: 'Urgence - Ville',
    emergencyAddressLine: 'Urgence - Adresse',
    1: 'Le patient est en France',
    2: 'Le patient est à l\'étranger',
    3: 'Le patient est en Espagne',
    technicalIssueText: 'Texte du problème technique',
    codeCommunicationType: 'Canal de communication',
    benefit: 'Prestation non couverte',
    general: 'Informations générales',
    technical: 'Problème technique',
    assistance: 'Assistance sans contrat',
    complaint: 'Réclamation',
    prm: 'PRM',
    EMAIL: 'Email',
    SMS: 'SMS',
    SECURE_LINK: 'Lien sécurisé',
    CODE: 'Code envoyé au :',
    LINK: 'Lien envoyé au :',
    PHARMACY: 'Fax à une pharmacie',
    pharmacyName: 'Nom de la pharmacie',
    pharmacyNumber: 'Numéro de la pharmacie',
    CREATE: 'Création',
    UPDATE: 'Modification',
    ARCHIVE: 'Archivage',
    REOPEN: 'Réouverture',
    medicalHistory: 'Historique médical',
    DOCTOR_FIRSTNAME: 'Prénom du docteur de famille',
    DOCTOR_LASTNAME: 'Nom du docteur de famille',
    DOCTOR_COUNTRY: 'Pays du docteur de famille',
    DOCTOR_CITY: 'Ville du docteur de famille',
    DOCTOR_POSTALCODE: 'Code postal du docteur de famille',
    DOCTOR_ADDRESS: 'Adresse postale du docteur de famille',
    DOCTOR_EMAIL: 'Adresse mail du docteur de famille',
    DOCTOR_PHONE: 'Téléphone du docteur de famille',
    genderCode: 'Genre',
    firstName: 'Prénom',
    lastName: 'Nom',
    birthName: 'Nom de naissance',
    emailAddress1: 'Email',
    noAdressMail: 'Le patient n\'a pas d\'adresse email',
    RESEND_PDA_LINK: 'Renvoyer le lien PDA',
    mobilePhone: 'Téléphone',
    birthdate: 'Date de naissance',
    birthCountry: 'Pays de naissance',
    birthCity: 'Ville de naissance',
    address1Line1: 'Rue',
    address1Postalcode: 'Code postal',
    address1City: 'Ville',
    address1Country: 'Pays',
    treatments: 'Traitements en cours',
    allergies: 'Allergies',
    chronicalDisease: 'Maladies chroniques',
    vaccinations: 'Statut vaccinal',
    lifeStyle: 'Mode de vie',
    weight: 'Poids',
    height: 'Taille',
    socialSecurityNumber: 'Numéro de sécurité sociale',
    policyNumber: 'Numéro de police',
    DELETE: 'Suppression',
    SEND: 'Envoi',
    true: 'Oui',
    false: 'Non',
    283030000: 'Télé conseil',
    283030001: 'Télé consultation',
    sharingConsents: 'Consentement partage des données',
    treatmentConsent: 'Consentement traitement des données',
    familyDocConsent: 'Autorisation du médecin traitant',
    statusCase: 'Statut du dossier',
    ACTIVE: 'Ouvert',
    INACTIVE: 'Archivé',
    CLOSED: 'Fermé',
    MEDICINE: 'Médicament',
    ADDITIONAL_NOTES: 'Notes additionelles',
  },
  contact: {
    start: {
      TITLE: 'Démarrer',
      VIDEO: 'Video',
      CHAT: 'Chat',
      PHONE: 'Appel',
    },
    video: {
      TITLE: 'Confirmer l\'envoi du lien vidéo',
      TITLE_2: 'Gestion de la session vidéo',
      SEND_VIDEO_LINK_CONFIRMATION: 'Voulez vous envoyer un mail avec le lien vidéo au patient pour initier la teleconsultation video ?',
      SEND_VIDEO_LINK_CONFIRMATION_OR_OPEN_VIDEO: 'Vous pouvez envoyer le lien vidéo au patient pour initier la teleconsultation ou demarrer la session vidéo.',
    },
    send: {
      TITLE: 'Envoyer',
      EMAIL: 'Email',
      SMS: 'SMS',
      RDV: 'RDV',
    },
    phone: {
      TITLE: 'Numéro de rappel',
      NO_PHONE_NUMBER: 'Il n\'y a pas de numéro de téléphone lié à ce patient.',
      CALLBACK_NUMBER: 'Le numéro de rappel du patient est le %{number}.',
    },
    sms: {
      TITLE: 'Contacter le patient',
      SEND: 'Envoyer',
      RECIVER_NUMBER: 'Le numéro utilisé est le %{number}.',
    },
    hospitalSearch: {
      TITLE: 'Rechercher un hopital',
      SEARCH: 'Rechercher',
      LIST: 'Liste des résultats',
      address: 'Adresse :',
      contact: 'Contact : ',
      departments: 'Départements : ',
      sendMail: 'Envoyer par email',
      sendSms: 'Envoyer par sms',
      caseReport: 'Afficher dans les documents',
      mailConfirmationTitle: 'Confirmation d\'envoi par mail',
      mailConfirmationContent: 'Vous vous apprétez à envoyer les données par email. Veuillez confirmer.',
      smsConfirmationTitle: 'Confirmation d\'envoi par sms',
      smsConfirmationContent: 'Vous vous apprétez à envoyer les données par SMS. Veuillez confirmer.',
    },
    email: {
      TITLE: 'Contacter par Email',
      SEND: 'Envoyer',
      OBJECT: 'Object',
      EMAIL_CC: 'Email en CC',
      EMAIL: 'Adresse Email',
    },
    MEDICAL_RESOURCES: 'Ressources Medicales',
    medicalResources: {
      TITLE: 'Ressources Médicales',
      MEDICAL_SEARCH: 'Recherche médicale',
      VARIOUS: 'Divers',
      TRAVELLERS: 'Voyageurs',
      MEDICATION: 'BDM',
      SEARCH_HOSPITAL: 'Recherche d\'hôpitaux',
    },
  },
  popinCreatePatient: {
    title: 'Informations nécessaire pour la création du patient',
    subtitle: 'Merci de compléter l’adresse email et le téléphone du patient pour accéder au dossier administratif',
  },
  consent: {
    title: 'RECUEIL DES CONSENTEMENTS',
    intro: 'Lire les consentements au patient :',
    titleTreatingAndSharing: 'Acconsente alla condivisione e all\'elaborazione dei dati',
    textTreatingAndSharing_v1: 'Per fornirle il servizio di teleconsulto, abbiamo bisogno del suo consenso a trattare i dati personali relativi alla salute. Il titolare del trattamento è AWP P&C S.A.. Potrà trovare il testo completo dell’informativa privacy all’indirizzo',
    textTreatingAndSharing_v2: 'Il consenso potrà essere revocato dandone comunicazione alla società. Acconsente il trattamento dei dati relativi alla salute per la finalità di erogazione del servizio di Teleconsulto?',
    textTreatingAndSharing: '  termini e condizioni  ',
    titleTreating: 'Traitement des données',
    textTreating: 'J\'autorise les médecins et infirmiers, constituant mon équipe de soins, à consulter mes données de santé à caractère personnel, qui sont recueillies et produites dans le cadre du service de téléconsultation',
    titleSharing: 'Partage des données',
    textSharing: 'J\'autorise la collecte et le traitement de mes données de santé à caractère personnel, afin de bénéficier du service de téléconsultation.',
    give: 'OUI',
    giveNot: 'NON',
    create: 'Continuer',
    consentUnvalid: 'Information patient',
    consentUnvalidText: 'Ce ou ces consentements sont indispensables pour continuer. Etes-vous certain(e) de vouloir abandonner ?',
    archived: 'Terminer',
  },
  qualification: {
    reason: 'Motif de l\'appel',
    country: 'Pays de l\'appel',
    category: 'Catégorie de l\'appel',
    viewHistory: 'Voir l\'historique des modifications',
    technicalProblem: 'Problème technique',
    qualificationTitle: ' Qualification de l\'appel',
    textQualification: 'L\'appel est :',
    teleAdvice: 'TELECONSEIL',
    teleConsult: 'TELECONSULTATION',
    teleAdviceNotes: 'Information délivrée',
    teleAdviceCodification: 'Codage téléconseil ',
    continue: 'CONTINUER',
    transfer: 'Teleconsultation',
    modalTitle: 'Compte-rendu médical',
    modalText: 'J\'autorise l\'envoi à mon médecin traitant du compte-rendu de téléconsultation dont j\'ai bénéficié',
    yes: 'OUI',
    no: 'NON',
    modalDoctor: 'Veuillez vérifier les informations du médecin généraliste :',
    reasonToCallNonMed: {
      technicalIssue: 'Problème technique',
      PRM: 'PRM',
      benefitNotCovered: 'Prestation non couverte',
      generalInformation: 'Informations générales',
      notContractedAssistance: 'Assistance non contractuelle',
      complaint: 'Réclamation',
    },
    ADVICE_REPORT: 'Rapport de téléconseil',
    NO_REPORT: 'Il n\'y a pas encore de compte rendu de téléconseil',
    ADD_REPORT: 'Ajouter un compte rendu de téléconseil',
    NEW_DOCUMENT_FROM_PDA: 'Le patient a téléchargé un document sur le PDA',
    MISSING_DIAGNOSTIC: 'Veuillez sélectionner un codage téléconseil pour continuer.',
  },
  teleconsultation: {
    EMERGENCY: 'urgence',
    QUALIFICATION_REPORT: 'Qualification de l’appel',
    relatedCase: {
      titleParent: 'Rapport du dossier parent ',
      titleChildren: 'Rapports des dossiers liés',
    },
    REASON_FOR_CALL: 'Raison d\'appel',
    SYMPTOM_FLAG: 'Indicateur de contrôle des symptômes',
    TELEADVICE_NOTES: 'Notes du téléconseil',
    DOCUMENTS: 'Documents',
    COUNTRY_OF_CALL: 'Pays d\'appel',
    TYPE_OF_CALL: 'Catégorie d\'appel',
    TELECONSULTATION_NOTES: 'Notes de la téléconsultation',
    SHARED: 'partagées avec le patient',
    PLACE_HOLDER: 'Renseignez les notes de la téléconsultation',
    NOT_SHARED: 'non partagées avec le patient',
    DOCTOR_NOTES: 'Notes personnelles au médecin',
    PRESCRIPTION: 'Prescriptions',
    NO_PRESCRIPTION: 'Il n\'y a pas encore de prescription',
    ADD_PRESCRIPTION: 'Ajouter une prescription',
    CODING: 'Codage diagnostic',
    ICD10_CODING: 'Codage ICD10',
    ENTER_ICD10: 'Entrez la première lettre de votre ICD10',
    LOCAL_CODING: 'Codage pathologie locale',
    FINAL_ORIENTATION: 'Orientation finale',
    FINAL_ORIENTATION_PHRASE: 'A la fin de la discussion, veuillez qualifier l\'appel',
    END_CONSULTATION: 'Fin de la consultation',
    PHYSICAL_EXAM: 'Examen clinique',
    PHYSICAL_EXAM_TEXT: 'Notes supplémentaires pour l\'examen physique (partagées avec le patient)',
    CREATE_RELATED_CASE: 'Créer un dossier lié',
    MEDICAL_REPORT: 'Compte rendu medical',
    NO_REPORT: 'Il n\'y a pas encore de compte rendu médical',
    ADD_REPORT: 'Ajouter un compte rendu médical',
    MISSING_ICD10: 'Veuillez sélectionner un ICD10 pour continuer',
    MISSING_DOCUMENT_FOR_FAMILY_DOCTOR: 'Veuillez ajouter un compte rendu medical pour le médecin de famille.',
    MISSING_MEDICAL_REPORT: 'Veuillez ajouter un compte rendu medical.',
  },
  medicine: {
    TITLE: 'Ajouter un médicament',
    SEARCH: 'Rechercher des médicaments',
    HAVE_SELECTED: 'Vous avez séléctionné : ',
    dosage: {
      DOSAGE: 'Dose',
      QUANTITY_PLACEHOLDER: 'Quantité',
      TIME_UNIT: 'par',
      TIME_PLACEHOLDER: 'Unité',
      DURATION: 'pendant',
      DURATION_QUANTITY_PLACEHOLDER: 'Nombre',
      DURATION_TIME_UNIT_PLACEHOLDER: 'Unité',
    },
    time_unit: {
      DAY: 'jour',
      WEEK: 'semaine',
      MONTH: 'mois',
    },
    repetition: {
      DAY: 'jour',
      NIGHT: 'nuit',
      MEAL: 'repas',
      WEEK: 'semaine',
      MONTH: 'mois',
    },
    DETAIL_PLACEHOLDER: 'Détails de la posologie / durée',
    ADD: 'Ajouter',
    instruction: {
      TAKE: 'Prendre',
      DOSE: 'doses',
      BY: 'par',
      DURING: 'pendant',
    },
    noResult: 'Aucun résultat pour votre recherche',
  },
  documentsToSend: {
    title: 'Documents',
    phrase1: 'Pour finir le traitement de cette consultation, choisissez les modes d\'envoi des documents.',
    thirdConsent: 'Attention, le troisième consentement a été donné, vous devez envoyer le rapport médical au médecin de famille.',
    buttonArchive: 'Archiver la consultation',
    textArchive: 'Une fois la consultation archivée, tous les documents ci-dessus seront envoyés au patient sur son espace PDA.',
    sendBy: 'Envoyer via : ',
    bySecureLink: 'Par lien sécurisé',
    byPharmacy: 'A la pharmacie',
    print: 'Imprimer',
    send: 'ENVOYER',
    download: 'TELECHARGER',
    creationDate: 'date de création de la prescription',
    prescription: {
      title: 'Prescriptions',
      subtitle: 'Prescription %{count}',
    },
    medicalReport: {
      title: 'Rapport médical',
      subtitle: 'Rapport médical %{count}',
    },
    patientDocument: {
      title: 'Documents du patient',
      subtitle: 'Documents patient %{count}',
    },
    sendToPharmacy: {
      listPharmacieTitle: 'Liste des pharmacies correspondant à la recherche :',
      title: 'Envoyer à la pharmacie',
      pharmacyResearch: 'pharmacie',
      subtitle: 'Avant de continuer, vérifiez le consentement pour l\'envoi de la prescription par fax :',
      authorize: 'J\'autorise',
      phraseEmail: '1. S\'il vous plaît, vérifiez et remplissez le courriel :',
      phrasePhone: '1. S\'il vous plaît, vérifiez et remplissez le numéro du patient :',
      warningOnModify: 'Attention, toute modification de ce champ entraînera une modification des informations générales du patient.',
      email: 'Email',
      phone: 'Téléphone',
      phase2: '2. Veuillez sélectionner ou ajouter une pharmacie :',
      search: 'Rechercher une pharmacie',
      name: 'Nom',
      adress: 'Adresse',
      city: 'Ville',
      country: 'Pays',
      zipcode: 'Code postal',
      add: 'Ajouter une pharmacie',
      fax: 'Numéro de fax :',
      phoneNumber: 'Numéro de téléphone: ',
      faxShort: 'Fax : ',
      phoneShort: 'Tél : ',
      internet: 'Recherche Google',
      errorMessage: 'Cette pharmacie n\'existe pas dans notre base de données. Vous devez l\'ajouter si vous voulez envoyer le document',
      phase3: '3. Veuillez trouver ci-dessous les informations sélectionnées. Vérifiez-les et envoyez le document',
      emailOfPatient: 'Email du patient:',
      phoneOfPatient: 'Téléphone du patient:',
      pharmacy: 'Pharmacie selectionnée:',
      pharmacySearchError: 'Cette pharmacie n\'existe pas dans notre base de données. Vous devez l\'ajouter si vous voulez l\'envoyer',
    },
    sendBySecure: {
      title: 'Envoyer par lien sécurisé',
      subtitle: 'Veuillez vérifier et remplir les champs suivants :',
      destination1: '1. Destination du lien',
      destination2: '2. Destination du code',
      email: 'Email',
      phone: 'Téléphone',
      or: 'OU',
    },
  },
  reasonToCallNonMed: {
    benefit: 'Prestation non couverte',
    general: 'Informations générales',
    technical: 'Problème technique',
    assistance: 'Assistance non contractuelle',
    complaint: 'Réclamation',
    prm: 'PRM',
  },
  emergency: {
    TITLE: 'Urgence',
    CURRENT_COUNTRY: 'Le patient a déclaré être actuellement dans le pays : ',
    AWARE_TO_CONTACT: 'Le patient est informé qu\'il doit contacter les urgences.',
    CURRENT_ADDRESS: 'Précisez la localisation actuelle du patient : ',
    MORE_INFO: 'Plus d\'informations',
    LOCAL_EMERGENCY: 'Les services d\'urgence locaux ont-ils été contactés ?',
    CREATE: 'Signaler l\'urgence',
  },
  techProblem: {
    TITLE: 'Problème technique',
    LABEL: 'Expliquez le problème rencontré :',
    CREATE: 'Signaler le problème',
  },
  editDocument: {
    LANGUAGE: 'Changer la langue',
    TITLE_PRESCRIPTION: 'Prescription',
    TITLE_MEDICAL_REPORT: 'Compte rendu médical',
    TITLE_ADVICE_REPORT: 'Rapport de téléconseil',
    TELEADVICE_REPORT_NUMBERING: 'Rapport de téléconseil',
    TELECONSULTATION_REPORT_NUMBERING: 'Rapport de téléconsultation médicale',
    ORDONNANCE: 'Ordonnance',
    BACK: 'Retour à la téléconsultation',
    DOCTOR: 'Docteur ',
    DR: 'Dr.',
    ID_NUMBER: 'N° RPPS : ',
    ADDRESS: 'Adresse',
    ORGANIZATION: 'Organisme de téléconsultation : ',
    UNDER_NAME: 'Désigné sous le nom ',
    IMPLEMENTED_BY: 'Les actes de téléconsultation sont mis en oeuvre par',
    EMAIL: 'Adresse électronique',
    OPEN_HOURS: 'Service ouvert 7j/7, 24h/24',
    ATTENTION_OF: 'A l\'attention de :',
    LEGAL_REPRESENTATIVE: 'ou de son représentant légal',
    FAMILY_DOCTOR: 'désigné par le patient comme son médecin traitant',
    FAMILY_DOCTOR_ADDRESS: 'Officiant à l\'adresse suivante : ',
    DEAR_COLLEAGUE: 'Cher confrère',
    DEAR_DOCTOR: 'Cher docteur',
    DEAR_PATIENT: 'Cher patient',
    DESCRIPTION_CALL_1_M: 'Votre patient, M %{name}, âgé de ',
    DESCRIPTION_CALL_1_W: 'Votre patiente, Mme %{name}, âgée de ',
    YEARS: ' ans ',
    MONTHS: 'mois ',
    DESCRIPTION_CALL_2: 'pesant %{weight}kg, ',
    DESCRIPTION_CALL_3: 'a fait appel à notre service de\n téléconsultation le %{callDay} à %{callHour}.',
    SUMMARY_CALL: 'Vous trouverez ci-dessous un compte rendu de nos échanges et de nos préconisations.',
    THANKS_FOR_THE_USE: 'Merci d\'avoir utilisé le service de téléconsultation, qui a eu lieu le %{callDay} à %{callHour}.',
    TO_BE_FOLLOWED: 'Nous lui demandons de revenir vers vous pour la suite de la prise en charge.',
    THE: 'le ',
    DATE: 'Date',
    PATIENT: 'Patient',
    SIR: ' Monsieur ',
    MADAM: ' Madame ',
    BIRTH_DATE: 'Né(e) le ',
    WEIGHT: 'Poids : %{weight}kg',
    EMPTY_MEDICINE: 'Il n\'y a pas encore de médicament',
    WARNING_ADDITIONAL_NOTES: 'Attention, le nombre de caractères ne doit pas excéder 2000 caractères',
    ADD_MEDICINE: 'Ajouter un médicament',
    EDIT: 'Editer',
    EMPTY_NOTES: 'Il n\'y a pas encore de notes additionnelles',
    ADD_NOTES: 'Ajouter des notes',
    ADVICE: {
      CALL_TELECONSULTATION: 'Votre patient(e), %{gender} %{firstName} %{lastName}, âgé(e) de %{year} ans, a fait appel à notre service de téléconsultation le %{date} à %{hour}.',
      TO_BE_FOLLOWED: 'Vous trouverez ci-dessous, un résumé de nos échanges et des informations délivrées.',
      TITLE_1: '1/ Raison de l\'appel',
      TITLE_2: '2/ Résumé de nos échanges',
      NOTE: 'Notes',
      TECHNICAL_ISSUE: 'Incident technique au cours de l’appel : %{value}',
      END: 'Veuillez agréer cher docteur nos sincères salutations,',
      END_DOCTOR: 'Confraternellement',
      YES: 'OUI',
      NO: 'NON',
    },
    medicalProfile: {
      TITLE: 'Profil médical',
      MEDICAL_HISTORY: 'Antécédent(s) déclaré(s)',
      CURRENT_TREATMENT: 'Traitement(s) en cours',
      ALLERGIES: 'Allergie(s)',
      VACCINATION_STATUS: 'Statut vaccinal',
      LIFESTYLE: 'Mode de vie',
    },
    PAGE_BREAK: 'Saut de page',
    DIALOG_SUMMARY: 'Résumé de nos échanges',
    PRESCRIPTION_GIVEN: 'A l’issue de la téléconsultation, une ordonnance %{title} a été délivrée comportant ',
    HOSPITALS_GIVEN: 'Les coordonnées de ou des établissement(s) hospitalier(s) suivant(s), ont été transmises au patient :',
    ORIENTATION_TO_DOCTOR: 'Au cours de la téléconsultation, il a été recommandé au patient de réaliser un examen médical physique.',
    EMERGENCY_DETECTED: 'Une urgence potentielle a été détectée au cours de l\'appel',
    EMERGENCY_CONTACTED: 'Le patient a été orienté vers les services d’urgence et le SAMU départemental a été contacté par nos équipes',
    PATIENT_REDIRECTED: 'Le patient a été orienté vers les services d’urgence locaux',
    TECHNICAL_ISSUE: 'Incident technique au cours de l’appel ',
    DETAILS: 'Détails',
    CONTACT: 'Pour toute question relative à cette prise en charge, n\'hésitez pas à contacter notre service.',
    FOOTER_1: 'Nous vous conseillons de revoir votre médecin traitant pour la suite de la prise en charge ou en cas d\'absence d\'amélioration sous 48h.',
    FOOTER_2: 'Le service de téléconsultation n’est pas un service d’urgence.',
    FOOTER_2_EMERGENCY_NUMBERS: 'En cas d’urgence veuillez composer le 15 ou le 112.',
    FOOTER_3: 'Cette ordonnance est établie au décours d’une téléconsultation médicale et conformément à la réglementation en vigueur ne comporte ni numéro FINESS ni numéro AM. Cependant la délivrance de médicaments prescrits par un médecin dûment identifié par son numéro RPPS doit être considérée comme il se doit par la pharmacie contactée.',
    FOOTER_4: 'En cas d\'urgence, contactez les services d\'urgence locaux.',
  },
  notify: {
    success: {
      SEND_EMAIL: 'Le mail a bien été envoyé',
      SEND_SMS: 'Le message a bien été envoyé',
      MODIFICATION_SAVED: 'Les modifications ont bien été prises en compte',
      AVAILABILITY_DELETED: 'Le créneau a bien été supprimé',
      ARCHIVED: 'Le dossier a bien été archivé',
      REOPENED: 'Le dossier a bien été ré-ouvert',
      CREATED: 'La creation a réussi',
      APPOINTMENTS_CREATED: 'Les disponibilités ont bien été crée',
    },
    error: {
      RELATED_CASE_CREATION: 'Vous ne pouvez pas créer un rendez-vous dans le passé.',
      MANDATORY_FIELD: 'Vous avez oublié de remplir des champs obligatoires',
    }
  },
  patientSearch: {
    TITLE: 'Recherche patient',
    FIRSTNAME: 'Prénom',
    LASTNAME: 'Nom',
    DATE_OF_BIRTH: 'Date de naissance',
    SEARCH_RESULT: 'Résultats de la recherche',
    NO_RESULT: 'Il n\'y a pas de résultats. Veuillez lancer une nouvelle recherche',
    PATIENTS_FOUND: '%{count} patients ont été trouvés pour les critères : ',
    PATIENTS_FOUND_0: 'Il n\'y a aucun patient correspondant aux critères : ',
    LAST_PATIENTS_FOUND: 'Les %{count} derniers patients : ',
    LAST_PATIENTS_FOUND_0: 'Le %{count} dernier patient : ',
    LAST_PATIENTS_FOUND_plural: 'Les %{count} derniers patients : ',
    OPEN_PATIENT: 'Ouvrir le patient',
    WAIT_10_LAST_PATIENTS: 'Veuillez attendre le chargement des 10 derniers patients.',
  },
  caseSearch: {
    TITLE: 'Recherche dossier',
    periodFromPlaceHolder: 'Du JJ/MM/AAAA',
    periodToPlaceHolder: 'Au JJ/MM/AAAA',
    periodFrom: 'Du',
    periodTo: 'Au',
    inCharge: 'Personne en charge',
    teamInCharge: 'Equipe en charge',
    assigned: 'Assignée à',
    teamAssigned: 'Equipe assignée',
    bPartner: 'B-partner',
    channel: 'Canal',
    createdBy: 'Créé par',
    status: 'Statut',
    caseNumber: 'Numéro de dossier',
    lastModifiedBy: 'Dernièrement modifié par',
    qualificationCode: 'Code de pré-qualification',
    KEYWORD: 'Mot clé',
    SHOW_MORE: 'Voir plus de critères',
    SHOW_LESS: 'Voir moins de critères',
    SEARCH_RESULT: 'Résultats de la recherche',
    WAIT_10_LAST_CASES: 'Veuillez attendre le chargement des 10 derniers dossiers.',
    FIRST_CASES: 'Voici le %{count} dernier dossier crée :',
    FIRST_CASES_0: 'Voici le %{count} dernier dossier crée :',
    FIRST_CASES_plural: 'Voici les %{count} derniers dossiers crées :',
    NO_RESULT: 'Il n\'y a pas de résultats. Veuillez lancer une nouvelle recherche.',
    CASES_FOUND: '%{count} dossiers ont été trouvés pour les critères : ',
    CASES_FOUND_1: 'Un dossier a été trouvé pour les critères : ',
    CASES_FOUND_0: 'Il n\'y a aucun dossier correspondant aux critères : ',
    LAST_EDIT: 'Dernière modification par %{firstname} %{lastname}',
    CREATED_BY: 'Dossier créé par %{firstname} %{lastname}',
    OPEN_CASE: 'Ouvrir le dossier',
    label: {
      ACTIVE: 'Ouvert',
      ARCHIVED: 'Archivé',
      INACTIVE: 'Archivé',
      CLOSED: 'Fermé',
      TELEADVICE: 'Téléconseil',
      TELECONSULTATION: 'Téléconsultation',
      OTHER: 'Autre',
    },
  },
  caseDetails: {
    BACK_TO_LIST: 'Retour à la liste',
    CASE: 'Dossier ',
    PATIENT: 'Patient',
    ARCHIVED: 'Archivé',
    TELEADVICE: 'Téléconseil',
    TELECONSULTATION: 'Télé consultation',
    LAST_EDIT: 'Dernière édition',
    HISTORY: 'Voir l\'historique de modifications',
    NO_CONSENTS: 'Ce dossier a été fermé parce que les consentements n\'ont pas été acceptés',
    RE_OPEN: 'Ré-ouvrir le dossier',
    CHECK_RE_OPEN: 'Êtes-vous sûr de vouloir ré-ouvrir ce dossier ?',
    SEE_IN_CONTEXT: 'Voir dans le contexte',
    PATIENT_INFO: 'Informations du patient',
    patientInfo: {
      FIRSTNAME: 'Prénom',
      LASTNAME: 'Nom',
      DATE_OF_BIRTH: 'Date de naissance',
      EMAIL: 'Adresse email',
      PHONE: 'Numéro de téléphone',
      AIDAT_ID: 'Aid@ ID',
      CHANNEL: 'Canal de communication',
      POLICY_NUMBER: 'Numéro de police',
      PROTOCOL_NUMBER: 'Numéro du protocole',
      BPARTNER: 'B-Partner',
      LEGAL_ENTITY: 'Entité légale',
      BRAND: 'Marque',
    },
    CASE_NOTES: 'Notes liées au dossier',
    caseNotes: {
      REASON_FOR_CALL: 'Raison d\'appel',
      TELEADVICE_NOTES: 'Notes du téléconseil',
      TELECONSULTATION_NOTES: 'Notes de la téléconsultation',
      DOCTOR_NOTES: 'Notes personnelles au médecin',
    },
    CODING: 'Codage du dossier',
    coding: {
      ADVICE_CODING: 'Code téléconseil : ',
      PATHOLOGY_CODING: 'Code pathologie : ',
    },
    PRESCRIPTIONS: 'Prescriptions',
    REPORTS: 'Compte-rendus',
    PATIENT_DOC: 'Documents fournis par le patient',
  },
  patientDetails: {
    modalResend: {
      TITLE: 'Renvoyer le lien PDA',
      SELECT: 'Merci de sélectionner une police en dessous',
    },
    ADD_POLICY: 'Ajouter une autre police',
    SKIP_ELIGIBILITY: 'aucune police éligible',
    BENIFICIARY_SEARCH: 'Recherche de bénéficiaire',
    POLICY_HOLDER_SEARCH: 'Recherche de titulaire',
    EXISTING_POLICY: 'Polices existantes',
    NEW_POLICY: 'Nouvelles polices',
  },
  forgot_password: {
    modal: {
      TITLE: 'Mot de passe oublié ?',
      INSTRUCTIONS: 'Merci de rentrer l\'identifiant que vous avez utilisé pour vous inscrire et nous vous enverrons les instructions pour réinitialiser votre mot de passe.',
      LOGIN: 'Identifiant',
      SUBMIT: 'Envoyer les instructions de réinitialisation',
    },
    sms: {
      TITLE: 'Entrez le code SMS',
      INSTRUCTIONS: 'Merci d\'entrer le code reçu par SMS.',
      SMS_CODE: 'Code SMS',
      SUBMIT: 'Suivant',
    },
    reset: {
      TITLE: 'Entrez votre nouveau mot de passe',
      INSTRUCTIONS: 'Merci d\'entrer votre nouveau mot de passe',
      PASSWORD: 'Mot de passe',
      PASSWORD_LABEL: 'Nouveau mot de passe*',
      PASSWORD_CONFIRMATION: 'Mot de passe',
      PASSWORD_CONFIRMATION_LABEL: 'Confirmez votre mot de passe*',
      OTP: 'Code',
      OTP_LABEL: 'Merci de renseigner le code reçu par SMS*',
      SUBMIT: 'Suivant',
    },
    done: {
      TITLE: 'Votre mot de passe a été réinitialisé !',
      INSTRUCTIONS: 'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.',
      SUBMIT: 'Continuer',
    },
  },
  export: {
    TITLE: 'Exports',
    periodForm: 'Période  ',
    periodFromPlaceHolder: 'Du JJ/MM/AAAA',
    periodToPlaceHolder: 'Au JJ/MM/AAAA',
    policyPeriodStartFrom: 'La police commence apres ',
    policyPeriodEndBefore: 'La police fini avant ',
    patientPolicy: {
      CASE_KPI_REPORT: 'Export des KPI des dossiers',
      PATIENT_KPI_REPORT: 'Export des KPI des patients',
    },
    field: {
      businessUnit: 'Business Unit',
      bPartner: 'B-Partner',
      protocolNumber: 'Numéro de protocole',
      prequalificationCode: 'Code de préqualification',
      emergencyDetected: 'Urgence détectée',
      technicalIssueDetected: 'Problèmes techniques détectés',
    },
    SUBMIT: 'Exporter',
    prequalificationCoding: {
      teleAdvice: 'tele Conseil',
      teleConsult: 'tele consultation',
    },
    TRUE: 'Oui',
    FALSE: 'Non',
    columns: {
      createdOn: 'Date de création',
      gender: 'Genre',
      dateOfBirth: 'Date de naissance',
      country: 'Pays de résidence',
      zipCode: 'Code postal',
      businessUnit: 'Business unit',
      bPartner: 'B-Partner',
      protocolNumber: 'N° de protocole',
      policyNumber: 'N° de police',
      pdaAccess: 'Accès PDA',
      pdaAccount: 'Compte PDA',
      totalNumberCases: 'Nb total de dossiers',
      totalNumberCasesWithSpecialists: 'Nb total de dossier avec spécialistes',
      lastCaseCreated: 'Dernier dossier',
      numberOfAppointments: 'Nb de RDV',
      numberOfAppointmentsCancelled: 'Nb de RDV annulés',
      countryOfTheCall: 'Pays de l\'appel',
      channelOfCommunication: 'Canal de communication',
      aidaCategory: 'Catégorie de l\'appel',
      reasonForCall: 'Raison de l\'appel',
      consent: 'Consentement Méd. traitant',
      caseQualification: 'Qualification',
      pathologyCoding: 'Codage pathologie',
      adviceCoding: 'Codage téléconseil',
      activeReport: 'Prescription(s) active(s)',
      orientationToEmergencyServices: 'Orientation vers le médecin pour examen clinique',
      emergencyDetected: 'Urgence Potentielle',
      documentJoin: 'Documents Joints',
      technicalIssue: 'Problème technique lors de l\'appel',
    },
    values: {
      man: 'Homme',
      woman: 'Femme',
      yes: 'Oui',
      no: 'Non',
      phone: 'Téléphone',
      video: 'Vidéo',
      chat: 'Chat',
      teleconsult: 'Téléconsultation',
      teleadvice: 'Téléconseil',
    }
  },
  operations: {
    TITLE: 'OPERATION DASHBOARD',
    TEAM_AVAILABILITIES: 'Disponibilité des équipes',
    CASE_LEVEL: 'Niveau des cas',
    CASES_STATUS: 'Statut des cas',
    DASHBOARD_INFOS: 'Informations sur les cas du dashboard',
    WAITING_TIME_FROM_PDA: 'Temps d\'attente depuis le PDA',
    PROCESSING_TIME: 'Temps d\'attente moyen avant prise en charge (cas sur le dashboard)',
    APPOINTMENT_FORECAST: 'Appointment forecast',
    DETAILS: 'Détails',
    team: {
      CONNECTED_NURSE: 'Infirmier connecté',
      CONNECTED_DOCTOR: 'Docteur connecté',
    },
    case: {
      FROM_PDA: 'Cas créés depuis le PDA',
      FROM_EPRM: 'Cas créés depuis l\'ePRM',
      WAITING_PDA: 'Cas en attente d\'être pris en charge',
      WAITING_APPOINT_PDA: 'Rendez-vous en attente d\'être pris en charge',
      WAITING_NURSE: 'Cas en attente d\'un(e) infirmier(e)',
      WAITING_DOCTOR: 'Cas en attente d\'un(e) médecin(e)',
      TAKEN_IN_CHARGE: 'Cas pris en charge par un soignant',
      ASSIGNED: 'Cas assignés à un soignant',
      ARCHIVED: 'Cas archivés (durant la période ci-dessus)',
      WITH_PDA: 'Des cas créés depuis le PDA',
      WITHOUT_PDA: 'Des cas créés depuis de l\'ePRM',
      minutes: 'min',
      ALL: 'Total des dossiers ouverts (durant la période ci-dessus)',
      ALL_DASHBOARD: 'Total des dossiers ouverts sur le dashboard'
    },
    appointment: {
      APPOINTMENT_TITLE: 'Prevision  des rendez-vous',
      CURENT_DAY: 'Aujourd\'hui',
      DAY_PLUS_1: 'J + 1',
      DAY_PLUS_2: 'J + 2',
      DAY_PLUS_3: 'J + 3',
      DAY_PLUS_4: 'J + 4',
      DAY_PLUS_5: 'J + 5',
      DAY_PLUS_6: 'J + 6',
      DAY_PLUS_7: 'J + 7',
    },
  },
  pharmacySearch: {
    TITLE: 'Recherche pharmacie',
    SORT_BY: 'Trier par',
    TO_DELETE: ' %{rows} ligne(s) sélectionnée(s)',
    ADD_PHARMACY: 'Ajouter une pharmacie',
    NAME: 'Nom',
    ADDRESS: 'Adresse',
    CITY: 'Ville',
    COUNTRY: 'Pays',
    ZIP_CODE: 'Code postal',
  },
  adviceCodingSearch: {
    TITLE: 'Codage local du téléconseil',
    PLACE_HOLDER: 'Rechercher un code',
    TO_DELETE: '%{rows} ligne(s) sélectionnée(s)',
    SORT_BY: 'Trier par',
    NO_RESULT: 'Il n\'y a pas de résultats. Veuillez lancer une nouvelle recherche.',
    ADD_CODE: 'Ajouter un code',
    NAME: 'Nom',
    PARENT: 'Parent',
    CREATION_DATE: 'Créé le : %{date}',
    UPDATE_CODE: ' Modification du code',
    DELETE: 'Suppression de code',
    DELETE_CODE: 'Etes-vous sur de vouloir supprimer %{count} code?',
    DELETE_CODE_0: 'Etes-vous sur de vouloir supprimer %{count} code?',
    DELETE_CODE_plural: 'Etes-vous sur de vouloir supprimer ces %{count} codes?',
    DELETE_IMPACT: 'La suppression sera faite de manière définitive. Les codages ne seront plus présent dans les dossiers consernés.',
    STATUS: {
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif'
    },
  },
  pathologyCodingSearch: {
    TITLE: 'Codage pathologique local',
    PLACE_HOLDER: 'Rechercher un code',
    TO_DELETE: '%{rows} ligne(s) sélectionnée(s)',
    SORT_BY: 'Trier par',
    NO_RESULT: 'Il n\'y a pas de résultats. Veuillez lancer une nouvelle recherche.',
    ADD_CODE: 'Ajouter un code',
    UPDATE_CODE: ' Modification du code',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    CREATION_DATE: 'Créé le : %{date}',
    DELETE: 'Suppression de code',
    DELETE_CODE: 'Etes-vous sur de vouloir supprimer %{count} code?',
    DELETE_CODE_0: 'Etes-vous sur de vouloir supprimer %{count} code?',
    DELETE_CODE_plural: 'Etes-vous sur de vouloir supprimer ces %{count} codes?',
    DELETE_IMPACT: 'La suppression sera faite de manière définitive. Les codages ne seront plus présent dans les dossiers concernés.',
    NAME: 'Nom',
    STATUS: 'Statut',
    IS_ACTIVE: {
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif'
    },
  },
  userSearch: {
    TITLE: 'Recherche utilisateur',
    FIRST_NAME: 'Prénom',
    NAME: 'Nom',
    ROLE: 'Rôle',
    TYPE: 'Type',
    SEARCH_RESULT: 'Résultats de la recherche',
    USERS_FOUND: '%{number} utilisateurs ont été trouvés pour les critères : ',
    NO_RESULT: 'Il n\'y a pas de résultats. Veuillez lancer une nouvelle recherche.',
    OPEN_USER: 'Ouvrir l\'utilisateur',
    USERNAME: 'Nom d\'utilisateur',
    EMAIL: 'Email',
    LAST_NAME: 'Nom de famille',
  },
  userDetails: {
    TITLE: 'Profil utilisateur',
    CREATE: 'Créateur',
    BACK_TO_LIST: 'Retour à la liste',
    WOMAN: 'Femme',
    MAN: 'Homme',
    IDENTITY: 'Identité',
    ADMIN: 'Administrateur',
    GENDER: 'Genre',
    FIRST_NAME: 'Prénom',
    LAST_NAME: 'Nom de famille',
    USERNAME: 'Nom d\'utilisateur',
    EMAIL: 'Email',
    PHONE: 'Téléphone',
    ROLE: 'Rôle',
    DOCTOR_ID: 'N° RPPS',
    THIRD_PART: 'Utilisateur externe',
    YES: 'Oui',
    NO: 'Non',
    KNOWLEDGE: 'Langues et spécialités',
    LANGUAGES: 'Langues',
    START_TYPING: 'Commencez à écrire pour voir les propositions',
    SPECIALITIES: 'Spécialités',
    KNOWLEDGE_DETAILS: 'Détails',
    ADDRESS: 'Adresse',
    STREET: 'Rue',
    ZIPCODE: 'Code postal',
    CITY: 'Ville',
    COUNTRY: 'Pays',
    INFORMATION: 'Information sur l\'organisation',
    SITE: 'Site',
    BUSINESS_UNIT: 'Unité opérationnelle',
    TEAM: 'Equipe',
    SIGNATURE: 'Signature',
    IMPORT_IMAGE: 'Importer une image',
    wordings: {
      dragFile: 'Déposez votre signature',
      selectFile: 'Ou sélectionnez-la depuis un dossier',
    },
    DOCTOR_INFORMATION: 'Informations du médecin',
    TYPE: 'Type',
  },
  mail: {
    TITLE: 'Mail',
    SEARCH_TITLE: 'Rechercher un mail',
    SUBTITLE: 'Liste des mails',
    EDIT_TITLE: 'Editer un mail',
    label: {
      idBusinessUnit: 'IdBusinessUnit',
      mailType: 'MailType',
      subject: 'Subject',
      template: 'Template',
      mailId: 'IdMail',
      all: 'Tous',
    },
  },
  sms: {
    TITLE: 'Sms',
    SEARCH_TITLE: 'Rechercher un Sms',
    SUBTITLE: 'Liste des Sms',
    EDIT_TITLE: 'Editer un Sms',
    label: {
      idBusinessUnit: 'IdBusinessUnit',
      smsType: 'SmsType',
      template: 'Template',
      all: 'Tous',
    },
  },
  appointment: {
    TITLE: 'CALENDRIER DE RENDEZ-VOUS',
    form: {
      userProfile: 'Profil Utilisateur',
    },
    empty: 'Emplacement libre     ',
    booked: 'Emplacement réservé   ',
    old: 'Disponibilité existante',
    new: 'Nouvelle disponibilité',
    details: {
      TITLE: 'Rendez-vous le %{date}',
      hour: 'Rendez-vous à %{date}',
      formatDate: 'DD/MM/YYYY',
      patient: 'Informations patient : ',
      patientInfos: '%{firstname} - %{lastname} - %{birthdate}',
      caseArchived: 'Ce cas a déjà été traité et archivé.',
      caseTaken: 'Ce cas est pris en charge par: %{doctor}',
      caseAssigned: 'Ce cas est assigné à :',
    },
    detailsEmpty: {
      TITLE: 'Rendez-vous disponible le %{date}',
      hour: 'Rendez-vous disponible à %{date}',
      formatDate: 'DD/MM/YYYY',
    },
  },
  availability: {
    TITLE: 'Gérer les plages horaires',
    add: {
      TITLE: 'Ajouter des nouvelles plages horaire',
      content: 'Créer des nouvelles disponibilités pour l\'utilisateur/la spécialité sélectionné: ',
      no_user_selected: 'Vous devez séletionner un utilisateur pour pouvoir ajouter des créneaux',
      no_user: 'Pas d\'utilisateur sélectionné',
    },
    form: {
      hours: 'Heures',
      period: 'Période ',
      slotDuration: 'Durée du créneau (min)',
    },
    summarize: {
      title: 'Résumé de la création des créneaux :',
      from: 'Période : %{startDate} à %{endDate}',
      to: 'à',
      during: 'Durée des créneaux : %{duration} min',
    }
  },
  createRelatedCase: {
    title: 'Choissisez un creneau pour le rendez-vous',
    chooseUser: 'Veuillez choisir avec quel praticien vous souhaitez prendre rendez-vous : ',
    chooseSlot: 'Veuillez choisir un créneau sur lequel prendre le rendez-vous : ',
    userAvailabilities: 'Les diponibilités du praticien : %{carer}',
    summarize: {
      title: 'Résumé du rendez-vous à créer',
      patient: 'Vous allez créer un rendez-vous pour le patient : %{patient} ',
      profile: 'Avec le profil utilisateur : %{profile}',
      period: 'Du %{startDate} au %{endDate}',
    },
    redirectionChoice: 'Le rendez-vous a été créé ainsi que le cas lié, souhaitez-vous être redirigé vers ce nouveau cas ?',
  },
  calendar: {
    today: 'Aujourd\'hui',
    previous: 'Précédent',
    next: 'Suivant',
    month: 'Mois',
    week: 'Semaine',
  },
  fax: {
    CANCEL_TITLE: 'Annulation du fax',
    SEND_TITLE: 'Envoi du fax',
    SENDING: 'Envoi du fax en cours',
    SENT: 'Fax envoyé avec succès',
    LINK_EXPIRED: 'Lien expiré ou invalide',
    CANCELLING: 'Annulation en cours',
    CANCEL: 'Envoi du fax annulé',
    BACK: 'Accéder au site de la Téléconsultation',
  },
  internalBeneficiary: {
    TITLE: 'Internal beneficiary',
    BENEFICIARY_NAME: 'Beneficiary first name',
    BENEFICIARY_SURNAME: 'Beneficiary last name',
    BENEFICIARY_BIRTHDATE: 'Beneficiary birthdate',
    EMAIL_ADDRESS: 'Email address',
    PHONE_NUMBER: 'Phone number',
    GENDER: 'Gender',
    POLICY_NUMBER: 'Policy number',
    PROTOCOL_NUMBER: 'Protocol number',
    POLICY_START_DATE: 'Policy start date',
    POLICY_END_DATE: 'Policy end date',
    STREET: 'Street',
    CITY: 'City',
    COUNTRY: 'Country',
    POST_CODE: 'ZIP/Postal code',
    POLICY_HOLDER_NAME: 'Policy holder first name',
    POLICY_HOLDER_SURNAME: 'Policy holder last name',
    POLICY_HOLDER_CITY: 'Policy holder city',
    POLICY_HOLDER_PHONE_NUMBER: 'Policy holder phone number',
    SEARCH: {
      NO_RESULT: "There are no results. Please launch a new search.",
      FOUND_RESULT: "%{count} internal beneficiaries were found for these criteria",
      SEARCH_RESULT: "Search result"
    },
    BENEFICIARY_SECTION: 'Beneficiary Info',
    POLICY_SECTION: 'Policy Info',
    ADDRESS_SECTION: 'Address',
    POLICY_HOLDER_SECTION: 'Policy Holder Info',
    MODAL: {
      CONFIRM_TO_DELETE: 'Are you sure you want to delete this beneficiary in internal beneficiary list?',
      DELETE_TITLE: 'Delete',
      DELETE_BUTTON: 'Delete'
    }
  },
  common: {
    LAST_EDIT_BY: 'Dernière modification par %{fullName}',
    USER_GENDER_MALE: 'Male',
    USER_GENDER_FEMALE: 'Female'
  },
  bPartner: {
    TITLE: 'B-partenaire',
    TITLE_LIST: 'Liste B-partenaire',
    TITLE_CREATE: 'Création B-Partner',
    label: {
      B_PARTNER_NAME: 'Nom partenaire',
      B_PARTNER_CODE: 'Code du partenaire',
      BUSINESS_UNIT: 'Unité opérationnelle',
      STATUS: 'Statut',
      COMMERCIAL_NAME: 'Commercial AZP',
      DOCTORS_NOTES: 'Commentaires service téléconsultation',
    },
    STATUS: {
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif'
    },
    button: {
      ADD: 'Ajout',
      EXPORT: 'Exporter'
    },
    TABLE: {
      B_PARTNER_NAME: 'Nom partenaire',
      B_PARTNER_CODE: 'Code du partenaire',
      BUSINESS_UNIT: 'Unité opérationnelle',
      STATUS: 'Statut',
      COMMERCIAL_NAME: 'Commercial AZP',
      PAGINATION_INFO: 'De %{start} à %{end}/%{total}',
    },
    MODAL: {
      DELETE_TITLE: 'Supprimer',
      CONFIRM_TO_DELETE: 'Etes-vous sûr de vouloir supprimer ce partenaire?'
    },
    export: {
      bPartner: 'Nom partenaire',
      bPartnerCode: 'Code du partenaire',
      businessUnit: 'nité opérationnelle',
      status: 'Statut',
      commercialName: 'Commercial AZP',
      doctorNotes: 'Commentaires service téléconsultation',
      createdOn: 'Date de création',
    },
  },
  logoManagement: {
    TITLE: 'Logo',
    TITLE_LIST: 'Liste des logos',
    TITLE_CREATE: 'Date de création du logo',
    label: {
      LOGO_NAME: 'Nom du logo',
      BUSINESS_UNIT: 'Unité opérationnelle',
      LOGO_FILE: 'Fichier du logo',
      STATUS: 'Statut',
    },
    STATUS: {
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif'
    },
    TYPE_OF_PROTOCOL_TYPE: {
      CREDIT_CARD_TYPE: 'Oui',
      NOT_CREDIT_CARD_TYPE: 'Non',
    },
    TABLE: {
      LOGO_NAME: 'Nom du logo',
      BUSINESS_UNIT: 'Unité opérationnelle',
      LOGO_FILE: 'Fichier du logo',
      STATUS: 'Statut',
      CREATED: 'Date de création',
      PAGINATION_INFO: 'De %{start} à %{end}/%{total}',
    },
    export: {
      logoName: 'Nom du logo',
      businessUnit: 'Unité opérationnelle',
      status: 'Statut',
      createdOn: 'Date de création',
    },
  },
  protocol: {
    TITLE: 'Protocole',
    TITLE_LIST: 'Liste protocoles',
    TITLE_CREATE: 'Création Protocole',
    label: {
      PROTOCOL_NAME: 'Nom du protocole',
      PROTOCOL_NUMBER: 'Numéro protocole',
      B_PARTNER_NUMBER: 'Numéro B-partenaire',
      STATUS: 'Statut',
      B_PARTNER: 'B-partenaire',
      LEGAL_ENTITY: 'Entité légale',
      BRAND:'Marque',
      MEDICAL_TELECONSULTATION_PHONE_NUMBER:'Numéro dédié service téléconsultation',
      NUMBER_OF_CONSULTATIONS_AUTHORIZED_PER_YEAR: 'Nombre de téléconsultations autorisées par an (glissant)',
      PROVIDER_NETWORK:'Prestataire externe',
      COMMERCIAL_NAME:'Commercial AZP',
      THIRD_PARTY_SSO:'Accès par SSO',
      TYPE_OF_PROTOCOL:'Portefeuille CIC/Crédit Mutuel ?',
      VISA: 'Visa',
      LOGO:'Logo',
    },
    STATUS: {
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif'
    },
    TYPE_OF_PROTOCOL_TYPE: {
      CREDIT_CARD_TYPE: 'Oui',
      NOT_CREDIT_CARD_TYPE: 'Non',
    },
    button: {
      ADD: 'Ajout',
      EXPORT: 'Exporter'
    },
    message: {
      WARNING_FILL_BIN: 'Le protocole ne sera opérationnel qu’une fois la liste des BIN associés chargés dans le EPRM',
      ERROR_NOT_ALLOWED_ENABLE_BOTH_VISA_CIC: 'Un protocole ne peut être à la fois VISA et CIC/Credit Mutuel. Merci de corriger'
    },
    TABLE: {
      PROTOCOL_NAME: 'Nom du protocole',
      PROTOCOL_NUMBER: 'Numéro protocole',
      BUSINESS_UNIT: 'Unité opérationnelle',
      LEGAL_ENTITY: 'Entité légale',
      BRAND: 'Marque',
      MEDICAL_TELECONSULTATION_PHONE_NUMBER: 'Numéro dédié service téléconsultation',
      B_PARTNER: 'B-partenaire',
      NUMBER_OF_CONSULTATIONS_AUTHORIZED_PER_YEAR: 'Nombre de téléconsultations autorisées par an (glissant)',
      PROVIDER_NETWORK: 'Prestataire externe',
      COMMERCIAL_NAME: 'Commercial AZP',
      THIRD_PARTY_SSO: 'Accès par SSO',
      TYPE_OF_PROTOCOL: 'Type de protocole',
      STATUS: 'Statut',
      PAGINATION_INFO: 'De %{start} à %{end}/%{total}',
    },
    MODAL: {
      DELETE_TITLE: 'Supprimer',
      CONFIRM_TO_DELETE: 'Etes-vous sûr de vouloir supprimer ce protocole ?'
    },
    export: {
      protocolName: 'Nom du protocole',
      protocolNumber: 'Numéro protocole',
      businessUnit: 'Unité opérationnelle',
      legalEntityName: 'Entité légale',
      brandName: 'Marque',
      MedicalTeleconsultationPhoneNumber: 'Numéro dédié service téléconsultation',
      bPartner: 'B-partenaire',
      numberOfConsultationPerYear: 'Nombre de téléconsultations autorisées par an (glissant)r',
      providerNetworkDetails: 'Prestataire externe',
      commercialName: 'Commercial AZP',
      isThirdPartySso: 'Accès par SSO',
      typeOfProtocol: 'Portefeuille CIC/Crédit Mutuel ?',
      status: 'Statut',
      isCmCic: 'Portefeuille CIC/Crédit Mutuel ?',
      isVisa: 'Visa',
      createdOn: 'Date de création',
      logoName: 'Nom du logo',
      phoneNumber: 'Numéro de téléphone',
    },
  },
  legalEntity: {
    TITLE: 'Entité légale',
    TITLE_LIST: 'Liste entités légales',
    TITLE_CREATE: 'Entité légale de création',
    label: {
      LEGAL_ENTITY_NAME: 'Nom entité légale',
      LEGAL_ENTITY_CODE: 'Code entité légale',
      BUSINESS_UNIT: 'Unité opérationnelle',
      STATUS: 'Statut',
      EMAIL_FOOTER: 'Bas de mail',
      ADDRESS: 'Adresse',
    },
    STATUS: {
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif'
    },
    button: {
      ADD: 'Ajout',
      EXPORT: 'Exporter'
    },
    TABLE: {
      LEGAL_ENTITY_NAME: 'Nom entité légale',
      LEGAL_ENTITY_CODE: 'Code entité légale',
      BUSINESS_UNIT: 'Unité opérationnelle',
      STATUS: 'Statut',
      PAGINATION_INFO: 'De %{start} à %{end}/%{total}',
    },
    export: {
      legalEntityName: 'Nom entité légale',
      legalEntityCode: 'Code entité légale',
      businessUnit: 'Unité opérationnelle',
      status: 'Statut',
      emailFooter: 'Bas de mail',
      address: 'Adresse',
      createdOn: 'Date de création',
    },
  },
  brandManagement: {
    TITLE: 'Marque',
    TITLE_LIST: 'Liste des marques',
    TITLE_CREATE: 'Date de création',
    label: {
      BRAND_NAME: 'Nom de la marque',
      BRAND_CODE: 'Code de la marque',
      BUSINESS_UNIT: 'Unité opérationnelle',
      SENDER_MAIL: 'Emetteur email',
      PDA_LINK: 'Lien PDA',
      BRAND_CREATION: 'Date de création',
      STATUS: 'Statut',
    },
    STATUS: {
      ACTIVE: 'Actif',
      INACTIVE: 'Inactif'
    },
    button: {
      ADD: 'Ajout',
      EXPORT: 'Exporter'
    },
    TABLE: {
      BRAND_NAME: 'Nom de la marque',
      BRAND_CODE: 'Code de la marque',
      BUSINESS_UNIT: 'Unité opérationnelle',
      SENDER_MAIL: 'Emetteur email',
      PDA_LINK: 'Lien PDA',
      BRAND_CREATION: 'Date de création',
      STATUS: 'Statut',
      PAGINATION_INFO: 'De %{start} à %{end}/%{total}',
    },
    export: {
      brandName: 'Nom de la marque',
      brandCode: 'Code de la marque',
      businessUnit: 'Unité opérationnelle',
      status: 'Statut',
      senderEmail: 'Emetteur email',
      pdaLink: 'Lien PDA',
      createdOn: 'Date de création',
    },

  },
};

export default fr;
