'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDatetime = require('react-datetime');

var _reactDatetime2 = _interopRequireDefault(_reactDatetime);

require('react-datetime/css/react-datetime.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CoreozDatePicker = function (_React$Component) {
  _inherits(CoreozDatePicker, _React$Component);

  function CoreozDatePicker() {
    _classCallCheck(this, CoreozDatePicker);

    return _possibleConstructorReturn(this, (CoreozDatePicker.__proto__ || Object.getPrototypeOf(CoreozDatePicker)).apply(this, arguments));
  }

  _createClass(CoreozDatePicker, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          label = _props.label,
          input = _props.input,
          meta = _props.meta,
          required = _props.required,
          dateFormat = _props.dateFormat,
          disabled = _props.disabled,
          onlyDate = _props.onlyDate,
          theme = _props.theme,
          className = _props.className,
          onFocus = _props.onFocus,
          onChange = _props.onChange,
          onBlur = _props.onBlur,
          rest = _objectWithoutProperties(_props, ['label', 'input', 'meta', 'required', 'dateFormat', 'disabled', 'onlyDate', 'theme', 'className', 'onFocus', 'onChange', 'onBlur']);

      var hasError = meta.error;
      var inputClassName = theme + '-input-coreoz ' + (hasError ? theme + '-input-coreoz--error' : '');
      return _react2.default.createElement(
        'div',
        { className: theme + '-container-input-coreoz date-picker-coreoz ' + className },
        label && _react2.default.createElement(
          'div',
          {
            className: theme + '-label-input-coreoz ' + (!input.value ? theme + '-label-input-coreoz--empty' : '') + ' ' + (required ? theme + '-label-input-coreoz--required' : '') + ' ' + (hasError && theme + 'label-input-coreoz--error')
          },
          label
        ),
        _react2.default.createElement(_reactDatetime2.default, _extends({}, input, rest, {
          dateFormat: dateFormat === 'timepicker' ? false : dateFormat || 'L',
          inputProps: _extends({ required: required, disabled: disabled, className: inputClassName }, rest),
          timeFormat: dateFormat === 'timepicker' ? true : !onlyDate,
          closeOnSelect: true,
          onFocus: function (_onFocus) {
            function onFocus(_x) {
              return _onFocus.apply(this, arguments);
            }

            onFocus.toString = function () {
              return _onFocus.toString();
            };

            return onFocus;
          }(function (e) {
            input.onFocus(e);
            if (onFocus) onFocus(e);
          }),
          onChange: function (_onChange) {

            function onChange(_x2) {
              return _onChange.apply(this, arguments);
            }
   
            onChange.toString = function () {
              return _onChange.toString();
            };

            return onChange;
          }(function (e) {
            input.onChange(e);
            if (onChange) onChange(e);
          }),
          onBlur: function (_onBlur) {
            function onBlur(_x3) {
              return _onBlur.apply(this, arguments);
            }

            onBlur.toString = function () {
              return _onBlur.toString();
            };

            return onBlur;
          }(function (e) {
            if (onBlur) onBlur(e);
            input.onBlur(e);
          })
        })),
        hasError && _react2.default.createElement(
          'div',
          { className: theme + '-error-form error-form--field' },
          meta.error
        )
      );
    }
  }]);

  return CoreozDatePicker;
}(_react2.default.Component);

exports.default = CoreozDatePicker;