// @flow

import { store } from '../../network/reduce';
import { DASHBOARD_MENU } from './dashboardReducer';

export function toggleDashboardMenu(dashboardMenu: boolean) {
  return (dispatch: Function) => {
    dispatch(store(DASHBOARD_MENU, dashboardMenu));
  };
}
