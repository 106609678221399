// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozDatePickerBase as CoreozDatePicker, CoreozInputBase as CoreozInput } from '../../lib/coreoz-form-base/index';
import moment from 'moment';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import ModalEprm from '../modal/ModalEprm';
import Loader from '../Loader';
import { dateValidator, required } from '../../validator';
import type { PatientType } from '../../types/PatientTypes';
import { notifyError } from '../../network/notification';
import MorePoliciesSearchResult from './MorePoliciesSearchResult';
import {policiesApi, AddNewPolicyType} from '../../network/api/policiesApi';
import type { PolicySearch } from '../../network/api/processApi';
import { PolicySearchOrigin } from '../../enum';

type Props = {
  toggle: Function,
  patientDetail: {} | PatientType;
  modal: boolean,
  existingPolicies: Object[],
  reloadPolicies: Function,
  dispatch: Function,
}

type State = {
  loading: boolean,
  origin: string,
  lastSearchedValues: ?PolicySearch,
  policies: ?Object[],
  hasSearch: boolean,
}

type PolicySearchFormProps = {
  children: any,
  title: string,
  sendValues: Function,
  initialValues: Object,
}

const PolicySearchForm =
  ({
    children, title, initialValues, sendValues,
  }: PolicySearchFormProps) => (
    <div className="policy-search">
      <span className="title-panel">{I18n.t(title)}</span>
      <Form
        onSubmit={sendValues}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form
            className="identification-form"
            onSubmit={handleSubmit}
          >
            {children}
          </form>
        )}
      />
    </div>
  );

class AddPolicyModal extends React.Component<Props, State> {
  state = {
    loading: false,
    origin: PolicySearchOrigin.BENIFICIARY,
    policies: null,
    lastSearchedValues: null,
    hasSearch: false,
  };

  handleSubmit = (values: any) => {
    this.setState({ lastSearchedValues: values, loading: true });

    const valuesToSend = this.state.origin === PolicySearchOrigin.BENIFICIARY ?
      {
        firstName: values.firstName,
        lastName: values.lastName,
        birthDate: (values.birthDate) ? moment(values.birthDate).add(6, 'h').format("YYYY-MM-DD") : null,
      } :
      {
        policyHolderFirstName: values.policyHolderFirstName,
        policyHolderLastName: values.policyHolderLastName,
        policyNumber: values.policyNumber,
      };

    if (this.state.origin === PolicySearchOrigin.BENIFICIARY) {
      policiesApi.fetchPolicyBeneficiaryContract(valuesToSend)
        .then(response => response.text())
        .then(text => text.length ? JSON.parse(text) : [])
        .then(policies => this.setState({ hasSearch: true, loading: false, policies }))
        .catch((e) => {
          this.setState({ loading: false })
          notifyError(this.props.dispatch)(e);
        });
    } else {
      policiesApi.fetchPolicyHolderContract(valuesToSend)
        .then(response => response.text())
        .then(text => text.length ? JSON.parse(text) : [])
        .then(policies => this.setState({ hasSearch: true, loading: false, policies }))
        .catch((e) => {
          this.setState({ loading: false })
          notifyError(this.props.dispatch)(e);
        });
    }

  };


  setSearchMode = (origin: string) => {
    this.setState({ origin, hasSearch: false });
  };

  addNewPolicy = (policySelectedInfos: Object) => {

    const isBeneficiarySearch: Boolean = this.state.origin === PolicySearchOrigin.BENIFICIARY;

    const searchParam: AddNewPolicyType = {};
    searchParam.isBeneficiarySearch = isBeneficiarySearch;
    searchParam.policyNumber = policySelectedInfos.policyNumber;

    if(isBeneficiarySearch) {
      searchParam.beneficiaryFirstName = policySelectedInfos.beneficiaryFirstName;
      searchParam.beneficiaryLastName = policySelectedInfos.beneficiaryLastName;
      searchParam.beneficiaryBirthday = policySelectedInfos.beneficiaryBirthday ? moment(policySelectedInfos.beneficiaryBirthday, 'YYYY-MM-DD').format("YYYY-MM-DD") : null;
    } else {
      searchParam.policyHolderFirstName = policySelectedInfos.holderFirstName;
      searchParam.policyHolderLastName = policySelectedInfos.holderLastName;
    }

    policiesApi.addNewPolicy(
      this.props.patientDetail.id, searchParam
    ).then(() => {
      this.setState({ hasSearch: false });
      this.props.reloadPolicies();
      this.props.toggle();
    })
      .catch(notifyError(this.props.dispatch));
  };

  render() {
    return (
      <ModalEprm
        modal={this.props.modal}
        title={I18n.t('patientDetails.ADD_POLICY')}
        toggle={this.props.toggle}
        className="add-policy-modal"
      >
        <div className="search-toggle">
          <Button
            color="primary"
            onClick={() => this.setSearchMode(PolicySearchOrigin.BENIFICIARY)}
            outline={this.state.origin !== PolicySearchOrigin.BENIFICIARY}
          >
            {I18n.t('patientDetails.BENIFICIARY_SEARCH')}
          </Button>
          <Button
            color="primary"
            onClick={() => this.setSearchMode(PolicySearchOrigin.POLICY_HOLDER)}
            outline={this.state.origin !== PolicySearchOrigin.POLICY_HOLDER}
          >
            {I18n.t('patientDetails.POLICY_HOLDER_SEARCH')}
          </Button>
        </div>
        {
          this.state.origin === PolicySearchOrigin.BENIFICIARY &&
          <PolicySearchForm
            title="patientDetails.BENIFICIARY_SEARCH"
            sendValues={this.handleSubmit}
            initialValues={this.props.patientDetail}
          >
            <Field
              component={CoreozInput}
              className="form-input"
              name="firstName"
              validate={required}
              label={I18n.t('form.FIRST_NAME')}
            />
            <Field
              component={CoreozInput}
              className="form-input"
              name="lastName"
              validate={required}
              label={I18n.t('form.NAME')}
            />
            <div className="double-input">
              <div className="date-of-birth form-input">
                <Field
                  component={CoreozDatePicker}
                  name="birthDate"
                  label={I18n.t('form.DATE_OF_BIRTH')}
                  validate={e => dateValidator(e, I18n.t('date.DATE_FORMAT_ADMINISTRATION_FILE'), true)}
                  dateFormat={I18n.t('date.DATE_FORMAT')}
                  placeholder={I18n.t('form.placeholder')}
                  onlyDate
                  format={(stringValue) => {
                    if (stringValue) {
                      if (moment(stringValue, 'DD/MM/YYYY', true).isValid()) {
                        return moment(stringValue, 'DD/MM/YYYY');
                      }
                      if (moment(stringValue, 'YYYY-MM-DD', true).isValid()) {
                        return moment(stringValue, 'YYYY-MM-DD');
                      }
                    }
                    return stringValue;
                  }}
                />
              </div>
            </div>
            <div className="form-action">
              <Button
                color="primary"
                type="submit"
              >
                {
                  this.state.loading ?
                    <Loader small />
                    :
                    <span> <i className="button-icon fa fa-search" />{I18n.t('button.SEARCH')}</span>
                }
              </Button>
            </div>
          </PolicySearchForm>
        }
        {
          this.state.origin === PolicySearchOrigin.POLICY_HOLDER &&
          <PolicySearchForm
            title="patientDetails.POLICY_HOLDER_SEARCH"
            initialValues={this.props.patientDetail}
            sendValues={this.handleSubmit}
          >
            <Field
              component={CoreozInput}
              className="form-input"
              name="policyHolderFirstName"
              validate={required}
              label={I18n.t('form.FIRST_NAME')}
            />
            <Field
              component={CoreozInput}
              className="form-input"
              name="policyHolderLastName"
              validate={required}
              label={I18n.t('form.NAME')}
            />
            <Field
              component={CoreozInput}
              name="policyNumber"
              className="form-input"
              label={I18n.t('form.POLICY_NUMBER')}
            />
            <div className="form-action">
              <Button
                color="primary"
                type="submit"
              >
                {
                  this.state.loading ?
                    <Loader small />
                    :
                    <span> <i className="button-icon fa fa-search" />{I18n.t('button.SEARCH')}</span>
                }
              </Button>
            </div>
          </PolicySearchForm>
        }
        {
          this.state.hasSearch &&
          <div className="search-result">
            <span className="title-panel">{I18n.t('patientSearch.SEARCH_RESULT')}</span>
            {
              this.state.policies &&
              this.state.policies.length === 0 &&
              <div className="policy-search-no-result shadow">
                {I18n.t('patientSearch.NO_RESULT')}
              </div>
            }
            {
              this.state.policies &&
              this.state.policies.length > 0 &&
              <MorePoliciesSearchResult
                policies={this.state.policies}
                existingPolicies={this.props.existingPolicies}
                nextStep={this.addNewPolicy}
              />
            }
          </div>
        }
      </ModalEprm>
    );
  }
}

export default connect()(AddPolicyModal);
