// @ flow

import React from 'react';
import { USER_MANAGEMENT_DETAILS_PAGE_ROUTE, USER_MANAGEMENT_NEW_PAGE_ROUTE, USER_MANAGEMENT_PAGE_ROUTE } from '../../routes';
import UserManagement from './UserManagement';
import UserManagementDetails from './UserManagementDetails';
import PrivateRoute from '../../components/PrivateRoute';

export default function UserManagementRoutes() {
  return (
    <div>
      <PrivateRoute exact path={USER_MANAGEMENT_PAGE_ROUTE} component={UserManagement} />
      <PrivateRoute path={USER_MANAGEMENT_DETAILS_PAGE_ROUTE} component={UserManagementDetails} />
      <PrivateRoute path={USER_MANAGEMENT_NEW_PAGE_ROUTE} component={UserManagementDetails} />
    </div>
  );
}
