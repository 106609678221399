// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createSearchFromParams } from '../../services/queryParamsService';
import type { Case } from '../../types/CasesTypes';

type Props = {
  history: Object,
  queryParams: Object,
  title: string,
  case: Case,
  documents: Array<Object>,
  previewRoute: string,
  NO_REPORT: string,
  ADD_REPORT: string,
  delete: Function,
  disabled: boolean,
};

type State = {
  toggle: boolean,
};

class ShowAndEditDocument extends React.Component<Props, State> {
  state = {
    toggle: false,
  };

  toggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  render() {
    return (
      <div className="empty-category">
        <div className="category-title">
          {this.props.title}
        </div>
        {
          this.props.case.isFamilyDocConsent &&
          <div className="category-subtitle">{I18n.t('documentsToSend.thirdConsent')}</div>
        }
        {this.props.documents.length > 0 ?
          <div className="teleconsultation-document">
            {this.props.documents.map(document => (
              <div className="tile-document" id={document.id} key={document.id}>
                <div className="document-name">{document.name}</div>
                <div className="document-save-date">
                  <i className="fa fa-save" />
                  {moment(document.creationDate).format(I18n.t('date.DATE_LONG'))}
                </div>
                <div className="document-action">
                  <a
                    className={`${this.props.disabled ? 'link-disabled' : ''}`}
                    onClick={() => !this.props.disabled &&
                      this.props.history.push(this.props.previewRoute
                        + createSearchFromParams({
                          ...this.props.queryParams, documentId: document.id,
                        }))}
                  >
                    {I18n.t('button.EDIT')}
                  </a>
                  <i
                    className={`fa fa-trash ${this.props.disabled ? 'link-disabled' : ''}`}
                    onClick={() => !this.props.disabled && this.props.delete(document.id)}
                  />
                </div>
              </div>
            ))}
          </div>
          :
          <div className="empty-box">
            <i className="fa fa-laptop-medical empty-box-icon" />
            <div className="empty-box-title">{this.props.NO_REPORT}</div>
            <div>
              <Button
                color="primary"
                disabled={this.props.disabled}
                onClick={() => this.props.history.push(this.props.previewRoute
                  + createSearchFromParams({ ...this.props.queryParams, documentId: null }))}
              >
                <i className="fal fa-plus" />
                <span>{this.props.ADD_REPORT}</span>
              </Button>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default withRouter(connect()(ShowAndEditDocument));
