// @ flow

import React from 'react';
import { PATIENT_DETAILS_ROUTE, PATIENT_PAGE_ROUTE } from '../../routes';
import PatientSearch from './PatientSearch';
import PatientDetails from './PatientDetails';
import PrivateRoute from '../../components/PrivateRoute';

export default function PatientRoutes() {
  return (
    <div>
      <PrivateRoute exact path={PATIENT_PAGE_ROUTE} component={PatientSearch} />
      <PrivateRoute path={PATIENT_DETAILS_ROUTE} component={PatientDetails} />
    </div>
  );
}
