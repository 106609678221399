// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { Button } from 'reactstrap';
import Tile from '../Tile';
import { required } from '../../validator';
import { ghostPolicy } from '../../enum';

type Policy = {
  policyNumber: string,
  openCase: Case[],
  protocolNumber: string,
  partnerName: string,
  expiryDate: string,
  patient: Patient,
  isActive: boolean,
}

type Patient = {
  prmId: string,
  firstName: string,
  lastName: string,
  birthdate: string,
  policyNumber: string
}

type Case = {
  brand: string,
  channel: string,
  startDateAppointment: string,
  dateCreation: string,
  partner: string,
  patientName: string,
  protocolNumber: string,
  reasonForTheCall: string,
  speciality: string,
  status: string,
  userName: string,
  userRole: string,
}

type Props = {
  policies: Policy[],
  existingPolicies: Policy[],
  nextStep: Function,
}

type PolicyTileProps = {
  policy: Policy,
  form: Object,
  isDisableRadio: boolean

}

const isDummyPolicy = (policy: Policy) => {

  if(policy.policyNumber == ghostPolicy.POLICY_NUMBER && policy.protocolNumber == ghostPolicy.PROTOCOL_NUMBER){
    return true;
  }

  return false;
}

const PolicyTile = ({ policy, form, isDisableRadio = false }: PolicyTileProps) => (


  <Tile
    className="tile-policy"
    key={`${policy.policyId}`}
  >
    <div className="policy-number">
      <label>
        <Field
          render={props => (<input
            {...props}
            {...props.input}
            onChange={(e) => {
              form.change('firstName', policy.beneficiaryFirstName);
              form.change('lastName', policy.beneficiaryLastName);
              form.change('birthdate', policy.beneficiaryBirthday);
              form.change('protocolNumber', policy.protocolNumber);
              form.change('policySelected', policy);
              props.input.onChange(e);
            }}
          />)}
          name="protocolCheckbox"
          type="radio"
          value={(`${policy.policyId}`) || ''}
          validate={required}
          disabled={!policy.isActive || isDisableRadio}
        />
        <div className="number">{policy.protocolNumber}</div>
      </label>
    </div>
    <div className="patient-info">
      <div
        className="name"
        title={`${policy.holderFirstName} ${policy.holderLastName}`}
      >{policy.holderFirstName} {policy.holderLastName}
      </div>
      <div
        className="dob-policy"
      >{policy.beneficiaryBirthday ? `${moment(policy.beneficiaryBirthday).format(I18n.t('date.DATE_FORMAT'))} - ` : ''}{policy.policyNumber}
      </div>
    </div>
    <div className={`policy-status-container ${policy.isActive ? 'eligible' : 'not-eligible'}`}>
      <div
        className="policy-status"
      >{policy.isActive ? I18n.t('processCase.eligibility.ELIGIBLE') : I18n.t('processCase.eligibility.NOT_ELIGIBLE')}
      </div>
      {policy.expiryDate &&
        <div className="policy-expiration-date"> {I18n.t('processCase.eligibility.EXPIRATION_DATE')}
          {moment(policy.expiryDate).format(I18n.t('date.DATE_FORMAT'))}
        </div>}
    </div>
  </Tile>
);

function MorePoliciesSearchResult({ policies, nextStep, existingPolicies }: Props) {
  const getPartnerNames = () => [...new Set(policies.map(policy => policy.partnerName))];

  const hasOnePolicyEligible =
    () => policies.filter(policy => policy.isActive === true).length !== 0;

  const handleSubmitFct = (values: Object) => {
    nextStep(values.policySelected);
  };


  return (
    <div>
      <Form
        onSubmit={handleSubmitFct}
        render={({ handleSubmit, invalid, form }) => (
          <form
            className="identification-form"
            onSubmit={handleSubmit}
          >
            {getPartnerNames().map(partner => (
              <div className="policy-container" key={partner}>
                <div className="policy-brand">
                  {partner} ({policies
                    .filter(policy => policy.partnerName === partner).length})
                </div>
                {policies
                  .filter(policy => policy.partnerName === partner)
                  .some(policy => existingPolicies
                    .every(existingPolicy =>
                      policy.policyNumber !== existingPolicy.policyNumber)) &&
                  I18n.t('patientDetails.NEW_POLICY')
                }
                {policies
                  .filter(policy => policy.partnerName === partner &&
                    existingPolicies
                      .every(existingPolicy => policy.policyNumber !== existingPolicy.policyNumber))
                  .map(policy => (
                    <PolicyTile policy={policy} form={form} isDisableRadio={isDummyPolicy(policy)} />
                  ))}
                {policies
                  .filter(policy => policy.partnerName === partner)
                  .some(policy => existingPolicies
                    .some(existingPolicy =>
                      policy.policyNumber === existingPolicy.policyNumber))
                  && I18n.t('patientDetails.EXISTING_POLICY')
                }
                {policies
                  .filter(policy => policy.partnerName === partner &&
                    existingPolicies
                      .some(existingPolicy => policy.policyNumber === existingPolicy.policyNumber))
                  .map(policy => (
                    <PolicyTile policy={policy} form={form} isDisableRadio={true} />
                  ))}
              </div>
            ))}
            <div className="form-action">
              {hasOnePolicyEligible() &&
                <Button
                  color="primary"
                  type="submit"
                  disabled={invalid}
                >
                  <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.CONTINUE')}
                </Button>
              }

            </div>
          </form>
        )}
      />
    </div>
  );
}

export default MorePoliciesSearchResult;
