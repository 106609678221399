// @flow

import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase as CoreozInput } from '../../lib/coreoz-form-base/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalEprm from './ModalEprm';
import ResizableTextarea from '../ResizableTextarea';
import referentialApi from '../../network/api/referentialApi';
import { notifyError } from '../../network/notification';
import caseApi from '../../network/api/caseApi';
import { currentSessionService } from '../../services/sessionServiceInstance';

type Props = {
  modal: boolean,
  toggle: Function,
  case: Object,
  patient: Object,
  fetchPatientEmergency: Function,
  dispatch: Function,
  caseInfos: Object,
  callingCountry: Object,
  emergencyIsDetected: Function,
};

type State = {
  emergencyValues: {},
};

class ModalEmergency extends React.Component<Props, State> {
  state = {
    emergencyValues: {},
  };

  componentDidMount() {
    caseApi
      .getEmergency(this.props.case.id)
      .then(response => response.json())
      .then((emergency) => {
        const emergencyValues = {
          ...emergency,
        };
        this.setState({ emergencyValues });
      })
      .catch(e => console.log('Erreur apppel API', e));
  }

  isCallingFromAboard() {
    if (this.props.callingCountry) {
      return this.props.callingCountry &&
          this.props.callingCountry.isoCode &&
          (this.props.callingCountry.isoCode.toUpperCase() !== currentSessionService().currentUser().businessUnitCountryCode.toUpperCase());
    }
  }

  updateEmergency = (values: Object) => {
    const finalValues = {
      countryId: this.props.callingCountry && this.props.callingCountry.id,
      addressStreet: values.addressStreet,
      addressPostalCode: values.addressPostalCode,
      addressCity: values.addressCity,
      addressDepartment: values.addressDepartment,
      addressCountry: values.addressCountry,
      addressAdditionalInfo: values.addressAdditionalInfo,
      haveEmergencyContact: values.haveEmergencyContact,
      haveDetectedEmergency: values.haveDetectedEmergency,
      haveAwareToContactEmergency: values.haveAwareToContactEmergency,
    };
    caseApi
      .updateEmergency(this.props.case.patientId, this.props.case.id, finalValues)
      .then(() => {
        this.props.toggle();
        this.props.emergencyIsDetected();
        caseApi
          .getEmergency(this.props.case.id)
          .then(response => response.json())
          .then(emergencyValues => this.setState({ emergencyValues }))
          .catch(e => console.log('Erreur apppel API', e));
      })
      .catch(notifyError(this.props.dispatch));
  };

  isTrue = (value) => {
    if (typeof (value) === 'string') {
      value = value.trim().toLowerCase();
    }
    switch (value) {
      case true:
      case 'true':
        return true;
      default:
        return false;
    }
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('emergency.TITLE')}
        modal={this.props.modal}
        toggle={this.props.toggle}
        className="modal-EPRM"
        size="lg"
      >
        <Form
          onSubmit={this.updateEmergency}
          initialValues={this.state.emergencyValues}
          render={({ handleSubmit, values }) => (
            <form
              className="emergency-form"
              onSubmit={handleSubmit}
            >
              <div className="modal-emergency-container">
                <div className="current-country">
                  {I18n.t('emergency.CURRENT_COUNTRY')} {this.props.callingCountry && this.props.callingCountry.label}
                </div>
                <div className="label-container">
                  {I18n.t('emergency.AWARE_TO_CONTACT')}
                </div>
                <div className="radio-button-container">
                  <Field
                    className="base-input-coreoz"
                    component="input"
                    name="haveAwareToContactEmergency"
                    type="radio"
                    value="true"
                    format={value => String(value)}
                    parse={value => value === 'true'}
                    disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                  />
                  <span>{I18n.t('form.YES')}</span>
                  <Field
                    className="base-input-coreoz"
                    component="input"
                    name="haveAwareToContactEmergency"
                    type="radio"
                    value="false"
                    format={value => String(value)}
                    parse={value => value === 'true'}
                    disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                  />
                  <span>{I18n.t('form.NO')}</span>
                </div>
                {values.haveAwareToContactEmergency &&
                !this.isCallingFromAboard() &&
                <div>
                  <div className="label-container">
                    {I18n.t('emergency.CURRENT_ADDRESS')}
                  </div>
                  <div className="current-address-container">
                    <Field
                      label={I18n.t('administration.identity.address.STREET')}
                      component={CoreozInput}
                      name="addressStreet"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.identity.address.POSTCODE')}
                      component={CoreozInput}
                      name="addressPostalCode"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.identity.address.CITY')}
                      component={CoreozInput}
                      name="addressCity"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.identity.address.DEPARTMENT')}
                      component={CoreozInput}
                      name="addressDepartment"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.identity.address.COUNTRY')}
                      component={CoreozInput}
                      name="addressCountry"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      component={ResizableTextarea}
                      minrows={2}
                      maxrows={10}
                      label={I18n.t('emergency.MORE_INFO')}
                      name="addressAdditionalInfo"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                  </div>
                </div>
                }
                {!this.isCallingFromAboard() &&
                <Fragment>
                  <div className="label-container">
                    {I18n.t('emergency.LOCAL_EMERGENCY')}
                  </div>
                  <div className="radio-button-container">
                    <Field
                      className="base-input-coreoz"
                      component="input"
                      name="haveEmergencyContact"
                      type="radio"
                      value="true"
                      format={value => String(value)}
                      parse={value => value === 'true'}
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <span>{I18n.t('form.YES')}</span>
                    <Field
                      className="base-input-coreoz"
                      component="input"
                      name="haveEmergencyContact"
                      type="radio"
                      value="false"
                      format={value => String(value)}
                      parse={value => value === 'true'}
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <span>{I18n.t('form.NO')}</span>
                  </div>
                </Fragment>}
              </div>
              <div className="modal-emergency-action">
                <Button
                  color="secondary"
                  onClick={this.props.toggle}
                >
                  <i className="button-icon fa fa-undo" />
                  {I18n.t('button.CANCEL')}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  // prevent issue with the string "false" that is not truthy
                  disabled={this.isCallingFromAboard() ? !(values.haveAwareToContactEmergency) :
                    (!(values.haveAwareToContactEmergency && values.haveEmergencyContact)
                      || (this.props.caseInfos && !this.props.caseInfos.isEditable))}
                >
                  <i className="button-icon fal fa-exclamation-triangle" />
                  {I18n.t('emergency.CREATE')}
                </Button>
              </div>
            </form>
          )}
        />
      </ModalEprm>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(ModalEmergency));
