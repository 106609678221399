import React from 'react';
import {PROTOCOL_CREATE_PAGE_ROUTE, PROTOCOL_EDIT_PAGE_ROUTE, PROTOCOL_MANAGEMENT_PAGE_ROUTE} from '../../routes';
import PrivateRoute from '../../components/PrivateRoute';
import { ProtocolContextProvider } from '../../stores/protocol-management-context'
import ProtocolManagement from './protocolManagement'
import ProtocolRequest from "./protocolRequest";

export default function ProtocolManagementRoutes() {
    return (
        <ProtocolContextProvider>
            <PrivateRoute exact path={PROTOCOL_MANAGEMENT_PAGE_ROUTE} component={ProtocolManagement} />
            <PrivateRoute exact path={PROTOCOL_EDIT_PAGE_ROUTE} component={ProtocolRequest} />
            <PrivateRoute exact path={PROTOCOL_CREATE_PAGE_ROUTE} component={ProtocolRequest} />
        </ProtocolContextProvider>
    );
}
