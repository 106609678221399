// @flow

import { RestClientAuthenticated } from '../network';

const exportApi = {
  searchCaseKpi: (filters: Object) => new RestClientAuthenticated(`/exports/caseKpi`, 'POST').jsonBody(filters).execute(),
  searchPatientKpi: (filters: Object) => new RestClientAuthenticated(`/exports/patientKpi`, 'POST').jsonBody(filters).execute(),
  fetchAllBpartners: () => new RestClientAuthenticated('/exports/bpartners', 'GET').execute(),
  exportLogoKpi: (filters: Object) => new RestClientAuthenticated(`/exports/logo-kpi`, 'POST').jsonBody(filters).execute(),
  exportBusinessUnitKpi: (filters: Object) => new RestClientAuthenticated(`/exports/business-unit-kpi`, 'POST').jsonBody(filters).execute(),
  exportProtocolKpi: (filters: Object) => new RestClientAuthenticated(`/exports/protocol-kpi`, 'POST').jsonBody(filters).execute(),
  exportBrandKpi: (filters: Object) => new RestClientAuthenticated(`/exports/brand-kpi`, 'POST').jsonBody(filters).execute(),
  exportLegalEntityKpi: (filters: Object) => new RestClientAuthenticated(`/exports/legal-entity-kpi`, 'POST').jsonBody(filters).execute(),
  exportBPartnerKpi: (filters: Object) => new RestClientAuthenticated(`/exports/bpartner-kpi`, 'POST').jsonBody(filters).execute(),
};

export default exportApi;
