// @flow

// from https://codesandbox.io/s/7k742qpo36

import React from 'react';
import { FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import diff from 'object-diff';
import { AUTO_SAVE } from '../state/process/processReducer';
import { store } from '../network/reduce';

type Props = {
  ignoreFields: Array<string>,
  save: Function,
  values: Object,
  setFieldData: Function,
  active: boolean,
  dispatch: Function,

};

type State = { values: Object, submitting: boolean };

class AutoSave extends React.Component<Props, State> {
  idleTime: number;
  interval: IntervalID;

  constructor(props) {
    super(props);
    this.state = { values: props.values, submitting: false };
    this.idleTime = 0;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.idleTime > 0.5) {
        this.idleTime = 0;
        this.save(this.props.active, this.props);
      } else {
        this.idleTime += 0.1;
      }
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;

    // L'idée c'est que si on change de page alors que toutes les props de la page précédente
    // ne sont pas encore arrivé on repasse quand même l'autosave à vert.
    // Ca évite d'arrivé sur une page avec l'autosave en rouge indéfiniment.
    this.props.dispatch(store(AUTO_SAVE, true));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active && this.props.active !== nextProps.active) {
      this.save(this.props.active, nextProps);
    }

    if (this.props.values !== nextProps.values) {
      this.props.dispatch(store(AUTO_SAVE, false));
      this.idleTime = 0;
    }
  }

  promise: Promise<any>;

  save = async (blurredField, nextProps) => {

    const { values, setFieldData, save, ignoreFields, errors } = nextProps;

    if (ignoreFields && ignoreFields.includes(blurredField)) {
      this.props.dispatch(store(AUTO_SAVE, true));
      return;
    }

    //TODO faire mieux
    if (['birthdate'].includes(blurredField) && !values[blurredField]) {
      this.props.dispatch(store(AUTO_SAVE, true));
      return;
    }

    // si le champ est non valide alors on ne sauvegarde pas
    // (pour eviter les erreurs de sauvegarde PRM)
    if (Object.keys(errors).includes(blurredField)) {
      this.props.dispatch(store(AUTO_SAVE, true));
      return;
    }

    if (this.promise) {
      await this.promise;
    }

    const differences = Object.keys(diff(this.state.values, values)).filter(k => !ignoreFields || !ignoreFields.includes(k));
    const key = blurredField || differences[0];

    if (ignoreFields && ignoreFields.includes(key)) {
      this.props.dispatch(store(AUTO_SAVE, true));
      return;
    }

    if (key) {
      const oldValue = this.state.values[key] || '';
      const newValue = values[key] || '';

      if (oldValue === newValue) {
        this.props.dispatch(store(AUTO_SAVE, true));
        return;
      }
      this.setState({ submitting: true });
      setFieldData(blurredField, { saving: true });
      this.promise = save(key, oldValue, newValue);
      await this.promise;
      delete this.promise;
      this.setState({ submitting: false, values });
      setFieldData(blurredField, { saving: false });
      this.props.dispatch(store(AUTO_SAVE, true));
    }

  };

  render() {
    // This component doesn't have to render anything, but it can render
    // submitting state.
    return null;
  }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in save prop nicely
export default connect()(props => (
  <FormSpy
    {...props}
    subscription={{ active: true, values: true, errors: true }}
    component={AutoSave}
  />
));
