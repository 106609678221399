import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Loader from '../../components/Loader';
import protocolContext from '../../stores/b-partner-management-context';
import { CoreozInputBase, CoreozSelectBase } from '../../lib/coreoz-form-base/index';
import userApi from '../../network/api/userApi';
import {
  BUSINESS_UNIT_DETAILS_PAGE_ROUTE, LEGAL_ENTITY_EDIT_PAGE_ROUTE,
  LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE,
  PROTOCOL_MANAGEMENT_PAGE_ROUTE,
} from '../../routes';
import { required } from '../../validator';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { LegalEntityDetail } from '../../types/LegalEntityManagement';
import { notifyError, notifySuccess } from '../../network/notification';
import legalEntityApi from '../../network/api/legalEntityApi';
import hasPermission from "../../services/permissionService";
import {currentSessionService} from "../../services/sessionServiceInstance";

const useStyles = makeStyles(theme => ({
  backdrop: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const LegalEntityRequest = (props) => {
  const classes = useStyles();
  const isEdit = !!props.match.params.id || false;
  const legalEntityId = props.match.params.id || null;
  const { searchCriteriaContext, updateSearchCriteriaContext } = useContext(protocolContext);
  const [businessUnits, setBusinessUnits] = useState([]);

  const [button, setButton] = useState('');
  const [legalEntityDetail, setLegalEntityDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    fetchBusinessUnits();
    if (legalEntityId) {
      fetchLegalEntityDetail(legalEntityId);
    }
  }, []);

  async function fetchBusinessUnits(): void {
    const businessUnitsResponse = await userApi.fetchBusinessUnits().then(response => response.json());

      const buList = businessUnitsResponse.filter(v => hasPermission( 'EDITABLE_BUSINESS_UNIT_LEGAL_ENTITY') || v.id === currentSessionService().currentUser().businessUnitId.toString());
      setBusinessUnits(buList);

  }

  async function fetchLegalEntityDetail(id: number): void {
    setLoading(true);

    const legalEntityResponse: LegalEntityDetail = await props.dispatch(legalEntityApi.fetchById(id));

    setLegalEntityDetail(legalEntityResponse);

    setLoading(false);
  }

  const onPreviousHandler = () => {
    props.history.push(LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE);
  };

  const handleChange = (e) => {

  };

  const onSave = (dataForm) => {
    const rawData = {
      ...dataForm,
    };

    if (isEdit && legalEntityId) {
      legalEntityApi
        .update(legalEntityId, rawData)
        .then(props.dispatch(notifySuccess))
        .catch(props.dispatch(notifyError));
    } else {
      legalEntityApi
        .save(rawData)
        .then(response => response.text())
        .then(props.dispatch(notifySuccess))
        .then((id) => {
          setTimeout(() => {
            props.history.push(LEGAL_ENTITY_EDIT_PAGE_ROUTE.replace(':id', id), props.location.state);
          }, 3000);
        })
        .catch(props.dispatch(notifyError));
    }
  };


  // eslint-disable-next-line no-shadow
  const formatForFront = (legalEntityDetail: LegalEntityDetail) => ({
    legalEntityCode: legalEntityDetail.legalEntityCode ? `${legalEntityDetail.legalEntityCode}` : '',
    legalEntityName: legalEntityDetail.legalEntityName || '',
    isActive: legalEntityDetail.isActive ? 'true' : 'false',
    businessUnitId: legalEntityDetail.businessUnitId || '',
    address: legalEntityDetail.address || '',
    emailFooter: legalEntityDetail.emailFooter || '',
  });

  return (
    <div className="legal-entity-detail user-details" id="protocol-details">

      <div className="legal-entity-detail-header">

        <div
          className="back-to-list"
          onClick={onPreviousHandler}
        >
          <i className="fa fa-arrow-left" />
          {I18n.t('userDetails.BACK_TO_LIST')}
        </div>

        <div className="title">
          {isEdit ? I18n.t('legalEntity.TITLE') : I18n.t('legalEntity.TITLE_CREATE')}
          {
                        isEdit && legalEntityDetail &&
                        <div className="legal-entity-detail-info">{legalEntityDetail.legalEntityName}</div>
                    }
        </div>


      </div>

      <Form
        onSubmit={onSave}
        initialValues={formatForFront(legalEntityDetail)}
        render={({ handleSubmit, form, invalid }) => (
          <form onSubmit={handleSubmit}>
            <div className="user-action">
              <Button
                color="secondary"
                type="button"
                onClick={onPreviousHandler}
              >
                <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={invalid}
              >
                <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
              </Button>

            </div>


            <div className="content">

              <div className="user-category">

                <div className="content-container">

                  <Field
                    autoComplete="off"
                    type="text"
                    component={CoreozInputBase}
                    name="legalEntityCode"
                    id="legalEntityCode"
                    label={I18n.t('legalEntity.label.LEGAL_ENTITY_CODE')}
                    validate={required}
                    required
                    maxLength="9"
                    format={stringValue => stringValue && stringValue.replace(/[^\d]/g, '')}
                  />
                  <Field
                    autoComplete="off"
                    component={CoreozInputBase}
                    name="legalEntityName"
                    id="legalEntityName"
                    label={I18n.t('legalEntity.label.LEGAL_ENTITY_NAME')}
                    validate={required}
                    required
                    maxLength="50"
                  />

                  <Field
                    name="businessUnitId"
                    id="businessUnitId"
                    autoComplete="off"
                    type="text"
                    component={CoreozSelectBase}
                    label={I18n.t('legalEntity.label.BUSINESS_UNIT')}
                    list={businessUnits && businessUnits.map(bu => ({
                      id: bu.id,
                      label: bu.name,
                    }))}
                    validate={required}
                    required
                  />

                  <Field
                    label={I18n.t('legalEntity.label.EMAIL_FOOTER')}
                    component={CoreozInputBase}
                    type="textarea"
                    name="emailFooter"
                    id="emailFooter"
                    minrows={5}
                    validate={required}
                    required
                  />

                  <Field
                    className="required"
                    label={I18n.t('legalEntity.label.ADDRESS')}
                    component={CoreozInputBase}
                    type="textarea"
                    name="address"
                    id="address"
                    minrows={5}
                    validate={required}
                    required
                  />


                  {
                                        isEdit &&
                                        <div className="radio-button-container">
                                          <div className="radio-button-label">{I18n.t('legalEntity.label.STATUS')}</div>
                                          <div className="choices">
                                            <Field
                                              component="input"
                                              name="isActive"
                                              type="radio"
                                              value="true"
                                              format={value => String(value)}
                                              parse={value => value === 'true'}
                                            />
                                            <span>{I18n.t('legalEntity.STATUS.ACTIVE')}</span>
                                            <Field
                                              component="input"
                                              name="isActive"
                                              type="radio"
                                              value="false"
                                              format={value => String(value)}
                                              parse={value => value === 'true'}
                                            />
                                            <span>{I18n.t('legalEntity.STATUS.INACTIVE')}</span>
                                          </div>
                                        </div>
                                    }

                </div>
              </div>

            </div>

            <div className="user-action mt-5">
              <Button
                color="secondary"
                type="button"
                onClick={onPreviousHandler}
              >
                <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={invalid}
              >
                <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
              </Button>
            </div>

          </form>
                )}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <div className="center-child search-loader"><Loader /></div>
      </Backdrop>
    </div>
  );
};

export default connect()(LegalEntityRequest);
