// @flow

import { RestClientAuthenticated } from '../network';

const prescriptionApi = {
  createPrescriptionContent: (caseId: string, patientId: string) => new RestClientAuthenticated(`/prescription/patient/${patientId}/case/${caseId}`, 'POST').execute(),
  getExistingPrescriptionContent: (patientId: string, documentId: string) => new RestClientAuthenticated(`/prescription/edit/patient/${patientId}/document/${documentId}`, 'GET').execute(),
  updatePrescription: (caseId: string, documentId: string, prescription: any) => new RestClientAuthenticated(`/prescription/${documentId}/case/${caseId}`, 'PUT').jsonBody(prescription).execute(),
  savePrescription: (caseId: string, prescription: any) => new RestClientAuthenticated(`/prescription/case/${caseId}`, 'POST').jsonBody(prescription).execute(),
  sendSecureLink: (idDocument: string, idCase: string, data: Object) => new RestClientAuthenticated(`/prescription/send/secure-link/${idDocument}/${idCase}`, 'POST').jsonBody(data).execute(),
  deletePrescription: (caseId: string, documentId: string) => new RestClientAuthenticated(`/prescription/case/${caseId}/document/${documentId}`, 'DELETE').execute(),
  removeMedicament: (medicamentId: string, documentId: string) => new RestClientAuthenticated(`/prescription/${documentId}/medicament/${medicamentId}`, 'DELETE').execute(),
};
export default prescriptionApi;
