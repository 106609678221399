import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Table } from 'reactstrap';
import type { SearchResultContentItem } from '../../types/ProtocolManagement';

type Props = {
	currentItems: SearchResultContentItem[],
	goToView: Function,
};

function ProtocolTable({ currentItems, goToView }: Props) {
	return (
		<div className="table-container">
			<Table bordered responsive>
				<thead>
					<tr>
						<th style={{ minWidth: '150px' }}>
							{I18n.t('protocol.TABLE.PROTOCOL_NUMBER')}
						</th>
						<th style={{ minWidth: '180px' }}>
							{I18n.t('protocol.TABLE.PROTOCOL_NAME')}
						</th>
						<th style={{ minWidth: '240px' }}>
							{I18n.t('protocol.TABLE.B_PARTNER')}
						</th>
						<th style={{ minWidth: '200px' }}>
							{I18n.t('protocol.TABLE.LEGAL_ENTITY')}
						</th>
						<th style={{ minWidth: '200px' }}>
							{I18n.t('protocol.TABLE.BRAND')}
						</th>
						<th style={{ minWidth: 'min-content' }}>
							{I18n.t('protocol.TABLE.STATUS')}
						</th>
					</tr>
				</thead>
				<tbody>
					{currentItems &&
						currentItems.map((item) => (
							<tr
								key={item.protocolId}
								onClick={() => {
									goToView(item.protocolId);
								}}
							>
								<td>{item.protocolNumber}</td>
								<td>{item.protocolName || ''}</td>
								<td>{`${item.bpartner.code} - ${item.bpartner.name}`}</td>
								<td>{`${item.legalEntity.code} - ${item.legalEntity.name}`}</td>
								<td>{`${item.brand.code} - ${item.brand.name}`}</td>
								<td>
									{item.isActive
										? I18n.t('protocol.STATUS.ACTIVE')
										: I18n.t('protocol.STATUS.INACTIVE')}
								</td>
							</tr>
						))}
				</tbody>
			</Table>
		</div>
	);
}

export default ProtocolTable;
