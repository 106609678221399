// @flow

import { store } from '../../network/reduce';
import mailApi from '../../network/api/mailApi';
import { notifyError } from '../../network/notification';
import { MAILS } from './mailReducer';

export const fetchMails = () => (dispatch: Function) => mailApi
  .fetch()
  .then(response => response.json())
  .then((responseJson) => {
    dispatch(store(MAILS, responseJson));
  })
  .catch(dispatch(notifyError));

