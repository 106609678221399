// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'reactstrap';
import { Field } from 'react-final-form';
import { CoreozInputBase as CoreozInput } from '../../../lib/coreoz-form-base/index';
import { connect } from 'react-redux';
import PlmPanel from '../../../components/panel/PlmPanel';

type Props = {
  caseInfos: Object,
};

class Insurance extends React.PureComponent<Props> {
  render() {
    return (
      <Row>
        <Col xs="12">
          <PlmPanel className="identity">
            <span className="title-panel">{I18n.t('administration.insurance.TITLE')}</span>
            <Field
              label={I18n.t('administration.insurance.SOCIAL_SECURITY_NUMBER')}
              component={CoreozInput}
              name="socialSecurityNumber"
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            />
          </PlmPanel>
        </Col>
      </Row>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(Insurance));
