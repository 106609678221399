// @flow

import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import NotificationsSystem from 'reapop';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { notificationTheme } from '../../components/modal/modalNotification/theme';
import { notifyError, notifyReloadPage, notifySuccess } from '../../network/notification';
import type { Credentials, LoginStage, OtpMethod } from '../../types/LoginTypes';
import { inWeboAlias, login, otpRequest, preAuth } from '../../services/sessionService';
import { currentLanguage } from '../../services/languages';
import { sendReset } from '../../services/forgotPasswordService';
import { changeLanguage } from '../../state/businessUnit/businessUnitService';
import Loader from '../../components/Loader';
import ForgotPasswordModal from '../forgotPassword/ForgotPasswordModal';

import * as Neon from '../../inwebo-simple-neon-lib';

type Props = {
  handleSubmit: Function,
  isConnected: boolean,
  dispatch: Function,
  history: Object,
}

type States = {
  showForgotPasswordModal: boolean,
  loginStage: LoginStage,
  credentials: Credentials,
  otpMethodUsed: OtpMethod,
  otpMethodsAvailable: OtpMethod,
  enrollDevice: boolean,
  activationCode: string,
  alias: string,
  login: string,
  logins: Array<any>,
  isTwoFactorEnabled: boolean,
  loading: boolean,
}

class Login extends React.Component<Props, States> {
  state = {
    showForgotPasswordModal: false,
    loginStage: 'FIRST_FACTOR',
    credentials: null,
    otpMethodUsed: null,
    otpMethodsAvailable: null,
    enrollDevice: false,
    activationCode: null,
    alias: null,
    login: null,
    logins: [],
    isTwoFactorEnabled: true,
    loading: false,
  };

  componentDidMount() {
    if (window.location.hostname === 'localhost') {
      alert('InWebo authentication will NOT work using localhost. Please use 127.0.0.1 instead.');
    }
    inWeboAlias()
      .then((alias) => {
        this.setState({ alias });
        const neon = new Neon.Neon(alias, 'ePRM Application');
        window.neon = neon;
        neon
          .initOnline()
          .then((logins) => {
            this.setState({ logins });
          })
          .catch((e) => {
            console.log('Error during initialization', e);
          });
      }).catch(e => console.log('Erreur apppel API', e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { loginStage, credentials } = this.state;
    const prevLoginStage = prevState.loginStage;
    const { history } = this.props;
    if (prevLoginStage !== loginStage) {
      if (loginStage === 'INWEBO_CHECK') {
        if (this.state.logins
          .filter(userLogin => userLogin === this.state.login).length > 0) {
          this.onSubmitSecond(credentials, true);
        } else {
          this.setState({ loginStage: 'ASK_ENROLL' });
        }
      }
    }
  }

  onSubmitAskEnroll = (value: boolean) => {
    const { credentials } = this.state;
    otpRequest(credentials)
      .then(data => this.setState({
        otpMethodsAvailable: data.otpMethodsAvailable,
        otpMethodUsed: data.otpMethodUsed,
        loginStage: 'SECOND_FACTOR',
        enrollDevice: value,
      }))
      .catch(notifyError(this.props.dispatch));
  }

  onChangeRequestedOtpMethod = (requestedOtpMethod: OtpMethod) => {
    const { credentials } = this.state;
    return otpRequest({ ...credentials, requestedOtpMethod })
      .then((data) => {
        this.setState({
          otpMethodsAvailable: data.otpMethodsAvailable,
          otpMethodUsed: data.otpMethodUsed,
          loginStage: 'SECOND_FACTOR',
        });
      })
      .catch(notifyError(this.props.dispatch));
  };

  onSubmitFirst = (credentials: Credentials) => {
    preAuth(credentials)
      .then((data) => {
        this.setState({ isTwoFactorEnabled: data.isTwoFactorEnabled });
        if (data.isTwoFactorEnabled) {
          this.setState({
            credentials,
            loginStage: 'INWEBO_CHECK',
            login: data.login,
          });
        } else {
          return this.onSubmitSecond(credentials, false);
        }
      })
      .catch(notifyError(this.props.dispatch));
  }

  onSubmitSecond = (credentials, alreadyEnrolled) => {
    const { enrollDevice } = this.state;
    this.setState({ loading: true });
    if (alreadyEnrolled) {
      window.neon.getOnlineOtpWithPin(this.state.login, '')
        .then((otpResult) => {
          login({ ...credentials, enrollDevice, otp: otpResult })
            .then(() => {
              this.props.dispatch(changeLanguage(currentLanguage()));
              this.props.history.push('/');
            })
            .catch((e) => {
              notifyError(this.props.dispatch)(e);
              this.setState({ loading: false });
            });
        })
        .catch((e) => {
          this.setState({ loginStage: 'FIRST_FACTOR' });
          notifyReloadPage(this.props.dispatch);
          console.log('Error while getting otp code', e);
          this.setState({ loading: false });
        });
    } else if (!this.state.isTwoFactorEnabled || !enrollDevice) {
      login({ ...credentials, enrollDevice })
        .then(() => {
          this.props.dispatch(changeLanguage(currentLanguage()));
          this.props.history.push('/');
        })
        .catch((e) => {
          notifyError(this.props.dispatch)(e);
          this.setState({ loading: false });
        });
    } else if (enrollDevice) {
      login({ ...credentials, enrollDevice })
        .then((loginData) => {
          window.neon.activateWithPin(loginData.activationCode, '')
            .then(() => {
              console.log('Login enrolled');
              this.props.dispatch(changeLanguage(currentLanguage()));
              this.props.history.push('/');
            })
            .catch((e) => {
              console.log('Error during enrolment', e);
              this.props.dispatch(changeLanguage(currentLanguage()));
              this.props.history.push('/');
            });
        })
        .catch((e) => {
          notifyError(this.props.dispatch)(e);
          this.setState({ loading: false });
        });
    }
  };

  setForgotPasswordModal = (value: boolean) => {
    this.setState({ showForgotPasswordModal: value });
  };

  sendForgotPasswordEmail = (login: String) => {
    sendReset(login)
      .then(() => this.setForgotPasswordModal(false))
      .then(() => this.props.dispatch(notifySuccess(this.props.dispatch, null, I18n.t('notify.success.SEND_EMAIL'))))
      .catch(notifyError(this.props.dispatch));
  };

  render() {
    const { loginStage, showForgotPasswordModal } = this.state;

    let content;
    switch (loginStage) {
      case 'FIRST_FACTOR':
        content = this.renderLogin();
        break;
      case 'INWEBO_CHECK':
        content = this.renderInWeboCheck();
        break;
      case 'ASK_ENROLL':
        content = this.renderAskEnroll();
        break;
      case 'SECOND_FACTOR':
        content = this.renderSecond();
        break;
    }

    return (
      <div>
        <NotificationsSystem theme={notificationTheme} />
        {(this.props.isConnected && loginStage === 'FIRST_FACTOR') ? (
          <Redirect to="/home" />
        ) : (
          <div id="login-container">
            <h1>{I18n.t('login.ePRM')}</h1>
            <h2><Translate value="login.TITLE" /></h2>
            {content}
            {showForgotPasswordModal &&
            <ForgotPasswordModal
              sendForgotPasswordEmail={this.sendForgotPasswordEmail}
              closedSelf={() => this.setForgotPasswordModal(false)}
              modal={showForgotPasswordModal}
            />}
          </div>
        )}
      </div>
    );
  }

  renderLogin() {
    return (
      <Form
        onSubmit={this.onSubmitFirst}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="field-group">
              <Field
                name="userName"
                component="input"
                placeholder={I18n.t('login.IDENTIFIER')}
                type="text"
                className="form-control"
              />
              <Field
                name="password"
                component="input"
                placeholder={I18n.t('login.PASSWORD')}
                type="password"
                className="form-control"
              />
              <div className="password-link-container">
                <a
                  href="#"
                  onClick={() => this.setForgotPasswordModal(true)}
                >
                  <Translate value="login.FORGOT_PASSWORD" />
                </a>
              </div>
            </div>
            <button type="submit" className="btn btn-primary"><Translate value="login.SIGNIN" />
            </button>
          </form>
        )}
      />
    );
  }

  renderInWeboCheck() {
    return (
      <Loader />
    );
  }

  renderSecond() {
    const { credentials, otpMethodUsed, otpMethodsAvailable } = this.state;
    const otpMethodUsedLower = otpMethodUsed.toLowerCase();
    return (
      <Form
        onSubmit={data => this.onSubmitSecond({ ...data, ...credentials }, false)}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h6><Translate value={`login.${otpMethodUsedLower}.CODE`} /></h6>
            <div className="field-group">
              <Field
                name="otp"
                component="input"
                placeholder={I18n.t(`login.${otpMethodUsedLower}.OTP`)}
                type="text"
                className="form-control"
              />
              <>
                {otpMethodsAvailable.map((m) => {
                  if (m !== otpMethodUsed) {
                    return (
                      <div className="text-right">
                        <a href="#" onClick={() => this.onChangeRequestedOtpMethod(m)}>
                          <Translate value={`login.alternatives.${m}`} />
                        </a>
                      </div>
                    );
                  }
                })}
              </>
            </div>
            {this.state.loading ?
              <div className="center-child"><Loader /></div>
              :
              <button type="submit" className="btn btn-primary"><Translate value={`login.${otpMethodUsedLower}.NEXT`} />
              </button>
            }
          </form>
        )}
      />
    );
  }

  renderAskEnroll() {
    return (
      <div>
        <h6><Translate value="login.enroll.TITLE" /></h6>
        <div className="instructions">
          <Translate value="login.enroll.INSTRUCTIONS" />
        </div>
        <div className="enroll-buttons">
          <button className="btn btn-primary" onClick={() => this.onSubmitAskEnroll(true)}>
            <Translate value="login.enroll.YES" />
          </button>
          <button className="btn btn-primary" onClick={() => this.onSubmitAskEnroll(false)}>
            <Translate value="login.enroll.NO" />
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  isConnected: state.login.isConnected,
}))(Login));
