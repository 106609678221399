// @flow

import React from 'react';
import { Field, Form } from 'react-final-form';
import { CoreozDatePickerBase } from '../../lib/coreoz-form-base/index';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dateValidator, simpleDateValidator } from '../../validator';
import PlmPanel from '../../components/panel/PlmPanel';
import operationsApi from '../../network/api/operationsApi';
import { Roles } from '../../enum';
import type { DashboardCaseInformations, DashboardFixedValues } from '../../types/OperationsType';
import TeamAvailabilities from '../../components/operations/TeamAvailabilities';
import { notifyError } from '../../network/notification';
import { CASE_PAGE_ROUTE } from '../../routes';
import hasPermission from '../../services/permissionService';
import userApi from '../../network/api/userApi';

type State = {
  showTeamDrawer: boolean,
  fixedValues: ?DashboardFixedValues,
  caseValues: ?DashboardCaseInformations,
  periodFrom: string,
  periodTo: string,
  statAppointment: {},
}

type Props = {
  dispatch: Function,
  history: {
    push: Function,
  }
}

class Operations extends React.Component<Props, State> {
  state = {
    showTeamDrawer: false,
    fixedValues: null,
    caseValues: null,
    periodFrom: moment().startOf('day').format(),
    periodTo: moment().add(1, 'd').startOf('d').format(),
    statAppointment: [],
    waitingTime: null,
  };

  formatDateTime = (stringValue: string) => {
    if (stringValue) {
      if (moment(stringValue, I18n.t('date.DATE_FORMAT'), true).isValid()) {
        return moment(stringValue, I18n.t('date.DATE_FORMAT'));
      }
      if (moment(stringValue, moment.ISO_8601).isValid()) {
        return moment(stringValue);
      }
    }
    return stringValue;
  };

  findCountAvailableTeams = (type: string) => {
    if (!this.state.fixedValues) {
      return '0/0';
    }
    const teamOfType =
      this.state.fixedValues.teamList.filter(teamPerson => teamPerson.type === type);

    return `${teamOfType.filter(team => team.isAvailable).length}/${teamOfType.length}`;
  };

  findOpenCases = (type: string) => {
    if (!this.state.fixedValues) {
      return 0;
    }
    const caseLevelFound =
      this.state.fixedValues.casesLevels.find(caseLevel => caseLevel.type === type);
    return !caseLevelFound ? 0 : caseLevelFound.count;
  };

  updateCaseValues = (values: Object) => {
    const { periodFrom, periodTo } = values;
    this.setState({ periodFrom, periodTo }, () => {
      this.searchCaseValues();
    });
  };

  searchCaseValues = () => {
    const { periodFrom, periodTo } = this.state;
    operationsApi.loadCaseValues(periodFrom, periodTo)
      .then(res => res.json())
      .then(caseValues => this.setState({ caseValues }))
      .catch(notifyError(this.props.dispatch));
  };

  goToCaseSearch = (team: string) => {
    if (hasPermission('ACCESS_CASES_DASHBOARD')) {
      if (team !== '') {
        this.props.history.push(CASE_PAGE_ROUTE, { teamInCharge: team });
      } else {
        this.props.history.push(CASE_PAGE_ROUTE);
      }
    }
  };

  toggleTeamDrawer = () => this.setState({ showTeamDrawer: !this.state.showTeamDrawer });

  componentDidMount() {
    operationsApi.loadFixedValues()
      .then(response => response.json())
      .then(fixedValues => this.setState({ fixedValues }))
      .catch(e => console.log('Erreur apppel API', e));
    this.searchCaseValues();
    this.fetchWaitTime();
  }

  fetchWaitTime = () => {
    userApi
      .getWaitTime()
      .then(response => response.text())
      .then((waitingTime) => {
        this.setState({ waitingTime });
      })
      .catch(this.props.dispatch(notifyError));
  };

  render() {
    return (
      <div className="operations">
        <div className="title-container">
          <div className="title">{I18n.t('operations.TITLE')}</div>
        </div>
        <div className="search-bar">
          <Form
            onSubmit={this.updateCaseValues}
            initialValues={{
              periodFrom: this.state.periodFrom,
              periodTo: this.state.periodTo,
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="criteria-line period">
                  <div className="period-field">
                    <Field
                      className="periodFrom"
                      name="periodFrom"
                      component={CoreozDatePickerBase}
                      placeholder={I18n.t('caseSearch.periodFromPlaceHolder')}
                      validate={e => dateValidator(e, moment.ISO_8601)}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      format={this.formatDateTime}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                    />
                    <i className="fa fa-arrow-right" />
                    <Field
                      className="periodTo"
                      name="periodTo"
                      component={CoreozDatePickerBase}
                      placeholder={I18n.t('caseSearch.periodToPlaceHolder')}
                      validate={e => simpleDateValidator(e, moment.ISO_8601)}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      format={this.formatDateTime}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                    />
                    <Button
                      type="submit"
                      color="primary"
                      className="validation-button"
                    >
                      <i className="button-icon fa fa-search" />
                      {I18n.t('button.SEARCH')}
                    </Button>
                  </div>
                </div>
              </form>
              )}
          />
        </div>
        <Row className="operation-main-row">
          <Col lg="12" md="12">
            <Row>
              <Col lg="6" sm="12">
                <PlmPanel >
                  <div className="title-panel-header">
                    <span className="title-panel">{I18n.t('operations.TEAM_AVAILABILITIES')}</span>
                    <button className="details btn btn-link"
                            onClick={() => this.toggleTeamDrawer()}>{I18n.t('operations.DETAILS')}</button>
                  </div>
                  {
                    this.state.fixedValues &&
                    Object.keys(Roles).map(role => (
                      <div className="subtitle-panel" key={role}>
                        <p>{I18n.t(`role.${role}`)}</p>
                        <p>{this.findCountAvailableTeams(role)}</p>
                      </div>
                    ))
                  }
                </PlmPanel>
              </Col>
              <Col lg="6" sm="12">
                <PlmPanel >
                  <div className="title-panel mb-3 mt-2">{I18n.t('operations.CASE_LEVEL')}</div>
                  {
                    this.state.fixedValues &&
                    Object.keys(Roles).map(role => (
                      <div className="team-availability" key={role}>
                        <p>{I18n.t(`role.${role}`)}</p>
                        <button
                          className="btn btn-link no-shadow"
                          onClick={() => this.goToCaseSearch(role)}>
                          {this.findOpenCases(role)}
                        </button>
                      </div>
                    ))
                  }
                </PlmPanel>
              </Col>
            </Row>
            {
              this.state.caseValues &&
              <Row className="operation-case-details">
                <Col className="case-operation-dashboard" lg="12">
                  <div className="case-operation-dashboard--title">
                    {I18n.t('caseSearch.periodFrom')} {moment(this.state.periodFrom).format(I18n.t('date.DATE_FORMAT'))} {I18n.t('caseSearch.periodTo')} {moment(this.state.periodTo).format(I18n.t('date.DATE_FORMAT'))}
                  </div>
                  <Row>
                    <Col lg="6" sm="12">
                      <PlmPanel >
                        <div className="title-panel mb-3 mt-2">{I18n.t('operations.CASES_STATUS')}</div>

                        <div className="subtitle-panel">
                          <p>{I18n.t('operations.case.ARCHIVED')}</p>
                          <p className="btn-link no-shadow" onClick={() => this.goToCaseSearch('')}>
                            {this.state.caseValues.archived}
                          </p>
                        </div>
                        <div className="subtitle-panel">
                          <p>{I18n.t('operations.case.ALL')}</p>
                          <p> {this.state.caseValues.allCasesDuringPeriod}
                          </p>
                        </div>
                        <div className="title-dashboard">{I18n.t('operations.DASHBOARD_INFOS')}</div>
                        <div className="subtitle-panel">
                          <p>{I18n.t('operations.case.ALL_DASHBOARD')}</p>
                          <p> {this.state.caseValues.allCasesOnDashboard}
                          </p>
                        </div>
                        <div className="container-panel">
                          <div className="subtitle-panel">{I18n.t('operations.case.FROM_PDA')}</div>
                          <div className="text-panel">
                            <p>{I18n.t('operations.case.WAITING_PDA')}</p>
                            <p>{this.state.caseValues.waitingFromPDA}</p>
                          </div>
                          <div className="text-panel">
                            <p>{I18n.t('operations.case.WAITING_APPOINT_PDA')}</p>
                            <p>{this.state.caseValues.waitingFromPDAWithAppoint}</p>
                          </div>
                          <div className="text-panel">
                            <p>{I18n.t('operations.case.TAKEN_IN_CHARGE')}</p>
                            <p>{this.state.caseValues.takenInChargePDA}</p>
                          </div>
                          <div className="text-panel">
                            <p>{I18n.t('operations.case.ASSIGNED')}</p>
                            <p>{this.state.caseValues.assignedPDA}</p>
                          </div>
                        </div>

                        <div className="container-panel">
                          <div className="subtitle-panel">{I18n.t('operations.case.FROM_EPRM')}</div>
                          <div className="text-panel">
                            <p>{I18n.t('operations.case.WAITING_NURSE')}</p>
                            <p>{this.state.caseValues.waitingForNurseFromEprm}</p>
                          </div>
                          <div className="text-panel">
                            <p>{I18n.t('operations.case.WAITING_DOCTOR')}</p>
                            <p>{this.state.caseValues.waitingForDoctorFromEprm}</p>
                          </div>
                          <div className="text-panel">
                            <p>{I18n.t('operations.case.TAKEN_IN_CHARGE')}</p>
                            <p>{this.state.caseValues.takenInChargeEprm}</p>
                          </div>
                          <div className="text-panel">
                            <p>{I18n.t('operations.case.ASSIGNED')}</p>
                            <p>{this.state.caseValues.assignedEprm}</p>
                          </div>
                        </div>
                      </PlmPanel>
                    </Col>
                    <Col lg="6" sm="12">
                      <PlmPanel>
                        <div className="title-panel mb-3 mt-2">{I18n.t('operations.WAITING_TIME_FROM_PDA')}</div>
                        <div className="team-availability">
                          <p>{I18n.t('channel.VIDEO')}</p>
                          <p>
                            {
                              this.state.waitingTime
                            }
                            {I18n.t('operations.case.minutes')}
                          </p>
                        </div>
                      </PlmPanel>
                      <PlmPanel >
                        <div className="title-panel mb-3 mt-2">{I18n.t('operations.PROCESSING_TIME')}</div>
                        <div className="team-availability">
                          <p>{I18n.t('operations.case.WITH_PDA')}</p>
                          <p>
                            {
                              this.state.caseValues &&
                              this.state.caseValues.processingTimeFromPDA
                            } {I18n.t('operations.case.minutes')}
                          </p>
                        </div>
                        <div className="team-availability">
                          <p>{I18n.t('operations.case.WITHOUT_PDA')}</p>
                          <p>
                            {
                              this.state.caseValues &&
                              this.state.caseValues.processingTimeFromEprm
                            } {I18n.t('operations.case.minutes')}
                          </p>
                        </div>
                      </PlmPanel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          </Col>
          {/*
          <Col className="colonne">
            <div className="case-operation-dashboard--title">
              <PlmPanel >
                <div className="colonne-title">
                  {I18n.t('operations.appointment.APPOINTMENT_TITLE')}
                </div>
                <div className="colonne-row">
                  <div className="row-title">{I18n.t('operations.appointment.CURENT_DAY')}</div>
                  <div className="row-border">{this.state.statAppointment[0] && this.state.statAppointment[0].qualification}</div>
                  <div className="row-no-border">{this.state.statAppointment[0] && this.state.statAppointment[0].teleconsultation}</div>
                </div>
                <div className="colonne-row">
                  <div className="row-title">{I18n.t('operations.appointment.DAY_PLUS_1')}</div>
                  <div className="row-border">{this.state.statAppointment[1] && this.state.statAppointment[1].qualification}</div>
                  <div className="row-no-border">{this.state.statAppointment[1] && this.state.statAppointment[1].teleconsultation}</div>
                </div>
                <div className="colonne-row">
                  <div className="row-title">{I18n.t('operations.appointment.DAY_PLUS_2')}</div>
                  <div className="row-border">{this.state.statAppointment[2] && this.state.statAppointment[2].qualification}</div>
                  <div className="row-no-border">{this.state.statAppointment[2] && this.state.statAppointment[2].teleconsultation}</div>
                </div>
                <div className="colonne-row">
                  <div className="row-title">{I18n.t('operations.appointment.DAY_PLUS_3')}</div>
                  <div className="row-border">{this.state.statAppointment[3] && this.state.statAppointment[3].qualification}</div>
                  <div className="row-no-border">{this.state.statAppointment[3] && this.state.statAppointment[3].teleconsultation}</div>
                </div>
                <div className="colonne-row">
                  <div className="row-title">{I18n.t('operations.appointment.DAY_PLUS_4')}</div>
                  <div className="row-border">{this.state.statAppointment[4] && this.state.statAppointment[4].qualification}</div>
                  <div className="row-no-border">{this.state.statAppointment[4] && this.state.statAppointment[4].teleconsultation}</div>
                </div>
                <div className="colonne-row">
                  <div className="row-title">{I18n.t('operations.appointment.DAY_PLUS_5')}</div>
                  <div className="row-border">{this.state.statAppointment[5] && this.state.statAppointment[5].qualification}</div>
                  <div className="row-no-border">{this.state.statAppointment[5] && this.state.statAppointment[5].teleconsultation}</div>
                </div>
                <div className="colonne-row">
                  <div className="row-title">{I18n.t('operations.appointment.DAY_PLUS_6')}</div>
                  <div className="row-border">{this.state.statAppointment[6] && this.state.statAppointment[6].qualification}</div>
                  <div className="row-no-border">{this.state.statAppointment[6] && this.state.statAppointment[6].teleconsultation}</div>
                </div>
                <div className="colonne-row">
                  <div className="row-title">{I18n.t('operations.appointment.DAY_PLUS_7')}</div>
                  <div className="row-border">{this.state.statAppointment[7] && this.state.statAppointment[7].qualification}</div>
                  <div className="row-no-border">{this.state.statAppointment[7] && this.state.statAppointment[7].teleconsultation}</div>
                </div>
              </PlmPanel>
            </div>
          </Col> -->
          */}
        </Row>
        {
          this.state.fixedValues &&
          <TeamAvailabilities
            teamList={this.state.fixedValues.teamList}
            toggleTeamDrawer={this.toggleTeamDrawer}
            showTeamDrawer={this.state.showTeamDrawer}
          />
        }
      </div>
    );
  }
}

export default withRouter(connect()(Operations));
