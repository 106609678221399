// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from 'reactstrap/es/Button';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase as CoreozInput } from '../../lib/coreoz-form-base/index';
import { I18n } from 'react-redux-i18n';
import ModalEprm from '../../components/modal/ModalEprm';
import medicinesApi from '../../network/api/medicinesApi';
import { notifyError } from '../../network/notification';
import ResizableTextarea from '../../components/ResizableTextarea';
import MedicineRadioButtons from './MedicineRadioButtons';
import { notTooShort } from '../../validator';
import type { PrescriptionMedicine } from '../../types/PrescriptionTypes';
import Loader from '../../components/Loader';

type Props = {
  modal: boolean,
  toggle: Function,
  dispatch: Function,
  writeNewMedicinePrescription: Function,
  previousMedicinePrescription: PrescriptionMedicine,
}
type State = {
  hasValidate: boolean,
  loading: boolean,
  medicinesList: Array<any>,
};

class ModalMedicine extends React.PureComponent<Props, State> {
  state = {
    hasValidate: false,
    loading: false,
    medicinesList: [],
  };

  toggle = () => {
    this.setState({ medicinesList: [] });
    this.props.toggle();
  };

  searchMedicine = (data) => {
    this.setState({
      loading: true,
      hasValidate: true,
    });
    medicinesApi.getMedicine(data.medicine)
      .then(response => response.json())
      .then((medicinesList) => {
        this.setState({
          medicinesList,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(notifyError)(e);
      });
  };

  addMedicineToPrescription = (data) => {
    if (!(this.props.previousMedicinePrescription &&
      this.props.previousMedicinePrescription.name)) {
      const medicineSelected =
        this.state.medicinesList.filter(elem => elem.idProduct === data.selectedProductId)[0];
      medicinesApi
        .getMedicineInnName(medicineSelected.idVmp)
        .then(response => response.text())
        .then((innName) => {
          const medicine = {
            name: medicineSelected.title,
            vidalId: medicineSelected.idVmp ? medicineSelected.idVmp : medicineSelected.idProduct,
            innName,
            instructions: data.instructions,
          };
          this.props.writeNewMedicinePrescription(medicine);
        })
        .catch(notifyError(this.props.dispatch));
    } else {
      this.props.writeNewMedicinePrescription(data);
    }
  };

  getMedicineName = (values) => {
    if (this.props.previousMedicinePrescription && this.props.previousMedicinePrescription.name) {
      return this.props.previousMedicinePrescription.name;
    } else if (this.state.medicinesList.length > 0 && this.state.medicinesList
      .filter(elem => elem.idProduct === values.selectedProductId).length > 0) {
      return this.state.medicinesList.filter(elem => elem.idProduct === values.selectedProductId)[0].title;
    }
    return '';
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('medicine.TITLE')}
        modal={this.props.modal}
        toggle={this.toggle}
        className="modal-EPRM medicine"
        size="xl"
      >
        <div className="panel-body">
          <Form
            className="form-horizontal"
            onSubmit={data => this.searchMedicine(data)}
            render={({ handleSubmit, values, invalid }) => (
              !(this.props.previousMedicinePrescription &&
                this.props.previousMedicinePrescription.name) &&
                <form onSubmit={handleSubmit} className="medicine-search">
                  <span className="title">{I18n.t('medicine.SEARCH')}</span>
                  <Field
                    component={CoreozInput}
                    name="medicine"
                    validate={notTooShort}
                  />
                  {!this.state.loading ?
                    <Button
                      className="button search submit"
                      disabled={invalid}
                    >
                      <i className="fa fa-search" />
                      {I18n.t('button.SEARCH')}
                    </Button>
                  :
                    <Loader />}
                </form>
            )}
          />
          <hr />
          <Form
            className="form-horizontal medicine-search"
            onSubmit={this.addMedicineToPrescription}
            initialValues={{
              ...this.props.previousMedicinePrescription,
              instructions: this.props.previousMedicinePrescription && this.props.previousMedicinePrescription.instructions && (this.props.previousMedicinePrescription.instructions.includes(I18n.t('medicine.instruction.TAKE')) ?
                this.props.previousMedicinePrescription.instructions.substring(0, this.props.previousMedicinePrescription.instructions.indexOf(I18n.t('medicine.instruction.TAKE'))) :
                this.props.previousMedicinePrescription.instructions),
            }}
            render={({ handleSubmit, values, invalid }) => (
              ((this.state.medicinesList.length > 0 || (this.props.previousMedicinePrescription && this.props.previousMedicinePrescription.name))) ?
                  (<form onSubmit={handleSubmit}>
                    <MedicineRadioButtons
                      propositions={this.state.medicinesList}
                      name="selectedProductId"
                      needed={!(this.props.previousMedicinePrescription &&
                      this.props.previousMedicinePrescription.name)}
                    />
                    <span>
                      {I18n.t('medicine.HAVE_SELECTED')}
                      {this.getMedicineName(values)}
                    </span>
                    <div className="medicine-footer">
                      <div className="medicine-footer-fields">
                        <Field
                          className="details total-width details"
                          component={ResizableTextarea}
                          name="instructions"
                          placeholder={I18n.t('medicine.DETAIL_PLACEHOLDER')}
                          minrows={2}
                          maxrows={5}
                        />
                      </div>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={invalid}
                      >
                        <i className="fa fa-plus" />
                        {I18n.t('medicine.ADD')}
                      </button>
                    </div>

                  </form>)
              :
              (<div>{this.state.hasValidate && I18n.t('medicine.noResult')}</div>))}
          />
        </div>
      </ModalEprm>
    );
  }
}

export default withRouter(connect()(ModalMedicine));
