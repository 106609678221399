// @flow

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import DocumentsToSend from './components/DocumentsToSend';
import caseApi from '../../network/api/caseApi';
import { store } from '../../network/reduce';
import { CASE_INFOS, USER_IS_AVAILABLE } from '../../state/process/processReducer';
import { DASHBOARD_PAGE_ROUTE } from '../../routes';
import { notifyArchived, notifyError } from '../../network/notification';
import caseReportApi from '../../network/api/caseReportApi';
import { RecordingReason, teleAdviceCode } from '../../enum';
import { currentSessionService } from '../../services/sessionServiceInstance';
import Loader from '../../components/Loader';
import { Button } from 'reactstrap';

type Props = {
  queryParams: Object,
  history: Function,
  dispatch: Function,
  caseInfos: Object,
}

type State = {
  caseLoaded: Object,
  listDocuments: Object,
}

class SendingDocuments extends React.Component<Props, State> {
  state = {
    caseLoaded: {},
    listDocuments: {},
  };

  archivedCase = () => {
    this.setState({ loading: true });
    caseApi
      .archiveCase(this.props.queryParams.caseId)
      .then(() => {
        this.props.dispatch(store(CASE_INFOS, null));
        this.props.dispatch(store(USER_IS_AVAILABLE, currentSessionService().currentUser().isAvailable));
        this.props.history.push(DASHBOARD_PAGE_ROUTE);
        this.setState({ loading: false });
      })
      .then(this.props.dispatch(notifyArchived))
      .catch(this.props.dispatch(notifyError));
  };

  componentDidMount = () => {
    this.fetchDocumentsList();
    caseApi
      .fetchDetailById(this.props.queryParams.caseId)
      .then(response => response.json())
      .then(caseLoaded => this.setState({ caseLoaded }))
      .catch(this.props.dispatch(notifyError));
  };

  fetchDocumentsList = () => {
    caseReportApi
      .getDocumentByCaseId(this.props.queryParams.caseId)
      .then(response => response.json())
      .then(listDocuments => this.setState({ listDocuments }))
      .catch(this.props.dispatch(notifyError));
  };

  render() {
    return (
      <div className="documents-to-send">
        <header>
          <div>
            <span className="documents-to-send-title">
              <i className="fas fa-file" />
              {I18n.t('documentsToSend.title')}
            </span>
          </div>
        </header>
        <div className="documents-to-send-content">
          <div>{I18n.t('documentsToSend.phrase1')}</div>
          {
            this.state.caseLoaded &&
            this.state.caseLoaded.isFamilyDocConsent &&
            <div>{I18n.t('documentsToSend.thirdConsent')}</div>
          }
          {(this.state.listDocuments && this.state.listDocuments.prescriptionList) &&
          <DocumentsToSend
            isPrescription
            canBeSend
            listDocuments={this.state.listDocuments.prescriptionList}
            patientId={this.props.queryParams.patientId}
            caseId={this.props.queryParams.caseId}
            title={I18n.t('documentsToSend.prescription.title')}
            fetchDocument={() => this.fetchDocumentsList()}

          />
          }
          {(this.state.listDocuments && this.state.listDocuments.medicalReportList) &&
          <DocumentsToSend
            isPrescription={false}
            isAdviceReport={this.state.caseLoaded.caseQualification === teleAdviceCode}
            canBeSend
            listDocuments={this.state.listDocuments && this.state.listDocuments.medicalReportList}
            patientId={this.props.queryParams.patientId}
            caseId={this.props.queryParams.caseId}
            title={I18n.t('documentsToSend.medicalReport.title')}
            fetchDocument={() => this.fetchDocumentsList()}
          />
          }
          {(this.state.listDocuments && this.state.listDocuments.patientSendList) &&
          <DocumentsToSend
            isPrescription={false}
            canBeSend={false}
            listDocuments={this.state.listDocuments && this.state.listDocuments.patientSendList}
            patientId={this.props.queryParams.patientId}
            caseId={this.props.queryParams.caseId}
            title={I18n.t('documentsToSend.patientDocument.title')}
            fetchDocument={() => this.fetchDocumentsList()}

          />
          }
        </div>
        <div className="documents-to-send-footer">
          <button
            className="btn btn-primary"
            onClick={this.archivedCase}
            disabled={(this.props.caseInfos && !this.props.caseInfos.isEditable) || this.state.loading}
          >
            {
              this.state.loading ?
                <Loader small/>
                :
                <div>
                  {I18n.t('documentsToSend.buttonArchive')}
                </div>
            }
          </button>
          <div>{I18n.t('documentsToSend.textArchive')}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(SendingDocuments));
