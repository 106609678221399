
import React, { useState, useEffect, useContext } from "react";
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { dateValidator } from '../../validator';
import Tile from '../../components/Tile';
import { CoreozDatePickerBase, CoreozInputBase } from '../../lib/coreoz-form-base/index';
import PaginatedList from '../../components/PaginatedList';
import Loader from '../../components/Loader';
import InternalBeneficiaryTile from '../../components/internalBeneficiary/InternalBeneficiaryTile';
import {SearchCriteriaType, SearchResultType, InternalBeneficiaryResponseType} from '../../types/InternalBeneficiaryTypes';
import DynamicPaginatedList from '../../components/DynamicPaginatedList';
import internalBeneficiaryApi from '../../network/api/internalBeneficiaryApi';
import { notifyError } from '../../network/notification';
import InternalBeneficiaryContext from '../../stores/internal-beneficiary-management-context';
import {INTERNAL_BENEFICIARY_DETAIL_PAGE_ROUTE} from '../../routes';

const InternalBeneficiaryManagement = (props) => {

  const {searchCriteriaContext, updateSearchCriteriaContext} = useContext(InternalBeneficiaryContext);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSearchResult, setTotalSearchResult] = useState(0);

  const searchResultListPageSize: number = 10;
  
  useEffect( () => {

    if(searchCriteriaContext){
      fetchInternalBeneficiary(searchCriteriaContext);
    }

  }, []);

  async function fetchInternalBeneficiary(searchCriteria: SearchCriteriaType) {
    
    setLoading(true);

    if(searchCriteria.pageSize === undefined) {
      searchCriteria.pageSize = searchResultListPageSize;
    }

    const searchResultResponse : SearchResultType = await props.dispatch(internalBeneficiaryApi.search(searchCriteria));

    updateSearchCriteriaContext({...searchCriteria});

    setLoading(false);

    setSearchResults((searchResultResponse) ? searchResultResponse.internalBeneficiaries : []);

    setTotalSearchResult((searchResultResponse) ? searchResultResponse.totalSize : 0);
    
  }

  const resetSearchResults = () => {
    setSearchResults([]);
    setTotalSearchResult(0);
  }

  const routeChange = (itemId: number) =>{ 
    props.history.push(INTERNAL_BENEFICIARY_DETAIL_PAGE_ROUTE.replace(':id', itemId))
  }

  return (
    
    <div className="internal-beneficiary-management-container" id="internalBeneficiaryManagement">
      <div className="title-container">
        <div className="title">{I18n.t('internalBeneficiary.TITLE')}</div>
      </div>
      <div className="search-bar">
        <Form
            onSubmit={fetchInternalBeneficiary}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
        
                    <Field
                      name="beneficiaryFirstName"
                      id="beneficiaryFirstNameBeneficiarySearch"
                      component={CoreozInputBase}
                      placeholder={I18n.t('internalBeneficiary.BENEFICIARY_NAME')}
                      className="m-0"
                      defaultValue={searchCriteriaContext && searchCriteriaContext.beneficiaryFirstName}
                    />
                    <Field
                      name="beneficiaryLastName"
                      id="beneficiaryLastNameBeneficiarySearch"
                      component={CoreozInputBase}
                      placeholder={I18n.t('internalBeneficiary.BENEFICIARY_SURNAME')}
                      className="m-0"
                      defaultValue={searchCriteriaContext && searchCriteriaContext.beneficiaryLastName}
                    />
                    <Field
                      component={CoreozDatePickerBase}
                      placeholder={I18n.t('internalBeneficiary.BENEFICIARY_BIRTHDATE')}
                      onlyDate
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      name="beneficiaryBirthDate"
                      id="beneficiaryBirthDateBeneficiarySearch"
                      format={value => (value ? moment(value).format('DD/MM/YYYY') : '')}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ? momentValue.format('YYYY-MM-DD') : momentValue)}
                      autoComplete="off"
                      className="m-0"
                    />
                    <Field
                      name="policyHolderFirstName"
                      id="policyHolderFirstNameBeneficiarySearch"
                      component={CoreozInputBase}
                      placeholder={I18n.t('internalBeneficiary.POLICY_HOLDER_NAME')}
                      className="m-0"
                      defaultValue={searchCriteriaContext && searchCriteriaContext.policyHolderFirstName}
                    />
                    <Field
                      name="policyHolderLastName"
                      id="policyHolderLastNameBeneficiarySearch"
                      component={CoreozInputBase}
                      placeholder={I18n.t('internalBeneficiary.POLICY_HOLDER_SURNAME')}
                      className="m-0"
                      defaultValue={searchCriteriaContext && searchCriteriaContext.policyHolderLastName}
                    />
                    <Field
                      name="policyNumber"
                      id="policyNumberBeneficiarySearch"
                      component={CoreozInputBase}
                      placeholder={I18n.t('internalBeneficiary.POLICY_NUMBER')}
                      className="layout-third-row m-0"
                      defaultValue={searchCriteriaContext && searchCriteriaContext.policyNumber}
                    />
                    <Field
                      name="protocolNumber"
                      id="protocolNumberBeneficiarySearch"
                      component={CoreozInputBase}
                      placeholder={I18n.t('internalBeneficiary.PROTOCOL_NUMBER')}
                      className="layout-third-row m-0"
                      defaultValue={searchCriteriaContext && searchCriteriaContext.protocolNumber}
                    />
                    <div className='layout-third-row search-box p-0'>
                      <Button  type="submit" color="primary" className="validation-button m-0" id="searchBtnBeneficiarySearch">
                          <i className="fa fa-search"/>{I18n.t('button.SEARCH')}
                      </Button>
                      <Button  type="button" color="primary" className="validation-button m-0" onClick={() => { form.reset(); resetSearchResults()}} id="resetBtnBeneficiarySearch">
                          <i className="fa fa-redo-alt"/>{I18n.t('button.RESET')}
                      </Button>
                    </div>
              </form>
            )}
          />
      </div>
      <div className="search-result">
        <div className="result-title">{I18n.t('internalBeneficiary.SEARCH.SEARCH_RESULT')}</div>
        {
          !loading && (searchResults.length == 0) ? <div className="no-result">{I18n.t('internalBeneficiary.SEARCH.NO_RESULT')}</div>
          : <div className="result-list" id="result-list">
              <div className="criteria">
                {
                  <div>
                    <div>{I18n.t('internalBeneficiary.SEARCH.FOUND_RESULT', { count: totalSearchResult })} <span>:</span> </div>

                    <DynamicPaginatedList
                      pageSize={searchResultListPageSize}
                      list={searchResults}
                      render={searchResult => (
                        <InternalBeneficiaryTile
                          key={searchResult.id}
                          internalBeneficiary={searchResult}
                          routeChange={routeChange}
                        />
                      )}
                      totalSearchResult={totalSearchResult}
                      callBackFetchList={fetchInternalBeneficiary}
                      lastSearchObject={{...searchCriteriaContext}}
                    />

                  </div>
                }
              </div>
            </div>
        } 

        {
          loading && <div className="center-child search-loader"><Loader/></div> 
        }

      </div>
    </div>
  );
}

export default withRouter(connect()(InternalBeneficiaryManagement));
