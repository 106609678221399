import React from 'react';
import {
    BUSINESS_UNIT_CREATE_PAGE_ROUTE,
    BUSINESS_UNIT_DETAILS_PAGE_ROUTE,
    BUSINESS_UNIT_PAGE_ROUTE,
} from '../../routes';
import PrivateRoute from '../../components/PrivateRoute';
import { BusinessUnitContextProvider } from '../../stores/business-unit-management-context';
import BusinessUnitManagement from './businessUnitManagement';
import BusinessUnitRequest from './businessUnitRequest';

export default function BusinessUnitManagementRoutes() {
  return (
    <BusinessUnitContextProvider>
      <PrivateRoute exact path={BUSINESS_UNIT_PAGE_ROUTE} component={BusinessUnitManagement} />
      <PrivateRoute exact path={BUSINESS_UNIT_DETAILS_PAGE_ROUTE} component={BusinessUnitRequest} />
      <PrivateRoute exact path={BUSINESS_UNIT_CREATE_PAGE_ROUTE} component={BusinessUnitRequest} />
    </BusinessUnitContextProvider>
  );
}
