// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from 'reactstrap/es/Button';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import caseApi from '../../network/api/caseApi';
import { notifyError, notifyReOpened } from '../../network/notification';
import PatientInformation from './components/PatientInformation';
import CaseNotes from './components/CaseNotes';
import caseReportApi from '../../network/api/caseReportApi';
import patientApi from '../../network/api/patientApi';
import { downloadBase64, groupHistoryByDateAndIdUser, searchHistory } from '../../utils';
import { currentSessionService } from '../../services/sessionServiceInstance';
import { store } from '../../network/reduce';
import { USER_IS_AVAILABLE } from '../../state/process/processReducer';

import {
  CASE_PAGE_ROUTE,
  CASE_PROCESS_PAGE_ROUTE,
  CONSENTS_PAGE_ROUTE,
  SENDING_DOCUMENTS_PAGE_ROUTE,
} from '../../routes';
import hasPermission from '../../services/permissionService';
import { CaseLifeStatus, ProcessSteps } from '../../enum';
import ModalEprm from '../../components/modal/ModalEprm';
import { createSearchFromParams } from '../../services/queryParamsService';
import CoreozDrawer from '../../components/CoreozDrawer';
import HistoryModification from '../../components/historyModification/HistoryModification';
import SendViaSecureLink from '../sendDocuments/components/SendViaSecureLink';
import pathologyCodingApi from '../../network/api/pathologyCoding';
import { CaseDetailResponse } from '../../types/caseDetailTypes';
import { PathologyCodingResponse } from '../../types/pathologyCodingType';
import adviceCodingApi from '../../network/api/adviceCodeingAPI';

type Props = {
  dispatch: Function,
  history: Function,
  match: {
    params: {
      id: string,
    }
  },
};

type State = {
  caseDetail: CaseDetailResponse;
  adviceCoding: Object,
  pathologyCoding: PathologyCodingResponse,
  document: Object,
  prescriptionDocuments: Array<any>,
  patientDocuments: Array<any>,
  reportDocument: Array<any>,
  reOpen: boolean,
  modal: boolean,
  sideDrawerOpenHistory: boolean,
  caseHistory: Array<any>,
  caseDefaultHistory: Array<any>,
};

class CaseDetails extends React.Component<Props, State> {
  state = {
    caseDetail: null,
    document: null,
    adviceCoding: {},
    pathologyCoding: {},
    prescriptionDocuments: [],
    patientDocuments: [],
    reportDocument: [],
    reOpen: false,
    modal: false,
    sideDrawerOpenHistory: false,
    caseHistory: [],
    caseDefaultHistory: [],
  };

  componentDidMount() {
    caseApi.fetchDetailById(this.props.match.params.id)
      .then(response => response.json())
      .then((caseDetail: CaseDetailResponse) => {
        this.setState({ caseDetail });

        if (caseDetail.adviceCodingId) {
          adviceCodingApi.fetchById(caseDetail.adviceCodingId)
            .then(response => response.json())
            .then(adviceCoding => this.setState({ adviceCoding }))
            .catch(e => console.log('Erreur apppel API', e));
        }

        if (caseDetail.pathologyCodingId) {
          pathologyCodingApi.fetchPathologyCodingById(caseDetail.pathologyCodingId)
            .then(response => response.json())
            .then((pathologyCoding: PathologyCodingResponse) => this.setState({ pathologyCoding }))
            .catch(e => console.log('Erreur apppel API', e));
        }

        if (caseDetail.id) {
          this.getCaseHistory(caseDetail.id);
        }
      })
      .catch(this.props.dispatch(notifyError));

    caseReportApi
      .getReportDocumentsByCaseId(this.props.match.params.id)
      .then(response => response.json())
      .then(responseJson =>
        this.setState({
          prescriptionDocuments: responseJson.prescriptionList,
          patientDocuments: responseJson.patientSendList,
          reportDocument: responseJson.medicalReportList,
        }))
      .catch(this.props.dispatch(notifyError));
  }

  downloadDocument = (fileInfos) => {
    patientApi
      .getDocument(fileInfos.id)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((document) => {
        downloadBase64(document.base64, document.name);
      })
      .catch(e => console.log('Erreur apppel API', e));
  };

  reOpenCase = (caseDetail, patientId) => {
    const caseId = caseDetail.id;
    const queryParams = {
      caseId,
      patientId,
    };
    caseApi.reOpenCase(caseId, patientId)
      .then(() => {
        if (!caseDetail.isSharingConsents || !caseDetail.isTreatmentConsent) {
          this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + CONSENTS_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
        } else {
          this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + SENDING_DOCUMENTS_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
        }
      })
      .then(this.props.dispatch(notifyReOpened))
      .catch(e => console.log('Erreur apppel API', e));
  };

  seeInContext = (caseId, patientId, idTakenInCharge) => {
    const queryParams = {
      caseId,
      patientId,
      redirectToConsent: this.state.caseDetail.isRedirectToConsentPage.toString(),
    };

    const hadCurrentUserTakenInCharge = (idTakenInCharge == currentSessionService().currentUser().userId);
    if(hadCurrentUserTakenInCharge) {
      caseApi.takeInCharge(caseId)
      .then(() => {
        this.props.dispatch(store(
          USER_IS_AVAILABLE,
          currentSessionService().currentUser().isAvailable,
        ));
      }).catch(this.props.dispatch(notifyError));
    }

    caseApi.getRedirectInfos(caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((redirectInfos) => {
        if (redirectInfos.redirectToConsent) {
          this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + CONSENTS_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
        } else {
          this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + ProcessSteps[redirectInfos.highestStep] + createSearchFromParams(queryParams)}`);
        }

      }).catch(e => console.log('Erreur apppel API', e));

  };

  getCaseHistory = (idCase: string) => {
    caseApi.getCaseHistory(idCase)
      .then(response => response.json())
      .then(caseHistory => this.setState({
        caseHistory: caseHistory.map(value => ({
          ...value,
          eventDate: moment(value.eventDate).format('DD/MM/YYYY HH:mm'),
        })),
        caseDefaultHistory: caseHistory.map(value => ({
          ...value,
          eventDate: moment(value.eventDate).format('DD/MM/YYYY HH:mm'),
        })),
      }))
      .catch(this.props.dispatch(notifyError));
  };

  drawerToggleHistory = () => {
    this.setState({
      sideDrawerOpenHistory: !this.state.sideDrawerOpenHistory,
    });
  };

  searchKeyWordHistory = value => this.setState({
    caseHistory: searchHistory(value, this.state.caseDefaultHistory),
  });

  render() {
    return (
      <div>
        {this.state.caseDetail &&
        <div className="case-details">
          <div className="case-details-header">
            <div
              className="back-to-list"
              onClick={() => this.props.history.push(`${CASE_PAGE_ROUTE}`, this.props.location.state)}
            >
              <i className="fa fa-arrow-left" />
              {I18n.t('caseDetails.BACK_TO_LIST')}
            </div>
            <div className="case-summary">
              <div className="case-identity">
                <i className="fa fa-folder" />
                <div>
                  <div className="case-number">{I18n.t('caseDetails.CASE')} {this.state.caseDetail.caseName}</div>
                  <div className="patient">{I18n.t('caseDetails.PATIENT')} {this.state.caseDetail.patientName}</div>
                  <div
                    className="case-status"
                  >{I18n.t(`activeStatus.${this.state.caseDetail.status}`)}
                  </div>
                </div>
              </div>
              {this.state.caseDefaultHistory.length > 0 &&
              <div className="case-last-edit">
                <div className="last-edit">{I18n.t('caseDetails.LAST_EDIT')}</div>
                <div
                  className="last-editor"
                >{this.state.caseDefaultHistory[0].userFirstName} {this.state.caseDefaultHistory[0].userLastName}
                </div>
                <div className="edit-date">{this.state.caseDefaultHistory[0].eventDate}</div>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.drawerToggleHistory();
                  }}
                >
                  <i className="fa fa-history" />
                  {I18n.t('caseDetails.HISTORY')}
                </Button>
              </div>
              }
            </div>
          </div>
          <CoreozDrawer show={this.state.sideDrawerOpenHistory} side={false}>
            <HistoryModification
              caseId={this.state.caseDetail.id}
              goBack={this.drawerToggleHistory}
              caseHistory={groupHistoryByDateAndIdUser(this.state.caseHistory)}
              searchKeyWordHistory={this.searchKeyWordHistory}
              callCategory={[]}
              countries={[]}
            />
          </CoreozDrawer>
          <div className="case-details-container">
            {(!this.state.caseDetail.isRedirectToConsentPage && (!this.state.caseDetail.isSharingConsents || !this.state.caseDetail.isTreatmentConsent)) &&
            <div className="no-consents">{I18n.t('caseDetails.NO_CONSENTS')}</div>
            }
            <div className="case-action">
              {hasPermission('REOPEN_CASE')
              && this.state.caseDetail.status === CaseLifeStatus.INACTIVE &&
              <Button
                color="secondary"
                onClick={() => this.setState({ reOpen: !this.state.reOpen })}
              >
                <i className="fa fa-redo" />
                {I18n.t('caseDetails.RE_OPEN')}
              </Button>
              }
              {this.state.caseDetail.status !== CaseLifeStatus.INACTIVE &&
              <Button
                color="primary"
                onClick={() => this.seeInContext(this.state.caseDetail.id, this.state.caseDetail.patientId, this.state.caseDetail.idTakenInCharge)}
              >
                <i className="fa fa-eye" />
                {I18n.t('caseDetails.SEE_IN_CONTEXT')}
              </Button>
              }
            </div>
            <ModalEprm
              title={I18n.t('caseDetails.RE_OPEN')}
              modal={this.state.reOpen}
              toggle={() => this.setState({ reOpen: !this.state.reOpen })}
            >
              <div className="take-in-charge-message">
                {I18n.t('caseDetails.CHECK_RE_OPEN')}
              </div>
              <div className="take-in-charge-buttons">
                <Button
                  color="secondary"
                  onClick={() => this.setState({ reOpen: !this.state.reOpen })}
                >
                  <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.reOpenCase(this.state.caseDetail, this.state.caseDetail.patientId)}
                >
                  <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('caseDetails.RE_OPEN')}
                </Button>
              </div>
            </ModalEprm>
            <div className="case-details-section">
              <div className="section-title">{I18n.t('caseDetails.PATIENT_INFO')}</div>
              <PatientInformation
                caseDetails={this.state.caseDetail}
              />
            </div>
            <div className="case-details-section">
              <div className="section-title">{I18n.t('caseDetails.CASE_NOTES')}</div>
              <CaseNotes
                caseDetails={this.state.caseDetail}
              />
            </div>
            <div className="case-details-section">
              <div className="section-title">{I18n.t('caseDetails.CODING')}</div>
              {this.state.adviceCoding &&
              <div className="case-codes">
                <span className="code-title">{I18n.t('caseDetails.coding.ADVICE_CODING')}</span>
                {this.state.adviceCoding.label}
              </div>
              }
              {this.state.pathologyCoding &&
              <div className="case-codes">
                <span className="code-title">{I18n.t('caseDetails.coding.PATHOLOGY_CODING')}</span>
                {this.state.pathologyCoding.name}
              </div>
              }
            </div>
            {this.state.prescriptionDocuments.length > 0 &&
            <div className="case-details-section">
              <div className="section-title">{I18n.t('caseDetails.PRESCRIPTIONS')}</div>
              {this.state.prescriptionDocuments.map(prescription => (
                <div className="tile-document" key={prescription.id}>
                  <div className="document-name">{prescription.name}</div>
                  <div className="document-action">
                    <a
                      onClick={() => this.setState({
                        modal: true,
                        document: prescription,
                      })}
                    >{I18n.t('lastDocuments.SEND')}
                    </a>
                  </div>
                  <div className="document-action">
                    <a
                      onClick={() => this.downloadDocument(prescription)}
                    >{I18n.t('lastDocuments.DOWNLOAD')}
                    </a>
                  </div>
                </div>
              ))}
            </div>
            }
            {this.state.reportDocument.length > 0 &&
            <div className="case-details-section">
              <div className="section-title">{I18n.t('caseDetails.REPORTS')}</div>
              {this.state.reportDocument.map(report => (
                <div className="tile-document" key={report.id}>
                  <div className="document-name">{report.name}</div>
                  <div className="document-action">
                    <a
                      onClick={() => this.downloadDocument(report)}
                    >{I18n.t('lastDocuments.DOWNLOAD')}
                    </a>
                  </div>
                </div>
              ))}
            </div>
            }
            {this.state.patientDocuments.length > 0 &&
            <div className="case-details-section">
              <div className="section-title">{I18n.t('caseDetails.PATIENT_DOC')}</div>
              {this.state.patientDocuments.map(patientDoc => (
                <div className="tile-document" key={patientDoc.id}>
                  <div className="document-name">{patientDoc.name}</div>
                  <div className="document-action">
                    <a
                      onClick={() => this.downloadDocument(patientDoc)}
                    >{I18n.t('lastDocuments.DOWNLOAD')}
                    </a>
                  </div>
                </div>
              ))}
            </div>
            }
          </div>
          <ModalEprm
            title={I18n.t('documentsToSend.sendBySecure.title')}
            modal={this.state.modal}
            size="lg"
            toggle={() => this.setState({ modal: !this.state.modal })}
          >
            <SendViaSecureLink
              documentId={this.state.document ? this.state.document.id : null}
              caseId={this.state.caseDetail.id}
              caseDetail={this.state.caseDetail}
              toggle={() => this.setState({ modal: !this.state.modal })}
              updateDocuments={() => {
              }}
              fromCaseDetails
            />
          </ModalEprm>
        </div>
        }
      </div>
    );
  }
}

export default withRouter(connect()(CaseDetails));
