
import { RestClientAuthenticated } from '../../network/network';
import { SearchResultType, SearchCriteriaType, SearchResultContentItem, BPartnerDetail } from '../../types/B-PartnerManagement';
import { notifyError, notifySuccess } from '../../network/notification';
import data from './mockData/b-partner.json'

const apiEndpoint = {
    searchList: "/b-partner/search",
    getById: "/b-partner/detail/:id",
    update: "/b-partner/:id",
    save: "/b-partner",
    fetchList: "/b-partner/list",
}

const bPartnerApi = {
    search: (searchCriteria: SearchCriteriaType): SearchResultType => (dispatch: Function) => {

        const queryString = Object.keys(searchCriteria).filter(v => searchCriteria[v] !== null).map(key => key + '=' + searchCriteria[key]).join('&');

        return new RestClientAuthenticated(`${apiEndpoint.searchList}?${queryString}`, 'GET').execute().then(response => response.text()).then(text => text.length ? JSON.parse(text) : null).catch((e) => dispatch(notifyError)(e));
    },
    fetchBpartnerById: (id: null): BPartnerDetail => (dispatch: Function) => {

        return new RestClientAuthenticated(`${apiEndpoint.getById.replace(':id', id)}`, 'GET').execute().then(response => (response.status == 200) && response.json()).catch((e) => dispatch(notifyError)(e));
    },
    update: (data: any, id: any) => new RestClientAuthenticated(`${apiEndpoint.update.replace(':id', id)}`, 'PUT').jsonBody(data).execute(),
    save: (data: any) => new RestClientAuthenticated(`${apiEndpoint.save}`, 'POST').jsonBody(data).execute(),
    fetchBpartners: () => new RestClientAuthenticated(`${apiEndpoint.fetchList}`, 'GET').execute(),
}


export interface FetchBpartnerResponse {
    id: string;
    bpartnerCode: number;
    bpartnerName: string;
    isActive: boolean;
}

export default bPartnerApi;
