// @flow

import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import Tile from '../Tile';
import type { PolicyType } from '../../types/PolicyType';

type Props = {
  policy: PolicyType,
  short?: boolean,
  selected?: boolean,
}
type TextProps = {
  i18nAccessor: string,
  text: string,
}

const TextLabel = ({ i18nAccessor, text }: TextProps) => (
  <div className="tile-text">
    <div className="tile-label">{I18n.t(i18nAccessor)}</div>
    <div className="tile-value">{text}</div>
  </div>);

PolicyTile.defaultProps = {
  short: false,
  selected: false,
};

export default function PolicyTile({ policy, short, selected }: Props) {
  return (
    <Tile
      className={`tile-policy ${selected ? 'selected' : ''}`}
    >
      <TextLabel
        i18nAccessor="administration.policy.policy.POLICY_NUMBER"
        text={policy.policyNumber ? policy.policyNumber : '--'}
      />
      <TextLabel
        i18nAccessor="administration.policy.policy.PROTOCOL_NUMBER"
        text={policy.protocolNumber ? policy.protocolNumber : '--'}
      />
      <TextLabel
        i18nAccessor="administration.policy.policy.POLICY_HOLDER"
        text={policy.holderFirstName ? (`${policy.holderFirstName } ${ policy.holderLastName}`) : '--' }
      />
      <TextLabel
        i18nAccessor="administration.policy.policy.BENEFICIARY"
        text={policy.beneficiaryFirstName ? (`${policy.beneficiaryFirstName } ${ policy.beneficiaryLastName}`) : '--'}
      />
      {
        !short &&
        <React.Fragment>
          {
            policy.expiryDate ?
              <TextLabel
                i18nAccessor="processCase.eligibility.EXPIRATION_DATE"
                text={policy.expiryDate ? moment(policy.expiryDate).format(I18n.t('date.DATE_FORMAT')) : ''}
              /> : <div className="tile-text" />
          }
          <TextLabel
            i18nAccessor="administration.policy.policy.PDA_ACCOUNT"
            text={I18n.t(`form.${policy.isPdaAccount ? 'YES' : 'NO'}`)}
          />
        </React.Fragment>
      }
    </Tile>
  );
}
