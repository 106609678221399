'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

require('./index.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CoreozSelect = function (_React$PureComponent) {
  _inherits(CoreozSelect, _React$PureComponent);

  function CoreozSelect() {
    _classCallCheck(this, CoreozSelect);

    return _possibleConstructorReturn(this, (CoreozSelect.__proto__ || Object.getPrototypeOf(CoreozSelect)).apply(this, arguments));
  }

  _createClass(CoreozSelect, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          list = _props.list,
          label = _props.label,
          onChange = _props.onChange,
          input = _props.input,
          placeholder = _props.placeholder,
          disabled = _props.disabled,
          disabledPlaceholder = _props.disabledPlaceholder,
          meta = _props.meta,
          required = _props.required,
          className = _props.className,
          theme = _props.theme;

      var isEmpty = !input.value && input.value !== 0 && input.value !== false;
      var hasError = meta.touched && meta.error;
      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
          'div',
          { className: theme + '-container-input-coreoz ' + theme + '-select-coreoz ' + className },
          label && _react2.default.createElement(
            'div',
            {
              className: theme + '-label-input-coreoz ' + (isEmpty ? theme + '-label-input-coreoz--empty' : '') + ' ' + (required ? theme + '-label-input-coreoz--required' : '') + ' ' + (hasError ? theme + '-label-input-coreoz--error' : '')
            },
            label
          ),
          _react2.default.createElement(
            'select',
            _extends({
              className: theme + '-input-coreoz ' + (hasError ? theme + '-input-coreoz--error' : '')
            }, input, {
              onChange: function (_onChange) {
                function onChange(_x) {
                  return _onChange.apply(this, arguments);
                }

                onChange.toString = function () {
                  return _onChange.toString();
                };

                return onChange;
              }(function (e) {
                input.onChange(e.target.value);
                if (onChange) onChange(e.target.value);
              }),
              disabled: disabled
            }),
            _react2.default.createElement(
              'option',
              { value: '', disabled: disabledPlaceholder },
              placeholder || ''
            ),
            !!list && list.map(function (opt) {
              return _react2.default.createElement(
                'option',
                { value: opt.id, key: '' + opt.id + opt.label },
                opt.label
              );
            })
          ),
          hasError && _react2.default.createElement(
            'div',
            { className: theme + '-error-form ' + theme + '-error-form--field' },
            meta.error
          )
        )
      );
    }
  }]);

  return CoreozSelect;
}(_react2.default.PureComponent);

CoreozSelect.defaultProps = {
  label: '',
  placeholder: '',
  disabled: false,
  required: true,
  className: '',
  theme: 'base',
  disabledPlaceholder: true
};
exports.default = CoreozSelect;