import React, { useState, useEffect, useContext, useRef } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Loader from '../../components/Loader';
import protocolContext from '../../stores/b-partner-management-context';
import { CoreozInputBase, CoreozSelectBase } from '../../lib/coreoz-form-base/index';
import userApi from '../../network/api/userApi';
import { LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE, LOGO_MANAGEMENT_PAGE_ROUTE } from '../../routes';
import { required } from '../../validator';
import { LogoDetail } from '../../types/logoManagement';
import InputFile from '../../lib/coreoz-input-file';
import logoApi from '../../network/api/logoApi';

const useStyles = makeStyles(theme => ({
  backdrop: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const LogoRequest = (props) => {
  const classes = useStyles();
  const isEdit = !!props.match.params.id || false;
  const logoId = props.match.params.id || null;
  const { searchCriteriaContext, updateSearchCriteriaContext } = useContext(protocolContext);
  const [businessUnits, setBusinessUnits] = useState([]);

  const [button, setButton] = useState('');
  const [logoDetail, setlogoDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBusinessUnits();
    if (logoId) {
      fetchLogoDetail(logoId);
    }
  }, []);

  async function fetchBusinessUnits(): void {
    const businessUnitsResponse = await userApi.fetchBusinessUnits().then(response => response.json());

    setBusinessUnits((businessUnitsResponse) || []);
  }

  async function fetchLogoDetail(id: number): void {
    setLoading(true);

    const legalEntityResponse: logoDetail = await props.dispatch(logoApi.fetchById(id));

    setlogoDetail(legalEntityResponse);

    setLoading(false);
  }

  const onPreviousHandler = () => {
    props.history.push(LOGO_MANAGEMENT_PAGE_ROUTE);
  };

  const onSave = (dataForm) => {
    const rawData = {
      ...dataForm,
    };
  };


  const formatForFront = (detail: LogoDetail) => {
    if (!detail) {
      return {};
    }

    return {
      logoName: detail.logoName || null,
      isActive: detail.isActive || null,
      businessUnitId: detail.businessUnitId || null,
      logoFile: detail.logoFile || null,
    };
  };

  return (
    <div className="logo-detail user-details" id="logo-details">

      <div className="logo-detail-header">

        <div
          className="back-to-list"
          onClick={onPreviousHandler}
        >
          <i className="fa fa-arrow-left" />
          {I18n.t('userDetails.BACK_TO_LIST')}
        </div>

        <div className="title">
          {isEdit ? I18n.t('logoManagement.TITLE') : I18n.t('logoManagement.TITLE_CREATE')}
          {
                        isEdit && logoDetail &&
                        <div className="logo-detail-info">{logoDetail.logoName}</div>
                    }
        </div>


      </div>

      <Form
        onSubmit={onSave}
        initialValues={formatForFront(logoDetail)}
        render={({ handleSubmit, form, invalid }) => (
          <form onSubmit={handleSubmit}>
            <div className="user-action">
              <Button
                color="secondary"
                type="button"
                onClick={onPreviousHandler}
              >
                <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={invalid}
              >
                <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
              </Button>

            </div>


            <div className="content">

              <div className="user-category">

                <div className="content-container">

                  <Field
                    autoComplete="off"
                    type="text"
                    component={CoreozInputBase}
                    name="logoName"
                    label={I18n.t('logoManagement.label.LOGO_NAME')}
                    validate={required}
                    required
                    maxLength="200"
                  />

                  <Field
                    name="businessUnitId"
                    id="businessUnitId"
                    autoComplete="off"
                    type="text"
                    component={CoreozSelectBase}
                    label={I18n.t('logoManagement.label.BUSINESS_UNIT')}
                    list={businessUnits && businessUnits.map(bu => ({
                      id: bu.id,
                      label: bu.name,
                    }))}
                    validate={required}
                    required
                  />


                  {isEdit && <div className="radio-button-container">
                    <div className="radio-button-label">{I18n.t('logoManagement.label.STATUS')}</div>
                    <div className="choices">
                      <Field
                        component="input"
                        name="isActive"
                        type="radio"
                        value="true"
                        format={value => String(value)}
                        parse={value => value === 'true'}
                      />
                      <span>{I18n.t('logoManagement.STATUS.ACTIVE')}</span>
                      <Field
                        component="input"
                        name="isActive"
                        type="radio"
                        value="false"
                        format={value => String(value)}
                        parse={value => value === 'true'}
                      />
                      <span>{I18n.t('logoManagement.STATUS.INACTIVE')}</span>
                    </div>
                  </div>
                                    }

                  <div className="base-container-input-coreoz ">
                    <div className="base-label-input-coreoz  base-label-input-coreoz--required ">{I18n.t('logoManagement.label.LOGO_FILE')}</div>


                  </div>
                  <div className="import-image">

                    <Field
                      label={I18n.t('userDetails.COUNTRY')}
                      component={InputFile}
                      name="logoFile"
                      validate={required}
                      required
                      wordings={{
                          dragFile: I18n.t('userDetails.wordings.dragFile'),
                          selectFile: I18n.t('userDetails.wordings.selectFile'),
                        }}
                    />
                  </div>


                </div>
              </div>

            </div>

            <div className="user-action mt-5">
              <Button
                color="secondary"
                type="button"
                onClick={onPreviousHandler}
              >
                <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={invalid}
              >
                <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
              </Button>
            </div>

          </form>
                )}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <div className="center-child search-loader"><Loader /></div>
      </Backdrop>
    </div>
  );
};

export default connect()(LogoRequest);
