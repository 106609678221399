// @flow

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

type Props = {
  className?: string,
  size?: string,
  title: string,
  children: any,
  modal: boolean,
  toggle: Function,
}

class ModalEprm extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    size: '',
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
          size={this.props.size}
        >
          <ModalHeader toggle={this.props.toggle} className="modal-eprm-header">{this.props.title}</ModalHeader>
          <ModalBody className="modal-eprm-content">
            {this.props.children}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ModalEprm;
