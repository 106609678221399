// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Button from 'reactstrap/es/Button';
import { Field, Form } from 'react-final-form';
import { CoreozDatePickerBase as CoreozDatePicker, CoreozInputBase as CoreozInput } from '../../lib/coreoz-form-base/index';
import moment from 'moment';

import {
  CASE_PROCESS_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  ELIGIBILITY_PAGE_ROUTE,
  PROCESS_DETAILS_PAGE_ROUTE,
} from '../../routes';
import processApi from '../../network/api/processApi';

import { dateValidator, required, validateNumber } from '../../validator';
import Loader from '../../components/Loader';
import { notifyError } from '../../network/notification';
import {
  DummyPolicyCmcCic,
  DummyPolicyItaly,
  LanguageIsoCode,
  RecordingReason,
  teleAdviceCode,
  typeOfBPartner,
} from '../../enum';
import { closeRecording } from '../../services/recordingService';
import { currentSessionService } from '../../services/sessionServiceInstance';
import CoreozSelect from '../../lib/coreoz-form-base/components/select/CoreozSelect';
import protocolApi from '../../network/api/protocolApi';
import { createSearchFromParams } from '../../services/queryParamsService';
import patientApi, { ShortPatient } from '../../network/api/patientApi';
import CreatePatientModal from '../eligibility/components/CreatePatientModal';
import ModalEprm from '../../components/modal/ModalEprm';
import Tile from '../../components/Tile';
import CreatePolicyModal from './components/CreatePolicyModal';
import { VisaEligibility } from '../../types/PolicyVisaType';
import IdentificationVisa from './components/IdentificationVisa';
import policiesApi from '../../network/api/policiesApi';

type
  Props = {
  history: Function,
  location: any,
};

type
  State = {
  loading: boolean,
  initialValues: Object,
  modal: boolean,
  createPatientModal: boolean,
  protocolsCmCic: Array<any>,
  patientToUse: null,
  patientList: any,
  italiansProtocols: any,
  patientInfo: any,
  skipEligibility: boolean,
  isShowResultVisa: boolean,
  addNewPolicyModal: boolean,
  messageResultVisa: string,
  visaPatientInfo: Object,
  visaOpenCaseInfo: Object,
};

class Identification extends React.Component<Props, State> {
  state = {
    createPatientModal: false,
    loading: false,
    initialValues: {},
    protocolsCmCic: [],
    patientToUse: null,
    patientList: {},
    italiansProtocols: {},
    skipEligibility: false,
    isShowResultVisa: false,
    addNewPolicyModal: false,
    visaOpenCaseInfo: null,
    patientInfo: {},
    messageResultVisa: '',
    visaPatientInfo: {},
  };

  app: HTMLElement;

  showCreatePatientModale = (patientInfo: ShortPatient, isSkipEligibility: boolean, policyInfo: any) => {
    this.setState({
      createPatientModal: true,
      skipEligibility: isSkipEligibility,
      patientInfo,
    });
  };

  createPatient = (patient: ShortPatient): void => {
    patientApi.create(patient)
      .then(response => response.text())
      .then((patientId) => {
        this.props.history.push(CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE + createSearchFromParams({
          ...patient,
          patientId,
          // HOTFIX
          birthDate: patient.newBirthdate ? moment(patient.newBirthdate, 'YYYY-MM-DD') : null,
          beneficiaryBirthday: patient.newBirthdate ? moment(patient.newBirthdate).format('YYYY-MM-DD') : null,
          isCmCicProtocol: this.state.patientToUse && this.state.patientToUse.isCmCicProtocol || false,
        }));
      });
  };

  updatePatient = (infos: ShortPatient, id: string): Promise<String> => new Promise((resolve, reject) => {
    patientApi.update(infos, id)
      .then(response => resolve(response))
      .catch(reject);
  });

  onSubmit = (values: any) => {
    this.setState({ loading: true, isShowResultVisa: false });
    const isNotItalian = currentSessionService().currentUser().businessUnitCountryCode !== LanguageIsoCode.ITALIAN;


    if (values.isOtherBpartner === typeOfBPartner.VISA) {
      this.processVisa(values);
    } else {
      const {
        firstName, lastName, birthDate, protocolCmCic,
      } = values.patient;
      const birthDateValue = moment(birthDate).format('YYYY-MM-DD');
      const rawValues = {
        ...values,
        birthDate: birthDateValue,
        patient: {
          ...values.patient,
          birthDate: birthDateValue,
        },
      };

      if (values.isOtherBpartner === typeOfBPartner.OTHER && isNotItalian || !currentSessionService().currentUser().canAccessCmCic) {
        processApi.checkEligibility({
          firstName,
          lastName,
          birthDate: birthDateValue,
        })
          .then(response => response.text())
          .then(text => (text.length ? JSON.parse(text) : []))
          .then((policiesInfo) => {
            this.setState({ loading: false });
            const stateData = JSON.stringify({
              policiesInfo,
              firstInfo: rawValues,
              identification: rawValues,
            });
            this.props.history.push(
              CASE_PROCESS_PAGE_ROUTE + ELIGIBILITY_PAGE_ROUTE,
              JSON.parse(stateData),
            );
          })
          .catch((e) => {
            this.setState({ loading: false });
            this.props.dispatch(notifyError)(e);
          });
      } else {
        patientApi.searchPatientsProfiles({ lastName, firstName, birthDate: birthDateValue })
          .then(response => response.text())
          .then(text => (text.length ? JSON.parse(text) : []))
          .then((patientList) => {
            const isUseOneProtocolAndPolicyForTreatingCases = currentSessionService().currentUser().useOneProtocolAndPolicyForTreatingCases;
            const policyNumber = isUseOneProtocolAndPolicyForTreatingCases ? DummyPolicyItaly : DummyPolicyCmcCic;
            const partnerName = isUseOneProtocolAndPolicyForTreatingCases ? this.state.italiansProtocols[0].bpartnerName : this.state.protocolsCmCic.filter(protocol => protocol.protocolNumber === protocolCmCic)[0].bpartnerName;
            const protocolNumber = isUseOneProtocolAndPolicyForTreatingCases ? this.state.italiansProtocols[0].protocolNumber : protocolCmCic;

            // patient exist in the ePRM
            this.setState({
              patientToUse: {
                lastName,
                firstName,
                birthDate: birthDateValue,
                policyNumber,
                partnerName,
                protocolNumber,
                isCmCicProtocol: true,
              },
            });

            if (patientList.length) {
              this.setState({
                modal: !this.state.modal,
              });
            } else { // patient doesn't exist in the ePRM
              this.showCreatePatientModale({
                lastName,
                firstName,
                birthDate: birthDateValue,
                expiryDate: '',
                policyNumber,
                partnerName,
                protocolNumber,
              }, false);
            }
            this.setState({ patientList });
          });

        this.setState({ loading: false });
      }
    }
  };


  processVisa = (values) => {
    const {
      firstName, lastName, birthDate, binNumber, bankCardHolderFirstName, bankCardHolderLastName,
    } = values.information;

    const birthDateValue = moment(birthDate).format('YYYY-MM-DD');
    this.setState({ visaOpenCaseInfo: null });

    processApi.checkVisa({
      firstName,
      lastName,
      birthDate: birthDateValue,
      binNumber,
    })
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((policiesInfo: VisaEligibility) => {
        this.setState({ loading: false, isShowResultVisa: false });
        if (policiesInfo.visaBin) {
          if (policiesInfo.patient && policiesInfo.patient.policy) {
            policiesApi.updatePolicyPatient(policiesInfo.patient.policy.policyId, policiesInfo.patient.id, {
              policyHolderFirstName: bankCardHolderFirstName || firstName,
              policyHolderLastName: bankCardHolderLastName || lastName,
              isBeneficiarySearch: values.isBankCardHolder,
            }).then(() => {
              if (policiesInfo.openCases && policiesInfo.patient && policiesInfo.patient.policy) {
                const stateData = {
                  policiesInfo,
                  firstInfo: {
                    ...values,
                    information: {
                      ...values.information,
                      isBankCardHolder: values.isBankCardHolder,
                      birthDate: birthDateValue,
                    },
                  },
                  isVisa: true,
                };
                this.props.history.push(CASE_PROCESS_PAGE_ROUTE + ELIGIBILITY_PAGE_ROUTE, stateData);
              } else {
                this.props.history.push(CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE + createSearchFromParams({
                  patientId: policiesInfo.patient.id,
                  protocolNumber: policiesInfo.patient.policy.protocolNumber,
                  lastName: policiesInfo.patient.lastName,
                  firstName: policiesInfo.patient.firstName,
                  birthDate: policiesInfo.patient.birthDate ? moment(policiesInfo.patient.birthDate, 'YYYY-MM-DD') : null,
                  policyNumber: policiesInfo.patient.policy.policyNumber,
                  expiryDate: policiesInfo.patient.policy.expiryDate ? moment(policiesInfo.patient.policy.expiryDate, 'YYYY-MM-DD') : null,
                  partnerName: policiesInfo.patient.partnerName,
                  beneficiaryBirthday: policiesInfo.patient.policy.beneficiaryBirthday ? moment(policiesInfo.patient.policy.beneficiaryBirthday, 'YYYY-MM-DD') : null,
                  beneficiaryFirstName: policiesInfo.patient.policy.beneficiaryFirstName,
                  beneficiaryLastName: policiesInfo.patient.policy.beneficiaryLastName,
                  policyHolderFirstName: policiesInfo.patient.policy.holderFirstName,
                  policyHolderLastName: policiesInfo.patient.policy.holderLastName,
                  isPdaAccount: policiesInfo.patient.isPdaAccount,
                }));
              }
            });
          } else if (policiesInfo.patient && !policiesInfo.patient.policy) {
            this.setState({
              patientToUse: {
                id: policiesInfo.patient.id,
                lastName,
                firstName,
                birthDate: birthDateValue,
                protocolNumber: policiesInfo.visaBin.protocolNumber,
                beneficiaryBirthday: birthDateValue,
                beneficiaryFirstName: firstName,
                beneficiaryLastName: lastName,
                policyHolderFirstName: bankCardHolderFirstName || firstName,
                policyHolderLastName: bankCardHolderLastName || lastName,
                policyNumber: binNumber,
                isCmCicProtocol: false,
                policyStartDate: policiesInfo.visaBin.policyStartDate || '',
                policyEndDate: policiesInfo.visaBin.policyEndDate || '',
                isBeneficiarySearch: values.isBankCardHolder,
              },
            });

            if (policiesInfo.openCases) {
              const stateData = {
                policiesInfo,
                firstInfo: {
                  ...values,
                  information: {
                    ...values.information,
                    isBankCardHolder: values.isBankCardHolder,
                    birthDate: birthDateValue,
                  },
                },
                isVisa: true,
              };
              this.setState({ visaOpenCaseInfo: stateData });
            }

            this.setState({
              addNewPolicyModal: true,
              visaPatientInfo: { ...policiesInfo, binNumber },
            });
          } else {
            const policyStartDate = policiesInfo.visaBin.policyStartDate ? moment(policiesInfo.visaBin.policyStartDate).toISOString() : '';
            const policyEndDate = policiesInfo.visaBin.policyEndDate ? moment(policiesInfo.visaBin.policyEndDate).toISOString() : '';
            this.setState({
              patientToUse: {
                lastName,
                firstName,
                birthDate: birthDateValue,
                protocolNumber: policiesInfo.visaBin.protocolNumber,
                beneficiaryBirthday: birthDateValue,
                beneficiaryFirstName: firstName,
                beneficiaryLastName: lastName,
                policyHolderFirstName: bankCardHolderFirstName || firstName,
                policyHolderLastName: bankCardHolderLastName || lastName,
                policyNumber: binNumber,
                startDate: policyStartDate,
                expiryDate: policyEndDate,
                isCmCicProtocol: false,
                isBeneficiarySearch: values.isBankCardHolder,
              },
            });

            this.showCreatePatientModale({
              lastName,
              firstName,
              birthDate: birthDateValue,
              expiryDate: policyEndDate,
              protocolNumber: policiesInfo.visaBin.protocolNumber,
            }, false);
          }
        } else {
          this.setState({
            loading: false,
            isShowResultVisa: true,
            messageResultVisa: I18n.t('processCase.identification.BANK_CARD_IS_INVALID'),
          });
          this.scrollToTop();
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          isShowResultVisa: true,
          messageResultVisa: I18n.t('processCase.identification.BANK_CARD_IS_INVALID'),
        });
        this.scrollToTop();
      });
  }

  createPolicyVisa = (patient): void => {
    patientApi.savePolicyByPatient(patient.patient.id, this.state.patientToUse)
      .then(response => response.text())
      .then(() => {
        if (this.state.visaOpenCaseInfo) {
          const stateData = {
            ...this.state.visaOpenCaseInfo,
            policiesInfo: {
              ...this.state.visaOpenCaseInfo.policiesInfo,
              patient: {
                ...this.state.visaOpenCaseInfo.policiesInfo.patient,
                policy: {
                  protocolNumber: this.state.patientToUse.protocolNumber,
                  policyNumber: this.state.patientToUse.policyNumber,
                  expiryDate: '',
                  beneficiaryBirthday: this.state.patientToUse.beneficiaryBirthday || null,
                  beneficiaryFirstName: this.state.patientToUse.beneficiaryFirstName,
                  beneficiaryLastName: this.state.patientToUse.beneficiaryLastName,
                  holderFirstName: this.state.patientToUse.policyHolderFirstName,
                  holderLastName: this.state.patientToUse.policyHolderLastName,
                },
              },
            },
          };

          this.props.history.push(CASE_PROCESS_PAGE_ROUTE + ELIGIBILITY_PAGE_ROUTE, stateData);
        } else {
          this.goNextWithExistingPatient();
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(notifyError)(e);
      });
  };

  goNextWithExistingPatient = () => {
    this.props.history.push(CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE + createSearchFromParams({
      patientId: this.state.patientToUse.id,
      protocolNumber: this.state.patientToUse.protocolNumber,
      lastName: this.state.patientToUse.lastName,
      firstName: this.state.patientToUse.firstName,
      birthDate: this.state.patientToUse.birthDate ? moment(this.state.patientToUse.birthDate, 'YYYY-MM-DD') : null,
      policyNumber: this.state.patientToUse.policyNumber,
      expiryDate: '',
      partnerName: this.state.patientToUse.partnerName,
      beneficiaryBirthday: this.state.patientToUse.beneficiaryBirthday ? moment(this.state.patientToUse.beneficiaryBirthday, 'YYYY-MM-DD') : null,
      beneficiaryFirstName: this.state.patientToUse.beneficiaryFirstName,
      beneficiaryLastName: this.state.patientToUse.beneficiaryLastName,
      policyHolderFirstName: this.state.patientToUse.policyHolderFirstName,
      policyHolderLastName: this.state.patientToUse.policyHolderLastName,
      isPdaAccount: this.state.patientToUse.isPdaAccount,
      isCmCicProtocol: this.state.patientToUse.isCmCicProtocol,
    }));
  };

  goBack = () => {
    closeRecording(this.props.dispatch, null, RecordingReason.WRONG_SERVICE);
    this.props.history.push(DASHBOARD_PAGE_ROUTE);
  };

  componentDidMount() {
    this.app = document.querySelector('#main-content');
    if (currentSessionService().currentUser().useOneProtocolAndPolicyForTreatingCases) {
      protocolApi.fetchProtocolsByCountryCode()
        .then(response => response.json())
        .then(italiansProtocols => this.setState({ italiansProtocols }))
        .catch(this.props.dispatch(notifyError));
    }

    let initialValues = {};
    protocolApi.fetchProtocolsCmCic()
      .then(response => response.json())
      .then(protocolsCmCic => this.setState({ protocolsCmCic }))
      .catch(this.props.dispatch(notifyError));

    if (this.props.location.state && this.props.location.state.firstInfo) {
      initialValues = {
        ...this.props.location.state.firstInfo,
        patient: this.props.location.state.firstInfo.patient && {
          firstName: this.props.location.state.firstInfo.patient.firstName,
          lastName: this.props.location.state.firstInfo.patient.lastName,
          birthDate: this.props.location.state.firstInfo.patient.birthDate ?
            moment(this.props.location.state.firstInfo.patient.birthDate) : null,
        },
        information: this.props.location.state.firstInfo.information && {
          ...this.props.location.state.firstInfo.information,
          birthDate: this.props.location.state.firstInfo.information.birthDate ?
            moment(this.props.location.state.firstInfo.information.birthDate) : null,
        },
      };
    }
    this.setState({ initialValues });
  }

  scrollToTop = () => {
    this.app.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  render() {
    return (
      <div className="process-case-step">
        <div className="step-title">
          <div>
            <i className="fa fa-fingerprint" />{I18n.t('processCaseHeader.steps.IDENTIFICATION')}
          </div>
        </div>
        <ModalEprm
          title={I18n.t('processCase.identification.CHOOSE_PATIENT')}
          modal={this.state.modal}
          toggle={() => this.setState({ modal: !this.state.modal })}
        >
          <div className="select-patient-message">
            {I18n.t('processCase.identification.SELECT_PATIENT')}
          </div>
          {this.state.patientList.length > 0 &&
          this.state.patientList.map(patient =>
            (<div
              key={patient.id}
              onClick={() => this.setState({
                   patientToUse: {
                     ...this.state.patientToUse,
                     id: patient.id,
                     beneficiaryBirthday: patient.beneficiaryBirthday ? moment(patient.beneficiaryBirthday, 'YYYY-MM-DD') : null,
                     beneficiaryFirstName: patient.beneficiaryFirstName,
                     beneficiaryLastName: patient.beneficiaryLastName,
                     policyHolderFirstName: patient.policyHolderFirstName,
                     policyHolderLastName: patient.policyHolderLastName,
                     isPdaAccount: patient.isPdaAccount,
                     policyNumber: patient.policyNumber,
                     isCmCicProtocol: true,
                   },
                 })}
            >
              <Tile
                id={patient.id}
                className={`tile-patient ${!!this.state.patientToUse && (this.state.patientToUse.id === patient.id) ? 'select' : ''}`}
              >
                <div className="patient-choice">
                  <i className="fa fa-user-injured" />
                  <div>
                    <Row title={`${patient.firstName} ${patient.lastName}`}>
                      <Col className="column-infos">
                        <div className="primary-infos">{patient.firstName} {patient.lastName}</div>
                        <div className="secondary-infos">{patient.email}</div>
                      </Col>
                      <Col className="column-infos">
                        <div className="primary-infos">{moment(patient.birthDate).format(I18n.t('date.DATE_FORMAT'))}</div>
                        <div className="secondary-infos">{patient.mobilePhone} </div>
                      </Col>
                    </Row>

                  </div>
                  <div className="infos" />

                </div>
              </Tile>
             </div>))
          }
          <div className="new-patient">
            {I18n.t('processCase.identification.NEW_PATIENT')}
            <Button
              color="primary"
              onClick={() => this.setState({
                modal: !this.state.modal,
                createPatientModal: !this.state.createPatientModal,
              })}
            >
              <i className="button-icon fas fa-plus-circle" />{I18n.t('button.NEW_PATIENT')}
            </Button>
          </div>
          <div className="select-patient-buttons">
            <Button
              color="secondary"
              onClick={() => this.setState({
                modal: !this.state.modal,
              })}
            >
              <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
            </Button>
            <Button
              color="primary"
              onClick={() => this.goNextWithExistingPatient()}
              disabled={(this.state.patientToUse && !this.state.patientToUse.id) || this.state.loading}

            >
              {
                this.state.loading ?
                  <Loader small />
                  :
                  <span> <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.CONTINUE')}</span>
              }

            </Button>
          </div>
        </ModalEprm>
        <CreatePolicyModal
          modal={this.state.addNewPolicyModal}
          toggle={() => this.setState({ addNewPolicyModal: !this.state.addNewPolicyModal })}
          visaPatientInfo={this.state.visaPatientInfo}
          submitFunction={this.createPolicyVisa}
        />
        <div className="step-container">
          { this.state.isShowResultVisa &&
          <Tile className="tile-eligibility eligibility-not-found">
            <i className="fa fa-user-times" />
            <div className="eligibility-result">
              {this.state.messageResultVisa}
            </div>
          </Tile>
          }

          <div className="explanation-phrase">{I18n.t('processCase.identification.EXPLANATION')}</div>
          <Form
            onSubmit={this.onSubmit}
            initialValues={this.state.initialValues}
            render={({
 handleSubmit, form, values, invalid,
}) => (
  <form
    className="identification-form"
    onSubmit={handleSubmit}
  >
    {currentSessionService().currentUser().canAccessCmCic && currentSessionService().currentUser().businessUnitCountryCode === LanguageIsoCode.FRENCH &&
    <div>
      <div className="form-question">{I18n.t('processCase.identification.SELECT_BPARTNER')}</div>
      <div className="radios-button-line">
        <label>
          <Field
            className="radio-input"
            name="isOtherBpartner"
            component="input"
            type="radio"
            value={typeOfBPartner.OTHER}
            onClick={() => {
              form.reset(this.state.initialValues);
            }}
          />
          <div className="radio-label">{I18n.t('form.OTHER')}</div>
        </label>
        <label>
          <Field
            className="radio-input"
            name="isOtherBpartner"
            component="input"
            type="radio"
            value={typeOfBPartner.CM_CIC}
            onClick={() => {
              form.reset(this.state.initialValues);
            }}
          />
          <div className="radio-label">{I18n.t('form.CM_CIC')}</div>
        </label>

        { currentSessionService().currentUser().checkEligibilityByVisa &&
          <label>
            <Field
              className="radio-input"
              component="input"
              name="isOtherBpartner"
              type="radio"
              value={typeOfBPartner.VISA}
              onClick={() => {
                form.reset(this.state.initialValues);
              }}
            />
            <div className="radio-label">{I18n.t('form.VISA')}</div>
          </label>
        }
      </div>
      {values.isOtherBpartner === typeOfBPartner.VISA &&
      <IdentificationVisa values={values} loading={this.state.loading} invalid={invalid} />
      }
      {values.isOtherBpartner === typeOfBPartner.CM_CIC &&
      <div>
        <div>{I18n.t('processCase.identification.SELECT_PROTOCOL')}</div>
        <div className="protocolCmCic-list">
          <Field
            name="patient.protocolCmCic"
            placeholder={I18n.t('processCase.identification.PLACEHOLDER_BP')}
            component={CoreozSelect}
            validate={required}
            list={this.state.protocolsCmCic.map(protocol => ({
                          id: protocol.protocolNumber,
                          label: (`${protocol.bpartnerName } - ${ protocol.protocolNumber}`),
                        }))}
          />
        </div>
      </div>
                  }
    </div>
                }

    {(values.isOtherBpartner === typeOfBPartner.OTHER || currentSessionService().currentUser().businessUnitCountryCode === LanguageIsoCode.SPANISH ) && ((!currentSessionService().currentUser().canAccessCmCic || (currentSessionService().currentUser().canAccessCmCic && values.isOtherBpartner != null && values.isOtherBpartner === typeOfBPartner.OTHER)) || (currentSessionService().currentUser().useOneProtocolAndPolicyForTreatingCases)) &&
    <div>
      <div className="form-question">{I18n.t('processCase.identification.TELECONSULT_QUESTION')}</div>
      <div className="radios-button-line">
        <label>
          <Field
            className="radio-input"
            component="input"
            name="goal"
            type="radio"
            value="true"
            format={value => String(value)}
            parse={value => value === 'true'}
          />
          <div className="radio-label">{I18n.t('form.YES')}</div>
        </label>
        <label>
          <Field
            className="radio-input"
            component="input"
            name="goal"
            type="radio"
            value="false"
            format={value => String(value)}
            parse={value => value === 'true'}
          />
          <div className="radio-label">{I18n.t('form.NO')}</div>
        </label>
      </div>
    </div>
                }
    {(values.goal != null && !values.goal) &&
    <div className="error-container">
      <div className="error-title">{I18n.t('processCase.identification.ERROR_TITLE')}</div>
      <div className="error-message">{I18n.t('processCase.identification.ERROR_MESSAGE')}</div>
      <div className="error-action">
        <Button color="secondary" onClick={this.goBack}>
          <i className="button-icon fa fa-long-arrow-left" />{I18n.t('button.END_CALL')}
        </Button>
      </div>
    </div>
    }

    {((( (values.isOtherBpartner != null && values.isOtherBpartner != typeOfBPartner.VISA) && ((values.goal != null && values.goal) || values.isOtherBpartner === typeOfBPartner.CM_CIC)) || (!currentSessionService().currentUser().canAccessCmCic && (values.goal != null && values.goal))) || (currentSessionService().currentUser().useOneProtocolAndPolicyForTreatingCases && (values.goal != null && values.goal))) &&
    <>
      <div className="form-question">{I18n.t('processCase.identification.BENEFICIARY_QUESTION')}</div>
      <div className="radios-button-line">
        <label>
          <Field
            className="radio-input"
            component="input"
            name="beneficiary"
            type="radio"
            value="true"
            format={value => String(value)}
            parse={value => value === 'true'}
          />
          <div className="radio-label">{I18n.t('form.YES')}</div>
        </label>
        <label>
          <Field
            className="radio-input"
            component="input"
            name="beneficiary"
            type="radio"
            value="false"
            format={value => String(value)}
            parse={value => value === 'true'}
          />
          <div className="radio-label">{I18n.t('form.NO')}</div>
        </label>
      </div>
      {values.beneficiary != null &&
      <div id="info-patient">
        {values.beneficiary ?
          <div className="form-question">
            {I18n.t('processCase.identification.CALLER_INFORMATION_QUESTION')}
          </div>
                          :
          <div className="form-question">
            {I18n.t('processCase.identification.RELATIVE_INFORMATION_QUESTION')}
          </div>
                        }
        <Field
          component={CoreozInput}
          className="form-input"
          name="patient.firstName"
          label={I18n.t('form.FIRST_NAME')}
          validate={required}
          required
        />
        <Field
          component={CoreozInput}
          className="form-input"
          name="patient.lastName"
          label={I18n.t('form.NAME')}
          validate={required}
          required
        />
        <Field
          component={CoreozDatePicker}
          name="patient.birthDate"
          className="form-input"
          label={I18n.t('form.DATE_OF_BIRTH')}
          validate={e => dateValidator(e, I18n.t('date.DATE_FORMAT'))}
          dateFormat={I18n.t('date.DATE_FORMAT')}
          placeholder={I18n.t('form.placeholder')}
          onlyDate
          required
        />
        <div className="form-action">
          <Button
            color="primary"
            type="submit"
            disabled={invalid || this.state.loading}
          >
            {
                              this.state.loading ?
                                <Loader small />
                                :
                                <span> <i className="button-icon fa fa-search" />{I18n.t('button.SEARCH')}</span>
                            }
          </Button>
        </div>
      </div>
                  }
    </>
                }
  </form>
            )}
          />
        </div>
        <CreatePatientModal
          modal={this.state.createPatientModal}
          toggle={() => this.setState({ createPatientModal: !this.state.createPatientModal })}
          patientInfosToSave={this.state.patientToUse}
          submitFunction={this.createPatient}
        />

      </div>
    );
  }
}

export default withRouter(connect()(Identification));
