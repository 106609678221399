import React, { useState, useEffect, useContext, useRef } from 'react';
import Pagination from 'react-js-pagination';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { SearchCriteriaType, SearchResultType } from '../../types/BusinessUnitTypes';
import {
  BUSINESS_UNIT_CREATE_PAGE_ROUTE,
  BUSINESS_UNIT_DETAILS_PAGE_ROUTE,
} from '../../routes';
import BusinessUnitContext from '../../stores/business-unit-management-context';

import { CoreozInputBase, CoreozSelectBase as CoreozSelect } from '../../lib/coreoz-form-base';
import { required } from '../../validator';
import BusinessTable from '../../components/businessUnit/BusinessTable';
import LogoTable from '../logo/component/logoTable';
import Loader from '../../components/Loader';
import businessUnitApi from '../../network/api/businessUnitApi';
import languageApi from '../../network/api/languageApi';
import referentialApi from '../../network/api/referentialApi';
import { notifyError } from '../../network/notification';
import ButtonExport from '../../components/ButtonExport';
import exportApi from '../../network/api/exportApi';
import { download } from '../../utils';

const BusinessUnitManagement = (props) => {
  const { searchCriteriaContext, updateSearchCriteriaContext } = useContext(BusinessUnitContext);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSearchResult, setTotalSearchResult] = useState(0);
  const [languages, setLanguages] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [value, setValue] = useState();
  const [countries, setCountries] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    fetchCountries();
    if (searchCriteriaContext) {
      fetchBusinessUnit(searchCriteriaContext);
    } else {
      fetchBusinessUnit({
        page,
        size: pageSize,
      });
    }
  }, []);


  async function fetchBusinessUnit(criteria: SearchCriteriaType) {
    setLoading(true);

    const searchCriteria = {
      ...criteria,
      page: criteria.page || page,
      size: criteria.size || pageSize,
    };

    const searchResultResponse = await props.dispatch(businessUnitApi.search(searchCriteria));

    updateSearchCriteriaContext({ ...searchCriteria });

    setLoading(false);

    setSearchResults((searchResultResponse) ? searchResultResponse.content : []);

    setTotalSearchResult((searchResultResponse) ? searchResultResponse.total : 0);
    setPerPage((searchResultResponse) ? searchResultResponse.perPage : 0);
    setTotalPages((searchResultResponse) ? searchResultResponse.totalPages : 0);
  }


  function fetchCountries() {
    referentialApi
      .fetchCountriesBySelectedLanguage(1)
      .then(response => response.json())
      .then(response => setCountries(response))
      .catch(props.dispatch(notifyError));
  }

  const handlePageChange = (pageNumber) => {
    fetchBusinessUnit({
      ...searchCriteriaContext,
      page: pageNumber,
    });
    setPage(pageNumber);
  };

  const resetSearchResults = () => {
    setSearchResults([]);
    setTotalSearchResult(0);
    setPage(1);
    setTotalPages(0);

    fetchBusinessUnit({
      page,
      size: pageSize,
    });
  };

  const goToView = (itemId: Number) => {
    props.history.push(BUSINESS_UNIT_DETAILS_PAGE_ROUTE.replace(':id', itemId));
  };

  const downloadReport = (searchParameters: Object) => {
    setExportLoading(true);

    const filters = {
      ...searchParameters,
      columnsTranslation: I18n.t('businessUnit.export'),
    };

    exportApi
      .exportBusinessUnitKpi(filters)
      .then(response => response.blob())
      .then(blob =>
        download(blob, 'export_business_unit.csv', () => {
          setExportLoading(false);
        })).catch((e) => {
        setExportLoading(false);
        notifyError(props.dispatch)(e);
      });
  };


  return (

    <div className="business-unit-management-container" id="businessUnitsManagement">
      <div className="title-container">
        <div className="title">{I18n.t('businessUnit.TITLE_LIST')}</div>
      </div>
      <div className="search-bar">
        <Form
          onSubmit={fetchBusinessUnit}
          render={({ handleSubmit, form,values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                type="text"
                name="businessUnitName"
                id="businessUnitName"
                label={I18n.t('businessUnit.label.businessUnitName')}
                component={CoreozInputBase}
                defaultValue={searchCriteriaContext && searchCriteriaContext.businessUnitName}
              />
              <Field
                name="countryName"
                id="countryName"
                label={I18n.t('businessUnit.label.countryName')}
                component={CoreozSelect}
                placeholder="All"
                type="text"
                disabledPlaceholder={false}
                required={false}
                list={countries && countries.map(countrie => ({
                    id: countrie.label.toLowerCase(),
                    label: countrie.label,
                }))}
                defaultValue={searchCriteriaContext && searchCriteriaContext.countryName}
              />
              <Field
                component={CoreozSelect}
                id="countryCode"
                type="text"
                name="countryCode"
                label={I18n.t('businessUnit.label.countryCode')}
                placeholder="All"
                disabledPlaceholder={false}
                required={false}
                list={countries && countries.map(country => ({
                          id: country.isoCode.toLowerCase(),
                          label: country.isoCode,
                        }))}
                defaultValue={searchCriteriaContext && searchCriteriaContext.countryCode}
              />

              <div className="layout-third-row search-box pt-5 mt-2">
                <Button
                  type="submit"
                  color="primary"
                  className="validation-button m-0"
                  id="searchBtnSearch"
                >
                  <i className="fa fa-search" />{I18n.t('button.SEARCH')}
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="validation-button m-0"
                  onClick={() => {
                            form.reset();
                            resetSearchResults();
                          }}
                  id="resetBtnSearch"
                >
                  <i className="fa fa-redo-alt" />{I18n.t('button.RESET')}
                </Button>
              </div>

              <div className="layout-third-row search-box pt-0">

                <ButtonExport downloadReport={downloadReport} loading={exportLoading} values={values} />
                <Button
                  type="button"
                  color="primary"
                  className="validation-button m-0"
                  onClick={() => {
                            props.history.push(BUSINESS_UNIT_CREATE_PAGE_ROUTE);
                          }}
                  id="createButton"
                >
                  <i className="fa fa-plus" />{I18n.t('brandManagement.button.ADD')}
                </Button>
              </div>
            </form>
              )}
        />
      </div>

      <div className="search-result">
        {
                !loading && !!searchResults.length &&
                <>
                  <div className="box-result">
                    <BusinessTable currentItems={searchResults} goToView={goToView} />
                  </div>
                  <div className="box-paginate">
                    <div className="show-paginate-result">
                      {I18n.t('logoManagement.TABLE.PAGINATION_INFO', { start: ((page - 1) * pageSize) + 1, end: ((page - 1) * pageSize) + pageSize, total: totalSearchResult })}
                    </div>
                    <div className="paginate-list">
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="&laquo;"
                        lastPageText="&raquo;"
                        prevPageText="&lsaquo;"
                        nextPageText="&rsaquo;"
                        activePage={page}
                        itemsCountPerPage={totalPages}
                        totalItemsCount={totalSearchResult}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                </>
        }
        {!loading && !searchResults.length &&
          <div className="no-result">{I18n.t('internalBeneficiary.SEARCH.NO_RESULT')}</div>
          }
        {
              loading && <div className="center-child search-loader"><Loader /></div>
          }
      </div>

    </div>

  );
};

export default withRouter(connect()(BusinessUnitManagement));
