// @flow

import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { required } from '../../validator'

type Props = {
  dispatch: Function,
  propositions: Array,
  title?: string,
  name: string,
  validate: Boolean,
  needed: boolean,
}

class MedicineRadioButtons extends React.Component<Props> {
  render() {
    return (
      this.props.needed &&
      <div className="radio-button-form-container container-input-coreoz medicine-container">
        <div className="radio-button-container medicine-list">
          {this.props.propositions.length > 0 &&
          this.props.propositions.map(item => (
            <div key={item.idProduct} className="medicine-ligne">
              <div>
                <label>
                  <Field
                    component="input"
                    name={this.props.name}
                    type="radio"
                    value={item.idProduct}
                    className="radio"
                    validate={required}
                  />
                  <span>{item.title}</span>
                </label>
              </div>
              {item.productUrl !== '' &&
              <button
                className="btn btn-default"
                type="button"
                onClick={() => window.open(item.productUrl, '_blank')}
              >
                <i className="fa fa-eye" />
                {I18n.t('button.SEARCH')}
              </button>
              }
            </div>
          ))
          }
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(MedicineRadioButtons));
