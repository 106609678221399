// @ flow

import React from 'react';
import { Switch } from 'react-router-dom';

import {
  ADVICE_REPORT_PAGE_ROUTE,
  CASE_PROCESS_PAGE_ROUTE,
  CONSENTS_PAGE_ROUTE,
  ELIGIBILITY_PAGE_ROUTE,
  IDENTIFICATION_PAGE_ROUTE,
  MED_REPORT_PAGE_ROUTE,
  PRESCRIPTION_PAGE_ROUTE,
  PROCESS_DETAILS_PAGE_ROUTE,
  QUALIFICATION_PAGE_ROUTE,
  SENDING_DOCUMENTS_PAGE_ROUTE,
  TELECONSULTATION_PAGE_ROUTE,
} from '../routes';
import Identification from './identification/Identification';
import Administration from './administration/Administration';
import Qualification from './qualification/Qualification';
import Eligibility from './eligibility/Eligibility';
import ConsentsCollect from './consentsCollect/ConsentsCollect';
import Teleconsultation from './teleconsultation/Teleconsultation';
import MedicalReport from './medicalReport/MedicalReport';
import SendingDocuments from './sendDocuments/SendingDocuments';
import Prescription from './prescription/Prescription';
import TeleAdviceReport from '../components/tele-advice-report/TeleAdviceReport';
import { currentSessionService } from '../services/sessionServiceInstance';
import PrivateRoute from '../components/PrivateRoute';

function CaseProcessRoutes(props) {
  return (
    <Switch>
      {currentSessionService().currentUser().showIdentification &&
        <PrivateRoute
          path={CASE_PROCESS_PAGE_ROUTE + IDENTIFICATION_PAGE_ROUTE}
          component={Identification}
        />
      }
      {currentSessionService().currentUser().showIdentification &&
      <PrivateRoute
        path={CASE_PROCESS_PAGE_ROUTE + ELIGIBILITY_PAGE_ROUTE}
        component={Eligibility}
      />
      }
      {
        props.queryParams.patientId &&
        <PrivateRoute
          path={CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE}
          component={() => <Administration {...props} />}
        />
      }
      {
        props.queryParams.patientId &&
        <PrivateRoute
          path={CASE_PROCESS_PAGE_ROUTE + CONSENTS_PAGE_ROUTE}
          component={() => <ConsentsCollect {...props} />}
        />
      }
      {
        props.queryParams.caseId &&
        <>
          {currentSessionService().currentUser().showIdentification &&
          <PrivateRoute
            path={CASE_PROCESS_PAGE_ROUTE + QUALIFICATION_PAGE_ROUTE}
            component={() => <Qualification {...props} />}
            exact
          />
          }
          <PrivateRoute
            exact
            path={CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE}
            component={() => <Teleconsultation {...props} />}
          />
          <PrivateRoute
            path={CASE_PROCESS_PAGE_ROUTE + SENDING_DOCUMENTS_PAGE_ROUTE}
            component={() => <SendingDocuments {...props} />}
          />
          <PrivateRoute
            path={CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + PRESCRIPTION_PAGE_ROUTE}
            component={() => <Prescription {...props} />}
          />
          <PrivateRoute
            path={CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + MED_REPORT_PAGE_ROUTE}
            component={() => <MedicalReport {...props} />}
          />
          <PrivateRoute
            path={CASE_PROCESS_PAGE_ROUTE + QUALIFICATION_PAGE_ROUTE + ADVICE_REPORT_PAGE_ROUTE}
            component={() => <TeleAdviceReport {...props} />}
          />
        </>
      }
    </Switch>
  );
}

export default CaseProcessRoutes;
