// @flow

import { RestClientAuthenticated } from '../network';

const processCaseApi = {
  fetchCaseInfos: (idCase: string, patientId: string) => new RestClientAuthenticated(`/process-case/caseInfos?idCase=${idCase || ''}&idPatient=${patientId || ''}`, 'GET').execute(),
  fetchCallQualificationCode: () => new RestClientAuthenticated('/process-case/call-qualification-code', 'GET').execute(),
  saveQualification: (data: any) => new RestClientAuthenticated('/process-case/qualification', 'POST').jsonBody(data).execute(),
};
export default processCaseApi;
