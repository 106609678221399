// @flow

import React from 'react';

type Props = {
  placeholder?: string,
  className?: string,
  label?: string,
  minrows: number,
  maxrows: number,
  initial?: string,
  onChange: Function,
  onBlur: Function,
  onFocus: Function,
  input: {
    value: string,
    onFocus: Function,
    onChange: Function,
    onBlur: Function,
  }
}

type State = {
  value: string,
  rows: number,
}

class ResizableTextarea extends React.PureComponent<Props, State> {
  textArea: ?HTMLTextAreaElement;

  static defaultProps = {
    className: '',
    placeholder: '',
    label: null,
    initial: null,
  };

  state = {
    value: this.props.input.value || this.props.initial || '',
    rows: this.props.minrows,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.input.value !== nextProps.input.value) {
      this.setState({
        value: nextProps.input.value,
      }, () => {this.updateTextareaSize()});
    }
  }

  componentDidMount() {
    let currentRows = this.calculateRows();
    this.setState({
      rows: currentRows < this.props.maxrows ? currentRows : this.props.maxrows,
    });
  }

  handleChange = (event: Object) => {
    let currentRows = this.calculateRows(true);
    this.setState({
      value: event.target.value,
      rows: currentRows < this.props.maxrows ? currentRows : this.props.maxrows,
    });
  };

  calculateRows = (pushToEnd: ?boolean) => {
    const textareaLineHeight = 24;
    if (!this.textArea) {
      return this.props.minrows;
    }

    const previousRows = this.textArea.rows;
    this.textArea.rows = this.props.minrows; // reset number of rows in textarea

    const currentRows = Math.floor(this.textArea.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      this.textArea.rows = currentRows;
    }

    if (currentRows >= this.props.maxrows) {
      this.textArea.rows = this.props.maxrows;
      if (pushToEnd) {
        this.textArea.scrollTop = this.textArea.scrollHeight;
      }
    }

    return currentRows;
  };

  updateTextareaSize = () => {
    let currentRows = this.calculateRows();
    this.setState({
      rows: currentRows < this.props.maxrows ? currentRows : this.props.maxrows,
    });
  };

  render() {
    return (
      <div className={`base-container-input-coreoz ${this.props.className || ''}`}>
        {this.props.label &&
        <div className="base-label-input-coreoz">
          {this.props.label}
        </div>
        }
        <textarea
          {...this.props.input}
          {...this.props}
          rows={this.state.rows}
          value={this.state.value}
          placeholder={this.props.placeholder}
          className="textarea base-input-coreoz"
          ref={textArea => (this.textArea = textArea)}
          onChange={(e) => {
            this.handleChange(e);
            this.props.input.onChange(e);
            if (this.props.onChange) this.props.onChange(e);
          }}
          onFocus={(e) => {
            this.props.input.onFocus(e);
            if (this.props.onFocus) this.props.onFocus(e);
          }}
          onBlur={(e) => {
            this.props.input.onBlur(e);
            if (this.props.onBlur) this.props.onBlur(e);
          }}
        />
      </div>
    );
  }
}

export default ResizableTextarea;
