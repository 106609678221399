// @flow

import { reduce } from '../../network/reduce';
import type { DashboardFilters } from '../../types/DashboardTypes';

export type UserStateType = {
  dashboardFilters: DashboardFilters,
  dashboardMenu: Boolean,

};

const initialState: UserStateType = {
  dashboardFilters: {},
  dashboardMenu: true,

};

export const DASHBOARD_FILTERS = 'DASHBOARD_FILTERS';
export const DASHBOARD_MENU = 'DASHBOARD_MENU';


const dashboardReducer = reduce(initialState, {
  [DASHBOARD_FILTERS]: 'dashboardFilters',
  [DASHBOARD_MENU]: 'dashboardMenu',

});

export default dashboardReducer;
