// @flow

import { RestClientAuthenticated } from '../../network/network';
import type { Sms } from '../../types/SmsTypes';

const smsApi = {
  fetch: () => new RestClientAuthenticated('/sms', 'GET').execute(),
  save: (data: Sms) =>
    new RestClientAuthenticated('/sms', 'POST').jsonBody(data).execute(),
};

export default smsApi;
