// @flow

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';

import PlmPanel from '../../components/panel/PlmPanel';
import { BUSINESS_UNIT_PAGE_ROUTE } from '../../routes';
import { required } from '../../validator';
import type { BusinessUnit } from '../../types/BusinessUnitTypes';
import { displayModal } from '../../state/notifications/notificationService';

import businessUnitApi from '../../network/api/businessUnitApi';
import { BUSINESSUNITS_ } from '../../state/businessUnit/businessUnitReducer';
import { store } from '../../network/reduce';
import { fetchBusinessUnits } from '../../state/businessUnit/businessUnitService';
import { notifyError, notifySuccess } from '../../network/notification';
import { CoreozInputBase as CoreozInput, CoreozSelectBase as CoreozSelect } from '../../lib/coreoz-form-base/index';
import RadioButton from '../../components/businessUnit/RadioButton';
import languageApi from '../../network/api/languageApi';
import { Col, Row } from 'reactstrap';

type Props = {
  businessUnits: BusinessUnit[],
  match: {
    params: {
      id: string,
    }
  },
  history: Object,
  dispatch: Function,
}

type State = {
  labelTitle: string,
  businessUnitSelected: BusinessUnit,
  languages: string[],

}

class BusinessUnitDetails extends React.Component<Props, State> {
  state = {
    labelTitle: I18n.t('businessUnit.NEW_TITLE'),
    businessUnitSelected: {
      id: null,
      name: null,
      prmId: null,
      canSendByFax: true,
      showChat: true,
      showIdentification: true,
      showLogoInDocument: true,
      showNewCaseButtonInDashboard: true,
      showQualification: true,
      showTeleAdviceReport: true,
      showViewFilterInDashboard: true,
      timezone: null,
      prmIdCountry: null,
      countryCode: null,
      countryName: null,
      doctorShouldCallback: true,
    },
    languages: [],
  };

  componentDidMount() {
    languageApi.fetchLanguages()
      .then(response => response.json())
      .then(response => this.setState({ languages: response }))
      .catch(e => console.log('Erreur apppel API', e));
    if (this.props.match.params.id !== null) {
      const businessUnitSelectedId = this.props.match.params.id;
      businessUnitApi
        .fetch()
        .then(response => response.json())
        .then((businessUnits) => {
          this.props.dispatch(store(BUSINESSUNITS_, businessUnits));
          const currentBusinessUnit = businessUnits.filter(businessUnit => businessUnit.id === businessUnitSelectedId)[0];
          if (businessUnitSelectedId === 'new') {
            this.setState({
              businessUnitSelected: {
                id: null,
                name: null,
                prmId: null,
                canSendByFax: true,
                showChat: true,
                showIdentification: true,
                showLogoInDocument: true,
                showNewCaseButtonInDashboard: true,
                showQualification: true,
                showTeleAdviceReport: true,
                showViewFilterInDashboard: true,
                canAccessCmCic: null,
                canCreatePatientInIdentificationPage: null,
                canResendMailVideo: null,
                timezone: null,
                prmIdCountry: null,
                countryCode: null,
                countryName: null,
                doctorShouldCallback: true,
                recordingsSentToAida: true,
                reportAddressedToPatient: true,
                sendCaseTimeRecordingsToAida: true,
                sendNotificationWhenCaseCreated: true,
                showAddressInDocument: true,
                showEmailServiceMedicalReport: true,
                showHospitals: true,
                showNonMedicationNotesFirst: true,
                useParentingForCodings: true,
                showAlertWhenNoMedicalReport: null,
              },
              labelTitle: I18n.t('businessUnit.NEW_TITLE'),
            });
          } else if (currentBusinessUnit) {
            this.setState({
              businessUnitSelected: currentBusinessUnit,
              labelTitle: I18n.t('businessUnit.EDIT_TITLE'),
            });
          }
        })
        .catch(e => console.log('Erreur apppel API', e));
    }
  }

  saveBusinessUnit = (businessUnit: BusinessUnit) => {
    businessUnitApi
      .save(businessUnit)
      .then(this.props.dispatch(notifySuccess))
      .then(() => {
        this.props.dispatch(fetchBusinessUnits());
        this.props.history.push(BUSINESS_UNIT_PAGE_ROUTE);
      })
      .catch(this.props.dispatch(notifyError));
  };

  deleteBusinessUnit = (businessUnit: BusinessUnit) => {
    businessUnitApi
      .delete(businessUnit.id)
      .then(this.props.dispatch(notifySuccess))
      .then(() => {
        this.props.dispatch(fetchBusinessUnits());
        this.props.history.push(BUSINESS_UNIT_PAGE_ROUTE);
      })
      .catch(this.props.dispatch(notifyError));
  };

  render() {
    if (this.props.businessUnits === undefined) {
      return false;
    }
    return (
      <div>
        <h1 className="content-title"><Translate value="businessUnit.TITLE" /></h1>
        <div className="content">
          <PlmPanel title={this.state.labelTitle}>
            <Form
              className="form-horizontal"
              onSubmit={this.saveBusinessUnit}
              initialValues={this.state.businessUnitSelected}
              render={({ handleSubmit, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="panel-body">
                    <Field
                      component={CoreozInput}
                      name="name"
                      label={I18n.t('businessUnit.label.name')}
                      validate={required}
                    />

                    <Field
                      label={I18n.t('businessUnit.label.prmId')}
                      component={CoreozInput}
                      name="prmId"
                      disabled
                    />
                    <Row>
                      <Col>
                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.canSendByFax')}</span>
                          <RadioButton name="canSendByFax" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showChat')}</span>
                          <RadioButton name="showChat" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showIdentification')}</span>
                          <RadioButton name="showIdentification" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showLogoInDocument')}</span>
                          <RadioButton name="showLogoInDocument" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showNewCaseButtonInDashboard')}</span>
                          <RadioButton name="showNewCaseButtonInDashboard" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showQualification')}</span>
                          <RadioButton name="showQualification" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showTeleAdviceReport')}</span>
                          <RadioButton name="showTeleAdviceReport" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showViewFilterInDashboard')}</span>
                          <RadioButton name="showViewFilterInDashboard" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.canAccessCmCic')}</span>
                          <RadioButton name="canAccessCmCic" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.canCreatePatientInIdentificationPage')}</span>
                          <RadioButton name="canCreatePatientInIdentificationPage" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.canResendMailVideo')}</span>
                          <RadioButton name="canResendMailVideo" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.emailFooterTakeInLegalEntityObject')}</span>
                          <RadioButton name="emailFooterTakeInLegalEntityObject" />
                        </div>

                      </Col>
                      <Col>
                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.doctorShouldCallback')}</span>
                          <RadioButton name="doctorShouldCallback" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.recordingsSentToAida')}</span>
                          <RadioButton name="recordingsSentToAida" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.reportAddressedToPatient')}</span>
                          <RadioButton name="reportAddressedToPatient" />
                        </div>
                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.sendNotificationWhenCaseCreated')}</span>
                          <RadioButton name="sendNotificationWhenCaseCreated" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.sendNotificationPatientWaitingInitial')}</span>
                          <RadioButton name="sendNotificationPatientWaitingInitial" />
                        </div>
                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.sendNotificationPatientWaitingGlobal')}</span>
                          <RadioButton name="sendNotificationPatientWaitingGlobal" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showAddressInDocument')}</span>
                          <RadioButton name="showAddressInDocument" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showEmailServiceMedicalReport')}</span>
                          <RadioButton name="showEmailServiceMedicalReport" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showHospitals')}</span>
                          <RadioButton name="showHospitals" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showNonMedicationNotesFirst')}</span>
                          <RadioButton name="showNonMedicationNotesFirst" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.useParentingForCodings')}</span>
                          <RadioButton name="useParentingForCodings" />
                        </div>

                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.useOneProtocolAndPolicyForTreatingCases')}</span>
                          <RadioButton name="useOneProtocolAndPolicyForTreatingCases" />
                        </div>
                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showOneConsentInConsentPage')}</span>
                          <RadioButton name="showOneConsentInConsentPage" />
                        </div>
                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories')}</span>
                          <RadioButton name="makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories" />
                        </div>
                        <div className="boolean-field">
                          <span>{I18n.t('businessUnit.label.showAlertWhenNoMedicalReport')}</span>
                          <RadioButton name="showAlertWhenNoMedicalReport" />
                        </div>
                      </Col>

                    </Row>
                    <Field
                      component={CoreozSelect}
                      name="countryCode"
                      label={I18n.t('businessUnit.label.countryCode')}
                      validate={required}
                      list={this.state.languages.map(language => ({
                        id: language.countryCode,
                        label: I18n.t(`businessUnit.languages.${language.countryCode}`),
                      }))}
                    />
                  </div>

                  <div className="panel-footer">
                    <Link
                      className="btn btn-secondary"
                      style={{ marginRight: '5px' }}
                      to={BUSINESS_UNIT_PAGE_ROUTE}
                    >
                      <Translate value="actions.BACK" />
                    </Link>
                    <button type="submit" className="btn btn-success" disabled={invalid || submitting}>
                      <Translate value="actions.SAVE" />
                    </button>
                    {this.props.match.params.id !== 'new' &&
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={() => this.props.dispatch(displayModal(
                        I18n.t('businessUnit.modal.title'),
                        I18n.t('businessUnit.modal.message'),
                        () => this.deleteBusinessUnit(this.state.businessUnitSelected),
                      ))}
                    >
                      <Translate value="actions.DELETE" />
                    </button>
                    }
                  </div>
                </form>
              )}
            />
          </PlmPanel>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  businessUnits: state.businessUnit.businessUnits,
}))(BusinessUnitDetails));

