// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase, CoreozSelectBase as CoreozSelect } from '../../lib/coreoz-form-base/index';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import Tile from '../../components/Tile';
import Loader from '../../components/Loader';
import caseApi from '../../network/api/caseApi';
import { notifyError, notifySuccess } from '../../network/notification';
import { AdviceCodingStatus, CodingCriteria } from '../../enum';
import ModalAddCoding from './ModalAddCoding';
import ModalEprm from '../../components/modal/ModalEprm';
import adviceCodingApi from '../../network/api/adviceCodeingAPI';

type Props = {
  dispatch: Function,
};

type State = {
  adviceCodings: Array<Object>,
  loading: boolean,
  addAdviceCodingModal: boolean,
  selectedCode: Object,
  codeAdviceToUpdate: Object,
  updateAdviceCoding: boolean,
  openDeleteModal: boolean,
  valuesToSearch: string,
  searchName: string,
};

class AdviceCodingSearch extends React.Component<Props, State> {
  state = {
    adviceCodings: [],
    loading: false,
    addAdviceCodingModal: false,
    codeAdviceToUpdate: {},
    updateAdviceCoding: false,
    openDeleteModal: false,
    valuesToSearch: '',
  };

  onSubmit = (values: Object) => {
    this.setState({
      loading: true,
      adviceCodings: [],
      valuesToSearch: values.name || '',
    });
    this.searchAdviceCoding(values.name || '');
  };

  searchAdviceCoding = (name: string, isStatus = null) => {
    adviceCodingApi.searchAdviceCodingList(name)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((adviceCodings) => {
        if (isStatus !== null) {
          this.setState({
            adviceCodings: adviceCodings.filter(advice => isStatus === advice.isActive),
          });
        } else {
          this.setState({ adviceCodings });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        notifyError(this.props.dispatch)(e);
        this.setState({ loading: false });
      });
  };


  saveAdviceCoding = (values: Object) => {
    adviceCodingApi.save(values)
      .then(() => {
        this.setState({ addAdviceCodingModal: !this.state.addAdviceCodingModal });
        this.searchAdviceCoding(this.state.valuesToSearch);
      })
      .then(this.props.dispatch(notifySuccess))
      .catch(notifyError(this.props.dispatch));
  };

  componentDidMount() {
    this.searchAdviceCoding('');
  }

  updateAdviceCoding = (values: Object) => {
    adviceCodingApi.update(values, values.id)
      .then(() => {
        this.setState({ updateAdviceCoding: !this.state.updateAdviceCoding });
        this.searchAdviceCoding(this.state.valuesToSearch);
      })
      .then(this.props.dispatch(notifySuccess))
      .catch(notifyError(this.props.dispatch));
  };

  deleteAdviceCoding = (values) => {
    values.forEach(codingId => adviceCodingApi
      .delete(codingId)
      .then(() => this.setState({
        adviceCodings: this.state.adviceCodings.filter(adviceCoding => adviceCoding.id !== codingId),
      }))
      .catch(this.props.dispatch(notifyError)));
  };

  updateValues = (adviceId: string) => {
    this.setState({
      codeAdviceToUpdate: this.state.adviceCodings.filter(adviceCoding => adviceCoding.id === adviceId)[0],
      updateAdviceCoding: !this.state.updateAdviceCoding,
    });
  }

  sortCodingList = (criteria) => {
    if (criteria !== null) {
      const isStatus = criteria === 'true';
      this.searchAdviceCoding(this.state.valuesToSearch, isStatus);
    }
  }

  render() {
    return (
      <div className="advice-coding-search-container">
        <div className="title-container">
          <div className="title">{I18n.t('adviceCodingSearch.TITLE')}</div>
        </div>
        <div className="search-bar">
          <Form
            onSubmit={this.onSubmit}
            initialValues={{ name: '' }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="criteria">
                  <Field
                    placeholder={I18n.t('adviceCodingSearch.PLACE_HOLDER')}
                    name="name"
                    component={CoreozInputBase}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    className="validation-button"
                  >
                    <i className="button-icon fa fa-search" />
                    {I18n.t('button.SEARCH')}
                  </Button>
                </div>
              </form>
            )}
          />
        </div>
        <div className="search-result">
          {this.state.adviceCodings.length > 0 &&
          <Form
            onSubmit={this.onSubmit}
            initialValues={{ selectAll: false }}
            render={({
 handleSubmit, values, form, invalid,
}) => (
  <form onSubmit={handleSubmit}>
    <div className="result-action">
      <div className="sort-action">
        <Field
          component="input"
          name="selectAll"
          type="checkbox"
          onClick={() => {
                        if (!values.selectAll) {
                          form.change('coding', this.state.adviceCodings.map(code => code.id));
                        } else {
                          form.change('coding', []);
                        }
                      }}
        />
        <Field
          name="sortValue"
          component={CoreozSelect}
          placeholder={I18n.t('adviceCodingSearch.SORT_BY')}
          list={Object.keys(AdviceCodingStatus).map(criteria => ({
                        id: AdviceCodingStatus[criteria],
                        label: I18n.t(`pathologyCodingSearch.${criteria}`),
                      }))}
          onChange={newValue => this.sortCodingList(newValue)}
          disabledPlaceholder={false}
        />
      </div>
      <div className="action-coding">
        <Button
          type="button"
          color="primary"
          onClick={() => this.setState({
                        addAdviceCodingModal: !this.state.addAdviceCodingModal,
                      })}
        >
          <i className="button-icon fa fa-plus" />
          {I18n.t('actions.ADD')}
        </Button>
        <Fragment>
          <Button
            type="button"
            color="danger"
            className="delete-button"
            disabled={!(values.coding && values.coding.length > 0)}
            onClick={() => this.setState({ openDeleteModal: !this.state.openDeleteModal })}
          >
            <i className="button-icon fa fa-trash" />
            {I18n.t('button.DELETE')}
          </Button>
          {values.coding && values.coding.length > 0 &&
          <div>
            <div className="rows-to-delete">
              {I18n.t('adviceCodingSearch.TO_DELETE', { rows: values.coding.length })}
            </div>
            <ModalEprm
              title={I18n.t('adviceCodingSearch.DELETE')}
              modal={this.state.openDeleteModal}
              toggle={() => this.setState({ openDeleteModal: !this.state.openDeleteModal })}
            >
              <div>
                <div>{I18n.t('adviceCodingSearch.DELETE_CODE', { count: values.coding.length })}</div>
                <div>{I18n.t('adviceCodingSearch.DELETE_IMPACT')}</div>

                <div className="modal-actions">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.setState({ openDeleteModal: !this.state.openDeleteModal })}
                  >
                    <i className="button-icon fa fa-undo" />
                    {I18n.t('button.CANCEL')}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={invalid}
                    onClick={() => {
                                  this.deleteAdviceCoding(values.coding);
                                  form.change('coding', []);
                                }}
                  >
                    <i className="button-icon fas fa-paper-plane" />
                    {I18n.t('button.DELETE')}
                  </Button>
                </div>
              </div>
            </ModalEprm>
          </div>
                      }
        </Fragment>
      </div>
    </div>
    <div className="result-list">
      {this.state.adviceCodings
                    .map(adviceCoding => (
                      <Tile
                        className="tile-search-coding"
                        key={adviceCoding.id}
                      >
                        <div className="coding-checkbox">
                          <Field
                            component="input"
                            name="coding"
                            type="checkbox"
                            value={adviceCoding.id}
                          />
                        </div>
                        <div className="tile-informations" onClick={() => this.updateValues(adviceCoding.id)}>
                          <div className="coding-name">{adviceCoding.name}</div>
                          <div className="coding-name">{I18n.t(`adviceCodingSearch.STATUS.${adviceCoding.isActive ? 'ACTIVE' : 'INACTIVE'}`)}</div>
                          <div className="coding-parent" />
                          {/* Ici pour préserver le style de la tile coding */}
                          <div className="coding-date">
                            {I18n.t('adviceCodingSearch.CREATION_DATE', { date: moment(adviceCoding.creationDate).format(I18n.t('date.DATE_LONG')) })}
                          </div>
                        </div>
                      </Tile>
                    ))}
    </div>
  </form>
            )}
          />
          }
          {!this.state.loading && this.state.adviceCodings.length === 0 &&
          <div className="no-result">
            {I18n.t('adviceCodingSearch.NO_RESULT')}
            <Button
              type="button"
              color="primary"
              onClick={() => {
                this.setState({
                  addAdviceCodingModal: !this.state.addAdviceCodingModal,
                });
              }}
            >
              <i className="button-icon fa fa-plus" />
              {I18n.t('actions.ADD')}
            </Button>
          </div>}
          {this.state.loading && <div className="loader"><Loader /></div>}
        </div>
        <ModalAddCoding
          buttonValue={I18n.t('button.SAVE')}
          openModal={this.state.addAdviceCodingModal}
          title={I18n.t('adviceCodingSearch.ADD_CODE')}
          closeModal={() => {
            this.setState({ addAdviceCodingModal: !this.state.addAdviceCodingModal });
          }}
          saveCoding={this.saveAdviceCoding}
        />
        <ModalAddCoding
          buttonValue={I18n.t('button.UPDATE')}
          title={I18n.t('adviceCodingSearch.UPDATE_CODE')}
          openModal={this.state.updateAdviceCoding}
          closeModal={() => {
            this.setState({ updateAdviceCoding: !this.state.updateAdviceCoding });
          }}
          saveCoding={this.updateAdviceCoding}
          valuesInitial={this.state.codeAdviceToUpdate}
        />
      </div>
    );
  }
}

export default connect()(AdviceCodingSearch);
