// @flow
import { connect } from 'react-redux';
import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { CommunicationChannel, teleConsultCode, UserRole } from '../../../enum';
import Loader from '../../../components/Loader';
import CaseStatus from '../../dashboard/CaseStatus';
import { CASE_PROCESS_PAGE_ROUTE, QUALIFICATION_PAGE_ROUTE, TELECONSULTATION_PAGE_ROUTE } from '../../../routes';
import { createSearchFromParams } from '../../../services/queryParamsService';
import { currentSessionService } from '../../../services/sessionServiceInstance';
import appointmentApi from '../../../network/api/appointmentApi';
import { notifyError, notifySuccess } from '../../../network/notification';


type Props = {
  appointment: Object,
  dispatch: Function,
  onCloseModal: Function,
};

class EventDetail extends React.Component<Props, State> {
  getChannelIcon = (channel: string) => {
    let icon = '';
    if (channel === CommunicationChannel.PHONE) {
      icon = 'fa-phone-volume';
    } else if (channel === CommunicationChannel.VIDEO) {
      icon = 'fa-video';
    } else if (channel === CommunicationChannel.CHAT) {
      icon = 'fa-comments';
    } else {
      return <div><Loader small grey /></div>;
    }
    return (<i className="channel-icon--tl far fa-calendar-day">
      <i className={`channel-icon--br fa ${icon}`} />
            </i>);
  };

  deleteAvailability = (appointmentId) => {
    appointmentApi.deleteAvailability(appointmentId)
      .then(this.props.onCloseModal)
      .then(this.props.dispatch(notifySuccess))
      .catch(this.props.dispatch(notifyError));
  }

  openRelatedCase = (caseId: string, patientId: string) => {
    const queryParams = {
      caseId,
      patientId,
    };
    if (!!caseId && !!patientId) {
      if (currentSessionService().currentUser().showIdentification && currentSessionService().currentUser().showQualification) {
        this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + QUALIFICATION_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
      } else {
        this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
      }
    }
  };


  render() {
    console.log('this.props.appointment', this.props.appointment);
    return (
      <div className="row event-details">
        {this.props.appointment.communicationType &&
        <div className="icon">{this.getChannelIcon(this.props.appointment.communicationType)}</div>}
        <div className="block">
          <div>{this.props.appointment.participant}</div>
          {this.props.appointment.communicationType ?
            <div>{I18n.t('appointment.details.hour', { date: moment(this.props.appointment.date).format(I18n.t('date.DATE_HOUR')) /* todo format to have hour */ })}</div>
            :
            <div>{I18n.t('appointment.detailsEmpty.hour', { date: moment(this.props.appointment.date).format(I18n.t('date.DATE_HOUR')) /* todo format to have hour */ })}</div>
          }

          {this.props.appointment.communicationType && !this.props.appointment.isArchived && !this.props.appointment.takenInChargeName &&
          <CaseStatus
            userRole={this.props.appointment.caseQualification === teleConsultCode ?
              UserRole.DOCTOR_LEVEL : UserRole.NURSE_LEVEL}
            caseDateCreation={this.props.appointment.createdDate}
            sessionStartDate={this.props.appointment.dateStartSession}
            // status={this.props.appointment.status}
            channel={this.props.appointment.communicationType}
            blinkClassName={false}
          />
          }
        </div>

        <div className="block">
          {this.props.appointment.communicationType &&
          <div className="patient-informations">
            <div className="patient-informations-title">{I18n.t('appointment.details.patient')}</div>
            <div className="patient-informations-infos">
              {I18n.t(
'appointment.details.patientInfos',
                {
                  firstname: this.props.appointment.patientFirstName,
                  lastname: this.props.appointment.patientLastName,
                  birthdate: moment(this.props.appointment.patientBirthDate).format(I18n.t('date.DATE_FORMAT')),
                },
)}

            </div>
          </div>
          }
        </div>

        {(this.props.appointment.relatedCaseId
            && this.props.appointment.patientId
        ) ?
          <div className="block-action">
            {this.props.appointment.isArchived ?
              <div className="case-status-informations">{I18n.t('appointment.details.caseArchived')}</div>
              :
              this.props.appointment.takenInChargeName
                  ?
                <div className="case-status-informations">{I18n.t('appointment.details.caseTaken', { doctor: this.props.appointment.takenInChargeName
                })}</div>
                :
                this.props.appointment.assignedToName ?
                  <div className="case-status-informations">
                    <div>{I18n.t('appointment.details.caseAssigned')}</div>
                    <div>{this.props.appointment.assignedToName}</div>
                  </div>
                  :
                  <button
                    className="btn btn-primary "
                    type="submit"
                    onClick={() => this.openRelatedCase(this.props.appointment.relatedCaseId, this.props.appointment.patientId)}
                  >
                    <i className="button-icon fa fa-plus" />
                    {I18n.t('button.OPEN_CASE')}
                  </button>
            }


          </div> :
          <div className="block-action">
            <button
              className="btn btn-primary "
              type="submit"
              onClick={() => this.deleteAvailability(this.props.appointment.id)}
            >
              <i className="button-icon fa fa-trash" />
              {I18n.t('button.DELETE')}
            </button>
          </div>
        }
      </div>
    );
  }
}

export default withRouter(connect()(EventDetail));
