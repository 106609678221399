// @flow

import { RestClientAuthenticated } from '../network';

const operationsApi = {
  loadFixedValues: () =>
    new RestClientAuthenticated('/dashboard/infos', 'GET').execute(),
  loadCaseValues: (dateFrom: string, dateTo: string) =>
    new RestClientAuthenticated(`/dashboard/case?dateFrom=${encodeURIComponent(dateFrom)}&dateTo=${encodeURIComponent(dateTo)}`, 'GET').execute(),
};

export default operationsApi;
