// @flow

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase as CoreozInput } from '../../lib/coreoz-form-base/index';

import ModalEprm from '../../components/modal/ModalEprm';
import caseApi from '../../network/api/caseApi';
import { CASE_PROCESS_PAGE_ROUTE, DASHBOARD_PAGE_ROUTE, TELECONSULTATION_PAGE_ROUTE } from '../../routes';
import { notifyError } from '../../network/notification';
import { currentSessionService } from '../../services/sessionServiceInstance';
import { createSearchFromParams } from '../../services/queryParamsService';
import { Roles } from '../../enum';
import caseReportApi from '../../network/api/caseReportApi';
import CoreozPhoneInput from '../../components/CoreozPhoneInput';

type Props = {
  dispatch: Function,
  queryParams: Object,
  modal: boolean,
  toggle: Function,
  familyDoctor: Object,
  caseId: String,
  history: Function,
  document: Object,
  caseInfos: Object,
};

class ModalTransfer extends React.Component<Props> {
  state = {
    phoneValid: true,
  }
  // UPDATE the case assigment to doctor team
  transferToADoctor = (value: Object) => {
    const familyDoctorValue = {
      ...value,
      isFamilyDocConsent: this.isTrue(value.isFamilyDocConsent),
    };

    if (value) {
      if (currentSessionService().currentUser().userRole === Roles.DOCTOR) {
        caseApi
          .updateFamilyDoctorAndTransfer(
            this.props.queryParams.caseId,
            currentSessionService().currentUser().userId, familyDoctorValue,
          )
          .then(() => {
            if (this.props.document.id !== undefined) {
              caseReportApi.deleteCaseReport(this.props.document.id)
                .catch(this.props.dispatch(notifyError));
            }
          })
          .then(() => {
            this.props.history
              .push(CASE_PROCESS_PAGE_ROUTE
                + TELECONSULTATION_PAGE_ROUTE
                + createSearchFromParams(this.props.queryParams));
          })
          .catch(this.props.dispatch(notifyError));
      } else {
        caseApi
          .updateFamilyDoctorAndTransfer(this.props.caseId, currentSessionService().currentUser().userId, familyDoctorValue)
          .then(() => {
            if (this.props.document.id) {
              caseReportApi.deleteCaseReport(this.props.document.id)
                .catch(this.props.dispatch(notifyError));
            }
            this.props.history.push(DASHBOARD_PAGE_ROUTE);
          })
          .catch(this.props.dispatch(notifyError));
      }
    }
  };

  isTrue = (value) => {
    if (typeof (value) === 'string') {
      value = value.trim().toLowerCase();
    }
    switch (value) {
      case true:
      case 'true':
        return true;
      default:
        return false;
    }
  };

  onSubmit = (values) => {
    this.transferToADoctor(values);
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('qualification.transfer')}
        modal={this.props.modal}
        className="modal-EPRM"
        size="lg"
      >
        <Form
          onSubmit={this.onSubmit}
          initialValues={{
            ...this.props.familyDoctor,
            isFamilyDocConsent: this.props.caseInfos && this.props.caseInfos.caseInfos.isFamilyDocConsent || false,
          }}
          render={({ handleSubmit, form, values }) => (
            <form
              className="transfer-to-doctor-form"
              onSubmit={handleSubmit}
            >
              <div className="modal-transfer-container">
                <div className="modal-transfer-title">
                  {I18n.t('qualification.modalTitle')}
                </div>
                <div className="label-container">
                  {I18n.t('qualification.modalText')}
                </div>
                <div className="radio-button-container">
                  <Field
                    className="radio-input"
                    component="input"
                    name="isFamilyDocConsent"
                    type="radio"
                    value="true"
                    format={value => String(value)}
                    parse={value => value === 'true'}
                    disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                  />
                  <span>{I18n.t('form.YES')}</span>
                  <Field
                    className="radio-input"
                    component="input"
                    name="isFamilyDocConsent"
                    type="radio"
                    value="false"
                    format={value => String(value)}
                    parse={value => value === 'true'}
                    disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                  />
                  <span>{I18n.t('form.NO')}</span>
                </div>
                {values.isFamilyDocConsent &&
                <Fragment>
                  <div className="label-container">
                    {I18n.t('qualification.modalDoctor')}
                  </div>
                  <div className="doctor-address-container">
                    <Field
                      label={I18n.t('administration.doctor.FIRSTNAME')}
                      component={CoreozInput}
                      name="firstName"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.doctor.LASTNAME')}
                      component={CoreozInput}
                      name="lastName"
                      required
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.doctor.STREET')}
                      component={CoreozInput}
                      name="address"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.doctor.POSTCODE')}
                      component={CoreozInput}
                      name="postcode"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.doctor.CITY')}
                      component={CoreozInput}
                      name="city"
                      required
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.doctor.COUNTRY')}
                      component={CoreozInput}
                      name="country"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <Field
                      label={I18n.t('administration.doctor.EMAIL')}
                      component={CoreozInput}
                      name="email"
                      type="email"
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                    />
                    <CoreozPhoneInput
                      value={values.phone}
                      name="phone"
                      onChange={newValue => form.change('phone', newValue)}
                      label={I18n.t('administration.doctor.PHONE')}
                      disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                      setPhoneIsValid={phoneValid => this.setState({ phoneValid })}
                    />
                  </div>
                </Fragment>
                }
              </div>
              <div className="modal-transfer-action">
                <Button
                  color="secondary"
                  onClick={this.props.toggle}
                >
                  <i className="button-icon fa fa-undo" />
                  {I18n.t('button.CANCEL')}
                </Button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                          values.isFamilyDocConsent === undefined
                          || values.isFamilyDocConsent && (!values.lastName || !values.city || !this.state.phoneValid)
                        }
                >
                  <i className="button-icon fa fa-arrow-circle-right" />
                  {I18n.t('button.CONTINUE_TRANSFER')}
                </button>
              </div>
            </form>
          )}
        />
      </ModalEprm>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(ModalTransfer));
