// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import MedicalResources from './MedicalResources';

type Props = {
  closeAllModal: Function,
  toggleMedicalResources: Function,
  toggleHospitalSearch: Function,
  medicalResources: boolean,
}

class MedicalResourcesButton extends React.Component<Props> {
  render() {
    return (
      <div className="contact-block">
        <div
          className="contact-mean ressources "
          onClick={() => {
            this.props.closeAllModal();
            this.props.toggleMedicalResources();
          }}
        >
          <i className="fa fa-briefcase-medical" />
          <div>{I18n.t('contact.MEDICAL_RESOURCES')}</div>
        </div>
        {this.props.medicalResources &&
        <MedicalResources
          toggleHospitalSearch={this.props.toggleHospitalSearch}
          closedSelf={() => {
            this.props.toggleMedicalResources();
          }}
        />
        }
      </div>
    );
  }
}

export default withRouter(MedicalResourcesButton);
