'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

require('./index.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function CoreozTooltip(_ref) {
  var message = _ref.message,
      _ref$className = _ref.className,
      className = _ref$className === undefined ? '' : _ref$className,
      theme = _ref.theme;

  return _react2.default.createElement(
    'div',
    { className: className + ' ' + theme + ' tip-container' },
    _react2.default.createElement('i', { className: 'fa fa-info-circle' }),
    _react2.default.createElement(
      'div',
      { className: 'tip' },
      message
    )
  );
}

exports.default = CoreozTooltip;