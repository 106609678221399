// @flow

import type { Sms, SmsListWithOptions } from '../../types/SmsTypes';
import { reduce } from '../../network/reduce';

export type SmsStateType = {
  smsList: SmsListWithOptions,
  searchResultsSms: Sms[],
}

const initialState: SmsStateType = {
  smsList: [],
  searchResultsSms: null,
};

export const SMS_LIST = 'SMS_LIST';
export const SEARCH_RESULTS_SMS = 'SEARCH_RESULTS_SMS';

const smsReducer = reduce(initialState, {
  [SMS_LIST]: 'smsList',
  [SEARCH_RESULTS_SMS]: 'searchResultsSms',
});

export default smsReducer;
