// @flow

import { I18n } from 'react-redux-i18n';
import { addNotification as notify } from 'reapop';
import toMessage from '../services/i18nService';
import { errorObject } from './handleError';
import { userDisconnected } from '../services/sessionService';

export function notifySuccess(reduxDispatch: Function, e: String, message: String) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: message || I18n.t('notify.success.MODIFICATION_SAVED'),
      status: 'success',
    }));
    return response;
  };
}
export function notifyRelatedCasePastDateError(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: I18n.t('notify.error.RELATED_CASE_CREATION'),
      status: 'error',
    }));
    return response;
  };
}


export function notifyAppointmentsCreated(reduxDispatch: Function, e: String, message: String) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: message || I18n.t('notify.success.APPOINTMENTS_CREATED'),
      status: 'success',
    }));
    return response;
  };
}

export function notifyAvailabilityDeleted(reduxDispatch: Function, e: String, message: String) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: message || I18n.t('notify.success.MODIFICATION_SAVED'),
      status: 'success',
    }));
    return response;
  };
}


export function notifyError(reduxDispatch: Function) {
  return (error: Function) => {
    let errorResponse = error;
    if (typeof error.then !== 'function') {
      console.log(error);
      errorResponse = Promise.resolve(errorObject);
    }

    return (errorResponse: Object).then((errorJson) => {
      reduxDispatch(notify({
        message: I18n.t(`wsError.${errorJson.errorCode}`, toMessage(errorJson)),
        status: 'error',
      }));
      if (errorJson.errorCode === 'AUTHENTICATION_EXPIRED') {
        userDisconnected('expired');
      }
    });
  };
}

export function notifyErrorRecording(reduxDispatch: Function) {
  return (error: Function) => {
    let errorResponse = error;
    if (typeof error.then !== 'function') {
      console.log(error);
      errorResponse = Promise.resolve(errorObject);
    }

    return (errorResponse: Object).then((errorJson) => {
      reduxDispatch(notify({
        message: I18n.t(`wsError.${errorJson.errorCode}`, toMessage(errorJson)),
        status: 'error',
      }));
      if (errorJson.errorCode === 'AUTHENTICATION_EXPIRED') {
        userDisconnected('expired');
      }
    });
  };
}

export function notifyReloadPage(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: I18n.t('wsError.RELOAD_PAGE'),
      status: 'error',
    }));
    return response;
  };
}

export function notifyLot4(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: 'Cette fonctionnalité sera réalisée dans le prochain lot',
      status: 'success',
    }));
    return response;
  };
}

export function notifyMandatoryField(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: I18n.t('notify.error.MANDATORY_FIELD'),
      status: 'error',
    }));
    return response;
  };
}

export function notifyDateError(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: 'Cette fonctionnalité sera réalisée dans le prochain lot',
      status: 'success',
    }));
    return response;
  };
}

export function notifyArchived(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: I18n.t('notify.success.ARCHIVED'),
      status: 'success',
    }));
    return response;
  };
}

export function notifyReOpened(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: I18n.t('notify.success.REOPENED'),
      status: 'success',
    }));
    return response;
  };
}

export function notifyCreated(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: I18n.t('notify.success.REOPENED'),
      status: 'success',
    }));
    return response;
  };
}
