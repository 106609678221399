'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

require('./index.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CoreozSelectAutocomplete = function (_React$PureComponent) {
  _inherits(CoreozSelectAutocomplete, _React$PureComponent);

  function CoreozSelectAutocomplete() {
    _classCallCheck(this, CoreozSelectAutocomplete);

    return _possibleConstructorReturn(this, (CoreozSelectAutocomplete.__proto__ || Object.getPrototypeOf(CoreozSelectAutocomplete)).apply(this, arguments));
  }

  _createClass(CoreozSelectAutocomplete, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      return _react2.default.createElement(
        'div',
        { className: this.props.theme + '-field-render' },
        _react2.default.createElement(_reactSelect2.default, _extends({}, this.props.input, {
          className: this.props.className || 'input',
          isDisabled: this.props.disabled,
          onChange: function onChange(e) {
            _this2.props.input.onChange(e);
            if (_this2.props.onChange) _this2.props.onChange(e.value);
          },
          onBlur: function onBlur() {
            return _this2.props.input.onBlur(_this2.props.input.value);
          },
          options: this.props.options
        })),
        _react2.default.createElement(
          'div',
          { className: this.props.theme + '-error-div' },
          this.props.meta.error && this.props.meta.touched && _react2.default.createElement(
            'span',
            null,
            this.props.meta.error
          )
        )
      );
    }
  }]);

  return CoreozSelectAutocomplete;
}(_react2.default.PureComponent);

exports.default = CoreozSelectAutocomplete;
;