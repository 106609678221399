// @flow
import React from 'react';

class Loader extends React.Component<Props, State> {
  render() {
    return (
      <div className={'lds-spinner' + (this.props.small ? ' small' : '') + (this.props.grey ? ' grey' : '')}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  }
}

export default Loader;
