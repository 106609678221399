
import { RestClientAuthenticated } from '../../network/network';
import { notifyError } from '../notification';
import {SearchCriteriaType} from "../../types/B-PartnerManagement";

const apiEndpoint = {
  url: '/advice-coding',
  search: '/search',
  getById: '/:id',
  saveById: '/:id',
};

const adviceCodingApi = {
  searchAdviceCodingList: (name: string) => new RestClientAuthenticated(`${apiEndpoint.url}/search?name=${name}`, 'GET').execute(),
  fetchAll: () => new RestClientAuthenticated(`${apiEndpoint.url}`, 'GET').execute(),
  fetchById: (id: string) => new RestClientAuthenticated(`${apiEndpoint.url}${apiEndpoint.saveById.replace(':id', id)}`, 'GET').execute(),
  update: (data: any, id: string) => new RestClientAuthenticated(`${apiEndpoint.url}${apiEndpoint.saveById.replace(':id', id)}`, 'PUT').jsonBody(data).execute(),
  save: (data: any) => new RestClientAuthenticated(`${apiEndpoint.url}`, 'POST').jsonBody(data).execute(),
  delete: (id: any) => new RestClientAuthenticated(`${apiEndpoint.url}${apiEndpoint.saveById.replace(':id', id)}`, 'DELETE').execute(),
};


export default adviceCodingApi;
