// @flow
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import React from 'react';
import moment, { now } from 'moment';
import ModalEprm from '../../../components/modal/ModalEprm';
import Filters from '../../appointment/components/Filters';
import appointmentApi from '../../../network/api/appointmentApi';
import { notifyError, notifyRelatedCasePastDateError, notifySuccess } from '../../../network/notification';
import CalendarContainer from '../../appointment/components/CalendarContainer';
import patientApi from '../../../network/api/patientApi';
import { CASE_PROCESS_PAGE_ROUTE, QUALIFICATION_PAGE_ROUTE, TELECONSULTATION_PAGE_ROUTE } from '../../../routes';
import { createSearchFromParams } from '../../../services/queryParamsService';
import Loader from '../../../components/Loader';
import { currentSessionService } from '../../../services/sessionServiceInstance';

type Props = {
  toggle: Function,
  visible: boolean,
  dispatch: Function,
  patientId: String,
  caseId: String,

};

type State = {
  steps: number,
  userAvailabilities: [],
  searchUserProfile: null,
  availabilityProfiles: [],
  patient: []
};

class CreateRelatedCaseModal extends React.Component<Props, State> {
  state = {
    steps: 1,
    appointmentCreated: null,
    loading: false,
  };

  componentDidMount() {
    appointmentApi.fetchAvailabilityProfiles()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((response) => {
        this.setState({ availabilityProfiles: response });
      })
      .catch(this.props.dispatch(notifyError));

    patientApi.fetchById(this.props.patientId)
      .then(response => response.json())
      .then(patient => this.setState({ patient }))
      .catch(this.props.dispatch(notifyError));
  }

  searchUserAvailabilities = (values) => {
    const { id } = values;
    this.setState({ loading: true });
    appointmentApi.searchAppointments(id)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((response) => {
        this.setState({
          userAvailabilities: response.map(e =>
            ({
              ...e,
              start: new Date(e.startPeriod),
              end: new Date(e.endPeriod),
            })).filter(availability => availability.isEmpty),
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(notifyError)(e);
      });
    this.setState({
      searchUserProfile: this.state.availabilityProfiles.filter(availabilityProfile => availabilityProfile.id === values.id)[0],
    });
  };

  createRealatedCase = () => {
    this.setState({ loading: true });
    appointmentApi.createRelatedCase(this.state.relatedCaseAppointment.appointmentId, this.props.caseId)
      .then(response => response.json())
      .then(appointmentCreated =>
        this.setState({
          appointmentCreated,
          steps: 3,
          loading: false,
        }))
      .then(this.props.dispatch(notifySuccess))
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(notifyError)(e);
      });
  }

  openDetails = (events) => {
    if (moment(events.start).isAfter(now())) {
      this.setState({
        steps: 2,
        relatedCaseAppointment: {
          appointmentId: events.id,
          startDate: events.startPeriod,
          endDate: events.endPeriod,
          slotDuration: events.duration,
        },
      });
    } else {
      this.props.dispatch(notifyRelatedCasePastDateError(this.props.dispatch));
    }
  };

  openRelatedCase = (caseId: string, patientId: string) => {
    const queryParams = {
      caseId,
      patientId,
    };
    if (!!caseId && !!patientId) {
      if (!(currentSessionService().currentUser().showIdentification && currentSessionService().currentUser().showQualification)) {
        this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
      } else {
        this.props.history.push(`${CASE_PROCESS_PAGE_ROUTE + QUALIFICATION_PAGE_ROUTE + createSearchFromParams(queryParams)}`);
      }
    }
  };

  closeModal = () => {
    this.setState({
      steps: 1,
      userAvailabilities: [],
      searchUserProfile: null,
    });
    this.props.toggle(false);
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('createRelatedCase.title')}
        modal={this.props.visible}
        toggle={this.props.toggle}
        className=""
        size="lg"
      >
        <div className="create-case-related-modal">
          {
            this.state.steps === 1 &&
            <div className="related-case-choose-carer">
              <span>{I18n.t('createRelatedCase.chooseUser')}</span>
              {this.state.availabilityProfiles &&
              <div className="related-case-filter">
                <Filters
                  search={this.searchUserAvailabilities}
                  availabilityProfiles={this.state.availabilityProfiles}
                  loading={this.state.loading}
                />
                {this.state.userAvailabilities && this.state.searchUserProfile &&
                <div className="related-case-calendar-part">
                  <span className="related-case-choose-slot">{I18n.t('createRelatedCase.chooseSlot')}</span>
                  <CalendarContainer events={this.state.userAvailabilities} openDetails={this.openDetails} />
                </div>
                }
              </div>
              }
              <div className="related-case-actions">
                <button
                  className="margin-auto btn btn-primary "
                  onClick={this.closeModal}
                >
                  <i className="button-icon fa fa-undo" />
                  {I18n.t('actions.CANCEL')}
                </button>
              </div>
            </div>
          }
          {
            this.state.steps === 2 &&
            <div>
              <div className="related-case-summurize">
                <div className="related-case-summurize-title">
                  {I18n.t('createRelatedCase.summarize.title')}
                </div>
                <div>
                  <div
                    className="related-case-summurize-content"
                  >{I18n.t('createRelatedCase.summarize.patient', { patient: `${this.state.patient.firstName } ${ this.state.patient.lastName}` })}
                  </div>
                  <div
                    className="related-case-summurize-content"
                  >{I18n.t('createRelatedCase.summarize.profile', { profile: this.state.searchUserProfile.name })}
                  </div>
                  <div className="related-case-summurize-content">{I18n.t('createRelatedCase.summarize.period', {
                    startDate: moment(this.state.relatedCaseAppointment.startDate).format(I18n.t('date.DATE_LONG')),
                    endDate: moment(this.state.relatedCaseAppointment.endDate).format(I18n.t('date.DATE_LONG')),
                  })}
                  </div>
                </div>
              </div>
              <div className="related-case-actions">
                <button
                  className="margin-auto btn btn-primary "
                  onClick={this.createRealatedCase}
                  disabled={this.state.loading}
                >
                  {
                    this.state.loading ?
                      <Loader small />
                      :
                      <span> <i className="button-icon fa fa-plus" />
                        {I18n.t('actions.CREATE_APPOINTEMENT')}
                      </span>
                  }
                </button>
                <button
                  className="margin-auto btn btn-primary "
                  onClick={this.closeModal}
                >
                  <i className="button-icon fa fa-undo" />
                  {I18n.t('actions.CANCEL')}
                </button>
              </div>
            </div>
          } {
          this.state.steps === 3 &&
          <div>
            <div>
              <div>{I18n.t('createRelatedCase.redirectionChoice')}</div>
              <div className="related-case-actions">
                <button
                  className="margin-auto btn btn-primary "
                  onClick={() => this.openRelatedCase(this.state.appointmentCreated.relatedCaseId, this.state.appointmentCreated.patientId)}
                >
                  <i className="button-icon fa fa-plus" />
                  {I18n.t('actions.REDIRECT_TO_RELATED_CASE')}
                </button>
                <button
                  className="margin-auto btn btn-primary "
                  onClick={this.closeModal}
                >
                  <i className="button-icon fa fa-undo" />
                  {I18n.t('actions.CONTINUE_TELECONSULT')}
                </button>
              </div>
            </div>
          </div>
        }
        </div>
      </ModalEprm>
    );
  }
}

export default withRouter(connect()(CreateRelatedCaseModal));
