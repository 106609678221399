// @flow
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase, CoreozSelectBase as CoreozSelect } from '../../lib/coreoz-form-base/index';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Tile from '../../components/Tile';
import userApi from '../../network/api/userApi';
import { notifyError } from '../../network/notification';
import { Roles } from '../../enum';
import { USER_MANAGEMENT_PAGE_ROUTE } from '../../routes';
import Loader from '../../components/Loader';

type Props = {
  dispatch: Function,
  history: Function,
}
type State = {
  lastSearchObject: Object,
  users: Array<Object>,
  loading: boolean,
  numberResultsShown: number,
}

class UserManagement extends React.Component<Props, State> {

  constructor(props) {
    super(props);


    this.state = {
      users: null,
      lastSearchObject: null,
      loading: false,
      numberResultsShown: 10,
    };
  }

  onSubmit = (values: any) => {
    this.setState({
      loading: true,
      users: null,
      lastSearchObject: values,
    });
    this.search(values);
  };

  search = (values) => {
    userApi.search(values)
      .then(resp => resp.json())
      .then(users => this.setState({ users, loading: false, numberResultsShown: 10 }))
      .catch((e) => {
        this.setState({ loading: false });
        notifyError(this.props.dispatch)(e);
      });
  };

  showMoreResults = () => this.setState({ numberResultsShown: this.state.numberResultsShown + 10 });

  displayCriteria = () => {
    let criteria = [];
    if (this.state.lastSearchObject.firstName) {
      criteria.push(`${I18n.t('userSearch.FIRST_NAME')} ${this.state.lastSearchObject.firstName} `);
    }
    if (this.state.lastSearchObject.lastName) {
      criteria.push(`${I18n.t('userSearch.LAST_NAME')} ${this.state.lastSearchObject.lastName} `);
    }
    if (this.state.lastSearchObject.role) {
      criteria.push(`${I18n.t('userSearch.TYPE')} ${I18n.t(`role.${this.state.lastSearchObject.role} `)}`);
    }
    if (this.state.lastSearchObject.username) {
      criteria.push(`${I18n.t('userSearch.USERNAME')} ${this.state.lastSearchObject.username} `);
    }
    if (this.state.lastSearchObject.email) {
      criteria.push(`${I18n.t('userSearch.EMAIL')} ${this.state.lastSearchObject.email} `);
    }
    return criteria.toString();
  };

  getRoleIcon = (role: string) => {
    if (role === Roles.NURSE) {
      return 'fa fa-syringe';
    } else if (role === Roles.DOCTOR) {
      return 'fa fa-stethoscope';
    }
    return '';
  };

  componentDidMount() {
    if (this.props.location.state !== null) {
      this.setState({
        loading: true,
        lastSearchObject: this.props.location.state,
      });
      this.search(this.props.location.state);
    }
  }

  render() {
    return (
      <div className="user-search-container">
        <div className="title-container">
          <div className="title">{I18n.t('userSearch.TITLE')}</div>
        </div>
        <div className="search-bar">
          <Form
            onSubmit={this.onSubmit}
            initialValues={this.props.location.state}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="criteria">
                  <div className="criteria-column">
                    <Field
                      name="firstName"
                      component={CoreozInputBase}
                      placeholder={I18n.t('userSearch.FIRST_NAME')}
                    />
                    <Field
                      name="username"
                      component={CoreozInputBase}
                      placeholder={I18n.t('userSearch.USERNAME')}
                    />
                  </div>
                  <div className="criteria-column">
                    <Field
                      name="lastName"
                      component={CoreozInputBase}
                      placeholder={I18n.t('userSearch.LAST_NAME')}
                    />


                    <Field
                      name="email"
                      component={CoreozInputBase}
                      placeholder={I18n.t('userSearch.EMAIL')}
                    />
                  </div>
                  <div className="criteria-column">
                    <Field
                      name="role"
                      component={CoreozSelect}
                      placeholder={I18n.t('userSearch.TYPE')}
                      list={Object.keys(Roles).map(role => ({ id: role, label: I18n.t(`role.${role}`) }))}
                      disabledPlaceholder={false}
                    />
                    <div className="box-btn">
                      <Button
                        type="submit"
                        color="primary"
                        className="validation-button button-width-150"
                      >
                        <i className="button-icon fa fa-search" />
                        {I18n.t('button.SEARCH')}
                      </Button>
                    </div>

                  </div>
                </div>

              </form>

            )}
          />
        </div>
        <div className="search-result">
          <div className="result-title">
            <div className="title">{I18n.t('userSearch.SEARCH_RESULT')}</div>

            <Button
              color="primary"
              type="button"
              className="new-user-button button-width-150"
              onClick={() => this.props.history.push(`${USER_MANAGEMENT_PAGE_ROUTE}/create`, this.state.lastSearchObject)}
            >
              <i className="button-icon fas fa-plus" />
              {I18n.t('actions.NEW')}
            </Button>


          </div>
          {this.state.users &&
            <div className="result-list">
              <div className="criteria">
                <div>{I18n.t('userSearch.USERS_FOUND', { number: this.state.users.length })}</div>
                <div>{this.displayCriteria()}</div>
              </div>
              {this.state.users &&
                this.state.users.slice(0, this.state.numberResultsShown).map(userFound =>
                (<Tile
                  className="tile-user"
                  key={userFound.id}
                >
                  <div className="name">
                    {userFound.firstName} {userFound.lastName}
                  </div>
                  <div className="role">
                    <i className={this.getRoleIcon(userFound.role)} />
                    {userFound.role}
                  </div>
                  <div className="open-user">
                    <Button
                      color="primary"
                      onClick={() => this.props.history.push(`${USER_MANAGEMENT_PAGE_ROUTE}/edit/${userFound.id}`, this.state.lastSearchObject)}
                    >
                      <i className="button-icon fa fa-folder-open" />{I18n.t('userSearch.OPEN_USER')}
                    </Button>
                  </div>
                </Tile>))
              }
              {
                this.state.users && this.state.numberResultsShown < this.state.users.length &&
                <div className="center-child">
                  <Button
                    type="submit"
                    color="primary"
                    className="validation-button"
                    onClick={this.showMoreResults}
                  >
                    <i className="button-icon fas fa-eye" />
                    {I18n.t('button.NEXT')}
                  </Button>
                </div>
              }
            </div>
          }
          {
            !this.state.loading && !this.state.users &&
            <div className="no-result">{I18n.t('userSearch.NO_RESULT')}</div>
          }

          {this.state.loading && <div className="center-child search-loader"><Loader /></div>}
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(UserManagement));
