// @ flow

import React from 'react';
import { B_PARTNER_MANAGEMENT_PAGE_ROUTE, B_PARTNER_CREATE_PAGE_ROUTE, B_PARTNER_EDIT_PAGE_ROUTE } from '../../routes';
import BPartnerManagement from './bPartnerManagement'
import bPartnerRequest from './bPartnerRequest';
import PrivateRoute from '../../components/PrivateRoute';
import { BPartnerContextProvider } from '../../stores/b-partner-management-context'

export default function BPartnerManagementRoutes() {
    return (
        <BPartnerContextProvider>
            <PrivateRoute exact path={B_PARTNER_MANAGEMENT_PAGE_ROUTE} component={BPartnerManagement} />
            <PrivateRoute exact path={B_PARTNER_CREATE_PAGE_ROUTE} component={bPartnerRequest} />
            <PrivateRoute exact path={B_PARTNER_EDIT_PAGE_ROUTE} component={bPartnerRequest} />
        </BPartnerContextProvider>
    );
}