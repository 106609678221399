// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import Col from 'reactstrap/es/Col';
import { I18n } from 'react-redux-i18n';
import MedicalResourceShort from './MedicalResourceShort';
import { Button, ModalHeader } from 'reactstrap';
import { MedicalRessourcesLinks } from '../../../enum';
import resourcesApi from '../../../network/api/resourcesApi';
import { notifyError } from '../../../network/notification';
import { connect } from 'react-redux';
import { currentSessionService } from '../../../services/sessionServiceInstance';

type Props = {
  dispatch: Function;
  closedSelf: Function;
  toggleHospitalSearch: Function;
};

class MedicalResources extends React.Component<Props, State> {
  state = {
    linksMedication: null,
    linksMedical: null,
    linksVarious: null,
    linksTravelers: null,
  };

  componentDidMount() {
    resourcesApi.getResources()
      .then(response => response.json())
      .then(resources => {
        const linksMedication = resources.filter((link) => link.section === MedicalRessourcesLinks.MEDICATION);
        const linksMedical = resources.filter(link => link.section === MedicalRessourcesLinks.MEDICAL_SEARCH);
        const linksVarious = resources.filter(link => link.section === MedicalRessourcesLinks.VARIOUS);
        const linksTravelers = resources.filter(link => link.section === MedicalRessourcesLinks.TRAVELERS);
        this.setState({ linksMedication, linksMedical, linksVarious, linksTravelers })
      })
      .catch(this.props.dispatch(notifyError));

  }

  render() {
    if (this.state.linksMedication == null) {
      return null
    }
    return (
      <div className={`medical-resources ${currentSessionService().currentUser().showQualification ? '' : 'spanish'}`}>
        <ModalHeader toggle={this.props.closedSelf} className=" medical-resources-header">
          <h5>
            <i className={`fa fa-briefcase-medical`} />
            {I18n.t('contact.medicalResources.TITLE')}
          </h5>
        </ModalHeader>
        <div className="medical-resources-body">
          <Col>
            <MedicalResourceShort
              icone="fa-stethoscope"
              label={I18n.t('contact.medicalResources.MEDICAL_SEARCH')}
              links={this.state.linksMedical}
            />
            <MedicalResourceShort
              icone="fa-notes-medical"
              label={I18n.t('contact.medicalResources.VARIOUS')}
              links={this.state.linksVarious}
            />
          </Col>
          <Col>
            <MedicalResourceShort
              icone="fa-plane"
              label={I18n.t('contact.medicalResources.TRAVELLERS')}
              links={this.state.linksTravelers}
            />
            <MedicalResourceShort
              icone="fa-pills"
              label={I18n.t('contact.medicalResources.MEDICATION')}
              links={this.state.linksMedication}
            />
            <Button
              type="button"
              className="btn btn-default"
              onClick={()=> this.props.toggleHospitalSearch()}
              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
            >
              {I18n.t('contact.medicalResources.SEARCH_HOSPITAL')}
            </Button>
          </Col>
        </div>
        <div className="arrow-right" />

      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(MedicalResources));
