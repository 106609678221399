// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { Button } from 'reactstrap';
import { CoreozDatePickerBase as CoreozDatePicker, CoreozInputBase as CoreozInput } from '../../../lib/coreoz-form-base/index';
import Tile from '../../../components/Tile';
import { dateValidator, required } from '../../../validator';
import Loader from '../../../components/Loader';
import moment from 'moment';

type Patient = {
  firstName: string,
  lastName: string,
  birthDate: string,
  policy: string,
}

type Identification = {
  patient: Patient,
  lastName: string,
  birthdate: string,
  policy: string,
}

type Props = {
  identification: Identification,
  submitFunction: Function,
  loading: boolean,
  scrollBehaviour?: 'auto' | 'smooth' | 'inherit' | 'initial',
}

type State = { initialValues: Object }

class EligibilityFail extends React.Component<Props, State> {

  state = {
    initialValues: {
      ...this.props.identification,
      patient: this.props.identification.patient && {
        ...this.props.identification.patient,
        birthDate:
          this.props.identification.patient.birthDate ? moment(this.props.identification.patient.birthDate) : null
      }
    },
  };
  app: HTMLElement;

  componentDidMount() {
    this.app = document.querySelector('#main-content');
  }

  handleSubmit = (data) => {
    this.props.submitFunction(data);
    this.scrollToTop();
  };

  scrollToTop = () => {
    this.app.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  render() {
    return (
      <div>
        <Tile className="tile-eligibility eligibility-not-found">
          <i className="fa fa-user-times" />
          <div className="eligibility-result">
            {I18n.t('processCase.eligibility.result.NOT_EXIST_AND_NOT_ELIGIBLE')}
            <div className="previous-information">
              {this.props.identification.patient.firstName &&
              <div>
                <span
                  className="previous-information-title">{I18n.t('form.FIRST_NAME')}:</span> {this.props.identification.patient.firstName}
              </div>
              }
              {this.props.identification.patient.lastName &&
              <div>
                <span
                  className="previous-information-title">{I18n.t('form.NAME')}:</span> {this.props.identification.patient.lastName}
              </div>
              }
              {!!this.props.identification.patient.birthDate &&
              <div>
                <span
                  className="previous-information-title">{I18n.t('form.DATE_OF_BIRTH')}:</span> {moment(this.props.identification.patient.birthDate).format(I18n.t('date.DATE_FORMAT'))}
              </div>
              }
              {this.props.identification.patient.policy &&
              <div>
                <span
                  className="previous-information-title">{I18n.t('form.POLICY_NUMBER')}:</span> {this.props.identification.patient.policy}
              </div>
              }
            </div>
          </div>
        </Tile>
        <div className="explanation-phrase">{I18n.t('processCase.eligibility.EXPLANATION_COLLECT_INFO')}</div>
        <Form
          onSubmit={this.handleSubmit}
          initialValues={this.state.initialValues}
          render={({ handleSubmit, values, invalid, form }) => (
            <form
              className="identification-form"
              onSubmit={handleSubmit}
            >
              <div>
                <div className="form-question">{I18n.t('processCase.eligibility.question.CHECK_SPELLING')}
                </div>
                <div className="radios-button-line">
                  <label>
                    <Field
                      className="radio-input"
                      component="input"
                      name="spelling"
                      type="radio"
                      value="true"
                      format={value => String(value)}
                      parse={value => value === 'true'}
                    />
                    <div className="radio-label">{I18n.t('form.YES')}</div>
                  </label>
                  <label>
                    <Field
                      className="radio-input"
                      component="input"
                      name="spelling"
                      type="radio"
                      value="false"
                      format={value => String(value)}
                      parse={value => value === 'true'}
                    />
                    <div className="radio-label">{I18n.t('form.NO')}</div>
                  </label>
                </div>
                {values.spelling != null ?
                  (!values.spelling ?
                      <div id="info-patient">
                        <div className="form-question">{I18n.t('processCase.eligibility.question.CHANGE_INFORMATION')}
                        </div>
                        <div className="form-indication">{I18n.t('form.STAR_USE')}</div>
                        <Field
                          component={CoreozInput}
                          className="form-input"
                          name="patient.firstName"
                          validate={required}
                          label={I18n.t('form.FIRST_NAME')}
                        />
                        <Field
                          component={CoreozInput}
                          className="form-input"
                          name="patient.lastName"
                          validate={required}
                          label={I18n.t('form.NAME')}
                        />
                        <div className="double-input">
                          <div className="date-of-birth form-input">
                            <Field
                              component={CoreozDatePicker}
                              name="patient.birthDate"
                              label={I18n.t('form.DATE_OF_BIRTH')}
                              validate={(e) => dateValidator(e, I18n.t('date.DATE_FORMAT'))}
                              dateFormat={I18n.t('date.DATE_FORMAT')}
                              placeholder={I18n.t('form.placeholder')}
                              onlyDate
                            />
                          </div>
                        </div>
                        <div className="form-action">
                          <Button
                            color="primary"
                            type="submit"
                            disabled={invalid}
                          >
                            {
                              this.props.loading ?
                                <Loader small />
                                :
                                <span> <i className="button-icon fa fa-search" />{I18n.t('button.SEARCH')}</span>
                            }
                          </Button>
                        </div>
                      </div>
                      :
                      <div>
                        <div
                          className="form-question">{I18n.t('processCase.eligibility.question.IS_POLICY_HOLDER')}</div>
                        <div className="radios-button-line">
                          <label>
                            <Field
                              className="radio-input"
                              render={(props) => <input {...props} {...props.input} onChange={(e) => {
                                if (e.target.value === 'true') {
                                  form.change('policyHolder.policyHolderFirstName', null);
                                  form.change('policyHolder.policyHolderLastName', null);
                                  form.change('policyHolder.policy', null);
                                }
                                props.input.onChange(e);
                              }} />}
                              name="policyHolderCheck"
                              type="radio"
                              value="true"
                              format={value => String(value)}
                              parse={value => value === 'true'}
                            />
                            <div className="radio-label">{I18n.t('form.YES')}</div>
                          </label>
                          <label>
                            <Field
                              className="radio-input"
                              render={(props) => <input {...props} {...props.input} onChange={(e) => {
                                if (e.target.value === 'false') {
                                  form.change('policyHolder.policy', null);
                                }
                                props.input.onChange(e);
                              }} />}
                              name="policyHolderCheck"
                              type="radio"
                              value="false"
                              format={value => String(value)}
                              parse={value => value === 'true'}
                            />
                            <div className="radio-label">{I18n.t('form.NO')}</div>
                          </label>
                        </div>
                        {values.policyHolderCheck != null &&
                        (!values.policyHolderCheck ?
                            <div>
                              <div className="form-indication">{I18n.t('form.STAR_USE')}</div>
                              <Field
                                component={CoreozInput}
                                className="form-input"
                                name="policyHolder.policyHolderFirstName"
                                validate={required}
                                label={I18n.t('form.FIRST_NAME')}
                              />
                              <Field
                                component={CoreozInput}
                                className="form-input"
                                name="policyHolder.policyHolderLastName"
                                validate={required}
                                label={I18n.t('form.NAME')}
                              />
                              <Field
                                component={CoreozInput}
                                name="policyHolder.policy"
                                className="form-input"
                                label={I18n.t('form.POLICY_NUMBER')}
                              />
                              <div className="form-action">
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={invalid}
                                >
                                  {
                                    this.props.loading ?
                                      <Loader small />
                                      :
                                      <span> <i className="button-icon fa fa-search" />{I18n.t('button.SEARCH')}</span>
                                  }
                                </Button>
                              </div>
                            </div>
                            :
                            <div id="info-patient">
                              <div className="policy">
                                <Field
                                  component={CoreozInput}
                                  name="policyHolder.policy"
                                  className="form-input"
                                  initialValue=""
                                  label={I18n.t('form.POLICY_NUMBER')}
                                />
                              </div>
                              <div className="form-action">
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={invalid}
                                >
                                  {
                                    this.props.loading ?
                                      <Loader small />
                                      :
                                      <span> <i className="button-icon fa fa-search" />{I18n.t('button.SEARCH')}</span>
                                  }
                                </Button>
                              </div>
                            </div>

                        )}
                      </div>
                  )
                  : null
                }
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default EligibilityFail;
