// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import ModalEprm from '../../../components/modal/ModalEprm';
import EventDetail from './EventDetail';

type Props = {
  date: Object,
  events: Object,
  toggle: Function,
  visible: boolean,
  onCloseModal: Function,
};

function DetailsAppointmentModal(props: Props) {
  return (
    <ModalEprm
      title={I18n.t('appointment.details.TITLE', { date: moment(props.date).format(I18n.t('appointment.details.formatDate')) })}
      modal={props.visible}
      toggle={props.toggle}
      className=""
      size="lg"
    >

      {
        props.events && props.events.map(event => (<div key={event.id}><EventDetail appointment={event} onCloseModal={props.onCloseModal} />
        </div>))
      }

    </ModalEprm>
  );
}

export default withRouter(DetailsAppointmentModal);
