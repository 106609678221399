import React, { createContext, useState } from 'react';
import { SearchCriteriaType } from '../types/BrandManagement';

const BrandContext = createContext({
  searchCriteriaContext: null,
  updateCriteriaSearchContext: (searchCriteria: SearchCriteriaTyp) => { },
});

export function BrandContextProvider(props) {
  const [useSearchCriteria, setUseSearchCriteria] = useState(null);

  function updateSearchCriteriaHandler(searchCriteria: SearchCriteriaType) {
    setUseSearchCriteria(searchCriteria);
  }

  const context = {
    searchCriteriaContext: useSearchCriteria,
    updateSearchCriteriaContext: updateSearchCriteriaHandler,
  };

  return (
    <BrandContext.Provider value={context}>
      {props.children}
    </BrandContext.Provider>
  );
}

export default BrandContext;
