// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Form } from 'react-final-form';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import { CASE_PROCESS_PAGE_ROUTE, QUALIFICATION_PAGE_ROUTE } from '../../routes';
import { createSearchFromParams } from '../../services/queryParamsService';
import MedicalProfile from '../../components/medicalProfile/MedicalProfile';
import { currentSessionService } from '../../services/sessionServiceInstance';
import CoreozDrawer from '../../components/CoreozDrawer';
import ListLastDocuments from '../../components/medicalProfile/components/ListLastDocuments';
import TeleAdviceReportPreview from './TeleAdviceReportPreview';
import type { Case } from '../../types/CasesTypes';
import caseApi from '../../network/api/caseApi';
import { notifyError } from '../../network/notification';
import patientApi from '../../network/api/patientApi';
import caseReportApi from '../../network/api/caseReportApi';
import processCaseApi from '../../network/api/processCaseApi';
import hospitalApi from '../../network/api/hospitalApi';
import Loader from '../Loader';

type Props = {
  queryParams: Object,
  history: Function,
};

type State = {
  sideDrawerOpen: boolean,
  patient: Object,
  medReport: Object,
  lastDocuments: Object,
  actualCase: Case,
  loading: Boolean,
};

class TeleAdviceReport extends React.Component<Props, State> {
  state = {
    sideDrawerOpen: false,
    patient: null,
    medReport: null,
    actualCase: null,
    lastDocuments: {},
    hospitals: [],
    loading: false,
  };

  goBack = () => {
    this.props.history
      .push({
        pathname: CASE_PROCESS_PAGE_ROUTE + QUALIFICATION_PAGE_ROUTE,
        search: createSearchFromParams(this.props.queryParams),
        state: 'medical',
      });
  };

  drawerToggleClickHandle = () => {
    this.setState({
      sideDrawerOpen: !this.state.sideDrawerOpen,
    });
  };

  fetchDocuments = (patientId: string, protocolNumber: string) => {
    patientApi
      .getLastDocuments(patientId, protocolNumber)
      .then(response => response.json())
      .then(lastDocuments => this.setState({ lastDocuments }))
      .catch(this.props.dispatch(notifyError));
  };

  getPatient = (id: string) => new Promise((resolve) => {
    patientApi
      .fetchById(id)
      .then(response => response.json())
      .then((patient) => {
        this.setState({ patient });
        resolve(patient);
      })
      .catch(e => console.log('Erreur apppel API', e));
  });

  componentDidMount = () => {
    caseApi.fetchDetailById(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then(resJson => this.setState({ actualCase: resJson }))
      .catch(notifyError(this.props.dispatch));

    caseReportApi.getAdviceReportContent(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then(medReport => this.setState({ medReport }))
      .catch(notifyError(this.props.dispatch));

    hospitalApi.getList(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(data => this.setState({ hospitals: data }))
      .catch(notifyError(this.props.dispatch));

    this.getPatient(this.props.queryParams.patientId)
      .then(patient => this.fetchDocuments(patient.id, patient.protocolNumber))
      .catch(e => console.log('Erreur apppel API', e));
  };

  onSubmit = (values: Object) => {
    this.setState({ loading: true });
    caseApi.checkTakenInCharge(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : null))
      .then((changeInfos) => {
        if (changeInfos && !changeInfos.change) {
          const htmlContent = renderToString(<TeleAdviceReportPreview
            previewMode
            nurse
            case={this.state.actualCase}
            patient={this.state.patient}
            age={this.getAge(this.state.patient.birthDate)}
            userName={this.state.medReport.doctorName}
            technicalIssue={this.state.actualCase.isTechnicalIssue}
            technicalIssueText={this.state.actualCase.technicalIssueText}
            place={this.state.medReport.place}
            organizationMail={this.state.medReport.businessUnitEmail}
            actor={this.state.medReport.businessUnitName}
            legalEntityAddress={this.state.medReport.businessUnitAddress}
            legalEntity={this.state.medReport.legalEntityName}
            logo={this.state.medReport.logo}
            brand={this.state.medReport.brandName}
            familyDoctorConsent={this.state.actualCase.isFamilyDocConsent}
            rpps={this.state.medReport.doctorRpps}
            speciality={this.state.medReport.doctorSpeciality}
            showLogoInDocument={currentSessionService().currentUser().showLogoInDocument}
            signature={this.state.medReport.doctorSignature}
            adviceNote={values.adviceNote}
            getPatientPresentation={this.getPatientPresentation()}
            reasonForTheCall={values.reasonForTheCall}
            hospitals={this.state.hospitals}
          />);
          caseApi.updateCaseField(
            this.props.queryParams.caseId,
            this.props.queryParams.patientId,
            'adviceNote',
            {
              oldValue: this.state.actualCase.adviceNote,
              newValue: values.adviceNote,
            },
          );
          caseApi.updateCaseField(
            this.props.queryParams.caseId,
            this.props.queryParams.patientId,
            'reasonForTheCall',
            {
              oldValue: this.state.actualCase.reasonForTheCall,
              newValue: values.reasonForTheCall,
            },
          );

          if (this.props.queryParams.documentId) {
            caseReportApi.updateAdviceReport(this.props.queryParams.documentId, {
              caseId: this.props.queryParams.caseId,
              content: htmlContent,
            })
              .then(() => {
                this.setState({ loading: false });
                this.goBack();
              })
              .catch(notifyError(this.props.dispatch));
          } else {
            caseReportApi.saveAdviceReport({
              caseId: this.props.queryParams.caseId,
              content: htmlContent,
            })
              .then(() => {
                this.setState({ loading: false });
                this.goBack();
              })
              .catch(notifyError(this.props.dispatch));
          }
        } else {
          this.setState({ loading: false });
          this.goBack();
        }
      })
      .catch(e => console.log('Erreur apppel API', e));
  };

  getPatientPresentation = () => {
    let phrase = '';
    const birthday = moment(this.state.patient.birthDate, 'YYYY-MM-DD');
    if (this.state.patient.genderCode === '1') {
      phrase = I18n.t('editDocument.DESCRIPTION_CALL_1_M', { name: this.state.actualCase.patientName.toUpperCase() });
    } else {
      phrase = I18n.t('editDocument.DESCRIPTION_CALL_1_W', { name: this.state.actualCase.patientName.toUpperCase() });
    }
    if (-birthday.diff(Date.now(), 'years') > 2) {
      phrase += -birthday.diff(Date.now(), 'years') + I18n.t('editDocument.YEARS');
    } else {
      phrase += -birthday.diff(Date.now(), 'months') + I18n.t('editDocument.MONTHS');
    }
    if (-birthday.diff(Date.now(), 'years') < 15 && this.state.patient.weight !== undefined) {
      phrase += I18n.t('editDocument.DESCRIPTION_CALL_2', { weight: this.state.patient.weight });
    }
    phrase += I18n.t('editDocument.DESCRIPTION_CALL_3', {
      callDay: moment(this.state.actualCase.dateCreation, 'YYYY-MM-DD').format(I18n.t('date.DATE_FORMAT')),
      callHour: moment(this.state.actualCase.dateCreation).format(I18n.t('date.DATE_HOUR_SHORT')),
    });
    return phrase;
  };

  getAge = (birthday: string) => {
    const birthdate = new Date(birthday);
    const cur = new Date();
    const diff = cur - birthdate; // This is the difference in milliseconds
    return Math.floor(diff / 31557600000);
  };

  render() {
    return (
      <div>
        <div className="document-edit">
          <div className="document-edit-header">
            <div className="edit-backTo" onClick={this.goBack}>
              <i className="fa fa-arrow-left" />
              <span>{I18n.t('editDocument.BACK')}</span>
            </div>
            <div className="edit-title">
              <div>{I18n.t('editDocument.TITLE_ADVICE_REPORT')}</div>
            </div>
          </div>
          <CoreozDrawer show={this.state.sideDrawerOpen} side={false}>
            <ListLastDocuments
              patientId={this.props.queryParams.patientId}
              goBack={this.drawerToggleClickHandle}
              lastDocuments={this.state.lastDocuments.lastDocumentsBean}
              nbTeleconsult={this.state.lastDocuments.nbTeleconsult}
              nbTeleadvice={this.state.lastDocuments.nbTeleadvice}
              nbMaxOfTeleconsult={this.state.lastDocuments.nbOfMaxTeleconsult}
            />
          </CoreozDrawer>
          <div className="edit-container">
            {this.state.patient &&
            <MedicalProfile
              drawerToggleClickHandle={this.drawerToggleClickHandle}
              patient={this.state.patient}
              documents={this.state.lastDocuments}
              queryParams={this.props.queryParams}
            />}
            {this.state.patient && this.state.actualCase && this.state.medReport &&
            <div className="edit-content">
              <Form
                onSubmit={this.onSubmit}
                initialValues={{
                  adviceNote: this.state.actualCase.adviceNote,
                  reasonForTheCall: this.state.actualCase.reasonForTheCall,
                }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="edit-header">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={this.state.loading}
                      >
                        {
                          this.state.loading ?
                            <Loader small />
                            :
                            <div>
                              <i className="button-icon fa fa-save" />
                              <span>{I18n.t('button.SAVE')}</span>
                            </div>
                        }
                      </Button>
                      <Button
                        color="secondary"
                        onClick={this.goBack}
                      >
                        <i className="button-icon fa fa-undo" />
                        <span>{I18n.t('button.CANCEL')}</span>
                      </Button>
                    </div>
                    <TeleAdviceReportPreview
                      nurse
                      case={this.state.actualCase}
                      patient={this.state.patient}
                      age={this.getAge(this.state.patient.birthDate)}
                      userName={this.state.medReport.doctorName || ''}
                      technicalIssue={this.state.actualCase.isTechnicalIssue}
                      technicalIssueText={this.state.actualCase.technicalIssueText}
                      place={this.state.medReport.place || ''}
                      organizationMail={this.state.medReport.businessUnitEmail || ''}
                      actor={this.state.medReport.businessUnitName || ''}
                      legalEntityAddress={this.state.medReport.businessUnitAddress || ''}
                      legalEntity={this.state.medReport.legalEntityName || ''}
                      logo={this.state.medReport.logo || ''}
                      brand={this.state.medReport.brandName || ''}
                      familyDoctorConsent={this.state.actualCase.isFamilyDocConsent || ''}
                      rpps={this.state.medReport.doctorRpps || ''}
                      speciality={this.state.medReport.doctorSpeciality || ''}
                      getPatientPresentation={this.getPatientPresentation()}
                      showLogoInDocument={currentSessionService().currentUser().showLogoInDocument}
                      signature={this.state.medReport.doctorSignature || ''}
                      hospitals={this.state.hospitals}
                    />
                    <div className="edit-footer">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={this.state.loading}
                      >
                        {
                          this.state.loading ?
                            <Loader small />
                            :
                            <div>
                              <i className="button-icon fa fa-save" />
                              <span>{I18n.t('button.SAVE')}</span>
                            </div>
                        }
                      </Button>
                      <Button
                        color="secondary"
                        onClick={this.goBack}
                      >
                        <i className="button-icon fa fa-undo" />
                        <span>{I18n.t('button.CANCEL')}</span>
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(TeleAdviceReport));
