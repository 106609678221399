import React from "react";
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

interface ProfileInfoProps{
    fullName: string;
    birthDate: string;
}

interface ModifyInfoProps{
    updateBy: string;
    updateDate: string;
}

interface ProfileHeaderProps{
    onPreviousPageFunc() :void;
    profileInfo: ProfileInfoProps;
    modifyInfo: ModifyInfoProps;
    title: string;
}

const ProfileHeader = (props :ProfileHeaderProps) => {

    const profileInfo: ProfileInfoProps = props.profileInfo;
    const modifyInfo: ModifyInfoProps = props.modifyInfo;

    return (
        <div className="content-header" >
          <div className="profile-header" >
            <div className="profile-back-to-list" onClick={() => {
                props.onPreviousPageFunc();
            }} >
                <span>
                    <i className="fa fa-arrow-left"/>
                    {I18n.t('userDetails.BACK_TO_LIST')}
                </span>
            </div>
            
            <div className="profile-info">
                {profileInfo && (
                    <>
                        <span>{props.title && props.title}</span>
                        <span className="detail">{profileInfo.fullName && profileInfo.fullName}</span>
                        <span className="detail">{profileInfo.birthDate && moment(profileInfo.birthDate).format('DD/MM/YYYY')}</span>
                    </>
                )}
            </div>

            <div className="profile-last-update-date">
                {modifyInfo && (
                    <>
                        <span>{ modifyInfo.updateBy && I18n.t('common.LAST_EDIT_BY', {fullName: modifyInfo.updateBy})}</span>
                        <span>{ modifyInfo.updateDate && moment(modifyInfo.updateDate).format('DD/MM/YYYY HH:mm')}</span>
                    </>
                )}
            </div>
          </div>  
        </div>
    );
};


export default ProfileHeader;