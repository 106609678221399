// @flow
import * as React from 'react';
import { Button } from 'reactstrap';
import { I18n } from 'react-redux-i18n';

type Props = {
  pageSize: number,
  dataArray: Object[],
  render: Function,
}

export default function PaginatedList({ pageSize, dataArray, render }: Props) {
  const [elementsShown, setNumberElementsShown] = React.useState(pageSize);
  return (
    <React.Fragment>
      {dataArray &&
      dataArray.slice(0, elementsShown).map(data => render(data))}
      {
        dataArray && elementsShown < dataArray.length &&
        <div className="center-child">
          <Button
            type="submit"
            color="primary"
            className="validation-button"
            onClick={() => setNumberElementsShown(elementsShown + pageSize)}
          >
            <i className="button-icon fas fa-eye" />
            {I18n.t('button.NEXT')}
          </Button>
        </div>
      }
    </React.Fragment>
  );
}
