// @flow

import type { Mail } from '../../types/MailTypes';
import { reduce } from '../../network/reduce';

export type MailStateType = {
  mails: Mails,
  searchResultsMail: Mail[],
}

const initialState: MailStateType = {
  mails: [],
  searchResultsMail: null,
};

export const MAILS = 'MAILS';
export const SEARCH_RESULTS_MAIL = 'SEARCH_RESULTS_MAIL';

const mailReducer = reduce(initialState, {
  [MAILS]: 'mails',
  [SEARCH_RESULTS_MAIL]: 'searchResultsMail',
});

export default mailReducer;
