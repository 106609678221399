// @ flow

import React from 'react';
import { CASE_DETAILS_ROUTE, CASE_PAGE_ROUTE } from '../../routes';
import CaseSearch from './CaseSearch';
import CaseDetails from './CaseDetails';
import PrivateRoute from '../../components/PrivateRoute';

function CasesRoutes() {
  return (
    <div>
      <PrivateRoute exact path={CASE_PAGE_ROUTE} component={CaseSearch} />
      <PrivateRoute path={CASE_DETAILS_ROUTE} component={CaseDetails} />
    </div>
  );
}

export default CasesRoutes;
