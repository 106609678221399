// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ResizableTextarea from '../../ResizableTextarea';

type Props = {
  initialToggle: Object,
  name: String,
}

type State = {
  initialValues: Object,
}

class Category extends React.Component<Props, State> {

  state = {
    toggle: this.props.initialToggle,
  };

  toggle = () => {
    this.setState({ toggle: !this.state.toggle })
  };

  render() {
    return (
      <div className="patient-medical-infos">
        <div onClick={this.toggle}>
          <h2>
            <i className={` fas ${this.state.toggle ? 'fa-chevron-down' : 'fa-chevron-right'}`} />
            {I18n.t(`medicalProfile.informationsMedicalProfile.${this.props.name}`)}
          </h2>
        </div>
        <div className="field">
          {this.state.toggle &&
          <Field
            className="form-control"
            component={ResizableTextarea}
            minrows={1}
            maxrows={5}
            name={this.props.name}
            type="text"
            disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
          />
          }
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(Category));
