import React, { createContext, useState } from 'react';
import { SearchCriteriaType } from '../types/logoManagement';

const LogoContext = createContext({
  searchCriteriaContext: null,
  updateCriteriaSearchContext: (searchCriteria: SearchCriteriaType) => { },
});

export function LogoContextProvider(props) {
  const [useSearchCriteria, setUseSearchCriteria] = useState(null);

  function updateSearchCriteriaHandler(searchCriteria: SearchCriteriaType) {
    setUseSearchCriteria(searchCriteria);
  }

  const context = {
    searchCriteriaContext: useSearchCriteria,
    updateSearchCriteriaContext: updateSearchCriteriaHandler,
  };

  return (
    <LogoContext.Provider value={context}>
      {props.children}
    </LogoContext.Provider>
  );
}

export default LogoContext;
