const es = {
  brand: 'ePRM',
  AUTHENTIFICATION_EXPIRED: 'Su sesión ha expirado. Por favor, identifíquese de nuevo',
  aside: {
    nav: {
      WORKPLACE: 'ESPACIO DE TRABAJO',
      ADMIN: ' ADMINISTRACIÓN',
      SHORTCUT: 'RECURSOS',
      DATABASE: 'BASE DE DATOS',
      HOME: 'Acogida',
      USERS: 'Usuarios',
      ROLES: 'Roles',
      LOG: 'Log API',
      HISTORY: 'Historial',
      SYSTEM: 'Sistema',
      SCHEDULED: 'Tareas planeadas',
      LOGS: 'Registros',
      DASHBOARD: 'Panel de consultas',
      APPOINTMENTS: 'Citas previas',
      PATIENTS: 'Pacientes',
      CASES: 'Consultas',
      AVAILABILITIES: 'Agenda',
      EXPORTS: 'Reportes',
      OPERATIONS: 'Operaciones',
      USER_MANAGEMENTS: 'Gestión usuarios',
      ADVICE_CODING: 'Codificación de tele-consejo',
      PATHOLOGY_CODING: 'Codificación Patológica',
      PHARMACIES: 'Farmacias',
      BUSINESS_UNIT: 'Unidad de Negocio',
      INTERNAL_BENFICIARY: 'Beneficiario interno',
      B_PARTNER: 'Socio B',
      LOGO: 'Logotipo',
      PROTOCOL: 'Protocolo',
      LEGAL_ENTITY: 'Entidad legal',
      BRAND: 'Marca',
    },
  },
  lastDocuments: {
    CONSULT: 'Tele-consultation',
    ADVICE: 'Tele-advice',
    formatDate: 'DD/MM/YYYY',
    DOWNLOAD: 'Descargar',
    caseNumber: 'Expediente n°',
    Prescription: 'Prescripción',
    Report: 'Informe',
    PatientDocument: 'Documento del paciente',
    SEND: 'Enlace seguro',
  },
  channel: {
    VIDEO: 'video',
    PHONE: 'teléfono',
    CHAT: 'chat',
  },
  role: {
    NURSE: 'Enfermera',
    DOCTOR: 'Médico',
    NURSE_LEVEL: 'Enfermera', // TODO : à virer !!
    DOCTOR_LEVEL: 'Médico', // TODO : à virer !!
    ADMIN: 'Administrador',
  },
  button: {
    TAKE_IN_CHARGE: 'TRATAR',
    TITLE_POPIN: 'CONSULTAR O TRATAR',
    OPEN_CASE: 'Abrir el caso',
    CONSULT_ONLY: 'Consultar',
    CANCEL: 'Volver',
    CONTINUE_TRANSFER: 'Continuar',
    ADD: 'Crear disponibilidades',
    END_CALL: 'Terminar la llamada',
    SEARCH: 'Buscar',
    ARCHIVE: 'Archivar',
    SELECT: 'Seleccionar',
    CONTINUE: 'Continuar',
    PREVIOUS: 'Anterior',
    BACK_TO_DASHBOARD: 'Volver al panel de consultas',
    SKIP_ELIGIBILITY: 'Saltar eligibilidad',
    CREATE: 'Crear nueva consulta',
    ABORT: 'Interrumpir',
    DOWNLOAD: 'Descargar',
    EDIT: ' Editar',
    SEND: 'Enviar',
    NEXT: 'Siguiente',
    SAVE: 'Guardar',
    NEW_PATIENT: 'Crear un nuevo paciente',
    VALIDATE: 'Validate',
    SEND_VIDEO_LINK_EMAIL: 'Enviar enlace de vídeo',
    START_VIDEO: 'Empezar el video',
    DELETE: 'Supresión',
    UPDATE: 'Actualización',
    RESET: 'Reiniciar',
    DETAILS: 'DETALLES',
    IMPORT: 'IMPORTAR',
    TEMPLATE: 'TEMPLATE',
  },
  communicationType: {
    video: 'Video',
    phone: 'Teléfono',
    chat: 'Chat',
  },
  activeStatus: {
    ACTIVE: 'Abierto',
    INACTIVE: 'Archivado',
    CLOSED: 'Cerrado',
  },
  loader: {
    text: 'Por favor, espere mientras carga la lista de carpetas...',
  },
  dashboard: {
    TITLE: 'Panel de consultas',
    AVERAGE_WAIT: 'Tiempo de espera :',
    MINUTES: 'min',
    OPENED_CASES: '%{count} Consulta abierta',
    OPENED_CASES_0: '%{count} Consulta abierta',
    OPENED_CASES_plural: '%{count} Consultas abiertas',
    NEW_CASE: 'Nueva consulta',
    filters: {
      TITLE: 'Filtros',
      VIEW: 'Vista',
      ASSIGNED: 'Asignado',
      CALL_TYPE: 'Canal',
      SPECIALITY: 'Especialidad',
      APPOINTMENT: 'Cita previa',
    },
    NOT_APPOINTMENT_TIME: 'Todavía no es hora',
    NO_SESSION: 'Sesión aún no iniciada.',
    WAITING_SINCE: 'Esperando desde',
    CASE_STATUS_NURSE_REQUIRED: 'Sin atender',
    CASE_STATUS_DOCTOR_REQUIRED: 'Sin atender',
    CASE_STATUS_ASSIGNED: 'Asignado',
    ASSIGNED_TO: 'Asignado a',
    CASE_STATUS_TAKEN: 'En curso',
    TAKEN_IN_CHARGE_BY: 'En curso por',
    modal: {
      TAKE_IN_CHARGE: '¿Quieres hacerte cargo de esta consulta? Aparecerás como médico trabajando en ella.',
    },
  },
  header: {
    PROFILE: 'Perfil',
    LOGOUT: 'Desconectar',
    availability: {
      AVAILABLE: 'Disponible',
      BUSY: 'Ocupado',
    },
  },
  caseStatus: {
    NOT_AVAILABLE: 'No disponible',
    WAITING: 'En espera',
    IN_PROGRESS: 'En curso',
    COMPLETED: 'Finalizado',
    DROPPED: 'Abandonado',
    NOT_STARTED: 'Sesión no iniciada',
  },
  filterBar: {
    CHAT: 'Chat',
    PHONE: 'Teléfono',
    VIDEO: 'Vídeo',
    ALL: 'Todo',
    LATER: 'Más tarde',
    TODAY: 'Hoy',
    DOCTOR_LEVEL: 'Médico',
    NURSE_LEVEL: 'Enfermera',
  },
  processCaseHeader: {
    CLOSE: 'Cerrar',
    TITLE: 'Ficha de Paciente',
    AUTO_SAVED: 'Guardado !',
    AUTO_SAVING: 'Guardando...',
    YEARS: 'años ',
    ASSIGNED: 'Asignado a',
    TAKEN_IN_CHARGE: 'En curso por',
    options: {
      ASSIGN: 'Asignar',
      TAKE: 'Tratar la consulta',
    },
    modal: {
      ASSIGNED_TITLE: 'Asignar un médico para esta consulta',
      ASSIGNED_TEXT: 'Puede seleccionar un médico para esta consulta',
      USERS: 'Médico',
      BUTTON: 'Asignar',
    },
    backToDashboard: {
      BACK_TITLE: 'Volver al panel de consultas',
      BACK_TEXT: '¿De verdad quieres volver al panel de consultas?',
    },
    steps: {
      IDENTIFICATION: 'Identificación',
      ELIGIBILITY: 'Eligibilidad',
      PATIENT_DETAILS: 'Perfil Paciente',
      CONSENTS: 'Consentimientos',
      QUALIFICATION: 'Cualificación',
      TELECONSULTATION: 'Teleconsulta',
      DOCUMENTS: 'Envío de documentos',
    },
  },
  processCase: {
    identification: {
      SELECT_BPARTNER: '1. Seleccione el tipo de socio B: ',
      SELECT_PROTOCOL: 'Seleccione el protocolo a utilizar para el caso:',
      CHOOSE_PATIENT: 'La elección del paciente',
      SELECT_PATIENT: 'Por favor, seleccione un paciente para el tratamiento del caso : ',
      NEW_POLICY: 'Add new policy',
      NEW_POLICY_QUESTION: 'This BIN not found under this patient:',
      NEW_PATIENT: '¿La información anterior no coincide con el paciente que está buscando? Puedes crear un nuevo paciente : ',
      PLACEHOLDER_BP: 'Seleccione el protocolo',
      EXPLANATION: 'Estás en contacto con un paciente. Antes de buscarlo en el sistema, haga las siguientes preguntas: ',
      TELECONSULT_QUESTION: '1. ¿Está llamando usted para una teleconsulta? ',
      BENEFICIARY_QUESTION: '2. ¿Está llamando usted por ti mismo? ',
      CALLER_INFORMATION_QUESTION: '3. ¿Me puede facilitar usted su identificación? ',
      BANK_CARD_HOLDER_QUESTION: '3. Are you the bank card holder?',
      VISA_CALLER_INFORMATION_QUESTION: '2. ¿Me puede facilitar usted su identificación? ',
      RELATIVE_INFORMATION_QUESTION: '3. ¿Me puede facilitar usted su identificación? ',
      ERROR_TITLE: 'Mensaje al paciente',
      ERROR_MESSAGE: 'El número que marcó usted, es el servicio de teleconsulta de Allianz. No podemos ayudarlo fuera de este ámbito',
      CONFIRM_ADDING_NEW_POLICY_MESSAGE: 'This patient already exists with another BIN number.',
      CONFIRM_ADDING_NEW_POLICY_MESSAGE2: 'Upon your validation, the BIN %{binNumber} will be recorded.',
      BANK_CARD_IS_INVALID: 'Your bank card does not include teleconsultation.',
    },
    eligibility: {
      result: {
        NOT_EXIST_AND_FOUND: 'Encontramos a una (o más) póliza de seguro elegible sólo en Aid@ y no en el ePRM. Por favor, elija una póliza de seguro para continuar y crear el paciente en el ePRM. ',
        EXIST_AND_ELIGIBLE: 'Se han encontrado uno (o más) pacientes posibles que coinciden con los datos introducidos. Elija una póliza para poder continuar.',
        EXIST_AND_NOT_ELIGIBLE: 'Lo sentimos, el servicio de teleconsulta no está incluido en el contrato identificado a continuación',
        EXIST_AND_OPEN_CASES: 'Se han encontrado consultas abiertas que coinciden con los datos introducidos. Elija un caso para continuar o cree una nueva consulta.',
        EXIST_AND_EXPIRED: 'El paciente ha sido encontrado, pero el contrato al que está vinculado ya no es válido.',
        NOT_EXIST_AND_NOT_ELIGIBLE: 'No se ha encontrado ningún paciente que se corresponda con los datos introducidos.',
        WARNING_TOO_LARGE: 'Su búsqueda no es lo suficientemente precisa. Tan sólo se tienen en cuenta los primeros datos',
        WARNING_TOO_MANY_PATIENTS: 'Su búsqueda no es lo suficientemente precisa. Tan sólo se recogen los 10 primeros resultados',
        WARNING_ONLY_PRM: 'Su búsqueda sólo se cierne al actual sistema. Si desea evaluar la validez del paciente, introduzca su nombre y apellido, así como fecha de nacimiento o número de póliza.',
        WARNING_GATEWAY_DOWN: 'Debido a un problema técnico, no se pudo verificar la elegibilidad del paciente. Es posible omitir la elegibilidad del paciente para continuar la consulta.',
      },
      EXPLANATION_COLLECT_INFO: 'Para comprobar la validez del paciente, introduzca la siguiente información :',
      question: {
        IS_POLICY_HOLDER: '2. ¿El paciente es el titular de la póliza?',
        CHECK_SPELLING: '1. Puedes confirmar que los datos introducidos están correctamente escritos ?  Están los datos escritos como en el contrato o póliza?',
        CHANGE_INFORMATION: '2. Vuelva a introudcir los datos del paciente y comience una nueva búsqueda.',
      },
      SKIP_ELIGIBILITY: '¿Está seguro de que quiere saltarse el proceso de validez del paciente',
      ELIGIBLE: 'Válido',
      NOT_ELIGIBLE: 'No válido',
      START_DATE: 'Comienza el ',
      EXPIRATION_DATE: 'Expira el ',
      ABORT_TEXT: '¿De verdad quieres volver al panel de consultas?',
    },
    UNDER_18: 'El paciente es menor de 18 años, por favor pida el registro de su familia.',
    FOREIGN_CALL: 'Cuidado: el paciente llama desde el extranjero',
  },
  form: {
    CM_CIC: 'Crédit mutuel/CIC',
    OTHER: 'Otro',
    NAME: 'Nombre',
    VISA: 'Visa',
    FIRST_NAME: 'Apellido',
    DATE_OF_BIRTH: 'Fecha de Nacimiento',
    POLICY_NUMBER: 'Número de póliza',
    BIN_NUMBER: 'First 9 digits of bank card number',
    PATIENT_NAME: 'Patient name',
    BANK_CARD_HOLDER_FIRST_NAME: 'Bank card holder first name',
    BANK_CARD_HOLDER_LAST_NAME: 'Bank card holder last name',
    YES: 'Si',
    NO: 'No',
    OR: 'O',
    STAR_USE: 'Puede usar un asterisco (*) para buscar un nombre compuesto',
    DATE_IN_FUTURE: 'La fecha indicada es una fecha futura',
    validator: {
      DATE_IN_FUTURE: 'La fecha indicada es una fecha futura',
      DATE_FORMAT: 'La fecha no está en el formato correcto',
      DATE_COMPARE: 'La fecha final es anterior a la fecha de inicio.',
      VALIDATE_EMAIL: 'Email no válido',
      VALIDATE_LIST: 'Listado no válido',
      VALIDATE_PHONE: 'Número de teléfono no válido',
      VALIDATE_PHONE_NO_0: 'El número no debe tener 0 justo después del código del país',
      REQUIRED: 'Requerido',
      TOO_SHORT: 'La búsqueda debe tener al menos tres caracteres de longitud',
      VALIDATE_IDENTICAL_VALUES: 'Por seguridad, utilice dos destinatarios distintos para el envío con enlace seguro y el OTP.',
      VALIDATE_JUST_ONE_VALUES: 'No utilice dos formatos iguales para la misma información.',
      VALIDATE_SLOT_DURATION: 'Un slot debe durar al menos: %{duration} min',
      START_DATE_BEFORE_NOW: 'La fecha de inicio no debe ser anterior a la de hoy',
      START_DATE_TO_EARLY: 'Las citas no pueden hacerse antes de las : %{startMaxDate}:00',
      END_DATE_TO_LATE: 'No se pueden hacer citas después de las : %{endMaxDate}:00',
      CONFIRM_PASSWORD: 'Las contraseñas \'Contraseña\' y \'Confirmación de contraseña\' deben ser idénticas',
      REQUIRED_DIGITS: 'Required %{digitValue} digits',
    },
    placeholder: 'DD/MM/AAAA',
  },
  login: {
    ePRM: 'ePRM Teleconsulta Allianz',
    IDENTIFIER: 'Usuario',
    PASSWORD: 'Contraseña',
    TITLE: 'Por favor, identifíquese',
    SIGNIN: 'Conectar',
    FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
    sms: {
      CODE: 'Por favor, introduzca el código recibido por SMS',
      OTP: 'Código',
      NEXT: 'Siguiente',
    },
    email: {
      CODE: 'Por favor, introduzca el código recibido por correo electrónico',
      OTP: 'Código',
      NEXT: 'Siguiente',
    },
    alternatives: {
      EMAIL: '¿Recibir el código por correo electrónico?',
      SMS: '¿Recibir el código por teléfono?',
    },
    enroll: {
      TITLE: 'Confía en tu navegador',
      INSTRUCTIONS: '¿Confías en tu navegador?',
      YES: 'Sí',
      NO: 'No',
    },
  },
  users: {
    TITLE: 'Usuarios',
    SEARCH_TITLE: 'Búsqueda de usuarios',
    LIST_TITLE: 'Listado de usuarios',
    USERNAME: 'Usuario',
    EMAIL: 'E-mail',
    FIRSTNAME: 'Nombre',
    LASTNAME: 'Apellido',
    PASSWORD: 'Contraseña',
    PASSWORD_CONFIRM: 'Confirmación de contraseña',
    BUSINESS_UNIT: 'Unidad de Negocio',
    ROLE: 'Rol',
    TYPE: 'Tipo',
    PHONE: 'Teléfono',
    TWO_FACTOR: 'Autenticación de doble factor',
    type: {
      NURSE: 'Enfermero/a',
      DOCTOR: 'Doctor/a',
    },
    CREATION_DATE: 'Fecha de alta',
    NEW_TITLE: 'Alta de un nuevo usuario',
    EDIT_TITLE: 'Editar usuario %{username}',
    DELETE_CONFIRM: 'Eliminar al usuario ?',
  },
  roles: {
    TITLE: 'Roles',
    LABEL: 'Nombre',
    PERMISSIONS: 'Permisos',
    ENABLED: 'Permisos activos',
    AVAILABLE: 'Permisos disponibles',
    LIST_TITLE: 'Roles',
    DETAIL_TITLE: 'Editar el rol %{label}',
    NEW_TITLE: 'Creación de un nuevo rol',
    DELETE_CONFIRM: 'Eliminar el rol ?',
  },
  history: {
    TITLE: 'Historial de las acciones',
    SEARCH_TITLE: 'Búsqueda de registros',
    LIST_TITLE: 'Lista de registros',
    USERNAME: 'ID de usuario',
    ID_ITEM: 'Identificador de objeto',
    TABLE: 'Tabla',
    MESSAGE: 'Mensaje',
    MODIFICATION_TYPE: {
      TITLE: 'Tipo de modificación',
      CREATE: 'Crear',
      UPDATE: 'Modificar',
      DELETE: 'Eliminar',
    },
    DATE: 'Fecha',
  },
  scheduled: {
    TITLE: 'Acciones planificadas',
    TASKNAME: 'Nombre acción',
    FREQUENCY: 'Frecuencia',
    EXECUTIONS: 'Número de ejecuciones',
    PREVIOUS_START: 'Inicio última acción',
    PREVIOUS_END: 'Fin última acción',
    NEVER_EXECUTED: 'Acción no ejecutada',
    DURATION: 'Tiempo de ejecución: %{duration} ms',
    NEXT: 'Próxima acción',
    STATUS: 'Estado',
    START: 'Iniciar acción',
    threads: {
      TITLE: 'Estadísticas  threads',
      ACTIVE: 'Número threads activos : ',
      INACTIVE: 'Número threads inactivos : ',
      MIN: 'Número máximo de threads : ',
      MAX: 'Número máximo de threads : ',
      LARGEST_POOL: 'El mayor número de threads en el pool : ',
    },
    DATE_FORMAT: 'DD-MM-YYYY kk:mm:ss',
  },
  logs: {
    TITLE: 'Gestión de nivel de trazas',
    ADD_TITLE: 'Añadir un marcador',
    SEARCH_TITLE: 'Buscar un marcador',
    DEFAULT_TITLE: 'Marcador de manera predeterminada',
    CHANGED_TITLE: 'Marcador modificadas',
    NAME: 'Nombre del marcador',
    LEVEL: 'Nivel',
    EMPTY: 'Sin Marcador',
    SELECT_TRACE: 'Rastros',
    SELECT_INACTIVE: 'Inactivo',
    SELECT_OFF: 'Apagado ',
    SELECT_INFO: 'Información',
    SELECT_DEBUG: 'Depurar',
    SELECT_ERROR: 'Error',
    SELECT_WARN: 'Advierte',
  },
  actions: {
    BACK: 'Atrás',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    DELETE: 'Eliminar',
    NEW: 'Nuevo',
    CONFIRM: 'Confirmar',
    SEARCH: 'Buscar',
    ADD: 'Añadir',
    CREATE_APPOINTEMENT: 'Crear la cita',
    REDIRECT_TO_RELATED_CASE: 'Ser redirigido al caso relacionado',
    CONTINUE_TELECONSULT: 'Continuar la teleconsulta actual',
  },
  message: {
    SUCCESS: 'Los cambios se han tenido en cuenta',
    ERROR: 'Ha habido un error y los cambios no se han guardado',
  },
  wsError: {
    INTERNAL_ERROR: 'Error de conexión',
    SERVER_CONNECTION: 'No puede conectarse al servidor HTTPS',
    AUTHENTICATION_EXPIRED: 'La sesión ha expirado',
    FIELD_REQUIRED: 'El campo \'%{field0}\' es necesario',
    COLOR_INVALID: 'El campo \'%{field0}\' debe ser de color hexadecimal : #ff00ff',
    EMAIL_INVALID: 'La dirección de correo electrónico \'%{field0}\' no es válida',
    USERNAME_ALREADY_EXISTS: 'Este nombre de usuario ya se está utilizando para otra cuenta. Por favor, utilice otro.',
    EMAIL_ALREADY_EXISTS: 'Esta dirección de correo electrónico ya se usa para otra cuenta. Por favor, utilice otro.',
    PASSWORDS_DIFFERENT: 'Las contraseñas \'%{field0}\' y \'%{field1}\' deben ser idénticas',
    WRONG_LOGIN_OR_PASSWORD: 'Nombre de usuario o contraseña inválidos',
    TOO_MANY_WRONG_ATTEMPS: 'Debido a una serie de intentos fallidos a la hora de identificarse, su cuenta estará bloqueada por %{field0} minutos. Por favor, vuelva a intentarlo más tarde',
    ROLE_LABEL_EXISTS: 'Ya existe un rol con esta etiqueta',
    PASSWORD_TOO_SHORT: 'La contraseña es demasiado corta, debe tener al menos %{field0} caracteres',
    MESSAGE: '%{field0}',
    OTP_INVALID: 'Código inválido',
    RESET_PASSWORD_UNAVAILABLE: 'Póngase en contacto con el administrador para restablecer su contraseña.',
    API_GATEWAY_ERROR: 'El PRM no es accesible actualmente',
    RATE_LIMITED: 'Espere antes de volver a intentarlo.',
    RELOAD_PAGE: 'Please reload page and retry.',
    BPARTNER_NOT_FOUND_FOR_BU: 'El BPartner para este caso no fue encontrado para su BU',
    BPARTNER_CODE_HAS_EXIST: 'B-Partner code has exist',
    RECORDING_ERROR: 'Ocurrió un error en el cierre del caso',
    CONTRACT_HAS_EXIST: 'Esta política ya existe',
    INVALID_BENEFICIARY: 'This beneficiary is invalid',
    DATA_NOT_FOUND: 'Not found patient or policy',
    EMAIL_TEMPLATE_NOT_FOUND: 'Found Patient and policy data but not found email template',
    SMS_TEMPLATE_NOT_FOUND: 'Found Patient and policy data but not found sms template',
    EMAIL_MISSING: 'Found Patient and policy data but thers is not patient\'s email',
    PHONE_NUMBER_MISSING: 'Found Patient and policy data but thers is not patient\'s phone number',
    DUPLICATED_PROTOCOL: 'Protocol number has exist',
    DUPLICATED_BUSINESS_UNIT: 'Business unit has exist',
    BUSINESS_UNIT_NOT_FOUND: 'Business unit not found',
    BRAND_NOT_FOUND: 'Brand unit not found',
    LOGO_NOT_FOUND: 'Logo unit not found',
    LEGAL_ENTITY_HAS_EXIST: 'Legal entity has exist',
    PROTOCOL_NOT_FOUND: 'Protocal not found!',
  },
  apiLog: {
    TITLE: 'Registros',
    request: 'solicitud',
    response: 'réplica',
    header: 'Cabecera',
    body: 'Body',
    apiFilter: 'Filtro por nombre de API:',
    statusCodeFilter: 'Filtrar por estado:',
    download: 'Descargar',
    noContent: 'No se han encontrado resultados',
    empty: 'Esta API no contiene ningún encabezado ni cuerpo para la solicitud y respuesta.. ',
    emptyBody: 'Body vacío',
    emptyHeader: 'Cabecera vide',
  },
  date: {
    long: 'DD MMMM YYYY HH:mm',
    DATE_START: 'Fecha inicio',
    DATE_END: 'Fecha fin',
    DATE_FORMAT: 'DD/MM/YYYY',
    DATE_FORMAT_ADMINISTRATION_FILE: 'YYYY-MM-DD',
    DATE_HOUR: 'HH:mm:ss',
    DATE_HOUR_SHORT: 'HH:mm',
    DATE_LONG: 'DD/MM/YYYY HH:mm:ss',
  },
  reactTable: {
    EMPTY: 'Vacía',
    PREVIOUS: 'Anterior',
    NEXT: 'Siguiente',
    OF: 'en',
    ROWS: 'filas',
  },
  businessUnit: {
    TITLE: 'UNIDAD DE NEGOCIO',
    TITLE_LIST: 'Lista de unidades de negocio',
    SEARCH_TITLE: 'Búsqueda Unidad de Negocio',
    SUBTITLE: 'Listado Unidades de Negocio',
    NEW_TITLE: 'Nueva Unidade de Negocio',
    EDIT_TITLE: 'Editar Unidad de Negocio',
    TITLE_CONFIGURATION: 'Configuración',
    TITLE_ADMIN: 'Administrador',
    TITLE_CREATE: 'BusinessUnit de creación',
    languages: {
      ENGLISH: 'EN',
      FRENCH: 'FR',
      SPANISH: 'ES',
      DEUTSH: 'DE',
      ITALIAN: 'IT',
    },
    modal: {
      title: 'Suprimir Unidad de Negocio',
      message: '¿Realmente quieres suprimir?',
    },
    label: {
      name: 'Nombre',
      prmId: 'Prm id',
      businessUnitName: 'Nombre de la unidad de negocio',
      canSendByFax: 'En la página "Envío de documentos", puede enviarlos por fax a una farmacia.',
      canAccessCmCic: 'Desde la página de identificación, el acceso a la elección de B-Partner Crédit Mutuel/CIC',
      canCreatePatientInIdentificationPage: 'Desde la página de identificación, la elección entre crear o recuperar un paciente existente',
      canResendMailVideo: 'Posibilidad de reenviar el correo electrónico que contiene el enlace de vídeo al paciente (Menú derecho en el proceso de creación de la carpeta)',
      showChat: 'Mostrar el chat en la barra de acción de las páginas "Calificación de llamadas" y "Tele-consulta".',
      showIdentification: 'Mostrar el paso de Identificación',
      showLogoInDocument: 'Mostrar el logo de bPartner en los documentos: teleconsulta, teleasesoramiento, prescripción',
      showNewCaseButtonInDashboard: 'Botón de nuevo caso en el tablero principal',
      showQualification: 'Mostrar el paso "Calificación de la llamada"',
      showTeleAdviceReport: 'Mostrar el resumen de la etapa de calificación en la página de teleconsulta',
      showViewFilterInDashboard: 'Ver filtro en el tablero principal',
      timezone: 'Zona horaria',
      prmIdCountry: 'Prm id country',
      doctorShouldCallback: 'En el tablero de mandos, una pantalla específica: 1. tiempo de espera y parpadeo para los casos sin cita previa y con teléfono para pedir al médico que llame al paciente // 2. para una carpeta de no-citas, debe parpadear hasta que un médico se haga cargo // 3. para una carpeta de citas, debe parpadear y tener en cuenta la nueva asignación',
      recordingsSentToAida: 'Enviando la hora de la cerradura a Aid@',
      reportAddressedToPatient: 'El informe de la teleconsulta está dirigido al paciente y no a un colega médico',
      sendNotificationWhenCaseCreated: "Envío de un SMS y un correo electrónico a todos los médicos conectados al crear (desde la PDA) un nuevo archivo de cita en el panel de control",
      sendSmsWhenCaseCreated: 'Envía un SMS a todos los usuarios conectados al crear una nueva carpeta en el tablero de mandos',
      sendNotificationPatientWaitingInitial: "Envío de un SMS y correo electrónico de cada caso a todos los médicos conectados cuando el paciente comienza a esperar vía chat o videollamada y por una cita (la hora de la cita debe haber pasado y el paciente está esperando en el tablero).",
      sendNotificationPatientWaitingGlobal: "Envío de un SMS y correo electrónico a todos los médicos conectados, cada 5 minutos, si hay al menos 1 caso en el que el paciente está esperando en chat o videollamada y para una cita (se debe exceder el tiempo de la cita y el paciente está esperando en el panel de control)",
      showAddressInDocument: 'Muestra la dirección de la organización en el informe de la teleconsulta',
      showEmailServiceMedicalReport: 'Muestra la dirección de correo electrónico de la organización en el informe de la teleconsulta',
      showHospitals: 'Muestra los resultados de la búsqueda de los hospitales seleccionados en el informe de teleconsulta',
      showNonMedicationNotesFirst: 'En el informe de la teleconsulta, al mostrar el contenido de las recetas, se muestran primero las notas adicionales y luego la lista de medicamentos.',
      useParentingForCodings: 'Mostrar el código padre del código de patología en la página de administración del código de patología, además del código de patología (comportamiento recuperado del PRM)',
      useOneProtocolAndPolicyForTreatingCases: 'Utilizar un solo protocolo y una sola política para el tratamiento de los casos',
      showOneConsentInConsentPage: 'Mostrar un consentimiento para comprobar en la página Consentimientos',
      makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories: 'No hacer campos obligatorios: Codificación de diagnóstico y codificación de ICD10',
      emailFooterTakeInLegalEntityObject: 'Mostrar el pie de página en el objeto "entidad legal" que está vinculado al protocolo',
      showAlertWhenNoMedicalReport: 'Mostrar un alerta cuando "Medical report" no esta crea',
      checkEligibilityByVisa: 'Habilitar el socio VISA al crear un nuevo caso',
      countryCode: 'Código de país',
      countryName: 'Nombre del país',
    },
    TABLE: {
      BUSINESS_UNIT_NAME: 'Nombre de la unidad de negocio',
      COUNTRY: 'Nombre del paìs',
      COUNTRY_CODE: 'Código de paìs',
      ZONE: 'Fuso horario',
      PAGINATION_INFO: 'PAGINATION_INFO: Mostrando %{start} a %{end} de %{total} entradas',
    },
    STATUS: {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
    },
    button: {
      ADD: 'Añadir',
      EXPORT: 'Exportar',
    },
    export: {
      businessUnit: 'Nombre de la unidad de negocio',
      countryCode: 'Código de país',
      country: 'Nombre del país',
      createdOn: 'Fecha de creación',
      timeZone: 'Zona horaria',
      canSendByFax: 'En la página "Envío de documentos", puede enviarlos por fax a una farmacia.',
      canAccessCmCic: 'Desde la página de identificación, el acceso a la elección de B-Partner Crédit Mutuel/CIC',
      canCreatePatientInIdentificationPage: 'Desde la página de identificación, la elección entre crear o recuperar un paciente existente',
      canResendMailVideo: 'Posibilidad de reenviar el correo electrónico que contiene el enlace de vídeo al paciente (Menú derecho en el proceso de creación de la carpeta)',
      showChat: 'Mostrar el chat en la barra de acción de las páginas "Calificación de llamadas" y "Tele-consulta".',
      showIdentification: 'Mostrar el paso de Identificación',
      showLogoInDocument: 'Mostrar el logo de bPartner en los documentos: teleconsulta, teleasesoramiento, prescripción',
      showNewCaseButtonInDashboard: 'Botón de nuevo caso en el tablero principal',
      showQualification: 'Mostrar el paso "Calificación de la llamada"',
      showTeleAdviceReport: 'Mostrar el resumen de la etapa de calificación en la página de teleconsulta',
      showViewFilterInDashboard: 'Ver filtro en el tablero principal',
      doctorShouldCallback: 'En el tablero de mandos, una pantalla específica: 1. tiempo de espera y parpadeo para los casos sin cita previa y con teléfono para pedir al médico que llame al paciente // 2. para una carpeta de no-citas, debe parpadear hasta que un médico se haga cargo // 3. para una carpeta de citas, debe parpadear y tener en cuenta la nueva asignación',
      recordingsSentToAida: 'Enviando la hora de la cerradura a Aid@',
      reportAddressedToPatient: 'El informe de la teleconsulta está dirigido al paciente y no a un colega médico',
      sendNotificationWhenCaseCreated: "Envío de un SMS y un correo electrónico a todos los médicos conectados al crear (desde la PDA) un nuevo archivo de cita en el panel de control",
      sendSmsWhenCaseCreated: 'Envía un SMS a todos los usuarios conectados al crear una nueva carpeta en el tablero de mandos',
      showAddressInDocument: 'Muestra la dirección de la organización en el informe de la teleconsulta',
      showEmailServiceMedicalReport: 'Muestra la dirección de correo electrónico de la organización en el informe de la teleconsulta',
      showHospitals: 'Muestra los resultados de la búsqueda de los hospitales seleccionados en el informe de teleconsulta',
      showNonMedicationNotesFirst: 'En el informe de la teleconsulta, al mostrar el contenido de las recetas, se muestran primero las notas adicionales y luego la lista de medicamentos.',
      useParentingForCodings: 'Mostrar el código padre del código de patología en la página de administración del código de patología, además del código de patología (comportamiento recuperado del PRM)',
      useOneProtocolAndPolicyForTreatingCases: 'Utilizar un solo protocolo y una sola política para el tratamiento de los casos',
      showOneConsentInConsentPage: 'Mostrar un consentimiento para comprobar en la página Consentimientos',
      makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories: 'No hacer campos obligatorios: Codificación de diagnóstico y codificación de ICD10',
      emailFooterTakeInLegalEntityObject: 'Mostrar el pie de página en el objeto "entidad legal" que está vinculado al protocolo',
      showAlertWhenNoMedicalReport: 'Mostrar un alerta cuando "Medical report" no esta crea',
    },
  },
  changeTaken: {
    title: 'Cambio en la gestión de la consulta',
    text: 'Cuidado, %{username} es la nueva persona a cargo de esta consulta.',
  },
  administration: {
    TITLE: 'Ficha del Paciente',
    MANDATORY: ': Campo obligatorio',
    PLEASE: 'Por favor, rellene los datos del paciente',
    identity: {
      TITLE: 'IDENTIDAD',
      CIVILITY: 'Estado Civil',
      M: 'Sr.', // Mister
      MME: 'Sra.', // Missus
      FIRSTNAME: 'Nombre',
      LASTNAME: 'Apellido',
      BIRTHNAME: 'Nombre de pila',
      EMAIL: 'Email',
      EMAIL_BOOLEAN: 'El paciente no tiene dirección de correo electrónico',
      RESEND_PDA_LINK: 'Reenviar PDA link',
      PHONE_NUMBER: 'Teléfono',
      birth: {
        TITLE: 'NACIMIENTO',
        BIRTHDATE: 'Fecha de nacimiento',
        COUNTRY: 'País de nacimiento',
        CITY: 'Ciudad de nacimiento',
      },
      address: {
        TITLE: 'DIRECCIÓN',
        STREET: 'Calle',
        POSTCODE: 'Código Postal',
        CITY: 'Ciudad',
        COUNTRY: 'País',
        DEPARTMENT: 'Departamento',
      },
    },
    lastDocuments: {
      history: 'Historial de consultas anteriores',
      total: 'Número total de',
      advice: 'TELECONSEILS',
      consult: 'TELECONSULTAS',
      showAll: 'VER TODO',
      onRollingMonths: 'Teleconsultas sobre este protocolo (durante 12 meses seguidos)',
      nbOfTeleconsultRollingMonthsForProtocol: 'Número de teleconsultas a lo largo de 12 meses rodando para el protocolo : ',
    },
    doctor: {
      TITLE: 'Médico de Familia',
      FIRSTNAME: 'Nombre',
      LASTNAME: 'Apellido',
      STREET: 'Calle',
      POSTCODE: 'Código Postal',
      CITY: 'Ciudad',
      EMAIL: 'Email',
      PHONE: 'Teléfono',
      COUNTRY: 'País',
    },
    insurance: {
      TITLE: 'Seguridad Social',
      SOCIAL_SECURITY_NUMBER: 'Número SS',
    },
    policy: {
      TITLE: 'PÓLIZA / CONTRATO',
      policyHolder: {
        TITLE: 'Titular de la póliza',
        FIRSTNAME: 'Nombre',
        LASTNAME: 'Apellido',
        CITY: 'Ciudad',
        PHONE_NUMBER: 'Teléfono',
      },
      beneficiary: {
        TITLE: 'Beneficiario',
        FIRSTNAME: 'Nombre',
        LASTNAME: 'Apellido',
        BIRTHDATE: 'Fecha de nacimiento',
      },
      policy: {
        TITLE: 'Póliza / Contrato',
        POLICY_NUMBER: 'Número de póliza',
        PROTOCOL_NUMBER: 'Numéro de protocolo (contrato) interno',
        POLICY_HOLDER: 'Titular',
        BENEFICIARY: 'Benéficiario',
        BRAND: 'Compañia / Marca',
        LEGAL_ENTITY: 'Entidad jurídica',
        PDA_ACCOUNT: 'Cuenta creada en PDA',
        YES: 'SI',
        NO: 'NO',
      },
    },
  },
  medicalProfile: {
    title: 'HISTORIAL MÉDICO',
    weight: 'P',
    height: 'A',
    BMI: 'IMC',
    weight_mesure: 'kg',
    height_mesure: 'cm',
    SEX: {
      1: 'Hombre',
      2: 'Mujer',
    },
    AGE: '%{age} años',
    total: 'Historial de consultas previas',
    teleAdvice: 'Teleconsejo',
    teleConsultation: 'Teleconsultas',
    show: 'Mostrar todo',
    lastModified: 'Último documento creado',
    informationsMedicalProfile: {
      medicalHistory: 'Antecedentes',
      treatment: 'Tratamientos en curso',
      allergies: 'Alergias conocidas',
      chronicalDisease: 'Enfermedades crónicas conocidas',
      vaccination: 'Vacuna(s)',
      lifeStyle: 'Estilo de vida',
    },
    lastConsultations: {
      history: 'Historial de consultas anteriores',
      total: 'Número total de',
      title: 'Número de casos creados',
      advice: 'Número de TELECONSEJOS',
      consult: 'Número de TELECONSULTAS',
      onRollingMonths: 'Número de teleconferencias en este protocolo (más de 12 meses de forma continuada)',
      showAll: 'Ver todo',
    },
    datesOfLastDocCreated: {
      title: 'Últimos documentos creados',
      advice: 'Último consejo médico:',
      consult: 'Último informe médico:',
    },
    lastDocumentsDrawer: {
      title: 'Últimas consultas',
      backTo: 'Volver a la lista',
    },
  },
  historyModifications: {
    title: 'Historial de modificaciones',
    newValue: 'Nuevo valor',
    oldValue: 'Valor antiguo',
    addressRecipient: 'Destinatario(s)',
    content: 'Contenidos',
    reasonForTheCall: 'Motivo de la llamada',
    countryLocalisation: 'País de llamada',
    reasonToCallNonMed: 'Motivo de la llamada no médica',
    caseQualification: 'Calificación de la convocatoria',
    adviceNote: 'Aviso telefónico',
    localAdviceCoding: 'Codificación local de la teleconferencia',
    teleconsultNotes: 'Notas de teleconsulta',
    doctorNotes: 'Notas del médico',
    icd10: 'Codificación ICD10',
    pathologyCoding: 'Codificación patológica',
    beneficiaryBirthday: 'Fecha de nacimiento del beneficiario',
    beneficiaryFirstName: 'Apellido  del beneficiario',
    beneficiaryLastName: 'Nombre del beneficiario',
    policyHolderLastName: 'Nombre del suscriptor',
    policyHolderFirstName: 'Apellido del suscriptor',
    medicalExam: 'Examen médico',
    medicalExamInstructions: 'Instrucciones para el examen médico',
    partnerName: 'Nombre de la pareja',
    protocolNumber: 'Número de protocolo',
    gender: {
      1: 'Hombre',
      2: 'Mujer',
    },
    by: ' por ',
    placeholder: 'Palabras clave',
    videoSessionId: 'Session vidéo id',
    prmIdAssignedTo: 'Asignado a   ',
    assignedTo: 'Asignado a   ',
    takenInCharge: 'Apoyado por',
    prmIdTakenInCharge: 'Apoyado por',
    technicalIssue: 'Problema técnico',
    awareEmergencyServices: 'Situación del paciente',
    orientationToEmergencyServices: 'Servicios de emergencia contactados',
    awareToContact: 'Se informa al paciente de que debe contratar urgencias',
    emergencyDetected: 'Emergencia detectada',
    emergencyAddressInfo: 'Emergencia - Más información',
    emergencyAddressZipCode: 'Emergencia - Código postal',
    emergencyAddressRegion: 'Emergency - Department',
    emergencyAddressCountry: 'Emergency - Country',
    emergencyAddressCity: 'Emergency - City',
    emergencyAddressLine: 'Emergency - Address',
    1: 'El paciente está en Francia',
    2: 'El paciente está en el extranjero',
    3: 'El paciente está en España',
    technicalIssueText: 'Texto del problema técnico',
    codeCommunicationType: 'Canal de communnication',
    benefit: 'Prestación no cubierta',
    general: 'Información genera',
    technical: 'Problema técnico',
    assistance: 'Asistencia sin contrato',
    complaint: 'Reclamación',
    prm: 'PRM',
    EMAIL: 'Email',
    SMS: 'SMS',
    SECURE_LINK: 'Enlace seguro',
    CODE: 'Código enviado a:',
    LINK: 'Enlace enviado a:',
    PHARMACY: 'Fax a una farmacia',
    pharmacyName: 'Nombre de la farmacia',
    pharmacyNumber: 'Número de farmacia',
    CREATE: 'Creación',
    UPDATE: 'Modificación',
    ARCHIVE: 'Archivo',
    REOPEN: 'Reapertura',
    medicalHistory: 'Antecedentes',
    DOCTOR_FIRSTNAME: 'Family Doctor firstName',
    DOCTOR_LASTNAME: 'Family Doctor lastName',
    DOCTOR_COUNTRY: 'Family Doctor country',
    DOCTOR_CITY: 'Family Doctor city',
    DOCTOR_POSTALCODE: 'Family Doctor postalCode',
    DOCTOR_ADDRESS: 'Family Doctor address',
    DOCTOR_EMAIL: 'Family Doctor email',
    DOCTOR_PHONE: 'Family Doctor phone',
    genderCode: 'Gendar',
    EMAIL_BOOLEAN: 'El paciente no tiene dirección de correo electrónico',
    firstName: 'Nombre',
    lastName: 'Apellido',
    birthName: 'Nombre de nacimiento',
    emailAddress1: 'email',
    policyNumber: 'Número de póliza',
    noAdressMail: 'No email',
    RESEND_PDA_LINK: 'PDA enlace reenviado',
    mobilePhone: 'Teléfono móvil',
    birthdate: 'Fecha de nacimiento',
    birthCountry: 'País',
    birthCity: 'Ciudad',
    address1Line1: 'Dirección',
    address1Postalcode: 'Código postal',
    address1City: 'Ciudad',
    address1Country: 'País',
    treatments: 'Tratamientos en curso',
    allergies: 'Alergia(s)',
    chronicalDisease: 'Chronical diseases',
    vaccinations: 'Cacuna(s)',
    lifeStyle: 'Estilo de vida',
    weight: 'Peso',
    height: 'Altura',
    socialSecurityNumber: 'Número de la seguridad social',
    DELETE: 'Borrar',
    true: 'Si',
    false: 'No',
    283030000: 'Tele conseil',
    283030001: 'Tele consultation',
    sharingConsents: 'Consentimiento para compartir datos',
    treatmentConsen: 'Consentimiento para el tratamiento de los datos',
    familyDocConsent: 'Autorización del médico tratante',
    statusCase: 'Estado del caso',
    ACTIVE: 'Abierto',
    INACTIVE: 'Archivo',
    CLOSED: 'Cerrado',
    MEDICINE: 'Medicación',
    ADDITIONAL_NOTES: 'Notas adicionales',
  },
  contact: {
    start: {
      TITLE: 'Arrancar',
      VIDEO: 'Video',
      CHAT: 'Chat',
      PHONE: 'Llamada',
    },
    video: {
      TITLE: 'Confirmar que el enlace de vídeo ha sido enviado',
      TITLE_2: 'Gestión de sesiones de vídeo',
      SEND_VIDEO_LINK_CONFIRMATION: '¿Desea enviar un correo electrónico con el enlace de vídeo para iniciar la videoconferencia?',
      SEND_VIDEO_LINK_CONFIRMATION_OR_OPEN_VIDEO: 'Puede enviar el enlace de vídeo al paciente para iniciar la teleconsulta o iniciar la sesión de vídeo.',
    },
    send: {
      TITLE: 'Enviar',
      EMAIL: 'Email',
      SMS: 'SMS',
      RDV: 'Hora',
    },
    phone: {
      TITLE: 'Número de devolución de llamada',
      NO_PHONE_NUMBER: 'No hay ningún número de teléfono por este paciente',
      CALLBACK_NUMBER: 'El número de teléfono de devolución de llamada del paciente es %{number}.',
    },
    sms: {
      TITLE: 'Contactar al paciente',
      SEND: 'enviar',
      RECIVER_NUMBER: 'El número de teléfono utilisa por contactar al paciente es el %{number}.',
    },
    hospitalSearch: {
      TITLE: 'Busca un hospital',
      SEARCH: 'Busca',
      LIST: 'Lista de resultados',
      address: 'Dirección :',
      contact: 'Contactar : ',
      departments: 'Departamentos : ',
      sendMail: 'Enviar por correo electrónico',
      sendSms: 'Enviar por sms',
      caseReport: 'Mostrar en los documentos',
      mailConfirmationTitle: 'Confirmación de envío por correo',
      mailConfirmationContent: 'Está a punto de enviar los datos por correo electrónico. Por favor confirme.',
      smsConfirmationTitle: 'Confirmación de envío por sms',
      smsConfirmationContent: 'Está a punto de enviar los datos por SMS. Por favor confirme.',
    },
    email: {
      TITLE: 'Contactar por email',
      SEND: 'enviar',
      OBJECT: 'Asunto',
      EMAIL_CC: 'CC',
      EMAIL: 'Destinatario',
    },
    MEDICAL_RESOURCES: 'Recursos Médicos',
    medicalResources: {
      TITLE: 'Recursos Médicos',
      MEDICAL_SEARCH: 'Investigación',
      VARIOUS: 'Varios',
      TRAVELLERS: 'Viaje',
      MEDICATION: 'Medicamentos',
      SEARCH_HOSPITAL: 'Hospitales',
    },
  },
  popinCreatePatient: {
    title: 'Información necesaria para crear al paciente en el sistema',
    subtitle: 'Para poder enviar los T&C al paciente necesitamos su correo electrónico y número de teléfono. La creación del paciente no será posible sin esta información.',
  },
  consent: {
    title: 'RECOPILACIÓN DE CONSENTIMIENTOS',
    intro: 'Obtenga los siguientes consentimientos del cliente',
    titleTreatingAndSharing: 'Acconsente alla condivisione e all\'elaborazione dei dati',
    textTreatingAndSharing_v1: 'Per fornirle il servizio di teleconsulto, abbiamo bisogno del suo consenso a trattare i dati personali relativi alla salute. Il titolare del trattamento è AWP P&C S.A.. Potrà trovare il testo completo dell’informativa privacy all’indirizzo',
    textTreatingAndSharing_v2: 'Il consenso potrà essere revocato dandone comunicazione alla società. Acconsente il trattamento dei dati relativi alla salute per la finalità di erogazione del servizio di Teleconsulto?',
    textTreatingAndSharing: '  termini e condizioni  ',
    titleTreating: 'Consentimiento para el tratamiento de datos ',
    textTreating: 'Autorizo a los profesionales de la salud a cargo de la prestación del servicio de teleconsulta a consultar la información médica recopilada sobre mi, así como la creada en el marco de los servicios de teleconsulta que se me presten.',
    titleSharing: 'Consentimiento para compartir datos ',
    textSharing: 'Autorizo a recopilar almacenar y tratar mis datos relativos a salud para la tramitación del servicio de Teleconsulta y los servicios asociados a los que se pueda acceder desde la Plataforma.',
    give: 'Doy mi consentimiento',
    giveNot: 'No doy mi consentimiento',
    create: 'Crear un caso',
    consentUnvalid: 'Mensaje al paciente',
    consentUnvalidText: 'Se requiere su consentimiento para realizar una teleconsulta. Al rechazar el consentimiento, no podemos continuar con esta llamada.',
    archived: 'Archivar el caso',
  },
  qualification: {
    reason: 'Motivo de la consulta - Síntomas',
    country: 'El paciente se encuentra en',
    category: 'Categoría',
    viewHistory: 'Ver histórico de cambios',
    technicalProblem: 'Notificar incidencia técnica',
    qualificationTitle: ' Cualificación de la consulta',
    textQualification: 'Cualificación de la consulta teniendo en cuenta la solicitud :',
    teleAdvice: 'TELECONSEJO',
    teleConsult: 'TELECONSULTA',
    teleAdviceNotes: 'Notas de Teleconsejo',
    teleAdviceCodification: 'Código de Teleconsejo',
    continue: 'Continuar',
    transfer: 'Envío informe',
    modalTitle: 'Antes de continuar verifica el consentimiento de envío del informe de teleconsulta a su médico de familia :',
    modalText: 'Autorizo el envío del informe de teleconsulta a mi médico de familia',
    yes: 'SI',
    no: 'NO',
    modalDoctor: 'Por favor, consulte la información del médico:',
    reasonToCallNonMed: {
      technicalIssue: 'Incidencia técnica',
      PRM: 'PRM - Teleconsulta',
      benefitNotCovered: 'Beneficio no cubierto',
      generalInformation: 'Información general',
      notContractedAssistance: 'Asistencia no contratada',
      complaint: 'Queja',
    },
    ADVICE_REPORT: 'Informe de teleconsejo',
    NO_REPORT: 'No hay informe de teleconsejo todavía.',
    ADD_REPORT: 'Añadir un informe de teleconsejo',
    NEW_DOCUMENT_FROM_PDA: 'El paciente sube un documento a la PDA',
    MISSING_DIAGNOSTIC: 'Por favor, seleccione un código de Teleconsejo para continuar.',
  },
  teleconsultation: {
    EMERGENCY: 'Emergencia',
    QUALIFICATION_REPORT: 'Informe de calificación',
    relatedCase: {
      titleParent: 'Informe de la casos de los padres',
      titleChildren: 'Informes de los archivos relacionados',
    },
    REASON_FOR_CALL: 'Motivo de la consulta - Síntomas',
    SYMPTOM_FLAG: 'Indicador de síntoma',
    TELEADVICE_NOTES: 'Tele notas de aviso',
    DOCUMENTS: 'Documentos',
    COUNTRY_OF_CALL: 'El paciente se encuentra en',
    TYPE_OF_CALL: 'Categoría',
    TELECONSULTATION_NOTES: 'Notas de teleconsulta',
    SHARED: 'Compartido con el paciente en el informe',
    PLACE_HOLDER: 'Indique aquí el resumen de la consulta',
    NOT_SHARED: 'No compartido con el paciente',
    DOCTOR_NOTES: 'Notas personales del médico',
    PRESCRIPTION: 'Prescripción',
    NO_PRESCRIPTION: 'No se ha creado ninguna prescripción todavía',
    ADD_PRESCRIPTION: 'Añadir',
    CODING: 'Codificación diagnóstica',
    ICD10_CODING: 'Codificación ICD10',
    ENTER_ICD10: 'Introduzca la primera letra para iniciar la búsqueda',
    LOCAL_CODING: 'Codificación local',
    FINAL_ORIENTATION: 'Resolución',
    FINAL_ORIENTATION_PHRASE: 'Al final de la entrevista, por favor, califique la llamada',
    END_CONSULTATION: 'Finalizar la consulta',
    PHYSICAL_EXAM: 'Recomendar visita física',
    PHYSICAL_EXAM_TEXT: 'Notas adicionales (serán compartidas con el paciente en el informe)',
    CREATE_RELATED_CASE: 'Crear cita',
    MEDICAL_REPORT: 'Informe de teleconsulta',
    NO_REPORT: 'No se ha creado ningún informe todavía',
    ADD_REPORT: 'Añadir',
    MISSING_ICD10: 'Por favor, seleccione un Codificación ICD10 para continuar',
    MISSING_DOCUMENT_FOR_FAMILY_DOCTOR: 'Por favor, añada un documento de informe para el médico de cabecera.',
    MISSING_MEDICAL_REPORT: 'Por favor, añada un informe de teleconsulta.',
  },
  medicine: {
    TITLE: 'Añadir',
    SEARCH: 'Buscar',
    HAVE_SELECTED: 'Has seleccionado : ',
    dosage: {
      DOSAGE: 'Dosis',
      QUANTITY_PLACEHOLDER: 'Cantidad',
      TIME_UNIT: 'por',
      TIME_PLACEHOLDER: 'Unidad',
      DURATION: 'duranté',
      DURATION_QUANTITY_PLACEHOLDER: 'Cantidad',
      DURATION_TIME_UNIT_PLACEHOLDER: 'Unidad',
    },
    time_unit: {
      DAY: 'dia',
      WEEK: 'semana',
      MONTH: 'mes',
    },
    repetition: {
      DAY: 'dia',
      NIGHT: 'noche',
      MEAL: 'comida',
      WEEK: 'semaina',
      MONTH: 'mes',
    },
    DETAIL_PLACEHOLDER: 'detalles',
    ADD: 'Añadir',
    instruction: {
      TAKE: 'Tome',
      DOSE: 'dosis',
      BY: 'por',
      DURING: 'durante',
    },
    noResult: 'No hay resultados para su búsqueda',
  },
  documentsToSend: {
    title: 'Documentos',
    phrase1: 'Para finalizar la consulta, indique un método de envío si el paciente lo requiere o bien archiva el caso directamente y los documentos se subirán a la cuenta del paciente en el PDA:',
    thirdConsent: 'Tenga cuidado, se ha dado el tercer consentimiento, debe enviar el informe médico al médico de familia.',
    buttonArchive: 'Archivar',
    textArchive: 'Al hacer click en "Archivar", los documentos anteriores se enviarán a la cuenta del paciente en el PDA.',
    sendBy: 'Enviar via : ',
    bySecureLink: 'Enlace seguro',
    byPharmacy: 'En la farmacia',
    print: 'Imprimir',
    send: 'Enviar',
    download: 'Descargar',
    creationDate: 'Fecha creación prescripción',
    prescription: {
      title: 'Prescripción',
      subtitle: 'Prescripción %{count}',
    },
    medicalReport: {
      title: 'Informe de teleconsulta',
      subtitle: 'Informe de teleconsulta %{count}',
    },
    patientDocument: {
      title: 'Documentos del paciente',
      subtitle: 'Documentos del paciente %{count}',
    },
    sendToPharmacy: {
      listPharmacieTitle: 'Lista de farmacias correspondientes a la búsqueda:',
      title: 'Envío a farmacia',
      pharmacyResearch: 'pharmacia',
      subtitle: 'Antes de continuar, comprobar el consentimiento correspondiente :',
      authorize: 'Autorizo',
      phraseEmail: ' 1. Por favor, compruebe y rellene el email del paciente',
      phrasePhone: ' 1. Por favor, compruebe y rellene el número de paciente:',
      warningOnModify: 'Atención, cualquier modificación de este campo resultará en una modificación de la información general del paciente.',
      email: 'Email',
      phone: 'Teléfono',
      phase2: '2. Por favor, seleccione o añada una farmacia:',
      search: 'BUSCAR UNA FARMACIA',
      name: 'Nombre',
      adress: 'Dirección',
      city: 'City',
      coutry: 'País',
      zipcode: 'Zipcode',
      faxShort: 'Fax : ',
      phoneShort: 'Tel : ',
      add: 'Añadir una farmacia',
      fax: 'Número de fax',
      phoneNumber: 'Número de teléfono: ',
      internet: 'Buscar',
      errorMessage: 'Esta farmacia no existe en nuestra base de datos". Tienes que añadirla si quieres enviarla',
      phase3: '3. A continuación encontrará la información seleccionada. Compruébelas y envíe el documento',
      emailOfPatient: 'Email del Paciente',
      phoneOfPatient: 'Teléfono del paciente :',
      pharmacy: 'Farmacia seleccionada',
      pharmacySearchError: 'Esta farmacia no existe en nuestra base de datos. Tienes que añadirlo si quieres enviarlo.',
    },
    sendBySecure: {
      title: 'Send via secure link',
      subtitle: 'Por favor, compruebe y rellene los siguientes campos:',
      destination1: '1. Destino del enlace',
      destination2: '2. Destino del código',
      email: 'Email',
      phone: 'Teléfono',
      or: 'O',
    },
  },
  reasonToCallNonMed: {
    benefit: 'Beneficio no cubierto',
    general: 'Información general',
    technical: 'Cuestión técnica',
    assistance: 'Asistencia no contratada',
    complaint: 'Reclamación',
    prm: 'PRM',
  },
  emergency: {
    TITLE: 'Emergencia',
    CURRENT_COUNTRY: 'El paciente declaró que se encuentra actualmente en el país: ',
    AWARE_TO_CONTACT: 'Se informa al paciente de que debe ponerse en contacto con las urgencias.',
    CURRENT_ADDRESS: 'Por favor, compruebe/llene la dirección actual del paciente: ',
    MORE_INFO: 'Más información',
    LOCAL_EMERGENCY: '¿Se ha contactado con el departamento de emergencias local?',
    CREATE: 'Crear emergencia',
  },
  techProblem: {
    TITLE: 'Incidencia técnica',
    LABEL: 'Explique la incidencia técnica:',
    CREATE: 'Notificar incidencia',
  },
  editDocument: {
    LANGUAGE: 'Cambiar el idioma',
    TITLE_PRESCRIPTION: 'Detalles de la receta',
    TITLE_MEDICAL_REPORT: 'Informe médico',
    TITLE_ADVICE_REPORT: 'Advice report',
    TELEADVICE_REPORT_NUMBERING: 'Informe de teleconsulta',
    TELECONSULTATION_REPORT_NUMBERING: 'Informe de teleconsulta médica',
    ORDONNANCE: 'Prescripción',
    BACK: 'Volver a la teleconsulta',
    DOCTOR: 'Doctor/a ',
    DR: 'Dr./Dra.',
    ID_NUMBER: 'Colegiado Nº : ',
    ADDRESS: 'Dirección',
    ORGANIZATION: 'Entidad jurídica : ',
    UNDER_NAME: 'Conocico bajo el nombre de',
    IMPLEMENTED_BY: 'Los actos de teleconsulta son implementados por',
    EMAIL: 'Correo electrónico',
    OPEN_HOURS: 'Servicio disponible 24h/7d',
    ATTENTION_OF: 'A la atención de :',
    LEGAL_REPRESENTATIVE: 'o su representante legal',
    FAMILY_DOCTOR: 'designado por el paciente como su médico tratante',
    FAMILY_DOCTOR_ADDRESS: 'Oficial en la siguiente dirección: ',
    DEAR_COLLEAGUE: 'Estimado colega',
    DEAR_DOCTOR: 'Estimado doctor',
    DEAR_PATIENT: 'Estimado paciente',
    DESCRIPTION_CALL_1_M: 'Su paciente, Sr. %{name}, edad ',
    DESCRIPTION_CALL_1_W: 'Su paciente, Sra %{name}, ',
    YEARS: ' años ',
    MONTHS: 'meses ',
    DESCRIPTION_CALL_2: 'con un peso de %{weight}kg, ',
    DESCRIPTION_CALL_3: 'ha llamado a nuestro servicio de teleconsultación en el %{callDay} a las %{callHour}.',
    SUMMARY_CALL: 'A continuación encontrarás un resumen de nuestro sesión y la información facilitada.',
    THANKS_FOR_THE_USE: 'Gracias por utilizar el servicio de teleconsulta, que tuvo luger el %{callDay} a las %{callHour}.',
    TO_BE_FOLLOWED: 'Le pedimos que regrese a usted por el resto del apoyo.',
    THE: 'el ',
    DATE: 'Fecha',
    PATIENT: 'Paciente',
    SIR: ' Sr.',
    MADAM: ' Sra.',
    BIRTH_DATE: 'Nacido el : ',
    WEIGHT: 'Peso : %{weight}kg',
    EMPTY_MEDICINE: 'No hay ningún registro todavía.',
    WARNING_ADDITIONAL_NOTES: 'Tenga en cuenta que el número de caracteres no debe exceder los 2000 caracteres.',
    ADD_MEDICINE: 'Añadir un medicamento',
    EDIT: 'Editar',
    EMPTY_NOTES: 'No hay notas adicionales todavía',
    ADD_NOTES: 'Añadir notas',
    ADVICE: {
      CALL_TELECONSULTATION: 'Su paciente, %{gender} %{firstName} %{lastName}, de %{year} años, utilizó nuestro servicio de teleconsulta el %{date} a las %{hour}.',
      TO_BE_FOLLOWED: 'A continuación encontrará un resumen de nuestros intercambios y de la información proporcionada.',
      TITLE_1: '1/ Motivo de la llamada',
      TITLE_2: '2/ Resumen de nuestras discusiones',
      NOTE: 'Notas',
      TECHNICAL_ISSUE: 'Incidente técnico durante la llamada : %{value}',
      END: 'Por favor, acepte, querido doctor, nuestros más sinceros saludos,',
      END_DOCTOR: 'Confraternalmente',
      YES: 'Sí',
      NO: 'No',
    },
    medicalProfile: {
      TITLE: 'Historial médico',
      MEDICAL_HISTORY: 'Antecedentes',
      CURRENT_TREATMENT: 'Tratamientos en curso',
      ALLERGIES: 'Alergias conocidas',
      VACCINATION_STATUS: 'Vacuna(s)',
      LIFESTYLE: 'Estilo de vida',
    },
    PAGE_BREAK: 'Salto de página',
    DIALOG_SUMMARY: 'Resumen de consulta',
    PRESCRIPTION_GIVEN: 'Al final de la teleconsulta, se dieron las siguientes pautas/recomendaciones: %{title} ',
    HOSPITALS_GIVEN: 'Se han facilitado al paciente los datos de contacto de los siguientes centros hospitalarios :',
    ORIENTATION_TO_DOCTOR: 'Durante la teleconsulta, se recomendó que el paciente se sometiera a un examen médico físico.',
    EMERGENCY_DETECTED: 'Se detectó una posible emergencia durante la llamada',
    EMERGENCY_CONTACTED: 'El paciente fue dirigido a los servicios de emergencia',
    PATIENT_REDIRECTED: 'El paciente fue dirigido a los servicios de emergencia locales',
    TECHNICAL_ISSUE: 'Incidente técnico durante la llamada',
    DETAILS: 'Detalles',
    CONTACT: 'Si tiene alguna consulta con respecto a este informe, por favor no dude en ponerse en contacto con nuestro servicio.',
    FOOTER_1: 'Le aconsejamos que vuelva a ver a su médico para el resto del tratamiento o en caso de que no haya mejoría en 48 horas.',
    FOOTER_2: 'El servicio de teleconsulta no es un servicio de emergencias/urgencias. Si fuera el caso, contacte con los servicios de emergencia/urgencia locales.',
    FOOTER_2_EMERGENCY_NUMBERS: ' ',
    FOOTER_3: 'Esta Prescripción se emite durante una teleconsulta médica y de acuerdo con la normativa vigente no incluye ni el número FINESS ni el AM. Sin embargo, la entrega de medicamentos prescritos por un médico debidamente identificado por su número ADELI debe considerarse como debe ser por la farmacia contactada.',
    FOOTER_4: 'Le recomendamos que consulte a su médico habitual para recibir atención adicional o si no hubiera mejoría.',
  },
  notify: {
    success: {
      SEND_EMAIL: 'El correo electrónico se ha enviado correctamente',
      SEND_SMS: 'El mensaje se ha enviado correctamente',
      MODIFICATION_SAVED: 'Los cambios se han bien tenido en cuenta',
      AVAILABILITY_DELETED: 'El nicho ha sido efectivamente eliminado',
      ARCHIVED: 'El caso se ha archivado correctamente',
      REOPENED: 'El caso ha sido reabierto',
      CREATED: 'La creación ha tenido éxito',
      APPOINTMENTS_CREATED: 'Se han creado disponibilidades',
    },
    error: {
      RELATED_CASE_CREATION: 'No se puede crear una cita en el pasado.',
      MANDATORY_FIELD: 'Olvidó rellenar los campos obligatorios',
    },
  },
  patientSearch: {
    TITLE: 'Búsqueda de pacientes',
    FIRSTNAME: 'Nombre',
    LASTNAME: 'Apellido',
    DATE_OF_BIRTH: 'Fecha de nacimiento',
    SEARCH_RESULT: 'Resultado de la búsqueda',
    NO_RESULT: 'No hay resultados. Por favor, inicie una nueva búsqueda',
    PATIENTS_FOUND: '%{count} de pacientes encontrados por criterios: ',
    PATIENTS_FOUND_0: 'No hay pacientes que cumplan los criterios: ',
    LAST_PATIENTS_FOUND: ' El último %{count} paciente : ',
    LAST_PATIENTS_FOUND_0: 'El último %{count} paciente : ',
    LAST_PATIENTS_FOUND_plural: 'Los últimos %{count} pacientes : ',
    OPEN_PATIENT: 'Abrir el paciente',
    WAIT_10_LAST_PATIENTS: 'Por favor, espere a que se carguen los últimos 10 pacientes.',
  },
  caseSearch: {
    TITLE: 'Búsqueda de Consultas',
    periodForm: 'Período  ',
    periodFromPlaceHolder: 'Del DD/MM/AAAA',
    periodToPlaceHolder: 'Al DD/MM/AAAA',
    periodFrom: 'Del',
    periodTo: 'Al',
    inCharge: 'Gente responsable',
    teamInCharge: 'Equipado responsable',
    assigned: 'Gente asignado',
    teamAssigned: 'Equipado asignado',
    bPartner: 'B-partner',
    channel: 'Canal',
    caseNumber: 'Número de caso',
    qualificationCode: 'Código de precalificación',
    lastModifiedBy: 'Modificado por',
    createdBy: 'Creado por',
    KEYWORD: 'Palabra clave',
    SHOW_MORE: 'Mostrar más criterios',
    SHOW_LESS: 'Mostrar menos criterios',
    SEARCH_RESULT: 'Resultado de la búsqueda',
    FIRST_CASES: 'Está el último %{count} consulta creada',
    FIRST_CASES_0: 'Está el último %{count} consulta creada',
    FIRST_CASES_plural: 'Estas son las últimas %{count} consultas creadas',
    WAIT_10_LAST_CASES: 'Por favor, espere a que se carguen los últimos 10 archivos',
    NO_RESULT: 'No hay resultados. Por favor, inicie una nueva búsqueda.',
    CASES_FOUND: '%{count} de casos encontrados por criterios: ',
    CASES_FOUND_1: 'Se ha encontrado un archivo para los criterios: ',
    CASES_FOUND_0: 'No hay registros que coincidan con los criterios: ',
    LAST_EDIT: 'Última edición por %{firstname} %{lastname}',
    CREATED_BY: 'Caso creada por %{firstname} %{lastname}',
    OPEN_CASE: 'Abrir el caso',
    label: {
      ACTIVE: 'Activo',
      ARCHIVED: 'Archivado',
      INACTIVE: 'Archivado',
      CLOSED: 'Cerrado',
      TELEADVICE: 'Teleconsejo',
      TELECONSULTATION: 'Teleconsulta',
      OTHER: 'Otro',
    },
  },
  caseDetails: {
    BACK_TO_LIST: 'Volver a la lista',
    CASE: 'Caso ',
    PATIENT: 'Paciente',
    ARCHIVED: 'Archivado',
    TELEADVICE: 'Tele consejo',
    TELECONSULTATION: 'Teleconsulta',
    LAST_EDIT: 'Última edición',
    HISTORY: 'Ver historial de modificaciones',
    NO_CONSENTS: 'Este caso fue cerrado porque los consentimientos no fueron aceptados.',
    RE_OPEN: 'Reabrir el caso',
    CHECK_RE_OPEN: '¿Estás seguro de que quieres reabrir este caso?',
    SEE_IN_CONTEXT: 'Ver en contexto',
    PATIENT_INFO: 'Información al paciente',
    patientInfo: {
      FIRSTNAME: 'Nombre',
      LASTNAME: 'Apellido',
      DATE_OF_BIRTH: 'Fecha de nacimiento',
      EMAIL: 'Dirección de correo electrónico',
      PHONE: 'Número de teléfono',
      AIDAT_ID: 'Aid@ ID',
      CHANNEL: 'Canal de comunicación',
      POLICY_NUMBER: 'Número de póliza',
      PROTOCOL_NUMBER: 'Número de protocolo',
      BPARTNER: 'B-Partner',
      LEGAL_ENTITY: 'Entidad legal',
      BRAND: 'Marca',
    },
    CASE_NOTES: 'Notas del caso',
    caseNotes: {
      REASON_FOR_CALL: 'Razón de la llamada',
      TELEADVICE_NOTES: 'Tele notas de aviso',
      TELECONSULTATION_NOTES: 'Tele notas de consulta',
      DOCTOR_NOTES: 'Notas personales del médico',
    },
    CODING: 'Códigos de casos',
    coding: {
      ADVICE_CODING: 'Codificación de teleconsejo: ',
      PATHOLOGY_CODING: 'Codificación de patología local: ',
    },
    PRESCRIPTIONS: 'Prescripciones',
    REPORTS: 'Informes',
    PATIENT_DOC: 'Documentos del paciente',
  },
  patientDetails: {
    modalResend: {
      TITLE: 'Reenviar PDA Link',
      SELECT: 'Por favor seleccione una de las pólizas a continuación',
    },
    ADD_POLICY: 'Agregar otra póliza',
    SKIP_ELIGIBILITY: 'No hay pólizas elegibles',
    BENIFICIARY_SEARCH: 'Búsqueda de beneficiario',
    POLICY_HOLDER_SEARCH: 'Búsqueda de titular de la póliza',
    EXISTING_POLICY: 'Existente póliza',
    NEW_POLICY: 'Nueva póliza',
  },
  forgot_password: {
    modal: {
      TITLE: '¿Olvidaste tu contraseña?',
      INSTRUCTIONS: 'Ingrese el inicio de sesión que utilizó para registrarse y le enviaremos por correo electrónico las instrucciones para restablecer la contraseña.',
      LOGIN: 'Iniciar sesión',
      SUBMIT: 'Enviar instrucciones de reinicio',
    },
    sms: {
      TITLE: 'Ingrese el código SMS',
      INSTRUCTIONS: 'Ingrese el código que recibió por SMS.',
      SMS_CODE: 'Código SMS',
      SUBMIT: 'Siguiente',
    },
    reset: {
      TITLE: 'Ingrese su nueva contraseña',
      INSTRUCTIONS: 'Por favor ingrese su nueva contraseña.',
      PASSWORD: 'Contraseña*',
      PASSWORD_LABEL: 'Nuvea Contraseña*',
      PASSWORD_CONFIRMATION: 'Contraseña',
      PASSWORD_CONFIRMATION_LABEL: 'Confirmar la contraseña*',
      OTP: 'Codigo',
      OTP_LABEL: 'Por favor, introduzca el código recibido por SMS*',
      SUBMIT: 'Next',
    },
    done: {
      TITLE: 'Tu contraseña ha sido restablecida !',
      INSTRUCTIONS: 'Ahora puede conectarse a esta cuenta con esta nueva contraseña.',
      SUBMIT: 'Continuar',
    },
  },
  export: {
    TITLE: 'Exportación',
    periodForm: 'Período  ',
    periodFromPlaceHolder: 'Del DD/MM/AAAA',
    periodToPlaceHolder: 'a DD/MM/AAAA',
    policyPeriodStartFrom: 'La política comienza después de ',
    policyPeriodEndBefore: 'La política termina antes de ',
    patientPolicy: {
      CASE_KPI_REPORT: 'Exportar las KPI de los caso',
      PATIENT_KPI_REPORT: 'Exportar de KPI de los usuario',
    },
    field: {
      businessUnit: 'Business Unit',
      bPartner: 'B-Partner',
      protocolNumber: 'Número de protocolo',
      prequalificationCode: 'Código de precalificación',
      emergencyDetected: 'Emergencia detectada',
      technicalIssueDetected: 'Problemas técnicos decretados',
    },
    SUBMIT: 'Exportar',
    prequalificationCoding: {
      teleAdvice: 'Teleconsejo',
      teleConsult: 'Teleconsulta',
    },
    TRUE: 'Si',
    FALSE: 'No',
    columns: {
      createdOn: 'Fecha de creación',
      gender: 'Género',
      dateOfBirth: 'Fecha de nacimiento',
      country: 'País de residencia',
      zipCode: 'Código postal',
      businessUnit: 'Business Unit',
      bPartner: 'B-Partner',
      protocolNumber: 'Número de Protocolo',
      policyNumber: 'Número de póliza',
      pdaAccess: 'Acceso a PDA',
      pdaAccount: 'Cuenta PDA',
      totalNumberCases: 'Número total de casos',
      totalNumberCasesWithSpecialists: 'Total de casos con especialistas',
      lastCaseCreated: 'Último caso creado',
      numberOfAppointments: 'Número de citas',
      numberOfAppointmentsCancelled: 'Número de citas canceladas',
      countryOfTheCall: 'País de la llamada',
      channelOfCommunication: 'Canal de comunicación',
      aidaCategory: 'Categoría de llamada',
      reasonForCall: 'Razón de la llamada',
      consent: 'Consentimiento médico',
      caseQualification: 'Calificación',
      pathologyCoding: 'Codificación Patológica',
      adviceCoding: 'Codificación de tele-consejo',
      activeReport: 'Informe de teleconsejo activo',
      orientationToEmergencyServices: 'Orientación a los Servicios de Emergencia',
      emergencyDetected: 'Emergencia detectada',
      documentJoin: 'Documentos adjuntos',
      technicalIssue: 'Tema técnico',
    },
    values: {
      man: 'Hombre',
      woman: 'Mujer',
      yes: 'Sí',
      no: 'No',
      phone: 'Teléfono',
      video: 'Video',
      chat: 'Chat',
      teleconsult: 'Teleconsulta',
      teleadvice: 'Teleadvice',
    },
  },
  operations: {
    TITLE: 'OPERACIÓN PANEL DE CONSULTAS',
    TEAM_AVAILABILITIES: 'DISPONIBILIDAD DEL EQUIPO',
    CASE_LEVEL: 'CONSULTAS NIVEL',
    CASES_STATUS: 'Estado de consultas',
    WAITING_TIME_FROM_PDA: 'Tiempo de espera del PDA',
    DASHBOARD_INFOS: 'Información del caso del tablero de mandos',
    PROCESSING_TIME: 'Tiempo medio de espera antes de que un caso se haga cargo (caso en el tablero)',
    APPOINTMENT_FORECAST: 'Citas previas',
    DETAILS: 'DETALLES',
    team: {
      CONNECTED_NURSE: 'Enfermaras conectadas',
      CONNECTED_DOCTOR: 'Médicos conectados',
    },
    case: {
      FROM_PDA: 'Casos creados desde la PDA',
      FROM_EPRM: 'Casos creados desde el ePRM',
      WAITING_PDA: 'Tiempo de espera del PDA',
      WAITING_APPOINT_PDA: 'Cita en espera de ser recogida',
      WAITING_NURSE: 'Casos esperando a una enfermera',
      WAITING_DOCTOR: 'Casos en espera',
      TAKEN_IN_CHARGE: 'Casos en curso por un médico\n',
      ASSIGNED: 'Casos asignado por un médico',
      ARCHIVED: 'Archivado',
      WITH_PDA: 'Los casos creados desde de PDA',
      WITHOUT_PDA: 'Los casos creados desde de ePRM',
      minutes: 'min',
      ALL: 'Todos los casos abiertos',
      ALL_DASHBOARD: 'Archivos abiertos totales en el tablero de mandos',
    },
    appointment: {
      APPOINTMENT_TITLE: 'Programación de citas',
      CURENT_DAY: 'Hoy en día',
      DAY_PLUS_1: 'D + 1',
      DAY_PLUS_2: 'D + 2',
      DAY_PLUS_3: 'D + 3',
      DAY_PLUS_4: 'D + 4',
      DAY_PLUS_5: 'D + 5',
      DAY_PLUS_6: 'D + 6',
      DAY_PLUS_7: 'D + 7',
    },
  },
  pharmacySearch: {
    TITLE: 'Buscar una farmacia',
    SORT_BY: 'Ordenar por',
    TO_DELETE: ' %{rows} línea(s) seleccionada(s)',
    ADD_PHARMACY: 'Añadir una farmacia',
    NAME: 'Nombre',
    ADDRESS: 'Dirección',
    CITY: 'Ciudad',
    COUNTRY: 'País',
    ZIP_CODE: 'Código postal',
  },
  adviceCodingSearch: {
    TITLE: 'Codificación de consejos',
    PLACE_HOLDER: 'Buscar un código',
    TO_DELETE: '%{rows} fila(s) seleccionada(s)',
    SORT_BY: 'Ordenar por',
    NO_RESULT: 'No hay resultados. Por favor, inicie una nueva búsqueda.',
    ADD_CODE: 'Agregar código de consejo',
    NAME: 'Nombre',
    PARENT: 'Parente',
    CREATION_DATE: 'Creado el : %{date}',
    UPDATE_CODE: ' Modificación del código',
    DELETE: 'Borrado de código',
    DELETE_CODE: '¿Estás seguro de que quieres borrar el código %{count}?',
    DELETE_CODE_0: '¿Estás seguro de que quieres borrar el código %{count}?',
    DELETE_CODE_plural: '¿Estás seguro de que quieres borrar estos códigos %{count}?',
    DELETE_IMPACT: 'La eliminación se hará de forma permanente. Los códigos ya no estarán presentes en los archivos que se han asignado.',
    STATUS: {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
    },
  },
  pathologyCodingSearch: {
    TITLE: 'Codificación de patología',
    PLACE_HOLDER: 'Buscar un código',
    TO_DELETE: '%{rows} fila(s) seleccionada(s)',
    SORT_BY: 'Ordenar por',
    NO_RESULT: 'No hay resultados. Por favor, inicie una nueva búsqueda.',
    ADD_CODE: 'Agregar código de patología',
    ACTIVE: 'ACTIVO',
    INACTIVE: 'DESACTIVADO',
    CREATION_DATE: 'Creado el : %{date}',
    UPDATE_CODE: ' Modificación del código',
    DELETE: 'Borrado de código',
    DELETE_CODE: '¿Estás seguro de que quieres borrar el código %{count}?',
    DELETE_CODE_0: '¿Estás seguro de que quieres borrar el código %{count}?',
    DELETE_CODE_plural: '¿Estás seguro de que quieres borrar estos códigos %{count}?',
    DELETE_IMPACT: 'La eliminación se hará de forma permanente. Los códigos ya no estarán presentes en los archivos que se han asignado.',
    NAME: 'Nombre',
    STATUS: 'Estado',
    IS_ACTIVE: {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
    },
  },
  userSearch: {
    TITLE: 'Búsqueda de usuario',
    FIRST_NAME: 'Nombre',
    NAME: 'Apellido',
    ROLE: 'Rol',
    TYPE: 'Typo',
    SEARCH_RESULT: 'Resultados de la búsqueda',
    USERS_FOUND: '%{number} de usuarios encontrados para el criterio: ',
    NO_RESULT: 'No hay coincidencias. Por favor, ejecute una nueva búsqueda.',
    OPEN_USER: 'Usuario abierto',
    ADDRESS: 'Dirección',
    STREET: 'Calle',
    ZIPCODE: 'Código postal',
    THIRD_PART: 'El doctor es una tercera parte',
    CITY: 'Ciudad',
    COUNTRY: 'País',
    INFORMATION: 'Información sobre la organización',
    SITE: 'Sitio',
    BUSINESS_UNIT: 'Business unit',
    TEAM: 'Equipo',
    SIGNATURE: 'Firma',
    IMPORT_IMAGE: 'Importar una imagen',
    USERNAME: 'Nombre del usuario',
    EMAIL: 'Correo electronico',
    LAST_NAME: 'Apellido',
  },
  userDetails: {
    TITLE: 'Perfil del usuario',
    CREATE: 'Creación de usuarios',
    BACK_TO_LIST: 'Volver a la lista',
    WOMAN: 'Mujer',
    MAN: 'Hombre',
    IDENTITY: 'Identidad',
    ADMIN: 'Administrador',
    GENDER: 'Género',
    FIRST_NAME: 'Nombre',
    LAST_NAME: 'Apellido',
    USERNAME: 'Nombre del usuario',
    EMAIL: 'Correo electronico',
    PHONE: 'Teléphono',
    ROLE: 'Rol',
    DOCTOR_ID: 'N° RPPS',
    THIRD_PART: 'Usuario externo',
    YES: 'Sí',
    NO: 'No',
    KNOWLEDGE: 'Idiomas y especialidades',
    LANGUAGES: 'Lenguas',
    START_TYPING: 'Empieza a escribir para ver las propuestas',
    SPECIALITIES: 'Especialidades',
    KNOWLEDGE_DETAILS: 'Detalles',
    ADDRESS: 'Dirección',
    STREET: 'Calle',
    ZIPCODE: 'Código postal',
    CITY: 'Ciudad',
    COUNTRY: 'País',
    INFORMATION: 'Información de la organización',
    SITE: 'Sitio',
    BUSINESS_UNIT: 'Unidad de negocios',
    TEAM: 'Equipo',
    SIGNATURE: 'Firma',
    IMPORT_IMAGE: 'Importar una imagen',
    wordings: {
      dragFile: 'Arrastra y suelta tu firma para subirlo',
      selectFile: 'O seleccionarlo de un archivo',
    },
    DOCTOR_INFORMATION: 'información médica',
    TYPE: 'Typo',
  },
  appointment: {
    TITLE: 'CALENDARIO DE NOMBRAMIENTOS',
    form: {
      userProfile: 'Usuario :',
    },
    empty: 'Espacio libre',
    booked: 'Espacio reservado',
    old: 'Disponibilidad existente',
    new: 'Nueva disponibilidad',
    details: {
      TITLE: 'Cita la : %{date}',
      hour: 'Cita en : %{date}',
      formatDate: 'DD/MM/YYYY',
      patient: 'Información del paciente',
      patientInfos: '%{firstname} - %{lastname} - %{birthdate}',
      caseArchived: 'Este caso ya ha sido manejado y archivado.',
      caseTaken: 'Este caso es apoyado por : %{doctor}',
      caseAssigned: 'Este caso está asignado a:',
    },
    detailsEmpty: {
      TITLE: 'Cita disponible la %{date}',
      hour: 'Cita disponible en %{date}',
      formatDate: 'DD/MM/YYYY',
    },
  },
  availability: {
    TITLE: 'GESTIONAR TIEMPOS',
    add: {
      TITLE: 'Agregar nuevos espacios',
      content: 'Cree nuevos espacios para el usuario / especialidad seleccionada: ',
      no_user_selected: 'Debes seleccionar un usuario para poder añadir franjas horarias',
      no_user: 'Ningún usuario seleccionado',
    },
    form: {
      hours: 'Horas',
      period: 'Período ',
      slotDuration: 'Duración de la ranura (min)',
    },
    summarize: {
      title: 'Niche creation summary :',
      from: 'Periodo: %{startDate} à %{endDate}',
      to: 'à',
      during: 'Duración de las franjas horarias : %{duration} min',
    },
  },
  createRelatedCase: {
    title: 'Escoge un médico para la cita',
    chooseUser: 'Por favor, elija el médico con el que desea hacer una cita : ',
    chooseSlot: 'Por favor, elija una franja horaria para crear una cita :',
    userAvailabilities: 'Disponibilidad de los médicos: %{carer}',
    summarize: {
      title: 'Resumen de la cita creada',
      patient: 'Creará una cita para el paciente :  %{patient} ',
      profile: 'Con perfil : %{profile}',
      period: 'De %{startDate} a %{endDate}',
    },
    redirectionChoice: 'La cita ha sido creada. Para continuar con el caso actual haz clic en “Continuar la teleconsulta actual”',
  },
  calendar: {
    today: 'Hoy',
    previous: 'Anterior',
    next: 'Siguiente',
    month: 'Mes',
    week: 'Semana',
  },
  fax: {
    CANCEL_TITLE: 'Cancelación del fax',
    SEND_TITLE: 'Enviando el fax',
    SENDING: 'Envío del fax actual',
    SENT: 'Fax enviado con éxito',
    LINK_EXPIRED: 'Enlace caducado o inválido',
    CANCELLING: 'Cancelación en curso',
    CANCEL: 'Enviando el fax cancelado',
    BACK: 'Ir a la página web de Teleconsulta',
  },
  internalBeneficiary: {
    TITLE: 'Beneficiario interno',
    BENEFICIARY_NAME: 'Nombre del beneficiario',
    BENEFICIARY_SURNAME: 'Apellido del beneficiario',
    BENEFICIARY_BIRTHDATE: 'Fecha de nacimiento del beneficiario',
    EMAIL_ADDRESS: 'Dirección de correo electrónico',
    PHONE_NUMBER: 'Número de teléfono',
    GENDER: 'Género',
    POLICY_NUMBER: 'Número de póliza ',
    PROTOCOL_NUMBER: 'Número de protocolo',
    POLICY_START_DATE: 'Fecha de inicio de la póliza',
    POLICY_END_DATE: 'Fecha de finalización de la póliza',
    STREET: 'Calle',
    CITY: 'Ciudad',
    COUNTRY: 'País',
    POST_CODE: 'Código postal',
    POLICY_HOLDER_NAME: 'Nombre del titular de la póliza',
    POLICY_HOLDER_SURNAME: 'Apellido del titular de la póliza',
    POLICY_HOLDER_CITY: 'Ciudad del titular de la póliza',
    POLICY_HOLDER_PHONE_NUMBER: 'Número de teléfono del titular de la póliza',
    SEARCH: {
      NO_RESULT: 'No hay resultados. Por favor, inicie una nueva búsqueda.',
      FOUND_RESULT: 'Se encontraron %{count} beneficiarios internos para estos criterios',
      SEARCH_RESULT: 'Resultados de la búsqueda',
    },
    BENEFICIARY_SECTION: 'Datos del beneficiario',
    POLICY_SECTION: 'Información de la póliza',
    ADDRESS_SECTION: 'Dirección',
    POLICY_HOLDER_SECTION: 'Información del titular de la póliza',
    MODAL: {
      CONFIRM_TO_DELETE: '¿Está seguro de que desea eliminar este beneficiario en la lista interna de beneficiarios?',
      DELETE_TITLE: 'Eliminar',
      DELETE_BUTTON: 'Eliminar',
    },
  },
  common: {
    LAST_EDIT_BY: 'Última edición por %{fullName}',
    USER_GENDER_MALE: 'Hombre',
    USER_GENDER_FEMALE: 'Mujer',
  },
  bPartner: {
    TITLE: 'Socio B',
    TITLE_LIST: 'Lista de socios B',
    TITLE_CREATE: 'Creación de un socio B',
    label: {
      B_PARTNER_NAME: 'Nombre del socio B',
      B_PARTNER_CODE: 'Código del socio B',
      BUSINESS_UNIT: 'Unidad de Negocio',
      STATUS: 'Estado',
      COMMERCIAL_NAME: 'Nombre comercial',
      DOCTORS_NOTES: 'Notas del médico',
    },
    STATUS: {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
    },
    button: {
      ADD: 'Añadir',
      EXPORT: 'Exportar',
    },
    TABLE: {
      B_PARTNER_NAME: 'Nombre del socio B',
      B_PARTNER_CODE: 'Código del socio B',
      BUSINESS_UNIT: 'Unidad de Negocio',
      STATUS: 'Estado',
      COMMERCIAL_NAME: 'Nombre comercial',
      PAGINATION_INFO: 'Mostrando %{start} a %{end} de %{total} entradas',
    },
    MODAL: {
      DELETE_TITLE: 'ELIMINAR',
      CONFIRM_TO_DELETE: '¿Está seguro de que desea eliminar esta lista de socios B?',
    },
    export: {
      bPartner: 'Nombre del socio B',
      bPartnerCode: 'Código del socio B',
      businessUnit: 'Unidad de Negocio',
      status: 'Estado',
      commercialName: 'Nombre comercial',
      doctorNotes: 'Notas del médico',
      createdOn: 'Fecha de creación',
    },
  },
  logoManagement: {
    TITLE: 'Logotipo',
    TITLE_LIST: 'Lista de logotipos',
    TITLE_CREATE: 'Fecha de creación del logotipo',
    label: {
      LOGO_NAME: 'Nombre del logotipo',
      BUSINESS_UNIT: 'Unidad de Negocio',
      LOGO_FILE: 'Archivo del logotipo',
      STATUS: 'Estado',
    },
    STATUS: {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
    },
    button: {
      ADD: 'Añadir',
      EXPORT: 'Exportar',
    },
    TABLE: {
      LOGO_NAME: 'Nombre del logotipo',
      BUSINESS_UNIT: 'Unidad de Negocio',
      STATUS: 'Estado',
      CREATED: 'Fecha de creación',
      PAGINATION_INFO: 'Mostrando %{start} a %{end} de %{total} entradas',
    },
    export: {
      logoName: 'Nombre del logotipo',
      businessUnit: 'Unidad de Negocio',
      status: 'Estado',
      createdOn: 'Fecha de creación',
    },
  },
  protocol: {
    TITLE: 'Protocolo',
    TITLE_LIST: 'Lista de protocolos',
    TITLE_CREATE: 'Creación de un Protocolo',
    label: {
      PROTOCOL_NAME: 'Nombre del protocolo',
      PROTOCOL_NUMBER: 'Número de protocolo',
      B_PARTNER_NUMBER: 'Número de Socio B',
      B_PARTNER: 'Socio B',
      LEGAL_ENTITY: 'Entidad legal',
      BRAND: 'Marca',
      LOGO: 'Logotipo',
      MEDICAL_TELECONSULTATION_PHONE_NUMBER: 'Número de teléfono de teleconsulta médica',
      NUMBER_OF_CONSULTATIONS_AUTHORIZED_PER_YEAR: 'Número de consultas autorizadas al año',
      CONSULTATIONS_PER_YEAR: 'Número de consultas autorizadas al año',
      PROVIDER_NETWORK: 'Red de proveedores',
      COMMERCIAL_NAME: 'Nombre comercial',
      THIRD_PARTY_SSO: 'SSO de terceros',
      TYPE_OF_PROTOCOL: '¿Cartera CIC/Crédit Mutuel ?',
      VISA: 'Visa',
      STATUS: 'Estado',
    },
    STATUS: {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
    },
    button: {
      ADD: 'Añadir',
      EXPORT: 'Exportar',
    },
    TYPE_OF_PROTOCOL_TYPE: {
      CREDIT_CARD_TYPE: 'Sí',
      NOT_CREDIT_CARD_TYPE: 'No',
    },
    TABLE: {
      PROTOCOL_NAME: 'Nombre del protocolo',
      PROTOCOL_NUMBER: 'Número de protocolo',
      BUSINESS_UNIT: 'Unidad de Negocio',
      LEGAL_ENTITY: 'Entidad legal',
      BRAND: 'Marca',
      MEDICAL_TELECONSULTATION_PHONE_NUMBER: 'Número de teléfono de teleconsulta médica',
      B_PARTNER: 'Socio B',
      NUMBER_OF_CONSULTATIONS_AUTHORIZED_PER_YEAR: 'Número de consultas autorizadas al año',
      PROVIDER_NETWORK: 'Red de proveedores',
      COMMERCIAL_NAME: 'Nombre comercial',
      THIRD_PARTY_SSO: 'SSO de terceros',
      TYPE_OF_PROTOCOL: 'Tipo de protocolo',
      STATUS: 'Estado',
      PAGINATION_INFO: 'Mostrando %{start} a %{end} de %{total} entradas',
    },
    MODAL: {
      DELETE_TITLE: 'ELIMINAR',
      CONFIRM_TO_DELETE: '¿Está seguro de que desea eliminar esta lista de protocolos?',
    },
    export: {
      protocolName: 'Nombre del protocolo',
      protocolNumber: 'Número de protocolo',
      businessUnit: 'Unidad de Negocio',
      legalEntityName: 'Entidad legal',
      brandName: 'Marca',
      MedicalTeleconsultationPhoneNumber: 'Número de teléfono de teleconsulta médica',
      bPartner: 'Socio B',
      numberOfConsultationPerYear: 'Número de consultas autorizadas al año',
      providerNetworkDetails: 'Red de proveedores',
      commercialName: 'Nombre comercial',
      isThirdPartySso: 'SSO de terceros',
      typeOfProtocol: 'Tipo de protocolo',
      status: 'Estado',
      isCmCic: '¿Cartera CIC/Crédit Mutuel ?',
      isVisa: 'Visa',
      createdOn: 'Fecha de creación',
      logoName: 'Nombre del logotipo',
      phoneNumber: 'teleconsulta médica',
    },
  },
  legalEntity: {
    TITLE: 'entidad jurídica',
    TITLE_LIST: 'Lista de entidades jurídicas',
    TITLE_CREATE: 'Fecha de creación',
    label: {
      LEGAL_ENTITY_NAME: 'Nombre de la entidad legal',
      LEGAL_ENTITY_CODE: 'Código de entidad jurídica',
      BUSINESS_UNIT: 'Unidad de Negocio',
      STATUS: 'Estado',
      EMAIL_FOOTER: 'Parte inferior del correo',
      ADDRESS: 'Dirección',
    },
    STATUS: {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
    },
    button: {
      ADD: 'Añadir',
      EXPORT: 'Exportar',
    },
    message: {
      WARNING_FILL_BIN: 'Protocol with visa will be fully functional once BIN list for visa has been updated.',
      ERROR_NOT_ALLOWED_ENABLE_BOTH_VISA_CIC: 'Protocol not applicable for enable both VISA and CIC/Credit Mutuel portfolio, please ensure to selectonly one of them.'
    },
    TABLE: {
      LEGAL_ENTITY_NAME: 'Nombre de la entidad legal',
      LEGAL_ENTITY_CODE: 'Código de entidad jurídica',
      BUSINESS_UNIT: 'Unidad de Negocio',
      STATUS: 'Estado',
      PAGINATION_INFO: 'Mostrando %{start} a %{end} de %{total} entradas',
    },
    export: {
      legalEntityName: 'Nombre de la entidad legal',
      legalEntityCode: 'Código de entidad jurídica',
      businessUnit: 'Unidad de Negocio',
      status: 'Estado',
      emailFooter: 'Parte inferior del correo',
      address: 'Dirección',
      createdOn: 'Fecha de creación',
    },
  },
  brandManagement: {
    TITLE: 'Marca',
    TITLE_LIST: 'Lista de marcas',
    TITLE_CREATE: 'Fecha de creación',
    label: {
      BRAND_CODE: 'Código de marca',
      BRAND_NAME: 'Nombre de la marca',
      BUSINESS_UNIT: 'Unidad de Negocio',
      STATUS: 'Estado',
      SENDER_MAIL: 'Remitente del correo electrónico',
      PDA_LINK: 'Enlace a la PDA',
      BRAND_CREATION: 'Fecha de creación',
    },
    STATUS: {
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
    },
    button: {
      ADD: 'Añadir',
      EXPORT: 'Exportar',
    },
    TABLE: {
      BRAND_CODE: 'Código de marca',
      BRAND_NAME: 'Nombre de la marca',
      BUSINESS_UNIT: 'Unidad de Negocio',
      SENDER_MAIL: 'Remitente del correo electrónico',
      PDA_LINK: 'Enlace a la PDA',
      BRAND_CREATION: 'Fecha de creación',
      STATUS: 'Estado',
      PAGINATION_INFO: 'Mostrando %{start} a %{end} de %{total} entradas',
    },
    export: {
      brandName: 'Nombre de la marca',
      brandCode: 'Código de marca',
      businessUnit: 'Unidad de Negocio',
      status: 'Estado',
      senderEmail: 'Remitente del correo electrónico',
      pdaLink: 'Enlace a la PDA',
      createdOn: 'Fecha de creación',
    },
  },
};

export default es;
