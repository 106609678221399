// @flow
import * as React from 'react';
import { Button } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import {SearchCriteriaType} from '../types/InternalBeneficiaryTypes';

type Props = {
  pageSize: number,
  list: Object[],
  render: Function,
  totalSearchResult: number,
  callBackFetchList: Function,
  lastSearchObject: Object
}

export default function DynamicPaginatedList({ pageSize, list, render, totalSearchResult, callBackFetchList, lastSearchObject }: Props) {
  return (
    <React.Fragment>
      {list &&
      list.map(data => render(data))}
      {
        list && list.length < totalSearchResult &&
        <div className="center-child">
          <Button
            id="nextPage"
            type="submit"
            color="primary"
            className="validation-button"
            onClick={() => { 
              lastSearchObject.pageSize += list.length; 
              callBackFetchList(lastSearchObject) }}
          >
            <i className="button-icon fas fa-eye" />
            {I18n.t('button.NEXT')}
          </Button>
        </div>
      }
    </React.Fragment>
  );
}
