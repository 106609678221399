// @flow

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

import { currentSessionService } from '../services/sessionServiceInstance';
import { phoneInputLanguage } from '../enum';
import { currentLanguage } from '../services/languages';
import { validatePhoneGoogle } from '../validator';

type Props = {
  required?: boolean,
  preferredCountries?: String[],
  value: String,
  label?: String,
  onChange: Function,
  setPhoneIsValid?: Function,
  disabled?: boolean,
}

type State = {
  errorMessage: String,
}

class CoreozPhoneInput extends React.Component<Props, State> {
  static defaultProps = {
    required: false,
    preferredCountries: [],
    label: undefined,
    setPhoneIsValid: () => {
    },
    disabled: false,
  };

  state = {
    errorMessage: '',
  };

  componentDidMount() {
    if (this.props.value) {
      const phoneNumber = parsePhoneNumberFromString(this.props.value, currentSessionService().currentUser().businessUnitCountryCode.toUpperCase());
      const errorMessage = validatePhoneGoogle(phoneNumber, this.props.required);
      this.setState({ errorMessage });
      this.props.setPhoneIsValid(!errorMessage);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.required !== nextProps.required) {
      const phoneNumber = parsePhoneNumberFromString(nextProps.value, currentSessionService().currentUser().businessUnitCountryCode.toUpperCase());
      const errorMessage = validatePhoneGoogle(phoneNumber, nextProps.required);
      this.setState({ errorMessage });
      nextProps.setPhoneIsValid(!errorMessage);
    }
  }

  render() {
    return (
      <div className="code-phone-number base-container-input-coreoz">
        {this.props.label &&
        <div
          className={`base-label-input-coreoz ${this.props.required ? 'base-label-input-coreoz--required' : ''}`}
        >
          {this.props.label}
        </div>
        }
        <div className="base-container-input-coreoz">
          <PhoneInput
            country={currentSessionService().currentUser().businessUnitCountryCode}
            localization={phoneInputLanguage[currentLanguage().code]}
            enableSearch
            inputProps={{ autoComplete: 'none' }}
            autoFormat={false}
            preferredCountries={this.props.preferredCountries}
            searchPlaceholder=""
            placeholder=""
            value={this.props.value}
            onChange={(newValue, country) => {
              const phoneNumber = parsePhoneNumberFromString(`+${newValue}`, country.countryCode.toUpperCase());
              const errorMessage = validatePhoneGoogle(phoneNumber, this.props.required);
              this.setState({ errorMessage });
              this.props.setPhoneIsValid(!errorMessage);
              this.props.onChange(phoneNumber ? phoneNumber.number : newValue);
            }}
            disabled={this.props.disabled}
          />
          <div className="base-error-form error-form--field error">
            {!!this.state.errorMessage && this.state.errorMessage}
          </div>
        </div>
      </div>
    );
  }
}

export default (CoreozPhoneInput);
