// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import patientApi from '../../../network/api/patientApi';
import { notifyError } from '../../../network/notification';

type Props = {
  caseDetails: Object,
  dispatch: Function,
}

type State = {
  patient: Object,
}

class PatientInformation extends React.Component<Props, State> {
  state = {
    patient: {},
  };

  componentDidMount() {
    patientApi.fetchById(this.props.caseDetails.patientId)
      .then(response => response.json())
      .then(patient => this.setState({ patient }))
      .catch(this.props.dispatch(notifyError));
  }

  render() {
    return (
      <div className="patient-information">
        <div className="patient-information-column">
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.FIRSTNAME')}</div>
            <div
              className="patient-information-value">{!!this.state.patient.firstName ? this.state.patient.firstName : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.LASTNAME')}</div>
            <div
              className="patient-information-value">{!!this.state.patient.lastName ? this.state.patient.lastName : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.DATE_OF_BIRTH')}</div>
            <div
              className="patient-information-value">{this.state.patient.birthDate ? moment(this.state.patient.birthDate).format(I18n.t('date.DATE_FORMAT')) : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.EMAIL')}</div>
            <div
              className="patient-information-value">{!!this.state.patient.email ? this.state.patient.email : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.PHONE')}</div>
            <div
              className="patient-information-value">{!!this.state.patient.mobilePhone ? this.state.patient.mobilePhone : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.AIDAT_ID')}</div>
            <div
              className="patient-information-value">{!!this.props.caseDetails.aidaId ? this.props.caseDetails.aidaId : '--'}</div>
          </div>
        </div>
        <div className="patient-information-column">
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.CHANNEL')}</div>
            <div
              className="patient-information-value">{!!this.props.caseDetails.channel ? I18n.t(`communicationType.${this.props.caseDetails.channel.toLowerCase()}`) : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.POLICY_NUMBER')}</div>
            <div
              className="patient-information-value">{!!this.props.caseDetails.policyNumber ? this.props.caseDetails.policyNumber : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.PROTOCOL_NUMBER')}</div>
            <div
              className="patient-information-value">{!!this.props.caseDetails.protocolNumber ? this.props.caseDetails.protocolNumber : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.BPARTNER')}</div>
            <div
              className="patient-information-value">{!!this.props.caseDetails.bpartnerName ? this.props.caseDetails.bpartnerName : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.BRAND')}</div>
            <div
              className="patient-information-value">{!!this.props.caseDetails.brand ? this.props.caseDetails.brand : '--'}</div>
          </div>
          <div className="patient-information-row">
            <div className="patient-information-subtitle">{I18n.t('caseDetails.patientInfo.LEGAL_ENTITY')}</div>
            <div
              className="patient-information-value">{!!this.props.caseDetails.legalEntityName ? this.props.caseDetails.legalEntityName : '--'}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(PatientInformation));
