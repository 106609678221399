// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'reactstrap';
import Tile from '../../../components/Tile';
import { DASHBOARD_PAGE_ROUTE } from '../../../routes';
import ModalEprm from '../../../components/modal/ModalEprm';
import { required } from '../../../validator';

type Policy = {
  policyNumber: string,
  openCase: Case[],
  protocolNumber: string,
  partnerName: string,
  startDate: string,
  expiryDate: string,
  patient: Patient,
  isActive: boolean,
  beneficiaryBirthday: string,
  beneficiaryFirstName: string,
  beneficiaryLastName: string,
  isSearchBeneficiary: boolean
};

type Patient = {
  id: String,
  firstName: String,
  lastName: String,
  birthDate: String,
  policyNumber: String
};

type Information = {
  firstName: String,
  lastName: String,
  birthDate: String,
  policy: string,
};

type Case = {
  brand: string,
  channel: string,
  startDateAppointment: string,
  dateCreation: string,
  partner: string,
  patientName: string,
  protocolNumber: string,
  reasonForTheCall: string,
  speciality: string,
  status: string,
  userName: string,
  userRole: string,
};

type Props = {
  policies: Policy[],
  onlyPrmSearch: boolean,
  tooManyPatients: boolean,
  firstInfo: Information,
  nextStep: Function,
  history: Function,
};

type State = {
  modal: boolean,
};

class EligibilitySuccess extends React.Component<Props, State> {
  state = {
    modal: false,
  };

  getPartnerNames = () => [...new Set(this.props.policies.map(policy => policy.partnerName))];

  hasOnePolicyEligible = () => this.props.policies.filter(policy => policy.isActive === true).length !== 0;

  getResultText = () => {
    if (!this.hasOnePolicyEligible()) {
      if (moment(this.props.policies[0].expiryDate).isBefore(moment())) {
        return I18n.t('processCase.eligibility.result.EXIST_AND_EXPIRED');
      }
      return I18n.t('processCase.eligibility.result.EXIST_AND_NOT_ELIGIBLE');
    } else if ( this.props.policies.some(policy => policy.patient && policy.patient.id)  ) {
      return I18n.t('processCase.eligibility.result.EXIST_AND_ELIGIBLE', { patient: ` ${this.props.patient.firstName} ${this.props.patient.lastName}` });
    }
    return I18n.t('processCase.eligibility.result.NOT_EXIST_AND_FOUND', { patient: ` ${this.props.patient.firstName} ${this.props.patient.lastName}` });
  };

  handleSubmit = (values) => {
    const { patientSelected } = values;
    this.props.nextStep(patientSelected);
  };

  render() {
    return (
      <div>
        <Tile
          className={`tile-eligibility ${this.hasOnePolicyEligible() ? 'eligibility-found' : 'eligibility-not-found'}`}
        >
          <i className="fa fa-user-check" />
          <div className="eligibility-result">
            {this.getResultText()}
          </div>
        </Tile>
        {
          this.props.onlyPrmSearch
          &&
          <div className="explanation-phrase warning">{I18n.t('processCase.eligibility.result.WARNING_ONLY_PRM')}</div>
        }
        {
          this.props.tooManyPatients
          &&
          <div
            className="explanation-phrase warning"
          >{I18n.t('processCase.eligibility.result.WARNING_TOO_MANY_PATIENTS')}
          </div>
        }
        {
          this.props.policies.length > 50
          &&
          <div className="explanation-phrase warning">{I18n.t('processCase.eligibility.result.WARNING_TOO_LARGE')}</div>
        }
        <Form
          onSubmit={this.handleSubmit}
          render={({ handleSubmit, invalid, form }) => (
            <form
              className="identification-form"
              onSubmit={handleSubmit}
            >
              { this.props.policies && this.getPartnerNames().map(partner => (
                <div className="policy-container" key={partner}>
                  <div className="policy-brand">
                    {partner} ({this.props.policies
                    .filter(policy => policy.partnerName === partner).length})
                  </div>
                  {this.props.policies
                    .filter(policy => policy.partnerName === partner)
                    .map((policy, i) => (
                      <Tile
                        className="tile-policy"
                        key={`${policy.protocolNumber}_${policy.policyNumber}_${policy.beneficiaryFirstName}_${policy.beneficiaryLastName}_${policy.beneficiaryBirthday}`}
                      >

                        <div className="policy-number">
                          <label>
                            <Field
                              render={props => (<input
                                {...props}
                                {...props.input}
                                onChange={(e) => {
                                                          form.change('patientSelected', policy);
                                                          props.input.onChange(e);
                                                        }}
                              />)}
                              name="protocolCheckbox"
                              type="radio"
                              value={(`${i}_${policy.protocolNumber}_${policy.beneficiaryFirstName}_${policy.beneficiaryLastName}_${policy.beneficiaryBirthday}`) || ''}
                              validate={required}
                              disabled={!policy.isActive}
                            />
                            <div className="number">{policy.protocolNumber}</div>
                          </label>
                        </div>
                        <div className="patient-info">
                          <div
                            className="name"
                            title={`${policy.beneficiaryFirstName} ${policy.beneficiaryLastName}`}
                          >{policy.beneficiaryFirstName} {policy.beneficiaryLastName}
                          </div>
                          <div
                            className="dob-policy"
                          >{policy.beneficiaryBirthday ? `${moment(policy.beneficiaryBirthday).format(I18n.t('date.DATE_FORMAT'))} - ` : ''}{policy.policyNumber}
                          </div>
                        </div>
                        <div className={`policy-status-container ${policy.isActive ? 'eligible' : 'not-eligible'}`}>
                          <div
                            className="policy-status"
                          >{policy.isActive ? I18n.t('processCase.eligibility.ELIGIBLE') : I18n.t('processCase.eligibility.NOT_ELIGIBLE')}
                          </div>
                          {!policy.isActive && policy.startDate && moment(policy.startDate).isValid() &&
                          <div className="policy-expiration-date"> {I18n.t('processCase.eligibility.START_DATE')}
                            {moment(policy.startDate).format(I18n.t('date.DATE_FORMAT'))}
                          </div>}
                          {policy.expiryDate && moment(policy.expiryDate).isValid() &&
                          <div className="policy-expiration-date"> {I18n.t('processCase.eligibility.EXPIRATION_DATE')}
                            {moment(policy.expiryDate).format(I18n.t('date.DATE_FORMAT'))}
                          </div>}
                        </div>
                      </Tile>
                    ))}
                </div>
              ))}
              <div className="form-action">
                {this.hasOnePolicyEligible() ?
                  <Button
                    color="primary"
                    type="submit"
                    disabled={invalid}
                  >
                    <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.CONTINUE')}
                  </Button>
                  :
                  <Button
                    color="primary"
                    onClick={() => this.setState({ modal: !this.state.modal })}
                  >
                    <i className="button-icon fa fa-ban" />{I18n.t('button.ABORT')}
                  </Button>
                }
              </div>
            </form>
          )}
        />
        <ModalEprm
          title={I18n.t('button.ABORT')}
          modal={this.state.modal}
          toggle={() => this.setState({ modal: !this.state.modal })}
        >
          <div className="take-in-charge-message">
            {I18n.t('processCase.eligibility.ABORT_TEXT')}
          </div>
          <div className="take-in-charge-buttons">
            <Button
              color="secondary"
              onClick={() => this.setState({ modal: !this.state.modal })}
            >
              <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
            </Button>
            <Button
              color="primary"
              onClick={() => this.props.history.push(DASHBOARD_PAGE_ROUTE)}
            >
              <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.BACK_TO_DASHBOARD')}
            </Button>
          </div>
        </ModalEprm>
      </div>
    );
  }
}

export default withRouter(EligibilitySuccess);
