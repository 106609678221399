// @flow
import React from 'react';
import { I18n } from 'react-redux-i18n';
import FilterButton from './FilterButton';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Appointment, CommunicationChannel, UserRole } from '../../enum';
import userApi from '../../network/api/userApi';
import { currentSessionService } from '../../services/sessionServiceInstance';
import specialityApi from '../../network/api/specialityApi';

type Props = {
  language: Object,
};

type State = { users: Array<Object>, specialties: Array<Object> };

class FilterBar extends React.Component<Props, State> {
  state = {
    users: [],
    specialties: [],
  };

  componentDidMount() {
    userApi
      .fetchByBusinessUnit()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(users => this.setState({ users }))
      .catch(e => console.log('Erreur apppel API', e));

    specialityApi
      .fetchSpecialties(this.props.language.code)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(specialties => this.setState({ specialties }))
      .catch(e => console.log('Erreur apppel API', e));
  }

  render() {
    return (
      <div className="dashboard-filters">
        <i className="filter-icon fa fa-filter" />
        <div className="filter-title">{I18n.t('dashboard.filters.TITLE')}</div>
        <div className="filters">
          {currentSessionService().currentUser().showViewFilterInDashboard &&
          <FilterButton
            title={I18n.t('dashboard.filters.VIEW')}
            type="userRole"
            selected={I18n.t('filterBar.ALL')}
            options={[
              { label: I18n.t(`filterBar.${UserRole.DOCTOR_LEVEL}`), value: UserRole.DOCTOR_LEVEL },
              { label: I18n.t(`filterBar.${UserRole.NURSE_LEVEL}`), value: UserRole.NURSE_LEVEL },
              { label: I18n.t('filterBar.ALL'), value: UserRole.NURSE_DOCTOR_LEVEL },
            ]}
          />
          }

          {this.state.users &&
          <FilterButton
            title={I18n.t('dashboard.filters.ASSIGNED')}
            type="userName"
            selected={I18n.t('filterBar.ALL')}
            options={[{ label: I18n.t('filterBar.ALL'), value: null }]
              .concat(this.state.users.map(user => ({
                label: `${user.firstName} ${user.lastName}`,
                value: `${user.id}`
              })))}
          />}
          {this.state.specialties &&
          <FilterButton
            title={I18n.t('dashboard.filters.SPECIALITY')}
            type="speciality"
            selected={I18n.t('filterBar.ALL')}
            options={[{
              label: I18n.t('filterBar.ALL'),
              value: null,
            }].concat(this.state.specialties.map(s => ({
              label: I18n.t(`specialities.${s.name}`),
              value: s.id,
              key: s.id,
            })))}
          />}
          <FilterButton
            title={I18n.t('dashboard.filters.CALL_TYPE')}
            type="channel"
            selected={I18n.t('filterBar.ALL')}
            options={[
              {
                label: I18n.t(`filterBar.${CommunicationChannel.PHONE}`),
                value: CommunicationChannel.PHONE,
              },
              {
                label: I18n.t(`filterBar.${CommunicationChannel.VIDEO}`),
                value: CommunicationChannel.VIDEO,
              },
              {
                label: I18n.t(`filterBar.${CommunicationChannel.CHAT}`),
                value: CommunicationChannel.CHAT,
              },
              {
                label: I18n.t('filterBar.ALL'),
                value: null,
              }].filter(option => option.value !== CommunicationChannel.CHAT
                || currentSessionService().currentUser().showChat)}
          />
          <FilterButton
            title={I18n.t('dashboard.filters.APPOINTMENT')}
            type="appointment"
            selected={I18n.t('filterBar.ALL')}
            options={[
              { label: I18n.t(`filterBar.${Appointment.TODAY}`), value: Appointment.TODAY },
              { label: I18n.t(`filterBar.${Appointment.LATER}`), value: Appointment.LATER },
              { label: I18n.t('filterBar.ALL'), value: null },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  language: state.businessUnit.language,
}))(FilterBar));
