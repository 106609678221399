// @ flow

import React from 'react';

import { MAIL_DETAILS_PAGE_ROUTE, MAIL_PAGE_ROUTE } from '../../routes';
import Mail from './Mail';
import MailDetails from './MailDetails';
import PrivateRoute from '../../components/PrivateRoute';

function MailRoutes() {
  return (
    <div>
      <PrivateRoute exact path={MAIL_PAGE_ROUTE} component={Mail} />
      <PrivateRoute path={MAIL_DETAILS_PAGE_ROUTE} component={MailDetails} />
    </div>
  );
}

export default MailRoutes;
