// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import ModalEprm from '../../../modal/ModalEprm';

type Props = {
    toggle: Function,
    action: Function,
    title: string,
    content: string,
}

type States = {
}

function ConfirmationModal(props: Props) {
  const {
    toggle, title, action, content,
  } = props;
  return (
    <ModalEprm
      title={title}
      modal
      className="hospital-search-confirmation-popin"
      toggle={() => toggle(false)}
      size="sm"
    >
      <div className="content">
        {content}
      </div>
      <div className="row">
        <Button className="margin-auto" color="primary" onClick={action}>{I18n.t('button.SEND')}</Button>
        <Button className="margin-auto" onClick={() => toggle(false)}>{I18n.t('button.CANCEL')}</Button>
      </div>
    </ModalEprm>
  );
}

export default withRouter(ConfirmationModal);
