// @flow

import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { connect } from 'react-redux';
import { store } from '../../network/reduce';
import { DASHBOARD_FILTERS } from '../../state/dashboard/dashboardReducer';

type Props = {
  title: string,
  selected: string,
  options: Object,
  dispatch: Function,
  type: string,
  filters: {
    userName: string,
    userRole: string,
    channel: string,
  }
}

type State = {
  selected: string,
  dropdownOpen: boolean
}

class FilterButton extends React.Component<Props, State> {
  state = {
    selected: this.props.selected,
    dropdownOpen: false,
  };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  changeSelection = (option) => {
    this.setState({ selected: option.label });
    this.props.dispatch(store(
      DASHBOARD_FILTERS,
      {
        ...this.props.filters,
        [this.props.type]: option.value,
      },
    ));
  };

  render() {
    return (
      <Dropdown className="filter" isOpen={this.state.dropdownOpen} size="sm" toggle={this.toggle}>
        {this.props.title} :
        <DropdownToggle
          caret
          className="filter-field"
          tag="span"
        >
          {this.state.selected}
        </DropdownToggle>
        <DropdownMenu className="filter-menu">
          {this.props.options.map(option => (
            <DropdownItem
              key={option.key || option.value}
              onClick={() => this.changeSelection(option)}
            >{option.label}
            </DropdownItem>))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default connect(state => ({
  filters: state.dashboard.dashboardFilters,
}))(FilterButton);
