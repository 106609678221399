// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { I18n, Translate } from 'react-redux-i18n';
import { CoreozSelectBase as CoreozSelect } from '../../../lib/coreoz-form-base/index';
import Loader from '../../../components/Loader';

type Props = {
  search: Function,
  availabilityProfiles: Object[],
  loading?: boolean,
};

function Filters(props: Props) {
  return (
    <div className="search-form">
      <Form
        onSubmit={props.search}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Row>
                <Col className="search-list">
                  <Field
                    className="centered"
                    component={CoreozSelect}
                    name="id"
                    label={I18n.t('appointment.form.userProfile')}
                    list={props.availabilityProfiles.map(e => ({ id: e.id, label: e.name }))}
                  />
                </Col>
                <Col className="actions-button centered">
                  <button
                    className="btn btn-primary "
                    type="submit"
                    disabled={!values.id || props.loading}
                  >
                    {
                      props.loading ?
                        <Loader small />
                        :
                        <span>   {I18n.t('button.SELECT')}</span>
                    }

                  </button>
                </Col>
              </Row>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default withRouter(Filters);
