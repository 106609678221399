const it = {
  brand: 'ePRM',
  AUTHENTIFICATION_EXPIRED: 'Your session has expired. Please login again',
  aside: {
    nav: {
      WORKPLACE: 'WORKPLACE',
      ADMIN: ' ADMINISTRATION',
      SHORTCUT: 'RESSOURCES',
      DATABASE: 'DATABASE',
      HOME: 'Home',
      USERS: 'Users',
      ROLES: 'Roles',
      LOG: 'Log API',
      HISTORY: 'History',
      SYSTEM: 'System',
      SCHEDULED: 'Scheduled Tasks',
      LOGS: 'Logs levels',
      DASHBOARD: 'Dashboard',
      APPOINTMENTS: 'Appointments',
      PATIENTS: 'Patients',
      CASES: 'Cases',
      AVAILABILITIES: 'Availabilities',
      EXPORTS: 'Exports',
      OPERATIONS: 'Operations',
      USER_MANAGEMENTS: 'User management',
      ADVICE_CODING: 'Advice coding',
      PATHOLOGY_CODING: 'Pathology coding',
      PHARMACIES: 'Pharmacies',
      BUSINESS_UNIT: 'Business unit',
      INTERNAL_BENFICIARY: 'Internal Beneficiary',
      B_PARTNER: 'B-Partner',
      LOGO: 'Logo',
      PROTOCOL: 'Protocol',
      LEGAL_ENTITY: 'Legal entity',
      BRAND: 'Brand',
    },
  },
  lastDocuments: {
    CONSULT: 'Tele consultation',
    ADVICE: 'Tele advice',
    formatDate: 'DD/MM/YYYY',
    DOWNLOAD: 'Download',
    caseNumber: 'Case n°',
    Prescription: 'Prescription',
    Report: 'Report',
    PatientDocument: 'Patient document',
    SEND: 'Secure link',
  },
  channel: {
    VIDEO: 'Video',
    PHONE: 'Phone',
    CHAT: 'Chat',
  },
  role: {
    NURSE: 'Nurse',
    DOCTOR: 'Doctor',
    NURSE_LEVEL: 'Nurse', // TODO : à virer !!
    DOCTOR_LEVEL: 'Doctor', // TODO : à virer !!
    ADMIN: 'Admin',
  },
  button: {
    TAKE_IN_CHARGE: 'Take in charge',
    TITLE_POPIN: 'Take in charge',
    OPEN_CASE: 'Open the case',
    CONSULT_ONLY: 'Consult only',
    CANCEL: 'Cancel',
    CONTINUE_TRANSFER: 'Continue',
    END_CALL: 'End call',
    SEARCH: 'Search',
    ARCHIVE: 'Archive',
    CONTINUE: 'Continue',
    PREVIOUS: 'Previous',
    BACK_TO_DASHBOARD: 'Back to dashboard',
    SKIP_ELIGIBILITY: 'Skip eligibility',
    CREATE: 'Create a new one',
    ABORT: 'Abort',
    DOWNLOAD: 'Download',
    EDIT: ' Edit',
    SEND: 'Send',
    NEXT: 'Next',
    SAVE: 'Save',
    NEW_PATIENT: 'Create a new patient',
    VALIDATE: 'Validate',
    SEND_VIDEO_LINK_EMAIL: 'Send video link',
    START_VIDEO: 'Start video',
    DELETE: 'DELETE',
    UPDATE: "UPDATE",
    RESET: "RESET",
    DETAILS: "DETAILS",
    IMPORT: "IMPORT",
    TEMPLATE: "TEMPLATE"
  },
  communicationType: {
    video: 'Video',
    phone: 'Phone',
    chat: 'Chat',
  },
  activeStatus: {
    ACTIVE: 'Opened',
    INACTIVE: 'Archived',
    CLOSED: 'Closed',
  },
  loader: {
    text: 'Please wait while loading the case list...',
  },
  dashboard: {
    TITLE: 'My dashboard',
    AVERAGE_WAIT: 'Patient waiting time',
    MINUTES: 'minute(s)',
    OPENED_CASES: '%{count} opened case',
    OPENED_CASES_0: '%{count} opened case',
    OPENED_CASES_plural: '%{count} opened cases',
    NEW_CASE: 'new case',
    filters: {
      TITLE: 'filters',
      VIEW: 'View',
      ASSIGNED: 'Assigned to',
      CALL_TYPE: 'Call type',
      SPECIALITY: 'Speciality',
      APPOINTMENT: 'Appointment',
    },
    NOT_APPOINTMENT_TIME: 'It\'s not the time yet.',
    NO_SESSION: 'Waiting for the patient to start the session.',
    WAITING_SINCE: 'Wainting since',
    CASE_STATUS_NURSE_REQUIRED: 'Nurse required',
    CASE_STATUS_DOCTOR_REQUIRED: 'Doctor required',
    CASE_STATUS_ASSIGNED: 'assigned',
    ASSIGNED_TO: 'Assigned to',
    CASE_STATUS_TAKEN: 'taken in charge',
    TAKEN_IN_CHARGE_BY: 'Taken in charge by',
    modal: {
      TAKE_IN_CHARGE: 'Are you sure you want to take over this case? You will appear to be working on it.',
    },
  },
  header: {
    PROFILE: 'Profile',
    LOGOUT: 'Logout',
    availability: {
      AVAILABLE: 'Available',
      BUSY: 'Busy',
    },
  },
  caseStatus: {
    NOT_AVAILABLE: 'Not available',
    WAITING: 'Waiting',
    IN_PROGRESS: 'In progress',
    COMPLETED: 'Completed',
    DROPPED: 'Dropped',
    NOT_STARTED: 'Session not started',
  },
  filterBar: {
    CHAT: 'Chat',
    PHONE: 'Phone',
    VIDEO: 'Video',
    ALL: 'All',
    LATER: 'Later',
    TODAY: 'Today',
    DOCTOR_LEVEL: 'Doctor',
    NURSE_LEVEL: 'Nurse',
  },
  processCaseHeader: {
    CLOSE: 'Close',
    TITLE: 'Patient Case',
    AUTO_SAVED: 'Everything is saved !',
    AUTO_SAVING: 'Saving...',
    YEARS: 'yo ',
    ASSIGNED: 'Assigned to',
    TAKEN_IN_CHARGE: 'Taken in charge by',
    options: {
      ASSIGN: 'Assign to',
      TAKE: 'Take in charge',
    },
    modal: {
      ASSIGNED_TITLE: 'Assign a caregiver for this file',
      ASSIGNED_TEXT: 'You can select a caregiver to be assigned to this file',
      USERS: 'Caregiver',
      BUTTON: 'Assign',
    },
    backToDashboard: {
      BACK_TITLE: 'Back to dashboard',
      BACK_TEXT: 'Do you really want to go back to the dashboard?',
    },
    steps: {
      IDENTIFICATION: 'Identification',
      ELIGIBILITY: 'Eligibility',
      PATIENT_DETAILS: 'Patient details',
      CONSENTS: 'Consents',
      QUALIFICATION: 'Call qualification',
      TELECONSULTATION: 'Teleconsultation',
      DOCUMENTS: 'Send documents',
    },
  },
  processCase: {
    identification: {
      SELECT_BPARTNER: '1. Select the type of B-Partner : ',
      SELECT_PROTOCOL: 'Select the protocol to use for the case : ',
      CHOOSE_PATIENT: 'Patient choice',
      SELECT_PATIENT: 'Please select a patient for treatment of the case : ',
      NEW_POLICY: 'Add new policy',
      NEW_POLICY_QUESTION: 'This BIN not found under this patient:',
      NEW_PATIENT: 'The above information does not match the patient you are looking for ? You can create a new patient : ',
      PLACEHOLDER_BP: 'Select the protocol',
      EXPLANATION: 'You are in touch with a patient. Before you search for the patient in the system, ask him the following questions :',
      TELECONSULT_QUESTION: '1. Are you calling for a teleconsultation?',
      BENEFICIARY_QUESTION: '2. Are you calling for yourself?',
      CALLER_INFORMATION_QUESTION: '3. Could you please give your identity information ?',
      BANK_CARD_HOLDER_QUESTION: '3. Are you the bank card holder?',
      VISA_CALLER_INFORMATION_QUESTION: '2. Could you please give your identity information ?',
      RELATIVE_INFORMATION_QUESTION: '3. Could you please give me the patient identity information?',
      ERROR_TITLE: 'Message to the patient',
      ERROR_MESSAGE: 'The number you have dialled is Allianz\'s teleconsultation service number, we cannot help you outside this scope.',
      CONFIRM_ADDING_NEW_POLICY_MESSAGE: 'This patient already exists with another BIN number.',
      CONFIRM_ADDING_NEW_POLICY_MESSAGE2: 'Upon your validation, the BIN %{binNumber} will be recorded.',
      BANK_CARD_IS_INVALID: 'Your bank card does not include teleconsultation.',
    },
    eligibility: {
      result: {
        NOT_EXIST_AND_FOUND: 'We found an eligible policy in Aid@ only and not in the ePRM. Please choose an insurance policy to continue and create the patient in the ePRM. ',
        EXIST_AND_ELIGIBLE: 'We have found one or more eligible patients corresponding to your information. Please choose an insurance policy to continue.',
        EXIST_AND_NOT_ELIGIBLE: 'Sorry, teleconsultation service is not included in the contract identified below',
        EXIST_AND_EXPIRED: 'The patient has been found, but the contract to which it is attached is no longer valid',
        EXIST_AND_OPEN_CASES: 'We found open case(s) corresponding to your information in the ePRM. Please choose one case to continue or create a new one.',
        NOT_EXIST_AND_NOT_ELIGIBLE: 'We found no patient and no policy holder matching the following information:',
        WARNING_TOO_LARGE: 'Warning, your search is not precise enough, only the first results are reported',
        WARNING_TOO_MANY_PATIENTS: 'Warning, your search is not precise enough, only the results of the first ten patients of the ePRM are reported',
        WARNING_ONLY_PRM: 'Warning, your search only concerns patients present in the ePRM. To also test the patient\'s eligibility, please enter the patient\'s first and last name, date of birth or policy number.',
        WARNING_GATEWAY_DOWN: 'Due to a technical problem, the patient\'s eligibility could not be verified. It is possible to skip the patient\'s eligibility to continue the consultation.',
      },
      EXPLANATION_COLLECT_INFO: 'To check the patient eligibility, please ask for the following information:',
      question: {
        IS_POLICY_HOLDER: '2. Is the patient the policy holder?',
        CHECK_SPELLING: '1. Are you sure of the spelling of the information used? Are they written as in the insurance contract?',
        CHANGE_INFORMATION: '2. Please modify the patient information and initiate a new search',
      },
      SKIP_ELIGIBILITY: 'Are you sure you want to skip the eligibility process ?',
      ELIGIBLE: 'Eligible',
      NOT_ELIGIBLE: 'Not eligible',
      START_DATE: 'Begins on ',
      EXPIRATION_DATE: 'Expired by ',
      ABORT_TEXT: 'Do you really want to go back to the dashboard?',
    },
    UNDER_18: 'The patient is under 18, please ask for his family record',
    FOREIGN_CALL: 'Be careful: the patient calls from abroad',
  },
  form: {
    CM_CIC: 'Crédit mutuel/CIC',
    OTHER: 'Other',
    NAME: 'Name',
    FIRST_NAME: 'First name',
    DATE_OF_BIRTH: 'Date of birth',
    POLICY_NUMBER: 'Policy number',
    BIN_NUMBER: 'First 9 digits of bank card number',
    PATIENT_NAME: 'Patient name',
    BANK_CARD_HOLDER_FIRST_NAME: 'Bank card holder first name',
    BANK_CARD_HOLDER_LAST_NAME: 'Bank card holder last name',
    YES: 'Yes',
    NO: 'No',
    OR: 'Or',
    STAR_USE: 'You can use the * to search a compound name',
    DATE_IN_FUTURE: 'Date of birth is in the future',
    validator: {
      DATE_IN_FUTURE: 'The date is in the future',
      DATE_FORMAT: 'The date is not in the correct format',
      DATE_COMPARE: 'La data di fine è precedente alla data di inizio.',
      VALIDATE_EMAIL: 'Invalid email address',
      VALIDATE_LIST: 'Invalid list empty',
      VALIDATE_PHONE: 'Incorrect telephone number',
      VALIDATE_PHONE_NO_0: 'Their cannot be a 0 next to the country code',
      REQUIRED: 'Required',
      TOO_SHORT: 'The search must be at least three characters long',
      VALIDATE_IDENTICAL_VALUES: 'To improve security, please choose different recipients for the secure link and the OTP.',
      VALIDATE_JUST_ONE_VALUES: 'Do not use two transfer means for the same information.',
      VALIDATE_SLOT_DURATION: 'Uno slot deve durare almeno: %{duration} min',
      START_DATE_BEFORE_NOW: 'La data di inizio non deve essere anteriore a quella odierna',
      START_DATE_TO_EARLY: 'Appointments cannot be made before : %{startMaxDate}:00',
      END_DATE_TO_LATE: 'Appointments cannot be made after : %{endMaxDate}:00',
      CONFIRM_PASSWORD: 'Passwords \'Password\' and \'Password confirmation\' must match',
      REQUIRED_DIGITS: 'Required %{digitValue} digits',
    },
    placeholder: 'DD/MM/YYYY',
  },
  login: {
    ePRM: 'ePRM',
    IDENTIFIER: 'Username',
    PASSWORD: 'Password',
    TITLE: 'Please sign in',
    SIGNIN: 'Login',
    FORGOT_PASSWORD: 'Forgot your password?',
    sms: {
      CODE: 'Please enter the code received by SMS',
      OTP: 'Code',
      NEXT: 'Next',
    },
    email: {
      CODE: 'Please enter the code received by email',
      OTP: 'Code',
      NEXT: 'Next',
    },
    alternatives: {
      EMAIL: 'Receive the code by email ?',
      SMS: 'Receive the code by phone ?',
    },
    enroll: {
      TITLE: 'Trust your browser',
      INSTRUCTIONS: 'Do you trust your browser ?',
      YES: 'Yes',
      NO: 'No',
    },
  },
  users: {
    TITLE: 'Users',
    SEARCH_TITLE: 'Search user',
    LIST_TITLE: 'User list',
    USERNAME: 'Username',
    EMAIL: 'Email',
    FIRSTNAME: 'First name',
    LASTNAME: 'Last name',
    PASSWORD: 'Password',
    PASSWORD_CONFIRM: 'Password confirmation',
    BUSINESS_UNIT: 'Business Unit',
    ROLE: 'Role',
    TYPE: 'Type',
    PHONE: 'Phone',
    TWO_FACTOR: 'Dual-factor authentication',
    type: {
      NURSE: 'Nurse',
      DOCTOR: 'Doctor',
    },
    CREATION_DATE: 'Creation date',
    NEW_TITLE: 'User creation',
    EDIT_TITLE: 'Editing user %{username}',
    DELETE_CONFIRM: 'Delete the user?',
  },
  roles: {
    TITLE: 'Roles',
    LABEL: 'Name',
    PERMISSIONS: 'Permissions',
    ENABLED: 'Actives permissions',
    AVAILABLE: 'Permissions available',
    LIST_TITLE: 'Roles',
    DETAIL_TITLE: 'Editing role %{label}',
    NEW_TITLE: 'Role creation',
    DELETE_CONFIRM: 'Delete the role?',
  },
  history: {
    TITLE: 'History',
    SEARCH_TITLE: 'Search logs',
    LIST_TITLE: 'List of logs',
    USERNAME: 'Username',
    ID_ITEM: 'Item id',
    TABLE: 'Table',
    MESSAGE: 'Message',
    MODIFICATION_TYPE: {
      TITLE: 'Modification type',
      CREATE: 'Create',
      UPDATE: 'Update',
      DELETE: 'Delete',
    },
    DATE: 'Date',
  },
  scheduled: {
    TITLE: 'Planned tasks',
    TASKNAME: 'Task name',
    FREQUENCY: 'Frequency',
    EXECUTIONS: 'Number of executions',
    PREVIOUS_START: 'Last execution start',
    PREVIOUS_END: 'Last execution end',
    NEVER_EXECUTED: 'Never executed yet',
    DURATION: 'Execution time: %{duration} ms',
    NEXT: 'Next execution',
    STATUS: 'Status',
    START: 'Start the task',
    threads: {
      TITLE: 'Threads statistics',
      ACTIVE: 'Number of active threads: ',
      INACTIVE: 'Number of inactive threads: ',
      MIN: 'Minimum threads number: ',
      MAX: 'Maximum threads number: ',
      LARGEST_POOL: 'Largest pool size: ',
    },
    DATE_FORMAT: 'MM-DD-YYYY kk:mm:ss',
  },
  logs: {
    TITLE: 'Logs manager',
    ADD_TITLE: 'Add log',
    SEARCH_TITLE: 'Search log',
    DEFAULT_TITLE: 'Default logs',
    CHANGED_TITLE: 'Changed logs',
    NAME: 'Log name',
    LEVEL: 'Level',
    EMPTY: 'No logs',
    SELECT_TRACE: 'Trace',
    SELECT_OFF: 'Off',
    SELECT_INACTIVE: 'Inactive',
    SELECT_INFO: 'Info',
    SELECT_DEBUG: 'Debug',
    SELECT_ERROR: 'Error',
    SELECT_WARN: 'Warn',
  },
  actions: {
    BACK: 'Back',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    DELETE: 'Delete',
    NEW: 'New',
    CONFIRM: 'Confirm',
    SEARCH: 'Search',
    ADD: 'Add',
    CREATE_APPOINTEMENT: "Creare l'appuntamento",
    REDIRECT_TO_RELATED_CASE: "Essere reindirizzati al relativo casoé",
    CONTINUE_TELECONSULT: 'Continua il teleconsulto in corso',
  },
  message: {
    SUCCESS: 'Changes have been successfully saved',
    ERROR: 'An error append while saving changes',
  },
  wsError: {
    INTERNAL_ERROR: 'Connection error',
    AUTHENTICATION_EXPIRED: 'Administration session has expired',
    FIELD_REQUIRED: 'Field \'%{field0}\' is required',
    COLOR_INVALID: 'Field \'%{field0}\' must be an hexadecimal color: #ff00ff',
    EMAIL_INVALID: 'Email address \'%{field0}\' is invalid',
    USERNAME_ALREADY_EXISTS: 'This username is already used, please choose another one',
    EMAIL_ALREADY_EXISTS: 'This email address is already used, please choose another one',
    PASSWORDS_DIFFERENT: 'Passwords \'%{field0}\' et \'%{field1}\' must match',
    WRONG_LOGIN_OR_PASSWORD: 'The username or password is incorrect',
    TOO_MANY_WRONG_ATTEMPS: 'Due to login attempt errors, your account is locked for %{field0} minutes, please try again later',
    ROLE_LABEL_EXISTS: 'A role already exists with this label',
    PASSWORD_TOO_SHORT: 'The password is too short, it must be at least %{field0} characters long',
    MESSAGE: '%{field0}',
    OTP_INVALID: 'Invalid code',
    RESET_PASSWORD_UNAVAILABLE: 'Contact the administrator to reset your password.',
    RATE_LIMITED: 'Please wait before retrying.',
    RELOAD_PAGE: 'Please reload page and retry.',
    BPARTNER_NOT_FOUND_FOR_BU: 'This case BPartner was not found for this BU',
    BPARTNER_CODE_HAS_EXIST: 'B-Partner code has exist',
    CONTRACT_HAS_EXIST: 'This policy already exists',
    INVALID_BENEFICIARY: 'This beneficiary is invalid',
    DATA_NOT_FOUND: 'Not found patient or policy',
    EMAIL_TEMPLATE_NOT_FOUND: 'Found Patient and policy data but not found email template',
    SMS_TEMPLATE_NOT_FOUND: 'Found Patient and policy data but not found sms template',
    EMAIL_MISSING: 'Found Patient and policy data but thers is not patient\'s email',
    PHONE_NUMBER_MISSING: 'Found Patient and policy data but thers is not patient\'s phone number',
    DUPLICATED_PROTOCOL: 'Protocol number has exist',
    BUSINESS_UNIT_NOT_FOUND: 'Business unit not found',
    BRAND_NOT_FOUND: 'Brand unit not found',
    LOGO_NOT_FOUND: 'Logo unit not found',
    LEGAL_ENTITY_HAS_EXIST: 'Legal entity has exist',
    PROTOCOL_NOT_FOUND: 'Protocal not found!',
  },
  apiLog: {
    TITLE: 'Logs',
    request: 'request',
    response: 'response',
    header: 'Header',
    body: 'Body',
    apiFilter: 'Filter by API names :',
    statusCodeFilter: 'Filter by status :',
    download: 'download complete text',
    noContent: 'no content',
    empty: 'This API does not content headers nor bodies for this request. ',
    emptyBody: 'empty body',
    emptyHeader: 'empty header',
  },
  date: {
    long: 'MMMM Do, YYYY HH:mm',
    DATE_START: 'Start date',
    DATE_END: 'End date',
    DATE_FORMAT: 'DD/MM/YYYY',
    DATE_FORMAT_ADMINISTRATION_FILE: 'YYYY-MM-DD',
    DATE_HOUR: 'HH:mm:ss',
    DATE_HOUR_SHORT: 'HH:mm',
    DATE_LONG: 'DD/MM/YYYY HH:mm:ss',
  },
  reactTable: {
    EMPTY: 'Empty',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    OF: 'of',
    ROWS: 'rows',
  },
  businessUnit: {
    TITLE: 'BusinessUnit',
    TITLE_LIST: 'Business unit list',
    SEARCH_TITLE: 'Search BusinessUnit',
    SUBTITLE: 'BusinessUnit List',
    NEW_TITLE: 'New BusinessUnit',
    EDIT_TITLE: 'Edit BusinessUnit',
    TITLE_CONFIGURATION: 'Configuration',
    TITLE_ADMIN: 'Administrator',
    TITLE_CREATE: 'Business unit creation',
    languages: {
      ENGLISH: 'EN',
      FRENCH: 'FR',
      SPANISH: 'ES',
      DEUTSH: 'DE',
      ITALIAN: 'IT',
    },
    modal: {
      title: 'Delete Business Unit',
      message: 'Confirm suppression ?',
    },
    label: {
      // TODO : mettre les bons labels
      name: 'Name',
      businessUnitName: 'Business unit name',
      prmId: 'PrmId',
      canSendByFax: 'In the page "Sending documents", possibility to send them by fax to a pharmacy',
      canAccessCmCic: 'From the identification page, access to the choice of the B-Partner Crédit Mutuel/CIC',
      canCreatePatientInIdentificationPage: 'From the identification page, choice between creating or retrieving an existing patient',
      canResendMailVideo: 'Possibilità di inviare nuovamente al paziente l\'e-mail contenente il link video (menu a destra nel processo di creazione della cartella)',
      showChat: 'Display the chat in the action bar of the "Call Qualification" and "Teleconsultation" pages',
      showIdentification: 'Display the identification step',
      showLogoInDocument: 'Display the bPartner logo in documents: teleconsultation, teleadvice, prescription',
      showNewCaseButtonInDashboard: 'New case button in dashboard',
      showQualification: 'Display the "Call Qualification" step',
      showTeleAdviceReport: 'Display the summary of the qualification stage in the teleconsultation page',
      showViewFilterInDashboard: 'View filter in dashboard',
      timezone: 'Timezone',
      prmIdCountry: 'Prm id country',
      doctorShouldCallback: 'On the dashboard, specific display: 1. waiting time and flashing for cases without an appointment and with a phone to encourage the doctor to call the patient back // 2. For a folder without an appointment, it should flash until a doctor takes over // 3. For a folder with an appointment, it should flash and take into account the new assignment',
      recordingsSentToAida: 'Sending lock time to Aid@',
      reportAddressedToPatient: 'The teleconsultation report is addressed to the patient and not to a physician colleague',
      sendNotificationWhenCaseCreated: "Sends an SMS and an email to all connected doctor when creating a new case by appointment in the dashboard from the PDA (Email would be sent for appointment case only.)",
      sendSmsWhenCaseCreated: 'Sends an SMS to all connected users when creating a new folder in the dashboard',
      sendNotificationPatientWaitingInitial: "Sends an SMS and email for each case to all connected doctor as patient start waiting in Chat or Video call and for an appointment ( appointment time must be passed and patient is waiting in the dashboard)",
      sendNotificationPatientWaitingGlobal: "Sends an SMS and email to all connected doctor for every 5 minutes if there is at least 1 case which patient is waiting in Chat or Video call and for an appointment ( appointment time must be passed, patient is waiting in the dashboard",
      showAddressInDocument: 'Displays the organization\'s address in the teleconsultation report',
      showEmailServiceMedicalReport: 'Displays the organization\'s email address in the teleconsultation report',
      showHospitals: 'Displays the results of the search for selected hospitals in the teleconsultation report',
      showNonMedicationNotesFirst: 'In the teleconsultation report, when displaying the content of the prescriptions, first display the additional notes and then the list of medications.',
      useParentingForCodings: 'Display the parent code of the pathology code in the pathology code administration page in addition to the pathology code (behavior retrieved from PRM)',
      useOneProtocolAndPolicyForTreatingCases: 'Use only one protocol and policy for treating cases',
      showOneConsentInConsentPage: 'Show one consent to check in the page Consents',
      makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories: 'Make no mandatories fiels : Diagnostic coding and ICD10 coding',
      emailFooterTakeInLegalEntityObject: 'Display the footer present in the object "legal entity" which is linked to the protocol',
      showAlertWhenNoMedicalReport: 'Show error alert when the medical report is not created in the Teleconsultation page',
      checkEligibilityByVisa: 'Enable VISA partner when creating new case',
      countryCode: 'Country code',
      countryName: 'Country name',
    },
    TABLE: {
      BUSINESS_UNIT_NAME: 'Business unit name',
      BUSINESS_UNIT: 'Business unit',
      COUNTRY: 'Country name',
      COUNTRY_CODE: 'Country code',
      ZONE: 'Timezone',
      STATUS: 'Status',
      PAGINATION_INFO: 'Showing %{start} to %{end} of %{total} entries',
    },
    STATUS: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
    },
    button: {
      ADD: 'Add',
      EXPORT: 'Export',
    },
    export: {
      businessUnit: 'Business unit name',
      countryCode: 'Country code',
      country: 'Country name',
      createdOn: 'Creation date',
      timeZone: 'Time zone',
      canSendByFax: 'In the page "Sending documents", possibility to send them by fax to a pharmacy',
      canAccessCmCic: 'From the identification page, access to the choice of the B-Partner Crédit Mutuel/CIC',
      canCreatePatientInIdentificationPage: 'From the identification page, choice between creating or retrieving an existing patient',
      canResendMailVideo: 'Possibility to resend the email containing the video link to the patient (Right menu in the case creation process)',
      showChat: 'Display the chat in the action bar of the "Call Qualification" and "Teleconsultation" pages',
      showIdentification: 'Display the identification step',
      showLogoInDocument: 'Display the bPartner logo in documents: teleconsultation, teleadvice, prescription',
      showNewCaseButtonInDashboard: 'New case button in dashboard',
      showQualification: 'Display the "Call Qualification" step',
      showTeleAdviceReport: 'Display the summary of the qualification stage in the teleconsultation page',
      showViewFilterInDashboard: 'View filter in dashboard',
      doctorShouldCallback: 'On the dashboard, specific display : 1. waiting time and flashing for cases without an appointment and with a phone to encourage the doctor to call the patient back // 2. For a folder without an appointment, it should flash until a doctor takes over // 3. For a folder with an appointment, it should flash and take into account the new assignment',
      recordingsSentToAida: 'Sending lock time to Aid@',
      reportAddressedToPatient: 'The teleconsultation report is addressed to the patient and not to a physician colleague',
      sendNotificationWhenCaseCreated: "Sends an SMS and an email to all connected doctor when creating a new case by appointment in the dashboard from the PDA (Email would be sent for appointment case only.)",
      sendSmsWhenCaseCreated: 'Sends an SMS to all connected users when creating a new folder in the dashboard',
      showAddressInDocument: 'Displays the organization\'s address in the teleconsultation report',
      showEmailServiceMedicalReport: 'Displays the organization\'s email address in the teleconsultation report',
      showHospitals: 'Displays the results of the search for selected hospitals in the teleconsultation report',
      showNonMedicationNotesFirst: 'In the teleconsultation report, when displaying the content of the prescriptions, first display the additional notes and then the list of medications.',
      useParentingForCodings: 'Display the parent code of the pathology code in the pathology code administration page in addition to the pathology code (behavior retrieved from PRM)',
      useOneProtocolAndPolicyForTreatingCases: 'Use only one protocol and policy for treating cases',
      showOneConsentInConsentPage: 'Show one consent to check in the page Consents',
      makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories: 'Make no mandatories fiels : Diagnostic coding and ICD10 coding',
      emailFooterTakeInLegalEntityObject: 'Display the footer present in the object "legal entity" which is linked to the protocol.',
      showAlertWhenNoMedicalReport: 'Show error alert when the medical report is not created in the Teleconsultation page',
    },
  },
  changeTaken: {
    title: 'Change in the takeover',
    text: 'Be careful, %{username} is the new person in charge of this file.',
  },
  administration: {
    TITLE: 'Administration file',
    MANDATORY: '*: mandatory fields',
    PLEASE: 'Please, check and fill patient information',
    identity: {
      TITLE: 'Identity',
      CIVILITY: 'Civility',
      M: 'Mr', // Mister
      MME: 'Mrs', // Missus
      FIRSTNAME: 'Firstname',
      LASTNAME: 'Lastname',
      BIRTHNAME: 'Birth name',
      EMAIL: 'Email',
      EMAIL_BOOLEAN: 'The patient doesn\'t have any email adress',
      PHONE_NUMBER: 'Phone',
      RESEND_PDA_LINK: 'Resend PDA link',
      birth: {
        TITLE: 'Birth',
        BIRTHDATE: 'Date of birth',
        COUNTRY: 'Country of birth',
        CITY: 'City of birth',
      },
      address: {
        TITLE: 'Address',
        STREET: 'Street',
        POSTCODE: 'Postcode',
        CITY: 'City',
        COUNTRY: 'Country',
        DEPARTMENT: 'Department',
      },
    },
    lastDocuments: {
      history: 'Historique des anciennes consultations',
      total: 'Total number of',
      advice: 'TELECONSEILS',
      consult: 'TELECONSULTATIONS',
      showAll: 'SHOW ALL',
      SEND: 'Secure link',
      onRollingMonths: 'Teleconsutations on this protocol (over 12 rolling months)',
      nbOfTeleconsultRollingMonthsForProtocol: 'Number of teleconsultations over 12 months rolling for the protocol : ',
    },
    doctor: {
      TITLE: 'Treating doctor',
      FIRSTNAME: 'Firstname',
      LASTNAME: 'Lastname',
      STREET: 'Street',
      POSTCODE: 'Postcode',
      CITY: 'City',
      EMAIL: 'Email',
      PHONE: 'Phone',
      COUNTRY: 'Country',
    },
    insurance: {
      TITLE: 'Insurance',
      SOCIAL_SECURITY_NUMBER: 'Social security number',
    },
    policy: {
      TITLE: 'Policy holder',
      policyHolder: {
        TITLE: 'Policy information',
        FIRSTNAME: 'Firstname',
        LASTNAME: 'Lastname',
        CITY: 'City',
        PHONE_NUMBER: 'Phone number',
      },
      beneficiary: {
        TITLE: 'Beneficiary',
        FIRSTNAME: 'Firstname',
        LASTNAME: 'Lastname',
        BIRTHDATE: 'Birtbay',
      },
      policy: {
        TITLE: 'Policy',
        POLICY_NUMBER: 'Policy number',
        PROTOCOL_NUMBER: 'Protocol number',
        POLICY_HOLDER: 'Policy holder',
        BENEFICIARY: 'Beneficiary',
        BRAND: 'Brand',
        LEGAL_ENTITY: 'Legal entity',
        PDA_ACCOUNT: 'PDA account',
        YES: 'Yes',
        NO: 'No',
      },
    },
  },
  medicalProfile: {
    title: 'MEDICAL PROFILE',
    weight: 'W',
    height: 'H',
    BMI: 'BMI',
    weight_mesure: 'kg',
    height_mesure: 'cm',
    SEX: {
      1: 'Male',
      2: 'Female',
    },
    AGE: '%{age}yo',
    total: 'Total number of',
    teleAdvice: 'Tele advice',
    teleConsultation: 'Tele consult',
    show: 'Show all',
    lastModified: 'Dates of last documents modified',
    informationsMedicalProfile: {
      medicalHistory: 'Medical history',
      treatment: 'Ongoing treatment',
      allergies: 'Allergies',
      chronicalDisease: 'Chronical disease',
      vaccination: 'Immunization status',
      lifeStyle: 'Lifestyle',
    },
    lastConsultations: {
      history: 'History of past consultations',
      total: 'Total number of',
      title: 'Number of cases created',
      advice: 'Number of TELEADVICE',
      consult: 'Number of TELECONSULTATION',
      showAll: 'SHOW ALL',
      onRollingMonths: 'Number of Teleconsutations on this protocol (over 12 rolling months)',
    },
    datesOfLastDocCreated: {
      title: 'Date of the last documents created',
      advice: 'Last advice report:',
      consult: 'Last medical report:',
    },
    lastDocumentsDrawer: {
      title: 'Last consultations',
      backTo: 'Back to list',
    },
  },
  historyModifications: {
    title: 'History modification',
    newValue: 'New value',
    oldValue: 'Old value',
    addressRecipient: 'Recipient(s)',
    content: 'Content',
    reasonForTheCall: 'Reason for call',
    countryLocalisation: 'Country of the call',
    reasonToCallNonMed: 'Reason for non-medical call',
    caseQualification: 'Qualification of the call',
    beneficiaryBirthday: 'Date of birth of the beneficiary',
    beneficiaryFirstName: 'First name of the beneficiary',
    beneficiaryLastName: 'Name of the beneficiary',
    policyHolderLastName: 'Name of policy holder',
    partnerName: 'B-Partner name',
    protocolNumber: 'Protocol number',
    gender: {
      1: 'Man',
      2: 'Woman',
    },
    adviceNote: 'Telephone advice note',
    localAdviceCoding: 'Local coding of the teleconference',
    teleconsultNotes: 'Teleconsultation notes',
    doctorNotes: 'Physician\'s notes',
    icd10: 'Code ICD10',
    pathologyCoding: 'Pathological coding',
    policyHolderFirstName: 'First name of policy holder',
    medicalExam: 'Medical examination',
    medicalExamInstructions: 'Medical Examination Instructions',
    by: ' by ',
    placeholder: 'Keywords',
    videoSessionId: 'Session vidéo id',
    prmIdAssignedTo: 'Assigned to ',
    assignedTo: 'Assigned to ',
    takenInCharge: 'Taken in charge by',
    prmIdTakenInCharge: 'Taken in charge by',
    technicalIssue: 'Technical problem',
    awareEmergencyServices: 'Patient situation',
    orientationToEmergencyServices: 'Emergency services contacted',
    awareToContact: 'The patient is informed that he/she must contract emergencies',
    emergencyDetected: 'Emergency detected',
    emergencyAddressInfo: 'Emergency - More Information',
    emergencyAddressZipCode: 'Emergency - Postal Code',
    emergencyAddressRegion: 'Emergency - Department',
    emergencyAddressCountry: 'Emergency - Country',
    emergencyAddressCity: 'Emergency - City',
    emergencyAddressLine: 'Emergency - Address',
    1: 'The patient is in France',
    2: 'The patient is abroad',
    3: 'The patient is in Spain',
    technicalIssueText: 'Text of the technical problem',
    codeCommunicationType: 'Communication type',
    benefit: 'Benefit not covered',
    general: 'General information',
    technical: 'Technical problem',
    assistance: 'Assistance without contract',
    complaint: 'Complaint',
    prm: 'PRM',
    EMAIL: 'Email',
    SMS: 'SMS',
    SECURE_LINK: 'Secure link',
    CODE: 'Code sent to:',
    LINK: 'Link sent to:',
    PHARMACY: 'Fax to a pharmacy',
    pharmacyName: 'Pharmacy name',
    pharmacyNumber: 'Pharmacy number',
    CREATE: 'Creation',
    UPDATE: 'Update',
    ARCHIVE: 'Archive',
    REOPEN: 'Reopen',
    medicalHistory: 'Medical history',
    DOCTOR_FIRSTNAME: 'Family Doctor firstName',
    DOCTOR_LASTNAME: 'Family Doctor lastName',
    DOCTOR_COUNTRY: 'Family Doctor country',
    DOCTOR_CITY: 'Family Doctor city',
    DOCTOR_POSTALCODE: 'Family Doctor postalCode',
    DOCTOR_ADDRESS: 'Family Doctor address',
    DOCTOR_EMAIL: 'Family Doctor email',
    DOCTOR_PHONE: 'Family Doctor phone',
    genderCode: 'Gender',
    firstName: 'firstName',
    lastName: 'lastName',
    birthName: 'birthName',
    emailAddress1: 'email',
    policyNumber: 'Policy number',
    noAdressMail: 'No email adress',
    RESEND_PDA_LINK: 'PDA Link resent',
    mobilePhone: 'Mobile phone',
    birthdate: 'BirthDate',
    birthCountry: 'Country',
    birthCity: 'City',
    address1Line1: 'Address',
    address1Postalcode: 'Postal code',
    address1City: 'City',
    address1Country: 'Country',
    treatments: 'Treatments',
    allergies: 'Allergies',
    chronicalDisease: 'Chronical diseases',
    vaccinations: 'Vaccinations',
    lifeStyle: 'LifeStyle',
    weight: 'Weight',
    height: 'Height',
    socialSecurityNumber: 'Social security number',
    DELETE: 'Deletion',
    true: 'True',
    false: 'False',
    283030000: 'Tele conseil',
    283030001: 'Tele consultation',
    sharingConsents: 'Consent to share data',
    treatmentConsent: 'Consent to data processing',
    familyDocConsent: 'Attending physician\'s authorization',
    statusCase: 'Case status',
    ACTIVE: 'Open',
    INACTIVE: 'Archived',
    CLOSED: 'Closed',
    MEDICINE: 'Medication',
    ADDITIONAL_NOTES: 'Additional notes',
  },
  contact: {
    start: {
      TITLE: 'Start',
      VIDEO: 'Video',
      CHAT: 'Chat',
      PHONE: 'Phone',
    },
    video: {
      TITLE: 'Confirmation of video link',
      TITLE_2: 'Gestione delle sessioni video',
      SEND_VIDEO_LINK_CONFIRMATION: 'Do you want to send an email with the video link to initiate the video consultation?',
      SEND_VIDEO_LINK_CONFIRMATION_OR_OPEN_VIDEO: 'È possibile inviare il link video al paziente per avviare il teleconsulto o avviare la sessione video.',

    },
    send: {
      TITLE: 'Send',
      EMAIL: 'Email',
      SMS: 'SMS',
      RDV: 'Appt.',
    },
    phone: {
      TITLE: 'Callback Phone Number',
      NO_PHONE_NUMBER: 'There is no Phone number link to this patient',
      CALLBACK_NUMBER: 'The patient callback phone number is %{number}.',
    },
    sms: {
      TITLE: 'Contact the patient',
      SEND: 'Send',
      RECIVER_NUMBER: 'The patient phone number used is %{number}.',
    },
    hospitalSearch: {
      TITLE: 'Search for a hospital',
      SEARCH: 'Search',
      LIST: 'Results list',
      address: 'Address :',
      contact: 'Contact : ',
      departments: 'Departments : ',
      sendMail: 'Send by email',
      sendSms: 'Send by sms',
      caseReport: 'Display in documents',
      mailConfirmationTitle: 'Confirmation of sending by mail',
      mailConfirmationContent: 'You are about to send the data by email. Please confirm.',
      smsConfirmationTitle: 'Confirmation of sending by sms',
      smsConfirmationContent: 'You are about to send the data by SMS. Please confirm.',
    },
    email: {
      TITLE: 'Contact by Email',
      SEND: 'Send',
      OBJECT: 'Object',
      EMAIL_CC: 'Email CC',
      EMAIL: 'Email Address',
    },
    MEDICAL_RESOURCES: 'Medical Resources',
    medicalResources: {
      TITLE: 'Medical Resources',
      MEDICAL_SEARCH: 'Medical search',
      VARIOUS: 'Various',
      TRAVELLERS: 'travellers',
      MEDICATION: 'Medication',
      SEARCH_HOSPITAL: 'Search hospital',
    },
  },
  popinCreatePatient: {
    title: 'Information required for patient creation',
    subtitle: 'In order to send the T&C to the patient we need his email address and his phone number. The patient\'s creation will not be possible without this piece of information.',
  },
  consent: {
    title: 'CONSENTS COLLECT',
    intro: 'Si prega di raccogliere il seguente consenso del paziente',
    titleTreatingAndSharing: 'Acconsente alla condivisione e all\'elaborazione dei dati',
    textTreatingAndSharing_v1: 'Per fornirle il servizio di teleconsulto, abbiamo bisogno del suo consenso a trattare i dati personali relativi alla salute. Il titolare del trattamento è AWP P&C S.A.. Potrà trovare il testo completo dell’informativa privacy all’indirizzo',
    textTreatingAndSharing_v2: 'Il consenso potrà essere revocato dandone comunicazione alla società. Acconsente il trattamento dei dati relativi alla salute per la finalità di erogazione del servizio di Teleconsulto?',
    textTreatingAndSharing: '  termini e condizioni  ',
    titleTreating: 'Trattamento dei dati di consenso',
    textTreating: 'Autorizzo i vari professionisti sanitari della piattaforma incaricati di erogare il servizio di teleconsulto / e costituendo il mio team di assistenza a consultare le informazioni mediche su di me raccolte e prodotte nell\'ambito dei servizi della piattaforma.',
    titleSharing: 'Consenso alla condivisione dei dati',
    textSharing: 'Acconsento alla raccolta e al trattamento dei miei dati sanitari personali per poter usufruire del servizio di teleconsulto e dei servizi associati accessibili dalla piattaforma.',
    give: 'Do il mio consenso',
    giveNot: 'Non do il mio consenso',
    create: 'Create case',
    consentUnvalid: 'messaggio al paziente',
    consentUnvalidText: 'Il tuo consenso è necessario per poter effettuare un teleconsulto. Rifiutando il consenso, non posso continuare questa chiamata.',
    archived: 'Archive the case',
  },
  qualification: {
    reason: 'Reason of the call',
    country: 'Country of the call',
    category: 'Call category',
    viewHistory: 'View modification history',
    technicalProblem: 'I have a technical problem',
    qualificationTitle: 'Call qualification',
    textQualification: 'Regarding the information collected, qualify the call:',
    teleAdvice: 'TELE ADVICE',
    teleConsult: 'TELE CONSULT',
    teleAdviceNotes: 'Tele advice notes',
    teleAdviceCodification: 'Tele advice codification',
    continue: 'CONTINUE',
    transfer: 'TRANSFER TO A TELECONSULTATION',
    modalTitle: 'Before continuing, check the consent for sending the medical report to family doctor :',
    modalText: 'I authorize the sending to my attending physician of the report of the teleconsultation from which I benefited',
    yes: 'YES',
    no: 'NO',
    modalDoctor: 'Please fill or check family doctor information:',
    reasonToCallNonMed: {
      technicalIssue: 'Technical issue',
      PRM: 'PRM',
      benefitNotCovered: 'Benefit non covered',
      generalInformation: 'General information',
      notContractedAssistance: 'Non-contracted assistance',
      complaint: 'Complaint',
    },
    ADVICE_REPORT: 'Advice report',
    NO_REPORT: 'No advice report yet',
    ADD_REPORT: 'Add a medical report',
    NEW_DOCUMENT_FROM_PDA: 'The patient uploads a document to the PDA.',
    MISSING_DIAGNOSTIC: 'Please select an advice codification to continue.',
    MISSING_DOCUMENT_FOR_FAMILY_DOCTOR: 'Please add a report document for the family doctor.',
    MISSING_MEDICAL_REPORT: 'Please add a medical report.',
  },
  teleconsultation: {
    EMERGENCY: 'emergency',
    QUALIFICATION_REPORT: 'Qualification report',
    relatedCase: {
      titleParent: 'Parent folder report ',
      titleChildren: 'Reports from related cases',
    },
    REASON_FOR_CALL: 'Reason for the call',
    SYMPTOM_FLAG: 'Symptom check flag',
    TELEADVICE_NOTES: 'Tele advice notes',
    DOCUMENTS: 'Documents',
    COUNTRY_OF_CALL: 'Country of the call',
    TYPE_OF_CALL: 'Call category',
    TELECONSULTATION_NOTES: 'Teleconsultation notes',
    SHARED: 'shared with the patient',
    PLACE_HOLDER: 'Fill in the notes of the teleconsultation',
    NOT_SHARED: 'not shared with the patient',
    DOCTOR_NOTES: 'Doctor personal notes',
    PRESCRIPTION: 'Prescriptions',
    NO_PRESCRIPTION: 'No prescription yet',
    ADD_PRESCRIPTION: 'Add a prescription',
    CODING: 'Diagnostic coding',
    ICD10_CODING: 'ICD10 coding',
    ENTER_ICD10: 'Enter the first letter of your ICD10',
    LOCAL_CODING: 'Local pathology coding',
    FINAL_ORIENTATION: 'Final orientation',
    FINAL_ORIENTATION_PHRASE: 'At the end of the interview, please qualify the call',
    END_CONSULTATION: 'End consultation',
    PHYSICAL_EXAM: 'Clinical exam',
    PHYSICAL_EXAM_TEXT: 'Additional notes for the physical exam (shared with the patient)',
    CREATE_RELATED_CASE: 'Create related case',
    MEDICAL_REPORT: 'Medical report',
    NO_REPORT: 'No medical report yet',
    ADD_REPORT: 'Add a medical report',
    MISSING_ICD10: 'Please select an ICD10 code to continue',
    MISSING_DOCUMENT_FOR_FAMILY_DOCTOR: 'Please add a medical report for the family doctor.',
  },
  medicine: {
    TITLE: 'Add a Medicine',
    SEARCH: 'Search medicine',
    HAVE_SELECTED: 'You have selected : ',
    dosage: {
      DOSAGE: 'Dosage',
      QUANTITY_PLACEHOLDER: 'Quantity',
      TIME_UNIT: 'per',
      TIME_PLACEHOLDER: 'Unit',
      DURATION: 'during',
      DURATION_QUANTITY_PLACEHOLDER: 'number',
      DURATION_TIME_UNIT_PLACEHOLDER: 'Unit',
    },
    time_unit: {
      DAY: 'day',
      WEEK: 'week',
      MONTH: 'month',
    },
    repetition: {
      DAY: 'day',
      NIGHT: 'night',
      MEAL: 'meal',
      WEEK: 'week',
      MONTH: 'month',
    },
    DETAIL_PLACEHOLDER: 'Dosage details / duration',
    ADD: 'Add',
    instruction: {
      TAKE: 'Take',
      DOSE: 'doses',
      BY: 'by',
      DURING: 'during',
    },
    noResult: 'No result for your search'
  },
  documentsToSend: {
    title: 'Documents',
    phrase1: 'To finish the treatment of the case, please selection the document to send and media.',
    thirdConsent: 'Be careful, the third consent has been given, you must send the medical report to the family doctor.',
    buttonArchive: 'Archive the case',
    textArchive: 'If you click on "Archive the case", all the documents above will be send to the PDA',
    sendBy: 'Send by : ',
    bySecureLink: 'By secure link',
    byPharmacy: 'By pharmacy',
    print: 'Print ',
    send: 'SEND',
    download: 'DOWNLOAD',
    creationDate: 'date of creation of the prescription',
    prescription: {
      title: 'Prescriptions',
      subtitle: 'Prescription %{count}',
    },
    medicalReport: {
      title: 'Medical report',
      subtitle: 'Medical report %{count}',
    },
    patientDocument: {
      title: 'Patient documents',
      subtitle: 'Patient documents %{count}',
    },
    sendToPharmacy: {
      listPharmacieTitle: 'List of pharmacies corresponding to the research:',
      title: 'Send to pharmacy',
      pharmacyResearch: 'pharmacy',
      subtitle: 'Before continuing, check the consent for sending the prescription fax consent :',
      authorize: 'I authorize',
      phraseEmail: ' 1. Please, check and fill the patient email',
      phrasePhone: ' 1. Please, check and fill the patient number:',
      warningOnModify: 'Warning, any modification of this field will result in a modification of the patient\'s general information.',
      email: 'Email',
      phone: 'Phone',
      phase2: '2. Please, select or add a pharmacy:',
      search: 'SEARCH A PHARMACY',
      name: 'Name',
      adress: 'Adress',
      city: 'City',
      coutry: 'Country',
      zipcode: 'Zipcode',
      add: 'Add a pharmacy',
      fax: 'Fax number',
      phoneNumber: 'Phone number: ',
      faxShort: 'Fax : ',
      phoneShort: 'Tel : ',
      internet: 'Google search',
      errorMessage: 'This pharmacie do not exist in our data base. You have to add it if you want send it',
      phase3: '3. Please find below the information selected. Check them and send the document',
      emailOfPatient: 'Email of Patient',
      phoneOfPatient: 'Phone of patient:',
      pharmacy: 'Pharmacy selected',
      pharmacySearchError: 'This pharmacie do not exist in our data base. You have to add it if you want send it',
    },
    sendBySecure: {
      title: 'Send via secure link',
      subtitle: 'Please, check and fill the following fields:',
      destination1: '1. Destination of the link',
      destination2: '2. Destination of the code',
      email: 'Email',
      phone: 'Phone',
      or: 'OR',
    },
  },
  reasonToCallNonMed: {
    benefit: 'Benefit non covered',
    general: 'General information',
    technical: 'Technical issue',
    assistance: 'Non-contracted assistance',
    complaint: 'Complaint',
    prm: 'PRM',
  },
  emergency: {
    TITLE: 'Emergency',
    CURRENT_COUNTRY: 'The patient declared he is currently in country: ',
    AWARE_TO_CONTACT: 'The patient is informed that he/she must contact the emergencies.',
    CURRENT_ADDRESS: 'Please check/fill the patient\'s current address: ',
    MORE_INFO: 'More information',
    LOCAL_EMERGENCY: 'Has local emergencies department been contacted?',
    CREATE: 'Create emergency',
  },
  techProblem: {
    TITLE: 'Technical Problem',
    LABEL: 'Explain your technical problem:',
    CREATE: 'Create technical problem',
  },
  editDocument: {
    LANGUAGE: 'Change language',
    TITLE_PRESCRIPTION: 'Prescription Details',
    TITLE_MEDICAL_REPORT: 'Medical Report',
    TITLE_ADVICE_REPORT: 'Advice report',
    TELEADVICE_REPORT_NUMBERING: 'Teleconsulting report',
    TELECONSULTATION_REPORT_NUMBERING: 'Medical teleconsultation report',
    ORDONNANCE: 'Order',
    BACK: 'Back to teleconsultation',
    DOCTOR: 'Doctor ',
    DR: 'Dr.',
    ID_NUMBER: 'N° RPPS: ',
    ADDRESS: 'Address',
    ORGANIZATION: 'Teleconsultation Operator: ',
    UNDER_NAME: 'Branded ',
    IMPLEMENTED_BY: 'Teleconsultation acts are implemented by',
    EMAIL: 'E-mail address',
    OPEN_HOURS: 'Service delivered 7 days a week, 24 hours a day',
    ATTENTION_OF: 'To the attention of :',
    LEGAL_REPRESENTATIVE: 'or his legal representative',
    FAMILY_DOCTOR: 'designated by the patient as his or her family doctor',
    FAMILY_DOCTOR_ADDRESS: 'Officer at the following address: ',
    DEAR_COLLEAGUE: 'Dear colleague',
    DEAR_DOCTOR: 'Dear doctor',
    DEAR_PATIENT: 'Dear patient',
    DESCRIPTION_CALL_1_M: 'Your patient, Mr. %{name}, ',
    DESCRIPTION_CALL_1_W: 'Your patient, Mrs %{name}, ',
    YEARS: ' years old ',
    MONTHS: 'months old ',
    DESCRIPTION_CALL_2: 'weighing %{weight}kg, ',
    DESCRIPTION_CALL_3: 'has used our teleconsultation service on %{callDay} to %{callHour}.',
    THANKS_FOR_THE_USE: 'Thank you for using the teleconsultation service, which took place the %{callDay} at %{callHour}.',
    SUMMARY_CALL: 'You will find below a report of our discussions and recommendations.',
    TO_BE_FOLLOWED: 'We ask him to come back to you for further care.',
    THE: 'the ',
    DATE: 'Date',
    PATIENT: 'Patient',
    SIR: ' Sir ',
    MADAM: ' Madam ',
    BIRTH_DATE: 'Birth Date',
    WEIGHT: 'Weight of the child in kg: %{weight}.',
    EMPTY_MEDICINE: 'There is no medicine yet',
    WARNING_ADDITIONAL_NOTES: 'Please note that the number of characters must not exceed 2000 characters.',
    ADD_MEDICINE: 'Add a drug',
    EDIT: 'Edit',
    EMPTY_NOTES: 'There are no additional notes yet',
    ADD_NOTES: 'Add notes',
    ADVICE: {
      CALL_TELECONSULTATION: 'Your patient, %{gender} %{firstName} %{lastName}, %{year} years old, used our teleconsultation service on the %{date} at %{hour}.',
      TO_BE_FOLLOWED: 'You will find below, a summary of our exchanges and the information provided.',
      TITLE_1: '1/ Reason for the call',
      TITLE_2: '2/ Summary of our discussions',
      NOTE: 'Notes',
      TECHNICAL_ISSUE: 'Technical incident during the call : %{value}',
      END: 'Please accept dear doctor our sincere greetings,',
      END_DOCTOR: 'Confraternally',
      YES: 'YES',
      NO: 'NO',
    },
    medicalProfile: {
      TITLE: 'Medical Profile',
      MEDICAL_HISTORY: 'Medical history',
      CURRENT_TREATMENT: 'Ongoing treatment',
      ALLERGIES: 'Allergy(s)',
      VACCINATION_STATUS: 'Vaccination status',
      LIFESTYLE: 'Lifestyle',
    },
    PAGE_BREAK: 'Page break',
    DIALOG_SUMMARY: 'Summary of our exchanges',
    PRESCRIPTION_GIVEN: 'At the end of the teleconsultation, an order %{title} was issued containing',
    HOSPITALS_GIVEN: 'The contact details of the following hospital facility(ies) have been provided to the patient :',
    ORIENTATION_TO_DOCTOR: 'During the teleconsultation, it was recommended that the patient undergo a physical medical examination.',
    EMERGENCY_DETECTED: 'A potential emergency was detected during the call',
    EMERGENCY_CONTACTED: 'The patient was referred to the emergency services and the departmental SAMU was contacted by our teams',
    PATIENT_REDIRECTED: 'The patient was referred to the local emergency services',
    TECHNICAL_ISSUE: 'Technical incident during the call',
    DETAILS: 'Details',
    CONTACT: 'For any questions regarding this service, please do not hesitate to contact our service.',
    FOOTER_1: 'We advise you to see your doctor again for the rest of the treatment or in case of no improvement within 48 hours.',
    FOOTER_2: 'The teleconsultation service is not an emergency service.',
    FOOTER_2_EMERGENCY_NUMBERS: 'In case of emergency please dial 112.',
    FOOTER_3: 'This order is issued during a medical teleconsultation and in accordance with the regulations in force does not include either FINESS or AM numbers. However, the delivery of medicines prescribed by a doctor duly identified by his ADELI number must be considered as it should be by the pharmacy contacted.',
    FOOTER_4: 'In case of an emergency, contact the local emergency services.',
  },
  notify: {
    success: {
      SEND_EMAIL: 'The mail has been send',
      SEND_SMS: 'The message has been send',
      MODIFICATION_SAVED: 'The modifications have been saved',
      AVAILABILITY_DELETED: 'The slot has indeed been removed',
      ARCHIVED: 'The case has been archived',
      REOPENED: 'The case has been reopened',
      CREATED: 'Creation has succeeded',
    },
    error: {
      RELATED_CASE_CREATION: 'You cannot create an appointment in the past.',
      MANDATORY_FIELD: 'You forgot to fill in the required fields',

    }
  },
  patientSearch: {
    TITLE: 'Patient search',
    FIRSTNAME: 'Firstname',
    LASTNAME: 'Lastname',
    DATE_OF_BIRTH: 'Date of birth',
    SEARCH_RESULT: 'Search result',
    NO_RESULT: 'There are no results. Please launch a new search.',
    PATIENTS_FOUND: '%{count} patients found for criterias: ',
    PATIENTS_FOUND_0: 'There are no patients matching the criteria: ',
    LAST_PATIENTS_FOUND: 'The last %{count} patient : ',
    LAST_PATIENTS_FOUND_0: 'The last %{count} patient : ',
    LAST_PATIENTS_FOUND_plural: 'Last %{count} patients : ',
    OPEN_PATIENT: 'Open patient',
    WAIT_10_LAST_PATIENTS: 'Please wait for the loading of the last 10 patients.'
  },
  caseSearch: {
    TITLE: 'Case search',
    periodFromPlaceHolder: 'From DD/MM/YYYY',
    periodForm: 'Range  ',
    periodToPlaceHolder: 'To DD/MM/YYYY',
    periodFrom: 'From',
    periodTo: 'To',
    inCharge: 'People in charge',
    teamInCharge: 'Team in charge',
    bPartner: 'B-partner',
    channel: 'Channel',
    assigned: 'Assigned to',
    teamAssigned: 'Team assigned',
    caseNumber: 'Case number',
    qualificationCode: 'Prequalification code',
    createdBy: 'Created by',
    status: 'Status',
    lastModifiedBy: 'Last modified by',
    KEYWORD: 'Keyword',
    SHOW_MORE: 'Show more criteria',
    SHOW_LESS: 'Show less criteria',
    SEARCH_RESULT: 'Search result',
    FIRST_CASES: 'This is the last %{count} case created.',
    FIRST_CASES_0: 'This is the last %{count} case created.',
    FIRST_CASES_plural: 'These are the last %{count} cases created :',
    WAIT_10_LAST_CASES: 'Please wait for the last 10 cases to load.',
    NO_RESULT: 'There are no results. Please launch a new search.',
    CASES_FOUND: '%{count} cases found for criterias: ',
    CASES_FOUND_1: 'A case has been found for the criteria: ',
    CASES_FOUND_0: 'There are no cases matching the criteria: ',
    LAST_EDIT: 'Last edit by %{firstname} %{lastname}',
    CREATED_BY: 'Case created by %{firstname} %{lastname}',
    OPEN_CASE: 'Open case',
    label: {
      ACTIVE: 'Active',
      ARCHIVED: 'Archived',
      INACTIVE: 'Archived',
      CLOSED: 'Closed',
      TELEADVICE: 'Teleadvice',
      TELECONSULTATION: 'Teleconsultation',
      OTHER: 'Other',
    },
  },
  caseDetails: {
    BACK_TO_LIST: 'Back to list',
    CASE: 'Case ',
    PATIENT: 'Patient',
    ARCHIVED: 'Archived',
    TELEADVICE: 'Tele advice',
    TELECONSULTATION: 'Tele consultation',
    LAST_EDIT: 'Last edit',
    HISTORY: 'See modifications history',
    NO_CONSENTS: 'This case was closed because the consents were not accepted',
    RE_OPEN: 'Re-open the case',
    CHECK_RE_OPEN: 'Are you sure you want to reopen this case?',
    SEE_IN_CONTEXT: 'See in context',
    PATIENT_INFO: 'Patient information',
    patientInfo: {
      FIRSTNAME: 'First name',
      LASTNAME: 'Last name',
      DATE_OF_BIRTH: 'Date of birth',
      EMAIL: 'Email address',
      PHONE: 'Phone number',
      AIDAT_ID: 'Aid@ ID',
      CHANNEL: 'Channel of communication',
      POLICY_NUMBER: 'Policy number',
      PROTOCOL_NUMBER: 'Protocol number',
      BPARTNER: 'B-Partner',
      LEGAL_ENTITY: 'Legal entity',
      BRAND: 'Brand',
    },
    CASE_NOTES: 'Case notes',
    caseNotes: {
      REASON_FOR_CALL: 'Reason for the call',
      TELEADVICE_NOTES: 'Tele advice notes',
      TELECONSULTATION_NOTES: 'Tele consultation notes',
      DOCTOR_NOTES: 'Doctor personal notes',
    },
    CODING: 'Case codes',
    coding: {
      ADVICE_CODING: 'Advice coding: ',
      PATHOLOGY_CODING: 'Local pathology coding: ',
    },
    PRESCRIPTIONS: 'Prescriptions',
    REPORTS: 'Reports',
    PATIENT_DOC: 'Patient documents',
  },
  patientDetails: {
    modalResend: {
      TITLE: 'Resend PDA Link',
      SELECT: 'Please select one policy below to resend the PDA link',
    },
    ADD_POLICY: ' Add another policy',
    SKIP_ELIGIBILITY: 'No eligible policy',
    EXISTING_POLICY: 'Existing policies',
    NEW_POLICY: 'New policies',
    BENIFICIARY_SEARCH: 'Beneficiary Search',
    POLICY_HOLDER_SEARCH: 'Policy Holder Search',
  },
  forgot_password: {
    modal: {
      TITLE: 'Forgot your password?',
      INSTRUCTIONS: 'Please enter the login you used to register and we\'ll email you the password reset instructions.',
      LOGIN: 'Login',
      SUBMIT: 'Send reset instructions',
    },
    sms: {
      TITLE: 'Enter the SMS code',
      INSTRUCTIONS: 'Please enter the code you received by SMS.',
      SMS_CODE: 'SMS code',
      SUBMIT: 'Next',
    },
    reset: {
      TITLE: 'Enter your new password',
      INSTRUCTIONS: 'Please enter your new password.',
      PASSWORD: 'Password',
      PASSWORD_LABEL: 'New password*',
      PASSWORD_CONFIRMATION: 'Password',
      PASSWORD_CONFIRMATION_LABEL: 'Confirm your password*',
      OTP: 'Code',
      OTP_LABEL: 'Please enter the code received by SMS*',
      SUBMIT: 'Next',
    },
    done: {
      TITLE: 'Your password has been reset !',
      INSTRUCTIONS: 'You can now connect to this account using this new password.',
      SUBMIT: 'Continue',
    },
  },
  export: {
    TITLE: 'Exports',
    periodForm: 'Period  ',
    periodFromPlaceHolder: 'From DD/MM/YYYY',
    periodToPlaceHolder: 'To DD/MM/YYYY',
    policyPeriodStartFrom: 'Policy begins after the ',
    policyPeriodEndBefore: 'Policy ends before ',
    patientPolicy: {
      CASE_KPI_REPORT: 'Export of Case KPIs report',
      PATIENT_KPI_REPORT: 'Export of user KPIs report',
    },
    field: {
      businessUnit: 'Business Unit',
      bPartner: 'B-Partner',
      protocolNumber: 'Protocol number',
      prequalificationCode: 'Prequalification code',
      emergencyDetected: 'Emergency detected',
      technicalIssueDetected: 'Technical issues dected',
    },
    SUBMIT: 'Export',
    prequalificationCoding: {
      teleAdvice: 'teleAdvice',
      teleConsult: 'teleConsultation',
    },
    TRUE: 'Yes',
    FALSE: 'No',
    columns: {
      createdOn: 'Creation date',
      gender: 'Gender',
      dateOfBirth: 'Date Of Birth',
      country: 'Country of residence',
      zipCode: 'Zip Code',
      businessUnit: 'Business Unit',
      bPartner: 'B-Partner',
      protocolNumber: 'Protocol Number',
      policyNumber: 'Policy Number',
      pdaAccess: 'PDA Access',
      pdaAccount: 'PDA Account',
      totalNumberCases: 'Total number of cases',
      totalNumberCasesWithSpecialists: 'Total number of cases with specialists',
      lastCaseCreated: 'Last created case',
      numberOfAppointments: 'Nb of appointments',
      numberOfAppointmentsCancelled: 'Nb of cancelled appointments',
      countryOfTheCall: 'Country of the call',
      channelOfCommunication: 'Communication channel',
      aidaCategory: 'Call Category',
      reasonForCall: 'Reason for Call',
      consent: 'Medical Consent',
      caseQualification: 'Qualification',
      pathologyCoding: 'Pathology Coding',
      adviceCoding: 'Advice Coding',
      activeReport: 'Active Report',
      orientationToEmergencyServices: 'Orientation to Emergency Services',
      emergencyDetected: 'Emergency Detected',
      documentJoin: 'Attached documents',
      technicalIssue: 'Technical Issue',
    },
    values: {
      man: 'Uomo',
      woman: 'Donna',
      yes: 'Sì',
      no: 'No',
      phone: 'Telefono',
      video: 'Video',
      chat: 'Chat',
      teleconsult: 'Teleconsulto',
      teleadvice: 'Teleconsiglio',
    }
  },
  operations: {
    TITLE: 'OPERATION DASHBOARD',
    TEAM_AVAILABILITIES: 'TEAM AVAILABILITIES',
    CASE_LEVEL: 'CASES LEVEL',
    CASES_STATUS: 'Cases status',
    WAITING_TIME_FROM_PDA: 'Waiting time from PDA',
    DASHBOARD_INFOS: 'Dashboard cases informations',
    PROCESSING_TIME: 'Processing time (cases from the dashboard)',
    APPOINTMENT_FORECAST: 'Appointment forecast',
    DETAILS: 'DETAILS',
    team: {
      CONNECTED_NURSE: 'Connected nurses',
      CONNECTED_DOCTOR: 'Connected doctors',
    },
    case: {
      FROM_PDA: 'Cases created from the PDA',
      FROM_EPRM: 'Cases created from the ePRM',
      WAITING_PDA: 'Cases waiting to be taken in charge',
      WAITING_APPOINT_PDA: 'Appointments waiting to be taken in charge',
      WAITING_NURSE: 'Cases waiting for a nurse',
      WAITING_DOCTOR: 'Cases waiting for a doctor',
      TAKEN_IN_CHARGE: 'Cases taken in charge by a carer',
      ASSIGNED: 'Cases waiting for a carer',
      ARCHIVED: 'Archived',
      WITH_PDA: 'Cases created from the PDA',
      WITHOUT_PDA: 'Cases created from the ePRM',
      minutes: 'min',
      ALL: 'All open cases',
      ALL_DASHBOARD: 'Total open files on the dashboard'
    },
    appointment: {
      APPOINTMENT_TITLE: 'Appointment forcast',
      CURENT_DAY: 'Today',
      DAY_PLUS_1: 'D + 1',
      DAY_PLUS_2: 'D + 2',
      DAY_PLUS_3: 'D + 3',
      DAY_PLUS_4: 'D + 4',
      DAY_PLUS_5: 'D + 5',
      DAY_PLUS_6: 'D + 6',
      DAY_PLUS_7: 'D + 7',
    },
  },
  pharmacySearch: {
    TITLE: 'Pharmacy search',
    SORT_BY: 'Sort by',
    TO_DELETE: ' %{rows} row(s) selected',
    ADD_PHARMACY: 'Add pharmacy',
    NAME: 'Name',
    ADDRESS: 'Address',
    CITY: 'City',
    COUNTRY: 'Country',
    ZIP_CODE: 'Zip code',
  },
  adviceCodingSearch: {
    TITLE: 'Advice coding',
    PLACE_HOLDER: 'Search for a code',
    TO_DELETE: '%{rows} row(s) selected',
    SORT_BY: 'Sort by',
    NO_RESULT: 'There are no results. Please launch a new search.',
    ADD_CODE: 'Add advice coding',
    NAME: 'Name',
    PARENT: 'Parent',
    CREATION_DATE: 'Created on : %{date}',
    UPDATE_CODE: 'Modification of the code',
    DELETE: 'Code deletion',
    DELETE_CODE: 'Are you sure you want to delete %{count} code ?',
    DELETE_CODE_0: 'Are you sure you want to delete %{count} code ?',
    DELETE_CODE_plural: 'Are you sure you want to delete these %{count} codes?',
    DELETE_IMPACT: 'The deletion will be done permanently. The codes will no longer be present in the files that have been assigned.',
    STATUS: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
    },
  },
  pathologyCodingSearch: {
    TITLE: 'Pathology coding',
    PLACE_HOLDER: 'Search for a code',
    TO_DELETE: '%{rows} row(s) selected',
    SORT_BY: 'Sort by',
    NO_RESULT: 'There are no results. Please launch a new search.',
    ADD_CODE: 'Add pathology coding',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    CREATION_DATE: 'Created on : %{date}',
    UPDATE_CODE: 'Modification of the code',
    DELETE: 'Code deletion',
    DELETE_CODE: 'Are you sure you want to delete %{count} code ?',
    DELETE_CODE_0: 'Are you sure you want to delete %{count} code ?',
    DELETE_CODE_plural: 'Are you sure you want to delete these %{count} codes?',
    DELETE_IMPACT: 'The deletion will be done permanently. The codes will no longer be present in the files that have been assigned.',
    NAME: "Name",
    STATUS: "Status",
    IS_ACTIVE: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
    },
  },
  userSearch: {
    TITLE: 'User search',
    FIRST_NAME: 'First name',
    NAME: 'Name',
    ROLE: 'Role',
    TYPE: 'Type',
    SEARCH_RESULT: 'Search results',
    USERS_FOUND: '%{number} users were found for the criteria: ',
    NO_RESULT: 'There are no hits. Please run a new search.',
    OPEN_USER: 'Open user',
    ADDRESS: 'Address',
    STREET: 'Street',
    ZIPCODE: 'Postal code',
    THIRD_PART: 'The doctor is a third part',
    CITY: 'City',
    COUNTRY: 'Country',
    INFORMATION: 'Organization Information',
    SITE: 'Site',
    BUSINESS_UNIT: 'Business unit',
    TEAM: 'Team',
    SIGNATURE: 'Signature',
    IMPORT_IMAGE: 'Import an image',
    USERNAME: 'Username',
    EMAIL: 'Email',
    LAST_NAME: 'Last name',
  },
  userDetails: {
    TITLE: 'User profile',
    BACK_TO_LIST: 'Back to the list',
    WOMAN: 'Woman',
    MAN: 'Man',
    IDENTITY: 'Identity',
    ADMIN: 'Administrator',
    GENDER: 'Gender',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    USERNAME: 'Username',
    EMAIL: 'Email',
    PHONE: 'Phone',
    ROLE: 'Role',
    DOCTOR_ID: 'N° RPPS',
    THIRD_PART: 'External user',
    YES: 'Yes',
    NO: 'No',
    KNOWLEDGE: 'Languages and specialities',
    LANGUAGES: 'Langues',
    START_TYPING: 'Start writing to see the proposals',
    SPECIALITIES: 'Specialities',
    KNOWLEDGE_DETAILS: 'Details',
    ADDRESS: 'Address',
    STREET: 'Street',
    ZIPCODE: 'Postal code',
    CITY: 'City',
    COUNTRY: 'Country',
    INFORMATION: 'Organizational Information',
    SITE: 'Site',
    BUSINESS_UNIT: 'Business unit',
    TEAM: 'Team',
    SIGNATURE: 'Signature',
    IMPORT_IMAGE: 'Import an image',
    wordings: {
      dragFile: 'Drag and drop your signature to upload it',
      selectFile: 'Or select it from a file',
    },
    DOCTOR_INFORMATION: 'Doctor Information',
    TYPE: 'Type',
  },

  appointment: {
    TITLE: 'CALENDARIO APPUNTAMENTI',
    form: {
      userProfile: 'Profilo utente',
    },
    empty: 'Spazio libero',
    booked: 'ESpazio riservato',
    old: 'Disponibilità esistente',
    new: 'Nuova disponibilità',
    details: {
      TITLE: 'Appuntamento in %{date}',
      hour: 'Appuntamento a %{date}',
      formatDate: 'DD/MM/YYYY',
      patient: 'Informazioni sul paziente',
      patientInfos: '%{firstname} - %{lastname} - %{birthdate}',
      caseArchived: "This case has already handled and archived.",
      caseTaken: "This case is handled by another doctor : %{doctor}",
      caseAssigned: "This case is assigned to doctor :",
    },
    detailsEmpty: {
      TITLE: 'Appuntamento disponibile in %{date}',
      hour: 'Appuntamento disponibile a %{date}',
      formatDate: 'DD/MM/YYYY',
    },
  },
  availability: {
    TITLE: 'TIEMPOS GESTIONAR TIEMPOS',
    add: {
      TITLE: 'Aggiunta di nuovi spazi',
      content: 'Creare nuovi spazi per l\'utente / la specialità selezionata:  ',
      no_user_selected: "È necessario selezionare un utente per poter aggiungere slot",
      no_user: "Nessun utente selezionato",
    },
    form: {
      hours: "Orario",
      period: "Periodo ",
      slotDuration: "Lunghezza della scanalatura (min)",
    },
    summarize: {
      title: 'Sintesi della creazione della nicchia :',
      from: 'Periodo: %{startDate} à %{endDate}',
      to: 'à',
      during: 'Durata degli slot : %{duration} min',
    }
  },
  createRelatedCase: {
    title: "Scegliere un cren per l'appuntamento",
    chooseUser: "Scegliete il medico con cui desiderate fissare un appuntamento: ",
    chooseSlot: "Si prega di scegliere una fascia oraria per fissare un appuntamento : ",
    userAvailabilities: "Disponibilità di medici: %{carer}",
    summarize: {
      title: "Riepilogo dell'appuntamento da creare",
      patient: "Creerete un appuntamento per il paziente : %{patient} ",
      profile: "Con profilo : %{profile}",
      period: "Da %{startDate} a %{endDate}",
    },
    redirectionChoice: "L'appuntamento è stato creato così come il relativo caso, desidera essere reindirizzato a questo nuovo caso?",
  },
  calendar: {
    today: 'Oggi',
    previous: 'Precedente',
    next: 'Avanti',
    month: 'Mese',
    week: 'Settimana',
  },
  fax: {
    CANCEL_TITLE: 'Annullamento del fax',
    SEND_TITLE: 'Invio del fax',
    SENDING: 'Invio fax in corso',
    SENT: 'Fax inviato con successo',
    LINK_EXPIRED: 'Link scaduto o non valido',
    CANCELLING: 'Cancellazione in corso',
    CANCEL: 'Invio del fax annullato',
    BACK: 'Vai al sito web del Teleconsulto',
  },
  internalBeneficiary: {
    TITLE: 'Internal beneficiary',
    BENEFICIARY_NAME: 'Beneficiary first name',
    BENEFICIARY_SURNAME: 'Beneficiary last name',
    BENEFICIARY_BIRTHDATE: 'Beneficiary birthdate',
    EMAIL_ADDRESS: 'Email address',
    PHONE_NUMBER: 'Phone number',
    GENDER: 'Gender',
    POLICY_NUMBER: 'Policy number',
    PROTOCOL_NUMBER: 'Protocol number',
    POLICY_START_DATE: 'Policy start date',
    POLICY_END_DATE: 'Policy end date',
    STREET: 'Street',
    CITY: 'City',
    COUNTRY: 'Country',
    POST_CODE: 'ZIP/Postal code',
    POLICY_HOLDER_NAME: 'Policy holder first name',
    POLICY_HOLDER_SURNAME: 'Policy holder last name',
    POLICY_HOLDER_CITY: 'Policy holder city',
    POLICY_HOLDER_PHONE_NUMBER: 'Policy holder phone number',
    SEARCH: {
      NO_RESULT: "There are no results. Please launch a new search.",
      FOUND_RESULT: "%{count} internal beneficiaries were found for these criteria",
      SEARCH_RESULT: "Search result"
    },
    BENEFICIARY_SECTION: 'Beneficiary Info',
    POLICY_SECTION: 'Policy Info',
    ADDRESS_SECTION: 'Address',
    POLICY_HOLDER_SECTION: 'Policy Holder Info',
    MODAL: {
      CONFIRM_TO_DELETE: 'Are you sure you want to delete this beneficiary in internal beneficiary list?',
      DELETE_TITLE: 'Delete',
      DELETE_BUTTON: 'Delete'
    }
  },
  common: {
    LAST_EDIT_BY: 'Last edit by %{fullName}',
    USER_GENDER_MALE: 'Male',
    USER_GENDER_FEMALE: 'Female'
  },
  bPartner: {
    TITLE: 'B-Partner',
    TITLE_LIST: 'B-Partner List',
    TITLE_CREATE: 'B-Partner Creation',
    label: {
      B_PARTNER_NAME: 'B-Partner name',
      B_PARTNER_CODE: 'B-Partner code',
      BUSINESS_UNIT: 'Business unit',
      STATUS: 'Status',
      COMMERCIAL_NAME: 'Commercial name',
      DOCTORS_NOTES: 'Doctors notes',
    },
    STATUS: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive'
    },
    button: {
      ADD: 'Add',
      EXPORT: 'Export'
    },
    TABLE: {
      B_PARTNER_NAME: 'B-Partner name',
      B_PARTNER_CODE: 'B-Partner code',
      BUSINESS_UNIT: 'Business unit',
      STATUS: 'Status',
      COMMERCIAL_NAME: 'Commercial name',
      PAGINATION_INFO: 'Showing %{start} to %{end} of %{total} entries',
    },
    MODAL: {
      DELETE_TITLE: 'Delete',
      CONFIRM_TO_DELETE: 'Are you sure you want to delete this B-Partner list?'
    },
    export: {
      bPartner: 'B-Partner name',
      bPartnerCode: 'B-Partner code',
      businessUnit: 'Business unit',
      status: 'Status',
      commercialName: 'Commercial name',
      doctorNotes: 'Doctor Notes',
      createdOn: 'Creation date',
    },
  },
  logoManagement: {
    TITLE: 'Logo',
    TITLE_LIST: 'Logo list',
    TITLE_CREATE: 'Logo creation',
    label: {
      LOGO_NAME: 'Logo name',
      BUSINESS_UNIT: 'Business unit',
      LOGO_FILE: 'Logo file',
      STATUS: 'Status',
    },
    STATUS: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
    },
    button: {
      ADD: 'Add',
      EXPORT: 'Export',
    },
    TABLE: {
      LOGO_NAME: 'Logo name',
      BUSINESS_UNIT: 'Business unit',
      STATUS: 'Status',
      CREATED: 'Created',
      PAGINATION_INFO: 'Showing %{start} to %{end} of %{total} entries',
    },
    export: {
      logoName: 'Logo name',
      businessUnit: 'Business unit',
      status: 'Status',
      createdOn: 'Created',
    },
  },
  protocol: {
    TITLE: 'Protocol',
    TITLE_LIST: 'Protocol List',
    TITLE_CREATE: 'Protocol Creation',
    label: {
      PROTOCOL_NAME: 'Protocol name',
      PROTOCOL_NUMBER: 'Protocol number',
      B_PARTNER_NUMBER: 'B-Partner number',
      B_PARTNER: 'B-Partner',
      LEGAL_ENTITY: 'Legal entity',
      BRAND:'Brand',
      MEDICAL_TELECONSULTATION_PHONE_NUMBER:'Medical teleconsultation phone number',
      NUMBER_OF_CONSULTATIONS_AUTHORIZED_PER_YEAR: 'Number of consultations authorized per year',
      PROVIDER_NETWORK:'Provider network',
      COMMERCIAL_NAME:'Commercial name',
      THIRD_PARTY_SSO:'Third party SSO',
      TYPE_OF_PROTOCOL:'CIC/Crédit Mutuel portfolio ?',
      VISA: 'Visa',
      STATUS: 'Status',
      LOGO:'Logo',
    },
    STATUS: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive'
    },
    button: {
      ADD: 'Add',
      EXPORT: 'Export'
    },
    message: {
      WARNING_FILL_BIN: 'Protocol with visa will be fully functional once BIN list for visa has been updated.',
      ERROR_NOT_ALLOWED_ENABLE_BOTH_VISA_CIC: 'Protocol not applicable for enable both VISA and CIC/Credit Mutuel portfolio, please ensure to selectonly one of them.'
    },
    TYPE_OF_PROTOCOL_TYPE: {
      CREDIT_CARD_TYPE: 'Yes',
      NOT_CREDIT_CARD_TYPE: 'No',
    },
    TABLE: {
      PROTOCOL_NAME: 'Protocol name',
      PROTOCOL_NUMBER: 'Protocol number',
      BUSINESS_UNIT: 'Business unit',
      LEGAL_ENTITY: 'Legal entity',
      BRAND: 'Brand',
      MEDICAL_TELECONSULTATION_PHONE_NUMBER: 'Medical teleconsultation phone number',
      B_PARTNER: 'B-Partner',
      NUMBER_OF_CONSULTATIONS_AUTHORIZED_PER_YEAR: 'Number of consultations authorized per year',
      PROVIDER_NETWORK: 'Provider network',
      COMMERCIAL_NAME: 'Commercial name',
      THIRD_PARTY_SSO: 'Third party SSO',
      TYPE_OF_PROTOCOL: 'Type of protocol',
      STATUS: 'Status',
      PAGINATION_INFO: 'Showing %{start} to %{end} of %{total} entries',
    },
    MODAL: {
      DELETE_TITLE: 'Delete',
      CONFIRM_TO_DELETE: 'Are you sure you want to delete this protocol list?'
    },
    export: {
      protocolName: 'Protocol name',
      protocolNumber: 'Protocol number',
      businessUnit: 'Business unit',
      legalEntityName: 'Legal entity',
      brandName: 'Brand name',
      MedicalTeleconsultationPhoneNumber: 'Medical teleconsultation phone number',
      bPartner: 'B-Partner',
      numberOfConsultationPerYear: 'Number of consultations authorized per year',
      providerNetworkDetails: 'Provider network',
      commercialName: 'Commercial name',
      isThirdPartySso: 'Third party SSO',
      typeOfProtocol: 'Type of protocol',
      status: 'Status',
      isCmCic: 'CIC/Crédit Mutuel portfolio',
      isVisa: 'Visa',
      createdOn: 'Creation date',
      logoName: 'Logo name',
      phoneNumber: 'phone number',
    },
  },
  legalEntity: {
    TITLE: 'Legal entity',
    TITLE_LIST: 'Legal entity list',
    TITLE_CREATE: 'Legal entity creation',
    label: {
      LEGAL_ENTITY_NAME: 'Legal entity name',
      LEGAL_ENTITY_CODE: 'Legal entity code',
      BUSINESS_UNIT: 'Business unit',
      STATUS: 'Status',
      EMAIL_FOOTER: 'Email footer',
      ADDRESS: 'Address',
    },
    STATUS: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
    },
    button: {
      ADD: 'Add',
      EXPORT: 'Export',
    },
    TABLE: {
      LEGAL_ENTITY_NAME: 'Legal entity name',
      LEGAL_ENTITY_CODE: 'Legal entity code',
      BUSINESS_UNIT: 'Business unit',
      STATUS: 'Status',
      PAGINATION_INFO: 'Showing %{start} to %{end} of %{total} entries',
    },
    export: {
      legalEntityName: 'Legal entity name',
      legalEntityCode: 'Legal entity code',
      businessUnit: 'Business unit',
      status: 'Status',
      emailFooter: 'Email footer',
      address: 'Address',
      createdOn: 'Creation date',
    },
  },
  brandManagement: {
    TITLE: 'Brand',
    TITLE_LIST: 'Brand list',
    TITLE_CREATE: 'Brand creation',
    label: {
      BRAND_NAME: 'Brand name',
      BRAND_CODE: 'Brand code',
      BUSINESS_UNIT: 'Business unit',
      STATUS: 'Status',
      SENDER_MAIL: 'Sender mail',
      PDA_LINK: 'PDA link',
      BRAND_CREATION: 'Brand creation',
    },
    STATUS: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
    },
    button: {
      ADD: 'Add',
      EXPORT: 'Export',
    },
    TABLE: {
      BRAND_NAME: 'Brand name',
      BRAND_CODE: 'Brand code',
      BUSINESS_UNIT: 'Business unit',
      STATUS: 'Status',
      SENDER_MAIL: 'Sender mail',
      PDA_LINK: 'PDA link',
      BRAND_CREATION: 'Brand creation',
      PAGINATION_INFO: 'Showing %{start} to %{end} of %{total} entries',
    },
    export: {
      brandName: 'Brand name',
      brandCode: 'Brand code',
      businessUnit: 'Business unit',
      status: 'Status',
      senderEmail: 'Sender mail',
      pdaLink: 'PDA link',
      brandCreation: 'Brand creation',
      createdOn: 'Creation date',
    },
  },
};

export default it;
