// @flow

import { RestClientAuthenticated } from '../network';

export type PolicySearch = {
  firstName: string,
  lastName: string,
  birthdate: Object,
  policyHolderFirstName?: string,
  policyHolderLastName?: string,
  policyNumber?: string,
};

export type PolicyVisaSearch = {
  firstName: string,
  lastName: string,
  birthdate: string,
  binNumber: number,
};

const apiEndpoint = {
  policyContractTransaction: '/policy/contract/transaction',
  checkVisaTransaction: '/visa/eligibility',
};

const processApi = {
  checkEligibility: (searchParams: PolicySearch) =>{
    const queryString = Object.keys(searchParams).filter(v => searchParams[v] !== null).map(key => `${key }=${ searchParams[key]}`).join('&');
    return new RestClientAuthenticated(`${apiEndpoint.policyContractTransaction}?${queryString}`, 'GET').execute()
  },
  checkVisa: (searchParams: PolicyVisaSearch) =>{
    const queryString = Object.keys(searchParams).filter(v => searchParams[v] !== null).map(key => `${key }=${ searchParams[key]}`).join('&');
    return new RestClientAuthenticated(`${apiEndpoint.checkVisaTransaction}?${queryString}`, 'GET').execute()
  },
};

export default processApi;
