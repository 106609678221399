// @flow

export function getQueryParams(search: string): Object {
  const searchResult = search.charAt(0) === '?' ? search.slice(1) : search.slice();
  const queryParamsArray = searchResult.split('&');
  return queryParamsArray.reduce(
    (accumulator, keyValueString) => {
      const keyValue = keyValueString.split('=');
      accumulator[keyValue[0]] = decodeURIComponent(keyValue[1]);
      return accumulator;
    }, {});
}

export function createSearchFromParams(params: Object) {
  return '?' + Object.entries(params).filter(kv => kv[1]).map(param => param.join('=')).join('&');
}
