// @flow

import React, { Fragment } from 'react';
import { Field, Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import {
  CoreozDatePickerBase as CoreozDatePicker,
  CoreozInputBase as CoreozInput,
  CoreozSelectBase as CoreozSelect,
} from '../../lib/coreoz-form-base/index';
import moment from 'moment';

import { dateValidator } from '../../validator';
import Loader from '../../components/Loader';
import type { BusinessUnit } from '../../types/BusinessUnitTypes';

type Props = {
  title: string,
  icone: string,
  loading: boolean,
  downloadSearch: Function,
  defaultValues: Object,
  businessUnits: Array<BusinessUnit>,
  bPartners: Array<any>
}

type State = {
  showDetails: boolean,
  defaultValues: Object,
}

class ExportsTileDetails extends React.Component<Props, State> {
  state = {
    showDetails: false,
    defaultValues: this.props.defaultValues,
  }

  componentWillReceiveProps(nextProps): void {
    if (this.props.defaultValues !== nextProps.defaultValues) {
      this.setState({ defaultValues: nextProps.defaultValues });
    }
  }

  filterBpartner = (businessUnitId) => {
    const selectedBU = this.props.businessUnits.filter(bu => bu.id === businessUnitId)[0];
    return this.props.bPartners.filter(bp => bp.language && bp.language.toUpperCase() === selectedBU.countryCode.toUpperCase());
  };

  validateDate = (e) => {
    if (!moment(e).isValid()) {
      return dateValidator(e, I18n.t('date.DATE_FORMAT'));
    }
    return undefined;
  }

  onSubmit = (form) => {
    this.props.downloadSearch(form);
  };

  render() {
    return (
      <div className="tile-export">
        <Form
          onSubmit={this.onSubmit}
          initialValues={this.state.defaultValues}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div
                className="tile-export-title"
                onClick={() => this.setState({ showDetails: !this.state.showDetails })}
              >
                <i className={`far ${this.props.icone}  large`} />
                <h2>
                  {this.props.title}
                  <i className={`fa fa-chevron-${this.state.showDetails ? 'down' : 'right'}`} />
                </h2>


                {!this.state.showDetails &&
                <Fragment>{!this.props.loading ?
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    color="primary"
                    type="submit"
                    className="submit-button"
                  >
                    {I18n.t('export.SUBMIT')}
                  </Button> :
                  <div className="center-child search-loader"><Loader small grey /></div>
                }
                </Fragment>
                }
              </div>
              {this.state.showDetails &&
              <div className="export-tile-form">
                <div className="criteria-line period">
                  <div className="period-field">
                    <Field
                      className="centered export-range"
                      name="createdAfter"
                      label={I18n.t('export.periodForm')}
                      component={CoreozDatePicker}
                      placeholder={I18n.t('export.periodFromPlaceHolder')}
                      validate={this.validateDate}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      readOnly
                      format={(stringValue) => {
                        if (stringValue) {
                          if (moment(stringValue, I18n.t('date.DATE_FORMAT'), true).isValid()) {
                            return moment(stringValue, I18n.t('date.DATE_FORMAT'));
                          }
                          if (moment(stringValue, moment.ISO_8601).isValid()) {
                            return moment(stringValue);
                          }
                        }
                        return stringValue;
                      }}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                    />
                    <i className="fa fa-arrow-right" />
                    <Field
                      className="createdBefore"
                      name="createdBefore"
                      component={CoreozDatePicker}
                      placeholder={I18n.t('export.periodToPlaceHolder')}
                      validate={this.validateDate}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      readOnly
                      format={(stringValue) => {
                        if (stringValue) {
                          if (moment(stringValue, I18n.t('date.DATE_FORMAT'), true).isValid()) {
                            return moment(stringValue, I18n.t('date.DATE_FORMAT'));
                          }
                          if (moment(stringValue, moment.ISO_8601).isValid()) {
                            return moment(stringValue);
                          }
                        }
                        return stringValue;
                      }}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                    />
                  </div>
                </div>
                <div className="export-row">
                  <div className="export-col">
                    <Field
                      className="policyPeriodStartFrom"
                      name="policyPeriodStartFrom"
                      label={I18n.t('export.policyPeriodStartFrom')}
                      component={CoreozDatePicker}
                      placeholder={I18n.t('export.periodFromPlaceHolder')}
                      validate={this.validateDate}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      readOnly
                      format={(stringValue) => {
                        if (stringValue) {
                          if (moment(stringValue, I18n.t('date.DATE_FORMAT'), true).isValid()) {
                            return moment(stringValue, I18n.t('date.DATE_FORMAT'));
                          }
                          if (moment(stringValue, moment.ISO_8601).isValid()) {
                            return moment(stringValue);
                          }
                        }
                        return stringValue;
                      }}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                    />
                    <Field
                      disabledPlaceholder
                      placeholder=""
                      autoComplete="off"
                      className="centered"
                      label={I18n.t('export.field.businessUnit')}
                      type="text"
                      component={CoreozSelect}
                      list={this.props.businessUnits}
                      name="businessUnitId"
                      required={false}
                    />
                    <Field
                      disabledPlaceholder={false}
                      placeholder=""
                      autoComplete="off"
                      className="centered"
                      label={I18n.t('export.field.bPartner')}
                      type="text"
                      component={CoreozSelect}
                      list={this.filterBpartner(values.businessUnitId)}
                      name="bPartnerId"
                      required={false}
                    />
                    <Field
                      placeholder=""
                      autoComplete="off"
                      className="centered"
                      label={I18n.t('export.field.protocolNumber')}
                      type="text"
                      component={CoreozInput}
                      name="protocolNumber"
                      required={false}
                    />
                  </div>
                  <div className="export-col">
                    <Field
                      className="policyPeriodEndBefore"
                      name="policyPeriodEndBefore"
                      label={I18n.t('export.policyPeriodEndBefore')}
                      component={CoreozDatePicker}
                      placeholder={I18n.t('export.periodToPlaceHolder')}
                      validate={this.validateDate}
                      dateFormat={I18n.t('date.DATE_FORMAT')}
                      readOnly
                      format={(stringValue) => {
                        if (stringValue) {
                          if (moment(stringValue, I18n.t('date.DATE_FORMAT'), true).isValid()) {
                            return moment(stringValue, I18n.t('date.DATE_FORMAT'));
                          }
                          if (moment(stringValue, moment.ISO_8601).isValid()) {
                            return moment(stringValue);
                          }
                        }
                        return stringValue;
                      }}
                      parse={momentValue => (momentValue && moment.isMoment(momentValue) ?
                        momentValue.format() : momentValue)}
                      onlyDate
                    />
                  </div>
                </div>
                <div>
                  {!this.props.loading ?
                    <Button
                      color="primary"
                      type="submit"
                      className="submit-button"
                    >{I18n.t('export.SUBMIT')}
                    </Button> :
                    <div className="center-child search-loader"><Loader grey /></div>
                  }
                </div>
              </div>}
            </form>
          )}
        />
      </div>

    );
  }
}

export default ExportsTileDetails;
