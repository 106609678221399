import React, { useState, useEffect, useContext } from "react";
import { I18n, Localize } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { CoreozDatePickerBase, CoreozInputBase } from '../../lib/coreoz-form-base/index';
import { Button } from 'reactstrap';
import Loader from '../../components/Loader';
import { notifyError } from '../../network/notification';
import InternalBeneficiaryContext from '../../stores/internal-beneficiary-management-context';
import {INTERNAL_BENEFICIARY_MANAGEMENT_PAGE_ROUTE} from '../../routes';
import ProfileHeader from '../../components/layout/header/ProfileHeader';
import PlmFormItem from '../../components/panel/PlmFormItem';
import { required, validateEmail, dateCompareAndCheckRequireValidator, isValidDate } from '../../validator';
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import internalBeneficiaryApi from '../../network/api/internalBeneficiaryApi';
import { ProfileType, ProfileFormType } from '../../types/InternalBeneficiaryTypes';
import { connect } from 'react-redux';
import ModalEprm from '../../components/modal/ModalEprm';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

let profileName, birthDate :string;

const InternalBeneficiaryDetail = (props) => {
  const classes = useStyles();
  const [button, setButton] = useState("");
  const {searchCriteriaContext, updateSearchCriteriaContext} = useContext(InternalBeneficiaryContext);

  const [profileInfo, setProfileInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(null);
  const [totalSearchResult, setTotalSearchResult] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [updateBy, setUpdateBy] = useState(null);
  const [updateDate, setUpdateDate] = useState(null);

  useEffect(() => {
    fetchProfile();
  }, []);

 
  async function fetchProfile() {
    const profileId = props.match.params.id;
    
    setLoading(true);

    const profileResultResponse : ProfileType = await props.dispatch(internalBeneficiaryApi.fetchProfile(profileId));

    setProfileInfo(profileResultResponse);

    if(profileResultResponse) {
      profileName = profileResultResponse.beneficiary.firstName + ' ' + profileResultResponse.beneficiary.lastName;
      birthDate = profileResultResponse.beneficiary.birthDate;
      setUpdateBy(profileResultResponse.updateBy);
      setUpdateDate(profileResultResponse.updateDate);
    }

    setLoading(false);
  }

  const saveProfile = async (profileForm :ProfileFormType) => {
    const profileRequest :ProfileType = mapUpdateProfileRequest(profileForm);
    setLoading(true);
    await props.dispatch(internalBeneficiaryApi.updateProfile(props.match.params.id, profileRequest));
    fetchProfile();
    setLoading(false);
  };

  const deleteProfile = async () => {
    setLoading(true);
    await props.dispatch(internalBeneficiaryApi.deleteProfile(props.match.params.id));
    setLoading(false);
    onPreviousHandler();
  };

  const mapUpdateProfileRequest = (profileForm :ProfileFormType) : ProfileType => {
    const profileRequest :ProfileType = {
      'protocolNumber': profileInfo.protocolNumber,
      'policyNumber': profileInfo.policyNumber,
      'policyStartDate': profileForm.policyStartDate,
      'policyEndDate': profileForm.policyEndDate,
      'beneficiary': {
        "firstName": profileInfo.beneficiary.firstName,
        "lastName":  profileInfo.beneficiary.lastName,
        "birthDate": profileInfo.beneficiary.birthDate,
        "email": profileForm && profileForm.beneficiaryEmail,
        "mobilePhone": profileForm && profileForm.beneficiaryMobilePhone,
        "genderCode":profileForm && (profileForm.beneficiaryGender) ? 2 : 1,
        "street": profileForm && profileForm.beneficiaryStreet,
        "city": profileForm && profileForm.beneficiaryCity,
        "country": profileForm && profileForm.beneficiaryCountry ,
        "postalCode": profileForm && profileForm.beneficiaryPostCode
      },
      "policyHolder": {
        "firstName":  profileForm && profileForm.policyHolderName,
        "lastName": profileForm && profileForm.policyHolderSurname,
        "phoneNo": profileForm && profileForm.policyHolderPhone,
        "city": profileForm && profileForm.policyHolderCity
      }
    };

    return profileRequest;
  }

  const onPreviousHandler = () => {
    props.history.push(INTERNAL_BENEFICIARY_MANAGEMENT_PAGE_ROUTE)
  };

  const isFemaleGender = (genderCode: Number):string => {
    if(genderCode === 1) {
      return 'false';
    } else if(genderCode === 2) {
      return 'true';
    } else {
      return null;
    }
  };

  return (
        <div className="internal-beneficiary-detail-container user-details" id="internalBeneficiaryDetail">
          <ProfileHeader onPreviousPageFunc={onPreviousHandler} title={I18n.t('internalBeneficiary.TITLE')} 
          profileInfo={{'fullName': profileName, 'birthDate': birthDate}} 
          modifyInfo={{'updateBy': updateBy, 'updateDate': updateDate}} 
          />
          <Form onSubmit={saveProfile} render={({ handleSubmit, form, invalid }) => (
              <form onSubmit={handleSubmit}>
                <div className="user-action">
                  <Button
                    color="secondary"
                    type="button"
                    onClick={onPreviousHandler}
                  >
                    <i className="button-icon fa fa-undo"/>{I18n.t('actions.CANCEL')}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={invalid}
                  >
                    <i className="button-icon fa fa-save"/>{I18n.t('actions.SAVE')}
                  </Button>
                  <Button
                    color="danger"
                    type="button"
                    onClick={() => setOpenDeleteModal(true) }
                  >
                    <i className="button-icon fas fa-trash-alt"/>{I18n.t('actions.DELETE')}
                  </Button>
                </div>
                <div className="content">
                  <div className="user-category">
                    <div className="title">{I18n.t('internalBeneficiary.BENEFICIARY_SECTION')}</div>
                    <br/>
                    <div className="content-container">
                      <PlmFormItem label={I18n.t('internalBeneficiary.BENEFICIARY_NAME')} >
                        {profileInfo && profileInfo.beneficiary && profileInfo.beneficiary.firstName}
                      </PlmFormItem>
                      <PlmFormItem label={I18n.t('internalBeneficiary.BENEFICIARY_SURNAME')}>
                        {profileInfo && profileInfo.beneficiary && profileInfo.beneficiary.lastName}
                      </PlmFormItem>
                      <PlmFormItem label={I18n.t('internalBeneficiary.BENEFICIARY_BIRTHDATE')}>
                        {profileInfo && profileInfo.beneficiary && profileInfo.beneficiary.birthDate && moment(profileInfo.beneficiary.birthDate).format('DD/MM/YYYY')}
                      </PlmFormItem>
                      <Field
                        component={CoreozInputBase}
                        name="beneficiaryEmail"
                        label={I18n.t('internalBeneficiary.EMAIL_ADDRESS')}
                        validate={e => validateEmail(e, false)}
                        defaultValue={profileInfo && profileInfo.beneficiary && profileInfo.beneficiary.email}
                      />
                      <Field
                        autoComplete="off"
                        component={CoreozInputBase}
                        name="beneficiaryMobilePhone"
                        label={I18n.t('internalBeneficiary.PHONE_NUMBER')}
                        required={false}
                        defaultValue={profileInfo && profileInfo.beneficiary && profileInfo.beneficiary.mobilePhone}
                      />
                    <div className="radio-button-container">
                      <div className="radio-button-label">{I18n.t('userDetails.GENDER')}</div>
                      <div className="choices">
                        <Field
                          component="input"
                          name="beneficiaryGender"
                          type="radio"
                          value="false"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                          required
                          defaultValue={profileInfo && profileInfo.beneficiary && isFemaleGender(profileInfo.beneficiary.genderCode)}
                        />
                        <span>{I18n.t('common.USER_GENDER_MALE')}</span>
                        <Field
                          component="input"
                          name="beneficiaryGender"
                          type="radio"
                          value="true"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                          required
                          defaultValue={profileInfo && profileInfo.beneficiary && isFemaleGender(profileInfo.beneficiary.genderCode)}
                        />
                        <span>{I18n.t('common.USER_GENDER_FEMALE')}</span>
                       </div>
                    </div>
                    </div>
                  </div>

                  <div className="user-category">
                    <div className="title">{I18n.t('internalBeneficiary.POLICY_SECTION')}</div>
                    <div className="content-container">
                      <Field
                        required
                        component={CoreozDatePickerBase}
                        label={I18n.t('internalBeneficiary.POLICY_START_DATE')}
                        onlyDate
                        dateFormat={I18n.t('date.DATE_FORMAT')}
                        name="policyStartDate"
                        id="policyStartDate"
                        format={value => (value && isValidDate(value) ? moment(value).format(I18n.t('date.DATE_FORMAT')) : value)}
                        parse={momentValue => (momentValue && moment.isMoment(momentValue) ? momentValue.format('YYYY-MM-DD') : momentValue)}
                        validate={(value, allValues) => dateCompareAndCheckRequireValidator(allValues.policyStartDate, allValues.policyEndDate, value, true)}
                        autoComplete="off"
                        defaultValue={profileInfo && profileInfo.policyStartDate}
                        />
                        
                      <Field
                        required    
                        component={CoreozDatePickerBase}
                        label={I18n.t('internalBeneficiary.POLICY_END_DATE')}
                        onlyDate
                        dateFormat={I18n.t('date.DATE_FORMAT')}
                        name="policyEndDate"
                        id="policyEndDate"
                        format={value => (value && isValidDate(value) ? moment(value).format(I18n.t('date.DATE_FORMAT')) : value)}
                        parse={momentValue => (momentValue && moment.isMoment(momentValue) ? momentValue.format('YYYY-MM-DD') : momentValue)}
                        validate={(value, allValues) => dateCompareAndCheckRequireValidator(allValues.policyStartDate, allValues.policyEndDate, value, true)}
                        autoComplete="off"
                        defaultValue={profileInfo && profileInfo.policyEndDate}
                        />

                      <PlmFormItem label={I18n.t('internalBeneficiary.PROTOCOL_NUMBER')}>
                        {profileInfo && profileInfo.protocolNumber}
                      </PlmFormItem>

                      <PlmFormItem label={I18n.t('internalBeneficiary.POLICY_NUMBER')}>
                        {profileInfo && profileInfo.policyNumber}
                      </PlmFormItem>
                    </div>
                  </div>

                  <div className="user-category">
                    <div className="title">{I18n.t('internalBeneficiary.ADDRESS_SECTION')}</div>
                    <div className="content-container">
                      <Field
                        autoComplete="off"
                        type="text"
                        component={CoreozInputBase}
                        name="beneficiaryStreet"
                        label={I18n.t('internalBeneficiary.STREET')}
                        defaultValue={profileInfo && profileInfo.policyHolder && profileInfo.beneficiary.street}
                      />
                      <Field
                        autoComplete="off"
                        type="text"
                        component={CoreozInputBase}
                        name="beneficiaryCity"
                        label={I18n.t('internalBeneficiary.CITY')}
                        defaultValue={profileInfo && profileInfo.policyHolder && profileInfo.beneficiary.city}
                      />
                      <Field
                        autoComplete="off"
                        type="text"
                        component={CoreozInputBase}
                        name="beneficiaryCountry"
                        label={I18n.t('internalBeneficiary.COUNTRY')}
                        defaultValue={profileInfo && profileInfo.policyHolder && profileInfo.beneficiary.country}
                      />
                      <Field
                        autoComplete="off"
                        type="text"
                        component={CoreozInputBase}
                        name="beneficiaryPostCode"
                        label={I18n.t('internalBeneficiary.POST_CODE')}
                        defaultValue={profileInfo && profileInfo.policyHolder && profileInfo.beneficiary.postalCode}
                      />    
                    </div>                                                                    
                  </div>

                  <div className="user-category">
                    <div className="title">{I18n.t('internalBeneficiary.POLICY_HOLDER_SECTION')}</div>
                    <div className="content-container">
                      <Field
                        autoComplete="off"
                        type="text"
                        component={CoreozInputBase}
                        name="policyHolderName"
                        label={I18n.t('internalBeneficiary.POLICY_HOLDER_NAME')}
                        defaultValue={profileInfo && profileInfo.policyHolder && profileInfo.policyHolder.firstName}
                      />
                      <Field
                        autoComplete="off"
                        type="text"
                        component={CoreozInputBase}
                        name="policyHolderSurname"
                        label={I18n.t('internalBeneficiary.POLICY_HOLDER_SURNAME')}
                        defaultValue={profileInfo && profileInfo.policyHolder && profileInfo.policyHolder.lastName}
                      />
                      <Field
                        autoComplete="off"
                        type="text"
                        component={CoreozInputBase}
                        name="policyHolderCity"
                        label={I18n.t('internalBeneficiary.POLICY_HOLDER_CITY')}
                        defaultValue={profileInfo && profileInfo.policyHolder && profileInfo.policyHolder.city}
                      />
                      <Field
                        autoComplete="off"
                        type="text"
                        component={CoreozInputBase}
                        name="policyHolderPhone"
                        label={I18n.t('internalBeneficiary.POLICY_HOLDER_PHONE_NUMBER')}
                        defaultValue={profileInfo && profileInfo.policyHolder && profileInfo.policyHolder.phoneNo}
                      />
                    </div>
                  </div>
                  
                </div>  
                <div className="user-action p-3">
                  <Button
                    color="secondary"
                    type="button"
                    onClick={onPreviousHandler}
                  >
                    <i className="button-icon fa fa-undo"/>{I18n.t('actions.CANCEL')}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={invalid}
                  >
                    <i className="button-icon fa fa-save"/>{I18n.t('actions.SAVE')}
                  </Button>
                  <Button
                    color="danger"
                    type="button"
                    onClick={() => setOpenDeleteModal(true) }
                  >
                    <i className="button-icon fas fa-trash-alt"/>{I18n.t('actions.DELETE')}
                  </Button>
                </div>
              </form>
            )}
          />
          <Backdrop className={classes.backdrop} open={loading}>
            <div className="center-child search-loader"><Loader/></div>
          </Backdrop>

          <ModalEprm
                title={I18n.t('internalBeneficiary.MODAL.DELETE_TITLE')}
                modal={openDeleteModal}
                toggle={() => setOpenDeleteModal(false)}>
                <div>
            
                  <div>{I18n.t('internalBeneficiary.MODAL.CONFIRM_TO_DELETE')}</div>

                  <div className="modal-actions">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => setOpenDeleteModal(false)}
                    >
                      <i className="button-icon fa fa-undo"/>
                      {I18n.t('actions.CANCEL')}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      onClick={deleteProfile}
                    >
                      <i className="button-icon fas fa-paper-plane"/>
                      {I18n.t('internalBeneficiary.MODAL.DELETE_BUTTON')}
                    </Button>
                  </div>
                </div>
            </ModalEprm>

        </div>
    );
};

export default connect()(InternalBeneficiaryDetail);
