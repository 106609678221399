// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import type { Mail } from '../../types/MailTypes';

import PlmPanel from '../panel/PlmPanel';
import PlmFormItem from '../panel/PlmFormItem';


type Props = {
  search: Function,
  mails: Mail[],
}

class MailSearch extends React.PureComponent<Props> {

  getMailTypeOptionList = (mails: Array<Object>) => {
    const mailTypeOptionList = [];
    mails.forEach((option: Object) => {
      if (!mailTypeOptionList.includes(option.mailType)) {
        mailTypeOptionList.push(option.mailType);
      }
    });
    return mailTypeOptionList;
  };

  render() {
    return (
      <PlmPanel title={I18n.t('mail.SEARCH_TITLE')}>
        <Form
          onSubmit={this.props.search}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="panel-body">
                <Row className="search-form">
                  <Col>
                    <PlmFormItem label={I18n.t('mail.label.idBusinessUnit')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="input"
                        name="idBusinessUnit"
                      />
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('mail.label.mailType')}>
                      <Field
                        className="form-control"
                        component="select"
                        name="mailType"
                        type="text"
                      >
                        <option value="any">{I18n.t('mail.label.all')}</option>
                        {this.props.mails.length > 0 &&
                          this.getMailTypeOptionList(this.props.mails)
                            .map(option => (
                              <option value={option} key={option}>{option}</option>
                          ))
                        }
                      </Field>
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('mail.label.subject')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="input"
                        name="subject"
                      />
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('mail.label.template')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="input"
                        name="template"
                      />
                    </PlmFormItem>
                  </Col>
                </Row>
              </div>
              <div className="panel-footer">
                <button
                  className="btn btn-info"
                  type="submit"
                >
                  <Translate value="actions.SEARCH" />
                </button>
              </div>
            </form>
          )}
        />
      </PlmPanel>
    );
  }
}

export default withRouter(connect()(MailSearch));
