// @flow
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field } from 'react-final-form';
import ResizableTextarea from '../ResizableTextarea';
import { validateWeight } from '../../validator';

type Props = {
  values: any,
  patient: Object,
  caseInfos: ?Object,
}

type FieldProps = {
  name: string,
}
function MedicalField({ name }: FieldProps) {
  return (<Field
    label={I18n.t(`medicalProfile.informationsMedicalProfile.${name}`)}
    name={name}
    component={ResizableTextarea}
    minrows={1}
    maxrows={5}
    required
  />);
}


export default function MedicalProfileFields({ values, patient, caseInfos }: Props) {
  return (
    <>
      {
        patient && !!Object.keys(patient).length &&
        <>
          <MedicalField
            name="medicalHistory"
          />
          <MedicalField
            name="treatment"
          />
          <MedicalField
            name="allergies"
          />
          <MedicalField
            name="chronicalDisease"
          />
          <MedicalField
            name="vaccination"
          />
          <MedicalField
            name="lifeStyle"
          />
        </>
      }
      <div className="patient-body-informations">
        <div className="info-lign">
          <h3>{I18n.t('medicalProfile.weight')}</h3>
          <div className="field-patient-body">
            <Field
              className="form-control weight-input"
              component="input"
              name="weight"
              type="text"
              validate={validateWeight}
              disabled={caseInfos && !caseInfos.isEditable}
            />
            <span>{I18n.t('medicalProfile.weight_mesure')}</span>
          </div>
        </div>
        <div className="info-lign">
          <h3>{I18n.t('medicalProfile.height')}</h3>
          <div className="field-patient-body">
            <Field
              className="form-control weight-input"
              component="input"
              name="height"
              type="text"
              validate={validateWeight}
              disabled={caseInfos && !caseInfos.isEditable}
            />
            <span>{I18n.t('medicalProfile.height_mesure')}</span>
          </div>
        </div>
        <div className="info-lign">
          <h3>{I18n.t('medicalProfile.BMI')}</h3>
          <div className="form-control disabled">
            {values.weight > 0 && values.height > 0 && Math.round((values.weight / (values.height / 100) ** 2) * 100) / 100}
          </div>
        </div>
      </div>
    </>
  );
}
