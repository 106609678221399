// @flow

import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from 'react-redux-i18n';

type Props = {
  name: string,
}

class RadioButton extends React.Component<Props> {

  render(){
    return(
      <div className="radios-button-line">
        <label>
          <Field
            className="radio-input"
            name={this.props.name}
            type="radio"
            component="input"
            value="true"
            format={value => String(value)}
            parse={value => value === 'true'}
          />
          <div className="radio-label">{I18n.t('form.YES')}</div>
        </label>
        <label>
          <Field
            className="radio-input"
            name={this.props.name}
            type="radio"
            component="input"
            value="false"
            format={value => String(value)}
            parse={value => value === 'true'}
          />
          <div className="radio-label">{I18n.t('form.NO')}</div>
        </label>
      </div>
    )
  }
}
export default RadioButton;
