// @flow

import { RestClientAuthenticated } from '../network';
import {
  SearchResultType,
  SearchCriteriaType,
  SearchResultContentItem,
  ProtocolDetail
} from '../../types/ProtocolManagement';
import data from './mockData/protocol.json'

import { notifyError } from '../../network/notification';

const apiEndpoint = {
  searchList: "/protocols/search",
  getById: "/protocols/:id",
  save: "/protocols",
  isCmcic: "/protocols/case/{caseId}/is-cmcic",
  update: "/protocols/:id"
}

const protocolApi = {
  fetchProtocolsCmCic: () => new RestClientAuthenticated('/protocols/cmcic', 'GET').execute(),
  fetchCheckProtocolsCmCic: (caseId: string) => new RestClientAuthenticated(`/protocols/case/${caseId}/is-cmcic`, 'GET').execute(),
  fetchProtocolsByCountryCode: () => new RestClientAuthenticated('/protocols/countryCode', 'GET').execute(),

  search: (searchCriteria: SearchCriteriaType): SearchResultType => (dispatch: Function) => {
    const queryString = Object.keys(searchCriteria).filter(v => searchCriteria[v] !== null).map(key => key + '=' + searchCriteria[key]).join('&');
    return new RestClientAuthenticated(`${apiEndpoint.searchList}?${queryString}`, 'GET').execute()
    .then(response => response.text())
    .then(text => (text.length ? JSON.parse(text) : null))
    .catch((e) => dispatch(notifyError)(e));
  },
  fetchById: (id: Number): ProtocolDetail => (dispatch: Function) => {
    return new RestClientAuthenticated(`${apiEndpoint.getById.replace(':id', id)}`, 'GET').execute().then(response => (response.status == 200) && response.json()).catch((e) => dispatch(notifyError)(e));
  },
  save: (data: ProtocolManagement) => new RestClientAuthenticated(`${apiEndpoint.save}`, 'POST').jsonBody(data).execute(),
  update: (id: number, data: ProtocolManagement) => new RestClientAuthenticated(`${apiEndpoint.update.replace(':id', id)}`, 'PUT').jsonBody(data).execute(),
};

const mockResponse = (searchCriteria: SearchCriteriaType): SearchResultType => {

  const firstPageIndex = (searchCriteria.page - 1) * searchCriteria.size;
  const lastPageIndex = firstPageIndex + searchCriteria.size;

  const resultContent = data.slice(firstPageIndex, lastPageIndex);

  const total = data.length;
  const per_page = Math.ceil(total / searchCriteria.size);
  return {
    content: resultContent,
    page: searchCriteria.page,
    per_page: per_page,
    total: total,
    total_pages: searchCriteria.size
  };
}

const mockResponseById = (id: Number): ProtocolDetail => {
  return data.find(v => v.id == id);
}

export interface ProtocolManagement {
  protocolNumber: string;
  bPartnerId: number;
  legalEntityId: number;
  brandId: number;
  logoId: number;
  phoneNo: string;
  numberOfConsultation: number;
  providerNetworkDetail: string;
  isThirdParty: boolean;
  isCreditType: boolean;
  isActive: boolean;
}


export default protocolApi;
