export function download(blobOrUrl, filename, cb) {
  if (typeof blobOrUrl === 'string') {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = blobOrUrl;
    a.download = filename;
    a.click();
  } else if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blobOrUrl, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blobOrUrl);
    a.download = filename;
    a.click();
  }
  if (cb) {
    cb();
  }
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function downloadBase64(b64Data, filename, cb) {
  const blob = b64toBlob(b64Data);
  download(blob, filename, cb);
}

export function cleanObject(object) {
  if (!object || !Object.keys(object)) return object;
  const result = JSON.parse(JSON.stringify(object));
  for (const key of Object.keys(object)) {
    if (!result[key]) delete result[key];
  }
  return result;
}

export function searchHistory(value, defaultValue) {
  return value ?
    defaultValue.filter(modification =>
      (!!modification.userFirstName
        && modification.userFirstName.toLowerCase().includes(value.toLowerCase()))
      || (!!modification.userLastName
        && modification.userLastName.toLowerCase().includes(value.toLowerCase()))
      || (!!modification.field
        && modification.field.toLowerCase().includes(value.toLowerCase()))
      || (!!modification.oldValue
        && modification.oldValue.toLowerCase().includes(value.toLowerCase()))
      || (!!modification.newValue
        && modification.newValue.toLowerCase().includes(value.toLowerCase()))
      || (!!modification.message
        && modification.message.toLowerCase().includes(value.toLowerCase()))
      || (!!modification.modificationType
        && modification.modificationType.toLowerCase().includes(value.toLowerCase()))
      || (!!modification.eventDate
        && modification.eventDate.toLowerCase().includes(value.toLowerCase())))
    : defaultValue;
}

/**
 * Creates a debounced function that delays invoking `func` until after `wait`
 * milliseconds have elapsed since the last time the debounced function was
 * invoked, or until the next browser frame is drawn. The debounced function
 * comes with a `cancel` method to cancel delayed `func` invocations and a
 * `flush` method to immediately invoke them. Provide `options` to indicate
 * whether `func` should be invoked on the leading and/or trailing edge of the
 * `wait` timeout. The `func` is invoked with the last arguments provided to the
 * debounced function. Subsequent calls to the debounced function return the
 * result of the last `func` invocation.
 * It was taken from the https://github.com/lodash/lodash library
 */
export function debounce(func, wait, options) {
  let lastArgs;
  let lastThis;
  let maxWait;
  let result;
  let timerId;
  let lastCallTime;

  let lastInvokeTime = 0;
  let leading = false;
  let maxing = false;
  let trailing = true;

  // Bypass `requestAnimationFrame` by explicitly setting `wait=0`.
  // const useRAF = (!wait && wait !== 0 && typeof root.requestAnimationFrame === 'function');

  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }
  wait = +wait || 0;
  if (isObject(options)) {
    leading = !!options.leading;
    maxing = 'maxWait' in options;
    maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : maxWait;
    trailing = 'trailing' in options ? !!options.trailing : trailing;
  }

  function invokeFunc(time) {
    const args = lastArgs;
    const thisArg = lastThis;

    lastArgs = lastThis = undefined;
    lastInvokeTime = time;
    result = func.apply(thisArg, args);
    return result;
  }

  function startTimer(pendingFunc, wait) {
    // if (useRAF) {
    //   root.cancelAnimationFrame(timerId);
    //   return root.requestAnimationFrame(pendingFunc);
    // }
    return setTimeout(pendingFunc, wait);
  }

  function cancelTimer(id) {
    // if (useRAF) {
    //   return root.cancelAnimationFrame(id);
    // }
    clearTimeout(id);
  }

  function leadingEdge(time) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time;
    // Start the timer for the trailing edge.
    timerId = startTimer(timerExpired, wait);
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result;
  }

  function remainingWait(time) {
    const timeSinceLastCall = time - lastCallTime;
    const timeSinceLastInvoke = time - lastInvokeTime;
    const timeWaiting = wait - timeSinceLastCall;

    return maxing
      ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke)
      : timeWaiting;
  }

  function shouldInvoke(time) {
    const timeSinceLastCall = time - lastCallTime;
    const timeSinceLastInvoke = time - lastInvokeTime;

    // Either this is the first call, activity has stopped and we're at the
    // trailing edge, the system time has gone backwards and we're treating
    // it as the trailing edge, or we've hit the `maxWait` limit.
    return (lastCallTime === undefined || (timeSinceLastCall >= wait) ||
      (timeSinceLastCall < 0) || (maxing && timeSinceLastInvoke >= maxWait));
  }

  function timerExpired() {
    const time = Date.now();
    if (shouldInvoke(time)) {
      return trailingEdge(time);
    }
    // Restart the timer.
    timerId = startTimer(timerExpired, remainingWait(time));
  }

  function trailingEdge(time) {
    timerId = undefined;

    // Only invoke if we have `lastArgs` which means `func` has been
    // debounced at least once.
    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    lastArgs = lastThis = undefined;
    return result;
  }

  function cancel() {
    if (timerId !== undefined) {
      cancelTimer(timerId);
    }
    lastInvokeTime = 0;
    lastArgs = lastCallTime = lastThis = timerId = undefined;
  }

  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now());
  }

  function pending() {
    return timerId !== undefined;
  }

  function debounced(...args) {
    const time = Date.now();
    const isInvoking = shouldInvoke(time);

    lastArgs = args;
    lastThis = this;
    lastCallTime = time;

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = startTimer(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === undefined) {
      timerId = startTimer(timerExpired, wait);
    }
    return result;
  }
  debounced.cancel = cancel;
  debounced.flush = flush;
  debounced.pending = pending;
  return debounced;
}
function isObject(value) {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

// TODO il faudrait réussir à faire ce traitement en back

export function groupHistoryByDateAndIdUser(historyToSort) {
  const groupByDate = historyToSort.reduce((result, history) => {
    if (!result[history.eventDate]) {
      result[history.eventDate] = [];
    }
    result[history.eventDate].push(history);
    return result;
  }, {});

  let groupByUser = {};

  Object.keys(groupByDate)
    .forEach(value => {
      const tmp = groupByDate[value]
        .reduce((result, history) => {
          if (!result[history.idUser]) {
            result[history.idUser] = [];
          }
          result[history.idUser].push(history);
          return result;
        }, {});
      if (!groupByUser[value]) {
        groupByUser[value] = {};
      }
      groupByUser[value] = tmp;
    });

  return groupByUser;
};

export function camelCase(str) {
  return (str.slice(0, 1).toLowerCase() + str.slice(1))
    .replace(/([-_ ]){1,}/g, ' ')
    .split(/[-_ ]/)
    .reduce((cur, acc) => {
      return cur + acc[0].toUpperCase() + acc.substring(1);
    });
}
