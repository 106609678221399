// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Filters from '../appointment/components/Filters';
import CalendarContainer from '../appointment/components/CalendarContainer';
import AddSlotModal from './components/AddSlotModal';
import appointmentApi from '../../network/api/appointmentApi';
import { notifyError } from '../../network/notification';
import { Col, Row } from 'reactstrap';
import DetailsAppointmentModal from '../appointment/components/DetailsAppointmentModal';

type Props = {
  history: Function,
  dispatch: Function,
};
type State = {
  searchUserProfile: string,
  isOpen: boolean,
  events: Object[],
  availabilityProfiles: Object[],
  loading: boolean,
  isDetailsOpen: boolean,
  appointments: Object[],
  date: Object[],

};

class Availability extends React.Component<Props, State> {
  state = {
    isOpen: false,
    searchUserProfile: null,
    availabilityProfiles: [],
    events: [],
    loading: false,
    isDetailsOpen: false,
    appointments: null,
    date: null,
  };

  componentDidMount() {
    appointmentApi.fetchAvailabilityProfiles()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((response) => {
        this.setState({ availabilityProfiles: response });
      })
      .catch(this.props.dispatch(notifyError));
  }

  search = (values) => {
    const { id } = values;
    this.setState({
      loading: true,
      searchUserProfile: this.state.availabilityProfiles.filter(availabilityProfile => availabilityProfile.id === values.id)[0],
    });
    appointmentApi.searchAppointments(id)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((response) => {
        this.setState({
          events: response.map(e =>
            ({
              ...e,
              start: new Date(e.startPeriod),
              end: new Date(e.endPeriod),
            })),
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(notifyError)(e);
      });
  };

  toggleModal = (toggle: boolean) => {
    this.setState({ isOpen: toggle });
  };

  onCloseModal = () => {
    this.search(this.state.searchUserProfile);
    this.setState({ isDetailsOpen: !this.state.isDetailsOpen });
  }

  openDetails = (events) => {
    if (!this.state.loading) {
      this.setState({
        isDetailsOpen: true,
        appointments: events.appointments,
        date: events.start,
      });
    }
  }

  render() {
    const {
      searchUserProfile, isOpen, availabilityProfiles, events, date, appointments, isDetailsOpen,
    } = this.state;
    return (
      <div className="appointment-container">
        <DetailsAppointmentModal
          events={appointments}
          date={date}
          visible={isDetailsOpen}
          toggle={() => this.setState({ isDetailsOpen: !isDetailsOpen })}
          onCloseModal={this.onCloseModal}
        />
        <AddSlotModal
          visible={isOpen}
          toggle={() => this.toggleModal(false)}
          selection={searchUserProfile}
          existingEvents={events}
          searchAvailabilityByUser={() => this.search(this.state.searchUserProfile)}
        />
        <div className="title-container">
          <div className="title">{I18n.t('availability.TITLE')}</div>
        </div>
        <Filters search={this.search} availabilityProfiles={availabilityProfiles} loading={this.state.loading} />
        {
          searchUserProfile && !this.state.loading &&
          <div>
            <div className="add-button">
              <button
                className="btn btn-primary "
                type="submit"
                onClick={() => this.toggleModal(true)}
                disabled={!searchUserProfile || this.state.loading}
              >
                <i className="button-icon fa fa-plus" />
                {I18n.t('actions.ADD')}
              </button>
            </div>
            <div>
              <Col>
                <Row className="legend">
                  <span className="empty-slot" /><span className="label" >{I18n.t('appointment.empty')} </span>
                </Row>
                <Row className="legend"><span className="booked-slot" /><span className="label" >{I18n.t('appointment.booked')} </span>
                </Row>
              </Col>
              <CalendarContainer events={events} openDetails={this.openDetails} />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default withRouter(connect()(Availability));
