// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n, setLocale } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { notifyError } from '../../network/notification';
import { getQueryParams } from '../../services/queryParamsService';
import notificationApi from '../../network/api/notificationApi';
import ModalEprm from '../../components/modal/ModalEprm';
import Loader from '../../components/Loader';
import { Button } from 'reactstrap';
import confApi from '../../network/api/confApi';

type Props = {
  dispatch: Function,
  location: {
    search: string,
  },
  match: {
    params: {
      token: string
    }
  }
};

type State = {
  loading: boolean,
  loadingUrl: boolean,
  redirectUrl: string
};

class CancelFax extends React.Component<Props, State> {
  state = {
    loading: true,
    loadingUrl: true,
    redirectUrl: undefined,
  };

  componentDidMount() {
    const queryParams = getQueryParams(this.props.location.search);
    const { token } = this.props.match.params;
    this.props.dispatch(setLocale(queryParams.lang));
    notificationApi
      .cancelFax(token)
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch(notifyError);
    confApi
      .fetchPdaUrl(queryParams.lang)
      .then(response => response.text())
      .then((redirectUrl) => {
        this.setState({
          redirectUrl,
          loadingUrl: false,
        });
      });
  }


  render() {
    return (
      <ModalEprm
        title={I18n.t('fax.CANCEL_TITLE')}
        modal
        toggle={() => {
        }}
      >
        <div className="fax-modal-content">

          {this.state.loading ?
            <div>
              <div>{I18n.t('fax.CANCELLING')}</div>
              <Loader />
            </div>
            :
            <div>
              <div>{I18n.t('fax.CANCEL')}</div>
              <i className="fax-success-icon fa fa-check-circle" />
            </div>
          }
          {!this.state.loadingUrl && this.state.redirectUrl &&
          <Button
            color="primary"
            onClick={() => {
              window.location = this.state.redirectUrl;
            }}
          >
            {I18n.t('fax.BACK')}
          </Button> }
        </div>
      </ModalEprm>
    );
  }
}

export default withRouter(connect(state => ({
  locale: state.i18n.locale,
}))(CancelFax));
