// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import Button from 'reactstrap/es/Button';
import moment from 'moment';

import EligibilitySuccess from './components/EligibilitySuccess';
import EligibilityFail from './components/EligibilityFail';
import EligibilityOpenCase from './components/EligibilityOpenCase';
import { CASE_PROCESS_PAGE_ROUTE, ELIGIBILITY_PAGE_ROUTE, IDENTIFICATION_PAGE_ROUTE, PROCESS_DETAILS_PAGE_ROUTE } from '../../routes';
import ModalEprm from '../../components/modal/ModalEprm';
import processApi from '../../network/api/processApi';
import type { ShortPatient } from '../../network/api/patientApi';
import patientApi from '../../network/api/patientApi';
import { createSearchFromParams } from '../../services/queryParamsService';
import CreatePatientModal from './components/CreatePatientModal';
import Tile from '../../components/Tile';
import { ghostPolicy } from '../../enum';
import { notifyError } from '../../network/notification';
import { connect } from 'react-redux';
import { VisaEligibility } from '../../types/PolicyVisaType';
import policiesApi from '../../network/api/policiesApi';

type Props = {
  history: Function,
  location: any,
};

type State = {
  modal: boolean,
  createPatientModal: boolean,
  openCase: boolean,
  loading: boolean,
  skipEligibility: boolean,
  patientInfo: ShortPatient,
  gatewayIsDown: boolean,
};

type Policy = {
  policyNumber: string,
  protocolNumber: string,
  partnerName: string,
  startDate: string,
  expiryDate: string,
  patientId: string,
  beneficiaryFirstName: string,
  beneficiaryLastName: string,
  beneficiaryBirthday: string,
  policyHolderFirstName: string,
  policyHolderLastName: string,
  isSearchBeneficiary: boolean
};

type Patient = {
  lastName: string,
  firstName: string,
  birthDate: string
};

class Eligibility extends React.Component<Props, State> {
  state = {
    modal: false,
    createPatientModal: false,
    openCase: true,
    loading: false,
    skipEligibility: false,
    patientInfo: null,
    gatewayIsDown: this.props.location.state.policiesInfo.gatewayIsDown,
  };

  hideOpenCases = () => {
    if (this.props.location.state.isVisa) {
      this.processVisa();
    } else {
      this.scrollToTop();
      this.setState({ openCase: false });
    }
  };

  app: HTMLElement;

  componentDidMount() {
    this.app = document.querySelector('#main-content');
    this.scrollToTop();
  }

  processVisa = () => {
    const policiesInfo = { ...this.props.location.state.policiesInfo };

    this.props.history.push(CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE + createSearchFromParams({
      patientId: policiesInfo.patient.id,
      protocolNumber: policiesInfo.patient.policy.protocolNumber,
      lastName: policiesInfo.patient.lastName,
      firstName: policiesInfo.patient.firstName,
      birthDate: policiesInfo.patient.birthDate ? moment(policiesInfo.patient.birthDate, 'YYYY-MM-DD') : null,
      policyNumber: policiesInfo.patient.policy.policyNumber,
      expiryDate: policiesInfo.patient.policy.expiryDate ? moment(policiesInfo.patient.policy.expiryDate, 'YYYY-MM-DD') : null,
      partnerName: policiesInfo.patient.partnerName,
      beneficiaryBirthday: policiesInfo.patient.policy.beneficiaryBirthday ? moment(policiesInfo.patient.policy.beneficiaryBirthday, 'YYYY-MM-DD') : null,
      beneficiaryFirstName: policiesInfo.patient.policy.beneficiaryFirstName,
      beneficiaryLastName: policiesInfo.patient.policy.beneficiaryLastName,
      policyHolderFirstName: policiesInfo.patient.policy.holderFirstName,
      policyHolderLastName: policiesInfo.patient.policy.holderLastName,
      isPdaAccount: policiesInfo.patient.isPdaAccount,
    }));
  }

  handleSubmit = (values: any) => {
    this.setState({ loading: true });
    const { firstName, lastName, birthDate } = values.patient || {};
    const { policyHolderFirstName, policyHolderLastName, policy: policyNumber } = values.policyHolder || {};

    let payload = {};

    if (values.spelling !== null && !values.spelling) {
      payload = {
        firstName,
        lastName,
        birthDate: birthDate && moment(birthDate).format('YYYY-MM-DD') || null,
      };
    } else {
      payload = {
        policyHolderFirstName: values.policyHolderCheck ? firstName : policyHolderFirstName,
        policyHolderLastName: values.policyHolderCheck ? lastName : policyHolderLastName,
        policyNumber: policyNumber || null,
      };
    }

    processApi.checkEligibility(payload).then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((policiesInfo) => {
        this.setState({ loading: false });
        this.setState({ gatewayIsDown: policiesInfo.gatewayIsDown });
        const stateData = JSON.stringify({
          policiesInfo,
          firstInfo: this.props.location.state.firstInfo,
          identification: {
            ...values,
            patient: {
              ...values.patient,
              policy: !values.spelling ? null : policyNumber,
            },
          },
        });
        this.props.history.replace(
          CASE_PROCESS_PAGE_ROUTE + ELIGIBILITY_PAGE_ROUTE,
          JSON.parse(stateData),
        );
      })
      .catch((e) => {
        this.props.dispatch(notifyError)(e);
        this.setState({ loading: false });
      });
  };

  createPatient = (patient: ShortPatient, isSkipEligibility: boolean): void => {
    patientApi.create(patient)
      .then(response => response.text())
      .then((patientId) => {
        if (isSkipEligibility) {
          this.props.history.push(CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE + createSearchFromParams({
            ...patient,
            patientId,
            policyNumber: ghostPolicy.POLICY_NUMBER,
            protocolNumber: ghostPolicy.PROTOCOL_NUMBER,
            partnerName: ghostPolicy.PARTNER_NAME,
            legalEntityName: ghostPolicy.LEGAL_ENTITY_NAME,
          }));
        } else {
          this.props.history.push(CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE + createSearchFromParams({
            ...patient,
            patientId,
            birthDate: patient.birthDate ? moment(patient.birthDate).format('YYYY-MM-DD') : null,
            beneficiaryBirthday: patient.beneficiaryBirthday ? moment(patient.beneficiaryBirthday).format('YYYY-MM-DD') : null,
          }));
        }
      })
      .catch(e => console.log('Erreur apppel API', e));
  };

  updatePatient = (infos: ShortPatient, idPrm: string): Promise<String> => new Promise((resolve, reject) => {
    patientApi.update(infos, idPrm)
      .then(response => resolve(response))
      .catch(reject);
  });

  scrollToTop = () => {
    this.app.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  showCreatePatientModale = (patientInfo: ShortPatient, isSkipEligibility: boolean, policyInfo: any) => {
    this.setState({
      createPatientModal: true,
      skipEligibility: isSkipEligibility,
      patientInfo,
    });
  };

  findPatientOnDummyPolicy = (): Policy => {
    const { policies } = this.props.location.state.policiesInfo;
    const policyInfo: Policy = policies && policies.find(policy => ghostPolicy.PROTOCOL_NUMBER === policy.protocolNumber && ghostPolicy.POLICY_NUMBER === policy.policyNumber && policy.patient.id);
    return policyInfo;
  }

  updatePatientInfo = (policySelectedInfo: Policy, patient: Patient): void => {
    // this.updatePatient({
    //   lastName: patient.lastName,
    //   firstName: patient.firstName,
    //   birthdate: patient.birthdate,
    //   policyNumber: policySelectedInfo.policyNumber,
    //   protocolNumber: policySelectedInfo.protocolNumber,
    //   startDate: policySelectedInfo.startDate,
    //   expiryDate: policySelectedInfo.expiryDate,
    //   partnerName: policySelectedInfo.partnerName,
    // }, policySelectedInfo.patient.id)
    //   .then(() => {
    //     this.props.history.push(CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE + createSearchFromParams({
    //       idPatient: policySelectedInfo.patient.prmId,
    //       protocolNumber: policySelectedInfo.protocolNumber,
    //       lastName: patient.lastName,
    //       firstName: patient.firstName,
    //       birthdate: patient.birthdate ? moment(patient.birthdate, 'YYYY-MM-DD') : null,
    //       policyNumber: policySelectedInfo.policyNumber,
    //       startDate: policySelectedInfo.startDate,
    //       expiryDate: policySelectedInfo.expiryDate,
    //       partnerName: policySelectedInfo.partnerName,
    //     }));
    //   })
    //   .catch(e => console.log('Erreur apppel API', e));
    this.props.history.push(CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE + createSearchFromParams({
      patientId: policySelectedInfo.patient.id,
      protocolNumber: policySelectedInfo.protocolNumber,
      lastName: patient.lastName,
      firstName: patient.firstName,
      birthDate: patient.birthDate ? moment(patient.birthDate).format('YYYY-MM-DD') : null,
      policyNumber: policySelectedInfo.policyNumber,
      startDate: policySelectedInfo.startDate,
      expiryDate: policySelectedInfo.expiryDate,
      partnerName: policySelectedInfo.partnerName,
    }));
  }

  goToNextStep = (policySelectedInfo: Policy): void => {
    const { patient, policyHolder, policyHolderCheck } = this.props.location.state.identification;

    if (!policySelectedInfo) {
      const policyDummyInfo: Policy = this.findPatientOnDummyPolicy();

      if (policyDummyInfo) {
        this.updatePatientInfo(policyDummyInfo, patient);
      } else {
        patient.policyNumber = ghostPolicy.POLICY_NUMBER;
        patient.protocolNumber = ghostPolicy.PROTOCOL_NUMBER;
        patient.partnerName = ghostPolicy.PARTNER_NAME;
        patient.legalEntityName = ghostPolicy.LEGAL_ENTITY_NAME;
        patient.birthDate = moment(patient.birthDate).format('YYYY-MM-DD');
        this.showCreatePatientModale(patient, true);
      }
    } else if (policySelectedInfo.patient && policySelectedInfo.patient.id) {
      this.updatePatientInfo(policySelectedInfo, patient);
    } else {
      let patientFirstName;
      let patientLastName;
      let policyHolderFirstName;
      let policyHolderLastName;

      if (patient.lastName && patient.lastName.indexOf('*') === -1) {
        patientLastName = patient.lastName;
      } else {
        patientLastName = policySelectedInfo.beneficiaryLastName;
      }

      if (patient.firstName && patient.firstName.indexOf('*') === -1) {
        patientFirstName = patient.firstName;
      } else {
        patientFirstName = policySelectedInfo.beneficiaryFirstName;
      }

      if (policyHolderCheck) {
        policyHolderFirstName = patientFirstName;
        policyHolderLastName = patientLastName;
      } else if (policyHolder) {
        policyHolderFirstName = policyHolder.policyHolderFirstName;
        policyHolderLastName = policyHolder.policyHolderLastName;
      } else if (policySelectedInfo.policyHolderFirstName && policySelectedInfo.policyHolderLastName) {
        policyHolderFirstName = policySelectedInfo.policyHolderFirstName;
        policyHolderLastName = policySelectedInfo.policyHolderLastName;
      }

      this.showCreatePatientModale({
        lastName: patientLastName,
        firstName: patientFirstName,
        policyHolderFirstName,
        policyHolderLastName,
        birthDate: patient.birthDate ? moment(patient.birthDate).format('YYYY-MM-DD') : policySelectedInfo.beneficiaryBirthday,
        policyNumber: policySelectedInfo.policyNumber,
        protocolNumber: policySelectedInfo.protocolNumber,
        startDate: policySelectedInfo.startDate,
        expiryDate: policySelectedInfo.expiryDate,
        partnerName: policySelectedInfo.partnerName,
        beneficiaryBirthday: policySelectedInfo.beneficiaryBirthday,
        beneficiaryFirstName: policySelectedInfo.beneficiaryFirstName,
        beneficiaryLastName: policySelectedInfo.beneficiaryLastName,
        isSearchBeneficiary: policySelectedInfo.isSearchBeneficiary,
      }, false);
    }
  };

  showEligibilityTile = () => {
    const areThereOpenCase = (this.props.location.state && !!this.props.location.state.policiesInfo.openCases && this.props.location.state.policiesInfo.openCases.length > 0 && this.state.openCase);
    const areTherePoliciesEligibility = (this.props.location.state && !!this.props.location.state.policiesInfo.policies && this.props.location.state.policiesInfo.policies.length > 0);
    if (areThereOpenCase && this.state.openCase) {
      return (<EligibilityOpenCase
        hideOpenCase={this.hideOpenCases}
        openCases={this.props.location.state.policiesInfo.openCases}
        firstInfo={this.props.location.state.firstInfo}
      />);
    } else if (areTherePoliciesEligibility && (!areThereOpenCase || !this.state.openCase)) {
      return (<EligibilitySuccess
        policies={this.props.location.state.policiesInfo.policies}
        patient={this.props.location.state.identification.patient}
        nextStep={this.goToNextStep}
      />);
    }
    return (<EligibilityFail
      identification={this.props.location.state.identification}
      submitFunction={this.handleSubmit}
      loading={this.state.loading}
    />);
  }

  render() {
    return (
      <div className="process-case-step">
        <div className="step-title">
          <Button
            color="secondary"
            onClick={() => this.props.history.push({
              pathname: CASE_PROCESS_PAGE_ROUTE + IDENTIFICATION_PAGE_ROUTE,
              state: { firstInfo: this.props.location.state.firstInfo },
            })}
          >
            <i className="button-icon fa fa-step-backward" />{I18n.t('button.PREVIOUS')}
          </Button>
          <div>
            <i className="fa fa-file-search" />{I18n.t('processCaseHeader.steps.ELIGIBILITY')}
          </div>
          { !this.props.location.state.isVisa &&
          <Button color="secondary" onClick={() => this.setState({ modal: !this.state.modal })}>
            <i className="button-icon fa fa-step-forward" />{I18n.t('button.SKIP_ELIGIBILITY')}
          </Button>
          }
          { this.props.location.state.isVisa && <div />}

        </div>
        <ModalEprm
          title={I18n.t('button.SKIP_ELIGIBILITY')}
          modal={this.state.modal}
          toggle={() => this.setState({ modal: !this.state.modal })}
        >
          <div className="take-in-charge-message">
            {I18n.t('processCase.eligibility.SKIP_ELIGIBILITY')}
          </div>
          <div className="take-in-charge-buttons">
            <Button
              color="secondary"
              onClick={() => this.setState({ modal: !this.state.modal })}
            >
              <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
            </Button>
            <Button
              color="primary"
              onClick={() => this.goToNextStep(null)}
            >
              <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.SKIP_ELIGIBILITY')}
            </Button>
          </div>
        </ModalEprm>
        <div className="step-container">
          {this.state.gatewayIsDown &&
          <div>
            <Tile className="tile-eligibility eligibility-not-found">
              <i className="fa fa-user-times" />
              <div className="eligibility-result">
                {I18n.t('processCase.eligibility.result.WARNING_GATEWAY_DOWN')}
              </div>
            </Tile>
          </div>
          }
          {this.showEligibilityTile()}
        </div>

        <CreatePatientModal
          modal={this.state.createPatientModal}
          toggle={() => this.setState({ createPatientModal: !this.state.createPatientModal })}
          patientInfosToSave={this.state.patientInfo}
          submitFunction={this.createPatient}
        />
      </div>
    );
  }
}

export default connect()(Eligibility);
