// @flow
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozDatePickerBase, CoreozInputBase } from '../../lib/coreoz-form-base/index';
import moment from 'moment';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dateValidator } from '../../validator';
import patientApi from '../../network/api/patientApi';
import { notifyError } from '../../network/notification';
import type { PatientSearchResultType, PatientSearchType } from '../../types/PatientTypes';
import PatientTile from '../../components/patients/PatientTile';
import Loader from '../../components/Loader';
import PaginatedList from '../../components/PaginatedList';

type State = {
  patients: ?PatientSearchResultType[],
  patientSearch: ?PatientSearchType,
  loading: boolean,
  isLast: boolean,
}
type Props = {
  dispatch: Function,
  location: {
    state: PatientSearchType,
  }
}

class PatientSearch extends React.Component<Props, State> {
  state = {
    patients: null,
    patientSearch: null,
    loading: true,
    isLastPatientsDisplayed: true,
  };


  fetchPatients = (values: PatientSearchType) => {
    this.setState({
      loading: true,
      patients: null,
      isLastPatientsDisplayed: false
    });
    patientApi.search(values)
      .then(response => response.text())
      .then(text => text.length ? JSON.parse(text) : null)
      .then(patients =>
        this.setState({
          patients: patients || [],
          patientSearch: values,
          loading: false,
        })
      )
      .catch((e) => {
        this.setState({
          loading: false,
        })
        this.props.dispatch(notifyError)(e);
      });
  };

  fetchLastPatients = () => {
    patientApi.fetchLastPatientsByRole()
      .then(response => response.text())
      .then(text => text.length ? JSON.parse(text) : [])
      .then(patients => this.setState({
        patients,
        loading: false,
      }))
      .catch((e) => {
        this.setState({ loading: false });
        notifyError(this.props.dispatch)(e);
      });
  }

  componentDidMount() {
    if (this.props.location.state && Object.keys(this.props.location.state).length) {
      this.fetchPatients(this.props.location.state);
    } else {
      this.fetchLastPatients();
    }
  }

  displayCriteria = () => {
    const { patientSearch } = this.state;
    if (!patientSearch) {
      return '';
    }

    let res = `${patientSearch.firstName ? `${I18n.t('patientSearch.FIRSTNAME')} : ${patientSearch.firstName}, ` : ''
      }${patientSearch.lastName ? `${I18n.t('patientSearch.LASTNAME')} : ${patientSearch.lastName}, ` : ''
      }${patientSearch.birthDate ? `${I18n.t('patientSearch.DATE_OF_BIRTH')} : ${moment(patientSearch.birthDate).format(I18n.t('date.DATE_FORMAT'))}, ` : ''
      }`;
    if (res.length > 2 && res[res.length - 1] === ' ' && res[res.length - 2] === ',') {
      return res.slice(0, -2);
    }
    return res;
  };

  render() {
    return (
      <div className="patient-search-container">
        <div className="title-container">
          <div className="title">{I18n.t('patientSearch.TITLE')}</div>
        </div>
        <div className="search-bar">
          <Form
            onSubmit={this.fetchPatients}
            initialValues={this.props.location.state || {}}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="firstName"
                  component={CoreozInputBase}
                  placeholder={I18n.t('patientSearch.FIRSTNAME')}
                />
                <Field
                  name="lastName"
                  component={CoreozInputBase}
                  placeholder={I18n.t('patientSearch.LASTNAME')}
                />
                <Field
                  component={CoreozDatePickerBase}
                  placeholder={I18n.t('form.placeholder')}
                  onlyDate
                  validate={e => dateValidator(e, I18n.t('date.DATE_FORMAT_ADMINISTRATION_FILE'))}
                  dateFormat={I18n.t('date.DATE_FORMAT')}
                  name="birthDate"
                  format={(stringValue) => {
                    if (stringValue) {
                      if (moment(stringValue, 'DD/MM/YYYY', true).isValid()) {
                        return moment(stringValue, 'DD/MM/YYYY');
                      }
                      if (moment(stringValue, 'YYYY-MM-DD', true).isValid()) {
                        return moment(stringValue, 'YYYY-MM-DD');
                      }
                    }
                    return stringValue;
                  }}
                  parse={momentValue => (momentValue && moment.isMoment(momentValue) ? momentValue.format('YYYY-MM-DD') : momentValue)}
                />
                <Button
                  type="submit"
                  color="primary"
                  className="validation-button"
                >
                  <i className="button-icon fa fa-search" />
                  {I18n.t('button.SEARCH')}
                </Button>
              </form>
            )}
          />
        </div>
        <div className="search-result">
          <div className="result-title">{I18n.t('patientSearch.SEARCH_RESULT')}</div>
          {this.state.patients &&
            <div className="result-list">
              <div className="criteria">
                {
                  this.state.isLastPatientsDisplayed ?
                    <div>{I18n.t('patientSearch.LAST_PATIENTS_FOUND', { count: this.state.patients.length })}</div>
                    :
                    <div>
                      <div>{I18n.t('patientSearch.PATIENTS_FOUND', { count: this.state.patients.length })}</div>
                      <div>{this.displayCriteria()}</div>
                    </div>
                }
                {
                  this.state.patients &&
                  <PaginatedList
                    pageSize={10}
                    dataArray={this.state.patients}
                    render={patient => (
                      <PatientTile
                        key={patient.id}
                        patient={patient}
                        lastSearchObject={this.state.patientSearch}
                      />
                    )}
                  />
                }
              </div>
            </div>
          }

          {this.state.loading &&
            <div>
              {
                this.state.isLastPatientsDisplayed &&
                <div className="no-result">{I18n.t('patientSearch.WAIT_10_LAST_PATIENTS')}</div>
              }
              <div className="center-child search-loader"><Loader /></div>
            </div>}
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(PatientSearch));
