// @flow

import { RestClientAuthenticated } from '../network';

export type Pharmacy = {
  name: string;
  address: string;
  city: string;
  country: string;
  faxNumber: string;
  phoneNumber: string;
  postalCode: string;
}
const referentialApi = {
  getAllCountries: () => new RestClientAuthenticated('/referential/countries', 'GET').execute(),
  getAllTimezone: () => new RestClientAuthenticated('/referential/timezone', 'GET').execute(),
  fetchCountriesBySelectedLanguage: (countryCode: string) => new RestClientAuthenticated(`/referential/countriesbyselectedlanguage/${countryCode}`, 'GET').execute(),
  getOneCountry: (countryId: string) => new RestClientAuthenticated(`/referential/countries/${countryId}`, 'GET').execute(),
};
export default referentialApi;
