// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Filters from './components/Filters';
import CalendarContainer from './components/CalendarContainer';
import DetailsAppointmentModal from './components/DetailsAppointmentModal';
import appointmentApi from '../../network/api/appointmentApi';
import { notifyError } from '../../network/notification';


type Props = {};

type State = {
  isOpen: boolean,
  events: Object[],
  appointments: Object[],
  availabilityProfiles: Object[]
};

class Appointment extends React.Component<Props, State> {
  state: State = {
    isOpen: false,
    appointments: null,
    events: [],
    searchUserProfile: null,
    date: null,
    availabilityProfiles: [],
    loading: false,
  };

  componentDidMount() {
    appointmentApi.fetchAvailabilityProfiles()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((response) => {
        this.setState({ availabilityProfiles: response });
      })
      .catch(this.props.dispatch(notifyError));
  }

  openDetails = (events) => {
    if (!this.state.loading) {
      this.setState({
        isOpen: true,
        appointments: events.appointments,
        date: events.start,
      });
    }
  };

  search = (values) => {
    const { id } = values;
    this.setState({ loading: true });

    appointmentApi.searchAppointments(id)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then((response) => {
        this.setState({
          events: response.map(e =>
            ({
              ...e,
              start: new Date(e.startPeriod),
              end: new Date(e.endPeriod),
            })),
          loading: false,
        });
      })
      .catch(this.props.dispatch(notifyError));
    this.setState({
      searchUserProfile: this.state.availabilityProfiles.filter(availabilityProfile => availabilityProfile.id === values.id)[0],
    });
  };

  onCloseModal = () => {
    this.search(this.state.searchUserProfile);
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      isOpen, appointments, date, availabilityProfiles, events, searchUserProfile,
    } = this.state;
    return (
      <div className="appointment-container">
        <DetailsAppointmentModal
          events={appointments}
          date={date}
          visible={isOpen}
          toggle={() => this.setState({ isOpen: !isOpen })}
          onCloseModal={this.onCloseModal}
        />
        <div className="title-container">
          <div className="title">{I18n.t('appointment.TITLE')}</div>
        </div>
        <Filters search={this.search} availabilityProfiles={availabilityProfiles} loading={this.state.loading} />
        {
          searchUserProfile && !this.state.loading &&
          <div>
            <Col>
              <Row className="legend">
                <span className="empty-slot" />
                <span className="label" >
                  {I18n.t('appointment.empty')}
                </span>
              </Row>
              <Row className="legend"><span className="booked-slot" />
                <span className="label">{I18n.t('appointment.booked')}</span>
              </Row>
            </Col>
            <CalendarContainer events={events} openDetails={this.openDetails} />
          </div>
        }

      </div>
    );
  }
}

export default withRouter(connect()(Appointment));
