// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'reactstrap';

import type { TeamDetail } from '../../types/OperationsType';
import CoreozDrawer from '../CoreozDrawer';
import { Roles } from '../../enum';

type Props = {
  teamList: TeamDetail[],
  showTeamDrawer: boolean,
  toggleTeamDrawer: Function,
}

type TeamMemberTileProps = {
  teamMember: TeamDetail,
}

function TeamMemberTile({ teamMember }: TeamMemberTileProps) {
  return (
    <div className="team-member-tile">
      <div className="team-member-name">
        {teamMember.firstName} {teamMember.lastName}
      </div>
      <div className={`team-member-availability ${teamMember.isAvailable ? 'available' : ''}`} />
    </div>
  )
}

export default function TeamAvailabilities({ teamList, showTeamDrawer, toggleTeamDrawer }: Props) {
  const listTeams = Object.keys(Roles).map(role => ({
    headerText: I18n.t(`operations.team.CONNECTED_${role}`),
    teamMembers: teamList.filter(teamPerson => teamPerson.type === role),
  }));
  return (
    <CoreozDrawer show={showTeamDrawer}>
      <div className="operation-drawer">
        <header>
          <button className="drawer-backTo" onClick={toggleTeamDrawer}>
            <i className="fa fa-arrow-left" />
            <span>{I18n.t('medicalProfile.lastDocumentsDrawer.backTo')}</span>
          </button>
          <div className="drawer-title">
            <h1>{I18n.t('operations.TEAM_AVAILABILITIES')}</h1>
          </div>
        </header>
        <div className="team-availabilities-detail">
          <Row>
            {
              listTeams.map(team => (
                <Col xs="12" lg="6">
                  <div className="team-availability-header">
                    {team.headerText}
                  </div>
                  <div className="team-list">
                    {
                      team.teamMembers.map(teamMember =>
                        (<TeamMemberTile teamMember={teamMember} />))
                    }
                  </div>
                </Col>
              ))
            }
          </Row>
        </div>
      </div>
    </CoreozDrawer>
  );
}
