// @flow

import React from 'react';

type Props = {
  children?: any,
  className: string,
}

class Tile extends React.PureComponent<Props> {
  render() {
    return (
      <div className={`tile shadow ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default (Tile);
