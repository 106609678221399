import React, { useState, useEffect, useContext, useRef } from 'react';
import Pagination from 'react-js-pagination';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Loader from '../../components/Loader';
import { CoreozInputBase, CoreozSelectBase } from '../../lib/coreoz-form-base/index';
import { BrandStatus } from '../../enum';
import { SearchCriteriaType, SearchResultType } from '../../types/BrandManagement';
import userApi from '../../network/api/userApi';
import {
  BRAND_CREATE_PAGE_ROUTE,
  BRAND_EDIT_PAGE_ROUTE,
} from '../../routes';
import BrandContext from '../../stores/brand-management-context';
import brandApi from '../../network/api/brandApi';
import BrandTable from './components/BrandTable';
import exportApi from '../../network/api/exportApi';
import { download } from '../../utils';
import { notifyError } from '../../network/notification';
import ButtonExport from '../../components/ButtonExport';

const BrandManagement = (props) => {
  const { searchCriteriaContext, updateSearchCriteriaContext } = useContext(BrandContext);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSearchResult, setTotalSearchResult] = useState(0);
  const [businessUnits, setBusinessUnits] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    fetchBusinessUnits();
    if (searchCriteriaContext) {
      fetchBrands(searchCriteriaContext);
    } else {
      fetchBrands({
        page,
        size: pageSize,
      });
    }
  }, []);


  async function fetchBrands(searchCriteria: SearchCriteriaType) {
    setLoading(true);

    if (!searchCriteria.page) {
      searchCriteria.page = page;
    }
    if (!searchCriteria.size) {
      searchCriteria.size = pageSize;
    }

    await new Promise(res => setTimeout(res, 1000));

    const searchResultResponse: SearchResultType = await props.dispatch(brandApi.search(searchCriteria));

    updateSearchCriteriaContext({ ...searchCriteria });

    setLoading(false);

    setSearchResults((searchResultResponse) ? searchResultResponse.content : []);

    setTotalSearchResult((searchResultResponse) ? searchResultResponse.total : 0);
    setPerPage((searchResultResponse) ? searchResultResponse.perPage : 0);
    setTotalPages((searchResultResponse) ? searchResultResponse.totalPages : 0);
  }

  async function fetchBusinessUnits() {
    const businessUnitsResponse = await userApi.fetchBusinessUnits().then(response => response.json());

    if (businessUnitsResponse) {
      setBusinessUnits(businessUnitsResponse);
    }
  }

  const handlePageChange = (pageNumber) => {
    fetchBrands({
      ...searchCriteriaContext,
      page: pageNumber,
    });
    setPage(pageNumber);
  };

  const resetSearchResults = () => {
    setSearchResults([]);
    setTotalSearchResult(0);
    setPage(1);
    setTotalPages(0);

    fetchBrands({
      page,
      size: pageSize,
    });
  };

  const goToView = (id: string) => {
    props.history.push(BRAND_EDIT_PAGE_ROUTE.replace(':id', id));
  };

  const downloadReport = (searchParameters: Object) => {
    setExportLoading(true);

    const filters = {
      ...searchParameters,
      status: searchParameters.status || '',
      columnsTranslation: I18n.t('brandManagement.export'),
    };

    exportApi
      .exportBrandKpi(filters)
      .then(response => response.blob())
      .then(blob =>
        download(blob, 'export_brand.csv', () => {
          setExportLoading(false);
        })).catch((e) => {
        setExportLoading(false);
        notifyError(props.dispatch)(e);
      });
  };

  const isStatus = (status: string) => {
    if (status) {
      return status === 'true' ? 'ACTIVE' : 'INACTIVE';
    }
    return null;
  };

  return (
    <div className="brand-management-container" id="brandManagement">
      <div className="title-container">
        <div className="title">{I18n.t('brandManagement.TITLE_LIST')}</div>
      </div>
      <div className="search-bar">
        <Form
          onSubmit={fetchBrands}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>

              <Field
                name="brandCode"
                id="brandCodeSearch"
                label={I18n.t('brandManagement.label.BRAND_CODE')}
                component={CoreozInputBase}
                className="m-0"
                defaultValue={searchCriteriaContext && searchCriteriaContext.brandCode}
                maxLength="9"
                format={stringValue => stringValue && stringValue.replace(/[^\d]/g, '')}
              />
              <Field
                name="brandName"
                id="brandNameSearch"
                label={I18n.t('brandManagement.label.BRAND_NAME')}
                component={CoreozInputBase}
                className="m-0"
                defaultValue={searchCriteriaContext && searchCriteriaContext.brandName}
              />


              <div className="layout-third-row search-box pt-4">
                <Button type="submit" color="primary" className="validation-button m-0" id="searchBtnSearch">
                  <i className="fa fa-search" />{I18n.t('button.SEARCH')}
                </Button>
                <Button type="button" color="primary" className="validation-button m-0" onClick={() => { form.reset(); resetSearchResults(); }} id="resetBtnSearch">
                  <i className="fa fa-redo-alt" />{I18n.t('button.RESET')}
                </Button>
              </div>

              <Field
                name="businessUnit"
                id="businessUnit"
                autoComplete="off"
                type="text"
                className="m-0"
                component={CoreozSelectBase}
                label={I18n.t('brandManagement.label.BUSINESS_UNIT')}
                list={businessUnits && businessUnits.map(bu => ({
                                    id: bu.id,
                                    label: bu.name,
                                }))}
                required={false}
                placeholder="All"
                disabledPlaceholder={false}
                defaultValue={searchCriteriaContext && searchCriteriaContext.businessUnit}
              />

              <Field
                name="status"
                id="status"
                type="text"
                className="m-0"
                component={CoreozSelectBase}
                label={I18n.t('brandManagement.label.STATUS')}
                placeholder="All"
                disabledPlaceholder={false}
                list={Object.keys(BrandStatus).map(v => ({ id: v, label: I18n.t(`brandManagement.STATUS.${v}`) }))}
                required={false}
                defaultValue={searchCriteriaContext && searchCriteriaContext.status}
              />
              <div className="layout-third-row search-box pt-4">

                <ButtonExport downloadReport={downloadReport} loading={exportLoading} values={values} />
                <Button type="button" color="primary" className="validation-button m-0" onClick={() => { props.history.push(BRAND_CREATE_PAGE_ROUTE); }} id="createButton">
                  <i className="fa fa-plus" />{I18n.t('brandManagement.button.ADD')}
                </Button>
              </div>
            </form>
                    )}
        />
      </div>

      <div className="search-result">
        {!loading &&
                    <>
                      <div className="box-result">
                        <BrandTable currentItems={searchResults} goToView={goToView} />
                      </div>
                      <div className="box-paginate">
                        <div className="show-paginate-result">
                          {I18n.t('brandManagement.TABLE.PAGINATION_INFO', { start: ((page - 1) * pageSize) + 1, end: ((page - 1) * pageSize) + pageSize, total: totalSearchResult })}
                        </div>
                        <div className="paginate-list">
                          <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="&laquo;"
                            lastPageText="&raquo;"
                            prevPageText="&lsaquo;"
                            nextPageText="&rsaquo;"
                            activePage={page}
                            itemsCountPerPage={totalPages}
                            totalItemsCount={totalSearchResult}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </>
                }
        { loading && <div className="center-child search-loader"><Loader /></div>}
      </div>
    </div>
  );
};

export default withRouter(connect()(BrandManagement));
