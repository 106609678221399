

import React, { createContext, useState } from 'react';
import { SearchCriteriaType } from '../types/ProtocolManagement';

const ProtocolContext = createContext({
    searchCriteriaContext: null,
    updateCriteriaSearchContext: (searchCriteria: SearchCriteriaType) => { }
});

export function ProtocolContextProvider(props) {

    const [useSearchCriteria, setUseSearchCriteria] = useState(null);

    function updateSearchCriteriaHandler(searchCriteria: SearchCriteriaType) {
        setUseSearchCriteria(searchCriteria);
    }

    const context = {
        searchCriteriaContext: useSearchCriteria,
        updateSearchCriteriaContext: updateSearchCriteriaHandler
    };

    return (
        <ProtocolContext.Provider value={context}>
            {props.children}
        </ProtocolContext.Provider>
    );
}

export default ProtocolContext;