import { Button } from 'reactstrap';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Loader from './Loader';


type Props = {
    loading: boolean,
    downloadReport: Function,
    values: Object
};

function ButtonExport({ loading, downloadReport, values }: Props) {
  return (
      <Button type="button" color="primary" disabled={loading} className="validation-button m-0" onClick={() => { downloadReport(values); }} id="exportButton">
    { !loading && <>
      <i className="fa fa-download" />{I18n.t('bPartner.button.EXPORT')}
            </>
            }
    { loading && <>
      <Loader small />
            </>
            }
  </Button>
  );
}
export default ButtonExport;
