import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import Tile from '../Tile';
import type { PatientSearchResultType, PatientSearchType } from '../../types/PatientTypes';
import { PATIENT_PAGE_ROUTE } from '../../routes';
import SearchResultType from '../../types/InternalBeneficiaryTypes';

type Props = {
  internalBeneficiary: SearchResultType,
  routeChange: Function
}

function InternalBeneficiaryTile({ internalBeneficiary, routeChange }: Props) {
  return (
    <Tile
      className="internal-beneficiary-title-container"
    >
      <div className='d-flex flex-column pl-4'>
          <div className='text-no-wrap-ellipsis'>
            <span className='pr-2' id="beneficiary-first-name" >{internalBeneficiary.beneficiaryFirstName}</span>
            <span id="beneficiary-last-name" >{internalBeneficiary.beneficiaryLastName}</span>
          </div>
          <div className='text-no-wrap-ellipsis'>
            <span className='thin-font' id="beneficiary-birth-date">
              { internalBeneficiary.beneficiaryBirthDate && moment(internalBeneficiary.beneficiaryBirthDate).format(I18n.t('date.DATE_FORMAT'))}
            </span>
          </div>
      </div>
      <div className='d-flex flex-column pl-4'>
          <div className='text-no-wrap-ellipsis'>
            <span className='pr-2' id="policyHolder-first-name">{internalBeneficiary.policyHolderFirstName}</span>
            <span id="policyHolder-last-name">{internalBeneficiary.policyHolderLastName}</span>
          </div>
          <div className='text-no-wrap-ellipsis'>
              <span>{I18n.t('internalBeneficiary.PROTOCOL_NUMBER')} : </span>
              <span id="protocol-number">{internalBeneficiary.protocolNumber}</span>
          </div>
          <div className='text-no-wrap-ellipsis'>
              <span>{I18n.t('internalBeneficiary.POLICY_NUMBER')} : </span>
              <span id="policy-number">{internalBeneficiary.policyNumber}</span>
          </div>
      </div>
      <div className='d-flex justify-content-end pr-4'>
        <Button color="primary" id="detail" onClick={() => routeChange(internalBeneficiary.id)} >
          <i className="button-icon fa fa-folder-open" />{I18n.t('button.DETAILS')}
        </Button>
      </div>
    </Tile>
  );
}

export default InternalBeneficiaryTile;
