// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProcessSteps, teleAdviceCode, teleConsultCode } from '../../../enum';
import {
  CASE_PROCESS_PAGE_ROUTE,
  CONSENTS_PAGE_ROUTE,
  ELIGIBILITY_PAGE_ROUTE,
  IDENTIFICATION_PAGE_ROUTE,
  PROCESS_DETAILS_PAGE_ROUTE,
  QUALIFICATION_PAGE_ROUTE,
  SENDING_DOCUMENTS_PAGE_ROUTE,
  TELECONSULTATION_PAGE_ROUTE,
} from '../../../routes';
import { createSearchFromParams } from '../../../services/queryParamsService';
import { currentSessionService } from '../../../services/sessionServiceInstance';
import caseApi from '../../../network/api/caseApi';
import { notifyError, notifyMandatoryField } from '../../../network/notification';

type Props = {
  caseInfos: Object,
  dispatch: Function,
  location: {
    pathname: string,
  },
  history: Function,
  queryParams: Object,
  isSalvageable: boolean,
  caseQualification: String,
};

type State = {
  greatestStep: number,
  haveTeleconsultation: boolean,
  seeEligibility: boolean,
};

class ProcessBar extends React.Component<Props, State> {
  state = {
    greatestStep: 0,
    haveTeleconsultation: true,
    seeEligibility: true,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname
      || (this.props.queryParams !== nextProps.queryParams)
      || (this.props.caseQualification === teleConsultCode && nextProps.caseQualification === teleAdviceCode)
    ) {
      this.setStep(nextProps);
      this.seeEligibilityStep(nextProps, this.callBackFunction);
    }
    if (this.props.caseInfos !== nextProps.caseInfos) {
      this.getIsTeleconsultation(nextProps.caseInfos);
    }
  }

  componentDidMount() {
    this.setStep(this.props);
    this.seeEligibilityStep(this.props, this.callBackFunction);
    this.getIsTeleconsultation(this.props.caseInfos);
  }

  getIsTeleconsultation(caseInfos) {
    if (caseInfos && caseInfos.caseInfos) {
      this.setState({ haveTeleconsultation: caseInfos.caseInfos.caseQualification == teleConsultCode });
    }
  }

  currentStep = (props) => {
    const pathnameArray = props.location.pathname.split('/');
    return ProcessSteps.indexOf(`/${pathnameArray[2]}`);
  };

  setStep = (props) => {
    const currentStep = this.currentStep(props);
    let { greatestStep } = this.state;

    if (props.queryParams.idPatient) {
      greatestStep = 2;
    }
    if (props.queryParams.idCase) {
      caseApi.getHighestStep(props.queryParams.idCase)
        .then(response => response.text())
        .then((highestStep: number) => {
          // HOTFIX: ici on perd l'assurance du highest step venant du back...
          this.setState({ greatestStep: Math.max(currentStep, parseInt(highestStep, 10)) });
        })
        .catch(e => console.log('Erreur apppel API', e));
    } else {
      if (greatestStep < currentStep || greatestStep < 2) {
        greatestStep = currentStep;
      }
      this.setState({ greatestStep });
    }
  };

  getStatus = (step: string) => {
    if (this.state.greatestStep > ProcessSteps.indexOf(step)) {
      return 'past';
    } else if (this.state.greatestStep === ProcessSteps.indexOf(step)) {
      return 'actual';
    }
    return '';
  };

  getActive = (step: string) => (this.state.greatestStep >= ProcessSteps.indexOf(step) ? 'active' : '');

  isTeleconsultationActive = (step: string, curentState) => ((curentState.greatestStep >= ProcessSteps.indexOf(step) && curentState.haveTeleconsultation) ? 'active' : '');

  goToStep = (step: ProcessSteps) => {
    if (this.getActive(step)) {
      if (this.props.location.pathname === (CASE_PROCESS_PAGE_ROUTE + PROCESS_DETAILS_PAGE_ROUTE)) {
        if (this.props.isSalvageable) {
          this.props.history.push(CASE_PROCESS_PAGE_ROUTE + step + createSearchFromParams(this.props.queryParams));
        } else {
          return this.props.dispatch(notifyMandatoryField(this.props.dispatch));
        }
      } else {
        this.props.history.push(CASE_PROCESS_PAGE_ROUTE + step + createSearchFromParams(this.props.queryParams));
      }
    }
  };

  seeEligibilityStep = (props, callBack) => {
    if (props.queryParams.isCmCicProtocol) {
      callBack(props.queryParams.isCmCicProtocol !== 'true');
    } else {
      if (props.queryParams.idCase) {
        caseApi.fetchIsCmCic(props.queryParams.idCase)
          .then(response => response.text())
          .then(isCmCic => {
            callBack((isCmCic !== 'true'));
          })
          .catch(props.dispatch(notifyError));
      }
    }
  };

  callBackFunction = (seeEligibility) => {
    this.setState({ seeEligibility });
  };

  render() {
    return (
      <div className="process-bar">
        {currentSessionService().currentUser().showIdentification &&
        <div className={`process-step ${this.getStatus(IDENTIFICATION_PAGE_ROUTE)}`}>
          <i className="fa fa-fingerprint" />
          <div>{I18n.t('processCaseHeader.steps.IDENTIFICATION')}</div>
        </div>
        }
        {currentSessionService().currentUser().showIdentification && this.state.seeEligibility &&
        <div className={`process-step ${this.getStatus(ELIGIBILITY_PAGE_ROUTE)}`}>
          <i className="fa fa-file-search" />
          <div>{I18n.t('processCaseHeader.steps.ELIGIBILITY')}</div>
        </div>
        }
        <div
          className={`process-step ${this.getStatus(PROCESS_DETAILS_PAGE_ROUTE)} ${this.getActive(PROCESS_DETAILS_PAGE_ROUTE)}`}
          onClick={() => this.goToStep(PROCESS_DETAILS_PAGE_ROUTE)}
        >
          <i className="fal fa-id-card" />
          <div>{I18n.t('processCaseHeader.steps.PATIENT_DETAILS')}</div>
        </div>
        {currentSessionService().currentUser().showIdentification &&
        <div
          className={`process-step ${this.getStatus(CONSENTS_PAGE_ROUTE)} ${this.getActive(CONSENTS_PAGE_ROUTE)}`}
          onClick={() => this.goToStep(CONSENTS_PAGE_ROUTE)}
        >
          <i className="fal fa-thumbs-up" />
          <div>{I18n.t('processCaseHeader.steps.CONSENTS')}</div>
        </div>
        }
        {currentSessionService().currentUser().showQualification &&
        <div
          className={`process-step ${this.getStatus(QUALIFICATION_PAGE_ROUTE)} ${this.getActive(QUALIFICATION_PAGE_ROUTE)}`}
          onClick={() => this.goToStep(QUALIFICATION_PAGE_ROUTE)}
        >
          <i className="fa fa-comments" />
          <div>{I18n.t('processCaseHeader.steps.QUALIFICATION')}</div>
        </div>
        }
        <div
          className={`process-step ${this.getStatus(TELECONSULTATION_PAGE_ROUTE)} ${this.isTeleconsultationActive(TELECONSULTATION_PAGE_ROUTE, this.state)}`}
          onClick={() => {
            if (this.isTeleconsultationActive(TELECONSULTATION_PAGE_ROUTE, this.state)) {
              this.goToStep(TELECONSULTATION_PAGE_ROUTE);
            }
          }}
        >
          <i className="fa fa-stethoscope" />
          <div>{I18n.t('processCaseHeader.steps.TELECONSULTATION')}</div>
        </div>
        <div
          className={`process-step ${this.getStatus(SENDING_DOCUMENTS_PAGE_ROUTE)} ${this.getActive(SENDING_DOCUMENTS_PAGE_ROUTE)}`}
          onClick={() => this.goToStep(SENDING_DOCUMENTS_PAGE_ROUTE)}
        >
          <i className="fa fa-paper-plane" />
          <div>{I18n.t('processCaseHeader.steps.DOCUMENTS')}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
  isSalvageable: state.process.isSalvageable,
  caseQualification: state.process.caseQualification,
}))(ProcessBar));
