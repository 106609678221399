import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { Table } from 'reactstrap';
import type { SearchResultContentItem } from '../../types/LegalEntityManagement';

type Props = {
    currentItems: SearchResultContentItem[],
    goToView: Function,
}

function LegalEntityTable({ currentItems, goToView }: Props) {
  return (
    <Table bordered>
      <thead>
        <tr>
          <th>
            {I18n.t('legalEntity.TABLE.LEGAL_ENTITY_CODE')}
          </th>
          <th>
            {I18n.t('legalEntity.TABLE.LEGAL_ENTITY_NAME')}
          </th>
          <th>
            {I18n.t('legalEntity.TABLE.BUSINESS_UNIT')}
          </th>
          <th>
            {I18n.t('legalEntity.TABLE.STATUS')}
          </th>
        </tr>
      </thead>
      <tbody>
        {currentItems && currentItems.map(item => (
          <tr
            key={item.id}
            onClick={() => {
                            goToView(item.id);
                        }}
          >
            <td>{item.legalEntityCode}</td>
            <td>{item.legalEntityName}</td>
            <td>{item.businessUnitName}</td>
            <td>{item.isActive ? I18n.t('legalEntity.STATUS.ACTIVE') : I18n.t('legalEntity.STATUS.INACTIVE')}</td>
          </tr>
                    ))}
      </tbody>
    </Table>
  );
}

export default LegalEntityTable;
