// @flow

import React from 'react';
import Select from 'react-select';
import { I18n } from 'react-redux-i18n';

type Props = {
  className?: string,
  disabled?: boolean,
  options: Array<any>,
  defaultValues?: Array<any>,
  noOptionsMessage: Function
}

class SelectMultiAutoComplete extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    disabled: false,
    defaultValues: [],
  };

  render() {
    return (
      <div className="field-render">
        <Select
          isMulti
          className={this.props.className}
          isDisabled={this.props.disabled}
          options={this.props.options}
          defaultValue={this.props.defaultValues}
          noOptionsMessage={this.props.noOptionsMessage}
          {...this.props}
          {...this.props.input}
        />
      </div>
    );
  }
}

export default SelectMultiAutoComplete;
