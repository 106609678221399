// @flow
import React from 'react';
import { Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import DashboardTile from './DashboardTile';
import FilterBar from './FilterBar';
import caseApi from '../../network/api/caseApi';
import { CASE_PROCESS_PAGE_ROUTE, IDENTIFICATION_PAGE_ROUTE } from '../../routes';
import Loader from '../../components/Loader';
import { currentSessionService } from '../../services/sessionServiceInstance';
import userApi from '../../network/api/userApi';
import { notifyError } from '../../network/notification';
import { store } from '../../network/reduce';
import { DASHBOARD_FILTERS } from '../../state/dashboard/dashboardReducer';
import hasPermission from '../../services/permissionService';
import { RecordingReason } from '../../enum';
import { startedNewCaseRecording } from '../../services/recordingService';
import moment from 'moment';

type Props = {
  history: Function,
  dispatch: Function,
  filters: {
    speciality: string,
    userRole: string,
    channel: string,
    userName: string,
  }
}

type State = {
  waitingTime: number,
  cases: any,
  filtering: boolean,
  casesNumber: number,
  page: number,
  maxPage: number,
}

class Dashboard extends React.Component<Props, State> {
  state = {
    cases: null,
    waitingTime: 0,
    page: 1,
    filtering: false,
    casesNumber: 0,
    maxPage: 100,
    lastCasesByRole: null
  };

  interval;
  intervalWaitTime;
  mainContent: HTMLElement;

  isBottom(elem) {
    return elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight);
  }

  componentDidMount() {
    this.props.dispatch(store(DASHBOARD_FILTERS, {}));
    this.fetchCases(this.state.page, {});
    if (hasPermission('MANAGE_CASES')) {
      this.interval = setInterval(() => this.fetchCases(this.state.page, this.props.filters), 2000);
    }
    this.intervalWaitTime = setInterval(() => this.fetchWaitTime(), 2000);

    this.mainContent = document.getElementById('main-content');
    this.mainContent.addEventListener('scroll', this.trackScrolling);

    userApi
      .getWaitTime()
      .then(response => response.text())
      .then((waitingTime) => {
        this.setState({ waitingTime });
      })
      .catch(this.props.dispatch(notifyError));
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.intervalWaitTime);
    if (this.mainContent) this.mainContent.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    if (this.isBottom(this.mainContent)) {
      this.setState(
        { page: Math.min(this.state.page + 1, this.state.maxPage) },
        () => this.fetchCases(this.state.page, this.props.filters),
      );
    }
  };

  fetchWaitTime = () => {
    userApi
      .getWaitTime()
      .then(response => response.text())
      .then((waitingTime) => {
        this.setState({ waitingTime });
      })
      .catch(this.props.dispatch(notifyError));
  };

  fetchCases = (page: number, filters: Object) => {
    if (page <= this.state.maxPage) {
      caseApi.fetch(page, filters)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : {}))
        .then((response) => {
          this.setState({
            filtering: false,
            casesNumber: response.metadata.pagination.totalCount,
            cases: response.items,
            maxPage: response.metadata.pagination.pageCount,
          });
        })
        .catch(e => console.log('Erreur apppel API', e));
    }
  };

  changingTime = () => {
    userApi
      .updateTimeWait({ time: this.state.waitingTime })
      .catch(this.props.dispatch(notifyError));
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.filters !== nextProps.filters && nextProps.filters) {
      this.setState({ filtering: true, page: 1, maxPage: 100 });
      this.fetchCases(this.state.page, nextProps.filters);
    }
  }

  changeAvailability = () => {
    userApi
      .updateAvailability(false);
  };

  render() {
    return (
      <div className="dashboard-container">
        <div className="title-container">
          <div className="title">{I18n.t('dashboard.TITLE')}</div>
          <div className="waiting-time">
            <div className="label-waiting">{I18n.t('dashboard.AVERAGE_WAIT')}</div>
            <div className="input-time">
              <Input
                type="number"
                className="waiting-time"
                value={this.state.waitingTime}
                onChange={(e) => {
                  this.setState({
                    waitingTime: e.target.value,
                  }, () => this.changingTime());
                }}
              />
              <div className="label-waiting">{I18n.t('dashboard.MINUTES')}</div>
            </div>
          </div>
        </div>
        <div className="new-case-container">
          <div className="open-cases">
            <span className="open-cases-text"> {I18n.t('dashboard.OPENED_CASES', {count: this.state.casesNumber})}</span>
          </div>
          {currentSessionService().currentUser().showNewCaseButtonInDashboard &&
          <Button
            className="btn-new-call shadow"
            onClick={() => {
              this.changeAvailability();
              startedNewCaseRecording(this.props.dispatch, RecordingReason.NEW_CASE_DASHBOARD_BUTTON);
              this.props.history.push(CASE_PROCESS_PAGE_ROUTE + IDENTIFICATION_PAGE_ROUTE);
            }}
          >
            <i className="button-icon fa fa-phone-alt"/>
            <span className="btn-label">
              {I18n.t('dashboard.NEW_CASE')}
            </span>
          </Button>
          }
        </div>
        <FilterBar/>

        <div className="tile-container" data-testid="list-of-cases">
          {!this.state.cases || this.state.filtering ?
            <div className="dashboard-loader">
              <Loader/>
              <span>{I18n.t('loader.text')}</span>
            </div>
            : !!this.state.cases && this.state.cases
            .map(item => (
              <div key={item.id}>
                <DashboardTile
                  key={item.id}
                  caseId={item.id}
                  patientId={item.patientId}
                  channel={item.communicationType}
                  patientName={item.patientName}
                  caseDateCreation={item.startDateAppointment || item.createdDate}
                  sessionStartDate={item.sessionStartDate}
                  brand={item.brandName}
                  partnerName={item.bpartnerName}
                  reasonForTheCall={item.reasonForTheCall}
                  processStatus={item.processStatus}
                  userName={item.userName}
                  appointment={!!item.startDateAppointment}
                  //permet de re-render le composant pour changer le statut 'blink' si la date du RDV est passée
                  appointmentStarted={moment().isAfter(moment(item.startDateAppointment))}
                  videoStatus={item.videoStatus}
                  chatStatus={item.chatStatus}
                  caseQualification={item.caseQualification}
                  isRedirectToConsent={item.isRedirectToConsentPage}
                  userTakenInChargeId={item.userTakenInChargeId}
                />
              </div>
            ))
          }
        </div>
        {this.state.page < this.state.maxPage && !this.state.filtering && <Loader/>}
      </div>
    );
  }
}

export default connect(state => ({
  filters: state.dashboard.dashboardFilters,
}), null, null, { withRef: true })(Dashboard);
