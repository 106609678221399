// @flow

import { FAX_CANCEL_ROUTE, FAX_CONFIRM_ROUTE } from '../../routes';
import React from 'react';
import SendFax from './SendFax';
import CancelFax from './CancelFax';
import { Route } from 'react-router-dom';

function FaxRoutes() {
  return (
    <div>
      <Route exact path={FAX_CONFIRM_ROUTE} component={SendFax}/>
      <Route exact path={FAX_CANCEL_ROUTE} component={CancelFax}/>
    </div>
  );
}

export default FaxRoutes;
