// @flow

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import ModalEprm from '../../../components/modal/ModalEprm';
import SendViaSecureLink from './SendViaSecureLink';
import { b64toBlob, downloadBase64 } from '../../../utils';
import {
  ADVICE_REPORT_PAGE_ROUTE,
  CASE_PROCESS_PAGE_ROUTE,
  MED_REPORT_PAGE_ROUTE,
  PRESCRIPTION_PAGE_ROUTE,
  QUALIFICATION_PAGE_ROUTE,
  TELECONSULTATION_PAGE_ROUTE,
} from '../../../routes';
import { createSearchFromParams } from '../../../services/queryParamsService';
import SendToPharmacie from './SendToPharmacie';
import { notifyError } from '../../../network/notification';
import caseApi from '../../../network/api/caseApi';
import patientApi from '../../../network/api/patientApi';
import { currentSessionService } from '../../../services/sessionServiceInstance';
import { SendBy } from '../../../enum';

type Props = {
  history: Object,
  title: string,
  isPrescription: boolean,
  isAdviceReport: boolean,
  canBeSend: boolean,
  listDocuments: Object,
  caseId: string,
  patientId: string,
  fetchDocument: Function,
};

type State = {
  sendToPharmacy: boolean,
  modal: boolean,
  caseStatus: string,
};

class DocumentsToSend extends React.Component<Props, State> {
  state = {
    sendToPharmacy: false,
    modal: false,
    document: {},
    caseStatus: '',
  };

  downloadDocument = (fileInfos) => {
    patientApi
      .getDocument(fileInfos.id)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((document) => {
        downloadBase64(document.base64, document.name);
      })
      .catch(e => console.log('Erreur apppel API', e));
  };

  getBase64Document = fileInfos => new Promise(resolve => patientApi
    .getDocument(fileInfos.id)
    .then(response => response.json())
    .then(resolve)
    .catch(this.props.dispatch(notifyError)));

  editDocument = (document: Object) => {
    if (this.props.isPrescription) {
      this.props.history
        .push(CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + PRESCRIPTION_PAGE_ROUTE
          + createSearchFromParams({
            caseId: this.props.caseId,
            patientId: this.props.patientId,
            documentId: document.id,
          }));
    } else if (this.props.isAdviceReport) {
      this.props.history
        .push(CASE_PROCESS_PAGE_ROUTE + QUALIFICATION_PAGE_ROUTE + ADVICE_REPORT_PAGE_ROUTE
          + createSearchFromParams({
            caseId: this.props.caseId,
            patientId: this.props.patientId,
            documentId: document.id,
          }));
    } else {
      this.props.history
        .push(CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + MED_REPORT_PAGE_ROUTE
          + createSearchFromParams({
            caseId: this.props.caseId,
            patientId: this.props.patientId,
            documentId: document.id,
          }));
    }
  };

  updateDocuments = (documentId: string, sentBy: string) => {
    caseApi
      .updateDocumentsValues(documentId, { sentBy })
      .then(this.props.fetchDocument)
      .catch(this.props.dispatch(notifyError));
  };

  componentDidMount = () => {
    caseApi
      .fetchDetailById(this.props.caseId)
      .then(response => response.json())
      .then((responseJson) => {
        this.setState({ caseStatus: responseJson.caseQualification });
      })
      .catch(this.props.dispatch(notifyError));
  };

  showPharmacy = (document: object) => {
    if (!currentSessionService().currentUser().canSendByFax) {
      return (!this.props.isPrescription && document.type !== DocumentType.PRESCRIPTION);
    }
    return true;
  };

  render() {
    return (
      <div>
        {this.props.listDocuments.length !== 0 &&
        <div className="prescription-container">
          {this.props.title}
        </div>}
        {this.props.listDocuments && this.props.listDocuments.map((document, index) =>
          (<div className="prescription" key={document.id}>
            <div className="prescription-title">
              <div>{document.name} </div>
              <div className="title-action">
                <button
                  className="btn btn-primary"
                  onClick={() => this.downloadDocument(document)}
                >
                  <i className="far fa-download" />
                  <div>{I18n.t('button.DOWNLOAD')}</div>
                </button>
                {this.props.canBeSend &&
                <button
                  className="btn btn-primary"
                  onClick={() => this.editDocument(document)}
                  disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                >
                  <i className="far fa-edit" />
                  <div>{I18n.t('button.EDIT')}</div>
                </button>
                }
                <div className="creation-date">
                  <i className="fal fa-save" />
                  {moment(document.creationDate).format(I18n.t('date.DATE_LONG'))}
                </div>
              </div>
            </div>
            {this.props.canBeSend ?
              <Fragment>
                <div className="send-by">{I18n.t('documentsToSend.sendBy')}</div>
                <div className="prescriptions-content">
                  <div className="main-to-send">
                    <div>{I18n.t('documentsToSend.bySecureLink')}</div>
                    <div className="send-link">
                      {document.isSendBySecureLink &&
                      <div>
                        <i className="fas fa-check-circle" />
                      </div>
                      }
                      <a
                        className={`${this.props.caseInfos && !this.props.caseInfos.isEditable ? 'link-disabled' : ''}`}
                        onClick={() => {
                          this.props.caseInfos && this.props.caseInfos.isEditable &&
                          this.setState({
                            modal: true,
                            sendToPharmacy: false,
                            document,
                          });
                        }}
                      >{I18n.t('documentsToSend.send')}
                      </a>

                    </div>
                  </div>
                  {this.showPharmacy(document) &&
                  <div className="main-to-send">
                    <div>{I18n.t(`documentsToSend.${this.props.isPrescription ? 'byPharmacy' : 'print'}`)}</div>
                    <div className="send-link">
                      {document.isSendByPharmacy &&
                      <div>
                        <i className="fas fa-check-circle" />
                      </div>
                      }
                      <a
                        className={this.props.caseInfos && !this.props.caseInfos.isEditable && this.props.isPrescription ? 'link-disabled' : ''}
                        onClick={() => {
                          if (this.props.isPrescription) {
                            if (this.props.caseInfos && this.props.caseInfos.isEditable) {
                              this.setState({
                                modal: true,
                                sendToPharmacy: true,
                                document,
                              });
                            }
                          } else {
                            this.downloadDocument(document);
                          }
                        }
                        }
                      >
                        {this.props.isPrescription ?
                          I18n.t('documentsToSend.send')
                          :
                          I18n.t('documentsToSend.download')
                        }
                      </a>
                    </div>
                  </div>
                  }
                </div>
              </Fragment> :
              <div className="prescriptions-content">
                <div className="main-to-send">
                  <div>{I18n.t('documentsToSend.print')}</div>
                  <a
                    onClick={() => {
                      this.downloadDocument(document);
                    }}
                  >{this.props.isPrescription ? I18n.t('documentsToSend.send') : I18n.t('documentsToSend.download')}
                  </a>
                </div>
              </div>
            }
            <ModalEprm
              title={this.state.sendToPharmacy ? I18n.t('documentsToSend.sendToPharmacy.title') : I18n.t('documentsToSend.sendBySecure.title')}
              modal={this.state.modal}
              size="lg"
              toggle={() => this.setState({ modal: !this.state.modal })}
            >
              {
                this.state.sendToPharmacy ?
                  <div>
                    <SendToPharmacie
                      caseId={this.props.caseId}
                      patientId={this.props.patientId}
                      documentToSend={this.getBase64Document(document)}
                      toggle={() => this.setState({ modal: !this.state.modal })}
                      updateDocuments={() => this.updateDocuments(this.state.document ? this.state.document.id : '', SendBy.PHARMACY)}
                    />
                  </div>
                  :
                  <SendViaSecureLink
                    documentId={this.state.document ? this.state.document.id : null}
                    caseId={this.props.caseId}
                    toggle={() => this.setState({ modal: !this.state.modal })}
                    updateDocuments={() => this.updateDocuments(this.state.document ? this.state.document.id : '', SendBy.SECURE_LINK)}
                  />

              }
            </ModalEprm>
          </div>))
        }
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(DocumentsToSend));
