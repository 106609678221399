import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Table } from 'reactstrap';
import type { SearchResultContentItem } from '../../types/B-PartnerManagement'

type Props = {
    currentItems: SearchResultContentItem[],
    goToView: Function,
}

function BPartnerTable({ currentItems, goToView }: Props) {

    return (
        <div className="table-container">
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th style={{ minWidth: '120px' }}>
                            {I18n.t('bPartner.TABLE.B_PARTNER_CODE')}
                        </th>
                        <th style={{ minWidth: '270px' }}>
                            {I18n.t('bPartner.TABLE.B_PARTNER_NAME')}
                        </th>
                        <th style={{ minWidth: '120px' }}>
                            {I18n.t('bPartner.TABLE.BUSINESS_UNIT')}
                        </th>
                        <th style={{ minWidth: 'min-content' }}>
                            {I18n.t('bPartner.TABLE.STATUS')}
                        </th>
                        <th style={{ minWidth: '200px' }}>
                            {I18n.t('bPartner.TABLE.COMMERCIAL_NAME')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems && currentItems.map(item => {
                        return (
                            <tr key={item.id} onClick={() => {
                                goToView(item.id);
                            }} >
                                <td>{item.bpartnerCode}</td>
                                <td>{item.bpartnerName}</td>
                                <td>{item.businessUnitName}</td>
                                <td>{item.isActive ? I18n.t('bPartner.STATUS.ACTIVE') : I18n.t('bPartner.STATUS.INACTIVE')}</td>
                                <td>{item.commercialName}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    )

}

export default BPartnerTable;
