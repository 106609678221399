// @flow
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { I18n } from 'react-redux-i18n';

type
  Props = {
  input: Object;
}
export default class RichText extends React.Component<void, Props, void> {
  state = {
    maxLength: this.props.maxLength,
    lastValue: '',
    modules: {
      toolbar: [
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ align: [] }],
        ['bold', 'italic', 'underline'],
        // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }],
      ],
    },
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.input.value !== nextProps.input.value) {
      if (this.getRichTextLength(nextProps.input.value) <= this.state.maxLength) {
        this.setState({ lastValue: nextProps.input.value });
      }
    }
  }

  getRichTextLength = (richText) => {
    return richText.replace(/<(?:.|\n)*?>/gm, '').length;
  };

  render() {
    const richTextLength = this.props.maxLength - this.getRichTextLength(this.props.input.value);
    return (
      <div className="text-editor">
        <ReactQuill
          onChange={this.props.input.onChange}
          value={(richTextLength >= 0 || !this.props.maxLength) ? this.props.input.value : this.state.lastValue}
          theme="snow"
          modules={this.state.modules}
        />
        {
          this.props.maxLength &&
          <div>
            {
              richTextLength > 1 ?
                <span> {I18n.t('richText.LIMIT', { length: richTextLength })}</span>
                :
                <span> {I18n.t('richText.SINGULAR_LIMIT', { length: richTextLength })}</span>
            }
          </div>
        }
      </div>
    );
  }
}