// @flow

import React from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

import { downloadBase64 } from '../../../utils';
import patientApi from '../../../network/api/patientApi';

type Props = {
    patientId: String,
    goBack: Function,
    lastDocuments: Array<Object>,
    nbTeleadvice: String,
    nbTeleconsult: String,
    nbMaxOfTeleconsult: String,
};

class ListLastDocuments extends React.Component<Props, State> {
    download = (fileInfos) => {
      patientApi
        .getDocument(fileInfos.id)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : {}))
        .then(docInfo => downloadBase64(docInfo.base64, docInfo.name))
        .catch(e => console.log('Erreur apppel API', e));
    };

    render() {
      const documents = (this.props.lastDocuments || []).map(value => ({
        ...value,
        creationDate: moment(value.creationDate).format('DD/MM/YYYY HH:mm'),
      })).reduce((result, lastDocuments) => {
        result[lastDocuments.caseId] = [...result[lastDocuments.caseId] || [], lastDocuments];
        return result;
      }, {});
      return (
        <div className="last-documents">
          <header>
            <div className="drawer-backTo" onClick={this.props.goBack}>
              <i className="fa fa-arrow-left" />
              <span>{I18n.t('medicalProfile.lastDocumentsDrawer.backTo')}</span>
            </div>
            <div className="drawer-title">
              <h1>{I18n.t('medicalProfile.lastDocumentsDrawer.title')}</h1>
            </div>
          </header>
          <div className="last-documents-number">
            <div className="square">
              <div>{I18n.t('lastDocuments.ADVICE')}</div>
              <div>{this.props.nbTeleadvice}</div>
            </div>
            <div className="square">
              <div>{I18n.t('lastDocuments.CONSULT')}</div>
              <div>{this.props.nbTeleconsult} / {this.props.nbMaxOfTeleconsult}</div>
            </div>
          </div>
          <div className="last-documents-cases">
            {
                        documents && Object.keys(documents).map((lastDocuments, index) =>
                            (<div key={index}>
                              <div className="documents-container">
                                <span>
                                  {(!!documents[lastDocuments][0] && !!documents[lastDocuments][0].qualificationCall) ?
                        `${(I18n.t(`lastDocuments.${documents[lastDocuments][0].qualificationCall}`))}: ${documents[lastDocuments][0].caseName}`
                        : I18n.t('lastDocuments.caseNumber') + index}
                                </span>
                              </div>
                              {documents[lastDocuments].map(document =>
                                    (<div  key={document.id}>
                                       <div className="documents">
                                        <span className="documents-title">
                                          {`${I18n.t(`lastDocuments.${document.type}`)} - ${document.creationDate}`}
                                        </span>
                                        <div className="documents-tile">
                                          <div className="document-name">{document.name}</div>
                                          <a onClick={() => this.download(document)}>{I18n.t('lastDocuments.DOWNLOAD')}</a>
                                        </div>
                                       </div>
                                    </div>))}
                            </div>))
                    }

          </div>
        </div>
      );
    }
}

export default ListLastDocuments;

