// @flow

import * as Sentry from '@sentry/browser';
import confApi from './network/api/confApi';

const fetchSentryInfos: Promise<string> = new Promise(resolve =>
  confApi.fetchEnv()
    .then(res => res.text())
    .then(resolve)
    .catch(() => resolve('error')));

export const initSentry: Promise<void> = new Promise(resolve => {
  fetchSentryInfos.then(env => {
    if (env !== 'dev') {
      Sentry.init({
        environment: env,
        dsn: 'https://a1f63ab21a654052bf7e3279af73b682@sentry.io/1731515'
      });
    }
    resolve();
  }).catch(resolve)
});


