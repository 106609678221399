// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { renderToString } from 'react-dom/server';
import { CASE_PROCESS_PAGE_ROUTE, TELECONSULTATION_PAGE_ROUTE } from '../../routes';
import { createSearchFromParams } from '../../services/queryParamsService';
import prescriptionApi from '../../network/api/prescriptionApi';
import { notifyError } from '../../network/notification';
import patientApi from '../../network/api/patientApi';
import MedicalProfile from '../../components/medicalProfile/MedicalProfile';
import { currentSessionService } from '../../services/sessionServiceInstance';
import PrescriptionPreview from './PrescriptionPreview';
import ModalMedicine from './ModalMedicine';
import CoreozDrawer from '../../components/CoreozDrawer';
import ListLastDocuments from '../../components/medicalProfile/components/ListLastDocuments';
import type { Medicine } from '../../types/PrescriptionTypes';
import caseApi from '../../network/api/caseApi';
import { languages } from '../../services/languages';
import Loader from '../../components/Loader';
import { LanguageIsoCode } from '../../enum';

type Props = {
  queryParams: Object,
  history: Function,
  dispatch: Function
};

type State = {
  prescription: Object,
  sideDrawerOpen: boolean,
  patient: Object,
  showAdditionalNotes: boolean,
  vidalPopUpOpen: boolean,
  medication: Array<Medicine>,
  medicineToUpdate: Medicine,
  lastDocuments: Object,
  medicalPatient: Object,
  loading: Boolean,
};

class Prescription extends React.Component<Props, State> {
  state = {
    prescription: null,
    sideDrawerOpen: false,
    patient: {},
    lastDocuments: {},
    medicalPatient: {},
    showAdditionalNotes: false,
    vidalPopUpOpen: false,
    medication: [],
    medicineToUpdate: null,
    previewLanguage: this.props.language.code,
    loading: false,
  };

  goBack = () => {
    this.props.history
      .push({
        pathname: CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE,
        search: createSearchFromParams(this.props.queryParams),
        state: 'prescription',
      });
  };

  updateMedication = (newMedication: Medication) => {
    if (this.state.medication && this.state.medication.filter(medication => medication.vidalId === newMedication.vidalId).length) {
      this.setState({
        medication: this.state
          .medication
          .map((medicament) => {
            if (medicament.vidalId === newMedication.vidalId) {
              medicament.instructions = newMedication.instructions || '';
            }
            return medicament;
          }),
      });
    } else {
      const medication = this.state.medication || [];
      this.setState({
        medication: [...medication, newMedication],
      });
    }
  };

  removeMedicamentFromMedication = (medicamentToDelete: Object) => {
    this.setState({
      medication: this.state.medication.filter(medicament => medicament.vidalId !== medicamentToDelete.vidalId),
    });
    if (medicamentToDelete.id && this.props.queryParams.documentId) {
      prescriptionApi.removeMedicament(medicamentToDelete.id, this.props.queryParams.documentId)
        .catch(this.props.dispatch(notifyError));
    }
  };

  toggleShowAdditionalNotes = () => this.setState({ showAdditionalNotes: true });

  vidalPopUp = (medicament) => {
    this.setState({ medicineToUpdate: medicament });
    this.vidalPopUpClickHandle();
  };

  isEnglishTemplate = () => this.state.previewLanguage === LanguageIsoCode.ENGLISH;

  componentDidMount = () => {
    this.getInformationOfPatient(this.props.queryParams.patientId);

    this.getPatient(this.props.queryParams.patientId)
      .then(patient => this.fetchDocuments(patient.id, patient.protocolNumber))
      .catch(e => console.log('Erreur apppel API', e));

    if (this.props.queryParams.documentId) {
      prescriptionApi
        .getExistingPrescriptionContent(this.props.queryParams.patientId, this.props.queryParams.documentId)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : {}))
        .then(prescription => this.setState({
          prescription,
          medication: prescription.medicines,
        }))
        .catch(e => console.log('Erreur apppel API', e));
    } else {
      prescriptionApi
        .createPrescriptionContent(this.props.queryParams.caseId, this.props.queryParams.patientId)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : {}))
        .then(prescription => this.setState({ prescription }))
        .catch(e => console.log('Erreur apppel API', e));
    }
  };

  onSubmit = async (values: Object) => {
    this.setState({ loading: true });
    await caseApi.checkTakenInCharge(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((changeInfos) => {
        if (!changeInfos.change) {
          const htmlContent = renderToString(<PrescriptionPreview
            previewMode
            dispatch={this.props.dispatch}
            i18nLanguage={this.state.previewLanguage}
            appI18nLanguage={this.props.language.code}
            prescription={this.state.prescription}
            additionalNote={values.additionalNote}
            title={values.title}
            showAdditionalNotes={this.state.showAdditionalNotes}
            showLogoInDocument={currentSessionService().currentUser().showLogoInDocument}
            toggleShowAdditionalNotes={() => this.setState({ showAdditionalNotes: true })}
            vidalPopUpClickHandle={this.vidalPopUpClickHandle}
            removeMedicamentFromMedication={this.removeMedicamentFromMedication}
            medication={this.state.medication}
            showBusinessUnitInformation={currentSessionService().currentUser().showLogoInDocument}
            showFullFooter={currentSessionService().currentUser().showLogoInDocument && !this.isEnglishTemplate()}
            showCityOfCreation={currentSessionService().currentUser().showLogoInDocument}
              // TODO : Changer le nom de ce booleen
            showEmergencyNumber={!this.isEnglishTemplate()}
          />);

          const prescriptionToUpdate = {
            additionalNote: values.additionalNote,
            title: values.title,
            medicines: this.state.medication,
            htmlContent,
          };

          if (this.props.queryParams.documentId) {
            prescriptionApi.updatePrescription(
              this.props.queryParams.caseId,
              this.props.queryParams.documentId,
              prescriptionToUpdate,
            ).then(() => {
              this.setState({ loading: false });
              this.goBack();
            })
              .catch((e) => {
                this.setState({ loading: false });
                notifyError(this.props.dispatch)(e);
              });
          } else {
            prescriptionApi.savePrescription(
              this.props.queryParams.caseId,
              prescriptionToUpdate,
            ).then(() => {
              this.setState({ loading: false });
              this.goBack();
            })
              .catch((e) => {
                this.setState({ loading: false });
                notifyError(this.props.dispatch)(e);
              });
          }
        } else {
          this.setState({ loading: false });
          this.goBack();
        }
      })
      .catch(e => console.log('Erreur apppel API', e));
  };

  drawerToggleClickHandle = () => {
    this.setState({
      sideDrawerOpen: !this.state.sideDrawerOpen,
    });
  };

  vidalPopUpClickHandle = () => {
    this.setState({
      vidalPopUpOpen: !this.state.vidalPopUpOpen,
    });
  };

  getPatient = (id: string) => new Promise((resolve) => {
    patientApi
      .fetchById(id)
      .then(response => response.json())
      .then((patient) => {
        this.setState({ patient });
        resolve(patient);
      })
      .catch(e => console.log('Erreur apppel API', e));
  });


  fetchDocuments = (patientId: string, protocolNumber: string) => {
    patientApi
      .getLastDocuments(patientId, protocolNumber)
      .then(response => response.json())
      .then(lastDocuments => this.setState({ lastDocuments }))
      .catch(this.props.dispatch(notifyError));
  };

  getInformationOfPatient = (id: string) => {
    patientApi
      .getPatientMedicalProfile(id)
      .then(response => response.json())
      .then(responseJson => this.setState({
        medicalPatient: responseJson || {},
      }))
      .catch(this.props.dispatch(notifyError));
  };

  handleChangeLanguage = (language) => {
    this.setState({
      open: false,
      previewLanguage: language.code,
    });
  };

  handleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    return (
      <div>
        <div className="document-edit">
          <div className="document-edit-header">
            <div className="edit-backTo" onClick={this.goBack}>
              <i className="fa fa-arrow-left" />
              <span>{I18n.t('editDocument.BACK')}</span>
            </div>
            <div className="edit-title">
              <div>{I18n.t('editDocument.TITLE_PRESCRIPTION')}</div>
            </div>
          </div>
          <CoreozDrawer show={this.state.sideDrawerOpen} side={false}>
            <ListLastDocuments
              patientId={this.props.queryParams.patientId}
              goBack={this.drawerToggleClickHandle}
              lastDocuments={this.state.lastDocuments.lastDocumentsBean}
              nbTeleconsult={this.state.lastDocuments.nbTeleconsult}
              nbTeleadvice={this.state.lastDocuments.nbTeleadvice}
              nbMaxOfTeleconsult={this.state.lastDocuments.nbOfMaxTeleconsult}
            />
          </CoreozDrawer>
          <div className="edit-container">
            <MedicalProfile
              drawerToggleClickHandle={this.drawerToggleClickHandle}
              patient={this.state.medicalPatient}
              documents={this.state.lastDocuments}
              queryParams={this.props.queryParams}
            />
            <div className="edit-content">
              <Form
                onSubmit={this.onSubmit}
                initialValues={this.state.prescription}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="edit-header">
                      <div className="language-preview">
                        <div className="language-label">
                          {!this.state.open &&
                          <Button
                            color="secondary"
                            onClick={this.handleOpen}
                          >
                            {I18n.t('editDocument.LANGUAGE')}
                          </Button>
                          }
                        </div>
                        {
                          this.props.language &&
                          <div className="language-flags">
                            {this.state.open &&
                            <div className="form-group">
                              <div className="form-control" data-testid="header-list-flag">
                                {
                                  languages()
                                    .filter(language => language.code === 'en' || language.code === this.props.language.code)
                                    .map(language => (
                                      <div className="liste-flag" key={language.code}>
                                        <img
                                          className="button-language"
                                          onClick={() => this.handleChangeLanguage(language)}
                                          src={language.imgPath}
                                          alt={language.code}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                            </div>
                            }
                          </div>
                        }
                      </div>

                      <Button
                        color="primary"
                        type="submit"
                        disabled={this.state.loading}
                      >{
                        this.state.loading ?
                          <Loader small />
                          :
                          <Fragment>
                            <i className="button-icon fa fa-save" />
                            <span>{I18n.t('button.SAVE')}</span>
                          </Fragment>

                      }
                      </Button>
                      <Button
                        color="secondary"
                        onClick={this.goBack}
                      >
                        <i className="button-icon fa fa-undo" />
                        <span>{I18n.t('button.CANCEL')}</span>
                      </Button>
                    </div>
                    {this.state.prescription &&
                    <PrescriptionPreview
                      previewMode={false}
                      // TODO - set boolean en db
                      showLogoInDocument={currentSessionService().currentUser().showLogoInDocument}
                      showAdditionalNotes={this.state.showAdditionalNotes}
                      showBusinessUnitInformation={currentSessionService().currentUser().showLogoInDocument}
                      // TODO Là je sais vraiement pas, il faut un boolean si la langue est "en" ou "es" ?
                      showFullFooter={currentSessionService().currentUser().showLogoInDocument && !this.isEnglishTemplate()}
                      showCityOfCreation={currentSessionService().currentUser().showLogoInDocument}
                      showEmergencyNumber={!this.isEnglishTemplate()}
                      i18nLanguage={this.state.previewLanguage}
                      appI18nLanguage={this.props.language.code}
                      prescription={this.state.prescription}
                      additionalNote={values.additionalNote}
                      toggleShowAdditionalNotes={this.toggleShowAdditionalNotes}
                      vidalPopUpClickHandle={this.vidalPopUpClickHandle}
                      removeMedicamentFromMedication={this.removeMedicamentFromMedication}
                      medication={this.state.medication}
                      vidalPopUp={this.vidalPopUp}
                      dispatch={this.props.dispatch}
                    />
                    }
                    <div className="edit-footer">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={this.state.loading}
                      >
                        {
                          this.state.loading ?
                            <Loader small />
                            :
                            <Fragment>
                              <i className="button-icon fa fa-save" />
                              <span>{I18n.t('button.SAVE')}</span>
                            </Fragment>

                        }
                      </Button>
                      <Button
                        color="secondary"
                        onClick={this.goBack}
                      >
                        <i className="button-icon fa fa-undo" />
                        <span>{I18n.t('button.CANCEL')}</span>
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
        <ModalMedicine
          modal={this.state.vidalPopUpOpen}
          toggle={this.vidalPopUpClickHandle}
          previousMedicinePrescription={this.state.medicineToUpdate}
          writeNewMedicinePrescription={(data) => {
            this.updateMedication(data);
            this.vidalPopUpClickHandle();
          }}
        />
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  language: state.businessUnit.language,
}))(Prescription));
