// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import ResizableTextarea from '../../ResizableTextarea';
import Button from 'reactstrap/es/Button';
import ModalEprm from '../../modal/ModalEprm';

type Props = {
  phoneNumbre:string,
  sendSms:Function,
  closedSelf:Function,
  displaySendSmsForm:? boolean,
}

type States = {
  message:string,
}

class SmsPopin extends React.Component<Props, States> {

  handleSubmit = (data) => {
    this.props.sendSms(data.message);
    this.props.closedSelf();
  };
  preferredCountries = ['fr', 'de', 'es', 'ch'];

  render() {
    return (
      <ModalEprm
        title={I18n.t('contact.sms.TITLE')}
        modal
        toggle={this.props.closedSelf}
        className="contact-popin"
        size="lg"
      >
        <Form
          onSubmit={this.handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="body">
              <div>
                {this.props.displaySendSmsForm ?
                  <p>{I18n.t('contact.phone.CALLBACK_NUMBER', { number: this.props.phoneNumbre })}</p>
                  :
                  <div>{I18n.t('contact.sms.RECIVER_NUMBER', { number: this.props.phoneNumbre })}</div>
                }{!this.props.displaySendSmsForm &&
              <Field
                component={ResizableTextarea}
                minrows={5}
                maxrows={30}
                name="message"
              />
              }
              </div>
              {!this.props.displaySendSmsForm &&
              <div className="footer">
                <Button type="submit"
                        color="primary"
                        className="validation-button">
                  <i className="fal fa-arrow-circle-right"/>
                  {I18n.t('contact.sms.SEND')}
                </Button>
              </div>}
            </form>
          )}
        />
      </ModalEprm>
    );
  }
}

export default withRouter(SmsPopin);
