// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozSelectBase as CoreozSelect } from '../../lib/coreoz-form-base/index';
import CoreozSelectAutocomplete from '../../lib/coreoz-form-base/components/select-autocomplete/CoreozSelectAutocomplete';
import { Button } from 'reactstrap';
import setFieldData from 'final-form-set-field-data';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import MedicalProfile from '../../components/medicalProfile/MedicalProfile';
import { notifyError } from '../../network/notification';
import caseApi from '../../network/api/caseApi';
import referentialApi from '../../network/api/referentialApi';
import AutoSave from '../../components/AutoSave';
import ModalTechnicalProblem from '../../components/modal/ModalTechnicalProblem';
import ResizableTextarea from '../../components/ResizableTextarea';
import ModalTransfer from './ModalTransfer';
import patientApi from '../../network/api/patientApi';
import CoreozDrawer from '../../components/CoreozDrawer';
import ListLastDocuments from '../../components/medicalProfile/components/ListLastDocuments';
import { Language, teleAdviceCode, teleConsultCode } from '../../enum';
import { currentSessionService } from '../../services/sessionServiceInstance';
import {
  ADVICE_REPORT_PAGE_ROUTE,
  CASE_PROCESS_PAGE_ROUTE,
  QUALIFICATION_PAGE_ROUTE,
  SENDING_DOCUMENTS_PAGE_ROUTE,
} from '../../routes';
import { createSearchFromParams } from '../../services/queryParamsService';
import processCaseApi from '../../network/api/processCaseApi';
import ContactSideBar from '../../components/contact-side-bar/ContactSideBar';
import Loader from '../../components/Loader';
import caseReportApi from '../../network/api/caseReportApi';
import ModalAssigned from '../../components/modal/ModalAssigned';
import ShowAndEditDocument from '../teleconsultation/ShowAndEditDocument';
import HistoryModification from '../../components/historyModification/HistoryModification';
import { groupHistoryByDateAndIdUser } from '../../utils';
import { store } from '../../network/reduce';
import { CASE_QUALIFICATION } from '../../state/process/processReducer';
import adviceCodingApi from '../../network/api/adviceCodeingAPI';

type Props = {
  history: Function,
  dispatch: Function,
  queryParams: Object,
  caseInfos: Object,
};

type State = {
  case: Object,
  countries: Array<Object>,
  modal: boolean,
  modalAssign: boolean,
  newUsername: string,
  adviceCoding: Array<any>,
  modalTechnicalProblem: boolean,
  sideDrawerOpen: boolean,
  sideDrawerOpenMedicalProfile: boolean,
  medicalPatient: Object,
  userCountry: Object,
  reasonToCallNonMed: Object,
  prescriptionDocuments: Array<Object>,
  patientDocuments: Array<Object>,
  medicalReportList: Array<Object>,
  sideDrawerOpenHistory: boolean,
  lastDocuments: Object,
};

class Qualification extends React.Component<Props, State> {
  state = {
    countries: [],
    modal: false,
    modalAssign: false,
    newUsername: '',
    adviceCoding: [],
    case: null,
    modalTechnicalProblem: false,
    sideDrawerOpen: false,
    sideDrawerOpenMedicalProfile: true,
    patient: {},
    medicalPatient: {},
    userCountry: {},
    familyDoctor: {},
    reasonToCallNonMed: [],
    sideDrawerOpenHistory: false,
    prescriptionDocuments: null,
    patientDocuments: [],
    medicalReportList: null,
    caseHistory: [],
    caseDefaultHistory: [],
    lastDocuments: {},
  };

  fetchCaseInformation = () => {
    caseApi.fetchDetailById(this.props.queryParams.caseId)
      .then(response => response.json())
      .then((caseInfos) => {
        this.setState({
          case: {
            ...caseInfos,
            countryLocalisation: caseInfos.countryLocalisation ?
              this.getCorrectCountryPrmIdByPrmId(caseInfos.countryLocalisation) :
              this.state.userCountry && this.state.userCountry.id,
          },
        });
      })
      .catch(this.props.dispatch(notifyError));
  };

  componentDidMount = () => {
    this.getInformationOfPatient(this.props.queryParams.patientId);
    this.fetchData();
  };


  fetchData = () => {
    this.fetchFamilyDoctor();

    this.fetchCountries()
      .then((countries) => {
        this.setState({
          allCountries: countries,
          countries,
          userCountry: countries.filter(c => c.isoCode && c.isoCode.toUpperCase() === currentSessionService().currentUser().businessUnitCountryCode.toUpperCase())[0],
        }, () => this.fetchCaseInformation());
      })
      .catch(e => console.log('Erreur apppel API', e));

    adviceCodingApi.fetchAll()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(adviceCoding => this.setState({
        adviceCoding: adviceCoding.map(code => ({
          value: code.id,
          label: code.name,
        })),
      }))
      .catch(this.props.dispatch(notifyError));


    processCaseApi
      .fetchCallQualificationCode()
      .then(response => response.json())
      .then(reasonToCallNonMed => this.setState({
        reasonToCallNonMed: reasonToCallNonMed.map(r => ({
          id: r.id,
          label: I18n.t(`reasonToCallNonMed.${r.code}`),
        })),
      }))
      .catch(this.props.dispatch(notifyError));

    patientApi.fetchById(this.props.queryParams.patientId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((patient) => {
        this.setState({ patient });
        this.fetchDocuments(patient);
      })
      .catch(e => console.log('Erreur apppel API', e));

    caseApi.checkTakenInCharge(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((changeInfos) => {
        if (changeInfos.change) {
          this.setState({ modalAssign: true, newUsername: changeInfos.newUserName });
        }
      })
      .catch(this.props.dispatch(notifyError));

    caseReportApi
      .getDocumentByCaseId(this.props.queryParams.caseId).then(response => response.text()).then(text => (text.length ? JSON.parse(text) : null))
      .then(responseJson =>
        this.setState({
          prescriptionDocuments: responseJson && responseJson.prescriptionList,
          patientDocuments: responseJson && responseJson.patientSendList,
          medicalReportList: responseJson && responseJson.medicalReportList,
        }))
      .catch(this.props.dispatch(notifyError));
  };

  fetchDocuments = (patient) => {
    patientApi
      .getLastDocuments(
        this.props.queryParams.patientId,
        patient.protocolNumber || this.props.queryParams.protocolNumber,
      )
      .then(response => response.json())
      .then(lastDocuments => this.setState({ lastDocuments }))
      .catch(this.props.dispatch(notifyError));
  };


  fetchFamilyDoctor = () => {
    caseApi.getFamilyDoctor(this.props.queryParams.patientId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : null))
      .then(familyDoctor => this.setState({ familyDoctor }))
      .catch(this.props.dispatch(notifyError));
  };


  getCaseHistory = () => {
    caseApi.getCaseHistory(this.props.queryParams.caseId)
      .then(response => response.json())
      .then(caseHistory => this.setState({
        caseHistory: caseHistory.map(value => ({
          ...value,
          eventDate: moment(value.eventDate).format('DD/MM/YYYY HH:mm'),
        })),
        caseDefaultHistory: caseHistory.map(value => ({
          ...value,
          eventDate: moment(value.eventDate).format('DD/MM/YYYY HH:mm'),
        })),
      }))
      .catch(this.props.dispatch(notifyError));
  };

  getAge = (birthday: string) => {
    const birthdate = new Date(birthday);
    const cur = new Date();
    const diff = cur - birthdate; // This is the difference in milliseconds
    return Math.floor(diff / 31557600000);
  };

  getCorrectCountryPrmIdByPrmId = (prmId) => {
    const country = this.state.allCountries.filter(c => c.id === prmId)[0] || {};
    const { isoCode } = country;
    const correctCountry = this.state.countries.filter(c => c.isoCode === isoCode)[0] || {};
    return correctCountry.id;
  };

  fetchCountries = () => new Promise(resolve => referentialApi
    .getAllCountries()
    .then(response => response.json())
    .then(resolve)
    .catch(this.props.dispatch(notifyError)));

  save = async (field, oldValue, newValue) => {
    await caseApi.checkTakenInCharge(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((changeInfos) => {
        if (changeInfos.change) {
          !this.props.modalAssigned && this.setState({ modalAssign: true, newUsername: changeInfos.newUserName });
        } else {
          caseApi.updateCaseField(this.props.queryParams.caseId, this.props.queryParams.patientId, field, {
            oldValue,
            newValue,
          }).then(() => {
            if (field === 'caseQualification') {
              this.props.dispatch(store(CASE_QUALIFICATION, newValue));
            }
          });
        }
      })
      .catch(e => console.log('Erreur apppel API', e));
    this.getCaseHistory();
  };

  drawerToggleClickHandle = () => {
    this.setState({
      sideDrawerOpen: !this.state.sideDrawerOpen,
    });
  };

  drawerToggleHistory = () => {
    this.setState({
      sideDrawerOpenHistory: !this.state.sideDrawerOpenHistory,
    });
  };

  drawerToggleClickHandleMedicalProfile = () => {
    this.setState({
      sideDrawerOpenMedicalProfile: !this.state.sideDrawerOpenMedicalProfile,
    });
  };

  getInformationOfPatient = (id: string) => {
    patientApi
      .getPatientMedicalProfile(id)
      .then(response => response.json())
      .then(responseJson => this.setState({
        medicalPatient: responseJson || {},
      }))
      .catch(this.props.dispatch(notifyError));
  };

  deleteDocument = (documentId) => {
    caseReportApi
      .deleteCaseReport(documentId)
      .then(() => this.setState({
        medicalReportList: this.state.medicalReportList.filter(document => document.id !== documentId),
      }))
      .catch(this.props.dispatch(notifyError));
  };

  searchKeyWordHistory = value => this.setState({
    caseHistory: value ? this.state.caseDefaultHistory.filter(modification =>
      !!modification.userFirstName && modification.userFirstName.toLowerCase().includes(value.toLowerCase())
        || !!modification.userLastName && modification.userLastName.toLowerCase().includes(value.toLowerCase())
        || !!modification.field && modification.field.toLowerCase().includes(value.toLowerCase())
        || !!modification.oldValue && modification.oldValue.toLowerCase().includes(value.toLowerCase())
        || !!modification.newValue && modification.newValue.toLowerCase().includes(value.toLowerCase())
        || !!modification.message && modification.message.toLowerCase().includes(value.toLowerCase())
        || !!modification.modificationType && modification.modificationType.toLowerCase().includes(value.toLowerCase())
        || !!modification.eventDate && modification.eventDate.toLowerCase().includes(value.toLowerCase())) : this.state.caseDefaultHistory,
  });

  onSubmit = (values) => {
    this.props.history
      .push(CASE_PROCESS_PAGE_ROUTE
        + SENDING_DOCUMENTS_PAGE_ROUTE
        + createSearchFromParams(this.props.queryParams));
  };

  updateRadioInfos = (change) => {
    change('caseQualification', teleAdviceCode);
  };

  checkAndDeleteReport = () => {
    if (!this.state.lastDocuments && !this.state.lastDocuments.lastDocumentsBean) {
      return;
    }
    this.state.lastDocuments.lastDocumentsBean
      .filter(v => v.caseId === this.props.queryParams.caseId)
      .forEach(document => this.deleteDocument(document.id));
  };

  render() {
    return (
      <div className="qualification">
        <ModalAssigned
          modal={this.props.caseInfos &&
          this.props.caseInfos.isEditable ?
            this.state.modalAssign :
            false}
          toggle={() => this.setState({ modalAssign: !this.state.modalAssign })}
          newUsername={this.state.newUsername}
        />
        <header>
          <div>
            <Button
              color="secondary"
              onClick={() => {
                this.getCaseHistory();
                this.drawerToggleHistory();
              }}
            >
              <i className="fa fa-history" />
              <span>{I18n.t('qualification.viewHistory')}</span>
            </Button>
          </div>
          <div>
            <span className="qualification-title">
              <i className="fas fa-comments" />
              {I18n.t('qualification.qualificationTitle')}
            </span>
          </div>
          <div>
            <Button
              color="secondary"
              onClick={() => this.setState({ modalTechnicalProblem: !this.state.modalTechnicalProblem })}
            >
              <i className="fal fa-tools " />
              <span>{I18n.t('qualification.technicalProblem')}</span>
            </Button>
          </div>
        </header>
        <CoreozDrawer show={this.state.sideDrawerOpen} side={false}>
          <ListLastDocuments
            patientId={this.props.queryParams.patientId}
            goBack={this.drawerToggleClickHandle}
            lastDocuments={this.state.lastDocuments.lastDocumentsBean}
            nbTeleconsult={this.state.lastDocuments.nbTeleconsult}
            nbTeleadvice={this.state.lastDocuments.nbTeleadvice}
            nbMaxOfTeleconsult={this.state.lastDocuments.nbOfMaxTeleconsult}
          />
        </CoreozDrawer>
        <CoreozDrawer show={this.state.sideDrawerOpenHistory} side={false}>
          <HistoryModification
            caseId={this.props.queryParams.caseId}
            goBack={this.drawerToggleHistory}
            caseHistory={groupHistoryByDateAndIdUser(this.state.caseHistory)}
            searchKeyWordHistory={this.searchKeyWordHistory}
          />
        </CoreozDrawer>
        <div className="qualification-container">
          <MedicalProfile
            drawerToggleClickHandle={this.drawerToggleClickHandle}
            patient={this.state.medicalPatient}
            documents={this.state.lastDocuments}
            queryParams={this.props.queryParams}
          />
          {
            this.state.case ?
              <div className="qualification-content">
                <ModalTechnicalProblem
                  modal={this.state.modalTechnicalProblem}
                  toggle={() =>
                    this.setState({ modalTechnicalProblem: !this.state.modalTechnicalProblem })}
                  case={this.state.case}
                  fetchCase={this.fetchCaseInformation}
                />
                <Form
                  onSubmit={this.onSubmit}
                  initialValues={this.state.case}
                  mutators={{ setFieldData }}
                  render={({ handleSubmit, values, form }) => (
                    <form onSubmit={handleSubmit}>
                      <AutoSave
                        setFieldData={form.mutators.setFieldData}
                        save={this.save}
                        ignoreFields={['isTechnicalIssue', 'technicalIssueText']}
                      />
                      {this.getAge(this.state.patient.birthDate) < 18 &&
                      <div className="under-18">
                        {I18n.t('processCase.UNDER_18')}
                      </div>
                      }
                      <div className="qualification-category">
                        <div className="reason-for-the-call">
                          <div className="category-subtitle">{I18n.t('qualification.reason')}</div>
                          <Field
                            component={ResizableTextarea}
                            className="total-width"
                            minrows={2}
                            maxrows={10}
                            name="reasonForTheCall"
                            disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                          />
                        </div>
                        {this.state.patientDocuments.length > 0 &&
                        <div>
                          <div
                            className="category-subtitle can-be-activate"
                            onClick={() => this.setState({ sideDrawerOpen: true })}
                          >
                            {I18n.t('qualification.NEW_DOCUMENT_FROM_PDA')} <i className="fa fa-paperclip" />
                          </div>
                        </div>}
                        <div className="call-specification">
                          <Field
                            label={I18n.t('teleconsultation.COUNTRY_OF_CALL')}
                            className="select-country"
                            component={CoreozSelect}
                            name="countryCalling" // countryCalling ? countryLocalisation => countryLocalisation = id, countryCalling = string nom
                            onChange={
                              (e) => {
                                // eslint-disable-next-line max-len
                                const countryNew = this.state.countries.filter(country => country.id === e)[0];
                                this.save('countryCalling', '', countryNew.id);
                              }
                            }
                            onBlur={(e, f) => {
                              this.save('countryCalling', f.id, e.id);
                            }}
                            list={this.state.countries}
                            required
                            disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                          />
                          <Field
                            label={I18n.t('teleconsultation.TYPE_OF_CALL')}
                            className="select-call-type"
                            component={CoreozSelect}
                            name="reasonToCallNonMed"
                            list={this.state.reasonToCallNonMed}
                            required
                            disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                          />
                        </div>
                      </div>
                      <div className="empty-category">
                        <div className="category-subtitle">{I18n.t('qualification.textQualification')}</div>
                        <div className="qualification-call">
                          <div className="orientation-box">
                            <i className="fas fa-comments empty-box-icon" />
                            <div className="empty-box-title">{I18n.t('qualification.teleAdvice')}</div>
                            <Field
                              className="radio-input"
                              component="input"
                              name="caseQualification"
                              type="radio"
                              value={teleAdviceCode}
                              onClick={() => {
                                if (values.caseQualification === teleConsultCode) {
                                  this.checkAndDeleteReport();
                                }
                              }}
                              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                            />
                          </div>
                          <div className="orientation-box">
                            <i className="fal fa-stethoscope empty-box-icon" />
                            <div className="empty-box-title">{I18n.t('qualification.teleConsult')}</div>
                            <Field
                              className="radio-input"
                              component="input"
                              name="caseQualification"
                              type="radio"
                              value={teleConsultCode}
                              onClick={() => {
                                if (!values.caseQualification || values.caseQualification === teleAdviceCode) {
                                  this.setState({ modal: !this.state.modal });
                                }
                              }}
                              disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                            />
                          </div>
                        </div>
                      </div>
                      {(!!values.caseQualification && values.caseQualification === teleAdviceCode) &&
                      <div className="tele-advice-part">
                        <div className="qualification-category">
                          <div className="category-subtitle">{I18n.t('qualification.teleAdviceNotes')}</div>
                          <Field
                            className="total-width"
                            component={ResizableTextarea}
                            minrows={5}
                            maxrows={30}
                            name="adviceNote"
                            disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                          />
                        </div>
                        <div className="qualification-category">
                          <div
                            className={`${currentSessionService().currentUser().makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories ? 'teleAdviceCoding-subtitle' : 'teleAdviceCoding-subtitle-required'}`}
                          >{I18n.t('teleconsultation.CODING')}
                          </div>
                          <Field
                            label={I18n.t('qualification.teleAdviceCodification')}
                            className="local-coding"
                            component={CoreozSelectAutocomplete}
                            name="adviceCodingId"
                            placeholder=""
                            options={this.state.adviceCoding}
                            format={v => this.state.adviceCoding.filter(o => o.value === v)[0]}
                            parse={v => v.value}
                            required={!currentSessionService().currentUser().makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories}
                            disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                          />
                        </div>
                        {this.state.medicalReportList ?
                          <ShowAndEditDocument
                            queryParams={this.props.queryParams}
                            title={I18n.t('qualification.ADVICE_REPORT')}
                            case={this.state.case}
                            disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                            documents={this.state.medicalReportList}
                            previewRoute={CASE_PROCESS_PAGE_ROUTE + QUALIFICATION_PAGE_ROUTE + ADVICE_REPORT_PAGE_ROUTE}
                            NO_REPORT={I18n.t('qualification.NO_REPORT')}
                            ADD_REPORT={I18n.t('qualification.ADD_REPORT')}
                            delete={this.deleteDocument}
                          /> :
                          <div className="loader-container">
                            <Loader />
                          </div>}
                        {!values.adviceCodingId && !currentSessionService().currentUser().makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories &&
                        <div className="warning">
                          <span className="warning-text">{I18n.t('qualification.MISSING_DIAGNOSTIC')}</span>
                        </div>}
                        <div className="submit-button">
                          <Button
                            color="primary"
                            type="submit"
                            className="validation-button"
                            disabled={
                              (!currentSessionService().currentUser().makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories && !(values.countryLocalisation && values.reasonToCallNonMed && values.adviceCodingId)) ||
                              (currentSessionService().currentUser().makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories && !(values.countryLocalisation && values.reasonToCallNonMed)) ||
                              !this.props.caseInfos.isEditable}
                          >
                            <i className="fal fa-arrow-circle-right" />
                            {I18n.t('button.CONTINUE')}
                          </Button>
                        </div>
                      </div>
                      }
                      {values.caseQualification === teleConsultCode &&
                      <ModalTransfer
                        queryParams={this.props.queryParams}
                        modal={this.state.modal}
                        toggle={() => this.setState({ modal: !this.state.modal }, () => this.updateRadioInfos(form.change))
                        }
                        familyDoctor={this.state.familyDoctor}
                        caseId={this.props.queryParams.caseId}
                        document={this.state.medicalReportList.length > 0 ? this.state.medicalReportList[0] : {}}
                      />
                      }
                    </form>)}
                />
              </div>
              :
              <div className="loader-container">
                <Loader />
              </div>
          }
          <ContactSideBar
            patientId={this.props.queryParams.patientId}
            caseId={this.props.queryParams.caseId}
            queryParams={this.props.queryParams}
            drawerToggleClickHandle={this.drawerToggleClickHandleMedicalProfile}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(Qualification));
