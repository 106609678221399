// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BUSINESS_UNIT_PAGE_ROUTE } from '../../routes';
import PlmPanel from '../panel/PlmPanel';
import PlmReactTable from '../PlmReactTable';
import type { BusinessUnit } from '../../types/BusinessUnitTypes';

type Props = {
  businessUnits: BusinessUnit[],
  history: Object,
}

type State = {
  columns: Array<{
    Header: string,
    accessor: string,
  }>,
};

class BusinessUnitList extends React.Component<Props, State> {
  state = {
    columns: [
      {
        Header: I18n.t('businessUnit.label.name'),
        accessor: 'name',
      },
      {
        Header: I18n.t('businessUnit.label.prmId'),
        accessor: 'prmId',
      },

    ],
  };

  render() {
    if (this.props.businessUnits === undefined || !Array.isArray(this.props.businessUnits)) {
      return false;
    }

    return (
      <PlmPanel title={I18n.t('businessUnit.SUBTITLE')}>
        <div className="panel-body">
          <PlmReactTable
            style={{ cursor: 'pointer' }}
            className="table table-striped"
            data={this.props.businessUnits}
            columns={this.state.columns}
            getTdProps={(state, rowInfo) => ({
              onClick: () => {
                if (rowInfo) {
                  this.props.history.push(`${BUSINESS_UNIT_PAGE_ROUTE}/${rowInfo.original.id}`);
                }
              },
            })}
          />
        </div>
        <div className="panel-footer">
        </div>
      </PlmPanel>
    );
  }
}

export default withRouter(connect()(BusinessUnitList));
