import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Loader from '../../components/Loader';
import {
	CoreozDatePickerBase,
	CoreozInputBase,
	CoreozSelectBase,
} from '../../lib/coreoz-form-base/index';
import { ProtocolStatus } from '../../enum';
import Pagination from 'react-js-pagination';
import ProtocolContext from '../../stores/protocol-management-context';
import {
	SearchCriteriaType,
	SearchResultType,
} from '../../types/ProtocolManagement';
import protocolApi from '../../network/api/protocolApi';
import userApi from '../../network/api/userApi';
import bPartnerApi from '../../network/api/bPartnerApi';
import legalEntityApi from '../../network/api/legalEntityApi';
import brandApi from '../../network/api/brandApi';
import ProtocolTable from '../../components/protocol/protocolTable';
import {
	PROTOCOL_CREATE_PAGE_ROUTE,
	PROTOCOL_EDIT_PAGE_ROUTE,
	PROTOCOL_MANAGEMENT_PAGE_ROUTE,
} from '../../routes';
import exportApi from '../../network/api/exportApi';
import { download } from '../../utils';
import { notifyError } from '../../network/notification';
import ButtonExport from '../../components/ButtonExport';

const initialPage = {
	currentPage: 1,
	pageSize: 10,
	perPage: 0,
	totalPages: 0,
};

const ProtocolManagement = (props) => {
	const { searchCriteriaContext, updateSearchCriteriaContext } =
		useContext(ProtocolContext);
	const [searchResults, setSearchResults] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalSearchResult, setTotalSearchResult] = useState(0);
	const [legalEntities, setLegalEntities] = useState([]);
	const [bPartners, setBPartners] = useState([]);
	const [brands, setBrands] = useState([]);

	const [currentPage, setCurrentPage] = useState(initialPage.currentPage);
	const [pageSize, setPageSize] = useState(initialPage.pageSize);
	const [perPage, setPerPage] = useState(initialPage.perPage);
	const [totalPages, setTotalPages] = useState(initialPage.totalPages);

	const [exportLoading, setExportLoading] = useState(false);

	useEffect(() => {
		fetchLegalEntities();
		fetchBPartners();
		fetchBrands();
		if (searchCriteriaContext) {
			fetchProtocol(searchCriteriaContext);
		} else {
			fetchProtocol({
				page: currentPage,
				size: pageSize,
			});
		}
	}, []);

	async function fetchProtocol(searchCriteria: SearchCriteriaType = {}) {
		setLoading(true);

		if (!searchCriteria?.page) {
			searchCriteria.page = initialPage.currentPage;
		}
		if (!searchCriteria?.size) {
			searchCriteria.size = initialPage.pageSize;
		}

		const searchResultResponse = await props.dispatch(
			protocolApi.search(searchCriteria)
		);

		updateSearchCriteriaContext({ ...searchCriteria });

		const {
			content = [],
			page = initialPage.currentPage,
			perPage = initialPage.perPage,
			total = 0,
			totalPages = initialPage.totalPages,
		} = searchResultResponse ?? {};

		setSearchResults(content);
		setCurrentPage(page);
		setPerPage(perPage);
		setTotalSearchResult(total);
		setTotalPages(totalPages);

		setLoading(false);
	}

	async function fetchBPartners() {
		const bPartnerResponses = await bPartnerApi
			.fetchBpartners()
			.then((response) => response.text())
			.then((text) => (text.length ? JSON.parse(text) : []));
		if (bPartnerResponses) {
			setBPartners(bPartnerResponses);
		}
	}

	async function fetchLegalEntities() {
		const legalEntityResponses = await legalEntityApi.fetchList();
		if (legalEntityResponses) {
			setLegalEntities(legalEntityResponses);
		}
	}

	async function fetchBrands() {
		const brandResponses = await brandApi.fetchList();
		if (brandResponses) {
			setBrands(brandResponses);
		}
	}

	const handlePageChange = (pageNumber) => {
		fetchProtocol({
			...searchCriteriaContext,
			page: pageNumber,
		});
		setCurrentPage(pageNumber);
	};

	const resetSearchResults = () => {
		fetchProtocol();
	};

	const goToView = (itemId: Number) => {
		props.history.push(PROTOCOL_EDIT_PAGE_ROUTE.replace(':id', itemId));
	};

	const downloadReport = (searchParameters: SearchCriteriaType) => {
		setExportLoading(true);

		const filters = {
			...searchParameters,
			columnsTranslation: I18n.t('protocol.export'),
		};

		exportApi
			.exportProtocolKpi(filters)
			.then((response) => response.blob())
			.then((blob) =>
				download(blob, 'export_protocol.csv')
			)
			.catch((e) => {
				notifyError(props.dispatch)(e);
			})
			.finally(() => {
				setExportLoading(false);
			});
	};

	const onSearch = (values: SearchCriteriaType) => {
		fetchProtocol({ ...values, page: 1 });
	};

	return (
		<div className="protocol-management-container" id="protocolManagement">
			<div className="title-container">
				<div className="title">{I18n.t('protocol.TITLE_LIST')}</div>
			</div>
			<div className="search-bar">
				<Form
					onSubmit={onSearch}
					render={({ handleSubmit, form, values }) => (
						<form onSubmit={handleSubmit}>
							<div className="input-field-container">
								<Field
									name="protocolNumber"
									id="protocolCodeSearch"
									label={I18n.t(
										'protocol.label.PROTOCOL_NUMBER'
									)}
									component={CoreozInputBase}
									className="m-0"
									defaultValue={
										searchCriteriaContext?.protocolNumber
									}
								/>

								<Field
									name="protocolName"
									id="protocolNameSearch"
									label={I18n.t(
										'protocol.label.PROTOCOL_NAME'
									)}
									component={CoreozInputBase}
									className="m-0"
									defaultValue={
										searchCriteriaContext?.protocolName
									}
								/>

								<Field
									name="bPartnerId"
									id="bPartnerSearch"
									autoComplete="off"
									type="text"
									className="m-0"
									label={I18n.t('protocol.label.B_PARTNER')}
									component={CoreozSelectBase}
									list={bPartners.map((bPartner) => ({
										id: bPartner.id,
										label: `${bPartner.bpartnerCode} - ${bPartner.bpartnerName}`,
									}))}
									required={false}
									placeholder="All"
									disabledPlaceholder={false}
									defaultValue={
										searchCriteriaContext?.bPartnerId
									}
								/>

								<Field
									name="legalEntityId"
									id="legalEntitySearch"
									autoComplete="off"
									type="text"
									className="m-0"
									label={I18n.t(
										'protocol.label.LEGAL_ENTITY'
									)}
									component={CoreozSelectBase}
									list={legalEntities.map((legalEntity) => ({
										id: legalEntity.id,
										label: `${legalEntity.code} - ${legalEntity.name}`,
									}))}
									required={false}
									placeholder="All"
									disabledPlaceholder={false}
									defaultValue={
										searchCriteriaContext?.legalEntityId
									}
								/>

								<Field
									name="brandId"
									id="brandSearch"
									autoComplete="off"
									type="text"
									className="m-0"
									label={I18n.t('protocol.label.BRAND')}
									component={CoreozSelectBase}
									list={brands.map((brand) => ({
										id: brand.id,
										label: `${brand.code} - ${brand.name}`,
									}))}
									required={false}
									placeholder="All"
									disabledPlaceholder={false}
									defaultValue={
										searchCriteriaContext?.brandId
									}
								/>

								<Field
									name="status"
									id="statusSearch"
									type="text"
									className="m-0"
									component={CoreozSelectBase}
									label={I18n.t('protocol.label.STATUS')}
									list={Object.keys(ProtocolStatus).map(
										(v) => ({
											id: v,
											label: I18n.t(
												`protocol.STATUS.${v}`
											),
										})
									)}
									required={false}
									placeholder="All"
									disabledPlaceholder={false}
									defaultValue={searchCriteriaContext?.status}
								/>
							</div>

							<div className="button-container">
								<div className="search-box pt-4">
									<Button
										type="submit"
										color="primary"
										className="validation-button m-0"
										id="searchBtnSearch"
									>
										<i className="fa fa-search" />
										{I18n.t('button.SEARCH')}
									</Button>
									<Button
										type="button"
										color="primary"
										className="validation-button m-0"
										onClick={() => {
											form.reset();
											resetSearchResults();
										}}
										id="resetBtnSearch"
									>
										<i className="fa fa-redo-alt" />
										{I18n.t('button.RESET')}
									</Button>
								</div>
								<div className="search-box mt-2 pt-4">
									<ButtonExport
										downloadReport={downloadReport}
										loading={exportLoading}
										values={values}
									/>
									<Button
										type="button"
										color="primary"
										className="validation-button m-0"
										onClick={() => {
											props.history.push(
												PROTOCOL_CREATE_PAGE_ROUTE
											);
										}}
										id="createButton"
									>
										<i className="fa fa-plus" />
										{I18n.t('protocol.button.ADD')}
									</Button>
								</div>
							</div>

							<div />
						</form>
					)}
				/>
			</div>

			<div className="search-result">
				{!loading && searchResults?.length > 0 && (
					<>
						<div className="box-result">
							<ProtocolTable
								currentItems={searchResults}
								goToView={goToView}
							/>
						</div>
						<div className="box-paginate">
							<div className="show-paginate-result">
								{I18n.t('protocol.TABLE.PAGINATION_INFO', {
									start: (currentPage - 1) * pageSize + 1,
									end:
										(currentPage - 1) * pageSize + pageSize,
									total: totalSearchResult,
								})}
							</div>
							<div className="paginate-list">
								<Pagination
									itemClass="page-item"
									linkClass="page-link"
									firstPageText="&laquo;"
									lastPageText="&raquo;"
									prevPageText="&lsaquo;"
									nextPageText="&rsaquo;"
									activePage={currentPage}
									itemsCountPerPage={totalPages}
									totalItemsCount={totalSearchResult}
									pageRangeDisplayed={5}
									onChange={handlePageChange}
								/>
							</div>
						</div>
					</>
				)}
				{!loading && !searchResults?.length && (
					<div className="no-result">
						{I18n.t('internalBeneficiary.SEARCH.NO_RESULT')}
					</div>
				)}
				{loading && (
					<div className="center-child search-loader">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

export default withRouter(connect()(ProtocolManagement));
