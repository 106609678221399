// @flow

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';

import PlmPanel from '../../components/panel/PlmPanel';
import PlmFormItem from '../../components/panel/PlmFormItem';
import { SMS_PAGE_ROUTE } from '../../routes';
import { required } from '../../validator';
import type { Sms } from '../../types/SmsTypes';
import { toggleConfirmationWithModal } from '../../state/notifications/notificationService';

import smsApi from '../../network/api/smsApi';
import { SMS_LIST } from '../../state/sms/smsReducer';
import { store } from '../../network/reduce';
import { fetchSmsList } from '../../state/sms/smsService';
import { notifyError, notifySuccess } from '../../network/notification';
import ResizableTextarea from '../../components/ResizableTextarea';

type Props = {
  smsList: Sms[],
  match: {
    params: {
      id: string,
    }
  },
  history: Object,
  dispatch: Function,
}

type State = {
  labelTitle: string,
  smsSelected: Sms,
}

class SmsDetails extends React.Component<Props, State> {
  state = {
    labelTitle: I18n.t('sms.NEW_TITLE'),
    smsSelected: {},
  };

  componentDidMount() {
    if (this.props.match.params.id !== null) {
      const smsSelectedId = this.props.match.params.id;
      smsApi
        .fetch()
        .then(response => response.json())
        .then((smsList) => {
          this.props.dispatch(store(SMS_LIST, smsList));
          const currentSms = smsList.filter(sms => sms.id === smsSelectedId)[0];
          this.setState({
            smsSelected: currentSms,
            labelTitle: I18n.t('sms.EDIT_TITLE'),
          });
        });
    }
  }

  saveSms = (sms: Sms) => {
    smsApi
      .save(sms)
      .then(this.props.dispatch(notifySuccess))
      .then(() => {
        this.props.dispatch(fetchSmsList());
        this.props.history.push(SMS_PAGE_ROUTE);
      })
      .catch(this.props.dispatch(notifyError));
  };

  componentWillUnmount() {
    this.props.dispatch(toggleConfirmationWithModal(false));
  }

  getSmsOptionList = (selectOptions: Array<Object>) => {
    const smsOptionList = [];
    selectOptions.forEach((option: Object) => {
      if (!smsOptionList.includes(option.smsType)) {
        smsOptionList.push(option.smsType);
      }
    });
    return smsOptionList;
  };

  render() {
    if (this.props.smsList === undefined) {
      return false;
    }
    return (
      <div>
        <h1 className="content-title"><Translate value="sms.TITLE" /></h1>
        <div className="content">
          <PlmPanel title={this.state.labelTitle}>
            <Form
              className="form-horizontal"
              onSubmit={this.saveSms}
              initialValues={this.state.smsSelected}
              render={({ handleSubmit, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="panel-body">
                    <PlmFormItem label={I18n.t('sms.label.idBusinessUnit')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="input"
                        name="idBusinessUnit"
                      />
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('sms.label.smsType')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="select"
                        name="smsType"
                      >
                        <option value="any">{I18n.t('sms.label.all')}</option>
                        {this.props.smsList.length > 0 &&
                        this.getSmsOptionList(this.props.smsList)
                          .map(option => (
                            <option value={option} key={option}>{option}</option>
                          ))
                        }
                      </Field>
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('sms.label.template')}>
                      <Field
                        component={ResizableTextarea}
                        minrows={5}
                        maxrows={30}
                        name="template"
                        validate={required}
                      />
                    </PlmFormItem>
                  </div>
                  <div className="panel-footer">
                    <Link
                      className="btn btn-secondary"
                      style={{ marginRight: '5px' }}
                      to={SMS_PAGE_ROUTE}
                    >
                      <Translate value="actions.BACK" />
                    </Link>
                    <button type="submit" className="btn btn-success" disabled={invalid || submitting}>
                      <Translate value="actions.SAVE" />
                    </button>
                  </div>
                </form>
              )}
            />
          </PlmPanel>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  smsList: state.sms.smsList,
}))(SmsDetails));

