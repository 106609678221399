import React from 'react';
import { LOGO_MANAGEMENT_PAGE_ROUTE, LOGO_EDIT_PAGE_ROUTE, LOGO_CREATE_PAGE_ROUTE } from '../../routes';
import PrivateRoute from '../../components/PrivateRoute';
import LogoManagement from './logoManagement';
import LogoRequest from './logoRequest';
import { LogoContextProvider } from '../../stores/logo-management-context';

export default function LogoManagementRoutes() {
  return (
    <LogoContextProvider>
      <PrivateRoute exact path={LOGO_MANAGEMENT_PAGE_ROUTE} component={LogoManagement} />
      <PrivateRoute path={LOGO_EDIT_PAGE_ROUTE} component={LogoRequest} />
      <PrivateRoute path={LOGO_CREATE_PAGE_ROUTE} component={LogoRequest} />
    </LogoContextProvider>
  );
}
