// @flow

import { I18n } from 'react-redux-i18n';
import moment from 'moment';

const slotDurationMax = 15;

export const required = value => ((typeof value === 'undefined' || String(value).trim().length === 0) ? I18n.t('form.validator.REQUIRED') : undefined);

export const dateValidator = (value: ?string, format: string, isRequired: ?Boolean) => { // format 'DD/MM/YYYY'
  if (value && !(moment(value, format, true).isValid())) {
    return I18n.t('form.validator.DATE_FORMAT');
  }

  if (value && (moment(value, 'YYYY-MM-DD', true).isValid() && moment(value, 'YYYY-MM-DD', true).isAfter(moment())) ||
    (moment(value, 'DD/MM/YYYY', true).isValid() && moment(value, 'DD/MM/YYYY', true).isAfter(moment()))) {
    return I18n.t('form.validator.DATE_IN_FUTURE');
  }


  if (isRequired && !value) {
    return I18n.t('form.validator.REQUIRED');
  }
  return undefined;
};

export const dateCompareValidator = (startDate, endDate) => {
  if (startDate && endDate) {
    if (startDate.isAfter(endDate)) {
      return I18n.t('form.validator.DATE_COMPARE');
    }
    if (startDate.isBefore(moment())) {
      return I18n.t('form.validator.START_DATE_BEFORE_NOW');
    }
  }
  return undefined;
};

export const hoursCompareValidator = (startDate, endDate) => {
  if (startDate && endDate && moment.isMoment(startDate) && moment.isMoment(endDate)) {
    const startMinutesOfDay = startDate.minutes() + startDate.hours() * 60;
    const endMinutesOfDay = endDate.minutes() + endDate.hours() * 60;
    if (endMinutesOfDay <= startMinutesOfDay) {
      return I18n.t('form.validator.DATE_COMPARE');
    }
  }
  return undefined;
};

export const simpleDateValidator =
  (value: string, format: string) => ((value && !(moment(value, format, true).isValid())) ?
    I18n.t('form.validator.DATE_FORMAT') : undefined);

export function validateSlotDuration(value: ?number) {
  if (value && value < slotDurationMax) {
    return I18n.t('form.validator.VALIDATE_SLOT_DURATION', { duration: slotDurationMax });
  }
  return undefined;
}

export function validatePhone(value: ?string) {
  if (value && ((value.match(/^\+[1-9]{2}\s?[1-9](?:[\s.-]?\d){4,11}$/)) || value.length === 3)) {
    return undefined;
  }
  if (value && value.match(/^\+[1-9]{2}0/)) {
    return I18n.t('form.validator.VALIDATE_PHONE_NO_0');
  }
  return I18n.t('form.validator.VALIDATE_PHONE');
}

export function validatePhoneGoogle(phoneNumber: ?Object, isRequired: ?Boolean) {
  if (phoneNumber) {
    if (phoneNumber.isPossible()) {
      return undefined;
    }
    return I18n.t('form.validator.VALIDATE_PHONE');
  }
  if (isRequired) {
    return I18n.t('form.validator.REQUIRED');
  }
  return undefined;
}

export function validateDifferentValues(value: ?string, otherValue: string) {
  if (!value || !otherValue || (value !== otherValue)) {
    return undefined;
  }
  return I18n.t('form.validator.VALIDATE_IDENTICAL_VALUES');
}

export function validateOnlyOneValue(value: ?string, otherValueIsPresent: boolean) {
  if (!value || (value && otherValueIsPresent)) {
    return undefined;
  }
  return I18n.t('form.validator.VALIDATE_JUST_ONE_VALUES');
}

export function validateEmail(email: ?string, isRequired: ?Boolean) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (isRequired && !email) {
    return I18n.t('form.validator.REQUIRED');
  }

  if ((email && !re.test(email))) {
    return I18n.t('form.validator.VALIDATE_EMAIL');
  }

  return undefined;
}

export function validateEmailFormat(email: ?string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if ((email && !re.test(email))) {
    return I18n.t('form.validator.VALIDATE_EMAIL');
  }
}

export function validateForEmail(email: ?string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  if ((email && re.test(email))) {
    return true;
  }
  if ((email === undefined)) {
    return true;
  }
  return false;
}

export function validateListNotEmpty(list: ?Array<string>) {
  if (!!list && list.length) {
    return undefined;
  }
  return I18n.t('form.validator.VALIDATE_LIST');
}

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const validateCost = (value) => {
  let booleanTest = false;
  if (value) { // value !== null && value !== ''
    booleanTest = !!String(value).match(/^(\d+)$|^(\d+([,|.])(\d{1,2})?)$/g);
    // explication, retourne true si value est un nombre qu'il ne posède qu'un seul séparateur ('.' ou ',') avec max deux chiffres derrière
    // donc '12.20' ok
    // 'a12' ou '1.2.3' ko

    if (booleanTest) {
      return undefined;
    }
    return I18n.t('contest.details.affectation.INCORRECT_ENTRY');
  }
};

export const validateWeight = (value) => {
  let booleanTest = false;
  if (value) { // value !== null && value !== ''
    booleanTest = !!String(value).match(/^(\d+)$/g);
    // explication, retourne true si value est un nombre sans séparateur ('.' ou ',')
    if (booleanTest) {
      return undefined;
    }
    return I18n.t('contest.details.affectation.INCORRECT_ENTRY');
  }
};


export function emailCompareAndCheckRequireValidator(codeRecipientMail: ?string, linkRecipientMail: ?string, codeRecipientPhone: ?string, linkRecipientPhone: ?string, value: null) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  if ((codeRecipientPhone == '' || linkRecipientPhone == '') && ((codeRecipientMail === undefined || codeRecipientMail.trim().length == 0) && (linkRecipientMail === undefined || linkRecipientMail.trim().length == 0))) {
    return I18n.t('form.validator.REQUIRED');
  }


  if (codeRecipientMail || linkRecipientMail) {
    validateDifferentValues(codeRecipientMail, linkRecipientMail);
  }
}

export const dateCompareAndCheckRequireValidator = (startDate, endDate, value: null, isRequired: ?Boolean, format) => {
  if (isRequired && !value) {
    return I18n.t('form.validator.REQUIRED');
  }

  if (!format) {
    format = I18n.t('date.DATE_FORMAT');
  }

  if (value && !isValidDate(value)) {
    return I18n.t('form.validator.DATE_FORMAT');
  }

  if (startDate && endDate && isValidDate(startDate) && isValidDate(endDate)) {
    if (moment(startDate).isAfter(moment(endDate))) {
      return I18n.t('form.validator.DATE_COMPARE');
    }
  }

  return undefined;
};

export const isValidDate = (value: string, format): boolean => {
  if (!format) {
    format = I18n.t('date.DATE_FORMAT');
  }
  if (value && (!moment(value).isValid() || !moment(moment(value).format(format), format, true).isValid())) {
    return false;
  }
  return true;
};


export const notTooShort = value => ((!!value) && (value.length >= 3) ? undefined : I18n.t('form.validator.TOO_SHORT'));

export const validatePasswordConfirm = (password: string, valueConfirmPassword: string) => {
  if (password && !valueConfirmPassword) {
    return I18n.t('form.validator.REQUIRED');
  }

  if (password && valueConfirmPassword && password !== valueConfirmPassword) {
    return I18n.t('form.validator.CONFIRM_PASSWORD');
  }


  return undefined;
};

export const dateValidatorRequired = (value: ?string) => {
  if (typeof value === 'undefined') {
    return I18n.t('form.validator.REQUIRED');
  }

  if (value) {
    if (!(moment(value, 'DD/MM/YYYY', true).isValid())) {
      return I18n.t('form.validator.DATE_FORMAT');
    }

    if ((moment(value, 'YYYY-MM-DD', true).isValid() && moment(value, 'YYYY-MM-DD', true).isAfter(moment())) ||
      (moment(value, 'DD/MM/YYYY', true).isValid() && moment(value, 'DD/MM/YYYY', true).isAfter(moment()))) {
      return I18n.t('form.validator.DATE_IN_FUTURE');
    }
  }

  return undefined;
};

export const validateNumber = (value: ?string, valueLength = 0) => {
  if (typeof value === 'undefined' || String(value).trim().length === 0) {
    return I18n.t('form.validator.REQUIRED');
  }

  if (value && value.length < valueLength) {
    return I18n.t('form.validator.REQUIRED_DIGITS', { digitValue: valueLength.toString() });
  }

  return undefined;
};

