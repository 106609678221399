

import React, { createContext, useState } from 'react';
import { SearchCriteria } from '../types/BusinessUnitTypes';

const BusinessUnitContext = createContext({
  searchCriteriaContext: null,
  updateCriteriaSearchContext: (searchCriteria: SearchCriteria) => { },
});

export function BusinessUnitContextProvider(props) {
  const [useSearchCriteria, setUseSearchCriteria] = useState(null);

  function updateSearchCriteriaHandler(searchCriteria: SearchCriteria) {
    setUseSearchCriteria(searchCriteria);
  }

  const context = {
    searchCriteriaContext: useSearchCriteria,
    updateSearchCriteriaContext: updateSearchCriteriaHandler,
  };

  return (
    <BusinessUnitContext.Provider value={context}>
      {props.children}
    </BusinessUnitContext.Provider>
  );
}

export default BusinessUnitContext;
