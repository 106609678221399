// @flow

import React, { Fragment } from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import { CoreozSelectBase as CoreozSelect } from '../../../lib/coreoz-form-base/index';
import { Field, Form } from 'react-final-form';
import {
  CASE_PROCESS_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  ELIGIBILITY_PAGE_ROUTE,
  IDENTIFICATION_PAGE_ROUTE,
} from '../../../routes';
import { Availability, RecordingReason, teleConsultCode, UserRole } from '../../../enum';
import caseApi from '../../../network/api/caseApi';
import ModalEprm from '../../modal/ModalEprm';
import userApi from '../../../network/api/userApi';
import { store } from '../../../network/reduce';
import { USER_IS_AVAILABLE } from '../../../state/process/processReducer';
import CaseStatus from '../../../pages/dashboard/CaseStatus';
import { currentSessionService } from '../../../services/sessionServiceInstance';
import { closeRecording } from '../../../services/recordingService';

type Props = {
  dispatch: Function,
  store: Function,
  history: {
    push: Function,
  },
  autoSave: boolean,
  canRender: boolean,
  caseInfos: Object,
  queryParams: {
    caseId: string,
    patientId: string,
  },
  location: Object,
  fetchData: Function,
}

type State = {
  creationDate: string,
  status: string,
  doctorName: string,
  gender: string,
  patientName: string,
  patientLastName: string,
  patientCity: string,
  patientCountry: string,
  patientBirthDate: string,
  available: boolean,
  modal: boolean,
  back: boolean,
  users: Array<Object>,
}

class ProcessCaseHeader extends React.Component<Props, State> {

  state = {
    status: this.props.caseInfos && this.props.caseInfos.caseInfos && I18n.t('processCaseHeader.' + this.props.caseInfos.caseInfos.status),
    doctorName: this.props.caseInfos && this.props.caseInfos.caseInfos && this.props.caseInfos.caseInfos.userName,
    creationDate: this.props.caseInfos && this.props.caseInfos.caseInfos && this.props.caseInfos.caseInfos.dateCreation,
    gender: this.props.caseInfos && this.props.caseInfos.patient.genderCode,
    patientName: this.props.caseInfos && this.props.caseInfos.patient.firstName,
    patientLastName: this.props.caseInfos && this.props.caseInfos.patient.lastName,
    patientCity: this.props.caseInfos && this.props.caseInfos.patient.city,
    patientCountry: this.props.caseInfos && this.props.caseInfos.patient.addressCountry,
    patientBirthDate: this.props.caseInfos && this.props.caseInfos.patient.birthdate,
    back: false,
    modal: false,
    users: [],
    available: this.props.userIsAvailable,
  };

  componentWillReceiveProps(nextProps: Object) {
    if (this.props.canRender !== nextProps.canRender) {
      this.setState({
        status: nextProps.caseInfos && nextProps.caseInfos.caseInfos && I18n.t('processCaseHeader.' + nextProps.caseInfos.caseInfos.status),
        doctorName: nextProps.caseInfos && nextProps.caseInfos.caseInfos && nextProps.caseInfos.caseInfos.userName,
        creationDate: nextProps.caseInfos && nextProps.caseInfos.caseInfos && nextProps.caseInfos.caseInfos.dateCreation,
        gender: nextProps.caseInfos && nextProps.caseInfos.patient.genderCode,
        patientName: nextProps.caseInfos && nextProps.caseInfos.patient.firstName,
        patientLastName: nextProps.caseInfos && nextProps.caseInfos.patient.lastName,
        patientCity: nextProps.caseInfos && nextProps.caseInfos.patient.city,
        patientCountry: nextProps.caseInfos && nextProps.caseInfos.patient.addressCountry,
        patientBirthDate: nextProps.caseInfos && nextProps.caseInfos.patient.birthDate,
      });
      
      
    }
    if (this.props.available !== nextProps.userIsAvailable) {
      this.setState({ available: nextProps.userIsAvailable });
    }
  }

  componentDidMount() {
    userApi
      .fetchByBusinessUnit()
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(users => this.setState({ users }))
      .catch(e => console.log('Erreur apppel API', e));
  }

  takeInCharge = () => {
    caseApi
      .takeInCharge(this.props.queryParams.caseId)
      .then(() => this.props.fetchData(this.props))
      .then(() => {
        this.props.dispatch(store(USER_IS_AVAILABLE, currentSessionService().currentUser().isAvailable));
      })
      .catch(e => console.log('Erreur apppel API', e));
  };

  assignedTo = (userId) => {

    const isEditable = this.props.caseInfos && this.props.caseInfos.isEditable;
    if(isEditable){
      closeRecording(this.props.dispatch, this.props.queryParams.caseId, RecordingReason.BACK_TO_DASHBOARD);
    }
    
    caseApi
      .assignedTo(this.props.queryParams.caseId, userId)
      .then(() => {
          this.props.fetchData(this.props);
          this.props.dispatch(store(USER_IS_AVAILABLE, currentSessionService().currentUser().isAvailable));
          this.props.history.push(DASHBOARD_PAGE_ROUTE);
        }
      )
      .catch(e => console.log('Erreur apppel API', e));
  };

  updateAvailability = (available: boolean) => {
    userApi
      .updateAvailability(available)
      .then(() => this.props.dispatch(store(USER_IS_AVAILABLE, available)))
      .catch(e => console.log('Erreur apppel API', e));
  };
  

  backToDashboard = () => {
    const isEditable = this.props.caseInfos && this.props.caseInfos.isEditable
    if(isEditable){
      closeRecording(this.props.dispatch, this.props.queryParams.caseId, RecordingReason.BACK_TO_DASHBOARD);
    }
    
    caseApi
      .isUserInChargeOfCases()
      .then(res => res.text())
      .then(inCharge => this.updateAvailability(inCharge === 'false'))
      .then(() => this.props.history.push(DASHBOARD_PAGE_ROUTE))
      .catch(e => console.log('Erreur apppel API', e));
  };

  render() {
    const liveStatus = !!this.props.caseInfos && !!this.props.caseInfos.caseInfos && (this.props.caseInfos.caseInfos.videoStatus || this.props.caseInfos.caseInfos.chatStatus);
    const dateCreation = !!this.props.caseInfos && !!this.props.caseInfos.caseInfos && (this.props.caseInfos.caseInfos.startDateAppointment || this.props.caseInfos.caseInfos.dateCreation);
    return (
      <div className="process-case-header">
        <ModalEprm
          title={I18n.t('processCaseHeader.modal.ASSIGNED_TITLE')}
          modal={this.state.modal}
          toggle={() => this.setState({ modal: !this.state.modal })}
        >
          <div className="take-in-charge-message">
            {I18n.t('processCaseHeader.modal.ASSIGNED_TEXT')}
          </div>
          <Form
            onSubmit={(data) => this.assignedTo(data.idUser)}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  label={I18n.t('processCaseHeader.modal.USERS')}
                  component={CoreozSelect}
                  name="idUser"
                  list={this.state.users.map(user => ({ id: user.id, label: `${user.firstName  } ${ user.lastName}` }))}
                />
                <div className="take-in-charge-buttons">
                  <Button
                    color="secondary"
                    onClick={() => this.setState({ modal: !this.state.modal })}
                  >
                    <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() => {
                      this.setState({ modal: !this.state.modal });
                    }}
                  >
                    <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('processCaseHeader.modal.BUTTON')}
                  </Button>
                </div>
              </form>
            )}
          />
        </ModalEprm>
        <div id="cancel-button" onClick={() => this.setState({ back: !this.state.back })}>
          <ModalEprm
            title={I18n.t('processCaseHeader.backToDashboard.BACK_TITLE')}
            modal={this.state.back}
            toggle={() => this.setState({ back: !this.state.back })}
          >
            <div className="take-in-charge-message">
              {I18n.t('processCaseHeader.backToDashboard.BACK_TEXT')}
            </div>
            <div className="take-in-charge-buttons">
              <Button
                color="secondary"
                onClick={() => this.setState({ back: !this.state.back })}
              >
                <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
              </Button>
              <Button
                color="primary"
                onClick={() => this.backToDashboard()}
              >
                <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.BACK_TO_DASHBOARD')}
              </Button>
            </div>
          </ModalEprm>
          <i className="fa fa-long-arrow-left" />
          <div className="action">{I18n.t('processCaseHeader.CLOSE')}</div>
        </div>
        {this.props.canRender &&
        <div className="process-case-header-nav">
          <div className="case-info">
            {this.state.creationDate !== null &&
            <Fragment>
              <i className="fa fa-user-injured" />
              <div>
                <div className="case-name">{I18n.t('processCaseHeader.TITLE')}</div>
                {!((this.props.location.pathname === CASE_PROCESS_PAGE_ROUTE + IDENTIFICATION_PAGE_ROUTE) ||
                  (this.props.location.pathname === CASE_PROCESS_PAGE_ROUTE + ELIGIBILITY_PAGE_ROUTE)) ?
                  <div className="creation-date">
                    {moment(this.state.creationDate).format(I18n.t('date.DATE_LONG'))}
                  </div>
                  :
                  <div />
                }
              </div>
            </Fragment>
            }
          </div>
          {!((this.props.location.pathname === CASE_PROCESS_PAGE_ROUTE + IDENTIFICATION_PAGE_ROUTE) ||
            (this.props.location.pathname === CASE_PROCESS_PAGE_ROUTE + ELIGIBILITY_PAGE_ROUTE)) ?
            <div className={`save-message ${this.props.autoSave ? 'saved' : 'not-saved'}`}>
              <i className="far fa-save" />
              <div className="message">
                {this.props.autoSave ? I18n.t('processCaseHeader.AUTO_SAVED') : I18n.t('processCaseHeader.AUTO_SAVING')}
              </div>
            </div>
            :
            <div>
            </div>
          }
          <div className="patient-info">
            {this.state.gender &&
            <i className={`${this.state.gender === 1 ? 'fa fa-mars' : 'fa fa-venus'} gender`} />}
            <div>
              {this.state.patientName &&
              <div className="major-info">
                <div className="firstName"
                     title={`${this.state.patientName} ${this.state.patientLastName}`}>{this.state.patientName} </div>
                <div className="lastName"
                     title={`${this.state.patientName} ${this.state.patientLastName}`}>{this.state.patientLastName}</div>
                {this.state.patientCity && <span>{` - ${this.state.patientCity}`}</span>}
                {this.state.patientCountry && <span>{`, ${this.state.patientCountry}`}</span>}
              </div>}
              {this.state.patientBirthDate &&
              <div className="minor-info">
                {moment().diff(this.state.patientBirthDate, 'years')} {I18n.t('processCaseHeader.YEARS')}
                - {moment(this.state.patientBirthDate).format(I18n.t('date.DATE_FORMAT'))}
              </div>
              }
            </div>
          </div>
          <div id="availability">
            <div id="status" className={this.state.available ? Availability.AVAILABLE : Availability.BUSY}
                 onClick={() => this.setState({ available: !this.state.available }, () => this.updateAvailability(this.state.available))}
            >
              <i className="fa fa-circle" />
              {this.state.available ? I18n.t('header.availability.AVAILABLE') : I18n.t('header.availability.BUSY')}
            </div>
          </div>

          <ul className="nav nav-pills">
            {(!((this.props.location.pathname === CASE_PROCESS_PAGE_ROUTE + IDENTIFICATION_PAGE_ROUTE) ||
              (this.props.location.pathname === CASE_PROCESS_PAGE_ROUTE + ELIGIBILITY_PAGE_ROUTE)) && !!this.props.queryParams.caseId) ?
              <UncontrolledDropdown className="nav-item dropdown" inNavbar>
                <DropdownToggle nav>
                  <div className="case-status">
                    {
                      this.props.caseInfos && this.props.caseInfos.caseInfos &&
                      <CaseStatus
                        userName={this.props.caseInfos.caseInfos.userName}
                        userRole={this.props.caseInfos.caseInfos.caseQualification === teleConsultCode ? UserRole.DOCTOR_LEVEL : UserRole.NURSE_LEVEL}
                        caseDateCreation={dateCreation}
                        sessionStartDate={this.props.caseInfos.caseInfos.sessionStartDate}
                        status={this.props.caseInfos.caseInfos.processStatus}
                        channel={this.props.caseInfos.caseInfos.channel}
                        liveStatus={liveStatus}
                        appointment={this.props.caseInfos.caseInfos.appointment}
                      />
                    }
                    <i className="caret-icon fa fa-sort-down" />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                  <DropdownItem className="dropdown-item" onClick={() => this.setState({ modal: true })}>
                    {I18n.t('processCaseHeader.options.ASSIGN')}
                  </DropdownItem>
                  <DropdownItem className="dropdown-item" onClick={() => this.takeInCharge()}>
                    {I18n.t('processCaseHeader.options.TAKE')}
                  </DropdownItem>
                </DropdownMenu>

              </UncontrolledDropdown>
              :
              <div>
              </div>

            }
          </ul>
        </div>}

      </div>
    );
  }
}

export default withRouter(connect(state => ({
  autoSave: state.process.autoSave,
  caseInfos: state.process.caseInfos,
  userIsAvailable: state.process.userIsAvailable,
}))(ProcessCaseHeader));
