// @flow

import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import NotificationsSystem from 'reapop';
import { notificationTheme } from '../../components/modal/modalNotification/theme';
import { connect } from 'react-redux';
import { notifyError } from '../../network/notification';
import { Field, Form } from 'react-final-form';
import { checkOtp, resetPassword, sendOtp } from '../../services/forgotPasswordService';

type Props = {
  isConnected: boolean,
  dispatch: Function,
  history: Object,
}

type States = {
  tokenValid: boolean,
  otpValid: boolean,
  resetDone: boolean,
  otp: string,
}

class ForgotPassword extends React.Component<Props, States> {
  state = {
    tokenValid: null,
    otpValid: false,
    resetDone: false,
    otp: null,
  };

  getToken = () => this.props.match.params.token;

  componentDidMount() {
    sendOtp(this.getToken())
      .then((data) => {
        if (data.isTwoFactorEnabled) {
          this.setState({ tokenValid: true });
        } else {
          this.setState({ tokenValid: true, otpValid: true });
        }
      })
      .catch(() => this.setState({ tokenValid: false }));
  }

  onOtpSubmit = (data) => {
    checkOtp(this.getToken(), data.otp)
      .then(() => this.setState({ otpValid: true, otp: data.otp }))
      .catch(this.props.dispatch(notifyError));
  }

  onResetSubmit = (data) => {
    resetPassword({
      otp: this.state.otp,
      resetPasswordToken: this.getToken(),
      ...data,
    })
      .then(() => this.setState({ resetDone: true }))
      .catch(this.props.dispatch(notifyError));
  }

  render() {
    const { tokenValid, otpValid, resetDone } = this.state;
    let content;
    if (tokenValid == null) {
      content = '';
    } else if (tokenValid) {
      if (!otpValid) {
        content = this.renderOtpForm();
      } else if (!resetDone) {
        content = this.renderResetForm();
      } else {
        content = this.renderResetDone();
      }
    } else {
      content = <Redirect to="/" />;
    }

    return (
      <div>
        <NotificationsSystem theme={notificationTheme} />
        <div className="forgot-password-container">
          {this.props.isConnected ? <Redirect to="/home" /> : content}
        </div>
      </div>
    );
  }

  renderOtpForm() {
    return (
      <>
        <h1><Translate value="forgot_password.sms.TITLE" /></h1>
        <div className="instructions"><Translate value="forgot_password.sms.INSTRUCTIONS" /></div>
        <Form
          onSubmit={this.onOtpSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="field-group">
                <Field
                  name="otp"
                  component="input"
                  placeholder={I18n.t('forgot_password.sms.SMS_CODE')}
                  type="text"
                  className="form-control"
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary"><Translate value="forgot_password.sms.SUBMIT" />
              </button>
            </form>
          )}
        />
      </>
    );
  }

  renderResetForm() {
    return (
      <>
        <h1><Translate value="forgot_password.reset.TITLE" /></h1>
        <div className="instructions"><Translate value="forgot_password.reset.INSTRUCTIONS" /></div>
        <Form
          onSubmit={this.onResetSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="field-group">
                <label>{I18n.t('forgot_password.reset.PASSWORD_LABEL')}</label>
                <Field
                  name="password"
                  component="input"
                  placeholder={I18n.t('forgot_password.reset.PASSWORD')}
                  type="password"
                  className="form-control"
                  required
                />
                <label>{I18n.t('forgot_password.reset.PASSWORD_CONFIRMATION_LABEL')}</label>
                <Field
                  name="passwordConfirmation"
                  component="input"
                  placeholder={I18n.t('forgot_password.reset.PASSWORD_CONFIRMATION')}
                  type="password"
                  className="form-control"
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary"><Translate value="forgot_password.reset.SUBMIT" />
              </button>
            </form>
          )}
        />
      </>
    );
  }

  renderResetDone() {
    return (
      <>
        <h1><Translate value="forgot_password.done.TITLE" /></h1>
        <div className="instructions"><Translate value="forgot_password.done.INSTRUCTIONS" /></div>
        <i className="fas fa-check-circle fa-5x" />
        <button
          onClick={() => this.props.history.push('/')}
          className="btn btn-primary"
        >
          <Translate value="forgot_password.done.SUBMIT" />
        </button>
      </>
    );
  }
}

export default withRouter(connect(state => ({
  isConnected: state.login.isConnected,
}))(ForgotPassword));

