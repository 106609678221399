// @flow

import React from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import ModalEprm from '../../../components/modal/ModalEprm';
import { CoreozDatePickerBase, CoreozInputBase as CoreozInput } from '../../../lib/coreoz-form-base/index';
import CalendarContainer from '../../appointment/components/CalendarContainer';
import { dateCompareValidator, hoursCompareValidator, validateSlotDuration } from '../../../validator';
import appointmentApi from '../../../network/api/appointmentApi';
import { notifyAppointmentsCreated, notifyDateError, notifyError } from '../../../network/notification';
import Loader from '../../../components/Loader';

type Props = {
  toggle: Function,
  visible: boolean,
  selection: string,
  existingEvents: Object,
  dispatch: Function,
  searchAvailabilityByUser: Function,
};
type State = {
  previewEvents: Object,
  steps: number,
};

class AddSlotModal extends React.Component<Props, State> {
  state = {
    previewEvents: [],
    steps: 1,
    slotValues: [],
    loading: false,
  };

  previewEventsValues = (events) => {
    this.setState({
      slotValues: {
        startDate: events.startDate,
        startHour: events.startHour,
        endDate: events.endDate,
        endHour: events.endHour,
        slotDuration: events.slotDuration,
      },
    });
    const start = moment((`${moment(events.startDate).format('YYYY-MM-DD') } ${ moment(events.startHour).format('HH:mm:ssZZ')}`));
    const end = moment((`${moment(events.endDate).format('YYYY-MM-DD') } ${ moment(events.endHour).format('HH:mm:ssZZ')}`));
    if (start.isBefore(end)) {
      appointmentApi.fetchPreviewAvailabilities({ start, end, slotDuration: events.slotDuration })
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : []))
        .then((response) => {
          this.setState({
            previewEvents: response.map(res =>
              ({
                ...res,
                start: new Date(res.start),
                end: new Date(res.end),
                new: true,
                slotDuration: events.slotDuration,
                idAvailabilityProfile: this.props.selection && this.props.selection.id,
              })),
            steps: 2,
          });
        })
        .catch(this.props.dispatch(notifyError));
    } else {
      this.props.dispatch(notifyDateError);
    }
  };

  closeModal = () => {
    this.setState({ steps: 1 });
    this.props.toggle(false);
  };

  createAvailabilities = () => {
    this.setState({ loading: true });
    appointmentApi.createAvailabilities(this.state.previewEvents)
      .then(notifyAppointmentsCreated(this.props.dispatch))
      .then((response) => {
        this.props.searchAvailabilityByUser();
        this.closeModal();
        return response;
      })
        .catch((e) => {
          this.setState({ loading: false });
          this.props.dispatch(notifyError)(e);
        });
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('availability.add.TITLE')}
        modal={this.props.visible}
        toggle={this.props.toggle}
        className=""
        size="lg"
      >
        <div className="add-slot-modal">
          <div className="user-selected-section">
            <span>{I18n.t('availability.add.content')}</span>
            <span className="user-selected">{this.props.selection ? this.props.selection.name : I18n.t('availability.add.no_user')}</span>
          </div>
          {this.state.steps === 1 &&
          <div>
            <Form
              onSubmit={this.previewEventsValues}
              render={({ handleSubmit, values, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="add-slot-form-container">
                    <Row>
                      <Field
                        type="text"
                        className="centered"
                        component={CoreozDatePickerBase}
                        name="startDate"
                        format={value => (value ? moment(value).format('DD/MM/YYYY') : '')}
                        label={I18n.t('availability.form.period')}
                        dateFormat={I18n.t('date.DATE_FORMAT')}
                        placeholder={I18n.t('form.placeholder')}
                        validate={(e, allValues) => dateCompareValidator(e, allValues.endDate)}
                        required
                      />
                      <Field
                        type="text"
                        className="centered"
                        format={value => (value ? moment(value).format('DD/MM/YYYY') : '')}
                        component={CoreozDatePickerBase}
                        name="endDate"
                        dateFormat={I18n.t('date.DATE_FORMAT')}
                        placeholder={I18n.t('form.placeholder')}
                        validate={(e, allValues) => dateCompareValidator(allValues.startDate, e)}
                        required
                      />
                    </Row>
                    <Row>
                      <Field
                        type="text"
                        className="centered"
                        component={CoreozDatePickerBase}
                        name="startHour"
                        label={I18n.t('availability.form.hours')}
                        dateFormat="timepicker"
                        placeholder={I18n.t('date.DATE_HOUR_SHORT')}
                        validate={(e, allValues) => hoursCompareValidator(e, allValues.endHour)}
                        onlyDate
                        locale="fr"
                        required
                      />
                      <Field
                        type="text"
                        className="centered"
                        component={CoreozDatePickerBase}
                        name="endHour"
                        dateFormat="timepicker"
                        placeholder={I18n.t('date.DATE_HOUR_SHORT')}
                        validate={(e, allValues) => hoursCompareValidator(allValues.startHour, e)}
                        onlyDate
                        locale="fr"
                        required
                      />
                    </Row>
                    <Row className="add-slot-duration">
                      <Field
                        type="number"
                        className="centered"
                        component={CoreozInput}
                        name="slotDuration"
                        label={I18n.t('availability.form.slotDuration')}
                        required
                        validate={validateSlotDuration}
                      />
                    </Row>
                    <div className="centered actions-button">
                      <button
                        className="margin-auto btn btn-primary "
                        type="submit"
                        disabled={invalid || !values.endDate || !values.startDate || !values.startHour || !values.endHour || !values.slotDuration}
                      >
                        <i className="button-icon fa fa-eye" />
                        {I18n.t('actions.PREVIEW')}
                      </button>
                      <button
                        className="margin-auto btn btn-primary "
                        onClick={this.closeModal}
                      >
                        <i className="button-icon fa fa-undo" />
                        {I18n.t('actions.CANCEL')}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
          }
          {this.state.steps === 2 &&
          <div>
            <div className="informations-add-slots">
              <Col>
                <Row className="informations-add-slots-title">{I18n.t('availability.summarize.title')}</Row>
                <Row>
                  <span className="informations-add-slots-title"> {I18n.t(
'availability.summarize.from',
                    {
                      startDate: this.state.slotValues.startDate.format(I18n.t('date.DATE_FORMAT')),
                      endDate: this.state.slotValues.endDate.format(I18n.t('date.DATE_FORMAT')),
                    },
)}
                  </span>
                </Row>
                <Row>
                  <span className="informations-add-slots-title">
                    {I18n.t(
'availability.summarize.from',
                      {
                        startDate: this.state.slotValues.startHour.format(I18n.t('date.DATE_HOUR_SHORT')),
                        endDate: this.state.slotValues.endHour.format(I18n.t('date.DATE_HOUR_SHORT')),
                      },
)}
                  </span>
                </Row>
                <Row>
                  <span
                    className="informations-add-slots-title"
                  >{I18n.t('availability.summarize.during', { duration: this.state.slotValues.slotDuration })}
                  </span>
                </Row>
              </Col>
              <Col className="legend-informations">
                <Row className="legend"><span className="new-slot" /><span className="label">{I18n.t('appointment.new')}</span></Row>
                <Row className="legend"><span className="empty-slot" /><span className="label">{I18n.t('appointment.empty')}</span></Row>
                <Row className="legend"><span className="booked-slot" /><span className="label">{I18n.t('appointment.booked')}</span></Row>
              </Col>
            </div>
            <CalendarContainer events={this.props.existingEvents.concat(this.state.previewEvents)} clickableEvent={false} defaultDate={this.state.slotValues.startDate == null ? null : this.state.slotValues.startDate.toDate()} />
            <div className="centered">
              <button
                className="margin-auto btn btn-primary "
                onClick={this.createAvailabilities}
                disabled={this.state.loading}
              >
                {
                  this.state.loading ?
                    <Loader small />
                    :
                    <div>
                      <i className="button-icon fa fa-plus" />
                      <span>{I18n.t('actions.ADD')}</span>
                    </div>
                }
              </button>
              <button
                className="margin-auto btn btn-primary "
                onClick={this.closeModal}
              >
                <i className="button-icon fa fa-undo" />
                {I18n.t('actions.CANCEL')}
              </button>
            </div>
          </div>
          }
        </div>
      </ModalEprm>
    );
  }
}

export default withRouter(connect()(AddSlotModal));
