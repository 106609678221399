import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Loader from '../../components/Loader';
import BPartnerContext from '../../stores/b-partner-management-context';
import {
	CoreozInputBase,
	CoreozSelectBase,
} from '../../lib/coreoz-form-base/index';
import { BpartnerStatus } from '../../enum';
import userApi from '../../network/api/userApi';
import bPartnerApi from '../../network/api/bPartnerApi';
import {
	SearchCriteriaType,
	SearchResultType,
	BPartnerDetail,
} from '../../types/B-PartnerManagement';
import {
	B_PARTNER_MANAGEMENT_PAGE_ROUTE,
	B_PARTNER_EDIT_PAGE_ROUTE,
} from '../../routes';
import { required } from '../../validator';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import ResizableTextarea from '../../components/ResizableTextarea';
import { notifyError, notifySuccess } from '../../network/notification';
import hasPermission from '../../services/permissionService';
import { currentSessionService } from '../../services/sessionServiceInstance';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		color: '#fff',
		zIndex: theme.zIndex.drawer + 1,
	},
}));

const BPartnerRequest = (props) => {
	const classes = useStyles();
	const isEdit = !!props.match.params.id || false;
	const bPartnerId = props.match.params.id || null;
	const { searchCriteriaContext, updateSearchCriteriaContext } =
		useContext(BPartnerContext);
	const [businessUnits, setBusinessUnits] = useState([]);

	const [button, setButton] = useState('');
	const [bPartnerDetail, setBpartnerDetail] = useState({});
	const [loading, setLoading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	useEffect(() => {
		fetchBusinessUnits();
		if (bPartnerId) {
			fetchBPartnerDetail(bPartnerId);
		}
	}, []);

	async function fetchBusinessUnits(): void {
		const businessUnitsResponse = await userApi
			.fetchBusinessUnits()
			.then((response) => response.json());

		const buList = businessUnitsResponse.filter(
			(v) =>
				hasPermission('EDITABLE_BUSINESS_UNIT_B_PARTNER') ||
				v.id === currentSessionService().currentUser().businessUnitId
		);

		setBusinessUnits(buList || []);
	}

	async function fetchBPartnerDetail(BPartnerId: Number): void {
		setLoading(true);

		const bPartnerDetailResponse: BPartnerDetail = await props.dispatch(
			bPartnerApi.fetchBpartnerById(BPartnerId)
		);

		setBpartnerDetail(bPartnerDetailResponse);

		setLoading(false);
	}

	const onPreviousHandler = () => {
		props.history.push(B_PARTNER_MANAGEMENT_PAGE_ROUTE);
	};

	const handleChange = (e) => {};

	const getHeaderTitle = (): String =>
		isEdit ? I18n.t('bPartner.TITLE') : I18n.t('bPartner.TITLE_CREATE');

	const isStatus = (status): string => (status ? 'true' : 'false');

	const onSave = (dataForm) => {
		setLoading(true);

		let rawData = {
			...dataForm,
			bpartnerCode: +dataForm.bpartnerCode,
		};

		if (isEdit) {
			rawData = {
				...rawData,
				isActive: isTrue(dataForm.isActive),
			};
			bPartnerApi
				.update(rawData, bPartnerId)
				.then(props.dispatch(notifySuccess))
				.catch(props.dispatch(notifyError))
				.finally(() => setLoading(false));
		} else {
			rawData = {
				...rawData,
				isActive: true,
			};

			bPartnerApi
				.save(rawData)
				.then((response) => response.text())
				.then(props.dispatch(notifySuccess))
				.then((bPartnerId) => {
					props.history.push(
						B_PARTNER_EDIT_PAGE_ROUTE.replace(':id', bPartnerId),
						props.location.state
					);
				})
				.catch(props.dispatch(notifyError))
				.finally(() => setLoading(false));
		}
	};

	const isTrue = (value) => {
		if (typeof value === 'string') {
			value = value.trim().toLowerCase();
		}
		switch (value) {
			case true:
			case 'true':
				return true;
			default:
				return false;
		}
	};

	const formatForFront = (bPartnerDetail: BPartnerDetail) => {
		const {
			bpartnerCode,
			bpartnerName,
			businessUnitId,
			commercialName,
			doctorsNotes,
			isActive,
		} = bPartnerDetail ?? {};

		return {
			bpartnerCode: bpartnerCode ?? '',
			bpartnerName: bpartnerName ?? '',
			businessUnitId: businessUnitId ?? '',
			commercialName: commercialName ?? '',
			doctorsNotes: doctorsNotes ?? '',
			isActive,
		};
	};

	return (
		<div className="b-partner-details user-details" id="bPartnerCreate">
			<div className="b-partner-details-header">
				<div className="back-to-list" onClick={onPreviousHandler}>
					<i className="fa fa-arrow-left" />
					{I18n.t('userDetails.BACK_TO_LIST')}
				</div>
				<div className="title">
					{getHeaderTitle()}
					{isEdit && bPartnerDetail && (
						<div className="b-partner_info">
							{bPartnerDetail.bpartnerName}
						</div>
					)}
				</div>
			</div>

			<Form
				onSubmit={onSave}
				initialValues={formatForFront(bPartnerDetail)}
				render={({ handleSubmit, form, invalid, values }) => (
					<form onSubmit={handleSubmit}>
						<div className="user-action">
							<Button
								color="secondary"
								type="button"
								onClick={onPreviousHandler}
							>
								<i className="button-icon fa fa-undo" />
								{I18n.t('actions.CANCEL')}
							</Button>
							<Button
								color="primary"
								type="submit"
								disabled={invalid}
							>
								<i className="button-icon fa fa-save" />
								{I18n.t('actions.SAVE')}
							</Button>
						</div>

						<div className="content">
							<div className="user-category">
								<div className="content-container">
									<Field
										autoComplete="off"
										type="text"
										component={CoreozInputBase}
										name="bpartnerCode"
										label={I18n.t(
											'bPartner.label.B_PARTNER_CODE'
										)}
										validate={required}
										maxlength="9"
										format={(stringValue) =>
											stringValue &&
											stringValue
												.toString()
												.replace(/[^\d]/g, '')
										}
										required
									/>
									<Field
										autoComplete="off"
										type="text"
										component={CoreozInputBase}
										name="bpartnerName"
										label={I18n.t(
											'bPartner.label.B_PARTNER_NAME'
										)}
										validate={required}
										maxlength="50"
										required
									/>

									<Field
										name="businessUnitId"
										id="businessUnitId"
										autoComplete="off"
										type="text"
										component={CoreozSelectBase}
										label={I18n.t(
											'bPartner.label.BUSINESS_UNIT'
										)}
										list={
											businessUnits &&
											businessUnits.map((bu) => ({
												id: bu.id,
												label: bu.name,
											}))
										}
										validate={required}
									/>

									{isEdit && (
										<div className="radio-button-container">
											<div className="radio-button-label">
												{I18n.t(
													'bPartner.label.STATUS'
												)}
											</div>
											<div className="choices">
												<Field
													component="input"
													name="isActive"
													type="radio"
													value="true"
													format={(value) =>
														String(value)
													}
													parse={(value) =>
														value === 'true'
													}
												/>
												<span>
													{I18n.t(
														'bPartner.STATUS.ACTIVE'
													)}
												</span>
												<Field
													component="input"
													name="isActive"
													type="radio"
													value="false"
													format={(value) =>
														String(value)
													}
													parse={(value) =>
														value === 'true'
													}
												/>
												<span>
													{I18n.t(
														'bPartner.STATUS.INACTIVE'
													)}
												</span>
											</div>
										</div>
									)}

									<Field
										autoComplete="off"
										type="text"
										component={CoreozInputBase}
										name="commercialName"
										maxlength="50"
										label={I18n.t(
											'bPartner.label.COMMERCIAL_NAME'
										)}
									/>

									<Field
										label={I18n.t(
											'bPartner.label.DOCTORS_NOTES'
										)}
										component={ResizableTextarea}
										name="doctorsNotes"
										maxlength="255"
										minrows={5}
									/>
								</div>
							</div>
						</div>

						<div className="user-action mt-5">
							<Button
								color="secondary"
								type="button"
								onClick={onPreviousHandler}
							>
								<i className="button-icon fa fa-undo" />
								{I18n.t('actions.CANCEL')}
							</Button>
							<Button
								color="primary"
								type="submit"
								disabled={invalid}
							>
								<i className="button-icon fa fa-save" />
								{I18n.t('actions.SAVE')}
							</Button>
						</div>
					</form>
				)}
			/>
			<Backdrop className={classes.backdrop} open={loading}>
				<div className="center-child search-loader">
					<Loader />
				</div>
			</Backdrop>
		</div>
	);
};

export default connect()(BPartnerRequest);
