// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';

import MenuSections from './MenuSections';
import CoreozDrawer from '../CoreozDrawer';
import { connect } from 'react-redux';

type Props = {
  dashboardMenu: Boolean,
};

class AsideNav extends React.Component<Props, State> {
  render() {
    return (
      <div className="menu-left">
        <div className="menu-view">
          <MenuSections />
        </div>
        <div className="responsive-menu-view">
          <CoreozDrawer show={this.props.dashboardMenu} side>
            <MenuSections />
          </CoreozDrawer>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  dashboardMenu: state.dashboard.dashboardMenu,
}))(AsideNav));
