// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import CodeMirror from 'react-codemirror';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/htmlembedded/htmlembedded';
import type { Header } from '../../types/LogApiTypes';
import logApi from '../../network/api/LogApi';
import Button from 'reactstrap/es/Button';

type Props = {
  isRequest: boolean,
  infos: {
    headerList: Header,
    body: string,
    isCompleteText: boolean,
    logId: string,
    method: string,
  },
}

const textDownload = (filename: string, text: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

class LogApiTileDetails extends React.Component<Props, void> {
  getFullResponse = () => {
    logApi
      .fetchFullBody(this.props.infos.id, this.props.isRequest)
      .then(res => res.text())
      .then(text => textDownload(`${this.props.infos.api}.txt`, text))
      .catch(console.log);
  }

  render() {
    return (
      <div className="log-container-details">
        <div
          className={`title title--${this.props.infos.method}`}
        >{this.props.isRequest ? I18n.t('apiLog.request') : I18n.t('apiLog.response')}
        </div>
        <div className="subtitle">
          <div className="api-header-title"> {I18n.t('apiLog.header')}</div>
          {this.props.infos.headerList.header.length > 0 && this.props.infos.headerList.header
            .map(headerInfo => (
              <div className="api-header" key={headerInfo.id}>
                <div className="header-key">{headerInfo.name}</div>
                <div> {headerInfo.value}</div>
              </div>
            ))
          }
          {this.props.infos.headerList.header.length <= 0 &&
          <div className="empty-body"> {I18n.t('apiLog.emptyHeader')} </div>
          }
        </div>
        <div className="subtitle">
          <div className="api-header-title"> {I18n.t('apiLog.body')}</div>
          {!this.props.infos.body &&
          <div className="empty-body"> {I18n.t('apiLog.emptyBody')} </div>
          }

          {
            this.props.infos.body &&
            <Button secondary="primary" onClick={this.getFullResponse}
                    className="download"> {I18n.t('apiLog.download')} </Button>
          }

          {this.props.infos.body && !this.props.infos.headerList.mimeType &&
          <div className="bloc-body"> {this.props.infos.body}  </div>
          }
          {this.props.infos.body && this.props.infos.headerList.mimeType &&
          <div className="bloc-body">
            <CodeMirror
              value={this.props.infos.body}
              options={{
                readOnly: true,
                mode: this.props.infos.headerList.mimeType,
              }}
            />
          </div>
          }
        </div>
      </div>

    );
  }
}

export default LogApiTileDetails;
