import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase as CoreozInput, CoreozSelectBase as CoreozSelect } from '../../lib/coreoz-form-base/index';
import ModalEprm from '../../components/modal/ModalEprm';
import { currentSessionService } from '../../services/sessionServiceInstance';
import { AdviceCodingStatus } from '../../enum';

type Props = {
  openModal: boolean,
  title: string,
  buttonValue: string,
  closeModal: Function,
  valuesInitial: {},
  saveCoding: Function,
};

class ModalAddCoding extends React.Component<Props, State> {
  onSubmit = (values) => {
    const data = {
      ...values,
      isActive: this.isTrue(values.isActive),
      id: this.props.valuesInitial && this.props.valuesInitial.id,
    };

    this.props.saveCoding(data);
  }

  initialForm = (values) => {
    if (values) {
      return {
        name: values.name,
        isActive: values.isActive && values.isActive.toString(),
      };
    }
    return {
      name: null,
      isActive: null,
    };
  }

  isTrue = (value) => {
    if (typeof (value) === 'string') {
      value = value.trim().toLowerCase();
    }
    switch (value) {
      case true:
      case 'true':
        return true;
      default:
        return false;
    }
  };

  render() {
    return (
      <ModalEprm
        title={this.props.title}
        modal={this.props.openModal}
        toggle={this.props.closeModal}
      >
        <Form
          onSubmit={this.onSubmit}
          initialValues={this.initialForm(this.props.valuesInitial)}
          render={({ handleSubmit, values, invalid }) => (
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <div className="add-coding-body">
                <div className="add-coding-form">
                  <div className="form-column">
                    <Field
                      name="name"
                      component={CoreozInput}
                      label={I18n.t('pathologyCodingSearch.NAME')}
                      type="text"
                      required
                    />
                    {
                      currentSessionService().currentUser().userParentingForCodings &&
                      <Field
                        name="parent"
                        component={CoreozInput}
                        label={I18n.t('pathologyCodingSearch.PARENT')}
                        type="text"
                      />
                    }
                    <Field
                      name="isActive"
                      component={CoreozSelect}
                      label={I18n.t('pathologyCodingSearch.STATUS')}
                      placeholder={I18n.t('pathologyCodingSearch.STATUS')}
                      list={Object.keys(AdviceCodingStatus).map(criteria => ({
                        id: AdviceCodingStatus[criteria],
                        label: I18n.t(`pathologyCodingSearch.${criteria}`),
                      }))}
                    />
                  </div>
                </div>
                <div className="add-coding-action">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={this.props.closeModal}
                  >
                    <i className="button-icon fa fa-undo" />
                    {I18n.t('button.CANCEL')}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={invalid || values.isActive === null || !values.name}
                  >
                    <i className="button-icon fas fa-paper-plane" />
                    {this.props.buttonValue}
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </ModalEprm>
    );
  }
}

export default connect()(ModalAddCoding);
