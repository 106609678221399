import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import protocolContext from '../../stores/b-partner-management-context';
import {
  CoreozInputBase as CoreozInput,
  CoreozSelectBase as CoreozSelect,
} from '../../lib/coreoz-form-base/index';
import {
  BUSINESS_UNIT_DETAILS_PAGE_ROUTE,
  BUSINESS_UNIT_PAGE_ROUTE,
} from '../../routes';
import { required } from '../../validator';
import RadioButton from '../../components/businessUnit/RadioButton';
import languageApi from '../../network/api/languageApi';
import businessUnitApi from '../../network/api/businessUnitApi';
import { notifyError, notifySuccess } from '../../network/notification';
import referentialApi from '../../network/api/referentialApi';

const useStyles = makeStyles(theme => ({
  backdrop: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const BusinessUnitRequest = (props) => {
  const classes = useStyles();
  const isEdit = !!props.match.params.id || false;
  const businessUnitId = props.match.params.id || null;
  const { searchCriteriaContext, updateSearchCriteriaContext } = useContext(protocolContext);


  const [button, setButton] = useState('');
  const [businessUnitDetail, setBusinessUnitDetail] = useState(null);
  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    fetchCountries();
    fetchTimezone();
    if (businessUnitId) {
      fetchBusinessUnitDetail(businessUnitId);
    }
  }, []);

  async function fetchBusinessUnitDetail(id) {
    setLoading(true);

    const protocolDetailResponse = await props.dispatch(businessUnitApi.fetchById(id));

    setBusinessUnitDetail(protocolDetailResponse);

    setLoading(false);
  }

  const onPreviousHandler = () => {
    props.history.push(BUSINESS_UNIT_PAGE_ROUTE);
  };

  const handleChange = (e) => {

  };

  const onSave = (dataForm) => {
    const rawData = {
      ...dataForm,
    };
    if (isEdit) {
      businessUnitApi
        .update(businessUnitId, rawData)
        .then(props.dispatch(notifySuccess))
        .catch(props.dispatch(notifyError));
    } else {
      businessUnitApi
        .save(rawData)
        .then(response => response.text())
        .then(props.dispatch(notifySuccess))
        .then((id) => {
          setTimeout(() => {
            props.history.push(BUSINESS_UNIT_DETAILS_PAGE_ROUTE.replace(':id', id), props.location.state);
          }, 3000);
        })
        .catch(props.dispatch(notifyError));
    }
  };

  function fetchCountries() {
    referentialApi
      .fetchCountriesBySelectedLanguage(1)
      .then(response => response.json())
      .then(response => setCountries(response))
      .catch(props.dispatch(notifyError));
  }

  function fetchTimezone() {
    referentialApi
      .getAllTimezone()
      .then(response => response.json())
      .then(response => setTimezones(response))
      .catch(props.dispatch(notifyError));
  }


  const formatForFront = () => {
    if (businessUnitDetail) {
      return {
        ...businessUnitDetail,
        businessUnitName: businessUnitDetail.name
      };
    }

    return {
      businessUnitName: '',
      timezone: '',
      countryCode: '',
      countryName: '',
      showNewCaseButtonInDashboard: true,
      showViewFilterInDashboard: true,
      showIdentification: true,
      showQualification: true,
      showLogoInDocument: true,
      showTeleAdviceReport: true,
      canSendByFax: true,
      showChat: true,
      doctorShouldCallback: true,
      showAddressInDocument: true,
      showHospitals: true,
      reportAddressedToPatient: true,
      showNonMedicationNotesFirst: true,
      showEmailServiceMedicalReport: true,
      sendNotificationWhenCaseCreated: true,
      recordingsSentToAida: true,
      useParentingForCodings: true,
      showAdviceCodingPage: false,
      canAccessCmCic: false,
      canCreatePatientInIdentificationPage: false,
      useOneProtocolAndPolicyForTreatingCases: false,
      showOneConsentInConsentPage: false,
      makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories: false,
      canResendMailVideo: false,
      showAlertWhenNoMedicalReport: false,
      emailFooterTakeInLegalEntityObject: false,
      notifyOfflineUserWhenCaseCreated: false,
      checkEligibilityByVisa: false,
    };
  };

  return (
    <div className="user-details">
      <div className="user-details-header">
        <div
          className="back-to-list"
          onClick={onPreviousHandler}
        >
          <i className="fa fa-arrow-left" />
          {I18n.t('userDetails.BACK_TO_LIST')}
        </div>
        <div className="title">
          {isEdit ? I18n.t('businessUnit.TITLE') : I18n.t('businessUnit.TITLE_CREATE')}
          {
              isEdit && businessUnitDetail &&
              <div className="detail-info">{businessUnitDetail.name}</div>
          }
        </div>
      </div>

      <Form
        onSubmit={onSave}
        initialValues={formatForFront}
        render={({ handleSubmit, values, form , invalid }) => (
  <form onSubmit={handleSubmit}>
    <div className="user-action">
      <Button
        color="secondary"
        type="button"
        onClick={onPreviousHandler}
      >
        <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
      </Button>
      <Button
        color="primary"
        type="submit"
        disabled={invalid}
      >
        <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
      </Button>

    </div>
    <div className="content">
      <div className="user-category p-5">
        <div className="title mb-3">{I18n.t('businessUnit.TITLE_ADMIN')}</div>
        <Field
          component={CoreozInput}
          name="businessUnitName"
          label={I18n.t('businessUnit.label.businessUnitName')}
          validate={required}
          required
          maxLength="254"
        />

        <Field
          component={CoreozSelect}
          name="countryName"
          label={I18n.t('businessUnit.label.countryName')}
          validate={required}
          list={countries.map(v => ({
            id: v.label,
            label: v.label,
          }))}
          onChange={(newValue) => {
                  const countrie = countries.find(v => v.label == newValue);
                    form.change('countryCode', countrie.isoCode.toLowerCase());
                    form.change('countryId', countrie.id);
                  }}
        />
        <Field
          component={CoreozInput}
          name="countryCode"
          label={I18n.t('businessUnit.label.countryCode')}
          disabled
        />
        <Field
          component={CoreozSelect}
          name="timezone"
          label={I18n.t('businessUnit.label.timezone')}
          validate={required}
          list={timezones.map(v => ({
            id: v.value,
            label: v.label,
          }))}
        />
      </div>

      <div className="user-category p-5">
        <div className="title mb-3">{I18n.t('businessUnit.TITLE_CONFIGURATION')}</div>
        <Row>
          <Col>
            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.canSendByFax')}</span>
              <RadioButton name="canSendByFax" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showChat')}</span>
              <RadioButton name="showChat" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showIdentification')}</span>
              <RadioButton name="showIdentification" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showLogoInDocument')}</span>
              <RadioButton name="showLogoInDocument" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showNewCaseButtonInDashboard')}</span>
              <RadioButton name="showNewCaseButtonInDashboard" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showQualification')}</span>
              <RadioButton name="showQualification" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showTeleAdviceReport')}</span>
              <RadioButton name="showTeleAdviceReport" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showViewFilterInDashboard')}</span>
              <RadioButton name="showViewFilterInDashboard" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.canAccessCmCic')}</span>
              <RadioButton name="canAccessCmCic" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.canCreatePatientInIdentificationPage')}</span>
              <RadioButton name="canCreatePatientInIdentificationPage" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.canResendMailVideo')}</span>
              <RadioButton name="canResendMailVideo" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.emailFooterTakeInLegalEntityObject')}</span>
              <RadioButton name="emailFooterTakeInLegalEntityObject" />
            </div>

          </Col>
          <Col>
            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.doctorShouldCallback')}</span>
              <RadioButton name="doctorShouldCallback" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.recordingsSentToAida')}</span>
              <RadioButton name="recordingsSentToAida" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.reportAddressedToPatient')}</span>
              <RadioButton name="reportAddressedToPatient" />
            </div>
            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.sendNotificationWhenCaseCreated')}</span>
              <RadioButton name="sendNotificationWhenCaseCreated" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.sendNotificationPatientWaitingInitial')}</span>
              <RadioButton name="sendNotificationPatientWaitingInitial" />
            </div>
            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.sendNotificationPatientWaitingGlobal')}</span>
              <RadioButton name="sendNotificationPatientWaitingGlobal" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showAddressInDocument')}</span>
              <RadioButton name="showAddressInDocument" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showEmailServiceMedicalReport')}</span>
              <RadioButton name="showEmailServiceMedicalReport" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showHospitals')}</span>
              <RadioButton name="showHospitals" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showNonMedicationNotesFirst')}</span>
              <RadioButton name="showNonMedicationNotesFirst" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.useParentingForCodings')}</span>
              <RadioButton name="useParentingForCodings" />
            </div>

            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.useOneProtocolAndPolicyForTreatingCases')}</span>
              <RadioButton name="useOneProtocolAndPolicyForTreatingCases" />
            </div>
            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showOneConsentInConsentPage')}</span>
              <RadioButton name="showOneConsentInConsentPage" />
            </div>
            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories')}</span>
              <RadioButton name="makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories" />
            </div>
            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.showAlertWhenNoMedicalReport')}</span>
              <RadioButton name="showAlertWhenNoMedicalReport" />
            </div>
            <div className="boolean-field">
              <span>{I18n.t('businessUnit.label.checkEligibilityByVisa')}</span>
              <RadioButton name="checkEligibilityByVisa" />
            </div>
          </Col>

        </Row>
      </div>

    </div>


    <div className="user-action">
      <Button
        color="secondary"
        type="button"
        onClick={onPreviousHandler}
      >
        <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
      </Button>
      <Button
        color="primary"
        type="submit"
        disabled={invalid}
      >
        <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
      </Button>

    </div>

  </form>
            )}
      />

    </div>
  );
};

export default connect()(BusinessUnitRequest);
