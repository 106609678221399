// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import type { PlaceType } from '../../../../types/GoogleTypes';

type Props = {
    hospital: PlaceType,
    selectHospital: Function,
    multiSelect: Function
}

type States = {
}

function HospitalRow(props: Props) {
  const { hospital, selectHospital, multiSelect } = props;

  return (
    <div className="hospital-row" key={hospital.id}>
      <div className="row center">
        <input type="checkbox" className="checkbox" onChange={() => multiSelect(hospital)}/>
        <div className="label" onClick={() => selectHospital(hospital)}>{hospital.name}</div>
        <i className="fa fa-arrow-right" />
      </div>
      <div className="separator" />
    </div>
  );
}

export default withRouter(HospitalRow);
