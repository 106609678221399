// @flow

import React, { Fragment } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { notifyArchived, notifyError } from '../../network/notification';
import {
  CASE_PROCESS_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  QUALIFICATION_PAGE_ROUTE,
  TELECONSULTATION_PAGE_ROUTE,
} from '../../routes';
import caseApi from '../../network/api/caseApi';
import Loader from '../../components/Loader';
import { store } from '../../network/reduce';
import { CASE_INFOS } from '../../state/process/processReducer';
import { createSearchFromParams } from '../../services/queryParamsService';
import { currentSessionService } from '../../services/sessionServiceInstance';
import { RecordingReason } from '../../enum';
import confApi from '../../network/api/confApi';

type Props = {
  caseInfos: Object,
  history: Function,
  dispatch: Function,
  queryParams: Object,
};

type State = {
  loading: boolean,
  caseLoaded: Object,
  consentLink: string,
};

class ConsentsCollect extends React.Component<Props, State> {
  state = {
    loading: false,
    caseLoaded: null,
    consentLink: '',
  };

  componentDidMount = () => {
    confApi.fetchConsentLink()
      .then(res => res.text())
      .then(consentLink => this.setState({ consentLink }))
      .catch(this.props.dispatch(notifyError));
    if (this.props.queryParams.caseId) {
      caseApi
        .fetchDetailById(this.props.queryParams.caseId)
        .then(response => response.json())
        .then(caseLoaded => this.setState({ caseLoaded }))
        .catch(this.props.dispatch(notifyError));
    }
  };

  redirectToNextPage = (caseId) => {
    if (currentSessionService().currentUser().showQualification) {
      caseApi.setStep(caseId, 4);
      this.props.history.push(CASE_PROCESS_PAGE_ROUTE + QUALIFICATION_PAGE_ROUTE
        + createSearchFromParams({
          patientId: this.props.queryParams.patientId,
          caseId,
        }));
    } else {
      caseApi.setStep(caseId, 5);
      this.props.history.push(CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE
        + createSearchFromParams({
          patientId: this.props.queryParams.patientId,
          caseId,
        }));
    }
  }

  onSubmit = (data) => {
    if (data.treating && data.sharing) {
      this.setState({ loading: true });

      // dossier CM/CIC deja créé à ce stade, on doit mettre a jour les consents
      if (this.props.queryParams.redirectToConsent && this.props.queryParams.redirectToConsent === 'true') {
        caseApi.updateConsents(this.props.queryParams.caseId)
          .then(() => {
            this.redirectToNextPage(this.props.queryParams.caseId);
          });
        }
      // gestion classique
      else {
        caseApi.createCase({
          patientId: this.props.queryParams.patientId,
          patientMobileNumber: this.props.caseInfos.patient.mobilePhone,
          patientEmail: this.props.caseInfos.patient.email,
          protocolNumber: this.props.queryParams.protocolNumber,
          policyNumber: this.props.queryParams.policyNumber,
          isSharingConsent: true,
          isTreatmentConsent: true,
        })
          .then(response => response.text())
          .then((caseId) => {
            this.setState({ loading: false });
            this.props.dispatch(store(CASE_INFOS, null));
            this.redirectToNextPage(caseId);
          })
          .catch(this.props.dispatch(notifyError));
      }
    } else {
      this.setState({ loading: true });

      let protocolNumber = '';
      let policyNumber = '';
      if (this.props.queryParams.protocolNumber) {
        protocolNumber = this.props.queryParams.protocolNumber;
      } else {
        protocolNumber = this.state.caseLoaded && this.state.caseLoaded.protocolNumber;
      }

      if (this.props.queryParams.policyNumber) {
        policyNumber = this.props.queryParams.policyNumber;
      } else {
        policyNumber = this.state.caseLoaded && this.state.caseLoaded.policyNumber;
      }

      // dossier CM/CIC deja créé à ce stade
      if (this.props.queryParams.redirectToConsent && this.props.queryParams.redirectToConsent === 'true') {
        caseApi.createAndArchive({
          id: this.props.queryParams.caseId ? this.props.queryParams.caseId : null,
          patientId: this.props.queryParams.patientId,
          protocolNumber,
          policyNumber,
          isSharingConsent: !!data.treating,
          isTreatmentConsent: !!data.sharing,
        }).then(() => {
          this.setState({ loading: false });
          this.props.dispatch(store(CASE_INFOS, null));
          this.props.history.push(DASHBOARD_PAGE_ROUTE);
        });
      } else {
        caseApi
          .createAndArchive({
            id: this.props.queryParams.caseId ? this.props.queryParams.caseId : null,
            patientId: this.props.queryParams.patientId,
            protocolNumber,
            policyNumber,
            isSharingConsent: !!data.treating,
            isTreatmentConsent: !!data.sharing,
          })
          .then(response => response.text())
          .then((caseId) => {
            this.setState({ loading: false });
            this.props.dispatch(store(CASE_INFOS, null));
            this.props.history.push(DASHBOARD_PAGE_ROUTE);
          })
          .then(this.props.dispatch(notifyArchived))
          .catch((e) => {
            this.setState({ loading: false });
            this.props.dispatch(notifyError)(e);
          });
      }
    }
  };

  render() {
    return (
      <div className="consents-collect">
        <header>
          <span><i className=" far fa-thumbs-up" />{I18n.t('consent.title')}</span>
        </header>
        {((this.props.queryParams.caseId && this.state.caseLoaded != null)
          || !this.props.queryParams.caseId) &&
          <Form
            onSubmit={this.onSubmit}
            initialValues={{
            treating: this.props.queryParams.caseId && this.props.queryParams.redirectToConsent !== 'true' ? this.state.caseLoaded.isTreatmentConsent : '',
            sharing: currentSessionService().currentUser().showOneConsentInConsentPage || (this.props.queryParams.caseId && this.props.queryParams.redirectToConsent !== 'true' ? this.state.caseLoaded.isSharingConsents : ''),
            consentLink: this.state.consentLink,
          }}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                {!currentSessionService().currentUser().showOneConsentInConsentPage &&
                <div className="consents-collect-content">
                  <span className="consents-intro">{I18n.t('consent.intro')}</span>
                  <div className="consents-container">
                    <span className="consents-title">{I18n.t('consent.titleSharing')}</span>
                    <span className="consents-text">{I18n.t('consent.textTreating')}</span>
                    <div className="radios-button-line">
                      <label>
                        <Field
                          className="radio-input"
                          component="input"
                          name="treating"
                          type="radio"
                          value="true"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                          disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                        />
                        <span className="radio-label">{I18n.t('consent.give')}</span>
                      </label>

                      <label>
                        <Field
                          className="radio-input"
                          component="input"
                          name="treating"
                          type="radio"
                          value="false"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                          disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                        />
                        <span className="radio-label">{I18n.t('consent.giveNot')}</span>
                      </label>
                    </div>
                  </div>
                  <div className="consents-container">
                    <span className="consents-title">{I18n.t('consent.titleTreating')}</span>
                    <span className="consents-text">{I18n.t('consent.textSharing')}</span>
                    <div className="radios-button-line">
                      <label>
                        <Field
                          className="radio-input"
                          component="input"
                          name="sharing"
                          type="radio"
                          value="true"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                          disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                        />
                        <span className="radio-label">{I18n.t('consent.give')}</span>
                      </label>
                      <label>
                        <Field
                          className="radio-input"
                          component="input"
                          name="sharing"
                          type="radio"
                          value="false"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                          disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                        />
                        <span className="radio-label">{I18n.t('consent.giveNot')}</span>
                      </label>
                    </div>
                  </div>
                </div>}
                {currentSessionService().currentUser().showOneConsentInConsentPage &&
                <div className="consents-collect-content">
                  <span className="consents-intro">{I18n.t('consent.intro')}</span>
                  <div className="consents-container">
                    <span className="consents-title">{I18n.t('consent.titleTreatingAndSharing')}</span>
                    <div>
                      <span className="consents-text">{I18n.t('consent.textTreatingAndSharing_v1')}</span>
                      <a
                        href={`${this.state.consentLink}`}
                        target="_blank"
                        className="text-treating-and-sharing"
                      >{I18n.t('consent.textTreatingAndSharing')}
                      </a>
                      <span className="consents-text">{I18n.t('consent.textTreatingAndSharing_v2')}</span>
                    </div>
                    <div className="radios-button-line">
                      <label>
                        <Field
                          className="radio-input"
                          component="input"
                          name="treating"
                          type="radio"
                          value="true"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                          disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                        />
                        <span className="radio-label">{I18n.t('consent.give')}</span>
                      </label>
                      <label>
                        <Field
                          className="radio-input"
                          component="input"
                          name="treating"
                          type="radio"
                          value="false"
                          format={value => String(value)}
                          parse={value => value === 'true'}
                          disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                        />
                        <span className="radio-label">{I18n.t('consent.giveNot')}</span>
                      </label>
                    </div>
                  </div>

                </div>
              }

                <div className="consent-footer">
                  {((values.treating === false) || (values.sharing === false)) &&
                  <div className="consents-unvalid">
                    <span className="unvalid-title">{I18n.t('consent.consentUnvalid')}</span>
                    <span className="unvalid-text">{I18n.t('consent.consentUnvalidText')}</span>
                    <Button
                      color="danger"
                      type="submit"
                      className="submit-button"
                    >
                      {
                      this.state.loading ?
                        <Loader small />
                        :
                        <Fragment>
                          {I18n.t('consent.archived')}
                        </Fragment>
                    }
                    </Button>
                  </div>
                }
                  {!((values.treating === false) || (values.sharing === false)) &&
                  <Button
                    color="primary"
                    type="submit"
                    className="submit-button"
                    disabled={!(values.treating && values.sharing)
                  || this.state.loading
                  || !this.props.caseInfos.isEditable
                  || (this.props.queryParams.caseId && this.props.queryParams.redirectToConsent !== 'true')
                  }
                  >
                    {
                    this.state.loading ?
                      <Loader small />
                      :
                      <Fragment>
                        <i className="fal fa-sign-in" />
                        <Translate value="consent.create" />
                      </Fragment>
                  }
                  </Button>
                }
                </div>
              </form>)}
          />
        }
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(ConsentsCollect));
