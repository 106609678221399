// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  caseDetails: Object,
}

class CaseNotes extends React.Component<Props> {
  render() {
    return (
      <div className="case-notes">
        {this.props.caseDetails.reasonForTheCall &&
        <div className="case-notes-section">
          <div className="note-subtitle">{I18n.t('caseDetails.caseNotes.REASON_FOR_CALL')}</div>
          <div className="note-value">{this.props.caseDetails.reasonForTheCall}</div>
        </div>
        }
        {this.props.caseDetails.adviceNote &&
        <div className="case-notes-section">
          <div className="note-subtitle">{I18n.t('caseDetails.caseNotes.TELEADVICE_NOTES')}</div>
          <div className="note-value">{this.props.caseDetails.adviceNote}</div>
        </div>
        }
        {this.props.caseDetails.teleconsultNotes &&
        <div className="case-notes-section">
          <div className="note-subtitle">{I18n.t('caseDetails.caseNotes.TELECONSULTATION_NOTES')}</div>
          <div className="note-value">{this.props.caseDetails.teleconsultNotes}</div>
        </div>
        }
        {this.props.caseDetails.doctorNotes &&
        <div className="case-notes-section">
          <div className="note-subtitle">{I18n.t('caseDetails.caseNotes.DOCTOR_NOTES')}</div>
          <div className="note-value">{this.props.caseDetails.doctorNotes}</div>
        </div>
        }
      </div>
    );
  }
}

export default CaseNotes;
