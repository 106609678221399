// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import { CASE_PROCESS_PAGE_ROUTE, TELECONSULTATION_PAGE_ROUTE } from '../../routes';
import { createSearchFromParams } from '../../services/queryParamsService';
import patientApi from '../../network/api/patientApi';
import MedicalProfile from '../../components/medicalProfile/MedicalProfile';
import caseApi from '../../network/api/caseApi';
import { notifyError } from '../../network/notification';
import caseReportApi from '../../network/api/caseReportApi';
import { currentSessionService } from '../../services/sessionServiceInstance';
import CoreozDrawer from '../../components/CoreozDrawer';
import ListLastDocuments from '../../components/medicalProfile/components/ListLastDocuments';
import MedicalReportPreview from './MedicalReportPreview';
import hospitalApi from '../../network/api/hospitalApi';
import Loader from '../../components/Loader';
import type { PatientType } from '../../types/PatientTypes';
import type { Case } from '../../types/CasesTypes';

type Props = {
  queryParams: Object,
  history: Function,
  dispatch: Function,
};

type State = {
  sideDrawerOpen: boolean,
  patient: Object,
  medReport: Object,
  actualCase: Case,
  lastDocuments: Object,
  medicalPatient: Object,
  loading: boolean
};

class MedicalReport extends React.Component<Props, State> {
  state = {
    sideDrawerOpen: false,
    patient: {},
    medReport: null,
    actualCase: null,
    lastDocuments: {},
    medicalPatient: {},
    hospitals: [],
    loading: false,
  };

  goBack = () => {
    const queryParams = {
      ...this.props.queryParams,
      documentId: null,
    };

    this.props.history
      .push({
        pathname: CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE,
        search: createSearchFromParams(queryParams),
        state: 'medical',
      });
  };

  drawerToggleClickHandle = () => {
    this.setState({
      sideDrawerOpen: !this.state.sideDrawerOpen,
    });
  };

  componentDidMount = () => {
    this.getPatient(this.props.queryParams.patientId)
      .then(patient => this.fetchDocuments(patient.id, patient.protocolNumber))
      .catch(e => console.log('Erreur apppel API', e));

    this.getInformationOfPatient(this.props.queryParams.patientId);

    caseApi.fetchDetailById(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text && text.length ? JSON.parse(text) : null))
      .then((resJson) => {
        this.setState({ actualCase: resJson });
      })
      .catch(notifyError(this.props.dispatch));

    caseReportApi.getMedicalReportContent(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text && text.length ? JSON.parse(text) : {}))
      .then((medReport) => {
        this.setState({ medReport });
      })
      .catch(notifyError(this.props.dispatch));

    hospitalApi.getList(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(data => this.setState({ hospitals: data }))
      .catch(notifyError(this.props.dispatch));
  };

  getPatient = (id: string) => new Promise((resolve) => {
    patientApi
      .fetchById(id)
      .then(response => response.json())
      .then((patient) => {
        this.setState({ patient });
        resolve(patient);
      })
      .catch(e => console.log('Erreur apppel API', e));
  });


  fetchDocuments = (patientId: string, protocolNumber: string) => {
    patientApi
      .getLastDocuments(patientId, protocolNumber)
      .then(response => response.json())
      .then(lastDocuments => this.setState({ lastDocuments }))
      .catch(this.props.dispatch(notifyError));
  };

  onSubmit = (values: Object) => {
    this.setState({ loading: true });
    caseApi.checkTakenInCharge(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((changeInfos) => {
        if (!changeInfos.change) {
          const htmlContent =
            renderToString(<MedicalReportPreview
              previewMode
              teleconsultNote={values.teleconsultNote}
              medReport={this.state.medReport}
              patient={this.state.medicalPatient}
              actualCase={this.state.actualCase}
              showLogoInDocument={currentSessionService().currentUser().showLogoInDocument}
              showAddressInDocument={currentSessionService().currentUser().showAddressInDocument}
              showHospitals={currentSessionService().currentUser().showHospitals}
              addressedToPatient={currentSessionService().currentUser().reportAddressedToPatient}
              showNonMedicationNotesFirst={currentSessionService().currentUser().showNonMedicationNotesFirst}
              patientPresentation={this.getPatientPresentation()}
              showEmailServiceMedicalReport={currentSessionService().currentUser().showEmailServiceMedicalReport}
              hospitals={this.state.hospitals}
            />);

          if (this.props.queryParams.documentId) {
            caseReportApi.updateMedicalReport(this.props.queryParams.documentId, {
              content: htmlContent,
              caseId: this.props.queryParams.caseId,
              oldTeleConsultNote: this.state.actualCase.teleconsultNotes,
              newTeleConsultNote: values.teleconsultNote,
            })
              .then(() => {
                this.goBack();
                this.setState({ loading: false });
              })
              .catch(notifyError(this.props.dispatch));
          } else {
            caseApi.updateCaseField(
              this.props.queryParams.caseId,
              this.props.queryParams.patientId,
              'teleconsultNotes',
              {
                oldValue: '',
                newValue: values.teleconsultNote,
              },
            );

            caseReportApi.saveMedicalReport({
              content: htmlContent,
              caseId: this.props.queryParams.caseId,
            })
              .then(() => {
                this.goBack();
                this.setState({ loading: false });
              })
              .catch(notifyError(this.props.dispatch));
          }
        } else {
          this.goBack();
          this.setState({ loading: false });
        }
      })
      .catch(e => console.log('Erreur apppel API', e));
  };

  getInformationOfPatient = (id: string) => {
    patientApi
      .getPatientMedicalProfile(id)
      .then(response => response.json())
      .then(responseJson => this.setState({
        medicalPatient: responseJson || {},
      }))
      .catch(this.props.dispatch(notifyError));
  };

  getPatientPresentation = () => {
    let phrase = '';
    if (this.state.medicalPatient.sex === 1) {
      phrase = I18n.t('editDocument.DESCRIPTION_CALL_1_M', { name: this.state.actualCase.patientName.toUpperCase() });
    } else {
      phrase = I18n.t('editDocument.DESCRIPTION_CALL_1_W', { name: this.state.actualCase.patientName.toUpperCase() });
    }
    if (-moment(this.state.medicalPatient.birthDate, 'YYYY-MM-DD').diff(Date.now(), 'years') > 2) {
      phrase += -moment(this.state.medicalPatient.birthDate, 'YYYY-MM-DD').diff(Date.now(), 'years') + I18n.t('editDocument.YEARS');
    } else {
      phrase += -moment(this.state.medicalPatient.birthDate, 'YYYY-MM-DD').diff(Date.now(), 'months') + I18n.t('editDocument.MONTHS');
    }
    if (-moment(this.state.medicalPatient.birthDate, 'YYYY-MM-DD').diff(Date.now(), 'years') < 15 && this.state.medicalPatient.weight !== undefined) {
      phrase += I18n.t('editDocument.DESCRIPTION_CALL_2', { weight: this.state.medicalPatient.weight });
    }
    phrase += I18n.t('editDocument.DESCRIPTION_CALL_3', {
      callDay: moment(this.state.actualCase.dateCreation, 'YYYY-MM-DD').format(I18n.t('date.DATE_FORMAT')),
      callHour: moment(this.state.actualCase.dateCreation).format(I18n.t('date.DATE_HOUR_SHORT')),
    });
    return phrase;
  };

  render() {
    return (
      <div>
        <div className="document-edit">
          <div className="document-edit-header">
            <div className="edit-backTo" onClick={this.goBack}>
              <i className="fa fa-arrow-left" />
              <span>{I18n.t('editDocument.BACK')}</span>
            </div>
            <div className="edit-title">
              <div>{I18n.t('editDocument.TITLE_MEDICAL_REPORT')}</div>
            </div>
          </div>
          <CoreozDrawer show={this.state.sideDrawerOpen} side={false}>
            <ListLastDocuments
              patientId={this.props.queryParams.patientId}
              goBack={this.drawerToggleClickHandle}
              lastDocuments={this.state.lastDocuments.lastDocumentsBean}
              nbTeleconsult={this.state.lastDocuments.nbTeleconsult}
              nbTeleadvice={this.state.lastDocuments.nbTeleadvice}
              nbMaxOfTeleconsult={this.state.lastDocuments.nbOfMaxTeleconsult}
            />
          </CoreozDrawer>
          <div className="edit-container">
            <MedicalProfile
              drawerToggleClickHandle={this.drawerToggleClickHandle}
              patient={this.state.medicalPatient}
              documents={this.state.lastDocuments}
              queryParams={this.props.queryParams}
            />
            {this.state.medicalPatient && this.state.actualCase && this.state.medReport &&
            <div className="edit-content">
              <Form
                onSubmit={this.onSubmit}
                initialValues={{ teleconsultNote: this.state.actualCase && this.state.actualCase.teleconsultNotes || '' }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="edit-header">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={this.state.loading}
                      >
                        {
                          this.state.loading ?
                            <Loader small />
                            :
                            <div>
                              <i className="button-icon fa fa-save" />
                              <span>{I18n.t('button.SAVE')}</span>
                            </div>
                        }
                      </Button>
                      <Button
                        color="secondary"
                        onClick={this.goBack}
                      >
                        <i className="button-icon fa fa-undo" />
                        <span>{I18n.t('button.CANCEL')}</span>
                      </Button>
                    </div>
                    <MedicalReportPreview
                      showLogoInDocument={currentSessionService().currentUser().showLogoInDocument}
                      showAddressInDocument={currentSessionService().currentUser().showAddressInDocument}
                      showHospitals={currentSessionService().currentUser().showHospitals}
                      addressedToPatient={currentSessionService().currentUser().reportAddressedToPatient}
                      showNonMedicationNotesFirst={currentSessionService().currentUser().showNonMedicationNotesFirst}
                      medReport={this.state.medReport}
                      patient={this.state.medicalPatient}
                      actualCase={this.state.actualCase}
                      businessUnitCountryCode={currentSessionService().currentUser().businessUnitCountryCode}
                      patientPresentation={this.getPatientPresentation()}
                      showEmailServiceMedicalReport={currentSessionService().currentUser().showEmailServiceMedicalReport}
                      hospitals={this.state.hospitals}
                    />
                    <div className="edit-footer">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={this.state.loading}
                      >
                        {
                          this.state.loading ?
                            <Loader small />
                            :
                            <div>
                              <i className="button-icon fa fa-save" />
                              <span>{I18n.t('button.SAVE')}</span>
                            </div>
                        }
                      </Button>
                      <Button
                        color="secondary"
                        onClick={this.goBack}
                      >
                        <i className="button-icon fa fa-undo" />
                        <span>{I18n.t('button.CANCEL')}</span>
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(MedicalReport));
