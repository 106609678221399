// @flow

import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import hasPermission from '../services/permissionService';
import { DASHBOARD_PAGE_ROUTE, EXPORTS_PAGE_ROUTE, LOGIN_PAGE_ROUTE } from '../routes';

type Props = {
  component: any,
  authorization: string,
  isConnected: boolean,
}

function PrivateRoute({
                        component: Component, authorization, isConnected, ...rest
                      }: Props) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authorization) {
          if (hasPermission(authorization)) {
            return <Component {...props} />;
          }
          return <Redirect to={hasPermission('ACCESS_CASES_DASHBOARD') ? DASHBOARD_PAGE_ROUTE : EXPORTS_PAGE_ROUTE} />;
        }
        if (isConnected) {
          return <Component {...props} />;
        }
        return <Redirect to={LOGIN_PAGE_ROUTE} />;
      }}
    />
  );
}

export default withRouter(connect(state => ({
  isConnected: state.login.isConnected,
}))(PrivateRoute));
