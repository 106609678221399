// @flow

import { RestClientAuthenticated } from '../network';
import type { Email } from '../../types/NotificationTypes';

const notificationApi = {
  sendSms: (patientId: string, caseId: string, message: Object) => new RestClientAuthenticated(`/notification/sms?patientId=${patientId}&caseId=${caseId}`, 'POST').jsonBody(message).execute(),
  sendFax: (infosFax: Object) => new RestClientAuthenticated('/notification/fax', 'POST').jsonBody(infosFax).execute(),
  validateFax: (token: string) => new RestClientAuthenticated(`/fax/send/${token}`, 'GET').execute(),
  cancelFax: (token: string) => new RestClientAuthenticated(`/fax/cancel/${token}`, 'PUT').execute(),
  sendEmail: (patientId: string, caseId: string, messageObj: Email) => new RestClientAuthenticated(`/notification/email?patientId=${patientId}&caseId=${caseId}`, 'POST').jsonBody(messageObj).execute(),
  sendEmailInitiateVideo: (caseId: string) => new RestClientAuthenticated(`/notification/email/video/${caseId}`, 'POST').execute(),
  resendPDALink: (prmId: string, patientId: string) =>
    new RestClientAuthenticated(`/notification/${prmId}/${patientId}/resendPDALink`, 'POST').execute(),
};

export default notificationApi;
