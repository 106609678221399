// @flow

import { RestClientAuthenticated } from '../network';
import {CaseDetailResponse} from "../../types/caseDetailTypes";

type FamilyDoctor = {
  firstName: string,
  lastName: string,
  addressCountry: string,
  addressCity: string,
  addressPostalcode: string,
  addressLine: string,
  email: string,
  phone: string,
  familyDocConsent: string,
};

const caseApi = {
  fetch: (page: number, filters: Object) => new RestClientAuthenticated(`/cases?page=${page}&view=${filters.userRole || ''}&assign=${filters.userName || ''}&speciality=${filters.speciality || ''}&type=${filters.channel || ''}&appointment=${filters.appointment || ''}`, 'GET').execute(),
  fetchById: (caseId: string) => new RestClientAuthenticated(`/cases/${caseId}`, 'GET').execute(),
  fetchDetailById: (caseId: string) => new RestClientAuthenticated(`/cases/${caseId}/detail`, 'GET').execute(),
  fetchCaseForTeleconsultationById: (caseId: string) => new RestClientAuthenticated(`/cases/${caseId}/teleconsultation`, 'GET').execute(),
  updateAssignmentCase: (caseId: string, value: String) => new RestClientAuthenticated(`/cases/${caseId}/assignment`, 'POST').jsonBody(value).execute(),
  fetchAdviceCodingList: () => new RestClientAuthenticated('/cases/advice-coding', 'GET').execute(),
  searchAdviceCodingList: (name: string) => new RestClientAuthenticated(`/cases/advice-coding/search?name=${name}`, 'GET').execute(),
  updateAdviceCoding: (adviceCoding: Object) => new RestClientAuthenticated('/cases/advice-coding/update', 'POST').jsonBody(adviceCoding).execute(),
  fetchAdviceCodingById: (idCode: string) => new RestClientAuthenticated(`/cases/advice-coding/${idCode}`, 'GET').execute(),
  deleteAdviceCoding: (idCode: string) => new RestClientAuthenticated(`/cases/advice-coding/${idCode}`, 'POST').execute(),
  fetchIcd10List: (search?: ?string) => new RestClientAuthenticated('/cases/icd10', 'POST').jsonBody(search).execute(),
  fetchPathologyCoding: () => new RestClientAuthenticated('/cases/pathology', 'GET').execute(),
  fetchPathologyCodingById: (idCode: string) => new RestClientAuthenticated(`/cases/pathology/${idCode}`, 'GET').execute(),
  searchPathologyCodingList: (name: string) => new RestClientAuthenticated(`/cases/pathology/search?name=${name}`, 'GET').execute(),
  updatePathologyCoding: (pathologyCoding: Object) => new RestClientAuthenticated('/cases/pathology/update', 'PATCH').jsonBody(pathologyCoding).execute(),
  deletePathologyCoding: (idCode: string) => new RestClientAuthenticated(`/cases/pathology/${idCode}`, 'POST').execute(),
  getLiveStatus: (id: string) => new RestClientAuthenticated(`/cases/${id}/live-status`, 'GET').execute(),
  setLiveStatusToPatientOut: (id: string) => new RestClientAuthenticated(`/cases/${id}/patientOut`, 'POST').execute(),
  getFamilyDoctor: (patientId: string) => new RestClientAuthenticated(`/cases/${patientId}/familyDoctor`, 'GET').execute(),
  getHighestStep: (caseId: string) => new RestClientAuthenticated(`/cases/${caseId}/step`, 'GET').execute(),
  getRedirectInfos: (caseId: string) => new RestClientAuthenticated(`/cases/${caseId}/redirect`, 'GET').execute(),
  updateFamilyDoctor: (idDoctor: string, idCase: ?string, field: string, newValue: string) => new RestClientAuthenticated(`/cases/familyDoctor/${idDoctor}?field=${field}${idCase ? `&idCase=${idCase}` : ''}`, 'PATCH').body(newValue).execute(),
  checkTakenInCharge: (caseId: string) => new RestClientAuthenticated(`/cases/${caseId}/takenInCharge`, 'GET').execute(),
  saveIcd10: (id: string, idPatient: string, icd10: Object) => new RestClientAuthenticated(`/cases/${id}/icd10?idPatient=${idPatient}`, 'POST').jsonBody(icd10).execute(),
  updateConsents: (id: string) => new RestClientAuthenticated(`/cases/${id}/consents`, 'PUT').execute(),
  updateCaseField: (id: string, patientId: string, field: string, values: Object) => new RestClientAuthenticated(`/cases/${id}?field=${field}&patientId=${patientId}`, 'PATCH').jsonBody(values).execute(),
  updateTechnicalProblem: (id: number, values: Object, patientId: string) => new RestClientAuthenticated(`/cases/${id}/technical-problem`, 'PATCH').jsonBody(values).execute(),
  takeInCharge: (id: string) => new RestClientAuthenticated(`/cases/${id}/takeInCharge`, 'POST').execute(),
  createCase: (caseInfos: Object) => new RestClientAuthenticated('/cases', 'POST').jsonBody(caseInfos).execute(),
  createAndArchive: (caseInfos: Object) => new RestClientAuthenticated('/cases/archived', 'POST').jsonBody(caseInfos).execute(),
  archiveCase: (caseId: string) => new RestClientAuthenticated(`/cases/${caseId}/archived`, 'PUT').execute(),
  assignedTo: (id: string, idUser: string) => new RestClientAuthenticated(`/cases/${id}/user/${idUser}/assigned`, 'PATCH').execute(),
  updateFamilyDoctorAndTransfer: (caseId: string, userId: string, values: FamilyDoctor) => new RestClientAuthenticated(`/cases/${caseId}/user/${userId}/family-doctor`, 'PUT').jsonBody(values).execute(),
  setStep: (caseId: string, step: number) => new RestClientAuthenticated(`/cases/${caseId}/step/${step}`, 'POST').execute(),
  updateDocumentsValues: (documentId: string, values: Object) => new RestClientAuthenticated(`/document/${documentId}/sent-by`, 'PATCH').jsonBody(values).execute(),
  search: (values: Object) => new RestClientAuthenticated('/cases/search', 'POST').jsonBody(values).execute(),
  reOpenCase: (idCase: string, idPatient: string) => new RestClientAuthenticated(`/cases/${idCase}/${idPatient}/reOpen`, 'POST').execute(),
  getCaseHistory: (caseId: string) => new RestClientAuthenticated(`/cases/${caseId}/histories`, 'GET').execute(),
  isUserInChargeOfCases: () => new RestClientAuthenticated('/cases/inCharge', 'GET').execute(),
  getEmergency: (caseId: string) => new RestClientAuthenticated(`/cases/${caseId}/emergency`, 'GET').execute(),
  updateEmergency: (patientId: string, caseId: string, finalValues: Object) =>
    new RestClientAuthenticated(`/cases/${caseId}/patient/${patientId}/emergency`, 'PATCH').jsonBody(finalValues).execute(),
  fetchIsCmCic: (caseId: string) => new RestClientAuthenticated(`/cases/cmcic?caseId=${caseId}`, 'GET').execute(),
  fetchLastCasesByRole: () => new RestClientAuthenticated(`/cases/lastCase`, 'GET').execute(),
  fetchPatientCase: (id: string) => new RestClientAuthenticated(`/cases/patient/${id}/consultations`, 'GET').execute(),
};

export default caseApi;
