//@flow

import type { ResetPasswordRequest } from '../types/ForgotPasswordTypes';
import { HttpClient } from '../network/network';
import { toJsonError } from '../network/handleError';

export function sendReset(userName: string) {
  return new HttpClient(`/forgot-password/send-reset?userName=${userName}`, 'POST')
    .execute()
    .catch(toJsonError);
}

export function sendOtp(resetPasswordToken: string) {
  return new HttpClient(`/forgot-password/send-otp?resetPasswordToken=${resetPasswordToken}`, 'POST')
    .execute()
    .then(response => response.json())
    .catch(toJsonError);
}

export function checkOtp(resetPasswordToken: string, otp: string) {
  return new HttpClient(`/forgot-password/check-otp?resetPasswordToken=${resetPasswordToken}&otp=${otp}`, 'POST')
    .execute()
    .catch(toJsonError);
}

export function resetPassword(resetPasswordRequest: ResetPasswordRequest) {
  return new HttpClient('/forgot-password/reset-password', 'POST')
    .jsonBody(resetPasswordRequest)
    .execute()
    .catch(toJsonError);
}
