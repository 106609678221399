// @flow

import { RestClientAuthenticated } from '../network'

const hospitalApi = {
  getCaseReport: (caseId: string) => new RestClientAuthenticated(`/hospital/${caseId}/report`, 'GET').execute(),
  updateCaseReport: (caseId: string, value: boolean) => new RestClientAuthenticated(`/hospital/${caseId}/report/${String(value)}`, 'GET').execute(),
  getList: (caseId: string) => new RestClientAuthenticated(`/hospital/case/${caseId}/places`, 'GET').execute(),
  getPlaces: (input: string, caseId: string) => new RestClientAuthenticated(`/hospital/places/${input}?caseId=${caseId}`, 'GET').execute(),
  sendMail: (body: Object) => new RestClientAuthenticated('/hospital/mail', 'POST').jsonBody(body).execute(),
  sendSms: (body: Object) => new RestClientAuthenticated('/hospital/sms', 'POST').jsonBody(body).execute(),
};

export default hospitalApi;
