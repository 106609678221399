// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import MailSearch from '../../components/mail/MailSearch';
import MailList from '../../components/mail/MailList';
import type { Criteria, Mail } from '../../types/MailTypes';
import { store } from '../../network/reduce';
import { SEARCH_RESULTS_MAIL } from '../../state/mail/mailReducer';
import search from '../../state/search';
import { fetchMails } from '../../state/mail/mailService';

type Props = {
  mails: Mail[],
  searchResultsMail: [],
  dispatch: Function,
}

class MailMain extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(fetchMails());
  }

  searchMail = (criteria: Criteria) => {
    this.props.dispatch(store(
      SEARCH_RESULTS_MAIL,
      search(criteria, this.props.mails),
    ));
  };

  render() {
    if (this.props.mails === undefined || !Array.isArray(this.props.mails)) {
      return false;
    }

    return (
      <div>
        <h1 className="content-title"><Translate value="mail.TITLE" /></h1>
        <div className="content">
          <Row className="row">
            <Col xs="12">
              <MailSearch
                search={this.searchMail}
                mails={this.props.mails}
              />
            </Col>
            <Col xs="12">
              <MailList
                mails={this.props.searchResultsMail
                  ? this.props.searchResultsMail
                  : this.props.mails}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  mails: state.mail.mails,
  searchResultsMail: state.mail.searchResultsMail,
}))(MailMain));
