import React from 'react';
import PrivateRoute from '../../components/PrivateRoute';
import { BRAND_EDIT_PAGE_ROUTE, BRAND_CREATE_PAGE_ROUTE, BRAND_MANAGEMENT_PAGE_ROUTE } from '../../routes';
import BrandManagement from '../brand/BrandManagement';
import BrandRequest from '../brand/BrandRequest';
import { BrandContextProvider } from '../../stores/brand-management-context';


export default function BrandManagementRoutes() {
  return (
    <BrandContextProvider>
      <PrivateRoute exact path={BRAND_MANAGEMENT_PAGE_ROUTE} component={BrandManagement} />
      <PrivateRoute exact path={BRAND_CREATE_PAGE_ROUTE} component={BrandRequest} />
      <PrivateRoute exact path={BRAND_EDIT_PAGE_ROUTE} component={BrandRequest} />
    </BrandContextProvider>
  );
}
