import React, { useState, useEffect, useContext } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Loader from '../../components/Loader';
import protocolContext from '../../stores/b-partner-management-context';
import {
	CoreozInputBase,
	CoreozSelectBase,
} from '../../lib/coreoz-form-base/index';
import bPartnerApi from '../../network/api/bPartnerApi';
import legalEntityApi from '../../network/api/legalEntityApi';
import brandApi from '../../network/api/brandApi';
import logoApi from '../../network/api/logoApi';

import { ProtocolDetail } from '../../types/ProtocolManagement';
import {
	PROTOCOL_EDIT_PAGE_ROUTE,
	PROTOCOL_MANAGEMENT_PAGE_ROUTE,
} from '../../routes';
import { required } from '../../validator';
import { notifyError, notifySuccess } from '../../network/notification';
import protocolApi from '../../network/api/protocolApi';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		color: '#fff',
		zIndex: theme.zIndex.drawer + 1,
	},
}));

const ProtocolRequest = (props) => {
	const classes = useStyles();
	const isEdit = !!props.match.params.id || false;
	const protocolId = props.match.params.id || null;
	const { searchCriteriaContext, updateSearchCriteriaContext } =
		useContext(protocolContext);
	const [legalEntities, setLegalEntities] = useState([]);
	const [bPartners, setBPartners] = useState([]);
	const [brands, setBrands] = useState([]);
	const [logos, setLogos] = useState([]);

	const [button, setButton] = useState('');
	const [protocolDetail, setProtocolDetail] = useState({});
	const [loading, setLoading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	useEffect(() => {
		fetchMasterList();
	}, []);

	async function fetchMasterList() {
		setLoading(true);

		let protocolDetailResponse: ProtocolDetail;
		if (protocolId) {
			protocolDetailResponse = await fetchProtocolDetail(protocolId);
			setProtocolDetail({ ...protocolDetailResponse });
		}

		const bPartnerResponses = await bPartnerApi
			.fetchBpartners()
			.then((response) => response.text())
			.then((text) => (text.length ? JSON.parse(text) : []));
		if (isEdit) {
			setBPartners(
				bPartnerResponses.filter(
					(bpartner) =>
						bpartner.isActive ||
						bpartner.id === protocolDetailResponse.bpartner.id
				)
			);
		} else {
			setBPartners(
				bPartnerResponses.filter((bpartner) => bpartner.isActive)
			);
		}

		const legalEntityResponses = await legalEntityApi.fetchList();
		if (isEdit) {
			setLegalEntities(
				legalEntityResponses.filter(
					(legalEntity) =>
						legalEntity.isActive ||
						legalEntity.id === protocolDetailResponse.legalEntity.id
				)
			);
		} else {
			setLegalEntities(
				legalEntityResponses.filter(
					(legalEntity) => legalEntity.isActive
				)
			);
		}

		const brandResponses = await brandApi.fetchList();
		if (isEdit) {
			setBrands([
				...brandResponses.filter(
					(brand) =>
						brand.isActive ||
						brand.id === protocolDetailResponse.brand.id
				),
			]);
		} else {
			setBrands([...brandResponses.filter((brand) => brand.isActive)]);
		}

		const logoResponses = await logoApi.fetchList();
		if (isEdit) {
			setLogos(
				logoResponses.filter(
					(logo) =>
						logo.isActive ||
						logo.id === protocolDetailResponse.logo.id
				)
			);
		} else {
			setLogos(logoResponses.filter((logo) => logo.isActive));
		}

		setLoading(false);
	}

	function fetchProtocolDetail(id: Number): ProtocolDetail {
		return props.dispatch(protocolApi.fetchById(id));
	}

	const onPreviousHandler = () => {
		props.history.push(PROTOCOL_MANAGEMENT_PAGE_ROUTE);
	};

	const handleChange = (e) => {};

	const getHeaderTitle = (): String =>
		isEdit ? I18n.t('protocol.TITLE') : I18n.t('protocol.TITLE_CREATE');

	const onSave = async (dataForm: ProtocolManagement) => {
		setLoading(true);
		if (isEdit) {
			await protocolApi
				.update(protocolId, dataForm)
				.then(props.dispatch(notifySuccess))
				.catch(props.dispatch(notifyError))
				.finally(() => setLoading(false));

			fetchMasterList();
		} else {
			dataForm.isActive = true;
			protocolApi
				.save(dataForm)
				.then((res) => res.text())
				.then(props.dispatch(notifySuccess))
				.then((res) => {
					props.history.push(
						PROTOCOL_EDIT_PAGE_ROUTE.replace(':id', res),
						props.location.state
					);
				})
				.catch(props.dispatch(notifyError))
				.finally(() => setLoading(false));
		}
	};

	const isShowErrorProtocolNotApplicableMsg = (isCmCic, isVisa) => {
		return isCmCic === true && isVisa === true;
	};

	const isShowWarningtoFillBinLaterMsg = (isCmCic, isVisa) => {
		return isCmCic === false && isVisa === true;
	};

	const formatForFront = () => {
		if (!protocolDetail || Object.keys(protocolDetail).length === 0) {
			return;
		}

		return {
			protocolNumber: protocolDetail?.protocolNumber || null,
			protocolName: protocolDetail?.protocolName || '',
			bPartnerId: protocolDetail?.bpartner.id || null,
			legalEntityId: protocolDetail?.legalEntity.id || null,
			brandId: protocolDetail?.brand.id || null,
			logoId: protocolDetail?.logo.id || null,
			phoneNo: protocolDetail?.phoneNumber || null,
			numberOfConsultation: protocolDetail?.numberOfConsultation
				? `${protocolDetail?.numberOfConsultation}`
				: '',
			providerNetworkDetail: protocolDetail?.providerNetworkDetail
				? `${protocolDetail?.providerNetworkDetail}`
				: '',
			isThirdParty: protocolDetail?.thirdPartSso,
			isCmCic: protocolDetail?.isCmCic,
			isVisa: protocolDetail?.isVisa,
			isActive: protocolDetail?.isActive,
		};
	};

	return (
		<div className="protocol-details user-details" id="protocol-details">
			<div className="protocol-details-header">
				<div className="back-to-list" onClick={onPreviousHandler}>
					<i className="fa fa-arrow-left" />
					{I18n.t('userDetails.BACK_TO_LIST')}
				</div>

				<div className="title">
					{getHeaderTitle()}
					{isEdit && protocolDetail && (
						<div className="protocol_details_info">
							{protocolDetail.protocolNumber}
						</div>
					)}
				</div>
			</div>

			<Form
				onSubmit={onSave}
				initialValues={formatForFront()}
				render={({ handleSubmit, form, invalid, values }) => (
					<form onSubmit={handleSubmit}>
						<div className="user-action">
							<Button
								color="secondary"
								type="button"
								onClick={onPreviousHandler}
							>
								<i className="button-icon fa fa-undo" />
								{I18n.t('actions.CANCEL')}
							</Button>
							<Button
								color="primary"
								type="submit"
								disabled={
									invalid ||
									isShowErrorProtocolNotApplicableMsg(
										values?.isCmCic,
										values?.isVisa
									)
								}
							>
								<i className="button-icon fa fa-save" />
								{I18n.t('actions.SAVE')}
							</Button>
						</div>

						<div className="content">
							<div className="user-category">
								<div className="content-container">
									<Field
										autoComplete="off"
										type="text"
										component={CoreozInputBase}
										name="protocolNumber"
										label={I18n.t(
											'protocol.label.PROTOCOL_NUMBER'
										)}
										validate={required}
										required
										maxLength="255"
									/>

									<Field
										autoComplete="off"
										type="text"
										component={CoreozInputBase}
										name="protocolName"
										label={I18n.t(
											'protocol.label.PROTOCOL_NAME'
										)}
										validate={required}
										required
										maxLength="255"
									/>

									<Field
										name="bPartnerId"
										id="bPartner"
										autoComplete="off"
										type="text"
										component={CoreozSelectBase}
										label={I18n.t(
											'protocol.label.B_PARTNER'
										)}
										list={bPartners.map((bPartner) => ({
											id: bPartner.id,
											label: `${
												bPartner.bpartnerCode
											} - ${bPartner.bpartnerName} ${
												!bPartner.isActive
													? `(${I18n.t(
															'protocol.STATUS.INACTIVE'
													  )})`
													: ''
											} `,
										}))}
										validate={required}
										required
									/>

									<Field
										name="legalEntityId"
										id="legalEntity"
										autoComplete="off"
										type="text"
										component={CoreozSelectBase}
										label={I18n.t(
											'protocol.label.LEGAL_ENTITY'
										)}
										list={legalEntities.map(
											(legalEntity) => ({
												id: legalEntity.id,
												label: `${legalEntity.code} - ${
													legalEntity.name
												} ${
													!legalEntity.isActive
														? `(${I18n.t(
																'protocol.STATUS.INACTIVE'
														  )})`
														: ''
												} `,
											})
										)}
										validate={required}
										required
									/>

									<Field
										name="brandId"
										id="brand"
										autoComplete="off"
										type="text"
										component={CoreozSelectBase}
										label={I18n.t('protocol.label.BRAND')}
										list={brands.map((brand) => ({
											id: brand.id,
											label: `${brand.code} - ${
												brand.name
											} ${
												!brand.isActive
													? `(${I18n.t(
															'protocol.STATUS.INACTIVE'
													  )})`
													: ''
											} `,
										}))}
										validate={required}
										required
									/>

									<Field
										name="logoId"
										id="logo"
										autoComplete="off"
										type="text"
										component={CoreozSelectBase}
										label={I18n.t('protocol.label.LOGO')}
										list={logos.map((logo) => ({
											id: logo.id,
											label: `${logo.name} ${
												!logo.isActive
													? `(${I18n.t(
															'protocol.STATUS.INACTIVE'
													  )})`
													: ''
											} `,
										}))}
										validate={required}
										required
									/>

									<Field
										type="text"
										component={CoreozInputBase}
										name="phoneNo"
										label={I18n.t(
											'protocol.label.MEDICAL_TELECONSULTATION_PHONE_NUMBER'
										)}
										validate={required}
										required
										maxLength="100"
									/>

									<Field
										type="text"
										component={CoreozInputBase}
										name="numberOfConsultation"
										label={I18n.t(
											'protocol.label.NUMBER_OF_CONSULTATIONS_AUTHORIZED_PER_YEAR'
										)}
										validate={required}
										required
										maxLength="9"
										format={(stringValue) =>
											stringValue &&
											stringValue.replace(/[^\d]/g, '')
										}
									/>

									<Field
										type="text"
										component={CoreozInputBase}
										name="providerNetworkDetail"
										label={I18n.t(
											'protocol.label.PROVIDER_NETWORK'
										)}
										required={false}
										maxLength="255"
									/>

									<div className="radio-button-container">
										<div className="radio-button-label third-part-status">
											{I18n.t(
												'protocol.label.THIRD_PARTY_SSO'
											)}
										</div>

										<div className="choices">
											<Field
												component="input"
												name="isThirdParty"
												type="radio"
												value="true"
												format={(value) =>
													String(value)
												}
												parse={(value) =>
													value === 'true'
												}
												validate={required}
												required
											/>
											<span>
												{I18n.t('userDetails.YES')}
											</span>
											<Field
												className="base-input-coreoz"
												component="input"
												name="isThirdParty"
												type="radio"
												value="false"
												format={(value) =>
													String(value)
												}
												parse={(value) =>
													value === 'true'
												}
												validate={required}
												required
											/>
											<span>
												{I18n.t('userDetails.NO')}
											</span>
										</div>
									</div>

									<div className="radio-button-container">
										<div className="radio-button-label required-flag">
											{I18n.t(
												'protocol.label.TYPE_OF_PROTOCOL'
											)}
										</div>
										<div className="choices">
											<Field
												component="input"
												name="isCmCic"
												type="radio"
												value="true"
												format={(value) =>
													String(value)
												}
												parse={(value) =>
													value === 'true'
												}
												validate={required}
											/>
											<span>
												{I18n.t(
													'protocol.TYPE_OF_PROTOCOL_TYPE.CREDIT_CARD_TYPE'
												)}
											</span>
											<Field
												className="base-input-coreoz"
												component="input"
												name="isCmCic"
												type="radio"
												value="false"
												format={(value) =>
													String(value)
												}
												parse={(value) =>
													value === 'true'
												}
												validate={required}
											/>
											<span>
												{I18n.t(
													'protocol.TYPE_OF_PROTOCOL_TYPE.NOT_CREDIT_CARD_TYPE'
												)}
											</span>
										</div>
									</div>

									<div className="radio-button-container">
										<div className="radio-button-label required-flag">
											{I18n.t('protocol.label.VISA')}
										</div>
										<div className="choices">
											<Field
												component="input"
												name="isVisa"
												type="radio"
												value="true"
												format={(value) =>
													String(value)
												}
												parse={(value) =>
													value === 'true'
												}
												validate={required}
											/>
											<span>{I18n.t('form.YES')}</span>
											<Field
												className="base-input-coreoz"
												component="input"
												name="isVisa"
												type="radio"
												value="false"
												format={(value) =>
													String(value)
												}
												parse={(value) =>
													value === 'true'
												}
												validate={required}
											/>
											<span>{I18n.t('form.NO')}</span>
										</div>
									</div>

									{isShowWarningtoFillBinLaterMsg(
										values?.isCmCic,
										values?.isVisa
									) && (
										<div
											className="alert alert-warning"
											role="alert"
										>
											{I18n.t(
												'protocol.message.WARNING_FILL_BIN'
											)}
										</div>
									)}
									{isShowErrorProtocolNotApplicableMsg(
										values?.isCmCic,
										values?.isVisa
									) && (
										<div
											className="alert alert-danger"
											role="alert"
										>
											{I18n.t(
												'protocol.message.ERROR_NOT_ALLOWED_ENABLE_BOTH_VISA_CIC'
											)}
										</div>
									)}

									{isEdit && (
										<div className="radio-button-container">
											<div className="radio-button-label required-flag">
												{I18n.t(
													'protocol.label.STATUS'
												)}
											</div>
											<div className="choices">
												<Field
													component="input"
													name="isActive"
													type="radio"
													value="true"
													format={(value) =>
														String(value)
													}
													parse={(value) =>
														value === 'true'
													}
													validate={required}
													required
												/>
												<span>
													{I18n.t(
														'protocol.STATUS.ACTIVE'
													)}
												</span>
												<Field
													component="input"
													name="isActive"
													type="radio"
													value="false"
													format={(value) =>
														String(value)
													}
													parse={(value) =>
														value === 'true'
													}
													validate={required}
													required
												/>
												<span>
													{I18n.t(
														'protocol.STATUS.INACTIVE'
													)}
												</span>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="user-action mt-5">
							<Button
								color="secondary"
								type="button"
								onClick={onPreviousHandler}
							>
								<i className="button-icon fa fa-undo" />
								{I18n.t('actions.CANCEL')}
							</Button>
							<Button
								color="primary"
								type="submit"
								disabled={
									invalid ||
									isShowErrorProtocolNotApplicableMsg(
										values?.isCmCic,
										values?.isVisa
									)
								}
							>
								<i className="button-icon fa fa-save" />
								{I18n.t('actions.SAVE')}
							</Button>
						</div>
					</form>
				)}
			/>
			<Backdrop className={classes.backdrop} open={loading}>
				<div className="center-child search-loader">
					<Loader />
				</div>
			</Backdrop>
		</div>
	);
};

export default connect()(ProtocolRequest);
