// @flow

import React from 'react';
import ProcessBar from './ProcessBar';
import ProcessCaseHeader from './ProcessCaseHeader';
import { currentSessionService } from '../../../services/sessionServiceInstance';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

type Props = {
  dispatch: Function,
  queryParams: Object,
  canRender: boolean,
};

type State = {
  userName: string,
  available: boolean,
};

class CaseHeader extends React.Component<Props, State> {
  state = {
    userName: currentSessionService().currentUser().fullName,
    available: true,
  };

  render() {
    return (
      <div className="header">
        <div>
          <ProcessCaseHeader canRender={this.props.canRender} queryParams={this.props.queryParams}
                             fetchData={this.props.fetchData} />
          <ProcessBar queryParams={this.props.queryParams} />
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(CaseHeader));
