// @flow

import { RestClientAuthenticated } from '../network';

const caseReportApi = {
  getMedicalReportContent: (caseId: string) => new RestClientAuthenticated(`/report/medical/case/${caseId}`, 'GET').execute(),
  getAdviceReportContent: (caseId: string) => new RestClientAuthenticated(`/report/advice/case/${caseId}`, 'GET').execute(),
  getPolicyAssuranceInfosByCases: (idCase: string, idPatient: string) => new RestClientAuthenticated(`/report/teleconsultation/policyAssurance?caseId=${idCase}&patientId=${idPatient}`, 'GET').execute(),
  getPolicyAssuranceInfosByPatient: (idPatient: string) => new RestClientAuthenticated(`/report/teleconsultation/patient/${idPatient}/policyAssurance`, 'GET').execute(),
  getDocumentByCaseId: (idCase: string) => new RestClientAuthenticated(`/report/documents/${idCase}`, 'GET').execute(),
  getReportDocumentsByCaseId: (idCase: string) => new RestClientAuthenticated(`/report/case/${idCase}/documents`, 'GET').execute(),
  saveMedicalReport: (data: Object) => new RestClientAuthenticated(`/report/tele-medical`, 'POST').jsonBody(data).execute(),
  updateMedicalReport: (caseId: string, data: Object) => new RestClientAuthenticated(`/report/${caseId}/tele-medical`, 'PUT').jsonBody(data).execute(),
  saveAdviceReport: (data: Object) => new RestClientAuthenticated(`/report/tele-advice`, 'POST').jsonBody(data).execute(),
  updateAdviceReport: (documentId: string,data: Object) => new RestClientAuthenticated(`/report/${documentId}/tele-advice`, 'PUT').jsonBody(data).execute(),
  deleteCaseReport: (documentId: string) => new RestClientAuthenticated(`/report/${documentId}`, 'DELETE').execute(),
};


export default caseReportApi;
