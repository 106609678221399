
import { RestClientAuthenticated } from '../../network/network';

const apiEndpoint = {
  pathologyCoding: '/pathology-coding',
  search: '/search',
  getById: '/:id',
  saveById: '/:id',
  list: '/list',
};

const pathologyCodingApi = {
  searchAdviceCodingList: (name: string) => new RestClientAuthenticated(`${apiEndpoint.pathologyCoding}/search?name=${name}`, 'GET').execute(),
  fetchPathologyCodingList: () => new RestClientAuthenticated(`${apiEndpoint.pathologyCoding}${apiEndpoint.list}`, 'GET').execute(),
  fetchPathologyCodingById: (id: string) => new RestClientAuthenticated(`${apiEndpoint.pathologyCoding}${apiEndpoint.saveById.replace(':id', id)}`, 'GET').execute(),
  update: (data: any, id: string) => new RestClientAuthenticated(`${apiEndpoint.pathologyCoding}${apiEndpoint.saveById.replace(':id', id)}`, 'PUT').jsonBody(data).execute(),
  save: (data: any) => new RestClientAuthenticated(`${apiEndpoint.pathologyCoding}`, 'POST').jsonBody(data).execute(),
  delete: (id: string) => new RestClientAuthenticated(`${apiEndpoint.pathologyCoding}${apiEndpoint.saveById.replace(':id', id)}`, 'DELETE').execute(),
};


export default pathologyCodingApi;
