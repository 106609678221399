// @flow

import { RestClientAuthenticated } from '../network';
import type { PolicySearch } from './processApi';


export type AddNewPolicyType = {
  isPolicyHolder: boolean,
  beneficiaryFirstName: string,
  beneficiaryLastName: string,
  policyHolderFirstName: string,
  policyHolderLastName: string,
  dateOfBirth: Object,
  policyNumber: string,
}


export const policiesApi = {
  fetchAllById: (prmIdPatient: string) =>
    new RestClientAuthenticated(`/policy/${prmIdPatient}`, 'GET').execute(),
  fetchPolicy: (patientPrmId: string, policyNumber: string) =>
    new RestClientAuthenticated(`/policy/${patientPrmId}/${policyNumber}`, 'GET').execute(),
  fetchPolicyBeneficiaryContract: (searchParams: PolicySearch) =>
    new RestClientAuthenticated(`/policy/beneficiary/contracts?${queryString(searchParams)}`, 'GET').execute(),
  fetchPolicyHolderContract: (searchParams: PolicySearch) =>
    new RestClientAuthenticated(`/policy/holder/contracts?${queryString(searchParams)}`, 'GET').execute(),
  addNewPolicy: (prmId: string, searchParams: AddNewPolicyType) =>
    new RestClientAuthenticated(`/policy/patient/${prmId}/contract`, 'POST').jsonBody(searchParams).execute(),
  fetchPolicyPatientContract: (prmId: string) =>
    new RestClientAuthenticated(`/policy/patient/${prmId}/contracts`, 'GET').execute(),
  updatePolicyPatient: (policyId: string,patientId: string, params: any) =>
    new RestClientAuthenticated(`/policy/${policyId}/patient/${patientId}`, 'PUT').jsonBody(params).execute(),
};

const queryString = (searchCriteria) => Object.keys(searchCriteria).filter(v => searchCriteria[v] !== null).map(key => key + '=' + searchCriteria[key]).join('&');


export default policiesApi;
