// @flow

import React from 'react';
import { downloadBase64 } from '../../utils';
import SummaryTile from './components/SummaryTile';

type Props = {
  summaryTitle:string,
  reasonForTheCall?:string,
  symptomCheckFlag?:string,
  teleAdviceNotes?:string,
  teleConsultationNotes?:string,
  documents?:Array<Object>,
  childrenList?:Array<Object>,
}

type State = {
  toggle:boolean,
}

class Summary extends React.Component<Props, State> {
  static defaultProps = {
    reasonForTheCall: null,
    symptomCheckFlag: null,
    teleAdviceNotes: null,
    teleConsultationNotes: null,
    documents: null,
    // childrenList: null,
  };

  state = {
    toggle: false,
  };

  toggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };



  render() {
    return (
      <div className="teleconsultation-category">
        <div onClick={this.toggle}>
          <div className="category-title summary-title">
            <i className={` fas ${this.state.toggle ? 'fa-chevron-down' : 'fa-chevron-right'}`}/>
            {this.props.summaryTitle}
          </div>
        </div>
        {this.state.toggle &&
        <div>
          {!this.props.childrenList ?
            <SummaryTile
              reasonForTheCall={this.props.reasonForTheCall}
              symptomCheckFlag={this.props.symptomCheckFlag}
              teleAdviceNotes={this.props.teleAdviceNotes}
              teleConsultationNotes={this.props.teleConsultationNotes}
              documents={this.props.documents}
            />
            :
            <div>
              {Object.keys(this.props.childrenList).map(child =>
                <div className="summary-children-related-case-container">
                  <span className="summary-children-related-case-title">{this.props.childrenList[child].caseName}</span>
                  <div className="summary-children-related-case-content">
                    <SummaryTile
                      reasonForTheCall={this.props.childrenList[child].reasonForTheCall}
                      symptomCheckFlag={this.props.childrenList[child].symptomCheckFlag}
                      teleAdviceNotes={this.props.childrenList[child].teleAdviceNotes}
                      teleConsultationNotes={this.props.childrenList[child].teleConsultationNotes}
                      documents={this.props.childrenList[child].documents}
                    />
                  </div>
                </div>
              )
              }
            </div>
          }
        </div>
        }

      </div>
    );
  }
}

export default Summary;
