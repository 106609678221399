// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import type { Sms } from '../../types/SmsTypes';

import PlmPanel from '../panel/PlmPanel';
import PlmFormItem from '../panel/PlmFormItem';

type Props = {
  search: Function,
  smsList: Sms[],
}

class SmsSearch extends React.PureComponent<Props> {
  getSmsTypeOptionList = (smsList: Array<Object>) => {
    const smsTypeOptionList = [];
    smsList.forEach((option: Object) => {
      if (!smsTypeOptionList.includes(option.smsType)) {
        smsTypeOptionList.push(option.smsType);
      }
    });
    return smsTypeOptionList;
  };

  render() {
    return (
      <PlmPanel title={I18n.t('sms.SEARCH_TITLE')}>
        <Form
          onSubmit={this.props.search}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="panel-body">
                <Row className="search-form">
                  <Col>
                    <PlmFormItem label={I18n.t('sms.label.idBusinessUnit')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="input"
                        name="idBusinessUnit"
                      />
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('sms.label.smsType')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="select"
                        name="smsType"
                      >
                        <option value="any">{I18n.t('sms.label.all')}</option>
                        {this.props.smsList.length > 0 &&
                        this.getSmsTypeOptionList(this.props.smsList)
                          .map(option => (
                            <option value={option} key={option}>{option}</option>
                          ))
                        }
                      </Field>
                    </PlmFormItem>
                    <PlmFormItem label={I18n.t('sms.label.template')}>
                      <Field
                        className="form-control"
                        type="text"
                        component="input"
                        name="template"
                      />
                    </PlmFormItem>
                  </Col>
                </Row>
              </div>
              <div className="panel-footer">
                <button
                  className="btn btn-info"
                  type="submit"
                >
                  <Translate value="actions.SEARCH" />
                </button>
              </div>
            </form>
          )}
        />
      </PlmPanel>
    );
  }
}

export default withRouter(connect()(SmsSearch));
