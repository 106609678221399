// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { CoreozSelectBase as CoreozSelect } from '../../lib/coreoz-form-base/index';
import CoreozSelectAutocomplete from '../../lib/coreoz-form-base/components/select-autocomplete/CoreozSelectAutocomplete';
import { Field, Form } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import MedicalProfile from '../../components/medicalProfile/MedicalProfile';
import ModalEmergency from '../../components/modal/ModalEmergency';
import ModalTechnicalProblem from '../../components/modal/ModalTechnicalProblem';
import ResizableTextarea from '../../components/ResizableTextarea';
import Summary from './Summary';
import CoreozDrawer from '../../components/CoreozDrawer';
import patientApi from '../../network/api/patientApi';
import ListLastDocuments from '../../components/medicalProfile/components/ListLastDocuments';
import AutoSave from '../../components/AutoSave';
import ContactSideBar from '../../components/contact-side-bar/ContactSideBar';
import caseApi from '../../network/api/caseApi';
import { notifyError } from '../../network/notification';
import referentialApi from '../../network/api/referentialApi';
import SelectMultiAutoComplete from '../../components/SelectMultiAutoComplete';
import {
  CASE_PROCESS_PAGE_ROUTE,
  MED_REPORT_PAGE_ROUTE,
  PRESCRIPTION_PAGE_ROUTE,
  SENDING_DOCUMENTS_PAGE_ROUTE,
  TELECONSULTATION_PAGE_ROUTE,
} from '../../routes';
import processCaseApi from '../../network/api/processCaseApi';
import { createSearchFromParams } from '../../services/queryParamsService';
import { currentSessionService } from '../../services/sessionServiceInstance';
import Loader from '../../components/Loader';
import caseReportApi from '../../network/api/caseReportApi';
import prescriptionApi from '../../network/api/prescriptionApi';
import { validateListNotEmpty } from '../../validator';
import ModalAssigned from '../../components/modal/ModalAssigned';
import ShowAndEditDocument from './ShowAndEditDocument';
import HistoryModification from '../../components/historyModification/HistoryModification';
import { Roles } from '../../enum';
import { debounce, groupHistoryByDateAndIdUser } from '../../utils';
import CreateRelatedCaseModal from './components/CreateRelatedCaseModal';
import protocolApi from '../../network/api/protocolApi';
import pathologyCodingApi from '../../network/api/pathologyCoding';

type Props = {
  queryParams: Object,
  dispatch: Function,
  history: Function,
  caseInfos: Object,
};

type State = {
  patientEmergency: Object,
  modalEmergency: boolean,
  modalTechnicalProblem: boolean,
  modalAssign: boolean,
  newUsername: string,
  sideDrawerOpen: boolean,
  sideDrawerOpenMedicalProfile: boolean,
  case: Object,
  parentRelatedCase: Object,
  childrenRelatedCase: Object,
  countries: Array<Object>,
  icd10Suggestions: Array<String>,
  pathologyCoding: Array<any>,
  reasonToCallNonMed: Array<Object>,
  lastDocuments: Object,
  medicalPatient: Object,
  prescriptionDocuments: Object,
  medicalReportDocument: Object,
  patientDocuments: Object,
  sideDrawerOpenHistory: boolean,
  localCall: boolean,
  callForeignCountry: Object,
  isEmergencyDetected: boolean,
  relatedCaseModalIsOpen: boolean,
  patient: Object,

};

class Teleconsultation extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.refReport = React.createRef();
    this.refPrescription = React.createRef();
  }

  state = {
    patientEmergency: {},
    modalEmergency: false,
    isEmergencyDetected: false,
    modalTechnicalProblem: false,
    modalAssign: false,
    newUsername: '',
    sideDrawerOpen: false,
    sideDrawerOpenMedicalProfile: true,
    case: null,
    parentRelatedCase: null,
    childrenRelatedCase: null,
    countries: [],
    icd10Suggestions: [],
    pathologyCoding: [],
    reasonToCallNonMed: [],
    lastDocuments: {},
    prescriptionDocuments: {},
    medicalReportDocument: {},
    anchor: true,
    patientDocuments: {},
    caseHistory: [],
    caseDefaultHistory: [],
    sideDrawerOpenHistory: false,
    medicalPatient: {},
    localCall: false,
    callForeignCountry: null,
    relatedCaseModalIsOpen: false,
    patient: null,
  };

  onSubmit = () => {
    caseApi.setStep(this.props.queryParams.caseId, 6)
      .then(() => this.props.history.push(CASE_PROCESS_PAGE_ROUTE +
        SENDING_DOCUMENTS_PAGE_ROUTE +
        createSearchFromParams(this.props.queryParams)))
      .catch(e => console.log('Erreur apppel API', e));
  };

  drawerToggleClickHandle = () => {
    this.setState({
      sideDrawerOpen: !this.state.sideDrawerOpen,
    });
  };

  drawerToggleClickHandleMedicalProfile = () => {
    this.setState({
      sideDrawerOpenMedicalProfile: !this.state.sideDrawerOpenMedicalProfile,
    });
  };

  fetchCountries = () => new Promise(resolve => referentialApi
    .getAllCountries()
    .then(response => response.json())
    .then((countries) => {
      this.setState({ countries });
      resolve(countries);
    })
    .catch(this.props.dispatch(notifyError)));

  searchIcd10 = (value) => {
    if (!value || !value.length) {
      return;
    }

    caseApi.fetchIcd10List(value)
      .then(response => response.json())
      .then(icd10 => this.setState({ icd10Suggestions: icd10.map(ic => ({ value: ic.id, label: ic.name })) }))
      .catch(this.props.dispatch(notifyError));
  };

  debouncedIcd10Search = debounce(this.searchIcd10, 300);

  isForeignCall = (countryCalling: number, countries: Array<any>) => {
    const countrySelected = countries.filter(c => c.id === countryCalling)[0];
    if (countrySelected && countrySelected.isoCode.toUpperCase() === currentSessionService().currentUser().businessUnitCountryCode.toUpperCase()) {
      this.setState({ localCall: true });
    } else {
      this.setState({ localCall: false });
    }
    this.setState({ callForeignCountry: countrySelected || null });
  };

  componentDidMount() {
    Promise.all([
      this.fetchCaseInformation(),
      this.fetchCountries()])
      .then((values) => {
        this.isForeignCall(+values[0].countryCalling, values[1]);
      })
      .catch(e => console.log('Erreur apppel API', e));

    pathologyCodingApi.fetchPathologyCodingList()
      .then(response => response.json())
      .then(pathologyCoding => this.setState({
        pathologyCoding: pathologyCoding.map(code => ({
          value: code.id,
          label: code.name,
        })),
      }))
      .catch(this.props.dispatch(notifyError));

    this.getInformationOfPatient(this.props.queryParams.patientId);

    patientApi.fetchById(this.props.queryParams.patientId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((patient) => {
        this.setState({ patient });
        this.fetchDocuments(patient);
      })
      .catch(e => console.log('Erreur apppel API', e));

    processCaseApi
      .fetchCallQualificationCode()
      .then(response => response.json())
      .then(reasonToCallNonMed => this.setState({
        reasonToCallNonMed: reasonToCallNonMed.map(r => ({
          id: r.id,
          label: I18n.t(`reasonToCallNonMed.${r.code}`),
        })),
      }))
      .catch(this.props.dispatch(notifyError));

    caseReportApi
      .getDocumentByCaseId(this.props.queryParams.caseId).then(response => response.text()).then(text => (text.length ? JSON.parse(text) : null))
      .then(responseJson =>
        this.setState({
          prescriptionDocuments: responseJson && responseJson.prescriptionList,
          patientDocuments: responseJson && responseJson.patientSendList,
          medicalReportDocument: responseJson && responseJson.medicalReportList,
        }))
      .catch(this.props.dispatch(notifyError));

    caseApi.checkTakenInCharge(this.props.queryParams.caseId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then((changeInfos) => {
        if (changeInfos.change) {
          this.setState({ modalAssign: true, newUsername: changeInfos.newUserName });
        }
      })
      .catch(this.props.dispatch(notifyError));
  }

  fetchDocuments = (patient) => {
    patientApi
      .getLastDocuments(
        this.props.queryParams.patientId,
        patient.protocolNumber || this.props.queryParams.protocolNumber,
      )
      .then(response => response.json())
      .then(lastDocuments => this.setState({ lastDocuments }))
      .catch(this.props.dispatch(notifyError));
  };


  fetchCaseInformation = () => new Promise(resolve => caseApi.fetchCaseForTeleconsultationById(this.props.queryParams.caseId)
    .then(response => response.json())
    .then((responseJson) => {
      this.setState({
        case: {
          ...responseJson.actualCase,
          icd10: responseJson.icd10List.map(icd10 => ({ value: icd10.id, label: icd10.name })),
        },
        parentRelatedCase: { ...responseJson.parentRelatedCase },
        childrenRelatedCase: { ...responseJson.childrenRelatedCase },
        isEmergencyDetected: responseJson.actualCase.emergencyDetected,
      });
      resolve(responseJson.actualCase);
    })
    .catch(this.props.dispatch(notifyError)));

  getInformationOfPatient = (id: string) => {
    patientApi
      .getPatientMedicalProfile(id)
      .then(response => response.json())
      .then(responseJson => this.setState({
        medicalPatient: responseJson || {},
      }))
      .catch(this.props.dispatch(notifyError));
  };

  drawerToggleHistory = () => {
    this.setState({
      sideDrawerOpenHistory: !this.state.sideDrawerOpenHistory,
    });
  };

  getAge = (birthday: string) => {
    const birthdate = new Date(birthday);
    const cur = new Date();
    const diff = cur - birthdate; // This is the difference in milliseconds
    return Math.floor(diff / 31557600000);
  };

  save = async (field, oldValue, newValue) => {
    if (field === 'icd10') {
      caseApi.checkTakenInCharge(this.props.queryParams.caseId)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : {}))
        .then((changeInfos) => {
          if (changeInfos.change) {
            this.setState({ modalAssign: true, newUsername: changeInfos.newUserName });
          } else {
            caseApi.saveIcd10(this.props.queryParams.caseId, this.props.queryParams.patientId, {
              oldValue: Array.isArray(oldValue) ? oldValue.map(icd10value => icd10value.value) : [],
              newValue: newValue ? newValue.map(icd10value => icd10value.value) : [],
            });
          }
        })
        .catch(this.props.dispatch(notifyError));
    } else {
      await caseApi.checkTakenInCharge(this.props.queryParams.caseId)
        .then(response => response.text())
        .then(text => (text.length ? JSON.parse(text) : {}))
        .then((changeInfos) => {
          if (changeInfos.change) {
            this.setState({ modalAssign: true, newUsername: changeInfos.newUserName });
          } else {
            caseApi.updateCaseField(this.props.queryParams.caseId, this.props.queryParams.patientId, field, {
              oldValue,
              newValue,
            });
          }
        })
        .catch(e => console.log('Erreur apppel API', e));
    }
  };

  searchKeyWordHistory = value => this.setState({
    caseHistory: value ? this.state.caseDefaultHistory.filter(modification =>
      !!modification.userFirstName && modification.userFirstName.toLowerCase().includes(value.toLowerCase())
      || !!modification.userLastName && modification.userLastName.toLowerCase().includes(value.toLowerCase())
      || !!modification.field && modification.field.toLowerCase().includes(value.toLowerCase())
      || !!modification.oldValue && modification.oldValue.toLowerCase().includes(value.toLowerCase())
      || !!modification.newValue && modification.newValue.toLowerCase().includes(value.toLowerCase())
      || !!modification.message && modification.message.toLowerCase().includes(value.toLowerCase())
      || !!modification.modificationType && modification.modificationType.toLowerCase().includes(value.toLowerCase())
      || !!modification.eventDate && modification.eventDate.toLowerCase().includes(value.toLowerCase())) : this.state.caseDefaultHistory,
  });

  deletePrescription = (idPrescription) => {
    prescriptionApi.deletePrescription(this.props.queryParams.caseId, idPrescription)
      .catch(this.props.dispatch(notifyError));
    this.setState({
      prescriptionDocuments: this.state.prescriptionDocuments
        .filter(prescription => prescription.id !== idPrescription),
    });
  };

  getCaseHistory = () => {
    caseApi.getCaseHistory(this.props.queryParams.caseId)
      .then(response => response.json())
      .then(caseHistory => this.setState({
        caseHistory: caseHistory.map(value => ({
          ...value,
          eventDate: moment(value.eventDate).format('DD/MM/YYYY HH:mm'),
        })),
        caseDefaultHistory: caseHistory.map(value => ({
          ...value,
          eventDate: moment(value.eventDate).format('DD/MM/YYYY HH:mm'),
        })),
      }))
      .catch(this.props.dispatch(notifyError));
  };

  deleteMedicalReport = (idMedicalReport) => {
    caseReportApi.deleteCaseReport(idMedicalReport)
      .catch(this.props.dispatch(notifyError));
    this.setState({
      medicalReportDocument: this.state.medicalReportDocument
        .filter(medicalReport => medicalReport.id !== idMedicalReport),
    });
  };

  scrollTo = () => {
    // TODO scroll prend trop de temps à se faire au chargement de la page
    // {this.props.history.location.state === "medical" && this.refReport.current.scrollIntoView()}
    // {this.props.history.location.state === "prescription" && this.refPrescription.current.scrollIntoView()}
    this.setState({ anchor: false });
  };

  toggleRelatedCaseModal = (toggle: boolean) => {
    this.setState({ relatedCaseModalIsOpen: toggle });
  };

  render() {
    {
      this.state.anchor && (this.refPrescription.current || this.refReport.current) && this.scrollTo();
    }
    return (
      <div className="teleconsultation">
        <ModalAssigned
          modal={this.props.caseInfos && this.props.caseInfos.isEditable ? this.state.modalAssign : false}
          toggle={() => this.setState({ modalAssign: !this.state.modalAssign })}
          newUsername={this.state.newUsername}
        />
        <CreateRelatedCaseModal
          visible={this.state.relatedCaseModalIsOpen}
          toggle={() => this.toggleRelatedCaseModal(false)}
          patientId={this.props.queryParams.patientId}
          caseId={this.props.queryParams.caseId}
        />
        <header>
          <div>
            <Button
              color="secondary"
              onClick={() => {
                this.getCaseHistory();
                this.drawerToggleHistory();
              }}
            >
              <i className="fa fa-history" />
              <span>{I18n.t('qualification.viewHistory')}</span>
            </Button>
          </div>
          <div>
            <span className="teleconsultation-title">
              <i className="fas fa-stethoscope" />
              {I18n.t('steps.TELECONSULTATION')}
            </span>
          </div>
          <div>
            <Button
              color="secondary"
              className={`emergency-button ${this.state.isEmergencyDetected ? 'activated' : ''}`}
              onClick={() => this.setState({ modalEmergency: !this.state.modalEmergency })}
            >
              <i className="fal fa-exclamation-triangle " />
              <span>{I18n.t('teleconsultation.EMERGENCY')}</span>
            </Button>
            <Button
              color="secondary"
              onClick={() => this.setState({ modalTechnicalProblem: !this.state.modalTechnicalProblem })}
            >
              <i className="fal fa-tools " />
              <span>{I18n.t('qualification.technicalProblem')}</span>
            </Button>
          </div>
        </header>
        <CoreozDrawer show={this.state.sideDrawerOpen} side={false}>
          <ListLastDocuments
            patientId={this.props.queryParams.patientId}
            goBack={this.drawerToggleClickHandle}
            lastDocuments={this.state.lastDocuments.lastDocumentsBean}
            nbTeleconsult={this.state.lastDocuments.nbTeleconsult}
            nbTeleadvice={this.state.lastDocuments.nbTeleadvice}
            nbMaxOfTeleconsult={this.state.lastDocuments.nbOfMaxTeleconsult}
          />
        </CoreozDrawer>
        <CoreozDrawer show={this.state.sideDrawerOpenHistory} side={false}>
          <HistoryModification
            caseId={this.props.queryParams.caseId}
            goBack={this.drawerToggleHistory}
            caseHistory={groupHistoryByDateAndIdUser(this.state.caseHistory)}
            searchKeyWordHistory={this.searchKeyWordHistory}
          />
        </CoreozDrawer>
        <div className="teleconsultation-container">
          <MedicalProfile
            drawerToggleClickHandle={this.drawerToggleClickHandle}
            patient={this.state.medicalPatient}
            documents={this.state.lastDocuments}
            queryParams={this.props.queryParams}
          />
          <CoreozDrawer show={this.state.sideDrawerOpenMedicalProfile} side>
            <MedicalProfile
              drawerToggleClickHandle={this.drawerToggleClickHandleMedicalProfile}
              patient={this.state.medicalPatient}
              documents={this.state.lastDocuments}
              queryParams={this.props.queryParams}
            />
          </CoreozDrawer>
          {
            this.state.case ?
              <div className="teleconsultation-content">
                <ModalTechnicalProblem
                  modal={this.state.modalTechnicalProblem}
                  toggle={() =>
                    this.setState({ modalTechnicalProblem: !this.state.modalTechnicalProblem })}
                  case={this.state.case}
                  fetchCase={() => this.fetchCaseInformation()}
                />
                <ModalEmergency
                  modal={this.state.modalEmergency}
                  patient={this.state.patientEmergency}
                  case={this.state.case}
                  callingCountry={this.state.callForeignCountry}
                  toggle={() => this.setState({ modalEmergency: !this.state.modalEmergency })}
                  emergencyIsDetected={() => this.setState({ isEmergencyDetected: true })}
                />
                {(this.state.case.reasonForTheCall || this.state.case.adviceNote)
                && currentSessionService().currentUser().showTeleAdviceReport &&
                <Summary
                  summaryTitle={I18n.t('teleconsultation.QUALIFICATION_REPORT')}
                  reasonForTheCall={this.state.case.reasonForTheCall}
                  teleAdviceNotes={this.state.case.adviceNote}
                  // symptomCheckFlag={} //TODO : No case is saved from triage in the dashboard yet
                />
                }

                {/* RelatedCase Parent */}
                {(this.state.parentRelatedCase.reasonForTheCall || this.state.parentRelatedCase.adviceNote
                  || this.state.parentRelatedCase.teleconsultNotes || this.state.parentRelatedCase.documents) &&
                  <Summary
                    summaryTitle={I18n.t('teleconsultation.relatedCase.titleParent')}
                    reasonForTheCall={this.state.parentRelatedCase.reasonForTheCall}
                    teleAdviceNotes={this.state.parentRelatedCase.adviceNote}
                    teleConsultationNotes={this.state.parentRelatedCase.teleconsultNotes}
                    documents={this.state.parentRelatedCase.documents}
                  />
                }

                {/* RelatedCase Children */}
                {Object.keys(this.state.childrenRelatedCase).length > 0 && (this.state.childrenRelatedCase[0].reasonForTheCall || this.state.childrenRelatedCase[0].adviceNote
                  || this.state.childrenRelatedCase[0].teleconsultNotes || this.state.childrenRelatedCase[0].documents) &&
                  <Summary
                    summaryTitle={I18n.t('teleconsultation.relatedCase.titleChildren')}
                    childrenList={this.state.childrenRelatedCase}
                  />
                }
                <Form
                  onSubmit={this.onSubmit}
                  initialValues={this.state.case}
                  mutators={{ setFieldData }}
                  render={({
                             handleSubmit, values, form, invalid,
                           }) => (
                             <form onSubmit={handleSubmit}>
                               <AutoSave
                                 setFieldData={form.mutators.setFieldData}
                                 save={this.save}
                                 ignoreFields={['orientation', 'nbTeleconsultOn12Months', 'isTechnicalIssue', 'technicalIssueText']}
                               />
                               {this.getAge(this.state.medicalPatient.birthDay) < 18 &&
                               <div className="under-18">
                                 {I18n.t('processCase.UNDER_18')}
                               </div>
                      }
                               <div className="teleconsultation-category ">
                                 {!currentSessionService().currentUser().showTeleAdviceReport &&
                                 <div className="reason-for-the-call">
                                   <div className="category-subtitle">{I18n.t('qualification.reason')}</div>
                                   <Field
                                     component={ResizableTextarea}
                                     className="total-width"
                                     minrows={2}
                                     maxrows={10}
                                     name="reasonForTheCall"
                                     disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                                   />
                                 </div>
                        }
                                 {this.state.patientDocuments.length > 0 &&
                                 <div>
                                   <div
                                     className="category-subtitle can-be-activate"
                                     onClick={() => this.setState({ sideDrawerOpen: true })}
                                   >
                                     {I18n.t('qualification.NEW_DOCUMENT_FROM_PDA')} <i className="fa fa-paperclip" />
                                   </div>
                                 </div>}
                                 <div className="call-specification">
                                   <Field
                                     label={I18n.t('teleconsultation.COUNTRY_OF_CALL')}
                                     className="select-country"
                                     component={CoreozSelect}
                                     name="countryCalling"
                                     list={this.state.countries}
                                     onChange={countryId => this.isForeignCall(+countryId, this.state.countries)}
                                     required
                                     disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                                   />
                                   <Field
                                     label={I18n.t('teleconsultation.TYPE_OF_CALL')}
                                     className="select-call-type"
                                     component={CoreozSelect}
                                     name="reasonToCallNonMed"
                                     list={this.state.reasonToCallNonMed}
                                     required
                                     disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                                   />
                                 </div>
                               </div>
                               {!this.state.localCall &&
                               <div className="foreign-call">
                                 {I18n.t('processCase.FOREIGN_CALL')}
                               </div>
                      }
                               <div className="teleconsultation-category teleconsultation-notes">
                                 <div className="category-title">{I18n.t('teleconsultation.TELECONSULTATION_NOTES')}
                                   <span className="category-subtitle">({I18n.t('teleconsultation.SHARED')})</span>
                                 </div>
                                 <Field
                                   className="total-width"
                                   component={ResizableTextarea}
                                   minrows={10}
                                   maxrows={30}
                                   name="teleconsultNotes"
                                   placeholder={I18n.t('teleconsultation.PLACE_HOLDER')}
                                   disabled={(this.props.caseInfos && !this.props.caseInfos.isEditable) || (currentSessionService().currentUser().userRole === Roles.NURSE)}
                                 />
                               </div>
                               <div className="teleconsultation-category doctor-notes">
                                 <div className="category-title">{I18n.t('teleconsultation.DOCTOR_NOTES')} <span
                                   className="category-subtitle"
                                 >({I18n.t('teleconsultation.NOT_SHARED')})
                                 </span>
                                 </div>
                                 <Field
                                   className="total-width"
                                   component={ResizableTextarea}
                                   minrows={5}
                                   maxrows={30}
                                   name="doctorNotes"
                                   disabled={(this.props.caseInfos && !this.props.caseInfos.isEditable) || (currentSessionService().currentUser().userRole === Roles.NURSE)}
                                 />
                               </div>
                               <div className="empty-category">
                                 <div
                                   className="category-title"
                                   ref={this.refPrescription}
                                 >{I18n.t('teleconsultation.PRESCRIPTION')}
                                 </div>
                                 {this.state.prescriptionDocuments.length > 0 ?
                                   <div className="teleconsultation-document">
                                     {this.state.prescriptionDocuments.map((document, index) => (
                                       <div className="tile-document" key={`${document.name }-${ document.creationDate}`}>
                                         <div className="document-name">{document.name}</div>
                                         <div className="document-save-date">
                                           <i
                                             className="fa fa-save"
                                           />{moment(document.creationDate).format(I18n.t('date.DATE_LONG'))}
                                         </div>
                                         <div className="document-action">
                                           <a
                                             className={`${((this.props.caseInfos && !this.props.caseInfos.isEditable) || (currentSessionService().currentUser().userRole === Roles.NURSE)) ? 'link-disabled' : ''}`}
                                             onClick={() => !((this.props.caseInfos && !this.props.caseInfos.isEditable) || (currentSessionService().currentUser().userRole === Roles.NURSE)) &&
                                      this.props.history.push(CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + PRESCRIPTION_PAGE_ROUTE
                                        + createSearchFromParams({
                                          ...this.props.queryParams,
                                            documentId: document.id,
                                        }))}
                                           >{I18n.t('button.EDIT')}
                                           </a>
                                           <i
                                             className={`fa fa-trash ${((this.props.caseInfos && !this.props.caseInfos.isEditable) || (currentSessionService().currentUser().userRole === Roles.NURSE)) ? 'link-disabled' : ''}`}
                                             onClick={() => !((this.props.caseInfos && !this.props.caseInfos.isEditable) || (currentSessionService().currentUser().userRole === Roles.NURSE)) && this.deletePrescription(document.id)}
                                           />
                                         </div>
                                       </div>
                            ))}
                                     <Button
                                       type="button"
                                       color="primary"
                                       onClick={() =>
                                this.props.history.push(CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + PRESCRIPTION_PAGE_ROUTE
                                  + createSearchFromParams({ ...this.props.queryParams, documentId: null }))}
                                       disabled={(this.props.caseInfos && !this.props.caseInfos.isEditable) || (currentSessionService().currentUser().userRole === Roles.NURSE)}
                                     >
                                       <i className="fal fa-plus" />
                                       <span>{I18n.t('teleconsultation.ADD_PRESCRIPTION')}</span>
                                     </Button>
                                   </div>
                          :
                                   <div className="empty-box">
                                     <i className="fa fa-pills empty-box-icon" />
                                     <div className="empty-box-title">{I18n.t('teleconsultation.NO_PRESCRIPTION')}</div>
                                     <div>
                                       <Button
                                         color="primary"
                                         onClick={() =>
                                  this.props.history.push(CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + PRESCRIPTION_PAGE_ROUTE
                                    + createSearchFromParams({ ...this.props.queryParams, documentId: null }))}
                                         disabled={(this.props.caseInfos && !this.props.caseInfos.isEditable) || (currentSessionService().currentUser().userRole === Roles.NURSE)}
                                       >
                                         <i className="fal fa-plus" />
                                         <span>{I18n.t('teleconsultation.ADD_PRESCRIPTION')}</span>
                                       </Button>
                                     </div>
                                   </div>
                        }
                               </div>
                               <div className="teleconsultation-category">
                                 <div className="category-title">{I18n.t('teleconsultation.CODING')}</div>
                                 <div className="teleconsultation-coding">
                                   <div className="ICD10-coding">
                                     <div
                                       className={`${currentSessionService().currentUser().makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories ? '' : 'title-required'}`}
                                     >{I18n.t('teleconsultation.ICD10_CODING')}
                                     </div>
                                     <Field
                                       component={SelectMultiAutoComplete}
                                       name="icd10"
                                       defaultValues={this.state.case.icd10}
                                       options={this.state.icd10Suggestions}
                                       onInputChange={this.debouncedIcd10Search}
                                       noOptionsMessage={() => I18n.t('teleconsultation.ENTER_ICD10')}
                                       validate={currentSessionService().currentUser().makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories ? undefined : validateListNotEmpty}
                                       disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                                     />
                                   </div>
                                   <div className="local-coding">
                                     {I18n.t('teleconsultation.LOCAL_CODING')}
                                     <Field
                                       component={CoreozSelectAutocomplete}
                                       name="pathologyCodingId"
                                       placeholder=""
                                       options={this.state.pathologyCoding}
                                       format={v => this.state.pathologyCoding.filter(o => o.value === v)[0]}
                                       parse={v => v.value}
                                       disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                                     />
                                   </div>
                                 </div>
                               </div>
                               <div className="empty-category">
                                 <div className="category-title">{I18n.t('teleconsultation.FINAL_ORIENTATION')}</div>
                                 <div className="category-subtitle">{I18n.t('teleconsultation.FINAL_ORIENTATION_PHRASE')}</div>
                                 <div className="final-orientation">
                                   <div className="orientation-box">
                                     <i className="fa fa-comments empty-box-icon" />
                                     <div className="empty-box-title">{I18n.t('teleconsultation.END_CONSULTATION')}</div>
                                     <Field
                                       className="radio-input"
                                       component="input"
                                       type="radio"
                                       name="medicalExam"
                                       value="false"
                                       onClick={() => {
                                form.change('orientation', null);
                              }}
                                       disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                                     />
                                   </div>
                                   <div className="orientation-box">
                                     <i className="fa fa-stethoscope empty-box-icon" />
                                     <div className="empty-box-title">{I18n.t('teleconsultation.PHYSICAL_EXAM')}</div>
                                     <Field
                                       className="radio-input"
                                       component="input"
                                       type="radio"
                                       name="medicalExam"
                                       value="true"
                                       onClick={() => {
                                form.change('medicalExam', null);
                              }}
                                       disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                                     />
                                   </div>
                                   <div className="orientation-box">
                                     <i className="fa fa-link empty-box-icon" />
                                     <div className="empty-box-title">{I18n.t('teleconsultation.CREATE_RELATED_CASE')}</div>
                                     <Field
                                       className="radio-input"
                                       component="input"
                                       name="orientation"
                                       type="radio"
                                       value="related-case"
                                       onClick={() => {
                                this.toggleRelatedCaseModal(true);
                                form.change('orientation', 'true');
                              }}
                                       disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                                     />
                                   </div>
                                 </div>
                                 {values.medicalExam === 'true' &&
                                 <div className="medical-exam">
                                   <div className="title">{I18n.t('teleconsultation.PHYSICAL_EXAM_TEXT')}</div>
                                   <Field
                                     className="total-width"
                                     component={ResizableTextarea}
                                     name="medicalExamInstructions"
                                     minrows={3}
                                     maxrows={10}
                                     disabled={this.props.caseInfos && !this.props.caseInfos.isEditable}
                                   />
                                 </div>
                        }
                               </div>
                               <ShowAndEditDocument
                                 queryParams={this.props.queryParams}
                                 title={I18n.t('teleconsultation.MEDICAL_REPORT')}
                                 case={this.state.case}
                                 disabled={(this.props.caseInfos && !this.props.caseInfos.isEditable) || (currentSessionService().currentUser().userRole === Roles.NURSE)}
                                 documents={this.state.medicalReportDocument}
                                 previewRoute={CASE_PROCESS_PAGE_ROUTE + TELECONSULTATION_PAGE_ROUTE + MED_REPORT_PAGE_ROUTE}
                                 NO_REPORT={I18n.t('teleconsultation.NO_REPORT')}
                                 ADD_REPORT={I18n.t('teleconsultation.ADD_REPORT')}
                                 delete={this.deleteMedicalReport}
                               />
                               {invalid && !currentSessionService().currentUser().makeDiagnosticCodingAndIcd10CodingFieldsNotMandatories &&
                               <div className="warning">
                                 <span className="warning-text">{I18n.t('teleconsultation.MISSING_ICD10')}</span>
                               </div>}
                               {((this.state.case.isFamilyDocConsent || currentSessionService().currentUser().showAlertWhenNoMedicalReport)
                        && this.state.medicalReportDocument.length === 0) &&
                        <div className="warning">
                          {currentSessionService().currentUser().showAlertWhenNoMedicalReport ?
                            <span className="warning-text">{I18n.t('teleconsultation.MISSING_MEDICAL_REPORT')}</span>
                          :
                            <span className="warning-text">{I18n.t('teleconsultation.MISSING_DOCUMENT_FOR_FAMILY_DOCTOR')}</span>
                        }
                        </div>}
                               <div className="submit-button">
                                 <Button
                                   className="validation-button"
                                   color="primary"
                                   type="submit"
                                   disabled={invalid
                          || !this.props.autoSave
                          || ((this.state.case.isFamilyDocConsent || currentSessionService().currentUser().showAlertWhenNoMedicalReport) && this.state.medicalReportDocument.length === 0)
                          || (this.props.caseInfos && !this.props.caseInfos.isEditable)}
                                 >
                                   <i className="button-icon fa fa-arrow-circle-right" />{I18n.t('button.CONTINUE')}
                                 </Button>
                               </div>
                             </form>)}
                />
              </div>
              :
              <div className="loader-container">
                <Loader />
              </div>
          }
          <ContactSideBar
            patientId={this.props.queryParams.patientId}
            caseId={this.props.queryParams.caseId}
            drawerToggleClickHandle={this.drawerToggleClickHandleMedicalProfile}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  autoSave: state.process.autoSave,
  caseInfos: state.process.caseInfos,
}))(Teleconsultation));
