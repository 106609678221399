// @flow

import type { BusinessUnit } from '../../types/BusinessUnitTypes';
import { reduce } from '../../network/reduce';
import { currentLanguage } from '../../services/languages';

export const BUSINESSUNITS_ = 'BUSINESSUNITS_';
export const SEARCH_RESULTS_BUSINESSUNIT = 'SEARCH_RESULTS_BUSINESSUNIT';
export const LANGUAGE = 'LANGUAGE';

export type BusinessUnitStateType = {
  businessUnits: BusinessUnit[],
  searchResultsBusinessUnit: BusinessUnit[],
  language: {
    code: string,
    imgPath: string,
  },
}

const initialState: BusinessUnitStateType = {
  businessUnits: [],
  searchResultsBusinessUnit: [],
  language: currentLanguage(),
};

const businessUnitReducer = reduce(initialState, {
  [BUSINESSUNITS_]: 'businessUnits',
  [SEARCH_RESULTS_BUSINESSUNIT]: 'searchResultsBusinessUnit',
  [LANGUAGE]: 'language',
});

export default businessUnitReducer;
