// @flow

import React from 'react';
import CreatableSelect from 'react-select/creatable';

type Props = {
  className?: string,
  disabled?: boolean,
  options: Array<any>,
  defaultValues?: Array<any>,
  noOptionsMessage: Function
}

class CreatableMultiSelect extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    disabled: false,
    defaultValues: [],
  };

  handleChange = (newValue: any, actionMeta: any) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  render() {
    return (
      <div className="field-render">
        <CreatableSelect
          isMulti
          className={this.props.className}
          isDisabled={this.props.disabled}
          onChange={this.handleChange}
          options={this.props.options}
          defaultValue={this.props.defaultValues}
          noOptionsMessage={this.props.noOptionsMessage}
          {...this.props}
          {...this.props.input}
        />
      </div>
    );
  }
}

export default CreatableMultiSelect;
