import React from 'react';
import Col from 'reactstrap/es/Col';
import { I18n } from 'react-redux-i18n';
import MedicalResourceShort from '../../components/contact-side-bar/components/MedicalResourceShort';
import resourcesApi from '../../network/api/resourcesApi';
import { MedicalRessourcesLinks } from '../../enum';
import { notifyError } from '../../network/notification';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

type Props = {
  dispatch: Function,
  closedSelf: Function,
};

class MedicalResourcesPage extends React.Component<Props, State> {
  state = {
    linksMedication: null,
    linksMedical: null,
    linksVarious: null,
    linksTravelers: null,
  };

  componentDidMount() {
    resourcesApi.getResources()
      .then(response => response.json())
      .then((resources) => {
        const linksMedication = resources.filter(link => link.section === MedicalRessourcesLinks.MEDICATION);
        const linksMedical = resources.filter(link => link.section === MedicalRessourcesLinks.MEDICAL_SEARCH);
        const linksVarious = resources.filter(link => link.section === MedicalRessourcesLinks.VARIOUS);
        const linksTravelers = resources.filter(link => link.section === MedicalRessourcesLinks.TRAVELERS);
        this.setState({
          linksMedication, linksMedical, linksVarious, linksTravelers,
        });
      })
      .catch(this.props.dispatch(notifyError));
  }

  render() {
    if (this.state.linksMedication == null) {
      return null;
    }
    return (
      <div className="dashboard-container">
        <div className="title-container">
          <div className="title">
            {I18n.t('contact.medicalResources.TITLE')}
          </div>
        </div>
        <div className="medical-resources-links">
          <Col>
            <MedicalResourceShort
              icone="fa-stethoscope"
              label={I18n.t('contact.medicalResources.MEDICAL_SEARCH')}
              links={this.state.linksMedical}
            />
            <MedicalResourceShort
              icone="fa-notes-medical"
              label={I18n.t('contact.medicalResources.VARIOUS')}
              links={this.state.linksVarious}
            />
          </Col>
          <Col>
            <MedicalResourceShort
              icone="fa-plane"
              label={I18n.t('contact.medicalResources.TRAVELLERS')}
              links={this.state.linksTravelers}
            />
            <MedicalResourceShort
              icone="fa-pills"
              label={I18n.t('contact.medicalResources.MEDICATION')}
              links={this.state.linksMedication}
            />
          </Col>
        </div>
      </div>
    );
  }
}
export default withRouter(connect()(MedicalResourcesPage));
