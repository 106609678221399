// @flow

import { RestClientAuthenticated } from '../network';
import type { Role } from '../../types/RolesTypes';

const authorizeApi = {
  fetch: () =>
    new RestClientAuthenticated('/authorize/permissions', 'GET').execute(),
  save: (role: Role) =>
    new RestClientAuthenticated('/authorize/permissions', 'POST').jsonBody(role).execute(),
  delete: (roleId: $PropertyType<Role, 'id'>) =>
    new RestClientAuthenticated(`/authorize/role/${roleId}`, 'DELETE').execute(),
  fetchRoles: () => new RestClientAuthenticated('/authorize/roles', 'GET').execute(),
};

export default authorizeApi;
