import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase as CoreozInput, CoreozSelectBase as CoreozSelect } from '../../../lib/coreoz-form-base/index';
import patientApi from '../../../network/api/patientApi';
import referentialApi from '../../../network/api/referentialApi';
import { notifyError } from '../../../network/notification';
import { Language } from '../../../enum';
import { currentSessionService } from '../../../services/sessionServiceInstance';
import notificationApi from '../../../network/api/notificationApi';
import { validatePhone } from '../../../validator';
import pharmacyApi from "../../../network/api/pharmaciesApi";

type Props = {
  caseId: string,
  patientId: string,
  documentToSend: Object,
  toggle: Function,
  updateDocuments: Function,
  caseInfos: Object,
}
type State = {
  patient: Object,
  displaySearch: boolean,
  displayAdd: boolean,
  loading: boolean,
}

class SendToPharmacie extends React.Component<Props, State> {
  state = {
    /*TODO   le mieux est de nommer les étapes, c'est plus claire que 1 ou 2. Par exemple send-selection et send-option.*/
    steps: 1,
    displaySearch: false,
    displayAdd: true,
    countries: [],
    userCountry: {},
    patient: {},
    pharmacies: null,
    loading: false,
    pharmacyToSave: null,
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    this.sendToPharmacy();
  };

  fetchPatient = () => {
    patientApi.fetchById(this.props.patientId)
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .then(patient => this.setState({ patient }))
      .catch(e => console.log('Erreur apppel API', e));
  };

  componentDidMount = () => {
    this.fetchPatient();
    this.fetchCountries()
      .then(countries => {
          this.setState({
            allCountries: countries,
            countries,
            userCountry: countries.filter(c => c.isoCode && c.isoCode.toUpperCase() === currentSessionService().currentUser().businessUnitCountryCode.toUpperCase())[0],
          })
        }
      )
      .catch(e => console.log('Erreur apppel API', e));
  };

  sendToPharmacy = () => {
    if (!!this.props.documentToSend) {
      this.props.documentToSend
        .then((document) => {
          notificationApi
            .sendFax({
              document,
              pharmacy: this.state.pharmacyToSave,
              caseId: this.props.caseId,
            })
            .then(() => this.setState({ loading: false }, () => {
              this.props.toggle();
              this.props.updateDocuments();
            }))
            .catch(this.props.dispatch(notifyError));
        })
        .catch(e => console.log('Erreur apppel API', e));
    }
  };

  searchPharmacy = (values) => {
    pharmacyApi
      .searchPharmacyWithFaxNumber({
        name: values.name || '',
        address: values.address || '',
        city: values.city || '',
        country: values.country || '',
        zipCode: values.zipcode || '',
        needFaxNumber: true,
      })
      .then(response => response.text())
      .then(text => (text.length ? JSON.parse(text) : []))
      .then(pharmacies => this.setState({ pharmacies }))
      .catch(e => console.log('Erreur apppel API', e));
  };

  fetchCountries = () => {
    return new Promise(resolve => referentialApi
      .getAllCountries()
      .then(response => response.json())
      .then(resolve)
      .catch(this.props.dispatch(notifyError)));
  };

  savePharmacy = (values) => {
    if (!this.state.displayAdd) {
      pharmacyApi.addPharmacy({
        name: values.namePharmacy || '',
        address: values.addressPharmacy || '',
        city: values.cityPharmacy || '',
        country: values.countryPharmacy || '',
        faxNumber: values.faxPharmacy || '',
        postalCode: values.zipcodePharmacy || '',
      }).then(response => response.json())
        .then(pharmacyToSave => this.setState({ steps: 3, pharmacyToSave }))
        .catch(this.props.dispatch(notifyError));
    } else {
      this.setState({ steps: 3 });
    }
  };

  goToSecondStep = (values, form) => {
    if (this.props.caseInfos && this.props.caseInfos.isEditable) {
      if (form.getState().modified.email) {
        patientApi.saveField(null, this.state.patient.id, 'email', {
          oldValue: form.getState().initialValues.email,
          newValue: values.email,
        });
      }
      if (form.getState().modified.phone) {
        patientApi.saveField(null, this.state.patient.id, 'mobilePhone', {
          oldValue: form.getState().initialValues.phone,
          newValue: values.phone,
        });
      }
      this.setState({ steps: 2 });
    }
  };

  getCountryNamebyId = (countryId: string) => {
    return this.state.countries && !!this.state.countries.filter(country => country.id === countryId)[0] && this.state.countries.filter(country => country.id === countryId)[0].label;
  };

  searchOnGoogle = (values) => {
    const url = `https://www.google.com/search?q=${I18n.t('documentsToSend.sendToPharmacy.pharmacyResearch')}`
      .concat(
        ` ${values.namePharmacy ? values.namePharmacy : ''}`,
        ` ${values.faxPharmacy ? values.faxPharmacy : ''}`,
        ` ${values.cityPharmacy ? values.cityPharmacy : ''}`,
        ` ${this.getCountryNamebyId(values.countryPharmacy) ? this.getCountryNamebyId(values.countryPharmacy) : ''}`,
      );
    window.open(url);
  };

  isNotEditable = () => this.props.caseInfos && !this.props.caseInfos.isEditable;

  render() {
    return (
      <div className="send-to-pharmacie">
        <Form
          onSubmit={this.handleSubmit}
          initialValues={{
            email: this.state.patient && this.state.patient.email,
            phone: this.state.patient && this.state.patient.mobilePhone,
            patientHaveNoAdressMail: this.state.patient && (this.state.patient.noEmailAddress === 'true'),
            country: this.state.userCountry && this.state.userCountry.id,
            countryPharmacy: this.state.userCountry && this.state.userCountry.id,
          }}
          render={({ handleSubmit, values, form }) => (
            <form
              className="identification-form"
              onSubmit={handleSubmit}
            >
              {this.state.steps === 1 &&
              <div className="send-to-pharmacie-step-container">
                <div className="documents-send-destination">{I18n.t('documentsToSend.sendToPharmacy.subtitle')}</div>
                <div>
                  <span
                    className="documents-send-destination">{I18n.t('documentsToSend.sendToPharmacy.authorize')}</span>
                  <div className="radios-button-line">
                    <label>
                      <Field
                        className="radio-input"
                        component="input"
                        name="faxConsent"
                        type="radio"
                        value="true"
                        format={value => String(value)}
                        parse={value => value === 'true'}
                        disabled={this.isNotEditable()}
                      />
                      <div className="radio-label">{I18n.t('form.YES')}</div>
                    </label>
                    <label>
                      <Field
                        className="radio-input"
                        component="input"
                        name="faxConsent"
                        type="radio"
                        value="false"
                        format={value => String(value)}
                        parse={value => value === 'true'}
                        disabled={this.isNotEditable()}
                      />
                      <div className="radio-label">{I18n.t('form.NO')}</div>
                    </label>
                  </div>
                  {!!values.faxConsent &&
                  <div>
                    <div className="subtitle-pharmacie">
                      {I18n.t(`documentsToSend.sendToPharmacy.${!!values.email ? 'phraseEmail' : 'phrasePhone'}`)}
                    </div>
                    <div className="subtitle-pharmacie">
                      {I18n.t(`documentsToSend.sendToPharmacy.warningOnModify`)}
                    </div>
                    <div className="documents-send-input">
                      {values.patientHaveNoAdressMail ?
                        <Field
                          label={I18n.t('documentsToSend.sendBySecure.phone')}
                          component={CoreozInput}
                          type="phone"
                          name="phone"
                          disabled={this.isNotEditable()}
                        />
                        :
                        <Field
                          label={I18n.t('documentsToSend.sendBySecure.email')}
                          component={CoreozInput}
                          type="email"
                          name="email"
                          disabled={this.isNotEditable()}
                        />
                      }
                    </div>
                  </div>
                  }
                </div>
                <div className="pharmacy-footer-container">
                  <button type="button" className="btn btn-secondary" onClick={this.props.toggle}>
                    <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
                  </button>

                  <button type="button" className="btn btn-primary"
                          onClick={() => this.goToSecondStep(values, form)}
                          disabled={!values.faxConsent}>
                    <i className="button-icon fas fa-paper-plane" />
                    {I18n.t('button.NEXT')}
                  </button>

                </div>

              </div>
              }
              {this.state.steps === 2 &&
              <div className="send-to-pharmacie-step-container">
                <div className="subtitle-pharmacie">{I18n.t('documentsToSend.sendToPharmacy.phase2')}</div>
                <div className="search-pharmacie-content">
                  <div className="search-section-title"
                       onClick={() => this.props.caseInfos && this.props.caseInfos.isEditable && this.setState({
                         displaySearch: !this.state.displaySearch,
                         displayAdd: this.state.displaySearch
                       })}>
                    <div className="title-section">{I18n.t('documentsToSend.sendToPharmacy.search')}</div>
                    <i className={`fal fa-chevron-${this.state.displaySearch ? 'down' : 'up'}`} />
                  </div>
                  {this.state.pharmacies && !this.state.pharmacies.length && !this.state.displaySearch &&
                  <div className="error-message">{I18n.t('documentsToSend.sendToPharmacy.pharmacySearchError')}</div>
                  }
                  <div className={`search-pharmacie-fields ${this.state.displaySearch ? 'noDisplay' : ''}`}>
                    <div>
                      <Field
                        name="name"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.name')}
                        type="text"
                        disabled={this.isNotEditable()}
                      />
                      <Field
                        name="address"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.adress')}
                        type="text"
                        disabled={this.isNotEditable()}
                      />
                    </div>
                    <div>
                      <Field
                        name="city"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.city')}
                        type="text"
                        required
                        disabled={this.isNotEditable()}
                      />
                      <Field
                        label={I18n.t('documentsToSend.sendToPharmacy.country')}
                        className="select-country"
                        component={CoreozSelect}
                        name="country"
                        list={this.state.countries}
                        required
                        disabled={this.isNotEditable()}
                      />
                    </div>
                    <div>
                      <Field
                        name="zipcode"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.zipcode')}
                        type="text"
                        disabled={this.isNotEditable()}
                      />
                      <div className="button-part">
                        <button className="btn btn-primary"
                                onClick={() => this.searchPharmacy(values)}
                                disabled={this.isNotEditable() || !values.country || !values.city}
                        >
                          {I18n.t('button.SEARCH')}
                        </button>
                      </div>
                    </div>

                  </div>
                  {this.state.pharmacies && !!this.state.pharmacies.length && !this.state.displaySearch &&
                  <div className="list-pharmacies-part">
                    <div className="title">{I18n.t('documentsToSend.sendToPharmacy.listPharmacieTitle')}</div>
                    {this.state.pharmacies.map(pharmacy =>
                      <div key={pharmacy.prmId}
                           className={`pharmacy-tile ${this.state.pharmacyToSave === pharmacy ? 'to-select' : ''}`}
                           onClick={() => this.props.caseInfos && this.props.caseInfos.isEditable && this.setState({ pharmacyToSave: pharmacy })}>
                        <div>{!!pharmacy.name && pharmacy.name}</div>
                        <div>{!!pharmacy.address && pharmacy.address}</div>
                        <div>{!!pharmacy.city && pharmacy.city}</div>
                        <div>{!!pharmacy.postalCode && pharmacy.postalCode}</div>
                        <div>{pharmacy.phoneNumber && `${I18n.t('documentsToSend.sendToPharmacy.phoneShort')} ${pharmacy.phoneNumber}`}
                          <br />
                          {pharmacy.faxNumber && `${I18n.t('documentsToSend.sendToPharmacy.faxShort')} ${pharmacy.faxNumber}`}
                        </div>
                      </div>
                    )}
                  </div>
                  }
                </div>
                <div className="add-pharmacie-content">
                  <div className="add-pharmacie-title-content"
                       onClick={() => this.props.caseInfos && this.props.caseInfos.isEditable && this.setState({
                         displayAdd: !this.state.displayAdd,
                         displaySearch: this.state.displayAdd
                       })}>
                    <div className="title-section">{I18n.t('documentsToSend.sendToPharmacy.add')}</div>
                    {!this.state.displayAdd ?
                      <div className="button-content">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => this.searchOnGoogle(values)}
                        >
                          <i className="button-icon fa fa-undo" />
                          {I18n.t('documentsToSend.sendToPharmacy.internet')}
                        </button>
                      </div>
                      :
                      <i className={'fal fa-chevron-down'} />
                    }

                  </div>
                  <div className={`add-pharmacie-fields ${this.state.displayAdd ? 'noDisplay' : ''}`}>
                    <div>
                      <Field
                        name="namePharmacy"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.name')}
                        type="text"
                        required
                        disabled={this.isNotEditable()}
                      />
                      <Field
                        name="addressPharmacy"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.adress')}
                        type="text"
                        disabled={this.isNotEditable()}
                      />
                      <Field
                        name="faxPharmacy"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.fax')}
                        type="text"
                        validate={validatePhone}
                        required
                        disabled={this.isNotEditable()}
                      />
                    </div>
                    <div>
                      <Field
                        name="cityPharmacy"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.city')}
                        type="text"
                        required
                        disabled={this.isNotEditable()}
                      />
                      <Field
                        label={I18n.t('documentsToSend.sendToPharmacy.country')}
                        className="select-country"
                        component={CoreozSelect}
                        name="countryPharmacy"
                        list={this.state.countries}
                        required
                        disabled={this.isNotEditable()}
                      />
                      <Field
                        name="zipcodePharmacy"
                        component={CoreozInput}
                        label={I18n.t('documentsToSend.sendToPharmacy.zipcode')}
                        type="text"
                        disabled={this.isNotEditable()}
                      />
                    </div>
                  </div>
                </div>
                <div className="pharmacy-footer-container">
                  <button type="button" className="btn btn-secondary" onClick={this.props.toggle}><i
                    className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}</button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.savePharmacy(values)}
                    disabled={this.isNotEditable()
                    || (this.state.displayAdd ?
                        (!this.state.pharmacyToSave)
                        :
                        (!values.namePharmacy
                          || !values.faxPharmacy
                          || !values.cityPharmacy
                          || !values.countryPharmacy
                          || validatePhone(values.faxPharmacy))
                    )}
                  >
                    <i className="button-icon fas fa-paper-plane" />
                    {I18n.t('button.NEXT')}
                  </button>
                </div>
              </div>
              }
              {this.state.steps === 3 &&
              <div>
                <div className="selection-subtitle">{I18n.t('documentsToSend.sendToPharmacy.phase3')}</div>
                <div className="selection-content">
                  {!values.patientHaveNoAdressMail ?
                    (values.email &&
                      <div className="selection-container">
                        <div className="selection-subtitle">
                          {I18n.t('documentsToSend.sendToPharmacy.emailOfPatient')}
                        </div>
                        <div className="email">{values.email}</div>
                      </div>)
                    :
                    (values.phone &&
                      <div className="selection-container">
                        <div
                          className="selection-subtitle">{I18n.t('documentsToSend.sendToPharmacy.phoneOfPatient')}</div>
                        <div className="email">{values.phone}</div>
                      </div>)
                  }
                  <div className="pharmacy-selected">
                    <div className="selection-subtitle">{I18n.t('documentsToSend.sendToPharmacy.pharmacy')}</div>
                    <div className="selection-container">
                      <div>{this.state.pharmacyToSave.name}</div>
                      <div>
                        {this.state.pharmacyToSave.address &&
                        <span>{this.state.pharmacyToSave.address.toLowerCase() + ' '}</span>}
                        {this.state.pharmacyToSave.postalCode &&
                        <span>{this.state.pharmacyToSave.postalCode + ' '}</span>
                        }
                        {this.state.pharmacyToSave.city &&
                        <span>{this.state.pharmacyToSave.city}</span>
                        }
                      </div>
                      {!!this.state.pharmacyToSave.faxNumber &&
                      <div>{I18n.t('documentsToSend.sendToPharmacy.fax')} {this.state.pharmacyToSave.faxNumber}</div>}
                      {!!this.state.pharmacyToSave.phoneNumber &&
                      <div>{I18n.t('documentsToSend.sendToPharmacy.phoneNumber')} {this.state.pharmacyToSave.phoneNumber}</div>}
                    </div>
                  </div>
                </div>
                <div className="pharmacy-footer-container">
                  <button type="button" className="btn btn-secondary"
                          onClick={this.props.toggle}>
                    <i className="button-icon fa fa-undo" />{I18n.t('button.CANCEL')}
                  </button>
                  <button type="button"
                          className="btn btn-primary"
                          onClick={this.sendToPharmacy}
                          disabled={this.isNotEditable()}
                  >
                    <i className="button-icon fas fa-paper-plane" />
                    {I18n.t('button.SEND')}
                  </button>
                </div>
              </div>
              }
            </form>
          )}
        />
      </div>
    )
  }
}

export default connect(state => ({
  caseInfos: state.process.caseInfos,
}))(SendToPharmacie);
