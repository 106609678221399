// @flow

import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import ModalEprm from '../modal/ModalEprm';
import type { PolicyType } from '../../types/PolicyType';
import PolicyTile from './PolicyTile';
import Loader from '../Loader';

type Props = {
  resendFunction: Function,
  toggle: Function,
  modal: boolean,
  policies: PolicyType[],
  loading?: boolean,
}
export default function ResendMailModal({
  resendFunction, modal: isDisplayModal, toggle, policies, loading,
}: Props) {
  const [policyId, setPolicyId] = useState(null);

  const onSelectPolicy = (policy) => {
    setPolicyId((policyId !== policy.policyId ? policy.policyId : null));
  };

  return (
    isDisplayModal && <ModalEprm
      title={I18n.t('patientDetails.modalResend.TITLE')}
      modal={isDisplayModal}
      toggle={toggle}
      className="patient-resendmail-modal"
    >
      <div className="popin-subtitle">{I18n.t('patientDetails.modalResend.SELECT')}</div>
      {policies && policies.filter(policy => !policy.isPdaAccount).map(policy =>
      (
        <React.Fragment key={`button_ResendMail_${policy.policyId}`}>
          <button className="tile-button" onClick={() => onSelectPolicy(policy)} key={policy.policyId}>
            <PolicyTile isDisabled={policy.isPdaAccount} selected={policyId === policy.policyId} short policy={policy} />
          </button>
        </React.Fragment>
      ))
      }
      <div className="popin-footer">
        <button
          className="btn btn-secondary"
          type="button"
          onClick={toggle}
        >
          {I18n.t('button.CANCEL')}
        </button>
        <button
          className="btn btn-primary "
          disabled={!policyId || loading}
          type="button"
          onClick={() => resendFunction(policyId)}
        >
          {
            loading ?
              <Loader small />
              :
              <span>{I18n.t('button.SEND')}</span>
          }
        </button>
      </div>
    </ModalEprm>
  );
}
