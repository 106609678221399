// @flow

import { RestClientAuthenticated } from '../network';
import {
  SearchResultType,
  SearchCriteriaType,
  SearchResultContentItem,
  LogoDetail,
  LogoRequest,
} from '../../types/logoManagement';
import data from './mockData/logo.json';

const apiEndpoint = {
  searchList: '/logo/search',
  getById: '/logo/:id',
  save: '/logo/save',
  fetchList: '/logo/list',
};

const logoApi = {
  search: (searchCriteria: SearchCriteriaType): SearchResultType => (dispatch: Function) => {
    const queryString = Object.keys(searchCriteria).filter(v => searchCriteria[v] !== null).map(key => `${key }=${ searchCriteria[key]}`).join('&');

    return mockResponse(searchCriteria);
    // return new RestClientAuthenticated(`${apiEndpoint.searchList}?${queryString}`, 'GET').execute().then(response => (response.status == 200) && response.json()).catch((e) => dispatch(notifyError)(e));
  },
  fetchById: (id: null): LogoDetail => (dispatch: Function) => mockResponseById(id),
  // return new RestClientAuthenticated(`${apiEndpoint.getById.replace(':id', id)}`, 'GET').execute().then(response => (response.status == 200) && response.json()).catch((e) => dispatch(notifyError)(e));
  save: (data: LogoRequest) => new RestClientAuthenticated(`${apiEndpoint.save}`, 'POST').jsonBody(data).execute(),
  fetchList: (): FetchLogoResponse[] => new RestClientAuthenticated(`${apiEndpoint.fetchList}`, 'GET').execute().then(response => response.json()), 
};

const mockResponse = (searchCriteria: SearchCriteriaType): SearchResultType => {
  const firstPageIndex = (searchCriteria.page - 1) * searchCriteria.size;
  const lastPageIndex = firstPageIndex + searchCriteria.size;

  const resultContent = data.slice(firstPageIndex, lastPageIndex);

  const total = data.length;
  const perPage = Math.ceil(total / searchCriteria.size);
  return {
    content: resultContent,
    page: searchCriteria.page,
    perPage,
    total,
    total_pages: searchCriteria.size,
  };
};

const mockResponseById = (id: number): LogoDetail => data.find(v => v.id === id);

export interface FetchLogoResponse {
  id: string;
  name: string;
  isActive: boolean;
}

export default logoApi;
