import React from 'react';
import {
  LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE,
  LEGAL_ENTITY_EDIT_PAGE_ROUTE,
  LEGAL_ENTITY_CREATE_PAGE_ROUTE,
} from '../../routes';
import PrivateRoute from '../../components/PrivateRoute';
import { LegalEntityContextProvider } from '../../stores/legal-entity-management-context';
import LegalEntityManagement from './legalEntityManagement';
import LegalEntityRequest from './legalEntityRequest';

export default function LegalEntityManagementRoutes() {
  return (
    <LegalEntityContextProvider>
      <PrivateRoute exact path={LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE} component={LegalEntityManagement} />
      <PrivateRoute path={LEGAL_ENTITY_EDIT_PAGE_ROUTE} component={LegalEntityRequest} />
      <PrivateRoute path={LEGAL_ENTITY_CREATE_PAGE_ROUTE} component={LegalEntityRequest} />
    </LegalEntityContextProvider>
  );
}
