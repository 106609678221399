import * as React from 'react';
import Button from 'reactstrap/es/Button';
import { I18n } from 'react-redux-i18n';
import { Field } from 'react-final-form';
import { dateValidator, dateValidatorRequired, required, validateNumber, validateWeight } from '../../../validator';
import { CoreozDatePickerBase as CoreozDatePicker, CoreozInputBase as CoreozInput } from '../../../lib/coreoz-form-base';

import Loader from '../../../components/Loader';
import moment from 'moment';


type Props = {
    values: any,
    loading: boolean,
    invalid: boolean,
};

export default function IdentificationVisa({
  values, invalid, loading,
}: Props) {
  return (
      <>
        <div className="form-question">{I18n.t('processCase.identification.VISA_CALLER_INFORMATION_QUESTION')}</div>

        <div id="info-patient">
          <Field
            component={CoreozInput}
            className="form-input"
            name="information.firstName"
            label={I18n.t('form.FIRST_NAME')}
            validate={required}
            required
          />
          <Field
            component={CoreozInput}
            className="form-input"
            name="information.lastName"
            label={I18n.t('form.NAME')}
            validate={required}
            required
          />
          <Field
            component={CoreozDatePicker}
            name="information.birthDate"
            className="form-input"
            label={I18n.t('form.DATE_OF_BIRTH')}
            validate={e => dateValidator(e, I18n.t('date.DATE_FORMAT'))}
            dateFormat={I18n.t('date.DATE_FORMAT')}
            placeholder={I18n.t('form.placeholder')}
            autoComplete="none"
            onlyDate
            required
          />
          <Field
            component={CoreozInput}
            className="form-input"
            name="information.binNumber"
            label={I18n.t('form.BIN_NUMBER')}
            validate={e => validateNumber(e, 9)}
            required
            maxLength="9"
            format={stringValue => stringValue && stringValue.toString().replace(/[^\d]/g, '')}
          />
              <>
                <div className="form-question">{I18n.t('processCase.identification.BANK_CARD_HOLDER_QUESTION')}</div>
                <div className="radios-button-line">
                  <label>
                    <Field
                      className="radio-input"
                      component="input"
                      name="isBankCardHolder"
                      type="radio"
                      validate={required}
                      value="true"
                      format={value => String(value)}
                      parse={value => value === 'true'}
                    />
                    <div className="radio-label">{I18n.t('form.YES')}</div>
                  </label>
                  <label>
                    <Field
                      className="radio-input"
                      component="input"
                      name="isBankCardHolder"
                      type="radio"
                      validate={required}
                      value="false"
                      format={value => String(value)}
                      parse={value => value === 'true'}
                    />
                    <div className="radio-label">{I18n.t('form.NO')}</div>
                  </label>
                </div>
                {values.isBankCardHolder != null && !values.isBankCardHolder &&
                      <>
                        <Field
                          component={CoreozInput}
                          className="form-input"
                          name="information.bankCardHolderFirstName"
                          label={I18n.t('form.BANK_CARD_HOLDER_FIRST_NAME')}
                          validate={required}
                          required
                        />
                        <Field
                          component={CoreozInput}
                          className="form-input"
                          name="information.bankCardHolderLastName"
                          label={I18n.t('form.BANK_CARD_HOLDER_LAST_NAME')}
                          validate={required}
                          required
                        />

                      </>
                  }
              </>

          <div className="form-action">
            <Button
              color="primary"
              type="submit"
              disabled={invalid || loading}
            >
              {
                          loading ?
                            <Loader small />
                              :
                            <span> <i className="button-icon fa fa-search" />{I18n.t('button.SEARCH')}</span>
                      }
            </Button>
          </div>
        </div>
      </>
  );
}
