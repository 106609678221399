// @flow
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CaseProcessRoutes from '../pages/CaseProcessRoutes';
import processCaseApi from '../network/api/processCaseApi';
import { CASE_INFOS } from '../state/process/processReducer';
import { store } from '../network/reduce';
import {
  ADVICE_REPORT_PAGE_ROUTE,
  CASE_PROCESS_PAGE_ROUTE,
  CONSENTS_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  ELIGIBILITY_PAGE_ROUTE,
  IDENTIFICATION_PAGE_ROUTE,
  MED_REPORT_PAGE_ROUTE,
  PRESCRIPTION_PAGE_ROUTE,
  PROCESS_DETAILS_PAGE_ROUTE,
  QUALIFICATION_PAGE_ROUTE,
  TELECONSULTATION_PAGE_ROUTE,
} from '../routes';
import CaseHeader from './layout/header/CaseHeader';
import { getQueryParams } from '../services/queryParamsService';
import { notifyError } from '../network/notification';

type Props = {
  caseInfos: Object,
  dispatch: Function,
  location: {
    search: string,
    pathname: string,
  }
};
type State = {
  queryParams: URLSearchParams,
};

class ProcessCaseContainer extends React.Component<Props, State> {
  state = {
    queryParams: new URLSearchParams(this.props.location.search),
  };

  needCaseHeader = props =>
    !props.location.pathname.includes(PRESCRIPTION_PAGE_ROUTE)
      && !props.location.pathname.includes(MED_REPORT_PAGE_ROUTE)
      && !props.location.pathname.includes(ADVICE_REPORT_PAGE_ROUTE)
    // TODO : il faut pas ajouter celle des documents ?
  ;


  fetchData = (props) => {
    const queryParams = getQueryParams(props.location.search);
    const { caseId, patientId } = queryParams;
    this.setState({ queryParams });
    this.props.dispatch(store(CASE_INFOS, null));
      processCaseApi
        .fetchCaseInfos(caseId, patientId).then(response => response.text())
          .then(text => (text.length ? JSON.parse(text) : []))
        .then((caseInfos) => {
          props.dispatch(store(CASE_INFOS, caseInfos));
        })
        .catch(this.props.dispatch(notifyError));

  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    this.props.dispatch(store(CASE_INFOS, null));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      if (
        (this.props.location.pathname.includes(DASHBOARD_PAGE_ROUTE) && nextProps.location.pathname.includes(CASE_PROCESS_PAGE_ROUTE)) ||
        (this.props.location.pathname.includes(IDENTIFICATION_PAGE_ROUTE) && nextProps.location.pathname.includes(CASE_PROCESS_PAGE_ROUTE)) ||
        (this.props.location.pathname.includes(ELIGIBILITY_PAGE_ROUTE) && nextProps.location.pathname.includes(CASE_PROCESS_PAGE_ROUTE)) || // Si on ouvre un cas vers un des steps, on reload pour recuperer ses info
        (this.props.location.pathname.includes(PROCESS_DETAILS_PAGE_ROUTE)) ||
        (this.props.location.pathname.includes(QUALIFICATION_PAGE_ROUTE)) ||
        (this.props.location.pathname.includes(TELECONSULTATION_PAGE_ROUTE)) || // TODO, on devrais pouvoir simplifier tout ca non?
        (this.props.location.pathname.includes(CONSENTS_PAGE_ROUTE) && nextProps.location.pathname.includes(QUALIFICATION_PAGE_ROUTE))) {
        this.fetchData(nextProps);
      }
    }
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({ queryParams: getQueryParams(nextProps.location.search) });
    }
  }

  render() {
    const canRender = !(!this.props.caseInfos && (this.state.queryParams.caseId || this.state.queryParams.patientId));
    return (
      <div className="admin-container">
        {this.needCaseHeader(this.props) &&
        <CaseHeader queryParams={this.state.queryParams} canRender={canRender} fetchData={this.fetchData} />}
        <div className="admin-content-container">
          <main id="main-content">
            {canRender && <CaseProcessRoutes queryParams={this.state.queryParams} />}
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(ProcessCaseContainer));
