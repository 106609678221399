// @flow

import React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import { I18n, Translate } from 'react-redux-i18n';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  ADVICE_CODING_PAGE_ROUTE,
  APPOINTMENTS_PAGE_ROUTE,
  AVAILABILITIES_PAGE_ROUTE,
  BUSINESS_UNIT_PAGE_ROUTE,
  CASE_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  EXPORTS_PAGE_ROUTE,
  MEDICAL_RESOURCES_PAGE_ROUTE,
  OPERATIONS_PAGE_ROUTE,
  PATHOLOGY_CODING_PAGE_ROUTE,
  PATIENT_PAGE_ROUTE,
  PHARMACIES_PAGE_ROUTE,
  USER_MANAGEMENT_PAGE_ROUTE,
  INTERNAL_BENEFICIARY_MANAGEMENT_PAGE_ROUTE,
  B_PARTNER_MANAGEMENT_PAGE_ROUTE,
  PROTOCOL_MANAGEMENT_PAGE_ROUTE,
  LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE,
  LOGO_MANAGEMENT_PAGE_ROUTE,
  BRAND_MANAGEMENT_PAGE_ROUTE,
} from '../../routes';
import hasPermission from '../../services/permissionService';
import { currentSessionService } from '../../services/sessionServiceInstance';

type Props = { dispatch: Function };

type State = {
  dropdownUsersOpen: boolean,
  dropdownDashboardOpen: boolean,
  dropdownAdministrationOpen: boolean,
  dropdownSystemsOpen: boolean,
  dropdownBankOpen: boolean,

};

class MenuSections extends React.Component<Props, State> {
  state = {
    dropdownUsersOpen: false,
    dropdownSystemsOpen: false,
    dropdownDashboardOpen: false,
    dropdownAdministrationOpen: false,
    dropdownBankOpen: false,
  };

  render() {
    return (
      <div className="classic-menu-view">
        <Nav
          className="aside-nav-active"
          id="aside-nav"
          vertical
        >
          {hasPermission('ACCESS_WORKPLACE') &&
            <div className="sidemenu-section">
              <Dropdown
                isOpen={this.state.dropdownDashboardOpen}
                inNavbar
                toggle={() =>
                  this.setState({
                    dropdownDashboardOpen: !this.state.dropdownDashboardOpen,
                  })
                }
                className={this.state.dropdownDashboardOpen ? 'open' : ''}
              >
                <div className="sidemenu-title">
                  <DropdownToggle nav>
                    <div className="sidemenu">
                      <i className={`fas fa-caret-${this.state.dropdownDashboardOpen ? 'down' : 'right'}`} />
                      {I18n.t('aside.nav.WORKPLACE')}
                    </div>
                  </DropdownToggle>
                </div>
                <DropdownMenu>
                  {hasPermission('ACCESS_CASES_DASHBOARD') &&
                    <NavItem>
                      <NavLink to={DASHBOARD_PAGE_ROUTE} className="nav-link dropdown-item">
                        <i className="fas fa-clipboard-list" />
                        <Translate value="aside.nav.DASHBOARD" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('ACCESS_APPOINTMENTS') &&
                    <NavItem>
                      <NavLink to={APPOINTMENTS_PAGE_ROUTE} className="nav-link">
                        <i className="fa fa-user-clock" />
                        <Translate value="aside.nav.APPOINTMENTS" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('MANAGE_PATIENTS') &&
                    <NavItem>
                      <NavLink to={PATIENT_PAGE_ROUTE} className="nav-link">
                        <i className="fa fa-user-injured" />
                        <Translate value="aside.nav.PATIENTS" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('ACCESS_CASES_DASHBOARD') &&
                    <NavItem>
                      <NavLink to={CASE_PAGE_ROUTE} className="nav-link">
                        <i className="far fa-folder-open" />
                        <Translate value="aside.nav.CASES" />
                      </NavLink>
                    </NavItem>
                  }
                </DropdownMenu>
              </Dropdown>
            </div>
          }
          {hasPermission('ACCESS_ADMINISTRATION') &&
            <div className="sidemenu-section">
              <Dropdown
                isOpen={this.state.dropdownAdministrationOpen}
                inNavbar
                toggle={() =>
                  this.setState({
                    dropdownAdministrationOpen: !this.state.dropdownAdministrationOpen,
                  })
                }
                className={this.state.dropdownAdministrationOpen ? 'open' : ''}
              >
                <div className="sidemenu-title">
                  <DropdownToggle nav>
                    <div className="sidemenu">
                      <i className={`fas fa-caret-${this.state.dropdownAdministrationOpen ? 'down' : 'right'}`} />
                      {I18n.t('aside.nav.ADMIN')}
                    </div>
                  </DropdownToggle>
                </div>
                <DropdownMenu>
                  {hasPermission('ACCESS_AVAILABILITIES') &&
                    <NavItem>
                      <NavLink to={AVAILABILITIES_PAGE_ROUTE}
                        className="nav-link">
                        <i className="far fa-calendar-check" />
                        <Translate value="aside.nav.AVAILABILITIES" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('ACCESS_EXPORT') &&
                    <NavItem>
                      <NavLink to={EXPORTS_PAGE_ROUTE}
                        className="nav-link">
                        <i className="fas fa-file-export" />
                        <Translate value="aside.nav.EXPORTS" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('ACCESS_KPI') &&
                    <NavItem>
                      <NavLink to={OPERATIONS_PAGE_ROUTE}
                        className="nav-link">
                        <i className="fas fa-chart-pie" />
                        <Translate value="aside.nav.OPERATIONS" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('MANAGE_DETAILS_USERS') &&
                    <NavItem>
                      <NavLink to={USER_MANAGEMENT_PAGE_ROUTE} className="nav-link">
                        <i className="fas fa-users" />
                        <Translate value="aside.nav.USER_MANAGEMENTS" />
                      </NavLink>
                    </NavItem>
                  }
                  {(hasPermission('MANAGE_CODING') && currentSessionService().currentUser().showAdviceCodingPage) &&
                    <NavItem>
                      <NavLink to={ADVICE_CODING_PAGE_ROUTE} className="nav-link">
                        <i className="fas fa-book" />
                        <Translate value="aside.nav.ADVICE_CODING" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('MANAGE_CODING') &&
                    <NavItem>
                      <NavLink to={PATHOLOGY_CODING_PAGE_ROUTE} className="nav-link">
                        <i className="fas fa-book" />
                        <Translate value="aside.nav.PATHOLOGY_CODING" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('MANAGE_PHARMACIES') &&
                    <NavItem>
                      <NavLink to={PHARMACIES_PAGE_ROUTE} className="nav-link">
                        <i className="fa fa-clinic-medical" />
                        <Translate value="aside.nav.PHARMACIES" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('MANAGE_BUSINESS_UNITS') &&
                    <NavItem>
                      <NavLink to={BUSINESS_UNIT_PAGE_ROUTE} className="nav-link">
                        <i className="fal fa-first-aid" />
                        <Translate value="aside.nav.BUSINESS_UNIT" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('MANAGE_INTERNAL_BENEFICIARY') &&
                    <NavItem>
                      <NavLink to={INTERNAL_BENEFICIARY_MANAGEMENT_PAGE_ROUTE} className="nav-link">
                        <i className="fa fa-ball-pile" />
                        <Translate value="aside.nav.INTERNAL_BENFICIARY" />
                      </NavLink>
                    </NavItem>
                  }
                  {hasPermission('MANAGE_B_PARTNER') &&
                  <NavItem>
                    <NavLink to={B_PARTNER_MANAGEMENT_PAGE_ROUTE} className="nav-link">
                      <i className="fa fa-handshake" />
                      <Translate value="aside.nav.B_PARTNER" />
                    </NavLink>
                  </NavItem>
                  }

                  {hasPermission('MANAGE_PROTOCOL') &&
                   <NavItem>
                     <NavLink to={PROTOCOL_MANAGEMENT_PAGE_ROUTE} className="nav-link">
                       <i className="fa fa-clipboard-list-check" />
                       <Translate value="aside.nav.PROTOCOL" />
                     </NavLink>
                   </NavItem>
                  }

                  {/*<NavItem>*/}
                  {/*  <NavLink to={LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE} className="nav-link">*/}
                  {/*    <i className="fa fa-industry" />*/}
                  {/*    <Translate value="aside.nav.LEGAL_ENTITY" />*/}
                  {/*  </NavLink>*/}
                  {/*</NavItem>*/}

                  {/*<NavItem>*/}
                  {/*  <NavLink to={LOGO_MANAGEMENT_PAGE_ROUTE} className="nav-link">*/}
                  {/*    <i className="fab fa-bandcamp"></i>*/}
                  {/*    <Translate value="aside.nav.LOGO" />*/}
                  {/*  </NavLink>*/}
                  {/*</NavItem>*/}

                  {/*<NavItem>*/}
                  {/*  <NavLink to={BRAND_MANAGEMENT_PAGE_ROUTE} className="nav-link">*/}
                  {/*    <i className="fa fa-solid fa-copyright" />*/}
                  {/*    <Translate value="aside.nav.BRAND" />*/}
                  {/*  </NavLink>*/}
                  {/*</NavItem>*/}
                </DropdownMenu>
              </Dropdown>
            </div>
          }
          <div className="sidemenu-title">
            <NavLink to={MEDICAL_RESOURCES_PAGE_ROUTE} className="shortcut">
              {I18n.t('aside.nav.SHORTCUT')}
            </NavLink>
          </div>
        </Nav>
      </div>
    );
  }
}

export default withRouter(connect()(MenuSections));
