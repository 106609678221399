// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import BusinessUnitSearch from '../../components/businessUnit/BusinessUnitSearch';
import BusinessUnitList from '../../components/businessUnit/BusinessUnitList';
import type { BusinessUnit, Criteria } from '../../types/BusinessUnitTypes';
import { store } from '../../network/reduce';
import { SEARCH_RESULTS_BUSINESSUNIT } from '../../state/businessUnit/businessUnitReducer';
import search from '../../state/search';
import { fetchBusinessUnits } from '../../state/businessUnit/businessUnitService';

type Props = {
  businessUnits: BusinessUnit[],
  searchResultsBusinessUnit: [],
  dispatch: Function,
}

class BusinessUnitMain extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(fetchBusinessUnits());
  }

  searchBusinessUnit = (criteria: Criteria) => {
    this.props.dispatch(store(
      SEARCH_RESULTS_BUSINESSUNIT,
      search(criteria, this.props.businessUnits),
    ));
  };

  render() {
    if (this.props.businessUnits === undefined) {
      return false;
    }
    return (
      <div className="dashboard-container">
        <div className="title-container">
          <div className="title"><Translate value="businessUnit.TITLE" /></div>
        </div>
        <div className="business-unit-container">
          <div className="business-unit-search">
            <BusinessUnitSearch
              search={this.searchBusinessUnit}
            />
          </div>
          <BusinessUnitList
            businessUnits={(this.props.searchResultsBusinessUnit
                  && this.props.searchResultsBusinessUnit.length > 0)
                ? this.props.searchResultsBusinessUnit
                : this.props.businessUnits}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  businessUnits: state.businessUnit.businessUnits,
  searchResultsBusinessUnit: state.businessUnit.searchResultsBusinessUnit,
}))(BusinessUnitMain));
