// @ flow

import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';

import {
  ADVICE_CODING_PAGE_ROUTE,
  APPOINTMENTS_PAGE_ROUTE,
  AVAILABILITIES_PAGE_ROUTE,
  BUSINESS_UNIT_PAGE_ROUTE,
  CASE_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  EXPORTS_PAGE_ROUTE,
  HISTORY_PAGE_ROUTE,
  LOG_API_PAGE_ROUTE,
  LOGS_PAGE_ROUTE,
  MAIL_PAGE_ROUTE,
  MEDICAL_RESOURCES_PAGE_ROUTE,
  OPERATIONS_PAGE_ROUTE,
  PATHOLOGY_CODING_PAGE_ROUTE,
  PATIENT_PAGE_ROUTE,
  PHARMACIES_PAGE_ROUTE,
  ROLES_PAGE_ROUTE,
  SCHEDULED_PAGE_ROUTE,
  SMS_PAGE_ROUTE,
  USER_MANAGEMENT_PAGE_ROUTE,
  USER_PAGE_ROUTE,
  INTERNAL_BENEFICIARY_MANAGEMENT_PAGE_ROUTE,
  B_PARTNER_MANAGEMENT_PAGE_ROUTE,
  PROTOCOL_MANAGEMENT_PAGE_ROUTE,
  LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE,
  LOGO_MANAGEMENT_PAGE_ROUTE,
  BRAND_MANAGEMENT_PAGE_ROUTE,
} from '../routes';
import Appointment from './appointment/Appointment';
import Availability from './availability/Availability';
import Dashboard from './dashboard/Dashboard';
import BusinessUnitRoutes from './businessUnit/BusinessUnitRoutes';
import Roles from './roles/Roles';
import LogApi from '../components/logApi/LogApi';
import Scheduled from './scheduled/Scheduled';
import Logs from './logs/Logs';
import History from './history/History';
import CasesRoutes from './cases/CasesRoutes';
import PatientRoutes from './patients/PatientRoutes';
import Exports from './exports/Exports';
import AdviceCodingSearch from './coding/AdviceCodingSearch';
import PathologyCodingSearch from './coding/PathologyCodingSearch';
import MedicalResourcesPage from './medicalRessources/MedicalResourcesPage';
import UserManagementRoutes from './userManagement/UserManagementRoutes';
import Operations from './operations/Operations';
import PharmacySearch from './pharmacies/PharmacySearch';
import MailRoutes from './mail/MailRoutes';
import SmsRoutes from './sms/SmsRoutes';
import hasPermission from '../services/permissionService';
import InternalBeneficiaryManagementRoutes from './internalBeneficiary/InternalBeneficiaryManagementRoutes';
import BPartnerManagementRoutes from './bPartner/bPartnerManagementRoutes';
import LegalEntityManagementRoutes from './legalEntity/legalEntityManagementRoutes';
import ProtocolManagementRoutes from './protocol/protocolManagementRoutes';
import LogoManagementRoutes from './logo/logoManagementRoutes';
import BrandManagementRoutes from './brand/BrandManagementRoutes';
import BusinessUnitManagementRoutes from "./businessUnitManagement/businessUnitManagementRoutes";

class BaseRoutes extends React.Component<Props, State> {
  state = {
    isAsideNavOpen: false,
  };

  render() {
    return (
      <>
        <Switch>
          <PrivateRoute
            exact
            path={DASHBOARD_PAGE_ROUTE}
            component={Dashboard}
            authorization="ACCESS_CASES_DASHBOARD"
          />
          <PrivateRoute
            exact
            path={APPOINTMENTS_PAGE_ROUTE}
            component={Appointment}
            authorization="ACCESS_APPOINTMENTS"
          />
          <PrivateRoute
            exact
            path={AVAILABILITIES_PAGE_ROUTE}
            component={Availability}
            authorization="ACCESS_AVAILABILITIES"

          />
          <PrivateRoute
            exact
            path={EXPORTS_PAGE_ROUTE}
            authorization="ACCESS_EXPORT"
            component={Exports}
          />
          <PrivateRoute
            exact
            path={OPERATIONS_PAGE_ROUTE}
            component={Operations}
            authorization="ACCESS_KPI"
          />
          <PrivateRoute
            exact
            path={ADVICE_CODING_PAGE_ROUTE}
            component={AdviceCodingSearch}
            authorization="MANAGE_CODING"
          />
          <PrivateRoute
            exact
            path={PATHOLOGY_CODING_PAGE_ROUTE}
            component={PathologyCodingSearch}
            authorization="MANAGE_CODING"
          />
          <PrivateRoute
            exact
            path={MEDICAL_RESOURCES_PAGE_ROUTE}
            component={MedicalResourcesPage}
          />
          <PrivateRoute
            exact
            path={PHARMACIES_PAGE_ROUTE}
            component={PharmacySearch}
            authorization="MANAGE_PHARMACIES"
          />
          <PrivateRoute
            path={PATIENT_PAGE_ROUTE}
            component={PatientRoutes}
            authorization="MANAGE_PATIENTS"
          />
          <PrivateRoute
            path={CASE_PAGE_ROUTE}
            component={CasesRoutes}
            authorization="ACCESS_CASES_DASHBOARD"
          />
          <PrivateRoute
            path={USER_MANAGEMENT_PAGE_ROUTE}
            component={UserManagementRoutes}
            authorization="MANAGE_DETAILS_USERS"
          />
          <PrivateRoute
            authorization="MANAGE_BUSINESS_UNITS"
            path={BUSINESS_UNIT_PAGE_ROUTE}
            component={BusinessUnitManagementRoutes}
          />
          <PrivateRoute
            exact
            path={ROLES_PAGE_ROUTE}
            authorization="MANAGE_ROLES"
            component={Roles}
          />
          <PrivateRoute
            exact
            path={LOG_API_PAGE_ROUTE}
            authorization="MANAGE_API_LOGS"
            component={LogApi}
          />
          <PrivateRoute
            exact
            path={SCHEDULED_PAGE_ROUTE}
            authorization="MANAGE_SYSTEM"
            component={Scheduled}
          />
          <PrivateRoute
            exact
            path={LOGS_PAGE_ROUTE}
            authorization="MANAGE_SYSTEM"
            component={Logs}
          />
          <PrivateRoute
            exact
            path={HISTORY_PAGE_ROUTE}
            authorization="MANAGE_SYSTEM"
            component={History}
          />
          <PrivateRoute
            path={MAIL_PAGE_ROUTE}
            component={MailRoutes}
            authorization="MANAGE_MESSAGES"
          />
          <PrivateRoute
            path={SMS_PAGE_ROUTE}
            component={SmsRoutes}
            authorization="MANAGE_MESSAGES"
          />
          <PrivateRoute
            path={INTERNAL_BENEFICIARY_MANAGEMENT_PAGE_ROUTE}
            component={InternalBeneficiaryManagementRoutes}
            authorization="MANAGE_INTERNAL_BENEFICIARY"
          />
          <PrivateRoute
            path={B_PARTNER_MANAGEMENT_PAGE_ROUTE}
            component={BPartnerManagementRoutes}
          />
          <PrivateRoute
            path={PROTOCOL_MANAGEMENT_PAGE_ROUTE}
            component={ProtocolManagementRoutes}
          />
          <PrivateRoute
            path={LOGO_MANAGEMENT_PAGE_ROUTE}
            component={LogoManagementRoutes}
          />

          <PrivateRoute
            path={LEGAL_ENTITY_MANAGEMENT_PAGE_ROUTE}
            component={LegalEntityManagementRoutes}
          />

          <PrivateRoute
            path={BRAND_MANAGEMENT_PAGE_ROUTE}
            component={BrandManagementRoutes}
          />
          <Route path="*" render={() => <Redirect to={hasPermission('ACCESS_CASES_DASHBOARD') ? DASHBOARD_PAGE_ROUTE : EXPORTS_PAGE_ROUTE} />} />
        </Switch>
      </>
    );
  }
}

export default withRouter(BaseRoutes);
