import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase as CoreozInput } from '../../../lib/coreoz-form-base/index';

import prescriptionApi from '../../../network/api/prescriptionApi';
import { emailCompareAndCheckRequireValidator, validateEmailFormat } from '../../../validator';
import Loader from '../../../components/Loader';
import { notifyError, notifySuccess } from '../../../network/notification';
import { cleanObject } from '../../../utils';
import CoreozPhoneInput from '../../../components/CoreozPhoneInput';

type Props = {
    documentId: string,
  caseId: string,
  caseInfos: Object,
  caseDetail: Object,
  dispatch: Function,
  toggle: Function,
  updateDocuments: Function,
  fromCaseDetails?: Boolean,
};

type State = { loading: boolean };

class SendViaSecureLink extends React.Component<Props, State> {
  state = { loading: false, codeRecipientPhoneValid: true, linkRecipientPhoneValid: true };

  handleSubmit = (data) => {
    this.setState({ loading: true });

    prescriptionApi
      .sendSecureLink(this.props.documentId, this.props.caseId, data)
      .then(() => {
        this.props.toggle();
        this.props.updateDocuments();
        this.setState({ loading: false });
      })
      .then(notifySuccess(this.props.dispatch))
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(notifyError)(e);
      });
  };

  formIsInvalid = (invalid, values) => {
    
    if (invalid) {
      return true;
    }
    
    const hasLinkRecipientMail = !!values.linkRecipientMail;
    const hasLinkRecipientPhone = values.linkRecipientPhone && values.linkRecipientPhone !== '+33';

    const hasCodeRecipientPhone = values.codeRecipientPhone && values.codeRecipientPhone !== '+33';
    const hasCodeRecipientMail = !!values.codeRecipientMail;

    // cas 1 : lien email // code téléphone
    if (hasLinkRecipientMail && hasCodeRecipientPhone) {
      return false;
    }
    // cas 2 : lien telephone // code mail
    else if (hasLinkRecipientPhone && hasCodeRecipientMail) {
      return false;
    }
    // cas 3 : lien et code mail
    else if (hasLinkRecipientMail && hasCodeRecipientMail && values.linkRecipientMail !== values.codeRecipientMail) {
      return false;
    }
    // cas 4 : lien et code téléphone
    else if (hasLinkRecipientPhone && hasCodeRecipientPhone && values.linkRecipientPhone !== values.codeRecipientPhone) {
      return false;
    } else {
      return true;
    }
  };

  validateEmailForm(value, allValues) {

    let rs = emailCompareAndCheckRequireValidator(allValues.codeRecipientMail, allValues.linkRecipientMail, allValues.codeRecipientPhone, allValues.linkRecipientPhone, value);

    if(rs) {
        return rs;
    }
  
    rs = validateEmailFormat(value);

    return rs;
  }

  render() {
    // TODO faire un appel WS avec la liste des BU
    const email = this.props.fromCaseDetails ? this.props.caseDetail.patientEmail : this.props.caseInfos.patient.email;
    const phone = this.props.fromCaseDetails ? this.props.caseDetail.patientPhoneNumber : this.props.caseInfos.patient.mobilePhone;
    const preferredCountries = ['fr', 'de', 'es', 'ch'];
    return (
      <div className="send-via-secure-link">
        <Form
          onSubmit={this.handleSubmit}
          initialValues={cleanObject({
            linkRecipientMail: email,
            linkRecipientPhone: '+33',
            codeRecipientPhone: phone,
          })}
          render={({
                     handleSubmit, form, values, invalid,
                   }) => (
            <form
              className="identification-form"
              onSubmit={handleSubmit}
            >
              <div className="documents-send-subtitle">{I18n.t('documentsToSend.sendBySecure.subtitle')}</div>
              <div>
      <span
        className="documents-send-destination"
      >{I18n.t('documentsToSend.sendBySecure.destination1')}
      </span>
                <div className="documents-send-input">
                  <Field
                    label={I18n.t('documentsToSend.sendBySecure.email')}
                    component={CoreozInput}
                    type="email"
                    name="linkRecipientMail"
                    validate={(value, allValues) => this.validateEmailForm(value, allValues)}
                    onChange={() => {
                      form.change('linkRecipientPhone', '+33');
                    }}
                    disabled={this.props.fromCaseDetails ? false : (this.props.caseInfos && !this.props.caseInfos.isEditable)}
                  />
                  <span className="documents-send-or">{I18n.t('documentsToSend.sendBySecure.or')}</span>
                  <CoreozPhoneInput
                    required={!values.linkRecipientMail && values.linkRecipientPhone && values.linkRecipientPhone !== '+33'}
                    preferredCountries={preferredCountries}
                    value={values.linkRecipientPhone}
                    onChange={newValue => {
                      form.change('linkRecipientMail', undefined);
                      form.change('linkRecipientPhone', newValue);
                    }}
                    setPhoneIsValid={(valid) => this.setState({ linkRecipientPhoneValid: valid })}
                    disabled={this.props.fromCaseDetails ? false : (this.props.caseInfos && !this.props.caseInfos.isEditable)}
                  />
                </div>
              </div>
              <div>
      <span
        className="documents-send-destination"
      > {I18n.t('documentsToSend.sendBySecure.destination2')}
      </span>
                <div className="documents-send-input">
                  <Field
                    label={I18n.t('documentsToSend.sendBySecure.email')}
                    component={CoreozInput}
                    type="email"
                    name="codeRecipientMail"
                    validate={(value, allValues) => this.validateEmailForm(value, allValues)}
                    onChange={() => {
                      form.change('codeRecipientPhone', '+33');
                    }}
                    disabled={this.props.fromCaseDetails ? false : (this.props.caseInfos && !this.props.caseInfos.isEditable)}
                  />
                  <span className="documents-send-or">{I18n.t('documentsToSend.sendBySecure.or')}</span>
                  <CoreozPhoneInput
                    required={!values.codeRecipientMail && values.codeRecipientPhone && values.codeRecipientPhone !== '+33'}
                    preferredCountries={preferredCountries}
                    value={values.codeRecipientPhone}
                    onChange={newValue => {
                      form.change('codeRecipientMail', undefined);
                      form.change('codeRecipientPhone', newValue);
                    }}
                    setPhoneIsValid={(valid) => this.setState({ codeRecipientPhoneValid: valid })}
                    disabled={this.props.fromCaseDetails ? false : (this.props.caseInfos && !this.props.caseInfos.isEditable)}
                  />
                </div>
                <div className="base-error-form error-form--field error">
                  {
                    (((!!values.linkRecipientPhone && values.linkRecipientPhone.length > 3) && !!values.linkRecipientMail)
                      || ((!!values.codeRecipientPhone && values.codeRecipientPhone.length > 3) && !!values.codeRecipientMail)) &&
                    I18n.t('form.validator.VALIDATE_JUST_ONE_VALUES')
                  }
                </div>
                <div className="base-error-form error-form--field error">
                  {
                    ((!!values.linkRecipientMail && !!values.codeRecipientMail && values.codeRecipientMail === values.linkRecipientMail)
                      || (!!values.linkRecipientPhone && values.linkRecipientPhone.length > 3 && !!values.codeRecipientPhone && values.codeRecipientPhone.length > 3 && values.codeRecipientPhone === values.linkRecipientPhone))
                    &&
                    I18n.t('form.validator.VALIDATE_IDENTICAL_VALUES')
                  }
                </div>
              </div>
              <div className="modal-footer-send-documents">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.props.toggle}
                >
                  <i className="button-icon fa fa-undo"/>
                  {I18n.t('button.CANCEL')}
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={this.formIsInvalid(invalid, values) || this.state.loading || (this.props.fromCaseDetails ? false : (this.props.caseInfos && !this.props.caseInfos.isEditable))}
                >
                  {
                    this.state.loading ?
                      <Loader small/>
                      :
                      <Fragment>
                        <i className="button-icon fas fa-paper-plane"/>
                        {I18n.t('button.SEND')}
                      </Fragment>
                  }
                </button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(SendViaSecureLink));
