// @flow

import { reduce } from '../../network/reduce';

export type UserStateType = {
  autoSave: boolean,
  userIsAvailable: boolean,
};

const initialState: UserStateType = {
  isSalvageable: false,
  autoSave: true,
  autoSaveEnabled: false,
  caseInfos: null,
  modalAssigned: false,
  userIsAvailable: true,
};
export const IS_SALVAGEABLE = 'IS_SALVAGEABLE';
export const AUTO_SAVE = 'AUTO_SAVE';
export const AUTO_SAVE_ENABLED = 'AUTO_SAVE_ENABLED';
export const MODAL_ASSIGNED = 'MODAL_ASSIGNED';
export const CASE_INFOS = 'CASE_INFOS';
export const USER_IS_AVAILABLE = 'USER_IS_AVAILABLE';
export const CASE_QUALIFICATION = 'CASE_QUALIFICATION'

const processReducer = reduce(initialState, {
  [IS_SALVAGEABLE]: 'isSalvageable',
  [AUTO_SAVE]: 'autoSave',
  [AUTO_SAVE_ENABLED]: 'autoSaveEnabled',
  [CASE_INFOS]: 'caseInfos',
  [MODAL_ASSIGNED]: 'modalAssigned',
  [USER_IS_AVAILABLE]: 'userIsAvailable',
  [CASE_QUALIFICATION]: 'caseQualification',
});

export default processReducer;
