import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Field, Form } from 'react-final-form';
import Loader from '../../components/Loader';
import { CoreozInputBase, CoreozSelectBase } from '../../lib/coreoz-form-base/index';
import userApi from '../../network/api/userApi';
import { BRAND_MANAGEMENT_PAGE_ROUTE } from '../../routes';
import { required } from '../../validator';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import BrandContext from '../../stores/brand-management-context';
import brandApi from '../../network/api/brandApi';
import { BrandDetail } from '../../types/BrandManagement';

const useStyles = makeStyles(theme => ({
  backdrop: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const brandRequest = (props) => {
  const classes = useStyles();
  const isEdit = !!props.match.params.id || false;
  const brandId = props.match.params.id || null;
  const { searchCriteriaContext, updateSearchCriteriaContext } = useContext(BrandContext);
  const [businessUnits, setBusinessUnits] = useState([]);

  const [button, setButton] = useState('');
  const [brandDetail, setBrandDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    fetchBusinessUnits();
    if (brandId) {
      fetchBrandDetail(brandId);
    }
  }, []);

  async function fetchBusinessUnits(): void {
    const businessUnitsResponse = await userApi.fetchBusinessUnits().then(response => response.json());

    setBusinessUnits((businessUnitsResponse) || []);
  }

  async function fetchBrandDetail(id: number): void {
    setLoading(true);

    const res: BrandDetail = await props.dispatch(brandApi.fetchById(id));

    setBrandDetail(res);

    setLoading(false);
  }

  const onPreviousHandler = () => {
    props.history.push(BRAND_MANAGEMENT_PAGE_ROUTE);
  };

  const onSave = (dataForm) => {
    const rawData = {
      ...dataForm,
    };
  };

  const formatForFront = (brandDetail: BrandDetail) => {
    if (!brandDetail) {
      return {};
    }

    return {
      brandCode: brandDetail.brandCode || null,
      brandName: brandDetail.brandName || null,
      isActive: brandDetail.isActive,
      businessUnitId: brandDetail.businessUnitId || null,
      senderMail: brandDetail.senderMail || null,
      pdaLink: brandDetail.pdaLink || null,
    };
  };

  return (
    <div className="legal-entity-detail user-details" id="protocol-details">

      <div className="legal-entity-detail-header">

        <div
          className="back-to-list"
          onClick={onPreviousHandler}
        >
          <i className="fa fa-arrow-left" />
          {I18n.t('userDetails.BACK_TO_LIST')}
        </div>

        <div className="title">
          {isEdit ? I18n.t('brandManagement.TITLE') : I18n.t('brandManagement.TITLE_CREATE')}
          {
                        isEdit && brandDetail &&
                        <div className="legal-entity-detail-info">{brandDetail.brandName}</div>
                    }
        </div>


      </div>

      <Form
        onSubmit={onSave}
        initialValues={formatForFront(brandDetail)}
        render={({ handleSubmit, form, invalid }) => (
          <form onSubmit={handleSubmit}>
            <div className="user-action">
              <Button
                color="secondary"
                type="button"
                onClick={onPreviousHandler}
              >
                <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={invalid}
              >
                <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
              </Button>

            </div>


            <div className="content">

              <div className="user-category">

                <div className="content-container">

                  <Field
                    autoComplete="off"
                    type="text"
                    component={CoreozInputBase}
                    name="brandCode"
                    label={I18n.t('brandManagement.label.BRAND_CODE')}
                    validate={required}
                    required
                    maxLength="254"
                    format={stringValue => stringValue && stringValue.replace(/[^\d]/g, '')}
                  />
                  <Field
                    autoComplete="off"
                    type="text"
                    component={CoreozInputBase}
                    name="brandName"
                    label={I18n.t('brandManagement.label.BRAND_NAME')}
                    validate={required}
                    required
                    maxLength="200"
                  />

                  <Field
                    name="businessUnitId"
                    id="businessUnitId"
                    autoComplete="off"
                    type="text"
                    component={CoreozSelectBase}
                    label={I18n.t('brandManagement.label.BUSINESS_UNIT')}
                    list={businessUnits && businessUnits.map(bu => ({
                                            id: bu.id,
                                            label: bu.name,
                                        }))}
                    validate={required}
                    required
                  />

                  <Field
                    autoComplete="off"
                    type="text"
                    component={CoreozInputBase}
                    name="senderMail"
                    label={I18n.t('brandManagement.label.SENDER_MAIL')}
                    validate={required}
                    required
                    maxLength="200"
                  />

                  <Field
                    autoComplete="off"
                    type="text"
                    component={CoreozInputBase}
                    name="pdaLink"
                    label={I18n.t('brandManagement.label.PDA_LINK')}
                    validate={required}
                    required
                    maxLength="200"
                  />


                  { isEdit && <div className="radio-button-container">
                    <div className="radio-button-label">{I18n.t('brandManagement.label.STATUS')}</div>
                    <div className="choices">
                      <Field
                        component="input"
                        name="isActive"
                        type="radio"
                        value="true"
                        format={value => String(value)}
                        parse={value => value === 'true'}
                      />
                      <span>{I18n.t('brandManagement.STATUS.ACTIVE')}</span>
                      <Field
                        component="input"
                        name="isActive"
                        type="radio"
                        value="false"
                        format={value => String(value)}
                        parse={value => value === 'true'}
                      />
                      <span>{I18n.t('brandManagement.STATUS.INACTIVE')}</span>
                    </div>
                              </div>
                                    }

                </div>
              </div>

            </div>

            <div className="user-action mt-5">
              <Button
                color="secondary"
                type="button"
                onClick={onPreviousHandler}
              >
                <i className="button-icon fa fa-undo" />{I18n.t('actions.CANCEL')}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={invalid}
              >
                <i className="button-icon fa fa-save" />{I18n.t('actions.SAVE')}
              </Button>
            </div>

          </form>
                )}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <div className="center-child search-loader"><Loader /></div>
      </Backdrop>
    </div>
  );
};

export default connect()(brandRequest);
