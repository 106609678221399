// @flow

import React, { Fragment } from 'react';
import { I18n, setLocale } from 'react-redux-i18n';
import { CoreozInputBase as CoreozInput } from '../../lib/coreoz-form-base/index';
import { Field } from 'react-final-form';
import moment from 'moment';
import { Button } from 'reactstrap';
import ResizableTextarea from '../../components/ResizableTextarea';

type
  Props = {
  previewMode?: boolean,
  showLogoInDocument: boolean,
  showAdditionalNotes: boolean,
  showBusinessUnitInformation: boolean,
  showFullFooter: boolean,
  showCityOfCreation: boolean,
  showEmergencyNumber: boolean,
  title?: string,
  additionalNote: string,
  prescription: Object,
  toggleShowAdditionalNotes: Function,
  removeMedicamentFromMedication: Function,
  vidalPopUpClickHandle: Function,
  medication: Array<Object>,
  vidalPopUp: Function,
  dispatch: Function,
};
const MAX_LENGTH_ADDITIONAL_NOTES = 2000;

class PrescriptionPreview extends React.PureComponent<Props> {
  componentWillMount() {
    this.props.dispatch(setLocale(this.props.i18nLanguage, false));
  }

  componentWillUnmount() {
    this.props.dispatch(setLocale(this.props.appI18nLanguage, false));
  }

  componentWillUpdate(nextProps) {
    this.props.dispatch(setLocale(nextProps.i18nLanguage, false));
  }

  componentDidUpdate(prevProps) {
    this.props.dispatch(setLocale(this.props.appI18nLanguage, false));
  }

  render() {
    return (
      <Fragment>
        {
          !this.props.previewMode &&
          <Field
            component={CoreozInput}
            className="title"
            name="title"
          />
        }
        <div className={`prescription-preview ${!this.props.previewMode ? 'prescription-min-height' : ''}`}>
          {this.props.previewMode &&
          <div className="document-bottom" >
            {this.props.showFullFooter ?
              <div className="document-footer footer">
                <div className="text-medium">{I18n.t('editDocument.FOOTER_1')}</div>
                <div className="bloc-text text-medium">
                  {`${I18n.t('editDocument.FOOTER_2')} ${this.props.showEmergencyNumber ? I18n.t('editDocument.FOOTER_2_EMERGENCY_NUMBERS') : ''}`}
                </div>
                <div className="text-medium">{I18n.t('editDocument.FOOTER_3')}</div>
              </div> :
              <div className="document-footer footer">
                <div className="bloc-text text-medium">
                  {`${I18n.t('editDocument.FOOTER_2')} ${this.props.showEmergencyNumber ? I18n.t('editDocument.FOOTER_2_EMERGENCY_NUMBERS') : ''}`}
                </div>
                <div className="text-medium">{I18n.t('editDocument.FOOTER_4')}</div>
              </div>}
          </div>}
          <div className="prescription-title pdf-header">
            <div className="document-header">
              {this.props.showLogoInDocument && <img
                src={this.props.prescription.logo}
                alt={this.props.prescription.brandName}
              />}
              <div className="document-doctor-info">
                <div className="important-text">
                  {I18n.t('editDocument.DOCTOR')}
                  <span className="text">
                    {!!this.props.prescription.doctorName && this.props.prescription.doctorName.toUpperCase()}
                  </span>
                </div>
                <div><span
                  className="important-text"
                >{I18n.t('editDocument.ID_NUMBER')}
                     </span>
                  <span className="text">
                    {this.props.prescription.doctorRpps}
                  </span>
                </div>
              </div>
            </div>
            {this.props.showBusinessUnitInformation &&
            <div className="document-address">
              <div className="important-text">{I18n.t('editDocument.ADDRESS')} :</div>
              <div className="text-small">{I18n.t('editDocument.ORGANIZATION')} <span
                className="important-small-text"
              >{this.props.prescription.legalEntityName}
                                                                                </span>
              </div>
              <div className="text-small">{I18n.t('editDocument.UNDER_NAME')} <span
                className="important-small-text"
              >"{this.props.prescription.brandName}"
                                                                              </span>
              </div>
              <div className="text-small">{this.props.prescription.businessUnitAddress}</div>
              {
                !!this.props.showEmergencyNumber &&
                <div className="bloc-text text-small">{I18n.t('editDocument.IMPLEMENTED_BY')} :
                  <span className="important-small-text"> {this.props.prescription.businessUnitName}</span>
                </div>
              }
              <div>
                <span className="important-text">
                  {I18n.t('editDocument.EMAIL')} :
                </span>
                <a
                  href={`mailto${this.props.prescription.businessUnitEmail}`}
                  className="text-small"
                >{this.props.prescription.businessUnitEmail}
                </a>
              </div>
              <div className="important-text">{I18n.t('editDocument.OPEN_HOURS')}</div>
            </div>
            }
            <div
              className="document-date text"
            >{`${this.props.showCityOfCreation ? `${this.props.prescription.place}, ${I18n.t('editDocument.THE')}` : `${I18n.t('editDocument.DATE')}:`} ${moment(this.props.prescription.createdDate, 'YYYY-MM-DD').format(I18n.t('date.DATE_FORMAT'))}`}
            </div>
            <div className="document-patient-info">
              <div className="text">{I18n.t('editDocument.PATIENT')} :
                {this.props.prescription.patientGender === 1 ? I18n.t('editDocument.SIR') : I18n.t('editDocument.MADAM')}
                <span className="text">
                  {`${this.props.prescription.patientFirstName ? this.props.prescription.patientFirstName.toUpperCase() : ''} ${!!this.props.prescription.patientLastName && this.props.prescription.patientLastName.toUpperCase()}`}
                </span>
              </div>
              <div className="text">{I18n.t('editDocument.BIRTH_DATE')} :{' '}
                {moment(this.props.prescription.patientBirthDate, 'YYYY-MM-DD').format(I18n.t('date.DATE_FORMAT'))}
              </div>
              { /* displayed if patient is under 15 */}
              {-moment(this.props.prescription.patientBirthDate, 'YYYY-MM-DD').diff(Date.now(), 'years') < 15 && this.props.prescription.patientWeight !== undefined &&
              <div
                className="text"
              >{I18n.t('editDocument.WEIGHT', { weight: this.props.prescription.patientWeight })}
              </div>
              }
            </div>
            {this.props.previewMode && <div className="numbering">
              <span className="important-text">{I18n.t('editDocument.ORDONNANCE')} (<span
                className="pagenumber"
              /> / <span
                className="pagecount"
              />)
              </span>
                                       </div>}
          </div>

          <div className="numbering-pdf-header">
            { this.props.previewMode &&
            <div className="numbering">
              <span className="important-text">{I18n.t('editDocument.ORDONNANCE')} (<span
                className="pagenumber"
              /> / <span
                className="pagecount"
              />)
              </span>
            </div>
            }
          </div>

          <div className="prescription-content">
            <div className="medicines">
              {this.props.medication && this.props.medication.length ?
                <div className="medicines-list">
                  {this.props.medication.map((medicament, index) => (
                    <div className="medicine" key={`${medicament.vidalId}`}>
                      <div className="medicine-info">
                        <div className="medicine-name important-text">{index + 1}/ {medicament.name}</div>
                        {medicament.innName && medicament.name !== medicament.innName &&
                        <div
                          className="medicine-INN-name important-text"
                        >({medicament.innName})
                        </div>
                        }
                        <div className="medicine-notes text">{medicament.instructions}</div>
                      </div>
                      {!this.props.previewMode && <div className="medicine-action hide-pdf">
                        <a
                          onClick={() => this.props.vidalPopUp(medicament)}
                        >{I18n.t('editDocument.EDIT')}
                        </a>
                        <i
                          className="fa fa-trash"
                          onClick={() => this.props.removeMedicamentFromMedication(medicament)}
                        />
                                                  </div>}
                    </div>
                  ))}
                  {!this.props.previewMode && <button
                    className="btn btn-primary hide-pdf"
                    type="button"
                    onClick={() => this.props.vidalPopUp({})}
                  >
                    <i className="fal fa-plus" /><span>{I18n.t('editDocument.ADD_MEDICINE')}</span>
                                              </button>}
                </div>
                :
                <div>
                  {!this.props.previewMode && <div className="empty-box hide-pdf">
                    <i className="fa fa-pills empty-box-icon" />
                    <div className="empty-box-title">{I18n.t('editDocument.EMPTY_MEDICINE')}</div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.props.vidalPopUp({})}
                    >
                      <i className="fal fa-plus" />
                      <span>{I18n.t('editDocument.ADD_MEDICINE')}</span>
                    </button>
                                              </div>}
                </div>
              }
            </div>
            <div className={`linked-on-pdf ${!this.props.previewMode ? 'margin-to-escape-footer' : ''}`}>
              <div className="additional-notes">
                {this.props.additionalNote || this.props.showAdditionalNotes ?
                  (this.props.previewMode ?
                    <div className="text">
                      {this.props.additionalNote}
                    </div>
                      :
                    <div>
                      <Field
                        component={ResizableTextarea}
                        name="additionalNote"
                        minrows={4}
                        maxrows={10}
                      />
                      <div className="counter-notes">
                        <div
                          className={`additional-notes-counter ${!!this.props.additionalNote && (this.props.additionalNote.length === MAX_LENGTH_ADDITIONAL_NOTES || this.props.additionalNote.length > MAX_LENGTH_ADDITIONAL_NOTES) ? 'warning' : ''}`}
                        >
                          {this.props.additionalNote ? this.props.additionalNote.length : 0}{` / ${MAX_LENGTH_ADDITIONAL_NOTES}`}
                        </div>
                        {!!this.props.additionalNote && (this.props.additionalNote.length === MAX_LENGTH_ADDITIONAL_NOTES || this.props.additionalNote.length > MAX_LENGTH_ADDITIONAL_NOTES) &&
                          <div
                            className="warning-additional-notes"
                          >{I18n.t('editDocument.WARNING_ADDITIONAL_NOTES')}
                          </div>}
                      </div>
                    </div>
                  )
                  :
                    <div>
                      {!this.props.previewMode && <div className="empty-box hide-pdf">
                        <i className="fa fa-notes-medical empty-box-icon" />
                        <div className="empty-box-title">{I18n.t('editDocument.EMPTY_NOTES')}</div>
                        <div>
                          <Button
                            color="primary"
                            onClick={this.props.toggleShowAdditionalNotes}
                          >
                            <i className="fal fa-plus" />
                            <span>{I18n.t('editDocument.ADD_NOTES')}</span>
                          </Button>
                        </div>
                                                  </div>}
                    </div>
                }
              </div>
              <div className="document-signature">
                <div className="important-text">
                  {I18n.t('editDocument.DR')} <span
                    className="text"
                  >{!!this.props.prescription.doctorName && this.props.prescription.doctorName.toUpperCase()}
                                              </span>
                </div>
                <img src={this.props.prescription.doctorSignature} className="doctor-signature" />
              </div>
            </div>
          </div>
          {!this.props.previewMode &&
          <div className="document-bottom footer fix-to-bottom">
            {this.props.showFullFooter ?
              <div className="document-footer footer">
                <div className="text-medium">{I18n.t('editDocument.FOOTER_1')}</div>
                <div className="bloc-text text-medium">
                  {`${I18n.t('editDocument.FOOTER_2')} ${this.props.showEmergencyNumber ? I18n.t('editDocument.FOOTER_2_EMERGENCY_NUMBERS') : ''}`}
                </div>
                <div className="text-medium">{I18n.t('editDocument.FOOTER_3')}</div>
              </div> :
              <div className="document-footer footer">
                <div className="bloc-text text-medium">
                  {`${I18n.t('editDocument.FOOTER_2')} ${this.props.showEmergencyNumber ? I18n.t('editDocument.FOOTER_2_EMERGENCY_NUMBERS') : ''}`}
                </div>
                <div className="text-medium">{I18n.t('editDocument.FOOTER_4')}</div>
              </div>}
          </div>}
        </div>
      </Fragment>
    );
  }
}

export default PrescriptionPreview;
