// @flow

import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'reactstrap';
import { CoreozDatePickerBase as CoreozDatePicker, CoreozInputBase as CoreozInput } from '../../../lib/coreoz-form-base/index';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import PlmPanel from '../../../components/panel/PlmPanel';

type Props = {}

class PolicyInformation extends React.PureComponent<Props> {
  render() {
    return (
      <Row>
        <Col xs="12">
          <PlmPanel>
            <span className="title-panel">{I18n.t('administration.policy.TITLE')}</span>
            <div className="sub-div" title={I18n.t('administration.policy.TITLE')}>
              <span className="subtitle-panel">{I18n.t('administration.policy.policyHolder.TITLE')}</span>
              <Field
                disabled
                label={I18n.t('administration.policy.policyHolder.FIRSTNAME')}
                component={CoreozInput}
                name="policyHolderFirstName"
              />
              <Field
                disabled
                label={I18n.t('administration.policy.policyHolder.LASTNAME')}
                component={CoreozInput}
                name="policyHolderLastName"
              />
              <Field
                disabled
                label={I18n.t('administration.policy.policyHolder.CITY')}
                component={CoreozInput}
                name="policyHolderCity"
              />
              <Field
                disabled
                label={I18n.t('administration.policy.policyHolder.PHONE_NUMBER')}
                component={CoreozInput}
                name="policyHolderPhonenumber"
              />
            </div>

            <div className="sub-div" title={I18n.t('administration.policy.beneficiary.TITLE')}>
              <span className="subtitle-panel">{I18n.t('administration.policy.beneficiary.TITLE')}</span>

              <Field
                disabled
                label={I18n.t('administration.policy.beneficiary.FIRSTNAME')}
                component={CoreozInput}
                name="beneficiaryFirstName"
              />
              <Field
                disabled
                label={I18n.t('administration.policy.beneficiary.LASTNAME')}
                component={CoreozInput}
                name="beneficiaryLastName"
              />
              <Field
                disabled
                label={I18n.t('administration.policy.beneficiary.BIRTHDATE')}
                component={CoreozDatePicker}
                onlyDate
                name="beneficiaryBirthday"
                dateFormat={I18n.t('date.DATE_FORMAT')}
                format={value => (value ? moment(value).format('DD/MM/YYYY') : '')}
              />
            </div>
            <div title={I18n.t('administration.policy.policy.TITLE')}>
              <span className="subtitle-panel">{I18n.t('administration.policy.policy.TITLE')}</span>

              <Field
                disabled
                label={I18n.t('administration.policy.policy.POLICY_NUMBER')}
                component={CoreozInput}
                name="policyNumber"
              />
              <Field
                disabled
                label={I18n.t('administration.policy.policy.PROTOCOL_NUMBER')}
                component={CoreozInput}
                name="protocolNumber"
              />
              <Field
                disabled
                label={I18n.t('administration.policy.policy.BRAND')}
                component={CoreozInput}
                name="brand"
              />
              <Field
                disabled
                label={I18n.t('administration.policy.policy.LEGAL_ENTITY')}
                component={CoreozInput}
                name="legalEntityName"
              />
              <div className="radio-button-form-container container-input-coreoz disabled">
                <div className="radio-button-label-container">
                  {I18n.t('administration.policy.policy.PDA_ACCOUNT')}
                </div>
                <div className="radio-button-container">
                  <Field
                    disabled
                    component="input"
                    name="isPdaAccount"
                    type="radio"
                    value="true"
                  />
                  <span>{I18n.t('administration.policy.policy.YES')}</span>

                  <Field
                    disabled
                    component="input"
                    name="isPdaAccount"
                    type="radio"
                    value="false"
                  />
                  <span>{I18n.t('administration.policy.policy.NO')}</span>

                </div>
              </div>
            </div>
          </PlmPanel>
        </Col>
      </Row>

    );
  }
}

export default withRouter(PolicyInformation);
