// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import ModalEprm from '../../modal/ModalEprm';

type Props = {
  callbackPhoneNumber: String,
  mobilePhone: String,
  closedSelf: Function,
}

type States = {
  message: string,
}

class CallbackPopin extends React.Component<Props, States> {
  getPhoneNumber = (callBack, mobile) => {
    if (callBack && callBack.length > 0) {
      return I18n.t('contact.phone.CALLBACK_NUMBER', { number: callBack });
    } else if (mobile && mobile.length > 3) {
      return I18n.t('contact.phone.CALLBACK_NUMBER', { number: mobile });
    }
    return I18n.t('contact.phone.NO_PHONE_NUMBER');
  };

  render() {
    return (
      <ModalEprm
        title={I18n.t('contact.phone.TITLE')}
        modal
        toggle={this.props.closedSelf}
        className="contact-popin"
        size="lg"
      >
        <p>{this.getPhoneNumber(this.props.callbackPhoneNumber, this.props.mobilePhone)}</p>
      </ModalEprm>
    );
  }
}

export default withRouter(CallbackPopin);
