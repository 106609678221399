// @flow

import { RestClientAuthenticated } from '../../network/network';
import type { Mail } from '../../types/MailTypes';

const mailApi = {
  fetch: () => new RestClientAuthenticated('/mail', 'GET').execute(),
  save: (data: Mail) =>
    new RestClientAuthenticated('/mail', 'POST').jsonBody(data).execute(),
};

export default mailApi;
