// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import type { PlaceType } from '../../../../types/GoogleTypes';
import HospitalRow from './HospitalRow';
import Loader from '../../../Loader';

type Props = {
  selectedHospital: PlaceType,
  selectHospital: Function,
  multiSelect: Function,
  places: PlaceType[],
  loading: boolean,
}

type States = {}

function SideContent(props: Props) {
  const {
    selectedHospital, places, selectHospital, multiSelect, loading,
  } = props;
  if (loading) {
    return (<div className="side-content-hospital">
      <div className="title"><Loader /></div>
    </div>)
  }
  return (
    <div>
      {
        selectedHospital &&
        <div className="side-content-hospital first">
          <div className="title">
            <i className="fa fa-arrow-left float-left" onClick={() => selectHospital(null)} />
            {selectedHospital.name}
          </div>
          <div className="infos">
            <div className="section">
              <div className="section-title">{I18n.t('contact.hospitalSearch.address')}</div>
              <div>{selectedHospital.address}</div>
            </div>
            {
              selectedHospital.contact && (
                <div className="section">
                  <div className="section-title">{I18n.t('contact.hospitalSearch.contact')}</div>
                  <div>{selectedHospital.contact}</div>
                </div>
              )
            }
            {
              selectedHospital.departments && (
                <div className="section">
                  <div className="section-title">{I18n.t('contact.hospitalSearch.departments')}</div>
                  <div>{selectedHospital.departments.map(dep => <div>{dep}</div>)}</div>
                </div>
              )
            }
          </div>
        </div>
      }
      <div className="side-content-hospital second">
        <div className="title">{I18n.t('contact.hospitalSearch.LIST')}</div>
        {
          places.map(place => (<HospitalRow
            hospital={place}
            selectHospital={selectHospital}
            multiSelect={multiSelect}
          />))
        }
      </div>
    </div>
  );
}

export default withRouter(SideContent);
