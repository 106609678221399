// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

type Props = {
  icon: string,
  label: string,
  action: Function,
  blink: Boolean,
  color: string,
  highlight: Boolean,
}


class ContactMean extends React.Component<Props> {

  componentDidMount() {
  }

  onClick = () => {
    this.props.action();
  };

  render() {
    return (
      <div className={`contact-mean ${this.props.highlight ? 'highlight' : ''} ${this.props.caseInfos && !this.props.caseInfos.isEditable ? 'button-disabled' : ''} ${this.props.color}`}
           onClick={() => this.props.caseInfos && this.props.caseInfos.isEditable && this.onClick()}>
        <h3 className="display-lign">
          <i className={`fa ${this.props.icon} ${this.props.blink ? 'blink' : ''}`} />
          <div>{this.props.label}</div>
        </h3>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  caseInfos: state.process.caseInfos,
}))(ContactMean));
